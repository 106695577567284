import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TextField,
    Typography,
    Stack,
    Drawer,
    Box,
    Button,
    IconButton,
} from "@mui/material";

import Fuse from "fuse.js";

import Colours from "../helpers/colours";

import { DEFAULT_HOTEL, URLAPI } from "../configuration";
import DateRangePicker from "../components/DateRangePicker";

import { dateToUTC, newDateUTC } from "../helpers/dates";
import TableHeadSort from "../components/TableHeadSort";

import sanitizeHtml from "sanitize-html";
// import EmailScreen from "../pages/EmailScreen";
import Icons from "../helpers/icons";
import EmailListModal from "../components/EmailListModal";

const ITEM_HEIGHT = "43px";

const headerStyles = {
    textAlign: "left",
    backgroundColor: Colours.tableRowBackground,
    fontWeight: "normal",
    paddingTop: 3,
    paddingBottom: 3,
};

const HEADER = [
    {
        id: "send_date",
        label: "Send date",
        format: (e) => {
            let str_send_date = new Date(e).toISOString().slice(0, 10);
            return `${str_send_date}`;
        },
        styles: headerStyles,
    },
    {
        id: "email_status",
        label: "Status",
        format: (e) => {
            return `${e}`;
        },
        styles: headerStyles,
    },
    {
        id: "from_date",
        label: "From",
        format: (e) => {
            let str_from_date = new Date(e).toISOString().slice(0, 10);
            return `${str_from_date}`;
        },
        styles: headerStyles,
    },
    {
        id: "to_date",
        label: "To",
        format: (e) => {
            let str_to_date = new Date(e).toISOString().slice(0, 10);
            return `${str_to_date}`;
        },
        styles: headerStyles,
    },
    {
        id: "user_emails",
        label: "Recipients",
        ignore: true,
        format: (e) => {
            let str_user_emails = `TO ${e.slice(0, 2).join(", ")} `;
            str_user_emails += e.length > 2 ? `+ ${e.length} others` : "";

            return str_user_emails;
        },
        styles: headerStyles,
    },
];

const EmailLogsScreen = () => {
    const { auth } = useSelector((state) => state);
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [transformedLogs, setTransformedLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("send_date");
    const [sortedLogs, setSortedLogs] = useState([]);

    const [querySearch, setQuerySearch] = useState("");
    const [fromDate, setFromDate] = useState(
        newDateUTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
    );
    const [toDate, setToDate] = useState(
        newDateUTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0)
    );

    // State to manage the side drawer
    const [drawerOpen, setDrawerOpen] = useState(false);

    // Functions

    useEffect(() => {
        Load();
    }, [hotelID]);

    const Load = () => {
        setData([]);
        setLoading(true);
        Axios.get(
            `${URLAPI}/email/logs/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
            }
        )
            .then((r) => {
                setData(r.data.data);
            })
            .finally(() => setLoading(false));
    };

    const formatDate = (d) => {
        return new Date(d).toISOString().slice(0, 10);
    };

    useEffect(() => {
        if (data.length === 0) {
            return;
        }

        let aux_d = {};

        // A simple hash function (djb2 algorithm) for generating a short, unique hash of the text
        const hashString = (str) => {
            let hash = 5381;
            for (let i = 0; i < str.length; i++) {
                hash = (hash * 33) ^ str.charCodeAt(i);
            }
            return hash >>> 0; // Convert to unsigned 32-bit integer
        };

        // Function to remove "Dear [Name]," from the start of the email text
        const cleanText = (text) => {
            return text.replace(/^<div>\n\s*Dear [^,]+,/, "").trim();
        };

        data.forEach((e) => {
            // let send_date = new Date(e.send_date).toISOString().slice(0, 10);
            // let from_date = new Date(e.from_date).toISOString().slice(0, 10);
            // let to_date = new Date(e.to_date).toISOString().slice(0, 10);

            let cleaned_text = cleanText(e.text);
            let text_hash = hashString(cleaned_text);

            let key = `${e.send_date}_${e.email_status}_${e.from_date}_${e.send_date}_${text_hash}`; // _${e.queued_reason}_${e.reject_reason}

            if (!aux_d.hasOwnProperty(key)) {
                aux_d[key] = {
                    key: key,
                    send_date: e.send_date,
                    send_date_str: formatDate(e.send_date),
                    email_status: e.email_status,
                    from_date: e.from_date,
                    from_date_str: formatDate(e.from_date),
                    to_date: e.to_date,
                    to_date_str: formatDate(e.to_date),
                    // queued_reason: e.queued_reason,
                    // reject_reason: e.reject_reason,
                    text: cleaned_text,
                    user_emails: new Set(),
                    user_emails_text: "",
                };
            }
            aux_d[key].user_emails.add(e.user_email);

            // Convert the Set to a string and store it in user_emails_text
            aux_d[key].user_emails_text = Array.from(
                aux_d[key].user_emails
            ).join(", ");
        });

        let groupedData = Object.values(aux_d).map((group) => {
            return {
                ...group,
                user_emails: Array.from(group.user_emails),
            };
        });

        setProcessedData(groupedData);
    }, [data]);

    // date first

    useEffect(() => {
        if (processedData.length === 0) {
            return;
        }

        let aux_transformed_logs = [];

        processedData.forEach((e) => {
            // let send_date = dateToUTC(new Date(e.send_date));

            if (
                new Date(e.send_date) >= fromDate &&
                new Date(e.send_date) <= toDate
            ) {
                aux_transformed_logs.push(e);
            }
        });

        setTransformedLogs(aux_transformed_logs);
        // console.log(transformedLogs);
    }, [fromDate, toDate]);

    useEffect(() => {
        if (transformedLogs.length > 0) filterLogs();
    }, [querySearch, transformedLogs]);

    const filterLogs = () => {
        let aux_filtered_logs = transformedLogs;

        if (querySearch === "") {
            setFilteredLogs(transformedLogs);
            return;
        }

        const searchOptions = {
            threshold: 0.2,
            keys: [
                { name: "send_date_str" },
                { name: "email_status" },
                { name: "from_date_str" },
                { name: "to_date_str" },
                { name: "user_emails_text" },
            ],
        };

        const fuseSearch = new Fuse(transformedLogs, searchOptions);

        aux_filtered_logs = fuseSearch
            .search(querySearch)
            .map(({ item: log, index }) => log);

        setFilteredLogs(aux_filtered_logs);
    };

    useEffect(() => {
        let sortArray = (arr, order) => {
            return arr.sort((a, b) => {
                if (order === "asc") return a[orderBy] > b[orderBy] ? -1 : 1;
                else return a[orderBy] >= b[orderBy] ? 1 : -1;
            });
        };

        let aux_sorted_logs = sortArray([...filteredLogs], order);

        setSortedLogs(aux_sorted_logs);
    }, [filteredLogs, order, orderBy]);

    // Modal

    const OPENED_SIZE = 800; // "300";
    const CLOSED_SIZE = 20;

    const AUTO_OPEN_ZONE = 20;
    const BUTTON_SIZE = 30;

    const closeOnLeave = false;

    const [open, setOpen] = useState(false);
    const [keepOpen, setKeepOpen] = useState(false);

    const handleMouseMove = (e) => {
        const x = e.clientX;

        if (x <= AUTO_OPEN_ZONE) {
            setOpen(true);
            setKeepOpen(true);
        }
    };

    useEffect(() => {
        if (closeOnLeave) {
            setOpen(false);
            setKeepOpen(false);
        }
    }, [closeOnLeave]);

    return (
        <>
            <Container>
                <div
                    onMouseMove={handleMouseMove}
                    onMouseLeave={() => {
                        setOpen(false);
                        if (closeOnLeave) setKeepOpen(false);
                    }}
                    style={{ display: "flex", marginLeft: "1em" }}
                >
                    <Drawer
                        variant="permanent"
                        open={open || keepOpen}
                        anchor="right"
                        sx={{
                            zIndex: 1199,
                            width: open || keepOpen ? OPENED_SIZE : CLOSED_SIZE,
                            transition: (theme) =>
                                theme.transitions.create("width", {
                                    easing: theme.transitions.easing.sharp,
                                    duration:
                                        theme.transitions.duration
                                            .enteringScreen,
                                }),

                            "& .MuiDrawer-paper": {
                                background: "#fafafa",
                                borderLeft: `1px solid ${Colours.navbarBottomLine}`,
                                justifyContent: "space-between",
                                overflowX: "hidden",
                                width:
                                    open || keepOpen
                                        ? OPENED_SIZE
                                        : CLOSED_SIZE,
                                transition: (theme) =>
                                    theme.transitions.create("width", {
                                        easing: theme.transitions.easing.sharp,
                                        duration:
                                            theme.transitions.duration
                                                .leavingScreen,
                                    }),
                            },
                        }}
                    >
                        {(open || keepOpen) && (
                            <>
                                <Box marginTop={8}>
                                    {/* <EmailScreen /> */}

                                    <EmailListModal
                                        setOpen={setOpen}
                                        setKeepOpen={setKeepOpen}
                                    />
                                </Box>
                            </>
                        )}
                    </Drawer>
                </div>

                <Stack sx={{ pt: 2, pb: 2 }} spacing={2}>
                    <Typography variant={"h4"}>Email reports</Typography>
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant={"h8"}>
                            Here is a log of all emails sent from Aro Analytics
                            to selected recipients.
                        </Typography>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                if (open || keepOpen) {
                                    setKeepOpen(false);
                                    setOpen(false);
                                } else {
                                    setKeepOpen(true);
                                    setOpen(true);
                                }
                            }}
                        >
                            Edit email recipients
                        </Button>
                    </Stack>

                    <Stack
                        component={Paper}
                        direction={"column"}
                        spacing={2}
                        // sx={{ minWidth: "300px", minHeight: "400px" }}
                    >
                        <Stack spacing={4} p={2}>
                            <Stack
                                direction="row"
                                // justifyContent={"space-between"}
                                alignItems={"center"}
                                spacing={2}
                            >
                                <DateRangePicker
                                    title="Time period"
                                    boxProps={{ sx: { height: ITEM_HEIGHT } }}
                                    fromDateProps={{
                                        value: fromDate,
                                        maxDate: new Date(
                                            new Date().getUTCFullYear() + 2,
                                            0,
                                            0
                                        ),
                                    }}
                                    onChangeFromDate={(newValue) => {
                                        setFromDate(newValue);
                                    }}
                                    toDateProps={{
                                        value: toDate,
                                        maxDate: new Date(
                                            new Date().getUTCFullYear() + 2,
                                            0,
                                            0
                                        ),
                                    }}
                                    onChangeToDate={(newValue) => {
                                        setToDate(newValue);
                                    }}
                                />
                                <Stack>
                                    <Typography
                                        variant="caption"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Search for
                                    </Typography>
                                    <TextField
                                        id="search-bar"
                                        className="text"
                                        onInput={(e) => {
                                            setQuerySearch(e.target.value);
                                        }}
                                        value={querySearch}
                                        variant="outlined"
                                        placeholder="Email title, recipient, etc..."
                                        sx={{
                                            minWidth: "300px",
                                            height: ITEM_HEIGHT,
                                        }}
                                        autoComplete="off"
                                        size={"small"}
                                        autoFocus
                                    />
                                </Stack>
                            </Stack>

                            <TableContainer style={{ overflow: "auto" }}>
                                <Table>
                                    <TableHeadSort
                                        headers={HEADER}
                                        order={order}
                                        setOrder={setOrder}
                                        orderBy={"send_date"}
                                        setOrderBy={setOrderBy}
                                    />
                                    <TableBody>
                                        {sortedLogs.length > 0 &&
                                            sortedLogs.map((e) => (
                                                <LogRow e={e} />
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </>
    );
};

function createMarkup(users, text) {
    let string_users = users.join(", ");

    console.log(sanitizeHtml("<img src=x onerror=alert('img') />"));

    return {
        __html: `Sent to: ${string_users} <br> ${sanitizeHtml(text)}`,
    };
}

const LogRow = ({ e }) => {
    const [selected, setSelected] = useState(false);

    return (
        <>
            <TableRow
                key={e.key}
                onClick={() => {
                    // console.log(e);
                    setSelected(!selected);
                }}
                sx={{
                    backgroundColor: selected ? Colours.selectedRow : "",
                }}
            >
                {HEADER.map(({ id, style, format }) => {
                    let val = e[id];

                    return <TableCell>{format ? format(val) : val}</TableCell>;
                })}
            </TableRow>

            {selected && (
                <TableRow>
                    <TableCell
                        colSpan={5}
                        dangerouslySetInnerHTML={createMarkup(
                            e.user_emails,
                            e.text
                        )}
                    />
                </TableRow>
            )}
        </>
    );
};

export default EmailLogsScreen;
