import React from "react";
import { useSelector } from "react-redux";
import { HOTELS_INFO } from "../../configuration";

// False === Hide ; True === Show
export const CheckHideBool = (k, hotelID) => {
    const hides = (HOTELS_INFO[hotelID] ?? {}).hide ?? {};
    if (hides[k] ?? 0) return false;
    return true;
};
/**
 * This component checks if the selected hotel has the option `{hide: {NNNN: 1}}`
 * If that NNNN key matches the k param provided, it returns empty.
 * If there is no hide option or if in the hide option there is not NNNN key or if this key is 0, returns the children
 * @param {String} k
 * @param {Component} children
 * @param {Boolean} forceHide when it is present, always hides it
 * @param {Array} rolesThatCannotSee array of roles, if any of your roles is in that list, the component will be hidden always
 */
const CheckHide = ({
    k,
    children,
    forceHide,
    rolesThatCannotSee = [],
    useEditedRoles = true,
}) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const authRoles = useSelector((state) => state.authRoles.edited);
    const authRolesOriginal = useSelector((state) => state.authRoles.original);

    // If forceHide is present or CheckHideBool returns false, hide. Otherwise, show.
    if (forceHide || !CheckHideBool(k, hotelID)) {
        return null;
    }

    // If any of the authenticated roles are in the rolesThatCannotSee list, hide the component.
    if (
        (useEditedRoles ? authRoles : authRolesOriginal).length > 0 &&
        rolesThatCannotSee.length > 0 &&
        rolesThatCannotSee.some((value) =>
            (useEditedRoles ? authRoles : authRolesOriginal).includes(value)
        )
    ) {
        return null;
    }

    return children;
};

export default CheckHide;

/**
 * For Tab elements use like this:
        {CheckHide({
            k: "Vouchers",
            children: (
                <Tab
                    wrapped
                    label="Vouchers"
                    id="VouchersReport"
                />
            ),
        })}
 *
 * Other elements:
        <CheckHide k="Vouchers">
            <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                <VouchersRevenueReport hotelID={hotelID} />
            </Grid>
        </CheckHide>
 */
