import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { Grid, Paper, IconButton, Container } from "@mui/material";

import { useSnackbar } from "notistack";

import DataLoader from "../components/DataLoader";

import Icons from "../helpers/icons";

import AroReportScreen from "../routers/AroReportScreen";
import AroInsightsScreen from "../routers/AroInsightsScreen";
import AroPlusScreen from "../routers/AroPlusScreen";
import CommissionScreen from "./CommissionScreen";
import ArrivalsScreen from "./ArrivalsScreen";

const AppScreen = () => {
    const authRoles = useSelector((state) => state.authRoles);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (
            authRoles.edited.length > 0 ||
            authRoles.hotels.length > 0 ||
            !authRoles.loaded
        ) {
        } else {
            enqueueSnackbar(
                "Looks like there are no roles assigned to you, please contact Alejandro Lorenzo - alejandro.lorenzo@aro.ie",
                {
                    persist: true,
                    disableWindowBlurListener: true,
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    preventDuplicate: true,
                    content: (key, message) => (
                        <Grid
                            container
                            alignItems="center"
                            spacing={1}
                            component={Paper}
                            sx={{
                                backgroundColor: "#d32f2f",
                                p: 1,
                                pl: 2,
                                pr: 2,
                                color: "white",
                                borderRadius: "4px",
                                fontSize: "0.75em",
                            }}
                        >
                            {console.log({ message })}
                            <Grid item xs={10}>
                                Looks like there are not roles assigned to you,
                                please contact support
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                container
                                justifyContent="flex-end"
                            >
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={() => {
                                        localStorage.clear();
                                        window.location.reload(false);
                                    }}
                                    aria-label="close"
                                >
                                    <Icons.LogOut />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ),
                }
            );
        }
    }, [authRoles]);

    return (
        <>
            <DataLoader
                Reservations
                SimpleData
                VouchersDetails
                Searches
                Membership
            />

            <Container maxWidth={false}>
                <Routes>
                    <Route path="" element={<AroReportScreen />} />
                    <Route path="report/*" element={<AroReportScreen />} />
                    <Route path="arrivals/*" element={<ArrivalsScreen />} />
                    <Route path="insights/*" element={<AroInsightsScreen />} />
                    <Route path="plus/*" element={<AroPlusScreen />} />
                    <Route path="commission" element={<CommissionScreen />} />
                </Routes>
            </Container>
            <br />
            <br />
            <br />
        </>
    );
};

export default AppScreen;
