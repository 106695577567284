import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import CustomFab from "./CustomFab";

import Icons from "../helpers/icons";

const ScrollToTop = ({ fabPositionX = 1, fabPositionY = 0 }) => {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        setShowButton(scrollTop > 100);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Box>
            {showButton && (
                <CustomFab
                    Icon={Icons.ArrowUp}
                    onClick={scrollToTop}
                    positionX={fabPositionX}
                    positionY={fabPositionY}
                />
            )}
        </Box>
    );
};

export default ScrollToTop;
