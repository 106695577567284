import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, Skeleton, Stack, Tooltip, Typography } from "@mui/material";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import { fns } from "../helpers/common";

import HoverPaper from "./shared/HoverPaper";

const CardDashboard_Old = ({
    title,
    subtitle,
    value,
    format,
    subbody,
    footer,
    isIncrease,
    selected,
    selectedColoured,
    colours = { selected: Colours.secondarySoft, unselected: null },
    loading = false,
    elevation = 10,
    noArrow = false,
    pointer = false,
    isInverted = false,
}) => {
    const [body, setBody] = useState("");

    const { id: hotelID } = useSelector((state) => state.hotelID);

    useEffect(() => {
        let auxBody = fns(hotelID, value, format, true);

        setBody(auxBody);
    }, [value, format]);

    return (
        <HoverPaper
            sx={{
                cursor: pointer ? "pointer" : "",
                p: 3,
                height: "100%",
                backgroundColor:
                    selected & selectedColoured
                        ? colours.selected
                        : colours.unselected,
            }}
            {...(selected ? { elevation: elevation } : {})}
        >
            <Stack
                sx={{ height: "100%" }}
                justifyContent="space-between"
                spacing={-2}
            >
                <Typography lineHeight={1}>
                    <Box component="span" fontWeight={"bold"}>
                        {title}
                    </Box>
                    <br />
                    <Box
                        component="span"
                        color={subtitle ? Colours.midLightText : "transparent"}
                        fontSize="0.6em"
                    >
                        {subtitle ?? "."}
                    </Box>
                </Typography>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    {loading ? (
                        <Skeleton variant="text" width={"60%"} />
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="baseline"
                            spacing={0}
                        >
                            <Typography lineHeight={"1"}>
                                {body.left}
                            </Typography>
                            <Typography
                                component="span"
                                fontSize="1.5em"
                                lineHeight={"1"}
                            >
                                {typeof body.number === "string" &&
                                body.number.length > 20
                                    ? body.number.substring(0, 20) + "..."
                                    : body.number}
                            </Typography>
                            <Typography lineHeight={"1"}>
                                {body.right}
                            </Typography>{" "}
                            <Box color={Colours.midLightText}>{subbody}</Box>
                        </Stack>
                    )}
                    {!noArrow && (
                        <>
                            {isIncrease ? (
                                <Icons.ArrowUp
                                    sx={{
                                        color: isInverted
                                            ? Colours.trafficLightRed
                                            : Colours.trafficLightGreen,
                                    }}
                                />
                            ) : (
                                <Icons.ArrowDown
                                    sx={{
                                        color: isInverted
                                            ? Colours.trafficLightGreen
                                            : Colours.trafficLightRed,
                                    }}
                                />
                            )}
                        </>
                    )}
                </Stack>

                <Typography lineHeight={"1"}>
                    <br />
                    <Box
                        component="span"
                        color={footer ? Colours.midLightText : "transparent"}
                        fontSize="0.75em"
                    >
                        {footer ?? "."}
                    </Box>
                </Typography>
            </Stack>
        </HoverPaper>
    );
};

export default CardDashboard_Old;
