import React, { useEffect, useState } from "react";

import { Box, Grid, Skeleton, Table, TableBody, TableRow } from "@mui/material";

import EChartsReact from "echarts-for-react";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import WrapperPDFTable from "../shared/WrapperPDFTable";

import TableBodyCell from "../TableBodyCell";

import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";
import { fns } from "../../helpers/common";
import { tooltip } from "../../helpers/eCharts";

const MembershipRepeatBookersReport = ({
    hotelID,
    data,
    time,
    isMobile = false,
}) => {
    const TITLE = "Membership - Repeat Bookers";

    const [dataRepeaters, setDataRepeaters] = useState({});
    const [membersRepeat, setMembersRepeat] = useState(-1); // Members repeat bookings
    const [repeatBookers, setRepeatBookers] = useState(-1); // All repeat bookings

    useEffect(() => {
        if (time && Object.keys(data).length > 0) {
            if (
                data.hasOwnProperty(time.getUTCFullYear()) &&
                data[time.getUTCFullYear()].hasOwnProperty(
                    time.getUTCMonth() + 1
                )
            )
                setDataRepeaters(
                    data[time.getUTCFullYear()][time.getUTCMonth() + 1]
                );
        }
    }, [data, time]);

    useEffect(() => {
        setMembersRepeat(-1);
        setRepeatBookers(-1);

        if (
            dataRepeaters.hasOwnProperty("MemberRepeatBookings") &&
            dataRepeaters.hasOwnProperty("RepeatBookings")
        ) {
            setMembersRepeat(dataRepeaters.MemberRepeatBookings);
            setRepeatBookers(dataRepeaters.RepeatBookings);
        }
    }, [dataRepeaters]);

    return (
        <Grid item sx={{ width: "100%", height: "100%" }}>
            <br />

            <Grid
                container={!isMobile}
                direction={isMobile ? "column" : "row"}
                alignItems="center"
                justifyContent="center"
            >
                {repeatBookers >= 0 && membersRepeat >= 0 ? (
                    <>
                        <Grid item sm={12} md={4}>
                            <WrapperPDFEChart k={TITLE}>
                                <EChartsReact
                                    style={
                                        isMobile
                                            ? { height: "300%", width: "100%" }
                                            : {}
                                    }
                                    option={{
                                        responsive: true,

                                        tooltip: {
                                            trigger: "item",
                                            position: tooltip.position,
                                        },

                                        series: [
                                            {
                                                type: "pie",
                                                center: ["50%", "50%"],
                                                radius: ["50%", "70%"],
                                                data: [
                                                    {
                                                        name: "Repeating Members",
                                                        value: membersRepeat,
                                                        itemStyle: {
                                                            color: Colours.primary,
                                                        },
                                                    },
                                                    {
                                                        name: "Repeating Non-Members",
                                                        value:
                                                            repeatBookers -
                                                            membersRepeat,
                                                        itemStyle: {
                                                            color: Colours.info,
                                                        },
                                                    },
                                                ],
                                                labelLine: {
                                                    show: false,
                                                },
                                                label: {
                                                    color: "black",
                                                    fontSize: "30",
                                                    position: "center",
                                                    formatter: () => {
                                                        return `{bold|${repeatBookers}}\n{gray|Total}`;
                                                    },
                                                    rich: {
                                                        bold: {
                                                            fontWeight: "bold",
                                                            fontSize: "30px",
                                                        },
                                                        gray: {
                                                            color: Colours.midLightText,
                                                            fontSize: "20px",
                                                        },
                                                    },
                                                },
                                                avoidLabelOverlap: false,
                                            },
                                        ],
                                    }}
                                />
                            </WrapperPDFEChart>
                        </Grid>
                        <Grid item sm={12} md={8}>
                            <WrapperPDFTable k={TITLE + " values"}>
                                <Table sx={{ width: "80%" }}>
                                    <TableBody>
                                        <TableRow key={"repeat-members"}>
                                            <TableBodyCell>
                                                <Icons.Circle
                                                    sx={{
                                                        fontSize: "100%",
                                                        color: Colours.primary,
                                                    }}
                                                />
                                            </TableBodyCell>
                                            <TableBodyCell>
                                                {"Repeating Members"}
                                            </TableBodyCell>
                                            <TableBodyCell>
                                                {fns(hotelID, membersRepeat)}
                                            </TableBodyCell>
                                        </TableRow>
                                        <TableRow key={"repeat-nonMembers"}>
                                            <TableBodyCell>
                                                <Icons.Circle
                                                    sx={{
                                                        fontSize: "100%",
                                                        color: Colours.info,
                                                    }}
                                                />
                                            </TableBodyCell>
                                            <TableBodyCell>
                                                {"Repeating Non-Members"}
                                            </TableBodyCell>
                                            <TableBodyCell>
                                                {fns(
                                                    hotelID,
                                                    repeatBookers -
                                                        membersRepeat
                                                )}
                                            </TableBodyCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </WrapperPDFTable>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} md={4}>
                            <br />
                            <Skeleton
                                variant="circular"
                                align="center"
                                sx={{
                                    margin: "auto",
                                    height: "200px",
                                    width: "200px",
                                }}
                            />
                            <br />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableBodyCell
                                            colSpan={100}
                                            align="center"
                                        >
                                            <Box
                                                component="span"
                                                fontStyle="italic"
                                            >
                                                {time.getUTCMonth() ===
                                                new Date().getUTCMonth()
                                                    ? "Data retrieved after the month ends."
                                                    : "No data"}
                                            </Box>
                                        </TableBodyCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default MembershipRepeatBookersReport;
