import React from "react";

import { Box, Toolbar } from "@mui/material";

import SideMenu from "../SideMenu";
import TopBar from "../TopBar";
import Footer from "../Footer";

const Menu = ({
    children,
    showSideMenu = true,
    closeOnLeave = false,
    showFooter = true,
}) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <TopBar />
            <Box sx={{ display: "flex", flexGrow: 1 }}>
                {showSideMenu && (
                    <Box sx={{ width: "auto", flexShrink: 0 }}>
                        <SideMenu closeOnLeave={closeOnLeave} />
                    </Box>
                )}
                <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                    {/* ↓ This is for reserving some room for the TopBar */}
                    <Toolbar />
                    {children}
                </Box>
            </Box>
            {showFooter && (
                <Box sx={{ display: "flex", flexGrow: 1 }}>
                    <Footer />
                </Box>
            )}
        </Box>
    );
};

export default Menu;
