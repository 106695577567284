import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Grid,
    Skeleton,
    Paper,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { fns } from "../helpers/common";
import TabSelector from "./TabSelector";
import TrendChart from "./TrendChart";
import { months } from "../helpers/dates";

const KeyTabsWithTrendGraph = ({
    TITLE,
    HEADER,
    dataMonths,
    dataYears,
    fullYear,
    time,
}) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [clickedCard, setClickedCard] = useState("");

    // useEffect(() => {
    //     // Now when any one is loaded, it counts as loaded, so if there is some missing data, it still shows
    //     if (!dataLoaded) {
    //         let aux =
    //             monthsData.some((month) => Object.keys(month).length > 0) ||
    //             Object.keys(dataByYear).length > 0;
    //         setDataLoaded(aux);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedData, clickedCard, cardMode, dataByYear, dataMonths]);

    const formatLabelValue = (value) => {
        let format = {
            maxDecimals:
                (HEADER.find((head) => head.id === clickedCard).format ?? {})
                    .maxDecimals ?? 0,
        };
        return fns(hotelID, value, format);
    };

    const [expandedHeader, setExpandedHeader] = useState([]);

    useEffect(() => {
        const auxExpandedHeader = [];
        HEADER.forEach((head) => {
            let auxHead = Object.assign({}, head);
            auxHead.expand = true;

            let oldValue = 0;

            if (fullYear) {
                auxHead.value =
                    (dataYears[time.getUTCFullYear()] ?? {})[head.id] ?? 0;

                oldValue =
                    (dataYears[time.getUTCFullYear() - 1] ?? {})[head.id] ?? 0;
            } else {
                auxHead.value =
                    ((dataMonths[time.getUTCFullYear()] ?? {})[
                        time.getUTCMonth()
                    ] ?? {})[head.id] ?? 0;
                oldValue =
                    ((dataMonths[time.getUTCFullYear() - 1] ?? {})[
                        time.getUTCMonth()
                    ] ?? {})[head.id] ?? 0;
            }

            if (oldValue) {
                auxHead.compare = true;
                auxHead.old = oldValue;
            } else {
                auxHead.compare = false;
                auxHead.noArrow = true;
            }

            auxExpandedHeader.push(auxHead);
        });

        setExpandedHeader(auxExpandedHeader);
    }, [HEADER]);

    return (
        <Grid
            key={"Grid" + TITLE}
            container
            alignItems="stretch"
            spacing={0}
            justifyContent="left"
        >
            <TabSelector
                hotelID={hotelID}
                data={expandedHeader}
                cardSelected={clickedCard}
                setCardSelected={setClickedCard}
            />

            {
                //   ____                 _
                //  / ___|_ __ __ _ _ __ | |__
                // | |  _| '__/ _` | '_ \| '_ \
                // | |_| | | | (_| | |_) | | | |
                //  \____|_|  \__,_| .__/|_| |_|
                //                 |_|
            }

            {clickedCard !== "" ? (
                fullYear && Object.keys(dataYears ?? {}).length > 0 ? (
                    <TrendChart
                        TITLE={
                            TITLE +
                                " - " +
                                HEADER.find((head) => head.id === clickedCard)
                                    .title ?? ""
                        }
                        key={"yearChart"}
                        data={Object.keys(dataYears).reduce((accYear, year) => {
                            accYear[year] = months.reduce(
                                (accMonth, month, monthIndex) => {
                                    accMonth[month] =
                                        (dataMonths[year][monthIndex] ?? {})[
                                            clickedCard
                                        ] ?? 0;
                                    return accMonth;
                                },
                                {}
                            );
                            return accYear;
                        }, {})}
                        selected={Object.assign(
                            {},
                            ...Object.keys(dataYears).map((key) => ({
                                [key]: key == time.getUTCFullYear(),
                            }))
                        )}
                        markPoints={{
                            min: {
                                show: true,
                                formatter: formatLabelValue,
                            },
                            max: {
                                show: true,
                                formatter: formatLabelValue,
                            },
                        }}
                        markLine={{
                            show: true,
                            formatter: formatLabelValue,
                        }}
                    />
                ) : !fullYear &&
                  Object.keys(
                      (dataMonths[time.getUTCFullYear()] ?? {})[
                          time.getUTCMonth()
                      ] ?? {}
                  ).length > 0 ? (
                    <TrendChart
                        TITLE={
                            TITLE +
                                " - " +
                                HEADER.find((head) => head.id === clickedCard)
                                    .title ?? ""
                        }
                        key={"monthChart"}
                        data={{
                            [`${
                                months[time.getUTCMonth()]
                            } ${time.getUTCFullYear()}`]: Object.keys(
                                dataMonths[time.getUTCFullYear()][
                                    time.getUTCMonth()
                                ].Details
                            ).reduce((acc, day) => {
                                acc[day] =
                                    dataMonths[time.getUTCFullYear()][
                                        time.getUTCMonth()
                                    ].Details[day][clickedCard];
                                return acc;
                            }, {}),
                        }}
                        markPoints={{
                            min: {
                                show: true,
                                formatter: formatLabelValue,
                            },
                            max: {
                                show: true,
                                formatter: formatLabelValue,
                            },
                        }}
                        markLine={{
                            show: true,
                            formatter: formatLabelValue,
                        }}
                    />
                ) : (
                    <Box component={Paper} height={"100%"} width={"100%"}>
                        <Skeleton
                            variant="rounded"
                            height={300}
                            maxWidth={600}
                            sx={{ margin: 2 }}
                        />
                    </Box>
                )
            ) : (
                ""
            )}
        </Grid>
    );
};

export default KeyTabsWithTrendGraph;
