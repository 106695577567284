import {
    Modal,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableHeaderCell from "../TableHeaderCell";
import TableBodyCell from "../TableBodyCell";

const DenseTableModal = ({ open, setOpen, data, hideKeys = [] }) => {
    const [orderBy, setOrderBy] = useState(Object.keys(data)[0] ?? "");
    const [order, setOrder] = useState("asc");
    const [sortedData, setSortedData] = useState([]);

    // sort
    useEffect(() => {
        if (data) {
            let aux = [...data];
            aux.sort((a, b) => {
                if (order === "asc") return a[orderBy] >= b[orderBy] ? -1 : 1;
                else return a[orderBy] >= b[orderBy] ? 1 : -1;
            });
            setSortedData(aux);
        }
    }, [data, orderBy, order]);

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <TableContainer component={Paper} sx={{ m: 5, maxHeight: "80vh" }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {Object.keys(data[0] ?? {})
                                .filter((k) => !hideKeys.includes(k))
                                .map((k) => (
                                    <TableHeaderCell key={k}>
                                        <TableSortLabel
                                            active={orderBy === k}
                                            direction={
                                                orderBy === k ? order : "asc"
                                            }
                                            onClick={() => {
                                                const isAsc =
                                                    orderBy === k &&
                                                    order === "asc";
                                                setOrder(
                                                    isAsc ? "desc" : "asc"
                                                );
                                                setOrderBy(k);
                                            }}
                                        >
                                            {k}
                                        </TableSortLabel>
                                    </TableHeaderCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ "& td": { fontSize: "0.75em" } }}>
                        {sortedData.map((details, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                {Object.keys(sortedData[0] ?? {})
                                    .filter((k) => !hideKeys.includes(k))
                                    .map((k) => (
                                        <TableBodyCell key={k}>
                                            {details[k] ?? ""}
                                        </TableBodyCell>
                                    ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Modal>
    );
};

export default DenseTableModal;
