import { hotelIDTypes } from "../types/hotelIDTypes";

export const setHotelID = (id) => {
    return async (dispatch) => {
        dispatch({
            type: hotelIDTypes.set,
            payload: { id },
        });
    };
};
