import React from "react";

import EChartsReact from "echarts-for-react";

import {
    Grid,
    Grow,
    TableBody,
    TableCell,
    Table,
    TableRow,
} from "@mui/material";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import WrapperPDFTable from "../shared/WrapperPDFTable";

import { months } from "../../helpers/dates";
import { cero } from "../../helpers/common";
import Colours from "../../helpers/colours";

const TopDaysMonthComparison = ({
    lastTopDays,
    thisTopDays,
    month,
    year,
    TITLE,
}) => {
    const topNData = (data, N, year, month) =>
        data
            .map((value, index) => ({
                date: `${year}-${cero(months.indexOf(month) + 1)}-${cero(
                    index + 1
                )}`,
                value,
            }))
            .sort((a, b) => b.value - a.value)
            .slice(0, N);

    return (
        <Grid container spacing={3} justifyContent={"center"}>
            {[lastTopDays, thisTopDays].map((data, index) => (
                <Grid item xs={12} md={6} key={index}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Grow in={true} timeout={250 + 500 * index}>
                            <div style={{ width: "100%", height: "400px" }}>
                                {console.log({ TITLE, index })}
                                <WrapperPDFEChart
                                    k={`${TITLE} ${year - 1 + index} ${month}`}
                                >
                                    <EChartsReact
                                        style={{
                                            height: "400px",
                                            width: "100%",
                                        }}
                                        option={{
                                            tooltip: {},
                                            xAxis: {
                                                type: "category",
                                                data: data.map(
                                                    (value, indexDay) => {
                                                        const day =
                                                            indexDay + 1;
                                                        const date = `${
                                                            year - 1 + index
                                                        }-07-${day
                                                            .toString()
                                                            .padStart(2, "0")}`;
                                                        return date;
                                                    }
                                                ),
                                            },
                                            yAxis: {},
                                            series: [
                                                {
                                                    type: "line",
                                                    data: data.map(
                                                        (value, indexDay) => {
                                                            return value;
                                                        }
                                                    ),
                                                    itemStyle: {
                                                        color:
                                                            index === 0
                                                                ? Colours.graphDisabledLighter
                                                                : Colours.graphSold,
                                                    },
                                                },
                                            ],
                                        }}
                                    />
                                </WrapperPDFEChart>
                            </div>
                        </Grow>
                        <br />
                        <WrapperPDFTable
                            k={`${TITLE} ${year - 1 + index} ${month} values`}
                        >
                            <Table
                                size="small"
                                style={{ width: "auto", marginBottom: "20px" }}
                            >
                                <TableBody>
                                    {topNData(
                                        data,
                                        3,
                                        year - 1 + index,
                                        month
                                    ).map((entry, index) => (
                                        <TableRow key={entry.date}>
                                            <TableCell>
                                                Top {index + 1}
                                            </TableCell>
                                            <TableCell>{entry.date}</TableCell>
                                            <TableCell>{entry.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </WrapperPDFTable>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};

export default TopDaysMonthComparison;
