import React, { useEffect, useState } from "react";
import Axios from "axios";

import {
    Container,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    styled,
    Button,
    Stack,
    CircularProgress,
    IconButton,
    Typography,
    TableSortLabel,
    Tooltip,
    Checkbox,
} from "@mui/material";

import { URLAPI } from "../../configuration";
import TableHeadSort from "../TableHeadSort";
import TableHeaderCell from "../TableHeaderCell";

const HEADER = [
    {
        id: "name",
        label: "Name",
    },
    {
        id: "roles_names",
        label: "Roles",
    },
];

const ListUsers = ({ selectedUsers = [] }) => {
    const [data, setData] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [loading, setLoading] = useState(false);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");

    const loadData = (setLoading) => {
        // setData([]);
        setLoading(true);
        Axios.get(`${URLAPI}/role_management/users`).then((response) => {
            console.log({ response });
            setLoading(false);
            setData(response.data.data);
            setDataToShow(response.data.data);
        });
    };
    useEffect(() => {
        loadData(setLoading);
    }, []);

    useEffect(() => {
        let aux = [...data];
        if (orderBy === "name") {
            aux.sort((a, b) =>
                order === "asc"
                    ? `${a.name} ${a.last_name}` > `${b.name} ${b.last_name}`
                        ? -1
                        : 1
                    : `${a.name} ${a.last_name}` <= `${b.name} ${b.last_name}`
                    ? -1
                    : 1
            );
        } else if (orderBy === "roles_name") {
            aux.sort((a, b) =>
                order === "asc"
                    ? a.roles_name.join(", ") > `${b.name} ${b.last_name}`
                        ? -1
                        : 1
                    : `${a.name} ${a.last_name}` <= `${b.name} ${b.last_name}`
                    ? -1
                    : 1
            );
        }
        setDataToShow(aux);
    }, [order, orderBy]);

    return (
        <>
            <br />
            <Typography>Users</Typography>
            {loading && <CircularProgress />}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {HEADER.map((headCell, index) => (
                            <TableHeaderCell
                                key={headCell.id}
                                sortDirection={
                                    orderBy === headCell.id ? order : false
                                }
                                // style={{
                                //     position: "sticky",
                                //     top: 0,
                                //     zIndex: index < stickyHeads ? 4 : 3,
                                //     left: index < stickyHeads ? index * 76 : "",
                                //     backgroundColor: Colours.plainWhite,
                                // }}
                            >
                                {"ignore" in headCell ? (
                                    <>{headCell.label}</>
                                ) : (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={
                                            orderBy === headCell.id
                                                ? order
                                                : "asc"
                                        }
                                        onClick={() => {
                                            const isAsc =
                                                orderBy === headCell.id &&
                                                order === "asc";
                                            setOrder(isAsc ? "desc" : "asc");
                                            setOrderBy(headCell.id);
                                        }}
                                    >
                                        {headCell.tooltip ? (
                                            <Tooltip title={headCell.tooltip}>
                                                <div>{headCell.label}</div>
                                            </Tooltip>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableSortLabel>
                                )}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataToShow.map((u) => (
                        <TableRow key={u.email}>
                            <TableCell>
                                <Checkbox />
                            </TableCell>
                            <TableCell>
                                {u.name} {u.last_name}
                            </TableCell>
                            <TableCell>
                                <ul
                                    style={{
                                        listStyleType: "disc",
                                        paddingLeft: "20px",
                                    }}
                                >
                                    {u.roles_name.map((role, index) => (
                                        <li key={index}>{role}</li>
                                    ))}
                                </ul>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default ListUsers;
