import { dataMembershipTypes } from "../types/dataMembershipTypes";

const dataMembershipInitial = {
    status: "empty",
    reload: new Date().toISOString(),
    loadedYears: [],
    addYear: new Date().getUTCFullYear(),
};
export const dataMembershipReducer = (
    state = dataMembershipInitial,
    action
) => {
    switch (action.type) {
        case dataMembershipTypes.loading:
            return {
                ...state,
                status: "loading",
                data: state.loadedYears.find((year) => state.addYear === year) // If the year is loaded, means it's a fresh load/reload(reset data)
                    ? {}
                    : state.data,
                hotelID: action.hotelID,
            };

        case dataMembershipTypes.partialLoad:
            return {
                ...state,
                status: "partiallyLoaded",
                data: action.payload,
                hotelID: action.hotelID,
            };

        case dataMembershipTypes.load:
            return {
                ...state,
                status: "loaded",
                data: { ...state.data, ...action.payload },
                hotelID: action.hotelID,
                loadedYears: [
                    ...new Set([...state.loadedYears, state.addYear]),
                ],
            };

        case dataMembershipTypes.addYear:
            return {
                ...state,
                status: "addingYear",
                hotelID: action.hotelID,
                addYear: action.year,
            };

        case dataMembershipTypes.reload:
            return {
                ...state,
                status: "reload",
                reload: new Date().toISOString(),
            };

        case dataMembershipTypes.error:
            return {
                ...state,
                status: "error",
                data: action.payload,
                error: action.error,
            };

        case dataMembershipTypes.clean:
            return dataMembershipInitial;

        default:
            return state;
    }
};
