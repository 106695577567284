import Colours, { pastelColours } from "./colours";
import Icons from "./icons";

export const CATEGORY = {
    website: { label: "Website", color: pastelColours[0] },
    ppc: { label: "PPC", color: pastelColours[1] },
    social: { label: "Social", color: pastelColours[2] },
    seo: { label: "SEO", color: pastelColours[3] },
    obe: { label: "OBE", color: pastelColours[4] },
    technical: { label: "Technial", color: pastelColours[5] },
    pr: { label: "PR", color: pastelColours[6] },
    reporting: { label: "Reporting", color: pastelColours[7] },
    email: { label: "Email", color: pastelColours[8] },
};
export const OWNER = {
    Hotel: { label: "Hotel" },
    Aro: { label: "Aro" },
};
export const EVENT_TYPE = {
    Action: { label: "Action" },
    Alert: { label: "Alert" },
};
export const PRIORITY = {
    high: {
        label: "High",
        Icon: Icons.ArrowUp,
        color: Colours.trafficLightRed,
        renderIcon: (sx = {}) => (
            <Icons.ArrowUp sx={{ ...sx, color: Colours.trafficLightRed }} />
        ),
    },
    medium: {
        label: "Medium",
        Icon: Icons.Horizontal,
        color: Colours.trafficLightYellow,
        renderIcon: (sx = {}) => (
            <Icons.Horizontal
                sx={{ ...sx, color: Colours.trafficLightYellow }}
            />
        ),
    },
    low: {
        label: "Low",
        Icon: Icons.ArrowDown,
        color: Colours.retain,
        renderIcon: (sx = {}) => (
            <Icons.ArrowDown sx={{ ...sx, color: Colours.retain }} />
        ),
    },
};
