import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
    Stack,
    Typography,
    Grid,
    IconButton,
    Modal,
    Box,
    Paper,
    Container,
    Select,
} from "@mui/material";

import PillarCard from "../PillarCard";

import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";

// Temporary data source
import data from "../../temp/scoreBoardDataPreDB";
import IndicatorFormNew from "./IndicatorFormNew";

const dataTwo = JSON.parse(data);

// Shows all 4 indicators
const IndicatorsSummary = ({
    pillarsScore,
    basePath,
    handleRefreshIndicators,
}) => {
    const navigate = useNavigate();

    const filterData = (data, filter) => {
        return data.filter(
            (kpi) => kpi.indicator_customer_journey_stage === filter
        );
    };

    const [searchParams] = useSearchParams();
    const [openIndicatorsForm, setOpenIndicatorsForm] = useState(false);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Typography fontWeight={"bold"}>Results</Typography>
                <IconButton onClick={() => setOpenIndicatorsForm(true)}>
                    <Icons.Add />
                </IconButton>
            </Stack>

            <IndicatorFormNew
                edit={true}
                setOpen={setOpenIndicatorsForm}
                open={openIndicatorsForm}
                handleRefreshIndicators={handleRefreshIndicators}
            />
            <br />

            <Grid container alignItems="stretch" spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {" "}
                    <PillarCard
                        PillarName="Attract"
                        PillarScore={pillarsScore["Acquire"]}
                        KPIData={filterData(dataTwo, "Acquire")}
                        circleColor={Colours.attract}
                        expanded={
                            searchParams.get("focus") === "Acquire" ||
                            searchParams.get("focus") === "Attract"
                        }
                        onClick={() => navigate(basePath + "attract")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {" "}
                    <PillarCard
                        PillarName="Engage"
                        PillarScore={pillarsScore["Engage"]}
                        KPIData={filterData(dataTwo, "Engage")}
                        circleColor={Colours.engage}
                        expanded={searchParams.get("focus") === "Engage"}
                        onClick={() => navigate(basePath + "engage")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {" "}
                    <PillarCard
                        PillarName="Convert"
                        PillarScore={pillarsScore["Convert"]}
                        KPIData={filterData(dataTwo, "Convert")}
                        circleColor={Colours.convert}
                        expanded={searchParams.get("focus") === "Convert"}
                        onClick={() => navigate(basePath + "convert")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <PillarCard
                        PillarName="Retain"
                        PillarScore={pillarsScore["Retain"]}
                        KPIData={filterData(dataTwo, "Retain")}
                        circleColor={Colours.retain}
                        expanded={searchParams.get("focus") === "Retain"}
                        onClick={() => navigate(basePath + "retain")}
                    />
                </Grid>
            </Grid>

            <br />
        </>
    );
};

export default IndicatorsSummary;
