import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useSnackbar } from "notistack";
import Axios from "axios";
import {
    Collapse,
    Container,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography,
    Box,
    Divider,
} from "@mui/material";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import HoverPaper from "./shared/HoverPaper";
import CheckShow from "./shared/CheckShow";

import {
    load as load_Bookings,
    partialLoad as partialLoad_Bookings,
    loading as loading_Bookings,
    error as error_Bookings,
} from "../actions/dataBookings";

import {
    ARO_ALL,
    AROLOGO,
    DEFAULT_HOTEL,
    URLAPI,
    HOTELS_INFO,
} from "../configuration";
import {
    dateToUTC,
    months,
    months_abbreviations,
    newDateUTC,
} from "../helpers/dates";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import {
    DATATYPE_CHECKIN,
    DATATYPE_RESDATE,
    GROUP_COUNTRIES,
    GROUP_OFFERS,
    GROUP_ROOM,
    PRESET_LAST_FULL_MONTH,
    PRESET_YEAR_TO_DATE,
    PresetDates,
} from "../helpers/filters";

import ConfirmModal from "./ConfirmModal";
import FiltersReportsArray, {
    BREAK_LINE,
    fields_FiltersTableReport,
    fields_OverviewTable,
    REPORTS,
    TABLE_METRICS,
    TABLE_OVERVIEW,
    CUSTOM,
} from "./FiltersReportsArray";

import TextPulledAgo from "./TextPulledAgo";
import { setHotelID } from "../actions/hotelID";

// Structure of the reports
const MONTHLY_REPORT = [
    {
        type: CUSTOM,
        presetDate: PRESET_LAST_FULL_MONTH,
        ...PresetDates(PRESET_LAST_FULL_MONTH),
        render: ({ js, hotelID }) => {
            let { year, month } = dateToUTC(js.fromDay ?? new Date(), true);
            let monthName = months[month];
            console.log({ js, year, month });
            return (
                <Typography variant="h1" fontSize={"3em"} fontWeight={"bold"}>
                    {`${
                        HOTELS_INFO[hotelID].name ?? ""
                    } Performance report for ${monthName} ${year}`}
                </Typography>
            );
        },
    },
    {
        type: CUSTOM,
        render: () => (
            <Typography
                variant="h2"
                fontSize={"2.5em"}
                style={{ marginTop: "60px" }}
            >
                Bookings
            </Typography>
        ),
    },
    {
        type: TABLE_OVERVIEW,
        title: "Rate Plan - Overview table",
        groupBy: [GROUP_OFFERS],
        dataType: DATATYPE_RESDATE,
        presetDate: PRESET_LAST_FULL_MONTH,
        ...PresetDates(PRESET_LAST_FULL_MONTH),
        ...fields_OverviewTable,
    },
    {
        type: TABLE_OVERVIEW,
        title: "Room Type - Overview table",

        groupBy: [GROUP_ROOM],
        dataType: DATATYPE_RESDATE,
        presetDate: PRESET_LAST_FULL_MONTH,
        ...PresetDates(PRESET_LAST_FULL_MONTH),
        ...fields_OverviewTable,
        sortable: [],
    },
    {
        type: TABLE_OVERVIEW,
        title: "Geographical Market - Overview table",
        groupBy: [GROUP_COUNTRIES],
        dataType: DATATYPE_RESDATE,
        presetDate: PRESET_LAST_FULL_MONTH,
        ...PresetDates(PRESET_LAST_FULL_MONTH),
        ...fields_OverviewTable,
    },
    {
        type: CUSTOM,
        render: () => (
            <Typography variant="h2" fontSize={"2.5em"} marginTop={"20px"}>
                Year to date summary
            </Typography>
        ),
    },
    {
        type: TABLE_METRICS,
        groupBy: [],
        dataType: DATATYPE_RESDATE,
        presetDate: PRESET_YEAR_TO_DATE,
        ...PresetDates(PRESET_YEAR_TO_DATE),
        ...fields_FiltersTableReport,
    },
    // CHECK IN DATA
    {
        type: CUSTOM,
        render: () => (
            <Typography variant="h2" fontSize={"2.5em"} marginTop={"40px"}>
                Checked In
            </Typography>
        ),
    },
    {
        type: TABLE_OVERVIEW,
        title: "Rate Plan - Overview table",
        groupBy: [GROUP_OFFERS],
        dataType: DATATYPE_CHECKIN,
        presetDate: PRESET_LAST_FULL_MONTH,
        ...PresetDates(PRESET_LAST_FULL_MONTH),
        ...fields_OverviewTable,
    },
    {
        type: TABLE_OVERVIEW,
        title: "Room Type - Overview table",
        groupBy: [GROUP_ROOM],
        dataType: DATATYPE_CHECKIN,
        presetDate: PRESET_LAST_FULL_MONTH,
        ...PresetDates(PRESET_LAST_FULL_MONTH),
        ...fields_OverviewTable,
        sortable: [],
    },
    {
        type: TABLE_OVERVIEW,
        title: "Geographical Market - Overview table",
        groupBy: [GROUP_COUNTRIES],
        dataType: DATATYPE_CHECKIN,
        presetDate: PRESET_LAST_FULL_MONTH,
        ...PresetDates(PRESET_LAST_FULL_MONTH),
        ...fields_OverviewTable,
    },
    {
        type: CUSTOM,
        render: () => (
            <Typography variant="h2" fontSize={"2.5em"} marginTop={"20px"}>
                Year to date summary
            </Typography>
        ),
    },
    {
        type: TABLE_METRICS,
        groupBy: [],
        dataType: DATATYPE_CHECKIN,
        presetDate: PRESET_YEAR_TO_DATE,
        ...PresetDates(PRESET_YEAR_TO_DATE),
        ...fields_FiltersTableReport,
    },
];

// Each report array and what it needs to work
const PDF_TEMPLATES = {
    MONTHLYREPORT: MONTHLY_REPORT,
};

/**
 * Renders an array of reports paired to the reportId, and enables the PDF download for it.
 *
 * - Right now only works a standalone for monthly email pdf generator, but it can be further developed to
 * present several PDFReport components in a list in PDFExportScreen under /pdfexport, making them collapsible.
 * TODO: To further develop into front access within a list of reports:
 * - Connect authorization and data retrieval with the live session.
 * - Allow PDF download only in standalone mode(for better pdf visual) enabling open external window to /pdfexport/reportID
 * - Specific management of each report array, it's required data and required visual structure(optional if we do it with FiltersReportsArray)
 *
 * @param {string} reportId id of the report template to use
 * @param {boolean} isCollapsible wether the report can be collapsed (is in a list) or not (is a standalone)
 * @returns FiltersArrayReports with the Reports under the pdf template chosen(reportId)
 */
const PDFReport = ({ reportId, isCollapsible = false }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [searchParams] = useSearchParams();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [retries, setRetries] = useState(3); // Max of retries to attempt
    const [fileName, setFileName] = useState("report.pdf");

    const [confirmModal, setConfirmModal] = useState(false);
    const { auth } = useSelector((state) => state);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [reports, setReports] = useState([]);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [isFrontAccess, setIsFrontAccess] = useState(false);
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (retries <= 0) return;

        let auxToken = null;
        let auxHotelID = DEFAULT_HOTEL;

        if (auth.user && auth.user.jwt) {
            setIsFrontAccess(true);
            console.log("Is front access");
        }

        // Accesing via back, automatized
        let auxToDate = newDateUTC();
        let auxFromDate = newDateUTC(auxToDate.getUTCFullYear() - 1, 0, 1)
            .toISOString()
            .slice(0, 10);
        auxToDate = auxToDate.toISOString().slice(0, 10);

        if (searchParams.size > 0) {
            auxToken = searchParams.get("token") ?? auxToken;
            auxHotelID = searchParams.get("hotelid") ?? auxHotelID;
            dispatch(setHotelID(auxHotelID));

            // This data retrieval switch: based on the reportId, do X data petition
            let args = JSON.stringify({
                from: auxFromDate,
                to: auxToDate,
                dateBy: "ResDate",
            });
            Axios({
                method: "post",
                url: `${URLAPI}/reservations/${auxHotelID}`,
                data: args,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auxToken}`,
                },
                timeout: 5000,
            })
                .then((response) => {
                    const {
                        data: { data = [], last_ResDate },
                    } = response;

                    dispatch(
                        partialLoad_Bookings(data, auxHotelID, last_ResDate)
                    );
                    setDataLoaded(true);
                })
                .catch((error_) => {
                    setRetries((prev) => prev - 1);
                    dispatch(error_Bookings(error_, {}));
                    console.error(error_);
                });
        }
        let { year, month } = dateToUTC(auxFromDate, true);
        let monthName = months[month];
        let auxFileName = `${auxHotelID}-${reportId} ${monthName} ${year}.pdf`;

        setFileName(auxFileName);
        setToken(auxToken);
    }, [searchParams, retries]);

    const generatePDF = () => {
        const element = document.getElementById("content"); //  TODO: Include content before/after, or inter-sections

        const sections = element.querySelectorAll(".page-section");

        const pdf = new jsPDF("p", "mm", "a4");
        const pageHeight = 297; // A4 page height in mm
        const imgWidth = 210; // A4 page width in mm
        const imgWidth_px = (imgWidth / 25.4) * 96; // A4 page width in px

        let currentPosition = 0;
        let spaceTop = 7;

        const processSection = (section, callback) => {
            const originalStyles = section.style.width;
            section.style.width = "145%";
            html2canvas(section, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg"); //"image/png");
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                currentPosition += spaceTop;

                section.style.width = originalStyles;

                const endingPosition = currentPosition + imgHeight;

                if (endingPosition > pageHeight) {
                    pdf.addPage();
                    currentPosition = spaceTop;
                }

                pdf.addImage(
                    imgData,
                    "JPEG",
                    0,
                    currentPosition,
                    imgWidth,
                    imgHeight
                );
                currentPosition += imgHeight;

                callback();
            });
        };

        const processSections = (index) => {
            if (index < sections.length) {
                processSection(sections[index], () =>
                    processSections(index + 1)
                );
            } else {
                pdf.save(fileName); // Save PDF when all sections have been processed
            }
        };

        processSections(0); // Start processing from the first section
    };

    useEffect(() => {
        let auxReports = [];
        if (PDF_TEMPLATES.hasOwnProperty(reportId)) {
            let auxReportSection = [];
            PDF_TEMPLATES[reportId].forEach((report, index) => {
                auxReportSection.push(report);
                if (report.type !== CUSTOM) {
                    auxReports.push(renderReports(auxReportSection));
                    auxReportSection = [];
                }
            });
        }
        setReports(auxReports);
    }, [reportId, dataLoaded]);

    const renderReports = (reportSection) => {
        return (
            <div class="page-section">
                <div
                    style={{
                        marginTop: 40,
                        marginLeft: 40,
                        marginRight: 40,
                    }}
                >
                    <FiltersReportsArray reports={reportSection} PDF={true} />
                </div>
            </div>
        );
    };

    return (
        <>
            <Container
                component={HoverPaper}
                sx={{ p: 3 }}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Box sx={{ display: "flex" }} alignItems="center">
                        {dataLoaded && (
                            <div
                                id="pdf-download-button"
                                onClick={generatePDF}
                                style={{ cursor: "pointer" }}
                            >
                                PDF Download
                            </div>
                        )}
                    </Box>
                </Stack>
            </Container>
            <br />
            {reports.length > 0 &&
                (isCollapsible ? (
                    <Collapse in={!isCollapsible || !isCollapsed}>
                        <Container>
                            <TextPulledAgo />
                            <FiltersReportsArray reports={reports} PDF={true} />
                        </Container>
                    </Collapse>
                ) : (
                    <div id="content">
                        <Container
                            maxWidth
                            sx={{
                                paddingTop: 2,
                            }}
                        >
                            <TextPulledAgo />{" "}
                            {/**Not being included atm. Think about more custom render adding X sections or special blocks */}
                            {reports}
                        </Container>
                    </div>
                ))}
        </>
    );
};

export default PDFReport;
