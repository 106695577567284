import React from "react";
import { Fab } from "@mui/material";

const CustomFab = ({
    Icon,
    positionX = 0,
    positionY = 0,
    onClick = () => {},
    ...otherProps
}) => {
    return (
        <Fab
            size="small"
            sx={{
                position: "fixed",
                bottom: positionY * 55 + 15,
                right: positionX * 50 + 10,
            }}
            onClick={onClick}
            {...otherProps}
        >
            <Icon size="small" />
        </Fab>
    );
};
export default CustomFab;
