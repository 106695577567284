import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Paper,
    Popover,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import { getYearsBefore, months } from "../helpers/dates";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

import { styled } from "@mui/material/styles";

import { update } from "../actions/settings";

export const lastYearButton = () =>
    new Date(new Date().getFullYear() - 1, new Date().getMonth(), 15);
export const currentYearButton = () =>
    new Date(new Date().getFullYear(), new Date().getMonth(), 15);
export const nextYearButton = () =>
    new Date(new Date().getFullYear() + 1, new Date().getMonth(), 15);

export const lastMonthButton = () =>
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 15);

export const currentMonthButton = () =>
    new Date(new Date().getFullYear(), new Date().getMonth(), 15);

export const nextMonthButton = () =>
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 15);

export const lastDayButton = () =>
    new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
    );

const DateSelector = ({
    collapse = false,
    deactivated = false,
    setTime = () => {},
    time = new Date(),
    fullYear = false,
    setFullYear = () => {},
    minDate = new Date(
        new Date().getUTCFullYear() - getYearsBefore().length + 2,
        0,
        1
    ),
    maxDate = new Date(new Date().getUTCFullYear() + 2, 0, 0),
    year = true,
    month = true,
    day = true,
    showCustom = true,
    buttons = [
        { label: "Current Year", value: currentYearButton(), fullYear: true },
        { label: "Last Year", value: lastYearButton(), fullYear: true },
        {
            label: "Current Month",
            value: currentMonthButton(),
            fullYear: false,
        },
        { label: "Last Month", value: lastMonthButton(), fullYear: false },
    ],
}) => {
    const {
        user: { jwt, user_id },
    } = useSelector((state) => state.auth);

    const [selectedInterval, setSelectedInterval] = useState("Current Month");

    const [views, setViews] = useState([]);
    const [label, setLabel] = useState("");
    const [value, setValue] = useState("");

    const dispatch = useDispatch();

    const updateTime = (e) => {
        dispatch(update("time", e.toISOString().slice(0, 10), jwt, user_id));
        setTime(e);
    };

    const updateFullYear = (e) => {
        dispatch(update("fullYear", e ? 1 : 0, jwt, user_id));
        setFullYear(e);
    };

    const change = (date, full) => {
        if (day) return;
        updateTime(date);
        updateFullYear(full);
    };

    const updateProps = () => {
        let auxViews = [];
        let auxLabel = "";
        let auxValue = "";
        if (fullYear) {
            auxViews = ["year"];
            auxLabel = "Year";
            auxValue = `${time.getUTCFullYear()}`;
        } else {
            if (year) {
                auxViews.push("year");
                auxValue += `${time.getUTCFullYear()}`;
                auxLabel = `Year ${auxLabel}`;
            }
            if (month) {
                auxViews.push("month");
                auxValue = `${months[time.getUTCMonth()]} ${auxValue}`;
                auxLabel = `Month ${auxLabel}`;
            }
            if (day) {
                auxViews.push("day");
                auxValue = `${time.getDate()} ${auxValue}`;
                auxLabel = `Day ${auxLabel}`;
            }

            auxLabel = auxViews
                .map((word, index) => {
                    let date = word.charAt(0).toUpperCase() + word.slice(1);
                    if (auxViews.length >= 2 && index === 1) date += " and";
                    return date;
                })
                .reverse()
                .join(" ");
        }
        setViews(auxViews);
        setLabel(auxLabel);
        setValue(auxValue);

        setSelectedButton(fullYear, time);
    };

    useEffect(() => {
        updateProps();
    }, []);

    useEffect(() => {
        updateProps();
    }, [fullYear, time]);

    const setSelectedButton = (fullYear, time) => {
        let selected = "Custom Date";

        (buttons === "default"
            ? DateSelector.defaultProps.buttons
            : buttons
        ).forEach(({ label, value, fullYear: fullyear }) => {
            if (fullYear !== fullyear) {
                return;
            }

            let aux_value = "";
            let aux_this = "";

            if (fullYear) {
                aux_value = value.getFullYear();
                aux_this = time.getFullYear();
            } else {
                aux_value = value.toISOString().slice(0, 10);

                if (day) {
                    aux_this = new Date(
                        time.getFullYear(),
                        time.getMonth(),
                        time.getDate()
                    )
                        .toISOString()
                        .slice(0, 10);
                } else {
                    aux_this = new Date(time.getFullYear(), time.getMonth(), 15)
                        .toISOString()
                        .slice(0, 10);
                }
            }

            if (aux_value === aux_this) selected = label;
        });
        setSelectedInterval(selected);
    };

    const StyledStack = styled(Stack)(({ theme }) => ({
        backgroundColor: Colours.tableRowBackground,
        borderRadius: !collapse ? "50px" : "15px",
        "& .MuiButtonBase-root": {
            borderColor: Colours.tableRowBackground,
            color: "black",
            borderRadius: "50px",
            "&:hover": {
                backgroundColor: Colours.secondarySoft,
            },
            textTransform: "none",
        },
        "& .Mui-selected": {
            backgroundColor: Colours.secondary,
            color: "black",
        },
    }));

    const setSelected = (value) => {
        const style =
            value === selectedInterval
                ? {
                      backgroundColor: Colours.secondary,
                      color: "black",
                  }
                : {};

        return style;
    };

    return (
        <StyledStack
            direction={collapse ? "column" : "row"}
            justifyContent="center"
            alignItems={collapse ? "column" : "row"}
            spacing={0}
            sx={
                deactivated
                    ? { pointerEvents: "none", cursor: "auto", opacity: "0.5" }
                    : {}
            }
        >
            {(buttons === "default"
                ? DateSelector.defaultProps.buttons
                : buttons
            ).map(({ label, value, fullYear: fullyear }) => (
                <Button
                    key={label}
                    onClick={() => change(value, fullyear)}
                    style={setSelected(label)}
                    sx={{
                        marginRight: !collapse ? "0.25em" : "",
                        pr: 2,
                        pl: 2,
                    }}
                    size="small"
                >
                    <Typography variant="caption">{label}</Typography>
                </Button>
            ))}
            {showCustom && (
                <CustomDateDropdown
                    collapse={collapse}
                    fullYear={fullYear}
                    setFullYear={setFullYear}
                    setTime={setTime}
                    views={views}
                    label={label}
                    value={value}
                    time={time}
                    minDate={minDate}
                    maxDate={maxDate}
                    isSelected={
                        selectedInterval === "Custom Date" ? true : false
                    }
                />
            )}
        </StyledStack>
    );
};

DateSelector.defaultProps = {
    collapse: false,
    setTime: () => {},
    time: new Date(),
    fullYear: false,
    setFullYear: () => {},
    minDate: new Date(
        new Date().getUTCFullYear() - getYearsBefore().length + 2,
        0,
        1
    ),
    maxDate: new Date(new Date().getUTCFullYear() + 2, 0, 0),
    year: true,
    month: true,
    day: true,
    showCustom: true,
    buttons: [
        { label: "Current Year", value: currentYearButton(), fullYear: true },
        { label: "Last Year", value: lastYearButton(), fullYear: true },
        {
            label: "Current Month",
            value: currentMonthButton(),
            fullYear: false,
        },
        { label: "Last Month", value: lastMonthButton(), fullYear: false },
    ],
};

const CustomDateDropdown = ({
    collapse,
    fullYear,
    setFullYear,
    setTime,
    views,
    label,
    value,
    time,
    minDate,
    maxDate,
    isSelected,
}) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // const [filterBy, setFilterBy] = useState("Custom Date");

    const OPTIONS = {
        "Custom Date": {
            onClick: (
                <Stack
                    direction={"column"}
                    justifyContent="center"
                    alignItems={"center"}
                    spacing={2}
                    sx={{ padding: 1, maxWidth: "300px" }}
                >
                    {setFullYear !== DateSelector.defaultProps.setFullYear ? (
                        <FormControlLabel
                            label="Full Year"
                            control={
                                <Checkbox
                                    checked={fullYear}
                                    onChange={() => setFullYear(!fullYear)}
                                />
                            }
                        />
                    ) : (
                        ""
                    )}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            style={{
                                "& .MuiPickersCalendarHeader-label css-dplwbx-MuiPickersCalendarHeader-label":
                                    {
                                        minWidth:
                                            "200px" /* Adjust as needed */,
                                    },
                            }}
                            closeOnSelect={true}
                            views={views}
                            label={label}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(v) => {
                                if (collapse) {
                                    setTime(new Date(v));
                                }
                            }}
                            onAccept={(v) => {
                                setTime(new Date(v));
                            }}
                            value={time}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    helperText={null}
                                    onKeyDown={(e) => e.preventDefault()}
                                    inputProps={{
                                        ...params.inputProps,
                                        value: value,
                                    }}
                                ></TextField>
                            )}
                        />
                    </LocalizationProvider>
                </Stack>
            ),
        },
    };

    return (
        <>
            <Button
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                }}
                style={
                    isSelected
                        ? {
                              backgroundColor: Colours.secondary,
                              color: "black",
                          }
                        : {}
                }
                // style={setSelected("Custom")}
                sx={{
                    pl: 2,
                }}
            >
                <Typography variant="caption">Custom</Typography>
                {open ? (
                    <Icons.ArrowUp sx={{ fontSize: "1.5em" }} />
                ) : (
                    <Icons.ArrowDown sx={{ fontSize: "1.5em" }} />
                )}
            </Button>
            <Popover
                sx={{ paddingx: 1 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                anchorEl={anchorEl}
                PaperProps={{ elevation: 3 }}
            >
                <Paper>
                    <Stack
                        direction={"column"}
                        divider={
                            <Divider
                                orientation="horizontal"
                                flexItem
                                sx={{
                                    borderColor: Colours.navbarBottomLine,
                                }}
                            />
                        }
                    >
                        {Object.keys(OPTIONS).map((opt) => (
                            <div key={opt}>
                                <div
                                    onClick={() => {
                                        // setFilterBy(opt);
                                        if (opt !== "Custom Date")
                                            OPTIONS[opt].onClick();
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        // backgroundColor:
                                        //     filterBy === opt
                                        //         ? Colours.secondarySoft
                                        //         : Colours.plainWhite,
                                    }}
                                >
                                    {OPTIONS["Custom Date"].onClick}
                                </div>
                            </div>
                        ))}
                    </Stack>
                </Paper>
            </Popover>
        </>
    );
};

export default DateSelector;
