import { indicatorTypes } from "../types/indicatorTypes";

export const indicatorReducer = (state = {}, action) => {
    switch (action.type) {
        case indicatorTypes.read:
            return action.payload;

        case indicatorTypes.clean:
            return {};

        default:
            return state;
    }
};
