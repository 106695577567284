import { preferencesTypes } from "../types/preferencesTypes";

export const toggleThemeMode = () => {
    return async (dispatch) => {
        dispatch({
            type: preferencesTypes.toggleThemeMode,
            payload: {},
        });
    };
};
