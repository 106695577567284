import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

const CircleSkeleton = () => (
    <TableCell sx={{ textAlign: "-webkit-center" }}>
        <Skeleton variant="circular" height="2.5em" width={"2.5em"} />
    </TableCell>
);

const InsightAssistRowDesktopLoading = ({
    times = 1,
    redAlerts = false,
    hideWeights = false,
}) => {
    return (
        <>
            {[...Array(times).keys()].map((element) => (
                <TableRow key={element}>
                    {redAlerts && (
                        <TableCell>
                            <Skeleton
                                variant="text"
                                height="2.5em"
                                width="100%"
                            />
                        </TableCell>
                    )}
                    <TableCell>
                        <Skeleton variant="text" height="2.5em" width="100%" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" height="2.5em" />
                    </TableCell>
                    <CircleSkeleton />
                    {!hideWeights && (
                        <>
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                        </>
                    )}
                    <TableCell>
                        <Skeleton variant="text" height="2.5em" />
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};

export default InsightAssistRowDesktopLoading;
