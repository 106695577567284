import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "notistack";

import { loadOne } from "../../actions/PDFExport";
import { AROLOGO } from "../../configuration";
import "../../styles/print.css";

const WrapperPDFTable = ({ children, k }) => {
    const dispatch = useDispatch();
    const printRef = useRef();
    const toggleButton = useSelector((state) => state.PDFExport.toggleButton);
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { enqueueSnackbar } = useSnackbar();

    const handleSaveForPrint = () => {
        const html = printRef.current.innerHTML;
        const tableSnap = html;
        dispatch(loadOne(tableSnap, k, hotelID));
        enqueueSnackbar(`Added report to printing list: ${k}`, {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            },
            variant: "success",
            autoHideDuration: 2000,
        });
    };

    const addTitleForPrint = () => {
        const printContents = printRef.current;
        const titleElement = document.createElement("h2");
        titleElement.innerText = k;
        titleElement.id = "print-title";
        printContents.prepend(titleElement);
    };

    const removeTitleAfterPrint = () => {
        const titleElement = document.getElementById("print-title");
        if (titleElement) {
            titleElement.remove();
        }
    };

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "PDF-Generated",
        onBeforeGetContent: addTitleForPrint,
        onAfterPrint: removeTitleAfterPrint,
    });

    return (
        <div>
            {toggleButton && (
                <>
                    <button onClick={handleSaveForPrint}>
                        Add to printing list
                    </button>
                    <button onClick={handlePrint}>Print this</button>
                </>
            )}

            <div ref={printRef}>
                <div className="watermark">
                    <img src={AROLOGO} width={"90%"} />
                </div>
                {children}
            </div>
        </div>
    );
};

export default WrapperPDFTable;
