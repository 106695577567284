import React from "react";
import { Route, Routes } from "react-router-dom";

import SalesSummary from "../components/SalesSummary";
import EventsCalendarScreen from "../pages/EventsCalendarScreen";
import AIScreen from "../pages/AIScreen";
import InsightsAssistScreen from "../pages/InsightsAssistScreen";
import IndicatorsScreen from "../pages/IndicatorsScreen";

const AroPlusScreen = () => {
    return (
        <>
            <Routes>
                <Route path="" element={<SalesSummary />} />
                <Route path="sales" element={<SalesSummary />} />
                <Route
                    path="indicators/*"
                    element={
                        <IndicatorsScreen basePath="/app/plus/indicators/" />
                    }
                />

                <Route
                    path="events"
                    element={<EventsCalendarScreen isolated />}
                />
                <Route path="ai" element={<AIScreen />} />
                <Route path="red2greens" element={<InsightsAssistScreen />} />
            </Routes>
        </>
    );
};
export default AroPlusScreen;
