import React, { useState } from "react";

import EChartsReact from "echarts-for-react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";

import {
    DATATYPE_CHECKIN,
    DATATYPE_CHECKOUT,
    DATATYPE_RESDATE,
} from "../helpers/filters";

const HeatMapCalendar = ({ data }) => {
    const [dataTypeNormal, setDataTypeNormal] = useState(DATATYPE_CHECKIN);
    const [dataTypeLY, setDataTypeLY] = useState(DATATYPE_CHECKIN);

    const getHeatMapData = (entries, dateType, type) => {
        const countMap = new Map();
        entries.data
            .filter((e) => e.period === type)
            .forEach((entry) => {
                const date = new Date(entry[dateType])
                    .toISOString()
                    .split("T")[0];
                if (countMap.has(date)) {
                    countMap.set(date, countMap.get(date) + 1);
                } else {
                    countMap.set(date, 1);
                }
            });
        return Array.from(countMap, ([date, count]) => [date, count]);
    };

    const getOption = (data, min, max) => {
        return {
            tooltip: {
                position: "top",
            },
            visualMap: {
                min: 0,
                max: Math.max(...data.map((item) => item[1])),
                calculable: true,
                orient: "vertical",
                left: "670",
                top: "center",
            },
            calendar: [
                {
                    yearLabel: { show: true },
                    orient: "vertical",
                    range: [min, max],
                    monthLabel: {
                        formatter: (value, index) => {
                            return `${value.nameMap}${value.yy}`;
                        },
                    },
                },
            ],
            series: [
                {
                    type: "heatmap",
                    coordinateSystem: "calendar",
                    data: data,
                },
            ],
        };
    };

    return (
        <>
            <Box>
                <Select
                    labelId="data-type-normal-label"
                    value={dataTypeNormal}
                    label="Data Type This Year"
                    onChange={(e) => setDataTypeNormal(e.target.value)}
                >
                    <MenuItem value={DATATYPE_CHECKIN}>Check-In</MenuItem>
                    <MenuItem value={DATATYPE_CHECKOUT}>Check-Out</MenuItem>
                    <MenuItem value={DATATYPE_RESDATE}>ResDate</MenuItem>
                </Select>
                <Select
                    labelId="data-type-last-label"
                    value={dataTypeLY}
                    label="Data Type Last Year"
                    onChange={(e) => setDataTypeLY(e.target.value)}
                >
                    <MenuItem value={DATATYPE_CHECKIN}>Check-In</MenuItem>
                    <MenuItem value={DATATYPE_CHECKOUT}>Check-Out</MenuItem>
                    <MenuItem value={DATATYPE_RESDATE}>ResDate</MenuItem>
                </Select>
            </Box>
            {Object.entries(data).map(([key, values]) => {
                const heatMapData = getHeatMapData(
                    values,
                    dataTypeNormal,
                    "thisYear"
                );
                const heatMapDataLY = getHeatMapData(
                    values,
                    dataTypeLY,
                    "lastYear"
                );

                const min = new Date(
                    Math.min(
                        Math.min(
                            ...heatMapData.map((item) => new Date(item[0]))
                        ),
                        Math.min(
                            ...heatMapDataLY.map((item) => new Date(item[0]))
                        )
                    )
                );
                const max = new Date(
                    Math.max(
                        Math.max(
                            ...heatMapData.map((item) => new Date(item[0]))
                        ),
                        Math.max(
                            ...heatMapDataLY.map((item) => new Date(item[0]))
                        )
                    )
                );
                return (
                    <div key={key} style={{ overflowX: "auto" }}>
                        <div
                            style={{
                                width: "100%",
                                maxHeight: 1500,
                                overflowY: "auto",
                            }}
                        >
                            <Typography variant="h6">{key}</Typography>
                            <Stack direction="row" sx={{ height: 10000 }}>
                                {heatMapData.length > 0 && (
                                    <>
                                        This
                                        <EChartsReact
                                            style={{
                                                height: "auto",
                                                width: 300,
                                            }}
                                            option={getOption(
                                                heatMapData,
                                                min,
                                                max
                                            )}
                                        />
                                    </>
                                )}
                                {heatMapDataLY.length > 0 && (
                                    <>
                                        Comparison
                                        <EChartsReact
                                            style={{
                                                height: "auto",
                                                width: 300,
                                            }}
                                            option={getOption(
                                                heatMapDataLY,
                                                min,
                                                max
                                            )}
                                        />
                                    </>
                                )}
                            </Stack>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default HeatMapCalendar;
