import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
    TableContainer,
} from "@mui/material";

import WrapperPDFTable from "../shared/WrapperPDFTable";
import SmallPercentage from "../SmallPercentage";
import TableHeaderCell from "../TableHeaderCell";
import TableBodyCell from "../TableBodyCell";

import { getYearsBefore, months } from "../../helpers/dates";
import { fns } from "../../helpers/common";
import Colours from "../../helpers/colours";

const CalcPercentage = (year, data, key, context, formatter) => {
    const latestYear = Object.keys(data).sort().reverse()[0];
    const actualYear = new Date().getUTCFullYear();

    let actual = 0;
    let old = 0;

    if (Object.keys(data).includes(actualYear)) {
        if (year === String(actualYear)) return;

        actual = (data[actualYear] ?? {})[key] ?? 0;
        old = (data[year] ?? {})[key] ?? 0;
    } else {
        if (year === String(latestYear)) return;

        actual = (data[latestYear] ?? {})[key] ?? 0;
        old = (data[year] ?? {})[key] ?? 0;
    }

    return (
        <SmallPercentage
            actual={actual}
            old={old}
            isInverted={key === "revenueCancelled" ? true : false}
            context={context}
            formatter={formatter}
        />
    );
};

const MonthlyRoomsTableReport = ({ data, dataYears, fullYear, monthN }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [dataByYear, setDataByYear] = useState({});
    const [yearKeys, setYearKeys] = useState([]);

    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});

    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const [selectedData, setSelectedData] = useState({});

    useEffect(() => {
        if (data) {
            const [
                January,
                February,
                March,
                April,
                May,
                June,
                July,
                August,
                September,
                October,
                November,
                December,
            ] = data;
            setDataByJanuary(January);
            setDataByFebruary(February);
            setDataByMarch(March);
            setDataByApril(April);
            setDataByMay(May);
            setDataByJune(June);
            setDataByJuly(July);
            setDataByAugust(August);
            setDataBySeptember(September);
            setDataByOctober(October);
            setDataByNovember(November);
            setDataByDecember(December);
        }
    }, [data]);

    useEffect(() => {
        if (dataYears) {
            setDataByYear(dataYears);
        }
    }, [dataYears]);

    useEffect(() => {
        setYearKeys(Object.keys(selectedData).sort().reverse());
    }, [selectedData]);

    useEffect(() => {
        if (fullYear) setSelectedData(dataByYear);
        else {
            let option = months[monthN];
            switch (option) {
                case "January":
                    setSelectedData(dataByJanuary);
                    break;
                case "February":
                    setSelectedData(dataByFebruary);
                    break;
                case "March":
                    setSelectedData(dataByMarch);
                    break;
                case "April":
                    setSelectedData(dataByApril);
                    break;
                case "May":
                    setSelectedData(dataByMay);
                    break;
                case "June":
                    setSelectedData(dataByJune);
                    break;
                case "July":
                    setSelectedData(dataByJuly);
                    break;
                case "August":
                    setSelectedData(dataByAugust);
                    break;
                case "September":
                    setSelectedData(dataBySeptember);
                    break;
                case "October":
                    setSelectedData(dataByOctober);
                    break;
                case "November":
                    setSelectedData(dataByNovember);
                    break;
                case "December":
                    setSelectedData(dataByDecember);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthN, fullYear, dataByYear, ...monthsData]);
    return (
        <>
            <br />
            <TableContainer style={{ overflow: "auto", maxHeight: "600px" }}>
                <WrapperPDFTable k="Monthly Rooms Revenue">
                    <Table>
                        {/*  _   _ _____    _    ____   */}
                        {/* | | | | ____|  / \  |  _ \  */}
                        {/* | |_| |  _|   / _ \ | | | | */}
                        {/* |  _  | |___ / ___ \| |_| | */}
                        {/* |_| |_|_____/_/   \_\____/  */}
                        <TableHead>
                            {yearKeys.length > 0 ? (
                                <TableRow>
                                    <TableCell
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 3,
                                            backgroundColor: Colours.plainWhite,
                                        }}
                                    ></TableCell>
                                    {yearKeys.map((year) => (
                                        <TableHeaderCell
                                            key={year}
                                            style={{
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 3,
                                                backgroundColor:
                                                    Colours.plainWhite,
                                            }}
                                        >
                                            {fullYear
                                                ? year
                                                : `${months[monthN].slice(
                                                      0,
                                                      3
                                                  )} ${year}`}
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            ) : (
                                <TableRow>
                                    <TableCell></TableCell>
                                    {[...getYearsBefore()].map((e) => (
                                        <TableCell key={e}>
                                            <Skeleton />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )}
                        </TableHead>
                        {/*  ____   _____        ______   */}
                        {/* |  _ \ / _ \ \      / / ___|  */}
                        {/* | |_) | | | \ \ /\ / /\___ \  */}
                        {/* |  _ <| |_| |\ V  V /  ___) | */}
                        {/* |_| \_\\___/  \_/\_/  |____/  */}
                        <TableBody>
                            {yearKeys.length > 0 ? (
                                <>
                                    <TableRow>
                                        <TableHeaderCell
                                            style={
                                                isMobile
                                                    ? {
                                                          position: "sticky",
                                                          left: 0,
                                                          zIndex: 1,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                                    : {}
                                            }
                                        >
                                            Bookings
                                        </TableHeaderCell>
                                        {yearKeys.map((year) => (
                                            <TableBodyCell key={year}>
                                                {fns(
                                                    hotelID,
                                                    (selectedData[year] ?? {})[
                                                        "bookings"
                                                    ] ?? 0
                                                )}
                                                {CalcPercentage(
                                                    year,
                                                    selectedData,
                                                    "bookings",
                                                    fullYear
                                                        ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                        : `Comparing ${new Date().getUTCFullYear()}-${
                                                              months[monthN]
                                                          } vs ${year}-${
                                                              months[monthN]
                                                          } `,
                                                    (number) =>
                                                        fns(hotelID, number)
                                                )}
                                            </TableBodyCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableHeaderCell
                                            style={
                                                isMobile
                                                    ? {
                                                          position: "sticky",
                                                          left: 0,
                                                          zIndex: 1,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                                    : {}
                                            }
                                        >
                                            Room Nights
                                        </TableHeaderCell>
                                        {yearKeys.map((year) => (
                                            <TableBodyCell key={year}>
                                                {fns(
                                                    hotelID,
                                                    (selectedData[year] ?? {})[
                                                        "roomNights"
                                                    ] ?? 0
                                                )}
                                                {CalcPercentage(
                                                    year,
                                                    selectedData,
                                                    "roomNights",
                                                    fullYear
                                                        ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                        : `Comparing ${new Date().getUTCFullYear()}-${
                                                              months[monthN]
                                                          } vs ${year}-${
                                                              months[monthN]
                                                          } `,
                                                    (number) =>
                                                        fns(hotelID, number)
                                                )}
                                            </TableBodyCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableHeaderCell
                                            style={
                                                isMobile
                                                    ? {
                                                          position: "sticky",
                                                          left: 0,
                                                          zIndex: 1,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                                    : {}
                                            }
                                        >
                                            Revenue
                                        </TableHeaderCell>
                                        {yearKeys.map((year) => (
                                            <TableBodyCell key={year}>
                                                {fns(
                                                    hotelID,
                                                    (selectedData[year] ?? {})[
                                                        "revenue"
                                                    ] ?? 0,
                                                    {
                                                        left: "currency",
                                                    }
                                                )}
                                                {CalcPercentage(
                                                    year,
                                                    selectedData,
                                                    "revenue",
                                                    fullYear
                                                        ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                        : `Comparing ${new Date().getUTCFullYear()}-${
                                                              months[monthN]
                                                          } vs ${year}-${
                                                              months[monthN]
                                                          } `,
                                                    (number) =>
                                                        fns(hotelID, number, {
                                                            left: "currency",
                                                        })
                                                )}
                                            </TableBodyCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableHeaderCell
                                            style={
                                                isMobile
                                                    ? {
                                                          position: "sticky",
                                                          left: 0,
                                                          zIndex: 1,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                                    : {}
                                            }
                                        >
                                            Revenue Cancelled
                                        </TableHeaderCell>
                                        {yearKeys.map((year) => (
                                            <TableBodyCell key={year}>
                                                {fns(
                                                    hotelID,
                                                    (selectedData[year] ?? {})[
                                                        "revenueCancelled"
                                                    ] ?? 0,
                                                    {
                                                        left: "currency",
                                                    }
                                                )}
                                                {CalcPercentage(
                                                    year,
                                                    selectedData,
                                                    "revenueCancelled",
                                                    fullYear
                                                        ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                        : `Comparing ${new Date().getUTCFullYear()}-${
                                                              months[monthN]
                                                          } vs ${year}-${
                                                              months[monthN]
                                                          } `,
                                                    (number) =>
                                                        fns(hotelID, number, {
                                                            left: "currency",
                                                        })
                                                )}
                                            </TableBodyCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <Tooltip title="Average Daily Rate">
                                            <TableHeaderCell
                                                style={
                                                    isMobile
                                                        ? {
                                                              position:
                                                                  "sticky",
                                                              left: 0,
                                                              zIndex: 1,
                                                              backgroundColor:
                                                                  Colours.plainWhite,
                                                          }
                                                        : {}
                                                }
                                            >
                                                ADR
                                            </TableHeaderCell>
                                        </Tooltip>
                                        {yearKeys.map((year) => (
                                            <TableBodyCell key={year}>
                                                {fns(
                                                    hotelID,
                                                    (selectedData[year] ?? {})[
                                                        "ADR"
                                                    ] ?? 0,
                                                    {
                                                        left: "currency",
                                                    }
                                                )}
                                                {CalcPercentage(
                                                    year,
                                                    selectedData,
                                                    "ADR",
                                                    fullYear
                                                        ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                        : `Comparing ${new Date().getUTCFullYear()}-${
                                                              months[monthN]
                                                          } vs ${year}-${
                                                              months[monthN]
                                                          } `,
                                                    (number) =>
                                                        fns(hotelID, number, {
                                                            left: "currency",
                                                        })
                                                )}
                                            </TableBodyCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <Tooltip title="Average Length of Stay">
                                            <TableHeaderCell
                                                style={
                                                    isMobile
                                                        ? {
                                                              position:
                                                                  "sticky",
                                                              left: 0,
                                                              zIndex: 1,
                                                              backgroundColor:
                                                                  Colours.plainWhite,
                                                          }
                                                        : {}
                                                }
                                            >
                                                ALoS
                                            </TableHeaderCell>
                                        </Tooltip>
                                        {yearKeys.map((year) => (
                                            <TableBodyCell key={year}>
                                                {fns(
                                                    hotelID,
                                                    (selectedData[year] ?? {})[
                                                        "ALoS"
                                                    ] ?? 0,
                                                    {
                                                        maxDecimals: 2,
                                                        right: " days",
                                                    }
                                                )}
                                                {CalcPercentage(
                                                    year,
                                                    selectedData,
                                                    "ALoS",
                                                    fullYear
                                                        ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                        : `Comparing ${new Date().getUTCFullYear()}-${
                                                              months[monthN]
                                                          } vs ${year}-${
                                                              months[monthN]
                                                          } `,
                                                    (number) =>
                                                        fns(hotelID, number, {
                                                            maxDecimals: 2,
                                                            right: " days",
                                                        })
                                                )}
                                            </TableBodyCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <Tooltip title="Average Booking Window">
                                            <TableHeaderCell
                                                style={
                                                    isMobile
                                                        ? {
                                                              position:
                                                                  "sticky",
                                                              left: 0,
                                                              zIndex: 1,
                                                              backgroundColor:
                                                                  Colours.plainWhite,
                                                          }
                                                        : {}
                                                }
                                            >
                                                ABW
                                            </TableHeaderCell>
                                        </Tooltip>
                                        {yearKeys.map((year) => (
                                            <TableBodyCell key={year}>
                                                {fns(
                                                    hotelID,
                                                    (selectedData[year] ?? {})[
                                                        "ABW"
                                                    ] ?? 0,
                                                    {
                                                        right: " days",
                                                    }
                                                )}
                                                {CalcPercentage(
                                                    year,
                                                    selectedData,
                                                    "ABW",
                                                    fullYear
                                                        ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                        : `Comparing ${new Date().getUTCFullYear()}-${
                                                              months[monthN]
                                                          } vs ${year}-${
                                                              months[monthN]
                                                          } `,
                                                    (number) =>
                                                        fns(hotelID, number, {
                                                            right: " days",
                                                        })
                                                )}
                                            </TableBodyCell>
                                        ))}
                                    </TableRow>
                                </>
                            ) : (
                                //   _                    _ _
                                //  | |    ___   __ _  __| (_)_ __   __ _
                                //  | |   / _ \ / _` |/ _` | | '_ \ / _` |
                                //  | |__| (_) | (_| | (_| | | | | | (_| |
                                //  |_____\___/ \__,_|\__,_|_|_| |_|\__, |
                                //                                  |___/
                                [...Array(7).keys()].map((e) => (
                                    <TableRow key={e}>
                                        {[...getYearsBefore()].map((f) => (
                                            <TableCell key={f}>
                                                <Skeleton />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </WrapperPDFTable>
            </TableContainer>
        </>
    );
};

export default MonthlyRoomsTableReport;
