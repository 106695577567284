import { authRolesTypes } from "../types/authRolesTypes";
import { HOTELS_INFO } from "../configuration";

export const roles = (roles, hotels = []) => {
    return {
        type: authRolesTypes.read,
        payload: {
            original: [...roles],
            edited: [...roles],
            hotels: hotels.filter((h) => h in HOTELS_INFO),
            loaded: true,
        },
    };
};

export const swapToHotelView = () => {
    console.log("to hotel");
    return {
        type: authRolesTypes.toHotel,
    };
};
export const swapToOriginal = () => {
    console.log("to original");
    return {
        type: authRolesTypes.toOriginal,
    };
};
