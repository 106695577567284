import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import EChartsReact from "echarts-for-react";
import {
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import SmallPercentage from "./SmallPercentage";
import TooltipComparison from "./shared/TooltipComparison";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";
import {
    formatDate,
    getDateFromWeekNumberSince2000,
    getWeekNumberSince2000,
} from "../helpers/dates";
import {
    DISPLAY_ELEMENT_HELPER,
    getMetricName,
    GROUP_DATE_MONTH,
    GROUP_DATE_WEEK,
    GROUP_DATE_YEAR,
} from "../helpers/filters";
import { fns } from "../helpers/common";

const WLeft = "145px";
const WRight = "80px";
const WPaddingLeftGraph = "145px";
const WPaddingRightGraph = "80px";

const SHOW_LIMIT = 5;

const ChartInTable = ({ js, data, place }) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [currentDataIndex, setCurrentDataIndex] = useState(0);

    const getNextDataSlice = () => {
        setCurrentDataIndex((prevIndex) =>
            Math.min(prevIndex + 1, Math.max(0, indexes.length - SHOW_LIMIT))
        );
    };

    const getPrevDataSlice = () => {
        setCurrentDataIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const getData = (groupKey, key, currentPrev) =>
        data[groupKey][currentPrev ? "result" : "resultOneYearBefore"][key] ??
        0;

    const getFromFromData = (itemKey) =>
        data[itemKey]["resultOneYearBefore"]["fromDate"];

    const getToFromData = (itemKey, values) =>
        data[itemKey]["resultOneYearBefore"]["toDate"];

    const getFirstWeekDay = (weekNumberFrom2000) => {
        return formatDate(
            getDateFromWeekNumberSince2000(weekNumberFrom2000, 6)
        );
    };

    const modeWeek = () =>
        js.groupBy[0] === GROUP_DATE_WEEK && js.groupBy.length === 1;

    const modeMonth = () =>
        js.groupBy.includes(GROUP_DATE_YEAR) &&
        js.groupBy.includes(GROUP_DATE_MONTH) &&
        js.groupBy.length === 2;

    const [indexes, setIndexes] = useState([]);

    useEffect(() => {
        let fromIndex = null;
        if (modeWeek())
            fromIndex = getWeekNumberSince2000(new Date(js.fromDay));
        // This is to avoid last year entries
        else if (modeMonth())
            fromIndex = `${new Date(js.fromDay).getUTCFullYear()}/${new Date(
                js.fromDay
            ).getUTCMonth()}`;
        setIndexes(
            Object.keys(data)
                .filter((v) => v >= fromIndex)
                .sort()
        );
    }, [data]);

    return (
        <>
            <Table
                style={{
                    width: "100%",
                    backgroundColor: Colours.tableRowBackground,
                    borderCollapse: "separate",
                    borderSpacing: 0,
                }}
                sx={{
                    borderRadius: "1em 1em 0 0",
                    overflow: "hidden",
                    "& th, & td": {
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                    },
                }}
            >
                <TableHead sx={{ borderTopLeftRadius: 8 }}>
                    <TableRow
                        sx={{
                            borderTopLeftRadius: 8,
                            display: "flex",
                            width: "100%",
                        }}
                    >
                        <TableCell
                            sx={{
                                width: WLeft,
                                borderTopLeftRadius: 8,
                                flex: "0 0 auto",
                            }}
                            onClick={getPrevDataSlice}
                        >
                            <IconButton disabled={currentDataIndex === 0}>
                                <Icons.ArrowLeft />
                            </IconButton>
                        </TableCell>
                        {indexes
                            .slice(
                                currentDataIndex,
                                currentDataIndex + SHOW_LIMIT
                            )
                            .map((itemKey, index) => (
                                <TableCell
                                    key={itemKey}
                                    sx={{
                                        flex: "1",
                                        padding: "0px",
                                        textAlign: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    {modeWeek() ? "week ending" : "month"}
                                    <br />
                                    <Typography fontWeight={"bold"}>
                                        {modeWeek()
                                            ? getFirstWeekDay(itemKey)
                                            : formatDate(
                                                  new Date(
                                                      itemKey.slice(0, 4),
                                                      itemKey.slice(5, 7),
                                                      1
                                                  ).toISOString(),
                                                  false
                                              )}
                                    </Typography>
                                </TableCell>
                            ))}
                        <TableCell
                            sx={{
                                width: WRight,
                                maxWidth: WRight,
                                textAlign: "right",
                                flex: "0 0 auto",
                            }}
                            onClick={getNextDataSlice}
                        >
                            <IconButton
                                disabled={
                                    currentDataIndex >=
                                    Math.max(0, indexes.length - SHOW_LIMIT)
                                }
                            >
                                <Icons.ArrowRight />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            {/* <br /> */}
            <EChartsReact
                style={{
                    marginTop: "15px",
                    height: 300,
                    width: "100%",
                    // paddingLeft: WPaddingLeftGraph,
                    // paddingRight: WPaddingRightGraph,
                }}
                option={{
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                        },
                    },
                    legend: {},
                    grid: {
                        left: WPaddingLeftGraph,
                        right: WPaddingRightGraph,
                        bottom: "8%",
                        // containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        data: indexes
                            .slice(
                                currentDataIndex,
                                currentDataIndex + SHOW_LIMIT
                            )
                            .map((itemKey, index) =>
                                modeWeek() ? getFirstWeekDay(itemKey) : itemKey
                            ),
                    },
                    yAxis: [
                        {
                            type: "value",
                            position: "left",
                        },
                        {
                            type: "value",
                            position: "right",
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#404040",
                                },
                            },
                            axisLabel: {
                                formatter: "{value}",
                            },
                        },
                    ],
                    series: [
                        {
                            name: "Bookings (Current)",
                            type: "bar",
                            data: indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey, index) =>
                                    getData(itemKey, "bookingsTotal", true)
                                ),
                            color: "#101010",
                        },
                        {
                            name: "Bookings STLY",
                            type: "bar",
                            data: indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey, index) =>
                                    getData(itemKey, "bookingsTotal", false)
                                ),
                            color: "#707070",
                        },
                        {
                            name: "Room Nights (Current)",
                            type: "bar",
                            data: indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey, index) =>
                                    getData(itemKey, "roomNightsTotal", true)
                                ),
                            color: "#202020",
                        },
                        {
                            name: "Room Nights STLY",
                            type: "bar",
                            data: indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey, index) =>
                                    getData(itemKey, "roomNightsTotal", false)
                                ),
                            color: "#808080",
                        },
                        {
                            name: "Revenue (Current)",
                            type: "line",
                            smooth: true,
                            yAxisIndex: 1,
                            data: indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey, index) =>
                                    getData(itemKey, "revenueTotal", true)
                                ),
                            color: "#404040",
                        },
                        {
                            name: "Revenue STLY",
                            type: "line",
                            smooth: true,
                            yAxisIndex: 1,
                            data: indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey, index) =>
                                    getData(itemKey, "revenueTotal", false)
                                ),
                            color: "#808080",
                        },
                    ],
                }}
            />
            <Table
                sx={{
                    "& th, & td": {
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                    },
                }}
            >
                <TableBody>
                    {[
                        { k: "bookingsTotal", text: "Bookings" },
                        { k: "roomNightsTotal", text: "Room Nights" },
                        {
                            k: "revenueTotal",
                            text: `${fns(
                                hotelID,
                                getMetricName("revenueTotal", place) ??
                                    "revenueTotal",
                                (DISPLAY_ELEMENT_HELPER["revenueTotal"] ?? {})
                                    .headerFormat
                            )}`,
                        },
                        {
                            k: "ADRTotal",
                            text: `${fns(
                                hotelID,
                                getMetricName("ADRTotal", place) ?? "ADRTotal",
                                (DISPLAY_ELEMENT_HELPER["ADRTotal"] ?? {})
                                    .headerFormat
                            )}`,
                        },
                    ].map(({ k: metric, text }) => (
                        <TableRow
                            key={metric}
                            sx={{ display: "flex", width: "100%" }}
                        >
                            <TableCell
                                sx={{
                                    width: WLeft,
                                    maxWidth: WLeft,
                                    backgroundColor: Colours.tableRowBackground,
                                    flex: "0 0 auto",
                                    alignContent: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography
                                    fontWeight={"bold"}
                                    fontSize="0.9em"
                                >
                                    {text}
                                </Typography>
                            </TableCell>
                            {indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey) => (
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            alignContent: "center",
                                            flex: "1",
                                        }}
                                    >
                                        <TooltipComparison
                                            // placeForMetricName={PLACE}
                                            metricName={metric}
                                            comparisonType={
                                                js.filters.Comparison
                                            }
                                            comparisonCustom={
                                                js.filters.ComparisonCustom
                                            }
                                            fromDay={getFromFromData(itemKey)}
                                            toDay={getToFromData(itemKey)}
                                            value={getData(
                                                itemKey,
                                                metric,
                                                true
                                            )}
                                            valueOld={getData(
                                                itemKey,
                                                metric,
                                                false
                                            )}
                                        >
                                            <Stack
                                                direction="column"
                                                sx={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {getData(itemKey, metric, true)}
                                                <SmallPercentage
                                                    brackets={false}
                                                    tooltip={false}
                                                    bubble={true}
                                                    bubbleRound={true}
                                                    actual={getData(
                                                        itemKey,
                                                        metric,
                                                        true
                                                    )}
                                                    actualName="Current period"
                                                    oldName={"Last period"}
                                                    old={getData(
                                                        itemKey,
                                                        metric,
                                                        false
                                                    )}
                                                    showFormula={false}
                                                />
                                            </Stack>
                                        </TooltipComparison>
                                    </TableCell>
                                ))}

                            <TableCell
                                sx={{ width: WRight, flex: "0 0 auto" }}
                            ></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default ChartInTable;
