import Axios from "axios";
import { HOTELS_INFO, URLAPI } from "../configuration";
import { authTypes } from "../types/authTypes";
import { roles } from "./authRoles";
import { setHotelID } from "./hotelID";

export const Authenticate = (token, hotelID) => {
    console.log("Authenticate");
    return async (dispatch) => {
        dispatch(tokenLoading());

        if (!token) {
            const storedData = localStorage.getItem("user");
            if (storedData) {
                // If data is available in local storage, update the Redux store with it
                console.log(loadUserData(JSON.parse(storedData)));
                dispatch(loadUserData(JSON.parse(storedData)));

                return;
            }
        }

        if (!token) {
            return;
        }

        Axios({
            method: "post",
            url: `${URLAPI}/authenticate`,
            // `https://arosuitestaging.arosuite.com/api/Cuchulainn/Authenticate`,
            data: JSON.stringify({
                token: token,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                console.log({ response });
                const { data } = response;

                const user = {
                    user: data.email,
                    user_id: data.user_id,
                    photo: data.photo,
                    expiresOn: data.expires_on,
                    firstName: data.first_name,
                    lastName: data.last_name,
                    rolesHotels: [...data.roles_hotels].sort(
                        (a, b) => parseInt(a) - parseInt(b)
                    ),
                    roles: data.roles,
                    jwt: data.jwt,
                };

                dispatch(loadUserData(user));
                dispatch(roles(data.roles, user.rolesHotels));
                if (hotelID !== null) {
                    // If we received an hotel to set by url

                    // If user has access to all hotels
                    if (data.roles_hotels.includes(-1)) {
                        // Check that the hotelID exists within hotel list
                        if (Object.keys(HOTELS_INFO).includes(hotelID)) {
                            // If so, set the hotelID
                            dispatch(setHotelID(hotelID));
                        } // Else, log that no such hotel exists
                        else {
                            console.log(`No hotel with hotelID of ${hotelID}`);
                        }
                    }
                    // Else if the hotelID is included in the users roles then it must exist
                    else if (data.roles_hotels.includes(hotelID)) {
                        dispatch(setHotelID(hotelID));
                    } else {
                        dispatch(
                            setHotelID(
                                data.roles_hotels
                                    .sort((a, b) => parseInt(a) - parseInt(b))
                                    .filter((el) => el !== -1)[0]
                            )
                        );
                    }
                } else if (
                    data.roles_hotels.filter((el) => el !== -1).length >= 1
                )
                    dispatch(
                        setHotelID(
                            data.roles_hotels
                                .sort((a, b) => parseInt(a) - parseInt(b))
                                .filter((el) => el !== -1)[0]
                        ) // TODO: Check that we are able preserve the ID of groups by having the group with the smaller ID num
                    );
                else {
                    console.log("Not found hotelID", data, hotelID);
                }
                localStorage.setItem("user", JSON.stringify(user));
            })
            .catch((error) => {
                console.log({ error });
                alert(`Error: ${error.response.data.error.Message}`);
                window.location = "/";
            });
    };
};

export const loadUserData = (user) => {
    return {
        type: authTypes.token,
        payload: {
            user: user,
            loading: false,
        },
    };
};

export const tokenLoading = () => {
    return {
        type: authTypes.token,
        payload: {
            loading: true,
        },
    };
};
