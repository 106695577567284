import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Axios from "axios";

import { URLAPI } from "../configuration";
import { useSnackbar } from "notistack";

const LogOut = () => {
    localStorage.clear();
    window.location.reload(false);
};

const IsJWTLive = () => {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const user_id = useSelector((state) => state.auth?.user?.user_id);
    const auth = useSelector((state) => state.auth);

    // Check if token is live every time when we change the location
    useEffect(() => {
        if (user_id) {
            Axios({
                method: "get",
                url: `${URLAPI}/jwtlive`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
            })
                .then((response) => {
                    const {
                        data: { status },
                    } = response;
                    console.log({ status });
                })
                .catch((error) => {
                    if (error.response) {
                        const { status } = error.response;
                        if (status === 401) {
                            console.log(
                                "Error 401: " +
                                    ((error.response.data ?? {}).msg ?? "")
                            );
                            enqueueSnackbar(`Token expired, please log in`, {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center",
                                },
                                variant: "warning",
                                autoHideDuration: 3000,
                            });
                            LogOut();
                        }
                    }
                });
        } else {
            // console.log("not user_id");
        }
    }, [location, user_id]);

    return null;
};

export default IsJWTLive;
