import { PDFExportTypes } from "../types/PDFExportTypes";

const initialLoadOne = { duplicable: true, visible: true };

export const loadOne = (data, k, hotelID, options = initialLoadOne) => {
    return {
        type: PDFExportTypes.loadOne,
        payload: data,
        key: k,
        hotelID,
        options: { ...initialLoadOne, ...options },
    };
};

export const removeOne = (k, hotelID) => {
    return {
        type: PDFExportTypes.removeOne,
        key: k,
        hotelID,
    };
};

export const toggleButtonAction = () => {
    return { type: PDFExportTypes.toggleButton };
};

export const clean = () => {
    return {
        type: PDFExportTypes.clean,
    };
};
