import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useSnackbar } from "notistack";
import Axios from "axios";
import {
    Collapse,
    Container,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography,
    Box,
} from "@mui/material";

import HoverPaper from "./shared/HoverPaper";
import CheckShow from "./shared/CheckShow";

import YearPerformance from "./reports/YearPerformance";
import MonthlyRevenue from "./MonthlyRevenue";
import MonthlyBookings from "./MonthlyBookings";
import MonthlyCancellations from "./MonthlyCancellations";
import MonthlySessions from "./MonthlySessions";
import MonthlyUsers from "./MonthlyUsers";
import MonthlyLostRevenue from "./MonthlyLostRevenue";
import MonthlyTopCountries from "./MonthlyTopCountries";
import MonthlyOthers from "./MonthlyOthers";
import ConfirmModal from "./ConfirmModal";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import { ARO_ALL, URLAPI } from "../configuration";

// import CardDashboard from "./CardDashboard";

const MonthlyReport = ({ data, month, year, isCollapsible, setReload }) => {
    const TITLE = "Revenue comparison";
    const [tabValue, setTabValue] = useState(0);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const { auth } = useSelector((state) => state);
    const { enqueueSnackbar } = useSnackbar();
    return (
        <>
            <Container
                component={HoverPaper}
                sx={{ p: 3 }}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Box
                        sx={{ display: "flex" }}
                        // justifyContent="center"
                        alignItems="center"
                    >
                        {isCollapsible && (
                            <IconButton
                                onClick={() =>
                                    window.open(
                                        `/monthly/${data.reportID}`,
                                        "_blank"
                                    )
                                }
                            >
                                <Icons.OpenLink />
                            </IconButton>
                        )}
                        <Typography>
                            <Box
                                component="span"
                                color={Colours.textColour}
                                fontWeight={"bold"}
                                fontSize={{ xs: 16, sm: 20 }}
                                letterSpacing={2}
                                display={"flex"}
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {`Monthly Report - ${
                                    data.title ?? ""
                                }`.toUpperCase()}
                            </Box>
                        </Typography>
                    </Box>
                    {isCollapsible && (
                        <Box>
                            <CheckShow rolesThatCanSee={ARO_ALL}>
                                <IconButton
                                    onClick={() => setConfirmModal(true)}
                                >
                                    <Icons.Delete />
                                </IconButton>
                            </CheckShow>
                            <IconButton
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                {isCollapsed ? (
                                    <Icons.ArrowUp />
                                ) : (
                                    <Icons.ArrowDown />
                                )}
                            </IconButton>
                        </Box>
                    )}
                </Stack>
            </Container>
            <br />
            <ConfirmModal
                isOpen={confirmModal}
                setIsOpen={setConfirmModal}
                text="Delete report?"
                onAccept={() => {
                    Axios.delete(
                        `${URLAPI}/jenkins/month_summary/get/${data.reportID}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${auth.user.jwt}`,
                            },
                        }
                    )
                        .then((response) => {
                            const { status } = response;
                            console.log({ response });
                            if (status === 200) {
                                enqueueSnackbar(
                                    `Successfully deleted, reloading data`,
                                    {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center",
                                        },
                                        variant: "success",
                                        autoHideDuration: 3000,
                                    }
                                );
                            } else {
                                enqueueSnackbar(`Error in delete`, {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                    },
                                    variant: "error",
                                    autoHideDuration: 3000,
                                });
                            }
                            setReload(new Date().getTime());
                        })
                        .catch((error) => {
                            console.log({ error });
                            enqueueSnackbar(
                                `Error in delete, ${error.response.data.message}`,
                                {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                    },
                                    variant: "error",
                                    autoHideDuration: 3000,
                                }
                            );
                        });
                }}
            />

            <Collapse in={!isCollapsible || !isCollapsed}>
                <Container>
                    <Typography sx={{ color: Colours.midLightText }}>
                        Generated at:{" "}
                        {new Date(data.ts).toISOString().split("T")[0]} for{" "}
                        {new Date(data.year, data.month_v, 1)
                            .toISOString()
                            .slice(0, 7)}
                    </Typography>
                    <Stack direction={"column"} spacing={0.1}>
                        <Container component={HoverPaper}>
                            <br />
                            <Typography fontWeight={"bold"}>
                                CONGRATULATIONS, YOU HAVE HIT PREDICTED SALES
                                TARGET!
                            </Typography>
                            <Typography>
                                Here is the chart illustrating your progress:
                            </Typography>
                            {data.reports && (
                                <YearPerformance
                                    TITLE={TITLE}
                                    dataLast={data.reports.Revenue.graph.last}
                                    dataThis={data.reports.Revenue.graph.this}
                                    dataTarget={
                                        data.reports.Revenue.graph.target
                                    }
                                />
                            )}
                            <br />
                            <Tabs
                                value={tabValue}
                                sx={{
                                    "& .MuiTabs-flexContainer": {
                                        justifyContent: "space-around",
                                    },
                                }}
                                onChange={(event, newValue) => {
                                    setTabValue(newValue);
                                }}
                            >
                                <Tab
                                    label="Revenue"
                                    id="Revenue"
                                    style={{ textTransform: "none" }}
                                />
                                <Tab
                                    label="Bookings"
                                    id="Bookings"
                                    style={{ textTransform: "none" }}
                                />
                                <Tab
                                    label="Cancellations"
                                    id="Cancellations"
                                    style={{ textTransform: "none" }}
                                />
                                <Tab
                                    label="Sessions"
                                    id="Sessions"
                                    style={{ textTransform: "none" }}
                                />
                                <Tab
                                    label="Users"
                                    id="Users"
                                    style={{ textTransform: "none" }}
                                />
                                <Tab
                                    label="Lost Revenue"
                                    id="LostRevenue"
                                    style={{ textTransform: "none" }}
                                />
                                <Tab
                                    label="Top Countries"
                                    id="TopCountries"
                                    style={{ textTransform: "none" }}
                                />

                                <Tab
                                    label="Others"
                                    id="Others"
                                    style={{ textTransform: "none" }}
                                />
                            </Tabs>
                            {data.reports && (
                                <Container>
                                    <br />

                                    {tabValue === 0 && (
                                        <>
                                            {/* REVENUE */}
                                            <MonthlyRevenue data={data} />
                                        </>
                                    )}
                                    {tabValue === 1 && (
                                        <>
                                            {/* BOOKINGS */}
                                            <MonthlyBookings data={data} />
                                        </>
                                    )}
                                    {tabValue === 2 && (
                                        <>
                                            {/* CANCELLATIONS */}
                                            <MonthlyCancellations data={data} />
                                        </>
                                    )}
                                    {tabValue === 3 && (
                                        <>
                                            {/* SESSIONS */}
                                            {data.reports.Sessions && (
                                                <MonthlySessions
                                                    data={data.reports.Sessions}
                                                />
                                            )}
                                        </>
                                    )}
                                    {tabValue === 4 && (
                                        <>
                                            {/* USERS */}
                                            {data.reports.Users && (
                                                <MonthlyUsers
                                                    data={data.reports.Users}
                                                />
                                            )}
                                        </>
                                    )}
                                    {tabValue === 5 && (
                                        <>
                                            {/* LOST REVENUE */}
                                            {data.reports.LostRevenue && (
                                                <MonthlyLostRevenue
                                                    data={
                                                        data.reports.LostRevenue
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                    {tabValue === 6 && (
                                        <>
                                            {/* TOP COUNTRIES */}
                                            {data.reports.TopCountries && (
                                                <MonthlyTopCountries
                                                    data={
                                                        data.reports
                                                            .TopCountries
                                                    }
                                                    month={data.month}
                                                    year={data.year}
                                                    hotel_id={data.hotel_id}
                                                />
                                            )}
                                        </>
                                    )}
                                    {tabValue === 7 && (
                                        <>
                                            {/* OTHERS */}
                                            {data.reports.Others && (
                                                <MonthlyOthers
                                                    data={data.reports.Others}
                                                    month={data.month}
                                                    year={data.year}
                                                    hotel_id={data.hotel_id}
                                                />
                                            )}
                                        </>
                                    )}
                                </Container>
                            )}

                            <br />
                        </Container>
                        <br />
                        <br />
                        <Typography fontWeight={"bold"}>OTHER STATS</Typography>
                        <br />
                        <Container>
                            {data.reports &&
                                data.reports.OtherStats &&
                                data.reports.OtherStats.cards && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={3}>
                                            {/* No data for Customer Login Bookings & Revenue from Customer Login - removed */}
                                            {/* <CardDashboard
                                                title="Customer Login Bookings"
                                                value={
                                                    data.reports.OtherStats
                                                        .cards
                                                        .customerLoginBookings
                                                        .this
                                                }
                                                noArrow
                                            /> */}
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            {/* <CardDashboard
                                                title="Revenue from Customer Login"
                                                value={
                                                    data.reports.OtherStats
                                                        .cards
                                                        .revenueFromCustomerLogin
                                                        .this
                                                }
                                                format={{ left: "currency" }}
                                                noArrow
                                            /> */}
                                        </Grid>
                                    </Grid>
                                )}
                        </Container>
                    </Stack>
                </Container>
            </Collapse>
        </>
    );
};

export default MonthlyReport;
