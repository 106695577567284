import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import Colours from "../helpers/colours";

const RoundedButton = styled((props) => (
    <Button
        component={props.to ? Link : null}
        variant="contained"
        disableElevation
        sx={{
            borderRadius: 1000,
            paddingRight: `${props.long ?? 2}em`,
            paddingLeft: `${props.long ?? 2}em`,
            color: Colours.lightText,
            whiteSpace: "nowrap",
        }}
        {...props}
    />
))({
    borderRadius: 1000,
    paddingRight: (props) => `${props.long ?? 2}em`,
    paddingLeft: (props) => `${props.long ?? 2}em`,
    color: Colours.lightText,
    whiteSpace: "nowrap",
});

export default RoundedButton;
