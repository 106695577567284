import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";

import CheckHide, { CheckHideBool } from "./shared/CheckHide";
import HoverPaper from "./shared/HoverPaper";

import GoBack from "./GoBack";

import RoomNights from "./reports/RoomNightsReport";
import RevenueReport from "./reports/RevenueReport";
import SessionsReport from "./reports/SessionsReport";
import PSVReport from "./reports/PSVReport";
import VouchersReport from "./reports/VouchersReport";

import Icons from "../helpers/icons";

const SalesSummary = () => {
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTabElementID, setSelectedTabElementID] =
        useState("RoomNights");

    const {
        settings: { defaultHomeReport = "RoomNights" },
    } = useSelector((state) => state.settings);

    useEffect(() => {
        setSelectedTabElementID(defaultHomeReport);
        setSelectedTab(indexes[defaultHomeReport]);
    }, [defaultHomeReport]);

    const [indexes, setIndexes] = useState({
        RoomNights: 0,
        RevenueChart: 1,
        SessionsChart: 2,
        PSVChart: 3,
        Vouchers: 4,
        CheckOutBusiness: 5,
        AvailabilityAndDemand: 6,
    });
    useEffect(() => {
        setIndexes(
            [
                "RoomNights",
                "RevenueChart",
                "SessionsChart",
                "PSVChart",
                CheckHideBool("Vouchers", hotelID) ? "VouchersReport" : null,
                "CheckOutBusiness",
            ]
                .filter((n) => n !== null)
                .reduce((acc, key, index) => {
                    acc[key] = index;
                    return acc;
                }, {})
        );
    }, [hotelID]);
    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Sales Summary</Typography>
                </Grid>
            </Grid>{" "}
            <Grid container alignItems="stretch" spacing={2}>
                {/*  ____                       _        */}
                {/* |  _ \ ___ _ __   ___  _ __| |_ ___  */}
                {/* | |_) / _ \ '_ \ / _ \| '__| __/ __| */}
                {/* |  _ <  __/ |_) | (_) | |  | |_\__ \ */}
                {/* |_| \_\___| .__/ \___/|_|   \__|___/ */}
                {/*           |_|                        */}
                <Grid item xs={12} md={12} order={{ xs: 2, sm: 2 }}>
                    <HoverPaper
                        component={Stack}
                        sx={{
                            p: 2,
                            pl: { xs: 6, md: 2 },
                            pr: { xs: 6, md: 2 },
                            height: "100%",
                            // bgcolor: Colours.notificationBackground,
                        }}
                        justifyContent="center"
                    >
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                            }}
                        >
                            <Tabs
                                value={selectedTab}
                                onChange={({ target }) => {
                                    setSelectedTab(indexes[target.id]);
                                    setSelectedTabElementID(target.id);
                                }}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                selectionFollowsFocus={true}
                            >
                                <Tab
                                    wrapped
                                    label="Room Nights"
                                    id="RoomNights"
                                    tabIndex={indexes["RoomNights"]}
                                />
                                <Tab
                                    wrapped
                                    label="Revenue"
                                    id="RevenueChart"
                                    tabIndex={indexes["RevenueChart"]}
                                />
                                <Tab
                                    wrapped
                                    label="Sessions"
                                    id="SessionsChart"
                                    tabIndex={indexes["SessionsChart"]}
                                />
                                <Tab
                                    wrapped
                                    label="PSV"
                                    id="PSVChart"
                                    tabIndex={indexes["PSVChart"]}
                                />
                                {CheckHide({
                                    k: "Vouchers",
                                    children: (
                                        <Tab
                                            wrapped
                                            label="Vouchers"
                                            id="VouchersReport"
                                            tabIndex={indexes["VouchersReport"]}
                                        />
                                    ),
                                })}
                            </Tabs>
                        </Box>
                        <br />

                        <div hidden={selectedTabElementID !== "RoomNights"}>
                            <Stack
                                direction="column"
                                height={"100%"}
                                sx={{
                                    minHeight: "400",
                                }}
                            >
                                <RoomNights />
                            </Stack>
                        </div>

                        <div hidden={selectedTabElementID !== "RevenueChart"}>
                            <RevenueReport />
                        </div>
                        <div hidden={selectedTabElementID !== "SessionsChart"}>
                            <SessionsReport />
                        </div>
                        <div hidden={selectedTabElementID !== "PSVChart"}>
                            <PSVReport />
                        </div>
                        <div hidden={selectedTabElementID !== "VouchersReport"}>
                            <VouchersReport hotelID={hotelID} />
                        </div>
                    </HoverPaper>
                </Grid>
            </Grid>
            <GoBack title="Edit targets" to="/targets" ICON={Icons.Edit} />
        </>
    );
};

export default SalesSummary;
