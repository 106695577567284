export const dataSimpleTypes = {
    loading: "[dataSimple] loading",
    partialLoad: "[dataSimple] partialLoad",
    load: "[dataSimple] load",
    reload: "[dataSimple] reload",
    error: "[dataSimple] error",
    clean: "[dataSimple] clean",
    updateOne: "[dataSimple] updateOne",
    reloading: "[dataSimple] reloading",
};
