import React, { useEffect, useState } from "react";

import { Grid, Skeleton } from "@mui/material";

import EChartsReact from "echarts-for-react";

import WrapperPDFChart from "../shared/WrapperPDFEChart";

import Colours from "../../helpers/colours";

const MembershipALoSReport = ({
    data,
    time = new Date(),
    isMobile = false,
}) => {
    const TITLE = "Membership - ALoS";
    const [dataMonth, setDataMonth] = useState([]);

    useEffect(() => {
        if (time) {
            let yearN = time.getUTCFullYear();
            let monthN = time.getUTCMonth();
            if (
                data.hasOwnProperty(yearN) &&
                data[yearN].hasOwnProperty(monthN)
            ) {
                let auxDataMonth = {};
                ((data[yearN] ?? {})[monthN] ?? []).forEach((entry) => {
                    let durationGroup =
                        entry.RoomNights >= 7 ? "+7" : entry.RoomNights;

                    if (auxDataMonth.hasOwnProperty(durationGroup)) {
                        auxDataMonth[durationGroup] += 1;
                    } else {
                        auxDataMonth[durationGroup] = 1;
                    }
                });
                setDataMonth(auxDataMonth);
            }
        }
    }, [data, time]);

    return (
        <>
            <Grid item sx={{ width: "100%", height: "100%" }}>
                <Grid
                    direction={isMobile ? "column" : "row"}
                    alignItems="center"
                    justifyContent="center"
                >
                    {Object.keys(data).length > 0 ? (
                        <>
                            <br />

                            <Grid item sm={12} md={12}>
                                <WrapperPDFChart k={TITLE}>
                                    <EChartsReact
                                        style={
                                            isMobile
                                                ? {
                                                      height: "450%",
                                                      width: "100%",
                                                  }
                                                : {
                                                      height: "350%",
                                                      width: "100%",
                                                  }
                                        }
                                        option={{
                                            grid: { top: "20%" },
                                            responsive: true,
                                            tooltip: {
                                                show: false,
                                                //     trigger: "axis",
                                                //     axisPointer: {
                                                //         // Use axis to trigger tooltip
                                                //         type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                                                //     },
                                                //     position: tooltip.position,
                                                // formatter: (params) => {
                                                //     let total = 0;
                                                //     let tooltipContent = "";
                                                //     params.forEach((param) => {
                                                //         if (
                                                //             param.value !==
                                                //             undefined
                                                //         ) {
                                                //             total += param.value;
                                                //             tooltipContent += `<br />${param.marker} <span"display:inline-block;margin-right:4px;">${param.seriesName} </span>&nbsp&nbsp&nbsp<b>${param.value}</b></span>`;
                                                //         }
                                                //     });
                                                //     return (
                                                //         `Total: <b>${total}</b>` +
                                                //         tooltipContent
                                                //     );
                                                // },
                                            },
                                            legend: {
                                                y: "5%",
                                            },
                                            yAxis: {
                                                type: "value",
                                                name: "Number of Member Bookings",
                                                nameLocation: "middle",
                                                nameGap: 40,
                                            },
                                            xAxis: {
                                                type: "category",
                                                name: "Number of Nights",
                                                nameLocation: "middle",
                                                nameGap: 25,
                                                data: Object.keys(dataMonth),
                                            },

                                            series: [
                                                {
                                                    type: "bar",
                                                    label: {
                                                        show: true,
                                                        position: "inside",
                                                    },
                                                    color: Colours.primary,
                                                    data: Object.keys(
                                                        dataMonth
                                                    ).map(
                                                        (key) => dataMonth[key]
                                                    ),
                                                },
                                            ],
                                        }}
                                    />
                                </WrapperPDFChart>
                            </Grid>
                        </>
                    ) : (
                        <Grid item sm={12} md={12}>
                            <br />
                            <Skeleton height={250} variant="rounded" />
                            <br />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default MembershipALoSReport;
