import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Grid,
    Container,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    Skeleton,
} from "@mui/material";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import TrendChart from "../TrendChart";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import OptionsButtonDialog from "../OptionsButtonDialog";
import TabSelector from "../TabSelector";
import DateSelector, {
    currentMonthButton,
    currentYearButton,
    lastMonthButton,
} from "../DateSelector";

import { getYearsBefore, months } from "../../helpers/dates";
import {
    processLostRevenue,
    processMonthLostRevenue,
} from "../../helpers/reports";
import { fns } from "../../helpers/common";

const LostRevenueReport = ({ hotelID }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(false);

    const { time: sharedTime, fullYear: sharedFullYear } = useSelector(
        (state) => state.settings.settings
    );

    const [connectionError, setConnectionError] = useState(false);
    const { dataSearches } = useSelector((state) => state);

    const TITLE = "Lost Revenue";
    const REPORT_DESC =
        "This report shows the Lost Revenue from unfinished user searches, due to different reasons: Sold Out, Closed Out or No Rate";

    const [dataLoaded, setDataLoaded] = useState(false);

    const [selectedData, setSelectedData] = useState({});
    const [selectedDataPrev, setSelectedDataPrev] = useState({});

    const [clickedCard, setClickedCard] = useState("");

    const HEADER = [
        {
            id: "TotalSearchCount",
            title: "Total Searches",
            expand: true,
            compare: true,
        },
        {
            id: "NoAvailabilitySearchCount",
            title: "No Av. Searches",
            expand: true,
            compare: true,
            isInverted: true,
        },
        {
            id: "LostRevenue",
            title: "Lost Revenue",
            format: { left: "currency" },
            expand: true,
            compare: true,
            isInverted: true,
        },
        {
            id: "TopSearchesElement",
            title: "Top Search Date",
            // value: getDateString(template.TopSearchesElement),
            expand: false,
            compare: false,
            noArrow: true,
        },
    ];

    const [dataByYear, setDataByYear] = useState({});
    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});
    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const setMonthsData = [
        setDataByJanuary,
        setDataByFebruary,
        setDataByMarch,
        setDataByApril,
        setDataByMay,
        setDataByJune,
        setDataByJuly,
        setDataByAugust,
        setDataBySeptember,
        setDataByOctober,
        setDataByNovember,
        setDataByDecember,
    ];

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    const getValidData = (unfilteredData) => {
        let validData = {};
        Object.entries(unfilteredData).map(([yearKey, data]) => {
            if (!Object.values(data).every((val) => val === 0)) {
                return (validData[yearKey] = data);
            }
            return undefined;
        });

        return validData;
    };

    //   _ __  _ __ ___   ___ ___  ___ ___
    //  | '_ \| '__/ _ \ / __/ _ \/ __/ __|
    //  | |_) | | | (_) | (_|  __/\__ \__ \
    //  | .__/|_|  \___/ \___\___||___/___/
    //  |_|
    useEffect(() => {
        if (dataSearches.status === "error") {
            setConnectionError(true);
            return;
        }

        // if (dataSearches.status !== "loaded") {
        //     return;
        // }

        if (!(dataSearches.data ?? {}).hasOwnProperty("LostRevenueSummary")) {
            return;
        }

        setConnectionError(false);

        const data = dataSearches.data.LostRevenueSummary;

        let res = {};
        data.forEach((element) => {
            const { SearchDate } = element;
            const year_ = new Date(SearchDate).getUTCFullYear();
            const month_ = new Date(SearchDate).getUTCMonth();
            if (!res.hasOwnProperty(year_)) res[year_] = {};
            if (!res[year_].hasOwnProperty(months[month_]))
                res[year_][months[month_]] = [];

            res[year_][months[month_]].push(element);
        });

        // Get array of all years to n years before
        let years = getYearsBefore();

        months.forEach((month, index) => {
            let auxMonthData = Object.assign(
                {},
                ...years.map((year) => ({
                    [year]: processMonthLostRevenue(
                        (res[`${year}`] ?? {})[month] ?? [],
                        "SearchDate",
                        year,
                        index
                    ),
                }))
            );
            setMonthsData[index](auxMonthData);
        });

        let auxYearData = Object.assign(
            {},
            ...years.map((year) => ({
                [year]: processLostRevenue(
                    Object.values(res[`${year}`] ?? {}).flat(1)
                ),
            }))
        );
        setDataByYear(getValidData(auxYearData));
    }, [dataSearches]);

    const getFullKey = () => {
        return `${clickedCard}`; // `${cardMode === "Total" ? cardMode : "Cancelled"}`;
    };
    useEffect(() => {
        setDataLoaded(false);

        if (monthsData) {
            setDataLoaded(true);
        }
    }, [monthsData]);

    useEffect(() => {
        setDataLoaded(false);

        if (dataByYear) {
            setDataLoaded(true);
        }
    }, [dataByYear]);

    useEffect(() => {
        let auxSelectedData = {};
        let auxSelectedDataPrev = {};

        if (time) {
            if (fullYear) {
                if (
                    Object.keys(dataByYear).length > 0 &&
                    dataByYear[`${time.getUTCFullYear()}`]
                    // && dataByYear[`${time.getUTCFullYear() - 1}`]
                ) {
                    auxSelectedData = dataByYear[`${time.getUTCFullYear()}`];
                    auxSelectedDataPrev =
                        dataByYear[`${time.getUTCFullYear() - 1}`] ?? {};
                }
            } else if (
                monthsData[time.getUTCMonth()] &&
                monthsData[time.getUTCMonth()][`${time.getUTCFullYear()}`] &&
                monthsData[time.getUTCMonth()][`${time.getUTCFullYear() - 1}`]
            ) {
                auxSelectedData =
                    monthsData[time.getUTCMonth()][`${time.getUTCFullYear()}`];

                auxSelectedDataPrev =
                    (monthsData[`${time.getUTCMonth()}`] ?? {})[
                        `${time.getUTCFullYear()}` - 1
                    ] ?? 0;
            }
        }

        setSelectedData(auxSelectedData);
        setSelectedDataPrev(auxSelectedDataPrev);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, dataByYear, ...monthsData]);

    useEffect(() => {
        // Now when any one is loaded, it counts as loaded, so if there is some missing data, it still shows
        if (!dataLoaded) {
            let aux =
                monthsData.some((month) => Object.keys(month).length > 0) ||
                Object.keys(dataByYear).length > 0;
            setDataLoaded(aux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData, clickedCard, dataByYear, ...monthsData]);

    const formatLabelValue = (value) => {
        let format = {
            maxDecimals:
                (HEADER.find((head) => head.id === clickedCard).format ?? {})
                    .maxDecimals ?? 0,
        };
        return fns(hotelID, value, format);
    };

    return (
        <Stack direction={"column"} spacing={0.1}>
            <Container maxWidth={false} component={HoverPaper}>
                <br />
                {/*  ___ _   _ ___ _____  */}
                {/* |_ _| \ | |_ _|_   _| */}
                {/*  | ||  \| || |  | |   */}
                {/*  | || |\  || |  | |   */}
                {/* |___|_| \_|___| |_|   */}
                <Stack
                    direction={{ md: "column", sm: "column", xs: "row" }}
                    justifyContent={isMobile ? "space-around" : "space-between"}
                    alignItems="flex-start"
                >
                    {/* Title */}
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <Stack alignItems="center">
                            <Typography fontWeight={"bold"}>{TITLE}</Typography>
                        </Stack>
                        <InfoIcon text={REPORT_DESC} />
                        <ErrorIcon show={connectionError} />
                    </Stack>

                    {isMobile ? (
                        <OptionsButtonDialog>
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                                buttons={[
                                    {
                                        label: "Current Year",
                                        value: currentYearButton(),
                                        fullYear: true,
                                    },
                                    {
                                        label: "Current Month",
                                        value: currentMonthButton(),
                                        fullYear: false,
                                    },
                                    {
                                        label: "Last Month",
                                        value: lastMonthButton(),
                                        fullYear: false,
                                    },
                                ]}
                            />
                            {""}
                        </OptionsButtonDialog>
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                                buttons={[
                                    {
                                        label: "Current Year",
                                        value: currentYearButton(),
                                        fullYear: true,
                                    },
                                    {
                                        label: "Current Month",
                                        value: currentMonthButton(),
                                        fullYear: false,
                                    },
                                    {
                                        label: "Last Month",
                                        value: lastMonthButton(),
                                        fullYear: false,
                                    },
                                ]}
                            />
                        </Stack>
                    )}
                </Stack>

                <br />

                {!dataLoaded && <ProgressBarSteps steps={[33, 66, 100]} />}
            </Container>

            <Grid
                container
                alignItems="stretch"
                spacing={0}
                justifyContent="left"
            >
                <TabSelector
                    hotelID={hotelID}
                    data={HEADER.map((head) => {
                        let auxHead = Object.assign({}, head);
                        const fullKey = `${head.id}`;
                        auxHead.value = (selectedData ?? {})[fullKey] ?? 0;
                        if (Object.keys(selectedDataPrev).length > 0) {
                            auxHead.compare = true;
                            auxHead.old =
                                (selectedDataPrev ?? {})[fullKey] ?? 0;
                        } else {
                            auxHead.compare = false;
                            auxHead.noArrow = true;
                        }
                        return auxHead;
                    })}
                    cardSelected={clickedCard}
                    setCardSelected={setClickedCard}
                />
                {
                    //   ____                 _
                    //  / ___|_ __ __ _ _ __ | |__
                    // | |  _| '__/ _` | '_ \| '_ \
                    // | |_| | | | (_| | |_) | | | |
                    //  \____|_|  \__,_| .__/|_| |_|
                    //                 |_|
                }
                {clickedCard &&
                clickedCard !== "TopSearchesElement" &&
                !isMobile ? (
                    dataLoaded ? (
                        fullYear ? (
                            <>
                                <TrendChart
                                    TITLE={
                                        TITLE +
                                            " - " +
                                            HEADER.find(
                                                (head) =>
                                                    head.id === clickedCard
                                            ).title ?? ""
                                    }
                                    key={"yearChart"}
                                    data={Object.keys(dataByYear).reduce(
                                        (accYear, year) => {
                                            accYear[year] = Object.keys(
                                                monthsData
                                            ).reduce((accMonth, month) => {
                                                accMonth[months[month]] =
                                                    (monthsData[month][year] ??
                                                        {})[getFullKey()] ?? 0;
                                                return accMonth;
                                            }, {});
                                            return accYear;
                                        },
                                        {}
                                    )}
                                    selected={Object.assign(
                                        {},
                                        ...Object.keys(dataByYear).map(
                                            (key) => ({
                                                [key]:
                                                    key ===
                                                    time.getUTCFullYear(),
                                            })
                                        )
                                    )}
                                    markPoints={{
                                        min: {
                                            show: true,
                                            formatter: formatLabelValue,
                                        },
                                        max: {
                                            show: true,
                                            formatter: formatLabelValue,
                                        },
                                    }}
                                    markLine={{
                                        show: true,
                                        formatter: formatLabelValue,
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <TrendChart
                                    TITLE={
                                        TITLE +
                                            " - " +
                                            HEADER.find(
                                                (head) =>
                                                    head.id === clickedCard
                                            ).title ?? ""
                                    }
                                    key={"monthChart"}
                                    data={{
                                        [`${
                                            months[time.getUTCMonth()]
                                        } ${time.getUTCFullYear()}`]:
                                            Object.keys(
                                                monthsData[time.getUTCMonth()][
                                                    time.getUTCFullYear()
                                                ].Details
                                            ).reduce((acc, day) => {
                                                acc[day] =
                                                    monthsData[
                                                        time.getUTCMonth()
                                                    ][
                                                        time.getUTCFullYear()
                                                    ].Details[day][
                                                        getFullKey()
                                                    ];
                                                return acc;
                                            }, {}),
                                    }}
                                    markPoints={{
                                        min: {
                                            show: true,
                                            formatter: formatLabelValue,
                                        },
                                        max: {
                                            show: true,
                                            formatter: formatLabelValue,
                                        },
                                    }}
                                    markLine={{
                                        show: true,
                                        formatter: formatLabelValue,
                                    }}
                                />
                            </>
                        )
                    ) : (
                        <>
                            <Skeleton variant="rounded" height={300} />
                            <br />
                        </>
                    )
                ) : (
                    ""
                )}
            </Grid>
        </Stack>
    );
};

export default LostRevenueReport;
