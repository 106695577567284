import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import EChartsReact from "echarts-for-react";

import {
    Box,
    Container,
    Grid,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import HoverPaper from "../shared/HoverPaper";

import CircleValue from "../CircleValue";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import TableHeaderCell from "../TableHeaderCell";
import SwitchIcons, { HEATMAP_MODE, LINE_MODE } from "./SwitchIcons";
import OptionsButtonDialog from "../OptionsButtonDialog";
import SwitchVisualTable, {
    VISUAL_MODE,
    TABLE_MODE,
} from "../SwitchVisualTable";
import LostRevenueReportRow from "./LostRevenueReportRow";
// import DateSelector, {
//     currentMonthButton,
//     currentYearButton,
//     lastMonthButton,
// } from "../DateSelector";

import Colours from "../../helpers/colours";
import { SliceTop, TOP_ALL } from "../../helpers/reports";
import { tooltip } from "../../helpers/eCharts";
import { fns } from "../../helpers/common";

const LostRevenueReport_Old = ({ hotelID }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const TOP = [10, 25, TOP_ALL, 5];
    const TITLE = "Lost Revenue";
    const REPORT_DESC =
        "This report shows the Lost Revenue from unfinished user searches, due to different reasons: Sold Out, Closed Out or No Rate";
    const HEADER = [
        {
            id: "searchDate",
            label: "Search Date",
        },
        {
            id: "searches",
            label: "Total Searches",
        },
        {
            id: "sessions",
            label: "Total Sessions",
        },
        {
            id: "noAvSearches",
            label: "No Av. Searches",
            tooltip: "No Av. Searches(Searches on no availability dates)",
        },
        {
            id: "noAvSessions",
            label: "No Av. Sessions",
            tooltip:
                "No Availability Sessions(Sessions without an available date)",
        },
        {
            id: "lostRevenue",
            label: "Lost Revenue",
            tooltip: "Sum of the minimum amount of revenue per No Av. Session",
            format: { left: "currency" },
        },
    ];

    const [graphMode, setGraphMode] = useState(HEATMAP_MODE);

    const { dataSearches } = useSelector((state) => state);
    const [connectionError, setConnectionError] = useState(false);
    const [mode, setMode] = useState(TABLE_MODE);

    const [topNumber, setTopNumber] = useState(0);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("lostRevenue");
    const [graphSelected, setGraphSelected] = useState("lostRevenue");

    const [rawData, setRawData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [timeStart, setTimeStart] = useState(new Date());

    // const getDetailsData = async (hotelID, fromTime, toTime) => {
    //     try {
    //         const response = await Axios.post(
    //             `${URLAPI}/lost_revenue/details/${
    //                 hotelID === "default" ? DEFAULT_HOTEL : hotelID
    //             }`,
    //             {
    //                 from: fromTime,
    //                 to: toTime,
    //             }
    //         );
    //         return response.data.data;
    //     } catch (error) {
    //         return [];
    //     }
    // };

    //   _ __  _ __ ___   ___ ___  ___ ___
    //  | '_ \| '__/ _ \ / __/ _ \/ __/ __|
    //  | |_) | | | (_) | (_|  __/\__ \__ \
    //  | .__/|_|  \___/ \___\___||___/___/
    //  |_|
    useEffect(() => {
        if (dataSearches.status === "error") setConnectionError(true);
        if (dataSearches.status === "loaded") {
            if (dataSearches.data.LostRevenueSummary.length > 0) {
                let auxRawData = [];
                let auxTimeStart = new Date();
                dataSearches.data.LostRevenueSummary.forEach((element) => {
                    auxRawData.push({
                        searchDate: element.SearchDate,
                        searches: element.TotalSearchCount,
                        sessions: element.TotalSessionCount,
                        noAvSearches: element.NoAvailabilitySearchCount,
                        noAvSessions: element.NoAvailabilitySessionCount,
                        lostRevenue: element.LostRevenue,
                    });
                    auxTimeStart =
                        new Date(element.SearchDate) < auxTimeStart
                            ? new Date(element.SearchDate)
                            : auxTimeStart;
                });
                auxTimeStart = new Date(
                    auxTimeStart.getUTCFullYear(),
                    auxTimeStart.getUTCMonth(),
                    1
                );
                setTimeStart(auxTimeStart);
                setRawData(auxRawData);
                setConnectionError(false);
            } else {
                setConnectionError(true);
            }
        }
    }, [dataSearches]);

    //   _                        ___                    _
    //  / |   __ _  __ _  ___    ( _ )    ___  ___  _ __| |_
    //  | |  / _` |/ _` |/ _ \   / _ \/\ / __|/ _ \| '__| __|
    //  | | | (_| | (_| | (_) | | (_>  < \__ \ (_) | |  | |_
    //  |_|  \__,_|\__, |\___/   \___/\/ |___/\___/|_|   \__|
    //             |___/
    useEffect(() => {
        if (rawData.length > 0) {
            setSelectedData([]);

            if (mode !== VISUAL_MODE) {
                let auxSelected = structuredClone(rawData);
                auxSelected.sort((a, b) => {
                    if (orderBy === "searchDate") {
                        return order === "asc"
                            ? new Date(b[orderBy]) - new Date(a[orderBy])
                            : new Date(a[orderBy]) - new Date(b[orderBy]);
                    } else {
                        return order === "asc"
                            ? b[orderBy] - a[orderBy]
                            : a[orderBy] - b[orderBy];
                    }
                });

                setSelectedData(SliceTop(auxSelected, TOP[topNumber]));
            } else {
                setSelectedData(rawData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawData, order, orderBy, topNumber, mode]);

    useEffect(() => {
        if (graphMode === LINE_MODE) {
            setOrderBy("searchDate");
            setOrder("asc");
        }
    }, [graphMode]);

    return (
        <Container component={HoverPaper} maxWidth={false}>
            <br />
            <Stack
                direction={{ md: "column", sm: "column", xs: "row" }}
                justifyContent={isMobile ? "space-around" : "space-between"}
                alignItems="flex-start"
                // style={{ border: "1px solid red" }}
            >
                {/* Title */}
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={"center"}
                    // style={{ border: "1px solid orange" }}
                >
                    <Stack
                        alignItems="center"
                        // style={{ border: "1px solid yellow" }}
                    >
                        <Typography fontWeight={"bold"}>
                            {TITLE} - Details
                        </Typography>
                        {/* <Typography fontStyle={"italic"} variant="subtitle2">
                            {`by ${
                                sharedDataSource === "CheckOut"
                                    ? "Check-out"
                                    : sharedDataSource === "CheckIn"
                                    ? "Check-in"
                                    : "Reservation"
                            } date`}
                        </Typography> */}
                    </Stack>
                    <InfoIcon text={REPORT_DESC} />
                    <ErrorIcon show={connectionError} />
                </Stack>

                {isMobile ? (
                    <OptionsButtonDialog>
                        <SwitchVisualTable
                            collapse={isMobile}
                            mode={mode}
                            setMode={setMode}
                            modes={[TABLE_MODE, VISUAL_MODE]}
                        />
                        {mode === VISUAL_MODE && (
                            <SwitchIcons
                                selectedMode={graphMode}
                                modes={[HEATMAP_MODE, LINE_MODE]}
                                setGraphMode={setGraphMode}
                            />
                        )}
                        {/* <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        /> */}
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography>{`Switch to top ${
                                TOP[
                                    (topNumber + 1) %
                                        (mode === TABLE_MODE
                                            ? TOP.length
                                            : TOP.length - 1)
                                ]
                            }`}</Typography>
                            <CircleValue
                                value={`${TOP[topNumber]}`}
                                tooltip={`Switch to top ${
                                    TOP[
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    ]
                                }`}
                                color={Colours.primary}
                                onClick={() => {
                                    setTopNumber(
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    );
                                }}
                                style={{ cursor: "pointer" }}
                            />
                        </Stack>
                        {""}
                    </OptionsButtonDialog>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <CircleValue
                            value={`${TOP[topNumber]}`}
                            tooltip={`Switch to top ${
                                TOP[(topNumber + 1) % TOP.length]
                            }`}
                            color={Colours.primary}
                            onClick={() => {
                                setTopNumber((topNumber + 1) % TOP.length);
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <SwitchVisualTable
                            mode={mode}
                            setMode={setMode}
                            modes={[TABLE_MODE, VISUAL_MODE]}
                        />

                        {mode === VISUAL_MODE && (
                            <SwitchIcons
                                selectedMode={graphMode}
                                modes={[HEATMAP_MODE, LINE_MODE]}
                                setGraphMode={setGraphMode}
                            />
                        )}

                        {/* <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        /> */}
                    </Stack>
                )}
            </Stack>
            {mode === TABLE_MODE && (
                //   _____  _    ____  _     _____
                //  |_   _|/ \  | __ )| |   | ____|
                //    | | / _ \ |  _ \| |   |  _|
                //    | |/ ___ \| |_) | |___| |___
                //    |_/_/   \_\____/|_____|_____|
                <>
                    <TableContainer
                        style={{ overflowX: "auto", maxHeight: "600px" }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {HEADER.map((headCell, index) => (
                                        <TableHeaderCell
                                            key={headCell.id}
                                            sortDirection={
                                                orderBy === headCell.id
                                                    ? order
                                                    : false
                                            }
                                            style={
                                                isMobile && index === 0
                                                    ? {
                                                          position: "sticky",
                                                          left: 0,
                                                          top: 0,
                                                          zIndex: 4,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                                    : {
                                                          position: "sticky",
                                                          top: 0,
                                                          zIndex: 3,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                            }
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={
                                                    orderBy === headCell.id
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() => {
                                                    const isAsc =
                                                        orderBy ===
                                                            headCell.id &&
                                                        order === "asc";
                                                    setOrder(
                                                        isAsc ? "desc" : "asc"
                                                    );
                                                    setOrderBy(headCell.id);
                                                }}
                                            >
                                                {headCell.tooltip ? (
                                                    <Tooltip
                                                        title={headCell.tooltip}
                                                    >
                                                        <div>
                                                            {headCell.label}
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    headCell.label
                                                )}
                                            </TableSortLabel>
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <>
                                    {selectedData.length > 0 &&
                                    dataSearches.status === "loaded" ? (
                                        selectedData.map((rowData, i) => (
                                            <LostRevenueReportRow
                                                key={i}
                                                hotelID={dataSearches.hotelID}
                                                rowData={rowData}
                                                header={HEADER}
                                                isMobile={isMobile}
                                            />
                                        ))
                                    ) : dataSearches.status === "loaded" ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={100}
                                                align="center"
                                            >
                                                <Box
                                                    component="span"
                                                    fontStyle="italic"
                                                >
                                                    No data
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        [
                                            ...Array(
                                                TOP[topNumber] === TOP_ALL
                                                    ? 25
                                                    : TOP[topNumber]
                                            ).keys(),
                                        ].map((e) => (
                                            <TableRow key={e}>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                {HEADER.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                    >
                                                        <Skeleton />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    )}
                                </>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            {/*   ____ ____      _    ____  _   _  */}
            {/*  / ___|  _ \    / \  |  _ \| | | | */}
            {/* | |  _| |_) |  / _ \ | |_) | |_| | */}
            {/* | |_| |  _ <  / ___ \|  __/|  _  | */}
            {/*  \____|_| \_\/_/   \_\_|   |_| |_| */}
            {mode === VISUAL_MODE && (
                <>
                    {selectedData.length > 0 ? (
                        <>
                            {/* <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                {mode === VISUAL_MODE && (
                                    <SwitchIcons
                                        selectedMode={graphMode}
                                        modes={[HEATMAP_MODE, LINE_MODE]}
                                        setGraphMode={setGraphMode}
                                    />
                                )}
                            </Stack> */}
                            {graphMode.type === "heatmap" ? (
                                //  _   _            _                          ____                 _
                                // | | | | ___  __ _| |_ _ __ ___   __ _ _ __  / ___|_ __ __ _ _ __ | |__
                                // | |_| |/ _ \/ _` | __| '_ ` _ \ / _` | '_ \| |  _| '__/ _` | '_ \| '_ \
                                // |  _  |  __/ (_| | |_| | | | | | (_| | |_) | |_| | | | (_| | |_) | | | |
                                // |_| |_|\___|\__,_|\__|_| |_| |_|\__,_| .__/ \____|_|  \__,_| .__/|_| |_|
                                //                                      |_|                   |_|
                                <>
                                    <br />

                                    <Grid
                                        container
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        {HEADER.slice(1).map((head) => (
                                            <Grid
                                                item
                                                xs={6}
                                                sm={4}
                                                md={2}
                                                justifyContent={"center"}
                                                display={"flex"}
                                            >
                                                <TableHeaderCell
                                                    align={"center"}
                                                    onClick={() =>
                                                        setGraphSelected(
                                                            head.id
                                                        )
                                                    }
                                                    sx={{
                                                        colsSpan: 12,
                                                        backgroundColor:
                                                            head.id ===
                                                            graphSelected
                                                                ? Colours.selectedRow
                                                                : Colours.plainWhite,
                                                    }}
                                                >
                                                    {head.label}
                                                </TableHeaderCell>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <WrapperPDFEChart k={TITLE}>
                                        <EChartsReact
                                            style={{
                                                height: "250%",
                                                width: "100%",
                                            }}
                                            option={{
                                                tooltip: {
                                                    position: "top",
                                                    formatter: function (p) {
                                                        return `${new Date(
                                                            p.data[0]
                                                        )
                                                            .toISOString()
                                                            .slice(
                                                                0,
                                                                10
                                                            )}<br>${fns(
                                                            hotelID,
                                                            p.data[1],
                                                            (
                                                                HEADER.find(
                                                                    (e) =>
                                                                        e.id ===
                                                                        graphSelected
                                                                ) ?? {}
                                                            ).format ?? {}
                                                        )}`;
                                                    },
                                                },
                                                visualMap: {
                                                    min: selectedData.reduce(
                                                        (min, item) => {
                                                            return min <
                                                                item[
                                                                    graphSelected
                                                                ]
                                                                ? min
                                                                : item[
                                                                      graphSelected
                                                                  ];
                                                        }
                                                    ),
                                                    max: selectedData.reduce(
                                                        (max, item) => {
                                                            return max >
                                                                item[
                                                                    graphSelected
                                                                ]
                                                                ? max
                                                                : item[
                                                                      graphSelected
                                                                  ];
                                                        }
                                                    ),
                                                    calculable: true,
                                                    orient: "horizontal",
                                                    left: "center",
                                                    top: "bottom",
                                                },
                                                calendar: [
                                                    {
                                                        orient: "horizontal",
                                                        dayLabel: {
                                                            firstDay: 1, // Monday
                                                            nameMap: [
                                                                "Sun",
                                                                "Mon",
                                                                "Tue",
                                                                "Wed",
                                                                "Thu",
                                                                "Fri",
                                                                "Sat",
                                                            ],
                                                        },
                                                        yearLabel: {
                                                            show: true,
                                                            position: "left",
                                                            margin: 50,
                                                            color: "#666",
                                                        },
                                                        range: [
                                                            timeStart
                                                                .toISOString()
                                                                .slice(0, 10),
                                                            new Date(
                                                                new Date().getUTCFullYear(),
                                                                new Date().getUTCMonth() +
                                                                    1,
                                                                1
                                                            )
                                                                .toISOString()
                                                                .slice(0, 10),
                                                        ],
                                                        left: "center",
                                                        top: "center",
                                                    },
                                                ],
                                                series: [
                                                    {
                                                        type: "heatmap",
                                                        coordinateSystem:
                                                            "calendar",
                                                        calendarIndex: 0,
                                                        data: [
                                                            ...selectedData.map(
                                                                (e) => {
                                                                    return [
                                                                        e.searchDate,
                                                                        e[
                                                                            graphSelected
                                                                        ],
                                                                    ];
                                                                }
                                                            ),
                                                        ],
                                                    },
                                                ],
                                            }}
                                        />
                                    </WrapperPDFEChart>
                                </>
                            ) : (
                                //     _     _             ____                 _
                                //    | |   (_)_ __   ___ / ___|_ __ __ _ _ __ | |__
                                //    | |   | | '_ \ / _ \ |  _| '__/ _` | '_ \| '_ \
                                //    | |___| | | | |  __/ |_| | | | (_| | |_) | | | |
                                //    |_____|_|_| |_|\___|\____|_|  \__,_| .__/|_| |_|
                                //                                        |_|
                                <WrapperPDFEChart k={TITLE}>
                                    <EChartsReact
                                        option={{
                                            animation: true,

                                            legend: {
                                                data: [
                                                    "Total Searches",
                                                    "Total Sessions",
                                                    "No Av. Searches",
                                                    "No Av. Sessions",
                                                    "Revenue Lost",
                                                ],
                                                selected: {
                                                    "Total Searches": true,
                                                    "Total Sessions": true,
                                                    "No Av. Searches": true,
                                                    "No Av. Sessions": true,
                                                    "Revenue Lost": false,
                                                },
                                            },
                                            tooltip: tooltip,
                                            dataZoom: [
                                                {
                                                    type: "inside",
                                                },
                                                {
                                                    start: 0,
                                                    end: 10,
                                                },
                                            ],
                                            xAxis: {
                                                type: "category",
                                                // axisLabel: {
                                                //     formatter: (v) => {
                                                //         return months[
                                                //             Number(v.slice(-2)) - 1
                                                //         ].slice(0, 3);
                                                //     },
                                                // },
                                            },
                                            yAxis: [{}, {}],
                                            series: HEADER.slice(1).map(
                                                (head) => ({
                                                    type: "line",
                                                    smooth: true,
                                                    name: head.label,
                                                    // symbol: "none",
                                                    //yAxisIndex: head.yAxisIndex ?? 1,
                                                    data: [
                                                        ...selectedData.map(
                                                            (e) => {
                                                                return [
                                                                    new Date(
                                                                        e.searchDate
                                                                    )
                                                                        .toISOString()
                                                                        .slice(
                                                                            0,
                                                                            10
                                                                        ),
                                                                    e[head.id],
                                                                ];
                                                            }
                                                        ),
                                                    ],
                                                })
                                            ),
                                        }}
                                    />
                                </WrapperPDFEChart>
                            )}
                        </>
                    ) : dataSearches.status === "loaded" ? (
                        <TableRow>
                            <TableCell colSpan={100} align="center">
                                <Box component="span" fontStyle="italic">
                                    No data
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            <br />
                            <Skeleton height={250} variant="rounded" />
                        </>
                    )}
                </>
            )}
            <br />
        </Container>
    );
};

export default LostRevenueReport_Old;
