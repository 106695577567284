import React, { Component } from "react";
import {
    ThemeProvider,
    Paper,
    Stack,
    Typography,
    Button,
    Box,
    Divider,
} from "@mui/material";
import { AROLOGO } from "../configuration"; // Asegúrate de que esta ruta de importación sea correcta
import Colours from "../helpers/colours"; // Asegúrate de que esta ruta de importación sea correcta
import Icons from "../helpers/icons"; // Asegúrate de que esta ruta de importación sea correcta
import { darkTheme, lightTheme } from "../theme"; // Asegúrate de que esta ruta de importación sea correcta
import { useSelector } from "react-redux";
import { connect } from "react-redux";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: "",
            errorStack: "",
            componentStack: "",
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Construir un mensaje de error detallado
        const errorDetails = `
            Error Stack Trace: ${error.stack.slice(0, 200)}
            Component Stack Trace: ${errorInfo.componentStack.slice(0, 200)}
            Error Message: ${error.toString().slice(0, 200)}
        `;
        this.setState({ errorDetails });
        console.error("Error caught in Error Boundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            const { themeMode } = this.props;

            const emailBody = encodeURIComponent(this.state.errorDetails);

            const mailToLink = `mailto:alejandro.lorenzo@aro.ie?subject=Error Report&body=${emailBody.slice(
                0,
                1900
            )}`;
            return (
                <ThemeProvider
                    theme={themeMode === "dark" ? darkTheme : lightTheme}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            height: "100vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Stack
                            direction="column"
                            alignItems="center"
                            spacing={2}
                        >
                            <img src={AROLOGO} alt="Logo" width={400} />
                            <Divider sx={{ width: "80%" }} />
                            <Box maxWidth={400} textAlign="center">
                                <Typography
                                    color={Colours.textColour}
                                    fontSize={24}
                                >
                                    There was an error in the load.
                                    <br />
                                    Please refresh the page and if the problem
                                    persists, contact the developers team.
                                </Typography>
                            </Box>
                            <Stack direction="row" spacing={1}>
                                <Button
                                    variant="outlined"
                                    startIcon={<Icons.Refresh />}
                                    onClick={() => window.location.reload()}
                                >
                                    Refresh
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<Icons.Email />}
                                    onClick={() => window.open(mailToLink)}
                                >
                                    Email
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </ThemeProvider>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    themeMode: state.preferences.themeMode,
});

export default connect(mapStateToProps)(ErrorBoundary);
