import React, { useEffect, useState } from "react";

import { Grid, Skeleton, Stack, Switch, Typography } from "@mui/material";

import EChartsReact from "echarts-for-react";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";

import Colours from "../../helpers/colours";
import { tooltip } from "../../helpers/eCharts";

const MembershipBookingsReport = ({
    data,
    time = new Date(),
    isMobile = false,
}) => {
    const TITLE = "Membership - Member Bookings";
    const [timeMode, setTimeMode] = useState("monthly");

    const [dataMonthWeeks, setDataMonthWeeks] = useState({});

    const getMonthWeeks = (year, month) => {
        const auxMonthWeeks = {};
        const auxDays = {};

        let firstDay = new Date(Date.UTC(year, month, 1));
        let lastDay = new Date(Date.UTC(year, month + 1, 0));

        for (let i = firstDay.getUTCDate(); i <= lastDay.getUTCDate(); i++) {
            let week = {};

            let date = new Date(Date.UTC(year, month, i));

            let day = date.getUTCDay();
            day = day === 0 ? 7 : day;

            while (date.getUTCMonth() === month && day <= 7) {
                week[date.toISOString().slice(0, 10)] = {
                    day: day,
                    memberBookings: 0,
                    nonMemberBookings: 0,
                };

                auxDays[date.toISOString().slice(0, 10)] =
                    week[date.toISOString().slice(0, 10)];

                date = new Date(Date.UTC(year, month, date.getUTCDate() + 1));
                day++; // +1 week day
                i++; // update aswell +1 month day
            }

            auxMonthWeeks[`${i - Object.keys(week).length}-${i - 1}`] = week;

            i--; // Correction i++ of for loop
        }

        return [auxMonthWeeks, auxDays];
    };

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const thisYear = time.getUTCFullYear();
            const thisMonth = time.getUTCMonth();
            const [auxDataMonthWeeks, auxDays] = getMonthWeeks(
                thisYear,
                thisMonth
            );

            Object.values((data[thisYear] ?? {})[thisMonth] ?? {}).forEach(
                (element) => {
                    let date = new Date(element.DateOfBooking)
                        .toISOString()
                        .slice(0, 10);

                    if (auxDays.hasOwnProperty(date)) {
                        auxDays[date].memberBookings += element.MemberBookings;
                        auxDays[date].nonMemberBookings +=
                            element.NonMemberBookings;
                    }
                }
            );

            setDataMonthWeeks(auxDataMonthWeeks);
        }
    }, [data, time]);

    return (
        <>
            <Grid item sx={{ width: "100%", height: "100%" }}>
                <Grid
                    container
                    direction={isMobile ? "column" : "row"}
                    alignItems="center"
                    justifyContent="center"
                >
                    {Object.keys(data).length > 0 &&
                    Object.keys(dataMonthWeeks).length > 0 ? (
                        <>
                            <br />
                            <Stack direction="row" alignItems="center">
                                <Typography variant="body2">Week</Typography>
                                <Switch
                                    checked={timeMode === "monthly"}
                                    onChange={() =>
                                        setTimeMode(
                                            timeMode === "monthly"
                                                ? "weekly"
                                                : "monthly"
                                        )
                                    }
                                    size="small"
                                />

                                <Typography variant="body2">Month</Typography>
                            </Stack>

                            <Grid item sm={12} md={12}>
                                {timeMode === "monthly" && (
                                    <WrapperPDFEChart k={TITLE}>
                                        <EChartsReact
                                            style={
                                                isMobile
                                                    ? {
                                                          height: "450%",
                                                          width: "100%",
                                                      }
                                                    : {
                                                          height: "350%",
                                                          width: "100%",
                                                      }
                                            }
                                            option={{
                                                grid: {
                                                    top: "20%",
                                                    left: "10%",
                                                    right: "10%",
                                                },
                                                responsive: true,
                                                tooltip: {
                                                    trigger: "axis",
                                                    axisPointer: {
                                                        // Use axis to trigger tooltip
                                                        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                                                    },
                                                    position: tooltip.position,
                                                    formatter: (params) => {
                                                        let total = 0;
                                                        let tooltipContent = "";
                                                        params.forEach(
                                                            (param) => {
                                                                if (
                                                                    param.value !==
                                                                    undefined
                                                                ) {
                                                                    total +=
                                                                        param.value;
                                                                    tooltipContent += `<br />${param.marker} <span"display:inline-block;margin-right:4px;">${param.seriesName} </span>&nbsp&nbsp&nbsp<b>${param.value}</b></span>`;
                                                                }
                                                            }
                                                        );
                                                        return (
                                                            `Total: <b>${total}</b>` +
                                                            tooltipContent
                                                        );
                                                    },
                                                },
                                                legend: {
                                                    y: "5%",
                                                },

                                                yAxis: {
                                                    type: "value",
                                                },
                                                xAxis: {
                                                    type: "category",
                                                    name: "Weeks of the month",
                                                    nameLocation: "middle",
                                                    nameGap: 25,
                                                    data: Object.keys(
                                                        dataMonthWeeks
                                                    ),
                                                },
                                                series: [
                                                    ...[
                                                        "Member Bookings",
                                                        "Non Member Bookings",
                                                    ].map((type, index) => {
                                                        const data = [
                                                            ...Object.keys(
                                                                dataMonthWeeks
                                                            ).map((week) => {
                                                                return Object.values(
                                                                    dataMonthWeeks[
                                                                        week
                                                                    ]
                                                                ).reduce(
                                                                    (
                                                                        acc,
                                                                        day
                                                                    ) =>
                                                                        acc +
                                                                        (type ===
                                                                        "Member Bookings"
                                                                            ? day.memberBookings
                                                                            : day.nonMemberBookings),
                                                                    0
                                                                );
                                                            }),
                                                        ];
                                                        return {
                                                            name: type,
                                                            type: "bar",
                                                            stack: "total",
                                                            label: {
                                                                show: true,
                                                                position:
                                                                    "inside",
                                                            },
                                                            color:
                                                                type ===
                                                                "Member Bookings"
                                                                    ? Colours.primary
                                                                    : Colours.infoSoft,
                                                            data: data,
                                                        };
                                                    }),
                                                ],
                                            }}
                                        />
                                    </WrapperPDFEChart>
                                )}
                                {timeMode === "weekly" && (
                                    <WrapperPDFEChart k={TITLE}>
                                        <EChartsReact
                                            style={
                                                isMobile
                                                    ? {
                                                          height: "450%",
                                                          width: "100%",
                                                      }
                                                    : {
                                                          height: "350%",
                                                          width: "100%",
                                                      }
                                            }
                                            option={{
                                                grid: { top: "20%" },
                                                responsive: true,
                                                tooltip: {
                                                    trigger: "axis",
                                                    axisPointer: {
                                                        // Use axis to trigger tooltip
                                                        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                                                    },
                                                    position: tooltip.position,
                                                    formatter: (params) => {
                                                        let total = 0;
                                                        let tooltipContent = "";
                                                        params.forEach(
                                                            (param) => {
                                                                if (
                                                                    param.value !==
                                                                    undefined
                                                                ) {
                                                                    total +=
                                                                        param.value;
                                                                    tooltipContent += `<br />${param.marker} <span"display:inline-block;margin-right:4px;">${param.seriesName} </span>&nbsp&nbsp&nbsp<b>${param.value}</b></span>`;
                                                                }
                                                            }
                                                        );
                                                        return (
                                                            `Total: <b>${total}</b>` +
                                                            tooltipContent
                                                        );
                                                    },
                                                },
                                                legend: {
                                                    y: "5%",
                                                },
                                                yAxis: {
                                                    type: "value",
                                                },
                                                xAxis: {
                                                    type: "category",

                                                    data: [
                                                        "Mon",
                                                        "Tue",
                                                        "Wed",
                                                        "Thu",
                                                        "Fri",
                                                        "Sat",
                                                        "Sun",
                                                    ],
                                                },

                                                series: [
                                                    ...[
                                                        "Member Bookings",
                                                        "Non Member Bookings",
                                                    ].map((type, index) => {
                                                        let data = new Array(
                                                            7
                                                        ).fill(0);

                                                        Object.keys(
                                                            dataMonthWeeks
                                                        ).forEach((week) => {
                                                            Object.values(
                                                                dataMonthWeeks[
                                                                    week
                                                                ]
                                                            ).forEach(
                                                                (day) =>
                                                                    (data[
                                                                        day.day -
                                                                            1
                                                                    ] +=
                                                                        type ===
                                                                        "Member Bookings"
                                                                            ? day.memberBookings
                                                                            : day.nonMemberBookings)
                                                            );
                                                        });

                                                        return {
                                                            name: type,
                                                            type: "bar",
                                                            stack: "total",
                                                            label: {
                                                                show: true,
                                                                position:
                                                                    "inside",
                                                            },
                                                            color:
                                                                type ===
                                                                "Member Bookings"
                                                                    ? Colours.primary
                                                                    : Colours.infoSoft,
                                                            data: data,
                                                        };
                                                    }),
                                                ],
                                            }}
                                        />
                                    </WrapperPDFEChart>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Grid item sm={12} md={12}>
                            <br />
                            <Skeleton height={250} variant="rounded" />
                            <br />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default MembershipBookingsReport;
