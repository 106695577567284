import { PDFExportTypes } from "../types/PDFExportTypes";

const PDFExportInitial = {
    status: "empty",
    data: {},
    hotelID: 0,
    showButton: false,
};

export const PDFExportReducer = (state = PDFExportInitial, action) => {
    switch (action.type) {
        case PDFExportTypes.toggleButton:
            return {
                ...state,
                toggleButton: !state.toggleButton,
            };
        case PDFExportTypes.loadOne:
            return {
                ...state,
                status: "loaded",
                data: {
                    ...state.data,
                    [action.key]: {
                        component: action.payload,
                        options: action.options,
                    },
                },
                hotelID: action.hotelID,
            };
        case PDFExportTypes.removeOne:
            let aux = { ...state.data };
            delete aux[action.key];
            return {
                ...state,
                status: "loaded",
                data: aux,
                hotelID: action.hotelID,
            };
        case PDFExportTypes.clean:
            return PDFExportInitial;

        default:
            return state;
    }
};
