import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";
import {
    Container,
    keyframes,
    Button,
    Stack,
    Typography,
    Grid,
    Box,
    Paper,
    IconButton,
    TextField,
    Divider,
} from "@mui/material";

import Fuse from "fuse.js";

import { useSnackbar } from "notistack";

import { GBH_ID, HOTELS_INFO, URLAPI } from "../configuration";
import Icons from "../helpers/icons";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const haveCommonElements = (list1, list2) => {
    const set1 = new Set(list1);
    for (const item of list2) {
        if (set1.has(item)) {
            return true;
        }
    }
    return false;
};

const EmailListModal = ({ setOpen, setKeepOpen }) => {
    const auth = useSelector((state) => state.auth);

    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState();

    const [users, setUsers] = useState([]);
    const loadUsers = async () => {
        if (auth.user) {
            setLoading(true);
            try {
                const response = await Axios.get(`${URLAPI}/users`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });
                const users = response.data.data
                    .filter((r) =>
                        haveCommonElements(
                            Object.keys(HOTELS_INFO),
                            r.hotel_id.map((h) => String(h))
                        )
                    ) // Pull only our hotels from the config file
                    .map((user) => ({
                        ...user,
                        fullName: `${user.name} ${user.last_name}`,
                    }));

                // console.log(users[0]);
                setUsers(users);

                let aux_toRecieve = new Set();
                let aux_notToRecieve = new Set();
                users.forEach((e) => {
                    if (
                        e.email_hotel.includes(
                            hotelID === "default" ? GBH_ID : hotelID
                        )
                    ) {
                        aux_toRecieve.add(e);
                    } else {
                        aux_notToRecieve.add(e);
                    }
                });

                console.log(aux_toRecieve, aux_notToRecieve);
                setToReceive(aux_toRecieve);
                setNotToReceive(aux_notToRecieve);
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        loadUsers();
    }, [hotelID, auth, reload]);

    const [toReceive, setToReceive] = useState(new Set());
    const [notToReceive, setNotToReceive] = useState(new Set());

    const [filteredUsers, setFilteredUsers] = useState(new Set());

    const [querySearch, setQuerySearch] = useState("");

    const handleRemoveUser = (e) => {
        console.log(e);

        setToReceive((prev) => {
            const newSet = new Set(prev);
            newSet.delete(e);
            return newSet;
        });

        setNotToReceive((prev) => new Set(prev.add(e)));
    };

    const handleAddUser = (e) => {
        console.log(e);

        setNotToReceive((prev) => {
            const newSet = new Set(prev);
            newSet.delete(e);
            return newSet;
        });

        setToReceive((prev) => new Set(prev.add(e)));
    };

    // useEffect(() => {
    //     console.log(toReceive, notToReceive);
    // }, [toReceive, notToReceive]);

    useEffect(() => {
        // console.log(querySearch, notToReceive);
        if (Array.from(notToReceive).length > 0) {
            // console.log("Entering filterUsers");
            filterUsers();
        }
    }, [querySearch, notToReceive]);

    const filterUsers = () => {
        // console.log("In filterUsers");

        let aux_filtered_users = notToReceive;

        if (querySearch === "") {
            setFilteredUsers(notToReceive);
            return;
        }

        const searchOptions = {
            threshold: 0.2,
            keys: [{ name: "fullName" }],
        };

        const fuseSearch = new Fuse(Array.from(notToReceive), searchOptions);

        aux_filtered_users = fuseSearch
            .search(querySearch)
            .map(({ item: e, index }) => e);

        setFilteredUsers(aux_filtered_users);
    };

    const { enqueueSnackbar } = useSnackbar();

    const saveData = async () => {
        const receiveUsers = Array.from(toReceive).map((e) => e.email);
        const notReceiveUsers = Array.from(notToReceive).map((e) => e.email);

        console.log(receiveUsers, notReceiveUsers);

        setUpdating(true);
        try {
            const response = await Axios.post(
                `${URLAPI}/email/users_receive`,
                {
                    hotel_id: hotelID === "default" ? GBH_ID : hotelID,
                    receive_users: receiveUsers,
                    not_receive_users: notReceiveUsers,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                }
            );
            console.log("Save response:", response.data);
            enqueueSnackbar(`Saved successfully`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
                variant: "success",
                autoHideDuration: 3000,
            });

            setUpdating(false);

            // await loadUsers();

            // setToReceive(new Set());
            // setNotToReceive(new Set());
        } catch (error) {
            // loadUsers();

            console.error("Error saving data:", error);
            enqueueSnackbar("Error at saving", {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
                variant: "error",
                autoHideDuration: 3000,
            });

            setUpdating(false);
        } finally {
            setOpen(false);
            setKeepOpen(false);
        }
    };
    return (
        <>
            <Container>
                {!loading ? (
                    <>
                        <Grid container>
                            <Grid item xs={12} mt={4}>
                                <Stack
                                    direction="row"
                                    sx={{
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography variant="h5">
                                        Edit email recipients
                                    </Typography>
                                    <IconButton
                                        onClick={() => {
                                            setKeepOpen(false);
                                            setOpen(false);
                                        }}
                                    >
                                        <Icons.CloseNavbar />
                                    </IconButton>
                                </Stack>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                            <Grid item xs={12} my={2}>
                                <Stack
                                    direction="row"
                                    sx={{
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography variant="caption">
                                        Here is a list of people who currently
                                        recieve email reports
                                    </Typography>
                                    {updating ? (
                                        <IconButton variant="outlined">
                                            <Icons.Refresh
                                                sx={{
                                                    animation: `${rotate} 2s linear infinite`,
                                                }}
                                            />
                                        </IconButton>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                if (
                                                    toReceive.size !== 0 ||
                                                    notToReceive.size !== 0
                                                )
                                                    saveData();
                                            }}
                                        >
                                            SAVE
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>

                            {Array.from(toReceive).map((user) => (
                                <Grid item component={Paper} xs={12}>
                                    <Stack
                                        direction={"row"}
                                        sx={{
                                            justifyContent: "space-between",
                                            p: 2,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box>
                                            <Stack
                                                direction="row"
                                                sx={{ alignItems: "center" }}
                                            >
                                                <Typography>
                                                    {user.fullName}{" "}
                                                </Typography>
                                                {/* {user.is_aro ? (
                                                <Box
                                                    sx={{
                                                        backgroundColor:
                                                            Colours.tableRowBackground,
                                                        borderRadius: "10px",
                                                        p: 0.2,
                                                        ml: 1,
                                                        width: "fit-content",
                                                    }}
                                                >
                                                    <Typography variant="caption">
                                                        (Aro)
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                ""
                                            )} */}
                                            </Stack>
                                            <Typography>
                                                {user.email}
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            onClick={(event) =>
                                                handleRemoveUser(user)
                                            }
                                            // onChange={(event) =>
                                            //     handleChangeReceiveEmail(
                                            //         user,
                                            //         event.target.checked
                                            //     )
                                            // }
                                        >
                                            <Icons.Remove />
                                        </IconButton>
                                    </Stack>
                                </Grid>
                            ))}

                            <Grid item xs={12} mt={4}>
                                <Typography variant="caption">
                                    Choose which people to add to the mailing
                                    list
                                </Typography>
                            </Grid>

                            <Grid item my={4}>
                                <Stack>
                                    <Typography
                                        variant="caption"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Search for
                                    </Typography>
                                    <TextField
                                        id="search-bar"
                                        className="text"
                                        onInput={(e) => {
                                            setQuerySearch(e.target.value);
                                        }}
                                        value={querySearch}
                                        variant="outlined"
                                        placeholder="Find whoever you like..."
                                        sx={{
                                            minWidth: "300px",
                                            // height: ITEM_HEIGHT,
                                        }}
                                        autoComplete="off"
                                        size={"small"}
                                        autoFocus
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        {Array.from(filteredUsers)
                            .slice(0, 7)
                            .map((user) => (
                                <Grid item component={Paper} xs={12}>
                                    <Stack
                                        direction={"row"}
                                        sx={{
                                            justifyContent: "space-between",
                                            p: 2,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box>
                                            <Stack
                                                direction="row"
                                                sx={{ alignItems: "center" }}
                                            >
                                                <Typography>
                                                    {user.fullName}{" "}
                                                </Typography>
                                                {/* {user.is_aro ? (
                                                <Box
                                                    sx={{
                                                        backgroundColor:
                                                            Colours.tableRowBackground,
                                                        borderRadius: "10px",
                                                        p: 0.2,
                                                        ml: 1,
                                                        width: "fit-content",
                                                    }}
                                                >
                                                    <Typography variant="caption">
                                                        (Aro)
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                ""
                                            )} */}
                                            </Stack>
                                            <Typography>
                                                {user.email}
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            onClick={(event) =>
                                                handleAddUser(user)
                                            }
                                        >
                                            <Icons.Add />
                                        </IconButton>
                                    </Stack>
                                </Grid>
                            ))}
                    </>
                ) : (
                    <Box sx={{ height: "100%", width: "100%" }}>
                        <Icons.Refresh
                            sx={{
                                animation: loading
                                    ? `${rotate} 2s linear infinite`
                                    : "none",
                                /* Center vertically and horizontally */
                                fontSize: "64px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    </Box>
                )}
            </Container>
        </>
    );
};

export default EmailListModal;
