import React, { useEffect, useState } from "react";

import Axios from "axios";

import {
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

import TableBodyCell from "../TableBodyCell";
import ErrorIcon from "../ErrorIcon";
import TableHeaderCell from "../TableHeaderCell";
import DenseTableModal from "./DenseTableModal";

import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";
import { URLAPI } from "../../configuration";
import { fns } from "../../helpers/common";
import { useSelector } from "react-redux";

const getDetailsData = async (hotelID, searchDate, jwt) => {
    return new Promise((resolve, reject) => {
        Axios({
            method: "post",
            url: `${URLAPI}/lost_revenue/details/${hotelID}`,
            data: { from: searchDate, to: searchDate },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
            },
        })
            .then((response) => {
                const {
                    data: { data },
                } = response;
                resolve(data);
            })
            .catch((error_) => {
                console.log({ error_ });
                reject([]);
            });
    });
};

const LostRevenueReportRow = ({
    hotelID,
    rowData,
    header,
    isMobile = false,
}) => {
    const { auth } = useSelector((state) => state);

    const [clicked, setClicked] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [rawDetails, setRawDetails] = useState([]);
    const [procDetails, setProcDetails] = useState([]);
    const [openMoreDetails, setOpenMoreDetails] = useState(false);

    useEffect(() => {
        if (clicked && rawDetails.length === 0) {
            const fetchData = async () => {
                try {
                    const auxRawDetails = await getDetailsData(
                        hotelID,
                        new Date(rowData.searchDate).toISOString(),
                        (auth.user ?? {}).jwt ?? ""
                    );
                    if (auxRawDetails.length > 0) {
                        setRawDetails(auxRawDetails);
                        setConnectionError(false);
                    } else {
                        setConnectionError(true);
                    }
                } catch (error) {
                    console.log(error);
                    setConnectionError(true);
                }
            };
            fetchData();
        }
    }, [clicked]);

    useEffect(() => {
        if (rawDetails.length > 0) {
            let auxProcDetails = {};

            rawDetails.forEach((element) => {
                if (auxProcDetails.hasOwnProperty(element.Reason)) {
                    if (
                        auxProcDetails[element.Reason].min.lostRevenue >
                        element.Revenue
                    ) {
                        auxProcDetails[element.Reason].min = {
                            lostRevenue: element.Revenue,
                            offerTitle: element.OfferTitle,
                            availabilityDate: element.AvailabilityDate,
                        };
                    }

                    if (
                        auxProcDetails[element.Reason].max.lostRevenue <
                        element.Revenue
                    ) {
                        auxProcDetails[element.Reason].max = {
                            lostRevenue: element.Revenue,
                            offerTitle: element.OfferTitle,
                            availabilityDate: element.AvailabilityDate,
                        };
                    }
                } else {
                    auxProcDetails[element.Reason] = {
                        min: {
                            lostRevenue: element.Revenue,
                            offerTitle: element.OfferTitle,
                            availabilityDate: element.AvailabilityDate,
                        },
                        max: {
                            lostRevenue: element.Revenue,
                            offerTitle: element.OfferTitle,
                            availabilityDate: element.AvailabilityDate,
                        },
                    };
                }
            });

            setProcDetails(auxProcDetails);
        }
    }, [rawDetails]);

    const formatValueOrDate = (prop, value) => {
        let leftUnit = (header.find((e) => e.id === prop) ?? {}).leftUnit ?? "";
        return `${leftUnit}${
            prop === "searchDate" || prop === "availabilityDate"
                ? new Date(value).toISOString().slice(0, 10)
                : fns(
                      hotelID,
                      value,
                      (header.find((e) => e.id === prop) ?? {}).format ?? {}
                  )
        }`;
    };

    return (
        <>
            <TableRow
                key={Object.values(rowData)[0]}
                onClick={() => setClicked(!clicked)}
                sx={
                    clicked
                        ? { backgroundColor: Colours.selectedRow }
                        : undefined
                }
                style={{ cursor: "pointer" }}
            >
                {header.map((head, index) => (
                    <TableBodyCell
                        key={head.id}
                        style={
                            isMobile && index === 0
                                ? {
                                      position: "sticky",
                                      left: 0,
                                      zIndex: 1,
                                      backgroundColor: clicked
                                          ? Colours.selectedRow
                                          : Colours.plainWhite,
                                  }
                                : {}
                        }
                    >
                        {formatValueOrDate(head.id, rowData[head.id])}
                    </TableBodyCell>
                ))}
            </TableRow>
            {/* TODO */}
            {clicked &&
                (Object.entries(procDetails).length > 0 ? (
                    <>
                        <TableRow
                            onClick={() => setClicked(!clicked)}
                            sx={
                                clicked
                                    ? {
                                          backgroundColor: Colours.selectedRow,
                                      }
                                    : undefined
                            }
                        >
                            <TableCell
                                colSpan={6}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableHeaderCell
                                                style={{
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: 1,
                                                    backgroundColor:
                                                        Colours.selectedRow,
                                                }}
                                            >
                                                Reason
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                {`Lost Revenue (Min/Max)`}
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                {`Availability Date (Min/Max)`}
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <span>
                                                        {`Offer Title (Min/Max)`}
                                                    </span>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setOpenMoreDetails(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <Icons.Table fontSize="small" />
                                                    </IconButton>
                                                </Stack>
                                            </TableHeaderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(procDetails).map(
                                            (element) => (
                                                <>
                                                    <TableRow>
                                                        <TableCell
                                                            rowSpan={2}
                                                            style={{
                                                                position:
                                                                    "sticky",
                                                                left: 0,
                                                                zIndex: 1,
                                                                backgroundColor:
                                                                    Colours.selectedRow,
                                                            }}
                                                        >
                                                            {element[0]}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatValueOrDate(
                                                                "lostRevenue",
                                                                element[1].min
                                                                    .lostRevenue
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatValueOrDate(
                                                                "availabilityDate",
                                                                element[1].min
                                                                    .availabilityDate
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                element[1].min
                                                                    .offerTitle
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            {formatValueOrDate(
                                                                "lostRevenue",
                                                                element[1].max
                                                                    .lostRevenue
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatValueOrDate(
                                                                "availabilityDate",
                                                                element[1].max
                                                                    .availabilityDate
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                element[1].max
                                                                    .offerTitle
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    </>
                ) : (
                    <TableRow
                        key={"skeleton"}
                        sx={
                            clicked
                                ? {
                                      backgroundColor: Colours.selectedRow,
                                  }
                                : undefined
                        }
                    >
                        <ErrorIcon show={connectionError} />
                        <TableCell colSpan={header.length}>
                            <Skeleton />
                        </TableCell>
                    </TableRow>
                ))}
            <DenseTableModal
                open={openMoreDetails}
                setOpen={setOpenMoreDetails}
                data={rawDetails.map((e) => ({
                    ...e,
                    AvailabilityDate: new Date(e["AvailabilityDate"])
                        .toISOString()
                        .slice(0, 10),
                }))}
                hideKeys={["SiteID", "SiteName", "SearchDate"]}
            />
        </>
    );
};

export default LostRevenueReportRow;
