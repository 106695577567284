import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import RoundedButton from "../components/RoundedButton";

const NotFoundScreen = () => {
    let location = useLocation();
    return (
        <Container>
            <br />
            <br />
            <br />
            <br />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Typography variant="h1">404</Typography>
                <Typography>
                    Page not found:{" "}
                    <Box component="span" fontWeight="bold">
                        {location.pathname}
                    </Box>
                </Typography>

                <RoundedButton to="/app">Home</RoundedButton>
            </Stack>
            <br />
            <br />
            <br />
            <br />
        </Container>
    );
};

export default NotFoundScreen;
