import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Divider,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import EventDisplay from "./EventDisplay";

import { newDateUTC } from "../../helpers/dates";
import { processEvents } from "../../helpers/reports";
import NoDataRow from "../NoDataRow";
import Icons from "../../helpers/icons";
import Carousel from "react-material-ui-carousel";
import Colours from "../../helpers/colours";
const EventsTimeCarousel = ({}) => {
    const theme = useTheme();
    let downXs = useMediaQuery(theme.breakpoints.down("xs"));
    let downSm = useMediaQuery(theme.breakpoints.down("sm"));
    let downMd = useMediaQuery(theme.breakpoints.down("md"));

    const [size, setSize] = useState(6);
    useEffect(() => {
        let auxSize = downXs ? 1 : downSm ? 2 : downMd ? 3 : 6;
        setSize(auxSize);
    }, [downXs, downSm, downMd]);

    const { dataSimple } = useSelector((state) => state);
    const js = useSelector((state) => state.globalReportFilters);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [events, setEvents] = useState({});
    useEffect(() => {
        if (dataSimple.status === "error") {
            return;
        }

        if (dataSimple.status === "loaded") {
            if (!dataSimple.data.hasOwnProperty("Events")) {
                return;
            }

            const data = dataSimple.data.Events;
            let auxEvents = processEvents(data);
            setEvents(auxEvents);
        }
    }, [dataSimple, hotelID]);

    const [filteredEvents, setFilteredEvents] = useState([]);

    useEffect(() => {
        if (
            js.hasOwnProperty("fromDay") &&
            js.hasOwnProperty("toDay") &&
            Object.keys(events).length > 0
        ) {
            const { fromDay, toDay } = js;

            let auxFilteredEvents = [];

            Object.keys(events).forEach((dateStr) => {
                if (dateStr >= fromDay && dateStr <= toDay) {
                    auxFilteredEvents = [
                        ...auxFilteredEvents,
                        ...events[dateStr],
                    ];
                }
            });

            auxFilteredEvents.sort(
                (a, b) => new Date(a.FromDate) - new Date(b.FromDate)
            );

            setFilteredEvents(auxFilteredEvents);
        }
    }, [js, events]);

    const isActualEvent = (event) => {
        const today = new Date().toISOString().slice(0, 10);
        return (
            event.FromDate.toISOString().slice(0, 10) >= today ||
            event.ToDate.toISOString().slice(0, 10) >= today
        );
    };

    const [visibleIndex, setVisibleIndex] = useState(0);

    useEffect(() => {
        if (filteredEvents.length > 0) {
            let auxVisibleIndex = filteredEvents.findIndex(isActualEvent);
            if (auxVisibleIndex < 0 || filteredEvents.length < size) {
                auxVisibleIndex = 0;
            } else if (
                filteredEvents.length - size >= 0 &&
                auxVisibleIndex >= filteredEvents.length - size
            ) {
                auxVisibleIndex = filteredEvents.length - size;
            }
            setVisibleIndex(auxVisibleIndex);
        }
    }, [filteredEvents]);

    const handleNext = () => {
        if (visibleIndex + 1 <= filteredEvents.length - size) {
            setVisibleIndex(visibleIndex + 1);
        }
    };
    const handlePrev = () => {
        if (visibleIndex - 1 >= 0) {
            setVisibleIndex(visibleIndex - 1);
        }
    };
    if (filteredEvents.length > 0) {
        return (
            <>
                <Divider
                    sx={{
                        marginY: 2,

                        borderColor: Colours.navbarBottomLine,
                    }}
                />
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={5}
                >
                    <Grid container alignItems={"center"} width={"15%"}>
                        <Grid item xs={2.5}>
                            {filteredEvents.length > size && (
                                <IconButton
                                    onClick={handlePrev}
                                    disabled={visibleIndex === 0}
                                >
                                    <Icons.ArrowLeft />
                                </IconButton>
                            )}
                        </Grid>
                        <Grid item xs={7}>
                            <Typography
                                textAlign={"center"}
                                fontWeight={"bold"}
                            >
                                Events
                            </Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            {filteredEvents.length > size && (
                                <IconButton
                                    onClick={handleNext}
                                    disabled={
                                        visibleIndex >=
                                        filteredEvents.length - size
                                    }
                                >
                                    <Icons.ArrowRight />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container alignItems={"center"} width={"90%"}>
                        {filteredEvents
                            .slice(visibleIndex, visibleIndex + size)
                            .map((event, index) => (
                                <Grid
                                    lg={2}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item
                                    key={index}
                                    sx={
                                        isActualEvent(event)
                                            ? {}
                                            : { opacity: 0.7 }
                                    }
                                >
                                    <EventDisplay
                                        event={event}
                                        handleRefreshEvents={() => {}}
                                        mode={"card"}
                                        clickable={true}
                                        highlight={
                                            event.FromDate.toISOString().slice(
                                                0,
                                                10
                                            ) ===
                                            new Date()
                                                .toISOString()
                                                .slice(0, 10)
                                        }
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </Stack>
            </>
        );
    }
};

export default EventsTimeCarousel;
