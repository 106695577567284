import { dataBookingsTypes } from "../types/dataBookingsTypes";

export const loading = (hotelID) => {
    return {
        type: dataBookingsTypes.loading,
        hotelID,
    };
};
export const partialLoad = (data, hotelID, lastEntry) => {
    return {
        type: dataBookingsTypes.partialLoad,
        payload: data,
        lastEntry,
        hotelID,
    };
};
export const load = (data, hotelID, lastEntry) => {
    return {
        type: dataBookingsTypes.load,
        payload: data,
        lastEntry,
        hotelID,
    };
};
export const error = (error, data) => {
    return {
        type: dataBookingsTypes.error,
        payload: data,
        error: error,
    };
};
