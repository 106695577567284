import { authRolesTypes } from "../types/authRolesTypes";

export const authRolesReducer = (
    state = { original: [], edited: [], hotels: [], loaded: false },
    action
) => {
    switch (action.type) {
        case authRolesTypes.read:
            return action.payload;

        case authRolesTypes.clean:
            return {};

        case authRolesTypes.toHotel:
            console.log({ action, state });
            return {
                ...state,
                original: state.original,
                edited: ["HotelGeneral"],
            };

        case authRolesTypes.toOriginal:
            return {
                ...state,
                original: state.original,
                edited: state.original,
            };

        default:
            return state;
    }
};
