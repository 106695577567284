import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Container,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import DateSelector, {
    currentMonthButton,
    currentYearButton,
    lastMonthButton,
} from "../DateSelector";
import SwitchVisualTable, {
    TABLE_MODE,
    TABULAR_MODE,
} from "../SwitchVisualTable";
import OptionsButtonDialog from "../OptionsButtonDialog";

import TableKeyToTime from "../TableKeyToTime";
import KeyTabsWithTrendGraph from "../KeyTabsWithTrendGraph";

import { round } from "../../helpers/reports";
import { months } from "../../helpers/dates";
import { reservationsToYearMonth } from "../DataLoader";

const CancellationByReport = ({
    hotelID,
    cancelBy,
    title = "Cancellation By Arrival",
    desc = "Using the Check In date, shows data for cancellations made in time selected.",
}) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { time: sharedTime, fullYear: sharedFullYear } = useSelector(
        (state) => state.settings.settings
    );
    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(false);

    const [loaded, setLoaded] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const { dataBookings } = useSelector((state) => state);

    const [selectedData, setSelectedData] = useState({});
    const [dataByYear, setDataByYear] = useState({});
    const [dataByMonth, setDataByMonth] = useState({});

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    useEffect(() => {
        let auxSelectedData = {};
        if (dataBookings.status === "loaded") {
            switch (cancelBy) {
                case "CancellationArrivalMonth":
                    auxSelectedData = {
                        ...dataBookings,
                        data: reservationsToYearMonth(
                            dataBookings.data,
                            "CheckIn"
                        ),
                    };
                    break;
                case "CancellationBookingMonth":
                    auxSelectedData = {
                        ...dataBookings,
                        data: reservationsToYearMonth(
                            dataBookings.data,
                            "ResDate"
                        ),
                    };
                    break;
                default:
                    auxSelectedData = { status: "error" };
            }
        }

        setSelectedData(auxSelectedData);
    }, [hotelID, cancelBy, dataBookings]);

    // Process & Set data
    useEffect(() => {
        if (selectedData.status === "error") setConnectionError(true);
        if (selectedData.status === "loaded") {
            setConnectionError(false);
            const processAndSetData = () => {
                let emptyHeader = Object.assign(
                    {},
                    ...HEADER.map((head) => ({ [head.id]: 0 }))
                );
                let auxDataByYear = {};

                let auxDataByMonth = {};

                // Iterate through all years
                Object.entries(selectedData.data).forEach(
                    ([year, dataYear]) => {
                        let auxDataYear = {};
                        Object.assign(auxDataYear, emptyHeader);

                        let auxYearBookingWindow = 0;
                        // let auxYearDailyRate = 0;

                        auxDataByMonth[year] = {};

                        // Iterate through months of [year]
                        Object.entries(dataYear).forEach(
                            ([monthName, dataMonth]) => {
                                const monthNumber = months.findIndex(
                                    (name) => name === monthName
                                );

                                let auxDataMonth = {};

                                // Get days of the month
                                const lastDay = new Date(
                                    Date.UTC(year, monthNumber + 1, 0)
                                ).getUTCDate();
                                let days = {};
                                for (let i = 1; i <= lastDay; i++) {
                                    days[i] = {};
                                    Object.assign(days[i], emptyHeader);
                                }

                                Object.assign(auxDataMonth, emptyHeader);
                                let auxBookingWindow = 0;
                                // let auxDailyRate = 0;

                                // Iterate through bookings of [month]
                                dataMonth.forEach((dataBooking) => {
                                    if (dataBooking.CancelStatus === 1) {
                                        auxDataMonth.bookings++;
                                        auxDataMonth.roomNights +=
                                            dataBooking.RoomNights ?? 0;
                                        auxDataMonth.revenue +=
                                            dataBooking.RoomRevenue ?? 0;
                                        // auxDailyRate +=
                                        //     dataBooking.DailyRoomRate ?? 0;
                                        auxBookingWindow +=
                                            dataBooking.BookingWindows ?? 0;

                                        // Details for each day
                                        let day = new Date(
                                            cancelBy ===
                                            "CancellationBookingMonth"
                                                ? dataBooking["ResDate"]
                                                : dataBooking["CheckIn"]
                                        ).getUTCDate();
                                        if (days.hasOwnProperty(day)) {
                                            days[day].bookings++;
                                            days[day].roomNights +=
                                                dataBooking.RoomNights ?? 0;
                                            days[day].revenue +=
                                                dataBooking.RoomRevenue ?? 0;
                                            days[day].ADR +=
                                                dataBooking.DailyRoomRate ?? 0;
                                            days[day].ABW +=
                                                dataBooking.BookingWindows ?? 0;
                                        } else {
                                            console.error("Day not found: ", {
                                                days,
                                                day,
                                            });
                                        }
                                    }
                                });

                                if (auxDataMonth.bookings > 0) {
                                    auxDataMonth.ALoS = round(
                                        auxDataMonth.roomNights /
                                            auxDataMonth.bookings,
                                        2
                                    );
                                    auxDataMonth.ABW = Math.round(
                                        auxBookingWindow /
                                            auxDataMonth.bookings,
                                        2
                                    );
                                }
                                if (auxDataMonth.roomNights > 0)
                                    auxDataMonth.ADR = Math.round(
                                        auxDataMonth.revenue /
                                            auxDataMonth.roomNights
                                    );

                                for (let day in days) {
                                    if (days[day].bookings > 0) {
                                        days[day].ALoS = round(
                                            days[day].roomNights /
                                                days[day].bookings,
                                            2
                                        );
                                        days[day].ABW = Math.round(
                                            auxBookingWindow /
                                                days[day].bookings,
                                            2
                                        );
                                    }
                                    if (days[day].roomNights > 0)
                                        days[day].ADR = Math.round(
                                            days[day].revenue /
                                                days[day].roomNights
                                        );
                                }
                                auxDataMonth.Details = days;

                                // Month calculated, add it to auxYear
                                Object.keys(auxDataYear).forEach(
                                    (key) =>
                                        (auxDataYear[key] += auxDataMonth[key])
                                );
                                auxYearBookingWindow += auxBookingWindow;
                                // auxYearDailyRate += auxDailyRate;

                                // Save month on auxDataByMonth
                                auxDataByMonth[year][monthNumber] =
                                    auxDataMonth;
                            }
                        );

                        if (auxDataYear.bookings > 0) {
                            auxDataYear.ALoS = round(
                                auxDataYear.roomNights / auxDataYear.bookings,
                                2
                            );
                            auxDataYear.ABW = Math.round(
                                auxYearBookingWindow / auxDataYear.bookings,
                                2
                            );
                        }
                        if (auxDataYear.roomNights > 0)
                            auxDataYear.ADR = Math.round(
                                auxDataYear.revenue / auxDataYear.roomNights
                            );

                        // Year calculated, add it to auxDataByYear
                        auxDataByYear[year] = auxDataYear;
                    }
                );
                // Set DataByYear
                setDataByYear(auxDataByYear);

                // Set monthsData
                setDataByMonth(auxDataByMonth);
            };

            processAndSetData();

            setLoaded(true);
        } else setLoaded(false);
    }, [selectedData]);

    const [mode, setMode] = useState(TABLE_MODE);
    const REPORT_DESC = desc;
    const TITLE = title;
    const HEADER = [
        {
            id: "bookings",
            title: "Bookings",
            isInverted: true,
        },
        {
            id: "roomNights",
            title: "Room Nights",
            isInverted: true,
        },
        {
            id: "revenue",
            title: "Revenue",
            format: { left: "currency" },
            isInverted: true,
        },

        {
            id: "ADR",
            title: "ADR",
            format: { left: "currency" },
            isInverted: true,
        },
        {
            id: "ALoS",
            title: "ALoS",
            format: { right: " days", maxDecimals: 2 },
            isInverted: true,
        },
        {
            id: "ABW",
            title: "ABW",
            format: { right: " days", maxDecimals: 2 },
            isInverted: true,
        },
    ];

    return (
        <Stack direction={"column"} spacing={0.1}>
            <Container maxWidth={false} component={HoverPaper}>
                <br />
                <Stack
                    direction={{ md: "column", sm: "row", xs: "row" }}
                    justifyContent={"flex-start"}
                >
                    {/* Title */}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography fontWeight={"bold"}>{TITLE}</Typography>

                        <InfoIcon text={REPORT_DESC} />
                        <ErrorIcon show={connectionError} />
                    </Stack>

                    {isMobile ? (
                        <OptionsButtonDialog>
                            <SwitchVisualTable
                                collapse={true}
                                modes={[TABLE_MODE, TABULAR_MODE]}
                                mode={mode}
                                setMode={setMode}
                            />
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                                year={mode !== TABLE_MODE}
                                buttons={
                                    mode === TABLE_MODE
                                        ? [
                                              {
                                                  label: "Years",
                                                  value: currentYearButton(),
                                                  fullYear: true,
                                              },
                                              {
                                                  label: "Current Month",
                                                  value: currentMonthButton(),
                                                  fullYear: false,
                                              },
                                              {
                                                  label: "Last Month",
                                                  value: lastMonthButton(),
                                                  fullYear: false,
                                              },
                                          ]
                                        : "default"
                                }
                            />
                            {""}
                        </OptionsButtonDialog>
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <SwitchVisualTable
                                modes={[TABLE_MODE, TABULAR_MODE]}
                                mode={mode}
                                setMode={setMode}
                            />

                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                                year={mode !== TABLE_MODE}
                                buttons={
                                    mode === TABLE_MODE
                                        ? [
                                              {
                                                  label: "Years",
                                                  value: currentYearButton(),
                                                  fullYear: true,
                                              },
                                              {
                                                  label: "Current Month",
                                                  value: currentMonthButton(),
                                                  fullYear: false,
                                              },
                                              {
                                                  label: "Last Month",
                                                  value: lastMonthButton(),
                                                  fullYear: false,
                                              },
                                          ]
                                        : "default"
                                }
                            />
                        </Stack>
                    )}
                </Stack>

                {mode === TABLE_MODE && (
                    //   _____  _    ____  _     _____
                    //  |_   _|/ \  | __ )| |   | ____|
                    //    | | / _ \ |  _ \| |   |  _|
                    //    | |/ ___ \| |_) | |___| |___
                    //    |_/_/   \_\____/|_____|_____|

                    <TableKeyToTime
                        tableKey={TITLE}
                        header={HEADER}
                        dataMonths={dataByMonth}
                        dataYears={dataByYear}
                        fullYear={fullYear}
                        time={time}
                    />
                )}
                <br />
                {!loaded ? (
                    <>
                        <ProgressBarSteps />
                    </>
                ) : (
                    ""
                )}
            </Container>
            {mode === TABULAR_MODE && (
                //    ____    _    ____  ____  ____
                //   / ___|  / \  |  _ \|  _ \/ ___|
                //  | |     / _ \ | |_) | | | \___ \
                //  | |___ / ___ \|  _ <| |_| |___) |
                //   \____/_/   \_\_| \_\____/|____/
                <KeyTabsWithTrendGraph
                    TITLE={TITLE}
                    HEADER={HEADER}
                    dataMonths={dataByMonth}
                    dataYears={dataByYear}
                    fullYear={fullYear}
                    time={time}
                />
            )}
        </Stack>
    );
};

export default CancellationByReport;
