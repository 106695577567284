import React, { useEffect, useState } from "react";

import CircleValue from "../CircleValue";

import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";
import { Skeleton } from "@mui/material";

export const HEATMAP_MODE = {
    id: "Heatmap",
    type: "heatmap",
    icon: Icons.CalendarMap,
    colour: Colours.graphTarget,
};
export const LINE_MODE = {
    id: "Lines",
    type: "line",
    icon: Icons.LineChart,
    colour: Colours.info,
};
export const BAR_MODE = {
    id: "Bars",
    type: "bar",
    icon: Icons.LeadersBoard,
    colour: Colours.primary,
};

const SwitchIcons = ({
    selectedMode = LINE_MODE,
    modes = [LINE_MODE, BAR_MODE],
    setGraphMode,
}) => {
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        let auxSelected = modes.indexOf(selectedMode);
        setSelected(auxSelected === -1 ? 0 : auxSelected);
    }, []);

    return (
        <>
            {modes.length > 0 ? (
                <CircleValue
                    tooltip={`Switch to ${
                        modes[(selected + 1) % modes.length].id
                    } graph`}
                    color={modes[(selected + 1) % modes.length].colour}
                    Icon={modes[(selected + 1) % modes.length].icon}
                    onClick={() => {
                        setGraphMode(modes[(selected + 1) % modes.length]);
                        setSelected((selected + 1) % modes.length);
                    }}
                    style={{ cursor: "pointer" }}
                />
            ) : (
                <Skeleton variant="circular"></Skeleton>
            )}
        </>
    );
};

export default SwitchIcons;
