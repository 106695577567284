import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Popover,
    Select,
    Switch,
    Typography,
} from "@mui/material";

import CustomFab from "./CustomFab";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";

import { update } from "../actions/settings";
import { SETTING_readability } from "../helpers/fabSettings";

const FabSettings = ({
    options = [SETTING_readability],
    fabPositionX = 0,
    fabPositionY = 0,
}) => {
    const { /*status,*/ settings } = useSelector((state) => state.settings);
    const {
        user: { jwt, user_id },
    } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [openSettings, setOpenSettings] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <CustomFab
                Icon={Icons.Settings}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpenSettings(!openSettings);
                }}
                positionX={0}
                positionY={0}
            />

            <Popover
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={openSettings}
                onClose={() => {
                    setOpenSettings(false);
                }}
                anchorEl={anchorEl}
                PaperProps={{ elevation: 0 }}
            >
                <Paper variant="outlined">
                    <List>
                        {options.map((option) => (
                            <ListItem key={option.id}>
                                <ListItemIcon>
                                    {[Icons[option.icon]].map((I) => (
                                        <I key="a" />
                                    ))}
                                </ListItemIcon>
                                <ListItemText primary={option.text} />
                                {option.type === "switch" ? (
                                    <Switch
                                        checked={
                                            String(
                                                settings[option.id] ??
                                                    option.default
                                            ).toLowerCase() === "true"
                                        }
                                        onChange={(e) => {
                                            dispatch(
                                                update(
                                                    option.id,
                                                    !(
                                                        settings[option.id] ??
                                                        option.default ===
                                                            "true"
                                                    ),
                                                    jwt,
                                                    user_id
                                                )
                                            );
                                        }}
                                    />
                                ) : option.type === "select1" ? (
                                    <Select
                                        variant="standard"
                                        value={
                                            settings[option.id] ??
                                            option.default
                                        }
                                        onChange={(e) => {
                                            dispatch(
                                                update(
                                                    option.id,
                                                    e.target.value,
                                                    jwt,
                                                    user_id
                                                )
                                            );
                                        }}
                                    >
                                        {Object.entries(option.options).map(
                                            ([key, value]) => (
                                                <MenuItem key={key} value={key}>
                                                    {value}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                ) : (
                                    <Typography sx={{ color: Colours.error }}>
                                        Error
                                    </Typography>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Popover>
        </>
    );
};

export default FabSettings;
