import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";
import {
    Container,
    keyframes,
    Button,
    Stack,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    Modal,
    Box,
    Paper,
} from "@mui/material";
import { useSnackbar } from "notistack";

import EmailHotelList from "../components/EmailHotelList";
import { UserList } from "../components/UsersListEmail";
import ConfirmModal from "../components/ConfirmModal";

import { GBH_ID, HOTELS_INFO, URLAPI } from "../configuration";
import Icons from "../helpers/icons";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const haveCommonElements = (list1, list2) => {
    const set1 = new Set(list1);
    for (const item of list2) {
        if (set1.has(item)) {
            return true;
        }
    }
    return false;
};
const SAVE_CONFIRM = 1;
const DISCARD_CONFIRM = 2;
const EmailScreen = () => {
    const auth = useSelector((state) => state.auth);

    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openEditConfirm, setOpenEditConfirm] = useState(0);

    const [users, setUsers] = useState([]);
    const loadUsers = async () => {
        if (auth.user) {
            setLoading(true);
            try {
                const response = await Axios.get(`${URLAPI}/users`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });
                const users = response.data.data
                    .filter((r) =>
                        haveCommonElements(
                            Object.keys(HOTELS_INFO),
                            r.hotel_id.map((h) => String(h))
                        )
                    ) // Pull only our hotels from the config file
                    .map((user) => ({
                        ...user,
                        fullName: `${user.name} ${user.last_name}`,
                    }));

                setUsers(users);
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        loadUsers();
    }, [hotelID, auth, reload]);

    const [toReceive, setToReceive] = useState(new Set());
    const [notToReceive, setNotToReceive] = useState(new Set());

    const handleChangeReceiveEmail = (user, value) => {
        const initialValue = user.email_hotel.includes(
            Number(hotelID === "default" ? 0 : hotelID)
        );
        console.log(
            `Update receive email for user ${user.email}: ${value}; initial: ${initialValue}`,
            "to:",
            toReceive,
            "not:",
            notToReceive
        );

        if (initialValue === value) {
            // remove from both, there are not changes
            setNotToReceive((prev) => {
                const newSet = new Set(prev);
                newSet.delete(user.email);
                return newSet;
            });
            setToReceive((prev) => {
                const newSet = new Set(prev);
                newSet.delete(user.email);
                return newSet;
            });
        } else {
            // Update the email_hotel list
            const updatedHotelsIdEmail = new Set(user.email_hotel ?? []);
            if (value) {
                updatedHotelsIdEmail.add(
                    Number(hotelID === "default" ? 0 : hotelID)
                );
            } else {
                updatedHotelsIdEmail.delete(
                    Number(hotelID === "default" ? 0 : hotelID)
                );
            }

            // Update sets for tracking changes
            if (value) {
                setToReceive((prev) => new Set(prev.add(user.email)));
                setNotToReceive((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(user.email);
                    return newSet;
                });
            } else {
                setNotToReceive((prev) => new Set(prev.add(user.email)));
                setToReceive((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(user.email);
                    return newSet;
                });
            }
        }
    };

    const { enqueueSnackbar } = useSnackbar();

    const saveData = async () => {
        const receiveUsers = Array.from(toReceive);
        const notReceiveUsers = Array.from(notToReceive);
        try {
            const response = await Axios.post(
                `${URLAPI}/email/users_receive`,
                {
                    hotel_id: hotelID === "default" ? GBH_ID : hotelID,
                    receive_users: receiveUsers,
                    not_receive_users: notReceiveUsers,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                }
            );
            console.log("Save response:", response.data);
            enqueueSnackbar(`Saved successfully`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
                variant: "success",
                autoHideDuration: 3000,
            });

            await loadUsers();

            setToReceive(new Set());
            setNotToReceive(new Set());
        } catch (error) {
            loadUsers();

            console.error("Error saving data:", error);
            enqueueSnackbar("Error at saving", {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
                variant: "error",
                autoHideDuration: 3000,
            });
        }
    };
    return (
        <>
            <Container>
                <Grid container>
                    <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography variant="h4">
                                Hotel Email List
                            </Typography>

                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setOpenEditModal(true);
                                    }}
                                >
                                    EDIT
                                </Button>
                                <Button variant="outlined" onClick={saveData}>
                                    SAVE
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setReload((reload) => reload + 1);
                                    }}
                                >
                                    <Icons.Refresh
                                        sx={{
                                            animation: loading
                                                ? `${rotate} 2s linear infinite`
                                                : "none",
                                        }}
                                    />
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <UserList
                    title="Users that receive email"
                    users={users.filter((user) =>
                        user.email_hotel.includes(
                            hotelID === "default" ? GBH_ID : Number(hotelID)
                        )
                    )}
                    handleChangeReceiveEmail={handleChangeReceiveEmail}
                    toReceive={toReceive}
                    notToReceive={notToReceive}
                    showCheckBox={false}
                    defaultExpanded={true}
                    notCompactTable={true}
                />
                <br />
                <Accordion
                    defaultExpanded={false}
                    sx={{
                        border: "none",
                        "&:before": { display: "none" },
                        "&:not(:last-child)": { borderBottom: 0 },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<Icons.ArrowDown />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography fontWeight={"bold"}>
                            Hotels that receive email
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <EmailHotelList reload={reload} users={users} />
                    </AccordionDetails>{" "}
                </Accordion>
            </Container>

            <Modal
                open={openEditModal}
                onClose={() => {
                    if (toReceive.size !== 0 || notToReceive.size !== 0)
                        setOpenEditConfirm(DISCARD_CONFIRM);
                    else setOpenEditModal(0);
                }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    component={Paper}
                    padding={2}
                    maxHeight={"90%"}
                    width={"80%"}
                    overflow={"auto"}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography fontWeight={"bold"} fontSize={"1.2em"}>
                            Edit your list
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            paddingBottom={2}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    if (
                                        toReceive.size !== 0 ||
                                        notToReceive.size !== 0
                                    )
                                        setOpenEditConfirm(SAVE_CONFIRM);
                                    else setOpenEditModal(0);
                                }}
                            >
                                SAVE
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    if (
                                        toReceive.size !== 0 ||
                                        notToReceive.size !== 0
                                    )
                                        setOpenEditConfirm(DISCARD_CONFIRM);
                                    else setOpenEditModal(0);
                                }}
                            >
                                CANCEL
                            </Button>
                        </Stack>
                    </Stack>
                    {console.log({ hotelID })}
                    <UserList
                        title="Preview of users that receive email"
                        users={users.filter(
                            (user) =>
                                user.email_hotel.includes(
                                    hotelID === "default"
                                        ? GBH_ID
                                        : Number(hotelID)
                                ) ||
                                toReceive.has(user.email) ||
                                notToReceive.has(user.email)
                        )}
                        handleChangeReceiveEmail={handleChangeReceiveEmail}
                        toReceive={toReceive}
                        notToReceive={notToReceive}
                        // showCheckBox={false}
                        defaultExpanded={true}
                        elevation={3}
                    />
                    <br />
                    <UserList
                        title={`This Hotel: ${hotelID}`}
                        users={users.filter(
                            (user) =>
                                !user.is_aro &&
                                user.hotel_id.includes(
                                    hotelID === "default"
                                        ? GBH_ID
                                        : Number(hotelID)
                                )
                        )}
                        handleChangeReceiveEmail={handleChangeReceiveEmail}
                        toReceive={toReceive}
                        notToReceive={notToReceive}
                        elevation={3}
                    />
                    <UserList
                        title="Aró people"
                        users={users.filter((user) => user.is_aro)}
                        handleChangeReceiveEmail={handleChangeReceiveEmail}
                        toReceive={toReceive}
                        notToReceive={notToReceive}
                        elevation={3}
                    />
                    {console.log({ users })}
                    <UserList
                        title="Others"
                        users={users.filter(
                            (user) =>
                                !user.is_aro &&
                                !user.hotel_id.includes(
                                    hotelID === "default"
                                        ? GBH_ID
                                        : Number(hotelID)
                                )
                        )}
                        handleChangeReceiveEmail={handleChangeReceiveEmail}
                        toReceive={toReceive}
                        notToReceive={notToReceive}
                        elevation={3}
                    />
                </Box>
            </Modal>
            <ConfirmModal
                isOpen={openEditConfirm}
                setIsOpen={setOpenEditConfirm}
                onAccept={() => {
                    saveData();
                    setOpenEditModal(false);
                }}
                onDecline={() => {
                    setOpenEditModal(false);
                    setToReceive(new Set());
                    setNotToReceive(new Set());
                }}
                text={`⚠ ${
                    openEditConfirm === 1 ? "Save" : "Discard"
                } changes to the email list?`}
                subtext={`Email list ${
                    openEditConfirm === 1 ? "will" : "won't"
                } be updated`}
                acceptText="Save changes"
                declineText="Discard changes"
            />
        </>
    );
};

export default EmailScreen;
