import { dataVouchersDetailsTypes } from "../types/dataVouchersDetailsTypes";

const dataVouchersDetailsInitial = {
    status: "empty",
    reload: new Date().toISOString(),
};
export const dataVouchersDetailsReducer = (
    state = dataVouchersDetailsInitial,
    action
) => {
    switch (action.type) {
        case dataVouchersDetailsTypes.loading:
            return {
                ...state,
                status: "loading",
                data: {},
                hotelID: action.hotelID,
            };

        case dataVouchersDetailsTypes.partialLoad:
            return {
                ...state,
                status: "partiallyLoaded",
                data: action.payload,
                hotelID: action.hotelID,
            };

        case dataVouchersDetailsTypes.load:
            return {
                ...state,
                status: "loaded",
                data: action.payload,
                hotelID: action.hotelID,
            };

        case dataVouchersDetailsTypes.reload:
            return {
                ...state,
                status: "reload",
                reload: new Date().toISOString(),
            };

        case dataVouchersDetailsTypes.error:
            return {
                ...state,
                status: "error",
                data: action.payload,
                error: action.error,
            };

        case dataVouchersDetailsTypes.clean:
            return dataVouchersDetailsInitial;

        default:
            return state;
    }
};
