import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";

import { useSnackbar } from "notistack";

import {
    Select,
    MenuItem,
    Typography,
    FormControl,
    InputLabel,
    TextField,
    Stack,
    Grid,
    Paper,
    Box,
    Container,
    Modal,
    IconButton,
} from "@mui/material";

import { TYPE_ABCD } from "./IndicatorsTypeABCD";
import IndicatorsTypeRange from "./IndicatorsTypeRange";
import IndicatorsTypeYesNo from "./IndicatorsTypeYesNo";
import IndicatorsTypePercentage from "./IndicatorsTypePercentage";

import {
    TYPES,
    CATEGORY,
    QUALITY_PERFORMANCE,
    SUBCATEGORY,
} from "../../helpers/indicators";
import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";
import { URLAPI } from "../../configuration";

import RoundedButton from "../RoundedButton";
import ModalConfirmation from "../ModalConfirmation";

const IndicatorFormNew = ({
    setOpen = () => {},
    open = false,
    handleRefreshIndicators,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { auth } = useSelector((state) => state);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [qualityPerformance, setQualityPerformance] = useState("");
    const [type, setType] = useState("");

    const [typeUtility, setTypeUtility] = useState({
        post: () => {},
        isValid: () => {},
    });

    const [errorForm, setErrorForm] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [closeAnswer, setCloseAnswer] = useState("");
    const handleClose = () => {
        if (
            name.trim() === "" &&
            description.trim() === "" &&
            category.trim() === "" &&
            subcategory.trim() === "" &&
            qualityPerformance.trim() === "" &&
            type.trim() === ""
        )
            setOpen(false);
        else setConfirmOpen(true);
    };

    const [disabledPost, setDisabledPost] = useState(false);
    const postIndicator = async () => {
        setDisabledPost(true);
        setErrorForm(false);
        const validType = typeUtility.isValid();
        if (
            !validType ||
            name.trim() === "" ||
            description.trim() === "" ||
            category.trim() === "" ||
            subcategory.trim() === "" ||
            qualityPerformance.trim() === "" ||
            type.trim() === ""
        ) {
            setErrorForm(true);
            enqueueSnackbar("Invalid fields detected.", {
                variant: "error",
            });
            return;
        }

        try {
            const responseIndicatorCommon = await Axios({
                method: "post",
                url: `${URLAPI}/indicators/indicator`,
                data: JSON.stringify({
                    name: name,
                    description: description,
                    type: type,
                    category: category,
                    subcategory: subcategory,
                    quality_performance: qualityPerformance,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
            });
            if (responseIndicatorCommon.status === 201) {
                const indicatorID = responseIndicatorCommon.data.id;
                await typeUtility.post(auth, indicatorID);

                handleRefreshIndicators();
                enqueueSnackbar(`Indicator '${name}' has been created!`, {
                    variant: "success",
                });
            } else {
                let error = new Error(
                    "Indicator Common fields might have not been posted."
                );
                error.response = responseIndicatorCommon;
                throw error;
            }
        } catch (error) {
            console.error("Creation didn't go as expected: ", { error });
            enqueueSnackbar("Creation didn't go as expected: " + error, {
                variant: "error",
            });
        }

        setCloseAnswer("close");
        setDisabledPost(false);
    };

    useEffect(() => {
        if (closeAnswer === "close") {
            setDescription("");
            setName("");
            setCategory("");
            setSubcategory("");
            setQualityPerformance("");
            setType("");

            setErrorForm(false);
            setOpen(false);

            setCloseAnswer("");
        }
    }, [closeAnswer]);

    return (
        <>
            <ModalConfirmation
                title={"Closing form"}
                description={
                    "Are you sure you want to close it? All changes will be lost."
                }
                answers={[
                    { title: "Close", value: "close" },
                    { title: "Cancel", value: "cancel" },
                ]}
                setAnswer={setCloseAnswer}
                setOpen={setConfirmOpen}
                open={confirmOpen}
            />

            <Modal
                key={"new-indicator-modal"}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                open={open}
                onClose={() => handleClose()}
            >
                <Container
                    style={{
                        width: "90%",
                        maxHeight: "90%",
                        overflow: "auto",
                    }}
                    disableGutters
                >
                    <Paper sx={{ p: 5, paddingTop: 3 }}>
                        <Stack>
                            <Box display={"flex"} justifyContent={"right"}>
                                <IconButton onClick={() => handleClose()}>
                                    <Icons.CloseNavbar />
                                </IconButton>
                            </Box>

                            <Typography
                                variant="h6"
                                style={{
                                    borderBottom: `2px solid ${Colours.primary}`,
                                    paddingBottom: 5,
                                }}
                            >
                                New indicators can be used across all hotels
                            </Typography>
                        </Stack>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Stack direction="column" spacing={2}>
                                    <TextField
                                        required
                                        error={name.trim() === "" && errorForm}
                                        fullWidth
                                        label="Name"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                    <TextField
                                        required
                                        error={
                                            description.trim() === "" &&
                                            errorForm
                                        }
                                        fullWidth
                                        label="Description"
                                        value={description}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                    />
                                    <FormControl
                                        fullWidth
                                        required
                                        error={
                                            category.trim() === "" && errorForm
                                        }
                                    >
                                        <InputLabel id="category-label">
                                            Category
                                        </InputLabel>
                                        <Select
                                            labelId="category-label"
                                            value={category}
                                            label="Category"
                                            onChange={(event) =>
                                                setCategory(event.target.value)
                                            }
                                        >
                                            {Object.values(CATEGORY).map(
                                                ({ key, color, label }) => {
                                                    return (
                                                        <MenuItem value={key}>
                                                            <Stack
                                                                direction={
                                                                    "row"
                                                                }
                                                            >
                                                                <Icons.Circle
                                                                    sx={{
                                                                        mr: 1,
                                                                        color: color,
                                                                    }}
                                                                />
                                                                {label}
                                                            </Stack>
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={
                                            subcategory.trim() === "" &&
                                            errorForm
                                        }
                                    >
                                        <InputLabel id="category-label">
                                            SubCategory
                                        </InputLabel>
                                        <Select
                                            labelId="subCategory-label"
                                            value={subcategory}
                                            label="SubCategory"
                                            onChange={(event) =>
                                                setSubcategory(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            {Object.values(SUBCATEGORY).map(
                                                ({ key, label }) => (
                                                    <MenuItem value={key}>
                                                        {label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={
                                            qualityPerformance.trim() === "" &&
                                            errorForm
                                        }
                                    >
                                        <InputLabel id="quality-performance-label">
                                            Quality / Performance
                                        </InputLabel>
                                        <Select
                                            labelId="quality-performance-label"
                                            value={qualityPerformance}
                                            label="Quality / Performance"
                                            onChange={(event) =>
                                                setQualityPerformance(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            {Object.values(
                                                QUALITY_PERFORMANCE
                                            ).map(({ key, label }) => (
                                                <MenuItem value={key}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <Stack direction="column" spacing={2}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={type.trim() === "" && errorForm}
                                    >
                                        <InputLabel id="indicator-type-label">
                                            Indicator Type
                                        </InputLabel>
                                        <Select
                                            labelId="indicator-type-label"
                                            id="indicator-type-select"
                                            value={type}
                                            label="Indicator Type"
                                            onChange={(event) =>
                                                setType(event.target.value)
                                            }
                                        >
                                            {Object.values(TYPES).map(
                                                (type_obj) => (
                                                    <MenuItem
                                                        value={type_obj.key}
                                                    >
                                                        {type_obj.icon +
                                                            " " +
                                                            type_obj.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    {type === TYPES.RANGE.key && (
                                        <IndicatorsTypeRange
                                            new_
                                            edit
                                            utilitySetter={setTypeUtility}
                                            type={TYPES.RANGE.key}
                                            errorForm={errorForm}
                                        />
                                    )}
                                    {type === TYPES.YESNO.key && (
                                        <IndicatorsTypeYesNo
                                            new_
                                            edit
                                            utilitySetter={setTypeUtility}
                                            type={TYPES.YESNO.key}
                                            errorForm={errorForm}
                                        />
                                    )}
                                    {type === TYPES.PERCENTAGE.key && (
                                        <IndicatorsTypePercentage
                                            new_
                                            edit
                                            utilitySetter={setTypeUtility}
                                            type={TYPES.PERCENTAGE.key}
                                            errorForm={errorForm}
                                        />
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>

                        <br />

                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <RoundedButton
                                disabled={disabledPost}
                                onClick={async () => {
                                    setDisabledPost(true);

                                    await postIndicator();
                                    setDisabledPost(false);
                                }}
                            >
                                Add new
                            </RoundedButton>
                        </Box>
                    </Paper>
                </Container>
            </Modal>
        </>
    );
};

export default IndicatorFormNew;
