import { settingsTypes } from "../types/settingsTypes";
import { URLAPI } from "../configuration";
import Axios from "axios";

export const loading = () => {
    return {
        type: settingsTypes.loading,
    };
};
export const load = (user_id) => {
    return async (dispatch) => {
        await Axios({
            method: "get",
            url: `${URLAPI}/settings/${user_id}`,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            const {
                data: { data },
            } = response;
            console.log({ settings: data });
            dispatch({
                type: settingsTypes.load,
                payload: data,
            });
        });
    };
};
export const error = (error, data) => {
    //TODO
    return {
        type: settingsTypes.error,
        payload: data,
        error: error,
    };
};
export const addDefault = (elements) => {
    //TODO
    return {
        type: settingsTypes.addDefault,
        payload: elements,
    };
};
export const add = (key, value) => {
    //TODO
    return {
        type: settingsTypes.add,
        payload: { key, value },
    };
};
export const update = (key, value, jwt, user_id) => {
    Axios({
        method: "post",
        url: `${URLAPI}/settings/${user_id}`,
        // `https://arosuitestaging.arosuite.com/api/Cuchulainn/Authenticate`,
        data: JSON.stringify({
            jwt,
            key,
            value,
        }),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
        },
    });
    return {
        type: settingsTypes.update,
        payload: { key, value },
    };
};
