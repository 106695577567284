import React, { useEffect, useState } from "react";

import FilterDropdown, {
    FilterChips,
    transformArrToOptions,
} from "./FiltersDropdown";
import { HOTELS_INFO } from "../configuration";

export const FiltersLine = ({}) => {
    const [activeFilters, setActiveFilters] = useState([]);

    return (
        <FilterChips
            activeFilters={activeFilters}
            setActiveFilters={(activeFilters) => {
                setActiveFilters(activeFilters);
            }}
            filters={{
                Hotels: {
                    label: "Hotels",
                },
            }}
        />
    );
};

const FiltersByClient = ({
    height = "auto",
    hotelsList,
    setSelectedHotels,
    handleSelectedHotels,
}) => {
    const [activeFilters, setActiveFilters] = useState({ Hotels: [] });
    const [hotelsOptions, setHotelsOptions] = useState([]);

    useEffect(() => {
        // const auxHotelList = [];

        // Object.keys(HOTELS_INFO).forEach((e) => {
        //     if (!isNaN(e)) {
        //         auxHotelList.push(HOTELS_INFO[e]["name"]);
        //     }
        // });

        // setActiveFilters({ Hotels: auxHotelList.sort() });

        // setHotelsOptions(auxHotelList.sort());

        // setHotelsOptions([]);
        // if (!hotelsList || hotelsList.length === 0) return;

        setHotelsOptions(hotelsList.length > 0 ? hotelsList.sort() : []);
    }, [hotelsList]);

    useEffect(() => {
        setSelectedHotels(activeFilters.Hotels);
        handleSelectedHotels(activeFilters.Hotels);
    }, [activeFilters]);

    return (
        <>
            <FilterDropdown
                title="Select Client"
                buttonProps={{ sx: { height: height } }}
                activeFilters={activeFilters}
                setActiveFilters={(activeFilters) => {
                    setActiveFilters(activeFilters);
                }}
                filters={{
                    Hotels: {
                        label: "Hotels",
                        options: transformArrToOptions(hotelsOptions),
                    },
                }}
            />
        </>
    );
};

export default FiltersByClient;
