import React, { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";

import EventDisplay from "./EventDisplay";

import { newDateUTC } from "../../helpers/dates";

const EventsCarousel = ({
    isMobile = false,
    events,
    handleRefreshEvents,
    hotelID,
    clickable,
}) => {
    const [upComingEvents, setUpComingEvents] = useState([]);

    useEffect(() => {
        setUpComingEvents([]);
        if (Object.keys(events).length > 0) {
            const currentDateString = newDateUTC().toISOString().slice(0, 10);
            if (!events.hasOwnProperty(currentDateString)) {
                events[currentDateString] = [];
            }

            const sortedEvents = Object.keys(events)
                .sort((a, b) => new Date(a) - new Date(b))
                .reduce((acc, key) => {
                    acc[key] = events[key];
                    return acc;
                }, {});

            const indexActual =
                Object.keys(sortedEvents).indexOf(currentDateString);
            const allUpComingEvents = Object.values(sortedEvents)
                .slice(indexActual)
                .flat();

            const auxUpComingEvents = allUpComingEvents.slice(0, 3);

            setUpComingEvents(auxUpComingEvents);
        }
    }, [events]);

    return (
        <>
            <Typography fontWeight={"bold"} mb={1}>
                Upcoming Events
            </Typography>

            <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"space-around"}
            >
                {upComingEvents.length > 0 &&
                    upComingEvents.map((event, index) => {
                        return (
                            <Grid md={4} item key={index}>
                                <EventDisplay
                                    event={event}
                                    handleRefreshEvents={handleRefreshEvents}
                                    mode={"card"}
                                    clickable={clickable}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
};

export default EventsCarousel;
