import { Avatar, Tooltip, Typography, Box, Badge } from "@mui/material";
import React from "react";
import { ConditionalWrapper } from "../helpers/common";

function isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

const AdjustFontSize = (fontSize, value) => {
    const valueDigits = isNumber(value)
        ? Number(value).toFixed(0).length
        : `${value}`;
    const fontSizeNumber = fontSize.slice(0, -2);
    const percentageReduced = 0.2;
    fontSize =
        valueDigits <= 3
            ? fontSize
            : `${
                  fontSizeNumber -
                  (valueDigits - 3) * (fontSizeNumber * percentageReduced)
              }em`;
    return fontSize;
};

const CircleValue = ({
    value = "?",
    tooltip,
    color,
    size = "default",
    textColor,
    bold,
    borderColor,
    notification,
    Icon,
    src,
    ...otherProps
}) => {
    let fontSize = "0.75em";
    let width = "1.5em";
    let height = "1.5em";
    let badgeRight = "0.5em";
    let badgeTop = "0.5em";
    switch (size) {
        case "default":
            fontSize = "0.75em";
            width = "1.5em";
            height = "1.5em";
            badgeRight = "0.15em";
            badgeTop = "0.15em";
            break;
        case "small":
            fontSize = "0.5em";
            width = "1em";
            height = "1em";
            badgeRight = "0.05em";
            badgeTop = "0.05em";
            break;

        case "big":
            fontSize = "1em";
            width = "2em";
            height = "2em";
            badgeRight = "0.25em";
            badgeTop = "0.25em";
            break;
        case "verybig":
            fontSize = "1.5em";
            width = "3em";
            height = "3em";
            badgeRight = "0.5em";
            badgeTop = "0.5em";
            break;
        case "veryverybig":
            fontSize = "2em";
            width = "4em";
            height = "4em";
            badgeRight = "0.75em";
            badgeTop = "0.75em";
            break;
        case "giant":
            fontSize = "3em";
            width = "6em";
            height = "6em";
            badgeRight = "0.75em";
            badgeTop = "0.75em";
            break;

        default:
            break;
    }

    if (!Icon) fontSize = AdjustFontSize(fontSize, value);

    return (
        <>
            <ConditionalWrapper
                condition={tooltip}
                wrapper={(children) => (
                    <Tooltip title={tooltip}>{children}</Tooltip>
                )}
                children={
                    <Badge
                        color="secondary"
                        badgeContent=" "
                        sx={{
                            "& .MuiBadge-badge": {
                                right: badgeRight,
                                top: badgeTop,
                            },
                        }}
                        invisible={notification ? false : true}
                    >
                        <Avatar
                            sx={{
                                width,
                                height,
                                bgcolor: color,
                                border: `solid 1px ${borderColor}`,
                            }}
                            src={src}
                            {...otherProps}
                        >
                            {!Icon && (
                                <Typography
                                    variant="button"
                                    fontSize={fontSize}
                                    color={textColor}
                                >
                                    <Box fontWeight={bold ? "bold" : undefined}>
                                        {typeof value == "number"
                                            ? Math.round(value)
                                            : value}
                                    </Box>
                                </Typography>
                            )}
                            {Icon && (
                                <Icon sx={{ fontSize, color: textColor }} />
                            )}
                        </Avatar>
                    </Badge>
                }
            />
        </>
    );
};

export default CircleValue;
