import React, { useState } from "react";

import { Box, Divider, IconButton, Paper, Stack, Dialog } from "@mui/material";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";

const OptionsButtonDialog = ({ Icon = Icons.Options, children }) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(!open);
    };

    return (
        <>
            <IconButton
                variant="contained"
                onClick={(event) => {
                    setOpen(!open);
                }}
            >
                <Icon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <Stack
                    sx={{ p: 5 }}
                    direction={"column"}
                    divider={
                        <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{
                                m: 1,
                                mr: 2,
                                borderColor: Colours.navbarBottomLine,
                            }}
                        />
                    }
                >
                    {React.Children.map(children, (child) => {
                        return <>{child}</>;
                    })}
                </Stack>
            </Dialog>
        </>
    );
};

export default OptionsButtonDialog;
