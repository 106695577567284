import React from "react";

import { Grid, Stack, Container, Typography } from "@mui/material";

import Icons from "../helpers/icons";
import Colours, { avatarColours } from "../helpers/colours";

const IconsScreen = () => {
    return (
        <>
            <Container>
                <h1>Icons</h1>
                <Grid container>
                    {Object.entries(Icons).map(([name, I]) => (
                        <Grid item direction={"row"} xs={3}>
                            <Stack direction="row" spacing={2}>
                                <I key={name} />
                                <Typography>{name}</Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
                <h1>Colours</h1>
                <Grid container>
                    {Object.entries(Colours).map(([name, color]) => (
                        <Grid item direction={"row"} xs={3}>
                            <Stack direction="row" spacing={2}>
                                <Icons.Circle key={name} sx={{ color }} />
                                <Typography>{name}</Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
                <h2>Avatar colours</h2>
                <Grid container>
                    {Object.entries(avatarColours).map(([name, color]) => (
                        <Grid item direction={"row"} xs={3}>
                            <Stack direction="row" spacing={2}>
                                <Icons.Circle key={name} sx={{ color }} />
                                <Typography>{name}</Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <br />
            <br />
            <br />
            <br />
        </>
    );
};

export default IconsScreen;
