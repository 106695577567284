import React, { useEffect, useState } from "react";
import Axios from "axios";

import { URLAPI } from "../../configuration";

import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

import TableBodyCell from "../TableBodyCell";
import ErrorIcon from "../ErrorIcon";
import TableHeaderCell from "../TableHeaderCell";
import AllSearchesLeadTimeGraph from "./AllSearchesLeadTimeGraph";

import { fns } from "../../helpers/common";
import Colours from "../../helpers/colours";

const getDetailsData = async (hotelID, fromDate, toDate, auth) => {
    return new Promise((resolve, reject) => {
        Axios({
            method: "post",
            url: `${URLAPI}/search_requests/details/${hotelID}`,
            data: { from: fromDate, to: toDate },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                const {
                    data: { data },
                } = response;
                resolve(data);
            })
            .catch((error_) => {
                console.log({ error_ });
                reject([]);
            });
    });
};

const AllSearchesLeadTimeRow = ({
    hotelID,
    rowData,
    parentHeader,
    isMobile,
    auth,
    dataType,
}) => {
    const [clicked, setClicked] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [rawDetails, setRawDetails] = useState([]);
    const [procDetails, setProcDetails] = useState([]);

    const [fromDate, setFromDate] = useState(new Date());

    const [header, setHeader] = useState([]);

    useEffect(() => {
        switch (dataType) {
            case "Searches":
                setHeader([
                    {
                        id: "offerTitle",
                        label: "Offer Title",
                    },
                    {
                        id: "searches",
                        label: "Total Searches",
                    },
                    {
                        id: "noAvSearches",
                        label: "No Av. Searches",
                        tooltip:
                            "No Av. Searches(Searches on no availability dates)",
                    },
                    {
                        id: "sessions",
                        label: "Total Sessions",
                    },
                    {
                        id: "noAvSessions",
                        label: "No Av. Sessions",
                        tooltip:
                            "No Availability Sessions (Sessions without an available date)",
                    },
                ]);
                break;
            case "Revenue":
                setHeader([
                    {
                        id: "offerTitle",
                        label: "Offer Title",
                    },
                    {
                        id: "lostRevenue",
                        label: "Lost Revenue",
                    },
                    {
                        id: "revenue",
                        label: "Revenue",
                    },
                ]);
                break;
            case "Bookings":
                setHeader([
                    {
                        id: "offerTitle",
                        label: "Offer Title",
                    },
                    {
                        id: "bookings",
                        label: "Bookings",
                    },
                    {
                        id: "roomNights",
                        label: "Room Nights",
                    },
                    {
                        id: "bedNights",
                        label: "Bed Nights",
                    },
                    // {
                    //     id: "averageLoS",
                    //     label: "Average LoS",
                    // },
                    // {
                    //     id: "averageLeadTime",
                    //     label: "Average Lead Time",
                    // },
                ]);
                break;
            default:
                break;
        }
    }, [dataType]);

    useEffect(() => {
        setFromDate(new Date(rowData.searchDate));

        if (clicked && rawDetails.length === 0) {
            const fetchData = async () => {
                try {
                    const auxRawDetails = await getDetailsData(
                        hotelID,
                        fromDate.toISOString().slice(0, 10),
                        new Date(fromDate.getTime() + 24 * 60 * 60 * 1000)
                            .toISOString()
                            .slice(0, 10),
                        auth
                    );

                    if (auxRawDetails.length > 0) {
                        setRawDetails(auxRawDetails);
                        setConnectionError(false);
                    } else {
                        setConnectionError(true);
                    }
                } catch (error) {
                    console.log(error);
                    setConnectionError(true);
                }
            };
            fetchData();
        }
    }, [clicked]);

    useEffect(() => {
        if (rawDetails.length > 0) {
            let auxProcDetails = {};

            // console.log(rawDetails[0]);

            rawDetails.forEach((element) => {
                const offerTitle = element.offerTitle;

                if (!auxProcDetails.hasOwnProperty(offerTitle)) {
                    auxProcDetails[offerTitle] = [];
                }

                auxProcDetails[offerTitle].push(element);
            });

            setProcDetails(auxProcDetails);

            // console.log(auxProcDetails);
        }
    }, [rawDetails]);

    const formatValueOrDate = (prop, value) => {
        // console.log(prop, value);

        let leftUnit =
            (parentHeader.find((e) => e.id === prop) ?? {}).leftUnit ?? "";
        return `${leftUnit}${
            prop === "searchDate" || prop === "availabilityDate"
                ? new Date(value).toISOString().slice(0, 10)
                : fns(
                      hotelID,
                      value,
                      (parentHeader.find((e) => e.id === prop) ?? {}).format ??
                          {}
                  )
        }`;
    };

    return (
        <>
            <TableRow
                key={Object.values(rowData)[0]}
                onClick={() => setClicked(!clicked)}
                sx={
                    clicked
                        ? { backgroundColor: Colours.selectedRow }
                        : undefined
                }
                style={{ cursor: "pointer" }}
            >
                {parentHeader.map((head, index) => (
                    <TableBodyCell
                        key={head.id}
                        style={
                            isMobile && index === 0
                                ? {
                                      position: "sticky",
                                      left: 0,
                                      zIndex: 1,
                                      backgroundColor: clicked
                                          ? Colours.selectedRow
                                          : Colours.plainWhite,
                                  }
                                : {}
                        }
                    >
                        {formatValueOrDate(head.id, rowData[head.id])}
                    </TableBodyCell>
                ))}
            </TableRow>

            {clicked &&
                (Object.entries(procDetails).length > 0 ? (
                    <>
                        <TableRow
                            onClick={() => setClicked(!clicked)}
                            sx={
                                clicked
                                    ? {
                                          backgroundColor: Colours.selectedRow,
                                      }
                                    : undefined
                            }
                        >
                            <TableCell
                                colSpan={6}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {header.map((headCell, index) => (
                                                <TableHeaderCell
                                                    key={headCell.id}
                                                    style={{
                                                        position: "sticky",
                                                        left: 0,
                                                        zIndex: 1,
                                                        backgroundColor:
                                                            Colours.selectedRow,
                                                    }}
                                                >
                                                    {headCell.label}
                                                </TableHeaderCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(procDetails).map(
                                            ([offerTitle, rowData]) => (
                                                <AllSearchesLeadTimeGraph
                                                    key={offerTitle}
                                                    data={rowData}
                                                    title={offerTitle}
                                                    header={header}
                                                    hotelID={hotelID}
                                                    dataType={dataType}
                                                />
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    </>
                ) : (
                    <TableRow
                        key={"skeleton"}
                        sx={
                            clicked
                                ? {
                                      backgroundColor: Colours.selectedRow,
                                  }
                                : undefined
                        }
                    >
                        <ErrorIcon show={connectionError} />
                        <TableCell colSpan={100}>
                            <Skeleton />
                        </TableCell>
                    </TableRow>
                ))}
        </>
    );
};

export default AllSearchesLeadTimeRow;
