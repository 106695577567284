import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Container,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Box,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import EChartsReact from "echarts-for-react";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import WrapperPDFTable from "../shared/WrapperPDFTable";
import HoverPaper from "../shared/HoverPaper";

import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import SmallPercentage from "../SmallPercentage";
import TableHeaderCell from "../TableHeaderCell";
import TableBodyCell from "../TableBodyCell";
import DateSelector from "../DateSelector";
import OptionsButtonDialog from "../OptionsButtonDialog";

import Colours from "../../helpers/colours";
import { getYearsBefore, months } from "../../helpers/dates";
import { fns } from "../../helpers/common";
import { round } from "../../helpers/reports";
import { reservationsToYearMonth } from "../DataLoader";

const CalcPercentage = (year, data, key, context, formatter) => {
    const actualYear = new Date().getUTCFullYear();

    if (String(year) === String(actualYear)) return;

    const actual = (data[actualYear] ?? {})[key] ?? 0;
    const old = (data[year] ?? {})[key] ?? 0;

    return (
        <SmallPercentage
            actual={actual}
            old={old}
            context={context}
            formatter={formatter}
        ></SmallPercentage>
    );
};

const process = (data) => {
    let aux = { revenue: 0 };
    if (data.length > 0) {
        data.forEach((element) => {
            aux.revenue +=
                element.Type === "Special Offer" ? element.RoomRevenue : 0;
        });
    }
    aux.revenue = round(aux.revenue, 0);
    return aux;
};

const ArrivalRevenueReport = ({ hotelID }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));
    let collapse = useMediaQuery(theme.breakpoints.down("sm"));

    const REPORT_DESC =
        "Shows a summary of the checked in arrival revenue for time period selected. Includes cancellations.";
    const TITLE = "Check In Arrival Revenue";

    const [connectionError, setConnectionError] = useState(false);

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(true);
    const { time: sharedTime, fullYear: sharedFullYear } = useSelector(
        (state) => state.settings.settings
    );

    const [displayData, setDisplayData] = useState([]);

    const { dataBookings } = useSelector((state) => state);

    const [loaded, setLoaded] = useState(false);

    const [dataByYear, setDataByYear] = useState({});
    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});
    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const setMonthsData = [
        setDataByJanuary,
        setDataByFebruary,
        setDataByMarch,
        setDataByApril,
        setDataByMay,
        setDataByJune,
        setDataByJuly,
        setDataByAugust,
        setDataBySeptember,
        setDataByOctober,
        setDataByNovember,
        setDataByDecember,
    ];

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    // Gets non-empty data(at least one key not 0)
    const getValidData = (unfilteredData) => {
        let validData = {};
        Object.entries(unfilteredData).map(([yearKey, data]) => {
            if (!Object.values(data).every((val) => val === 0)) {
                return (validData[yearKey] = data);
            }
        });
        return validData;
    };

    //               _ _ ____        _
    //   _ __  _   _| | |  _ \  __ _| |_ __ _
    //  | '_ \| | | | | | | | |/ _` | __/ _` |
    //  | |_) | |_| | | | |_| | (_| | || (_| |
    //  | .__/ \__,_|_|_|____/ \__,_|\__\__,_|
    //  |_|
    useEffect(() => {
        if (dataBookings.status === "error") setConnectionError(true);
        if (dataBookings.status === "loaded") {
            // Get array of all years to n years before
            let years = getYearsBefore();

            let aux_data = reservationsToYearMonth(
                dataBookings.data,
                "CheckIn"
            );

            months.forEach((month, index) => {
                let auxMonthData = Object.assign(
                    {},
                    ...years.map((year) => ({
                        [year]: process(
                            ((aux_data[`${year}`] ?? {})[month] ?? []).filter(
                                (element) =>
                                    ["Special Offer"].includes(element.Type)
                            )
                        ),
                    }))
                );

                setMonthsData[index](getValidData(auxMonthData));
            });

            let auxYearData = Object.assign(
                {},
                ...years.map((year) => ({
                    [year]: process(
                        Object.values(aux_data[`${year}`] ?? {})
                            .flat(1)
                            .filter((element) =>
                                ["Special Offer"].includes(element.Type)
                            )
                    ),
                }))
            );
            setDataByYear(getValidData(auxYearData));
            setLoaded(true);
        } else setLoaded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataBookings]);

    useEffect(() => {
        let auxDisplayData = {};
        if (
            Object.entries(dataByYear).length > 0 &&
            Object.entries(monthsData).every((arr) => arr.length > 0)
        ) {
            auxDisplayData = fullYear
                ? dataByYear
                : monthsData[time.getUTCMonth()];
        }
        setDisplayData(auxDisplayData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, dataByYear, ...monthsData]);

    return (
        <Stack direction={"column"} spacing={0.1}>
            <Container maxWidth={false} component={HoverPaper}>
                <br />
                <Stack
                    direction={{ md: "column", sm: "row", xs: "row" }}
                    justifyContent={isMobile ? "space-around" : "space-between"}
                    alignItems="flex-start"
                    // style={{ border: "1px solid red" }}
                >
                    {/* Title */}
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"center"}
                        // style={{ border: "1px solid orange" }}
                    >
                        <Stack
                            alignItems="center"
                            // style={{ border: "1px solid yellow" }}
                        >
                            <Typography fontWeight={"bold"}>{TITLE}</Typography>
                        </Stack>
                        <InfoIcon text={REPORT_DESC} />
                        <ErrorIcon show={connectionError} />
                    </Stack>

                    {isMobile ? (
                        <OptionsButtonDialog>
                            {/* <SwitchVisualTable
                                collapse={isMobile}
                                mode={mode}
                                setMode={setMode}
                            /> */}
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                            />
                            {""}
                        </OptionsButtonDialog>
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            // style={{ border: "1px solid green   " }}
                        >
                            {/* <SwitchVisualTable mode={mode} setMode={setMode} /> */}
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                            />
                        </Stack>
                    )}
                </Stack>

                {!loaded ? (
                    <>
                        <br /> <ProgressBarSteps />
                    </>
                ) : (
                    ""
                )}

                <Stack
                    justifyContent="flex-start"
                    alignItems={{ sm: "center", md: "flex-start" }}
                    direction={{ sm: "column", md: "row" }}
                    marginTop={isMobile ? "" : "1em"}
                >
                    <Box minWidth={"30%"}>
                        {/*    //   _____  _    ____  _     _____   */}
                        {/*    //  |_   _|/ \  | __ )| |   | ____|  */}
                        {/*    //    | | / _ \ |  _ \| |   |  _|    */}
                        {/*    //    | |/ ___ \| |_) | |___| |___   */}
                        {/*    //    |_/_/   \_\____/|_____|_____|  */}
                        <WrapperPDFTable k={TITLE}>
                            <Table>
                                {/*  _  _ ___   _   ___   */}
                                {/* | || | __| /_\ |   \  */}
                                {/* | __ | _| / _ \| |) | */}
                                {/* |_||_|___/_/ \_\___/  */}
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableHeaderCell>
                                            {/*</TableRow>colSpan={2} align={"center"}>*/}
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    fontSize: "100%",
                                                }}
                                            >
                                                Total revenue
                                                {fullYear ? (
                                                    ""
                                                ) : (
                                                    <InfoIcon
                                                        size={"60%"}
                                                        color={
                                                            Colours.textColour
                                                        }
                                                        text={
                                                            "Click on graph months for faster month switch"
                                                        }
                                                    />
                                                )}
                                            </Typography>
                                        </TableHeaderCell>
                                    </TableRow>
                                </TableHead>
                                {/*  ____   _____        ______   */}
                                {/* |  _ \ / _ \ \      / / ___|  */}
                                {/* | |_) | | | \ \ /\ / /\___ \  */}
                                {/* |  _ <| |_| |\ V  V /  ___) | */}
                                {/* |_| \_\\___/  \_/\_/  |____/  */}
                                <TableBody>
                                    {Object.keys(displayData).length > 0 ? (
                                        <>
                                            {Object.keys(displayData)
                                                .sort()
                                                .reverse()
                                                .map((year) => (
                                                    <TableRow key={year}>
                                                        <TableHeaderCell>
                                                            {year}
                                                        </TableHeaderCell>
                                                        <TableBodyCell>
                                                            {fns(
                                                                hotelID,
                                                                displayData[
                                                                    year
                                                                ]["revenue"],
                                                                {
                                                                    left: "currency",
                                                                }
                                                            )}

                                                            {CalcPercentage(
                                                                year,
                                                                displayData,
                                                                "revenue",
                                                                fullYear
                                                                    ? ` Comparing ${time.getUTCFullYear()} vs ${year}`
                                                                    : `Comparing ${time.getUTCFullYear()}-${
                                                                          months[
                                                                              time.getUTCMonth()
                                                                          ]
                                                                      } vs ${year}-${
                                                                          months[
                                                                              time.getUTCMonth()
                                                                          ]
                                                                      }`,
                                                                (number) =>
                                                                    fns(
                                                                        hotelID,
                                                                        number,
                                                                        {
                                                                            left: "currency",
                                                                        }
                                                                    )
                                                            )}
                                                        </TableBodyCell>
                                                    </TableRow>
                                                ))}
                                        </>
                                    ) : (
                                        //   _                    _ _
                                        //  | |    ___   __ _  __| (_)_ __   __ _
                                        //  | |   / _ \ / _` |/ _` | | '_ \ / _` |
                                        //  | |__| (_) | (_| | (_| | | | | | (_| |
                                        //  |_____\___/ \__,_|\__,_|_|_| |_|\__, |
                                        //                                  |___/
                                        getYearsBefore().map((e) => (
                                            // TODO: NoDatRow substitution
                                            <TableRow key={e}>
                                                {[...Array(2).keys()].map(
                                                    (f) => (
                                                        <TableCell key={f}>
                                                            {dataBookings.status !==
                                                            "loaded" ? (
                                                                <Skeleton />
                                                            ) : (
                                                                "No Data"
                                                            )}
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </WrapperPDFTable>
                    </Box>
                    <Box height={"100%"} width={"100%"}>
                        {/*   ____ ____      _    ____  _   _  */}
                        {/*  / ___|  _ \    / \  |  _ \| | | | */}
                        {/* | |  _| |_) |  / _ \ | |_) | |_| | */}
                        {/* | |_| |  _ <  / ___ \|  __/|  _  | */}
                        {/*  \____|_| \_\/_/   \_\_|   |_| |_| */}
                        {Object.keys(displayData).length > 0 ? (
                            <WrapperPDFEChart k={TITLE + " Graph"}>
                                <EChartsReact
                                    onEvents={{
                                        click: fullYear
                                            ? null
                                            : (e) => {
                                                  const {
                                                      componentType,
                                                      value,
                                                  } = e;
                                                  if (
                                                      componentType === "xAxis"
                                                  ) {
                                                      let monthN =
                                                          months.findIndex(
                                                              (e) => e === value
                                                          );

                                                      setTime(
                                                          new Date(
                                                              getYearsBefore()[0],
                                                              monthN,
                                                              15
                                                          )
                                                      );
                                                  }
                                              },
                                    }}
                                    option={{
                                        animation: false,
                                        legend: {},
                                        tooltip: {
                                            trigger: "axis",
                                            axisPointer: {
                                                type: "shadow",
                                            },
                                        },
                                        dataset: {
                                            source: fullYear
                                                ? [
                                                      ...Object.entries(
                                                          dataByYear
                                                      ).map(([year, data]) => [
                                                          year,
                                                          data.revenue,
                                                      ]),
                                                  ]
                                                : [
                                                      [
                                                          "years",
                                                          ...Object.keys(
                                                              dataByYear
                                                          ),
                                                      ],
                                                      ...Object.keys([
                                                          ...Array(12),
                                                      ]).map((monthNumber) => [
                                                          months[monthNumber],
                                                          ...Object.keys(
                                                              dataByYear
                                                          ).map((year) =>
                                                              monthsData[
                                                                  monthNumber
                                                              ][year]
                                                                  ? monthsData[
                                                                        monthNumber
                                                                    ][year]
                                                                        .revenue
                                                                  : 0
                                                          ),
                                                      ]),
                                                      // ↑ This returns this ↓ for each month
                                                      // [
                                                      //     "January",
                                                      //     ...Object.keys(
                                                      //         monthsData[0]
                                                      //     ).map(
                                                      //         (y) =>
                                                      //             monthsData[0][y][
                                                      //                 clickedCard
                                                      //             ]
                                                      //     ),
                                                      // ],
                                                  ],
                                        },
                                        xAxis: {
                                            type: "category",
                                            triggerEvent: fullYear
                                                ? false
                                                : true,
                                            axisLabel: {
                                                interval: 0,
                                                formatter: (v) => {
                                                    let text = fullYear
                                                        ? v
                                                        : v.slice(0, 3);
                                                    if (
                                                        months[
                                                            time.getUTCMonth()
                                                        ] === v
                                                    ) {
                                                        return ` {bold|${text}}`;
                                                    }

                                                    return text;
                                                },
                                                rich: {
                                                    bold: {
                                                        fontWeight: "bold",
                                                        backgroundColor:
                                                            Colours.selectedRow,

                                                        padding: [-3, 6, 3, 6],
                                                    },
                                                },
                                                rotate: isMobile ? 35 : 0,
                                            },
                                        },
                                        yAxis: {
                                            axisLabel: {
                                                formatter: (value) => {
                                                    if (value >= 1000) {
                                                        return (
                                                            (
                                                                value / 1000
                                                            ).toFixed(0) + "K"
                                                        );
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                rotate: isMobile ? 55 : 0,
                                            },
                                        },
                                        // TODO: Avoid zoom reset after selecting month
                                        dataZoom: [{}],
                                        series: fullYear
                                            ? [
                                                  {
                                                      type: "bar",
                                                      itemStyle: {
                                                          color: Colours.attract,
                                                      },
                                                  },
                                              ]
                                            : [
                                                  ...[
                                                      ...Object.keys(
                                                          dataByYear
                                                      ),
                                                  ].map((element, index) => {
                                                      return {
                                                          type: "bar",
                                                          smooth: true,
                                                          itemStyle: {
                                                              color: [
                                                                  Colours.info,
                                                                  Colours.attract,
                                                                  Colours.engage,
                                                                  Colours.convert,
                                                                  Colours.retain,
                                                                  Colours.graphExtraLine1,
                                                              ][
                                                                  index %
                                                                      Object.keys(
                                                                          dataByYear
                                                                      ).length
                                                              ],
                                                          },
                                                      };
                                                  }),
                                              ],
                                    }}
                                />
                            </WrapperPDFEChart>
                        ) : (
                            <Skeleton variant="rounded" height={"16em"} />
                        )}
                    </Box>
                </Stack>
                <br />
            </Container>
        </Stack>
    );
};

export default ArrivalRevenueReport;
