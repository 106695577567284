import React, { useEffect, useState } from "react";

import { CircularProgress, Fab, Slide, Stack, Typography } from "@mui/material";

import Colours from "../helpers/colours";

const UndoFab = ({
    onClick,
    list_,
    onEnd = () => {}, //Runs only if time ends
    text = "UNDO",
    TTL = 5000,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (list_.length > 0) {
            const now = new Date();
            setIsVisible(true);
            setProgress(0);

            const interval = setInterval(() => {
                const currentTime = new Date();
                const elapsed = currentTime - now;
                const newProgress = Math.min((elapsed / TTL) * 100, 100);
                setProgress(newProgress);
            }, 100);

            const timer = setTimeout(() => {
                setIsVisible(false);
                onEnd();
            }, TTL);

            return () => {
                clearInterval(interval);
                clearTimeout(timer);
            };
        } else {
            setIsVisible(false);
            setProgress(0);
        }
    }, [list_]);

    if (!isVisible) return null;
    else
        return (
            <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit>
                <Fab
                    variant="extended"
                    onClick={onClick}
                    sx={{
                        bgcolor: Colours.graphDisabled,
                        color: Colours.midLightText,
                        position: "fixed",
                        bottom: 10,
                        left: "5%",
                        zIndex: 1,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography>{text}</Typography>
                        <CircularProgress
                            sx={{ p: 0.5 }}
                            variant="determinate"
                            value={progress}
                        />
                        {/* {progress.toFixed(1)} */}
                    </Stack>
                </Fab>
            </Slide>
        );
};

export default UndoFab;
