import { NotificationsTypes } from "../types/NotificationsTypes";

const NotificationsInitial = {
    status: "empty",
    data: [],
    user_id: 0,
    reload: new Date().toISOString(),
};

export const NotificationsReducer = (state = NotificationsInitial, action) => {
    switch (action.type) {
        case NotificationsTypes.set:
            return {
                ...state,
                status: "loaded",
                data: action.payload,
                user_id: action.user_id,
            };

        case NotificationsTypes.reload:
            return {
                ...state,
                status: "reload",
                reload: new Date().toISOString(),
            };

        case NotificationsTypes.clean:
            return NotificationsInitial;

        default:
            return state;
    }
};
