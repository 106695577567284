import { dataCommissionTypes } from "../types/dataCommissionTypes";

const dataCommissionInitial = {
    status: "empty",
    reload: new Date().toISOString(),
};
export const dataCommissionReducer = (
    state = dataCommissionInitial,
    action
) => {
    switch (action.type) {
        case dataCommissionTypes.loading:
            return {
                ...state,
                status: "loading",
                data: {},
            };

        case dataCommissionTypes.partialLoad:
            return {
                ...state,
                status: "partiallyLoaded",
                data: action.payload,
            };

        case dataCommissionTypes.load:
            return {
                ...state,
                status: "loaded",
                data: action.payload,
            };

        case dataCommissionTypes.reload:
            return {
                ...state,
                status: "reload",
                reload: new Date().toISOString(),
            };

        case dataCommissionTypes.error:
            return {
                ...state,
                status: "error",
                data: action.payload,
                error: action.error,
            };

        case dataCommissionTypes.clean:
            return dataCommissionInitial;

        default:
            return state;
    }
};
