import React, { useEffect, useState } from "react";

import CircleValue from "./CircleValue";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import { Stack, Typography } from "@mui/material";

export const TABLE_MODE = "TABLE_MODE"; // Used for tables
export const TABULAR_MODE = "TABULAR_MODE"; // Used for cards and carousel
export const VISUAL_MODE = "VISUAL_MODE"; // Used for simple and standalone graphs

const modePresets = {
    TABLE_MODE: {
        name: "Table",
        color: Colours.info,
        icon: Icons.Table,
    },
    VISUAL_MODE: {
        name: "Visual",
        color: Colours.primary,
        icon: Icons.LeadersBoard,
    },
    TABULAR_MODE: {
        name: "Tabular",
        color: Colours.trafficLightGreen,
        icon: Icons.DashBoard,
    },
};

const SwitchVisualTable = ({
    collapse = false,
    modes = Object.keys(modePresets),
    mode,
    setMode,
}) => {
    const getCurrentIndex = () => {
        return modes.findIndex((element) => element === mode);
    };

    const getNextIndex = () => {
        const currentIndex = getCurrentIndex();
        return (currentIndex + 1) % modes.length;
    };

    const handleClick = () => {
        const nextPreset = modes[getNextIndex()];
        setMode(nextPreset);
    };

    return (
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
            {collapse ? (
                <Typography>{`Switch to ${
                    modePresets[modes[getNextIndex()]].name
                }`}</Typography>
            ) : (
                ""
            )}
            <CircleValue
                tooltip={`Switch to ${modePresets[modes[getNextIndex()]].name}`}
                color={modePresets[modes[getNextIndex()]].color}
                Icon={modePresets[modes[getNextIndex()]].icon}
                onClick={handleClick}
                style={{ cursor: "pointer" }}
            />
        </Stack>
    );
};

export default SwitchVisualTable;
