import { dataSimpleTypes } from "../types/dataSimpleTypes";

export const loading = (hotelID) => {
    return {
        type: dataSimpleTypes.loading,
        hotelID,
    };
};
export const reloading = () => {
    return {
        type: dataSimpleTypes.reloading,
    };
};
export const partialLoad = (data, hotelID) => {
    return {
        type: dataSimpleTypes.partialLoad,
        payload: data,
        hotelID,
    };
};
export const load = (data, hotelID) => {
    return {
        type: dataSimpleTypes.load,
        payload: data,
        hotelID,
    };
};
export const error = (error, data) => {
    return {
        type: dataSimpleTypes.error,
        payload: data,
        error: error,
    };
};
export const updateOne = (hotelID, who, month_v, year_v, obj) => {
    return {
        type: dataSimpleTypes.updateOne,
        hotelID,
        payload: {
            who: who,
            month_v: month_v,
            year_v: year_v,
            obj,
        },
    };
};
