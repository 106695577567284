import { arrayDictYearMonthSort } from "../helpers/api";
import { dataSearchesTypes } from "../types/dataSearchesTypes";

const dataSearchesInitial = {
    status: "empty",
    reload: new Date().toISOString(),
};
export const dataSearchesReducer = (state = dataSearchesInitial, action) => {
    switch (action.type) {
        case dataSearchesTypes.loading:
            return {
                ...state,
                status: "loading",
                data: {},
                hotelID: action.hotelID,
            };

        case dataSearchesTypes.partialLoad:
            return {
                ...state,
                status: "partiallyLoaded",
                data: action.payload,
                hotelID: action.hotelID,
            };

        case dataSearchesTypes.load:
            return {
                ...state,
                status: "loaded",
                data: action.payload,
                hotelID: action.hotelID,
            };

        case dataSearchesTypes.reload:
            return {
                ...state,
                status: "reload",
                reload: new Date().toISOString(),
            };

        case dataSearchesTypes.error:
            return {
                ...state,
                status: "error",
                data: action.payload,
                error: action.error,
            };

        case dataSearchesTypes.clean:
            return dataSearchesInitial;

        case dataSearchesTypes.updateOne:
            const { who, month_v, year_v, obj } = action.payload;

            const data = JSON.parse(JSON.stringify(state));
            console.log({ filter: state.data[who], state });
            let notTheOne = state.data[who].filter(
                (item) =>
                    !(
                        (item.month_v ?? "") === month_v &&
                        (item.year_v ?? "") === year_v
                    )
            );
            let theOne =
                state.data[who].filter(
                    (item) =>
                        (item.month_v ?? "") === month_v &&
                        (item.year_v ?? "") === year_v
                )[0] ?? {};

            theOne = { ...theOne, month_v, year_v, ...obj };

            data[who] = arrayDictYearMonthSort([...notTheOne, theOne]);

            console.log({ data, theOne, notTheOne });

            return {
                ...state,
                status: "loaded",
                data: data,
                hotelID: action.hotelID,
            };

        default:
            return state;
    }
};
