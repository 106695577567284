import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import EChartsReact from "echarts-for-react";
import Axios from "axios";

import { Container, Skeleton, Stack, Typography } from "@mui/material";

import ProgressBarSteps from "../../shared/ProgressBarSteps";
import WrapperPDFEChart from "../../shared/WrapperPDFEChart";
import HoverPaper from "../../shared/HoverPaper";

import InfoIcon from "../../InfoIcon";
import ErrorIcon from "../../ErrorIcon";

import { DEFAULT_HOTEL, URLAPI } from "../../../configuration";

import { fridaysBetween } from "../../../helpers/eCharts";
import { tooltip } from "../../../helpers/eCharts";
import Colours from "../../../helpers/colours";

const AvailabilityAndDemandChart = ({ height = "330%" }) => {
    const { auth } = useSelector((state) => state);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [data, setData] = useState([]);
    const [startZoom, setStartZoom] = useState(null);
    const [endZoom, setEndZoom] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data.length > 0) {
            const today = new Date();
            const oneMonthAgo = new Date(today);
            oneMonthAgo.setMonth(today.getMonth() - 1);

            const oneMonthFromNow = new Date(today);
            oneMonthFromNow.setMonth(today.getMonth() + 1);

            let closestStartDate = null;
            let closestEndDate = null;

            data.forEach((entry) => {
                const currentDate = new Date(entry.AllocationDate);

                if (
                    closestStartDate === null ||
                    Math.abs(currentDate - oneMonthAgo) <
                        Math.abs(closestStartDate - oneMonthAgo)
                ) {
                    closestStartDate = currentDate;
                }

                if (
                    closestEndDate === null ||
                    Math.abs(currentDate - oneMonthFromNow) <
                        Math.abs(closestEndDate - oneMonthFromNow)
                ) {
                    closestEndDate = currentDate;
                }
            });

            setStartZoom(closestStartDate.toISOString().slice(0, 10));
            setEndZoom(closestEndDate.toISOString().slice(0, 10));
        }
    }, [data]);

    const [animation, setAnimation] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        setData([]);
        setLoading(true);
        Axios({
            method: "get",
            url: `${URLAPI}/availability_and_demand/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
            params: {
                from: `${new Date().getUTCFullYear() - 1}-01-01`,
                to: `${new Date().getUTCFullYear() + 1}-01-01`,
            },
        })
            .then((response) => {
                let d = response.data.data;

                d.sort((a, b) => a.AllocationDate - b.AllocationDate);

                setData(d);
                setError(false);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setError(true);
            });
    }, [hotelID]);

    useEffect(() => {
        if (data.length > 1) setAnimation(false);
    }, [data]);

    const MarkLine = () => {
        return {
            data: [
                {
                    name: "Current Date",
                    xAxis: new Date().toISOString().slice(0, 10),
                    lineStyle: {
                        color: Colours.trafficLightRed,
                        type: "dashed",
                    },
                    label: { show: false },
                    symbol: "none", //TODO: remove arrow symbol, I was not able of find it
                },
            ],
        };
    };
    const MarkArea = () => {
        return {
            itemStyle: {
                color: Colours.lightBubble,
            },
            data: [
                ...fridaysBetween(
                    new Date(data[0].AllocationDate),
                    new Date(data[data.length - 1].AllocationDate)
                ).map((element) => [
                    {
                        xAxis: element.toISOString().slice(0, 10),
                    },
                    {
                        xAxis: new Date(element.setDate(element.getDate() + 2))
                            .toISOString()
                            .slice(0, 10),
                    },
                ]),
            ],
        };
    };

    const TITLE = "Availability and Demand";
    const REPORT_DESC =
        "Compares number of free rooms in our system be allocated with users demand each day";
    return (
        <Container component={HoverPaper}>
            <br />
            <Stack
                direction={{ md: "row", xs: "column" }}
                justifyContent="left"
                alignItems="center"
            >
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={"center"}
                >
                    <Typography fontWeight={"bold"}>{TITLE}</Typography>
                    <InfoIcon text={REPORT_DESC} />
                    <ErrorIcon show={error} />
                </Stack>
            </Stack>
            {loading && <ProgressBarSteps />}
            {data.length === 0 && (
                <Skeleton variant="rounded" width={"100%"} height={300} />
            )}
            {data.length > 1 && (
                <WrapperPDFEChart k={TITLE}>
                    <EChartsReact
                        style={{ width: "100%", height }}
                        option={{
                            animation: animation,
                            grid: {
                                top: 30,
                                left: 30,
                                right: 5,
                                // bottom: 25,
                            },
                            legend: {},
                            tooltip: tooltip,
                            dataZoom: [
                                {
                                    type: "inside",
                                    startValue: startZoom,
                                    endValue: endZoom,
                                },
                                {
                                    start: 0,
                                    end: 10,
                                },
                            ],

                            xAxis: {
                                type: "category",
                                data: data.map((element) =>
                                    new Date(element.AllocationDate)
                                        .toISOString()
                                        .slice(0, 10)
                                ),
                            },
                            yAxis: {},
                            series: [
                                {
                                    type: "line",
                                    smooth: true,
                                    data: data.map(
                                        (element) => element.RemainAllocation
                                    ),
                                    name: "Availability",
                                    // lineStyle: { color: Colours.error },
                                    itemStyle: {
                                        color: Colours.graphExtraLine1,
                                    },
                                    markLine: MarkLine(),
                                    markArea: MarkArea(),
                                },
                                {
                                    type: "line",
                                    smooth: true,
                                    data: data.map(
                                        (element) => element.Occupancy
                                    ),
                                    name: "Demand",
                                    itemStyle: {
                                        color: Colours.graphExtraLine2,
                                    },
                                    markLine: MarkLine(),
                                    markArea: MarkArea(),
                                },

                                {
                                    type: "line",
                                    smooth: true,
                                    data: data.map(
                                        (element) => element.forecast_value
                                    ),
                                    name: "Forecast Demand",
                                    itemStyle: {
                                        color: Colours.graphProjection,
                                    },
                                    markLine: MarkLine(),
                                    markArea: MarkArea(),
                                },
                                // {
                                //     type: "line",
                                //     smooth: true,
                                //     data: data.map(
                                //         (element) => element.forecast_andy
                                //     ),
                                //     name: "Forecast Demand Andy",
                                //     itemStyle: { color: Colours.error },
                                // },
                            ],
                        }}
                    />
                </WrapperPDFEChart>
            )}
            <br />
        </Container>
    );
};

export default AvailabilityAndDemandChart;
