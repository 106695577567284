import React, { useEffect, useState } from "react";

import {
    Stack,
    Typography,
    Paper,
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    TablePagination,
} from "@mui/material";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import { styled } from "@mui/material/styles";

import CircleValue from "./CircleValue";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";

import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";

const PillarCard = ({
    PillarName,
    PillarScore,
    KPIData,
    circleColor,
    onClick,
}) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const ColouredLinearProgress = styled(LinearProgress)(({ theme }) => ({
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: Colours.tableRowBackground,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: circleColor,
        },
    }));

    const ClickablePaper = styled(Paper)({
        transition: "box-shadow 0.3s",
        "&:hover": {
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
        },
        cursor: "pointer",
    });

    return (
        <ClickablePaper
            component={Stack}
            direction="column"
            justifyContent="flex-start"
            p={2}
            borderLeft={`5px solid ${circleColor}`}
            onClick={onClick}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    gutterBottom
                >
                    {PillarName}
                </Typography>
                <Icons.Options
                    fontSize="small"
                    sx={{ color: Colours.tableRowBackground }}
                />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="caption" gutterBottom>
                    {Math.round(PillarScore)}% complete
                </Typography>
                <Typography variant="caption" gutterBottom>
                    {KPIData.length} Actionable items
                </Typography>
            </Stack>
            <ColouredLinearProgress
                variant="determinate"
                value={Math.round(PillarScore)}
                sx={{
                    color: (theme) =>
                        theme.palette.grey[
                            theme.palette.mode === "light" ? 200 : 800
                        ],
                }}
            />

            <Box />
        </ClickablePaper>
    );
};

export default PillarCard;
