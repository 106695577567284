import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Button,
    Box,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Stack,
    Table,
    TableBody,
    Tooltip,
    Typography,
} from "@mui/material";

import HoverPaper from "./shared/HoverPaper";
import CheckShow from "./shared/CheckShow";
import { CheckHideBool } from "./shared/CheckHide";
import TooltipComparison from "./shared/TooltipComparison";

import SmallPercentage from "./SmallPercentage";
import NoDataRow from "./NoDataRow";
import InfoIcon from "./InfoIcon";
import EventsTimeCarousel from "./events/EventsTimeCarousel";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";
import {
    COMPARISON_FILTER,
    DATATYPE_CHECKIN,
    DATATYPE_RESDATE,
    DEFAULT_COMPARISON,
    DISPLAY_ELEMENT_HELPER,
    getMetricName,
} from "../helpers/filters";
import { fns } from "../helpers/common";

import { ARO_ADMIN } from "../configuration";

//   _____ _                           _
//  | ____| | ___ _ __ ___   ___ _ __ | |_
//  |  _| | |/ _ \ '_ ` _ \ / _ \ '_ \| __|
//  | |___| |  __/ | | | | |  __/ | | | |_
//  |_____|_|\___|_| |_| |_|\___|_| |_|\__|
const DataElement = ({
    name,
    value,
    valueOld,
    fromDay,
    toDay,
    comparison,
    comparisonCustom,
    js,
}) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const [selected, setSelected] = useState(false);

    if (value !== null)
        return (
            <TooltipComparison
                placeForMetricName={PLACE}
                metricName={name}
                comparisonType={comparison}
                comparisonCustom={comparisonCustom}
                fromDay={fromDay}
                toDay={toDay}
                value={value}
                valueOld={valueOld}
                setSelected={setSelected}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={1}
                    p={1}
                    sx={{
                        backgroundColor: selected ? "white" : null,
                        border: selected
                            ? "1px solid lightgrey"
                            : "1px solid transparent",
                        borderRadius: 1,
                    }}
                >
                    <Typography>
                        {fns(
                            hotelID,
                            getMetricName(name, PLACE) ?? name,
                            (DISPLAY_ELEMENT_HELPER[name] ?? {}).headerFormat
                        )}
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={4}
                    >
                        <Typography fontWeight={"bold"}>
                            {fns(hotelID, value, {})}
                        </Typography>
                        <SmallPercentage
                            brackets={false}
                            tooltip={false}
                            bubble={true}
                            bubbleRound={true}
                            actual={value}
                            old={valueOld}
                            showFormula={false}
                            isInverted={
                                (DISPLAY_ELEMENT_HELPER[name] ?? {}).upside ??
                                false
                            }
                        />
                    </Stack>
                </Stack>
            </TooltipComparison>
        );
    else
        return (
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={0}
            >
                <Skeleton width={100} />

                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={4}
                >
                    <Skeleton width={50} />
                    <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "45px" }}
                        width={45}
                        height={25}
                    />
                </Stack>
            </Stack>
        );
};

//   _____  _    ____  _     _____
//  |_   _|/ \  | __ )| |   | ____|
//    | | / _ \ |  _ \| |   |  _|
//    | |/ ___ \| |_) | |___| |___
//    |_/_/   \_\____/|_____|_____|
const PLACE = "FiltersTableReport";
const FiltersTableReport = ({
    data,
    js,
    PDF = false,
    hideExtra = false,
    hideEvents = false,
}) => {
    const [js_, setJs_] = useState(js);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [openHidePanel, setOpenHidePanel] = useState(false);
    const [group, setGroup] = useState({ keys: [], result: {} });
    const [groupNumber, setGroupNumber] = useState(0);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            if (groupNumber >= Object.keys(data).length) setGroupNumber(0);
            if (groupNumber < Object.values(data).length) {
                setGroup(
                    Object.values(data)[groupNumber] ?? { keys: [], result: {} }
                );
            }
        }
    }, [data, js_, groupNumber]);

    // Uncomment this to easily test email last month
    // useEffect(() => {
    //     let text =
    //         `${group.result.revenueTotal}\n` +
    //         `${group.result.revenueRoom}\n` +
    //         `${group.result.revenueRoomAndExtras}\n` +
    //         `${group.result.revenueExtras}\n` +
    //         `${group.result.bookingsRooms}\n` +
    //         `${group.result.roomNightsTotal}\n` +
    //         `${group.result.bookingsCancelled}\n` +
    //         `${group.result.ABWTotal}\n` +
    //         `${group.result.ALoSTotal}\n` +
    //         `${group.result.ADRTotal}\n` +
    //         `${group.result.revenueVouchers}\n` +
    //         `${group.result.vouchersBookings}\n` +
    //         `${group.result.vouchersCount}\n`;
    //     console.log(text);
    //     navigator.clipboard.writeText(text);
    // }, [group]);

    useEffect(() => {
        setJs_(js);
    }, [js]);

    return (
        <Container
            maxWidth={false}
            component={HoverPaper}
            sx={{ p: 2, backgroundColor: Colours.notificationCard }}
        >
            {Object.keys(group.result).length > 0 && // HERE TODO: review
            Object.keys(js_).length > 2 ? (
                <>
                    {Object.values(data).length > 1 && (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <IconButton
                                size="small"
                                disabled={groupNumber === 0}
                                onClick={() => setGroupNumber(groupNumber - 1)}
                            >
                                <Icons.ArrowLeft />
                            </IconButton>
                            <Typography fontSize="0.8em">
                                {groupNumber + 1}/{Object.values(data).length}
                            </Typography>
                            <IconButton
                                size="small"
                                disabled={
                                    groupNumber >=
                                    Object.values(data).length - 1
                                }
                                onClick={() => setGroupNumber(groupNumber + 1)}
                            >
                                <Icons.ArrowRight />
                            </IconButton>
                            <Grid container columnSpacing={1}>
                                {(group.keys ?? []).map((e) => (
                                    <Grid item>
                                        <Typography fontSize="0.9em" key={e[0]}>
                                            <b>{e[0] ?? "NONE (Voucher)"}</b>:{" "}
                                            {e[1] ?? "NONE (Voucher)"}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    )}
                    {hideExtra ? (
                        <></>
                    ) : (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.8em",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {js_.dataType === DATATYPE_RESDATE
                                        ? "Booked: "
                                        : js_.dataType === DATATYPE_CHECKIN
                                        ? "Checked in: "
                                        : "Checked out: "}
                                    {new Date(js_.fromDay).toLocaleDateString(
                                        "en-IE",
                                        {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                        }
                                    )}{" "}
                                    -{" "}
                                    {new Date(js_.toDay).toLocaleDateString(
                                        "en-IE",
                                        {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                        }
                                    )}
                                </Typography>
                            </Stack>

                            <Divider
                                sx={{
                                    marginY: 2,
                                    borderColor: Colours.navbarBottomLine,
                                }}
                            />
                        </>
                    )}

                    <Grid container rowSpacing={4} columnSpacing={2}>
                        {Object.values(group.result).length === 0 &&
                            [...Array(8).keys()].map((e, i) => (
                                <Grid item key={i} xs={6} md={3}>
                                    <DataElement value={null} />
                                </Grid>
                            ))}
                        {/* Sorted metrics */}
                        {js_.fieldsOrder.map((metric, index) => {
                            if (
                                !CheckHideBool("Vouchers", hotelID) &&
                                ((DISPLAY_ELEMENT_HELPER[metric] ?? {})
                                    .isVoucher ??
                                    false)
                            )
                                return;
                            if (metric === "empty") {
                                return (
                                    <Grid
                                        item
                                        key={metric + index}
                                        xs={6}
                                        md={3}
                                    />
                                );
                            }
                            if (
                                metric in group.result &&
                                !js_.hideFields.includes(metric)
                            ) {
                                return (
                                    <Grid item key={metric} xs={6} md={3}>
                                        <DataElement
                                            name={metric}
                                            value={group.result[metric]}
                                            valueOld={
                                                (group.resultOneYearBefore ??
                                                    {})[metric]
                                            }
                                            percentageDirection={false}
                                            fromDay={js_.fromDay}
                                            toDay={js_.toDay}
                                            // TODO: how to initiate this ?
                                            comparison={
                                                js_.filters.Comparison ??
                                                DEFAULT_COMPARISON
                                            }
                                            comparisonCustom={
                                                js_.filters.ComparisonCustom
                                            }
                                        />
                                    </Grid>
                                );
                            }
                        })}

                        {/* Not sorted metrics */}
                        {Object.entries(group.result ?? {}).map(
                            ([metric, value]) => {
                                if (
                                    !CheckHideBool("Vouchers", hotelID) &&
                                    (DISPLAY_ELEMENT_HELPER[metric].isVoucher ??
                                        false)
                                )
                                    return;
                                if (
                                    !js_.hideFields.includes(metric) &&
                                    !js_.fieldsOrder.includes(metric) //HERE
                                ) {
                                    return (
                                        <Grid item key={metric} xs={6} md={3}>
                                            <DataElement
                                                name={metric}
                                                value={value}
                                                valueOld={
                                                    (group.resultOneYearBefore ??
                                                        {})[metric]
                                                }
                                                percentageDirection={false}
                                                fromDay={js_.fromDay}
                                                toDay={js_.toDay}
                                                comparison={
                                                    js_.filters.Comparison ??
                                                    DEFAULT_COMPARISON
                                                }
                                                comparisonCustom={
                                                    js_.filters.ComparisonCustom
                                                }
                                            />
                                        </Grid>
                                    );
                                } else return null;
                            }
                        )}
                    </Grid>

                    {!PDF && !hideExtra && !hideEvents && (
                        <EventsTimeCarousel js={js} />
                    )}

                    {hideExtra ? (
                        <></>
                    ) : (
                        <>
                            <Divider
                                sx={{
                                    marginY: 2,
                                    borderColor: Colours.navbarBottomLine,
                                }}
                            />
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    {Array.from({ length: 2 }).map(
                                        (_, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    backgroundColor:
                                                        index === 0
                                                            ? `${Colours.trafficLightGreen}20`
                                                            : `${Colours.trafficLightRed}20`,
                                                    color:
                                                        index === 0
                                                            ? "#1B7B3E"
                                                            : "#D11D1D",
                                                    py: ".1em",
                                                    px: ".5em",
                                                    borderRadius: "50px",
                                                    mr: ".5em",
                                                }}
                                            >
                                                {index === 0 ? (
                                                    <Icons.ArrowFullUp
                                                        sx={{
                                                            fontSize: "0.9em",
                                                        }}
                                                    />
                                                ) : (
                                                    <Icons.ArrowFullDown
                                                        sx={{
                                                            fontSize: "0.9em",
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )
                                    )}
                                    <Typography
                                        sx={{ fontSize: "0.8em", ml: "2em" }}
                                    >
                                        Comparison is versus the{" "}
                                        {COMPARISON_FILTER[
                                            js_.filters.Comparison ??
                                                DEFAULT_COMPARISON
                                        ].label.toLowerCase()}
                                    </Typography>
                                    <InfoIcon
                                        size="75%"
                                        margin={2}
                                        text={`The comparison percentage is calculated by taking the (selected period minus same time last year) divide by same time last year x 100.

    For example:
        Revenue:
            Selected period = €26915                
            Same time last year = €30500
            (€26915 - €30500)/€30500 x 100 = -12%`}
                                    />
                                </Stack>
                                <CheckShow rolesThatCanSee={ARO_ADMIN}>
                                    <Button
                                        startIcon={<Icons.EyeOff />}
                                        onClick={() => {
                                            setOpenHidePanel(true);
                                        }}
                                    >
                                        {js_.hideFields.length}
                                    </Button>
                                </CheckShow>
                            </Stack>
                        </>
                    )}
                </>
            ) : (
                <Table>
                    <TableBody>
                        <NoDataRow text="No data for these filters" />
                    </TableBody>
                </Table>
            )}

            <Dialog
                maxWidth="lg"
                onClose={() => setOpenHidePanel(false)}
                open={openHidePanel}
            >
                <DialogTitle>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography variant="h6">Show/Hide fields </Typography>
                        <Tooltip title="Clean filters">
                            <IconButton
                                onClick={() => {
                                    setJs_({
                                        ...js_,
                                        hideFields: [],
                                    });
                                }}
                            >
                                <Icons.Clean />
                            </IconButton>
                        </Tooltip>
                        <IconButton onClick={() => setOpenHidePanel(false)}>
                            <Icons.CloseNavbar />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {Object.entries(group.result ?? {}).map(
                            ([metric, value]) => (
                                <ListItem disablePadding key={metric}>
                                    <ListItemButton
                                        onClick={() => {
                                            js_.hideFields.includes(metric)
                                                ? setJs_({
                                                      ...js_,
                                                      hideFields: [
                                                          ...js_.hideFields.filter(
                                                              (e) =>
                                                                  e !== metric
                                                          ),
                                                      ],
                                                  })
                                                : setJs_({
                                                      ...js_,
                                                      hideFields: [
                                                          ...js_.hideFields,
                                                          metric,
                                                      ],
                                                  });
                                        }}
                                    >
                                        <ListItemIcon>
                                            {js_.hideFields.includes(metric) ? (
                                                <Icons.EyeOff />
                                            ) : (
                                                <Icons.EyeOn color="primary" />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontWeight:
                                                            DISPLAY_ELEMENT_HELPER[
                                                                metric
                                                            ].important
                                                                ? "bold"
                                                                : "normal",
                                                    }}
                                                >
                                                    {DISPLAY_ELEMENT_HELPER[
                                                        metric
                                                    ].short ?? metric}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                        )}
                    </List>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default FiltersTableReport;
