import React from "react";

import { Grid } from "@mui/material";
import CardDashboard from "./CardDashboard";
import SmallPercentage from "./SmallPercentage";

import Colours from "../helpers/colours";

const MINIMUM_SIZE = 2;

/*
"data" needs to be an array of objects. The keys within the objects *can* be:
{
    id: (number|string) | if it's empty, will  be the index
    title: (string) 
    format: {left: (string), right: (string) } to specify any left/right format
    value: (number)
    old: (number) if valid, will create a percentage to compare
    compare: (boolean) if true, it will check the "old" value to add an arrow and a percentage
}
*/
const TabSelector = ({
    hotelID = -1,
    data,
    cardSelected,
    setCardSelected,
    expandComponent = <></>,
}) => {
    // useEffect(() => {
    //     console.log(cardSelected, data);
    // }, [data, cardSelected]);

    return (
        <Grid container spacing={0.5} sx={{ pt: 1, pb: 1 }}>
            {(data.length > 0 ? data : []).map((e) => (
                <Grid
                    item
                    xs={6}
                    md={Math.max(MINIMUM_SIZE, 12 / data.length)}
                    onClick={() => {
                        cardSelected === e.id
                            ? setCardSelected("")
                            : setCardSelected(e.id);
                    }}
                    key={e.id}
                >
                    <CardDashboard
                        title={e.title}
                        value={e.value}
                        format={e.format}
                        selected={cardSelected === e.id}
                        elevation={2}
                        selectedColoured={cardSelected === e.id}
                        colours={{
                            selected: null,
                            unselected: Colours.navbarBottomLine,
                        }}
                        isIncrease={e.compare ? e.value - e.old > 0 : ""}
                        footer={
                            e.compare ? (
                                <SmallPercentage
                                    colorized
                                    actual={e.value}
                                    old={e.old}
                                    isInverted={e.isInverted ?? false}
                                />
                            ) : (
                                e.footer
                            )
                        }
                        subbody={e.subbody}
                        subtitle={e.subtitle}
                        noArrow={e.noArrow}
                        pointer={e.expand ?? false}
                        isInverted={e.isInverted ?? false}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default TabSelector;
