import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Container,
    Divider,
    Grid,
    Stack,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import WrapperMonthlyRoomsReport from "../components/reports/WrapperMonthlyRoomsReport";
import TopVouchersReport from "../components/reports/TopVouchersReport";
import ArrivalRevenueSummary from "../components/reports/ArrivalRevenueSummary";
import ArrivalRevenueReport from "../components/reports/ArrivalRevenueReport";
import TopBookedDates from "../components/reports/TopBookedDates";
import VouchersRevenueReport from "../components/reports/VouchersRevenueReport";
import RoomsAndVouchersRevenueReport from "../components/reports/RoomsAndVouchersRevenueReport";
import DataLoader from "../components/DataLoader";
import CancellationByReport from "../components/reports/CancellationByReport";

import LostRevenueReport from "../components/reports/LostRevenueReport";
import WrapperTopReport from "../components/reports/WrapperTopReport";

import CheckHide from "../components/shared/CheckHide";
import FabSettings from "../components/FabSettings";
import ScrollToTop from "../components/ScrollToTopButton";

import Colours from "../helpers/colours";

const MonthlyKPIsScreen = ({ isolated = false }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);
    const navigate = useNavigate();

    return (
        <>
            {!isolated ? (
                <>
                    <DataLoader Reservations SimpleData VouchersDetails />
                    <FabSettings />
                    <ScrollToTop />

                    {/*  __  __ _____ _   _ _   _  */}
                    {/* |  \/  | ____| \ | | | | | */}
                    {/* | |\/| |  _| |  \| | | | | */}
                    {/* | |  | | |___| |\  | |_| | */}
                    {/* |_|  |_|_____|_| \_|\___/  */}
                    <Container disableGutters>
                        <Stack
                            direction={"row"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Stack sx={{ overflowX: "auto" }}>
                                <Box
                                    sx={
                                        isMobile
                                            ? { pl: 1, pr: 1 }
                                            : { pl: 5, pr: 5 }
                                    }
                                >
                                    <Tabs
                                        value={1}
                                        onChange={({ target }) => {
                                            if (target.id !== "MonthlyKPIs") {
                                                navigate("/app");
                                            }
                                        }}
                                        variant="scrollable"
                                        sx={{
                                            "& .MuiTab-root": {
                                                textTransform: "none",
                                            },
                                        }}
                                        scrollButtons="auto"
                                        allowScrollButtonsMobile
                                        selectionFollowsFocus={true}
                                    >
                                        <Tab label="Home ↗" id="Home" />
                                        <Tab
                                            label="Monthly KPI's"
                                            id="MonthlyKPIs"
                                        />
                                    </Tabs>
                                </Box>
                            </Stack>
                            {/* <Stack
                                sx={
                                    isMobile
                                        ? { pl: 1, pr: 1 }
                                        : { pl: 5, pr: 5 }
                                }
                            >
                                <Box sx={{ minWidth: "100px" }}>
                                    <DateSelectorDropdown
                                        isMobile={isMobile}
                                        day={false}
                                    ></DateSelectorDropdown>
                                </Box>
                            </Stack> */}
                        </Stack>
                        <Divider
                            sx={{ borderColor: Colours.navbarBottomLine }}
                        />
                        <br />
                    </Container>
                </>
            ) : (
                <>
                    {/* <Container disableGutters sx={{ pb: 1 }}>
                        <Stack
                            direction={"row"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent="flex-end"
                        >
                            <DateSelectorDropdown
                                isMobile={isMobile}
                                day={false}
                            />
                        </Stack>
                    </Container> */}
                </>
            )}

            {/*  ____  _____ ____   ___  ____ _____ ____   */}
            {/* |  _ \| ____|  _ \ / _ \|  _ \_   _/ ___|  */}
            {/* | |_) |  _| | |_) | | | | |_) || | \___ \  */}
            {/* |  _ <| |___|  __/| |_| |  _ < | |  ___) | */}
            {/* |_| \_\_____|_|    \___/|_| \_\|_| |____/  */}
            <Container maxWidth={false} height="100vh">
                <br />
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <WrapperMonthlyRoomsReport hotelID={hotelID} />
                    </Grid>

                    <CheckHide k="Vouchers">
                        <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                            <VouchersRevenueReport hotelID={hotelID} />
                        </Grid>
                    </CheckHide>

                    <CheckHide k="Vouchers">
                        <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                            <RoomsAndVouchersRevenueReport hotelID={hotelID} />
                        </Grid>
                    </CheckHide>

                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <WrapperTopReport
                            hotelID={hotelID}
                            processBy="OfferTitle"
                            title="Top Performing Rates / Offers"
                            report_desc="This report shows the top selling rates / offers for the selected time period."
                        />
                    </Grid>

                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <WrapperTopReport
                            hotelID={hotelID}
                            processBy="RoomName"
                            title="Top Performing Rooms"
                            report_desc="This report shows the top selling rooms booked for the selected time period."
                        />
                    </Grid>

                    <CheckHide k="Vouchers">
                        <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                            <TopVouchersReport hotelID={hotelID} />
                        </Grid>
                    </CheckHide>

                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <WrapperTopReport
                            hotelID={hotelID}
                            title="Top Countries"
                            report_desc="This report shows the top selling countries for the selected time period."
                        />
                    </Grid>

                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <TopBookedDates hotelID={hotelID} />
                    </Grid>

                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <WrapperTopReport
                            hotelID={hotelID}
                            processBy="ResDate"
                            title="Top Selling Dates"
                            report_desc="This report shows the dates with the highest number of bookings within a selected time period."
                        />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ width: "100%" }}>
                        <ArrivalRevenueReport hotelID={hotelID} />
                    </Grid>

                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <ArrivalRevenueSummary hotelID={hotelID} />
                    </Grid>

                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <CancellationByReport
                            hotelID={hotelID}
                            cancelBy={"CancellationBookingMonth"}
                            title={"Cancellation by Booking"}
                            desc={
                                "Using Reservation date, shows data for cancellations made in time selected."
                            }
                        />
                    </Grid>
                    <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                        <CancellationByReport
                            hotelID={hotelID}
                            cancelBy={"CancellationArrivalMonth"}
                            title={"Cancellation by Arrival"}
                            desc={
                                "Using the Check In date, shows data for cancellations made in time selected."
                            }
                        />
                    </Grid>

                    <CheckHide k="LostRevenue">
                        <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                            <LostRevenueReport hotelID={hotelID} />
                        </Grid>
                    </CheckHide>
                </Grid>
            </Container>
        </>
    );
};

export default MonthlyKPIsScreen;
