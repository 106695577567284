import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Container, Stack, Typography } from "@mui/material";

import UndoFab from "../UndoFab";
import NotificationCard from "../NotificationCard";
import LoadingBubble from "../LoadingBubble";
import DataLoader from "../DataLoader";

import { NotificationsTypes } from "../../types/NotificationsTypes";
import NotificationsNew from "./NotificationsNew";

import {
    STATUS_COMPLETE,
    STATUS_ERROR,
    STATUS_WARNING,
} from "../../helpers/status";

const hoursAgo = (date, hours) =>
    new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours() - hours
    );

const isToday = (date) => {
    const today = new Date();
    return date.toDateString() === today.toDateString();
};

const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.toDateString() === yesterday.toDateString();
};

const IndicatorsNotifications = () => {
    const dispatch = useDispatch();
    const notifications_store = useSelector((state) => state.notifications);
    const [discardedNotifications, setDiscardedNotifications] = useState([]);
    const [notifications, setNotifications] = useState([
        {
            id: 1,
            status: STATUS_ERROR,
            name: "FAQs implemented",
            subtext: "We will continue to measure number of visitors",
            ts: new Date(),
        },
        {
            id: 2,
            status: STATUS_WARNING,
            name: "FAQs implemented",
            subtext:
                "We will continue to measure number of visitors visitors visitors",
            ts: hoursAgo(new Date(), 3),
        },
        {
            id: 3,
            status: STATUS_COMPLETE,
            name: "FAQs implemented",
            subtext: "a a a a a a a a a a a a a a a a a a a a a a a ",
            ts: hoursAgo(new Date(), 6),
        },
        {
            id: 4,
            status: "Other",
            name: "FAQs implemented",
            subtext:
                "We will continue to measure number of visitorsvisitorsvisitorsvisitorsvisitorsvisitorsvisitorsvisitorsvisitorsvisitorsvisitorsvisitorsvisitors",
            ts: hoursAgo(new Date(), 7.3),
        },
        {
            id: 5,
            status: STATUS_ERROR,
            name: "FAQs implemented",
            subtext:
                "We will continue to measure number of visitorsWe will continue to measure number of visitorsWe will continue to measure number of visitorsWe will continue to measure number of visitorsWe will continue to measure number of visitors",
            ts: hoursAgo(new Date(), 24 + 3),
        },
        {
            id: 6,
            status: STATUS_COMPLETE,
            name: "FAQs implemented implemented implementedFAQs implemented implemented implementedFAQs implemented implemented implemented",
            subtext:
                "We will continue to measure number of visitorsWe will continue to measure number of visitorsWe will continue to measure number of visitorsWe will continue to measure number of visitorsWe will continue to measure number of visitors",
            ts: hoursAgo(new Date(), 24 * 5 + 6),
        },
    ]);
    useEffect(() => {
        if (notifications_store) {
            console.log({ notifications_store });
            setNotifications((cn) => notifications_store.data);
        }
    }, [notifications_store]);

    const handleUndo = () => {
        setDiscardedNotifications((currentDiscarded) => {
            if (currentDiscarded.length > 0) {
                const last = currentDiscarded[currentDiscarded.length - 1];

                setNotifications((currentNotifications) => [
                    ...currentNotifications,
                    last,
                ]);

                return currentDiscarded.slice(0, -1);
            }
            return currentDiscarded;
        });
    };

    const handleDismiss = (notificationId) => {
        const discarded = notifications.find((n) => n.id === notificationId);

        setNotifications((currentNotifications) =>
            currentNotifications.filter((n) => n.id !== notificationId)
        );
        setDiscardedNotifications([...discardedNotifications, discarded]);
    };

    const todayNotifications = notifications
        .filter((notification) => isToday(new Date(notification.ts)))
        .sort((a, b) => b.ts - a.ts);
    const yesterdayNotifications = notifications
        .filter((notification) => isYesterday(new Date(notification.ts)))
        .sort((a, b) => b.ts - a.ts);
    const olderNotifications = notifications
        .filter(
            (notification) =>
                !isToday(new Date(notification.ts)) &&
                !isYesterday(new Date(notification.ts))
        )
        .sort((a, b) => b.ts - a.ts);

    // Reload if no task to undo
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (discardedNotifications.length === 0) {
                dispatch({ type: NotificationsTypes.reload });
            }
        }, 2 * 60 * 1000); //  2 minutes

        return () => clearInterval(intervalId);
    }, [dispatch]);

    return (
        <Container sx={{ position: "relative" }}>
            <DataLoader /> {/*  Only for settings and notifications*/}
            <div style={{ position: "absolute", right: 10, top: 10 }}>
                <LoadingBubble
                    dataKey="notifications"
                    title="Notifications"
                    index={0}
                    reload={NotificationsTypes.reload}
                />
            </div>
            <br />
            <Typography>Notifications</Typography>
            <br />
            <br />
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
                sx={{ overflowX: "hidden", pb: 3, p: 2 }}
            >
                {todayNotifications.map((notification) => (
                    <NotificationCard
                        id={notification.id}
                        key={notification.id}
                        type={notification.status}
                        title={notification.name}
                        text={notification.subtext}
                        time={new Date(notification.ts)}
                        onDismiss={handleDismiss}
                        onClick={notification.onClick}
                    />
                ))}
                {yesterdayNotifications.length > 0 && (
                    <>
                        <Typography variant="h5">Yesterday</Typography>
                        {yesterdayNotifications.map((notification) => (
                            <NotificationCard
                                id={notification.id}
                                key={notification.id}
                                type={notification.status}
                                title={notification.name}
                                text={notification.subtext}
                                time={new Date(notification.ts)}
                                onDismiss={handleDismiss}
                                onClick={notification.onClick}
                            />
                        ))}
                    </>
                )}
                {olderNotifications.length > 0 && (
                    <>
                        <Typography variant="h5">Older</Typography>
                        {olderNotifications.map((notification) => (
                            <NotificationCard
                                id={notification.id}
                                key={notification.id}
                                type={notification.status}
                                title={notification.name}
                                text={notification.subtext}
                                time={new Date(notification.ts)}
                                onDismiss={handleDismiss}
                                onClick={notification.onClick}
                            />
                        ))}
                    </>
                )}
            </Stack>
            <UndoFab
                list_={discardedNotifications}
                onClick={handleUndo}
                onEnd={() => console.log("end")}
            />
            <hr />
            <NotificationsNew />
        </Container>
    );
};

export default IndicatorsNotifications;
