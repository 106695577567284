import { arrayDictYearMonthSort } from "../helpers/api";
import { dataSimpleTypes } from "../types/dataSimpleTypes";

const dataSimpleInitial = {
    status: "empty",
    reload: new Date().toISOString(),
};
export const dataSimpleReducer = (state = dataSimpleInitial, action) => {
    switch (action.type) {
        case dataSimpleTypes.loading:
            return {
                ...state,
                status: "loading",
                data: {},
                hotelID: action.hotelID,
            };

        case dataSimpleTypes.reloading:
            return {
                ...state,
                status: "reloading",
                data: state.data,
                hotelID: state.hotelID,
            };

        case dataSimpleTypes.partialLoad:
            return {
                ...state,
                status: "partiallyLoaded",
                data: action.payload,
                hotelID: action.hotelID,
            };

        case dataSimpleTypes.load:
            return {
                status: "loaded",
                data: action.payload,
                hotelID: action.hotelID,
            };

        case dataSimpleTypes.reload:
            return {
                ...state,
                status: "reload",
                reload: new Date().toISOString(),
            };

        case dataSimpleTypes.error:
            return {
                ...state,
                status: "error",
                data: action.payload,
                error: action.error,
            };

        case dataSimpleTypes.clean:
            return dataSimpleInitial;

        case dataSimpleTypes.updateOne:
            const { who, month_v, year_v, obj } = action.payload;

            const data = JSON.parse(JSON.stringify(state));
            console.log({ filter: state.data[who], state });
            let notTheOne = state.data[who].filter(
                (item) =>
                    !(
                        (item.month_v ?? "") === month_v &&
                        (item.year_v ?? "") === year_v
                    )
            );
            let theOne =
                state.data[who].filter(
                    (item) =>
                        (item.month_v ?? "") === month_v &&
                        (item.year_v ?? "") === year_v
                )[0] ?? {};

            theOne = { ...theOne, month_v, year_v, ...obj };

            data[who] = arrayDictYearMonthSort([...notTheOne, theOne]);

            console.log({ data, theOne, notTheOne });

            return {
                ...state,
                status: "loaded",
                data: data,
                hotelID: action.hotelID,
            };

        default:
            return state;
    }
};
