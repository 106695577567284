import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    CircularProgress,
    Container,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import Axios from "axios";
import { useSnackbar } from "notistack";

import CheckShow from "./shared/CheckShow";
import RoundedButton from "./RoundedButton";

import { ARO_ALL, DEFAULT_HOTEL, URLAPI } from "../configuration";

import Icons from "../helpers/icons";

const MarketingStrategy = () => {
    const [managers, setManagers] = useState([]);
    const [links, setLinks] = useState([]);
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);

    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);
        setLinks([]);
        setManagers([]);
        Axios({
            method: "get",
            url: `${URLAPI}/marketing_strategy/get/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                const {
                    data: { data },
                } = response;

                const { links: links_, managers: managers_ } = data;
                setLinks(links_);
                setManagers(managers_);
                setLoading(false);
            })
            .catch((e) => {
                console.log({ error_loading: e });
                setLoading(false);
            });
    }, [hotelID, reload]);

    const [linkName, setLinkName] = useState("");
    const [linkUrl, setLinkUrl] = useState("arosuite.com");

    const handleAddElement = () => {
        Axios({
            method: "post",
            url: `${URLAPI}/marketing_strategy/link/new/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            data: { name: linkName, url: linkUrl },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                setReload(reload + 1);
                setLinkName("");
                setLinkUrl("");
                enqueueSnackbar("Element added", { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar("Error at adding the element", {
                    variant: "error",
                });
            });
    };

    const handleDeleteLink = (link_id) => {
        Axios({
            method: "delete",
            url: `${URLAPI}/marketing_strategy/link/delete/${link_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                enqueueSnackbar("Link deleted", { variant: "success" });
                setReload(reload + 1);
            })
            .catch((error) => {
                enqueueSnackbar("Error deleting the link", {
                    variant: "error",
                });
            });
    };

    const [managerName, setManagerName] = useState("");
    const [managerEmail, setManagerEmail] = useState("");

    const handleAddManager = () => {
        Axios({
            method: "post",
            url: `${URLAPI}/marketing_strategy/manager/new/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            data: { manager_name: managerName, manager_email: managerEmail },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                setReload(reload + 1);
                setManagerEmail("");
                setManagerName("");
                enqueueSnackbar("Manager added", { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar("Error at adding the manager", {
                    variant: "error",
                });
            });
    };

    const handleDeleteManager = (manager_id) => {
        Axios({
            method: "delete",
            url: `${URLAPI}/marketing_strategy/manager/delete/${manager_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                setReload(reload + 1);
                enqueueSnackbar("Manager deleted", { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar("Error deleting the manager", {
                    variant: "error",
                });
            });
    };

    return (
        <>
            <Container>
                <Grid container>
                    <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                        <Typography variant="h4">Saved Resources</Typography>
                    </Grid>
                </Grid>
                <List>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <Icons.Home />
                        </ListItemIcon>
                        <ListItemButton
                            component="a"
                            href="https://www.arosuite.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ListItemText primary="Aró Suite" />
                        </ListItemButton>
                    </ListItem>
                    {loading && <CircularProgress />}
                    {links.map(({ hotel_id, url, url_name, ID: link_id }) => (
                        <ListItem
                            key={`${hotel_id}${url}${link_id}`}
                            disablePadding
                        >
                            <ListItemIcon>
                                <Icons.Link />
                            </ListItemIcon>
                            <ListItemButton
                                component="a"
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ListItemText primary={url_name} />
                            </ListItemButton>
                            <CheckShow rolesThatCanSee={ARO_ALL}>
                                <ListItemIcon>
                                    <IconButton
                                        onClick={() =>
                                            handleDeleteLink(link_id)
                                        }
                                    >
                                        <Icons.Delete />
                                    </IconButton>
                                </ListItemIcon>
                            </CheckShow>
                        </ListItem>
                    ))}
                    {managers.map(
                        ({
                            hotel_id,
                            manager_email,
                            manager_name,
                            ID: manager_id,
                        }) => (
                            <ListItem key={hotel_id} disablePadding>
                                <ListItemIcon>
                                    <Icons.User />
                                </ListItemIcon>
                                <ListItemButton
                                    component="a"
                                    href={`mailto:${manager_email}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ListItemText
                                        primary={`Manager: ${manager_name}`}
                                    />
                                </ListItemButton>
                                <CheckShow rolesThatCanSee={ARO_ALL}>
                                    <ListItemIcon>
                                        <IconButton
                                            onClick={() =>
                                                handleDeleteManager(manager_id)
                                            }
                                        >
                                            <Icons.Delete />
                                        </IconButton>
                                    </ListItemIcon>
                                </CheckShow>
                            </ListItem>
                        )
                    )}
                </List>
                <CheckShow rolesThatCanSee={ARO_ALL}>
                    <>
                        <br />
                        <TextField
                            size="small"
                            label="Name"
                            variant="outlined"
                            value={linkName}
                            onChange={(e) => setLinkName(e.target.value)}
                            style={{ marginRight: "10px" }}
                        />
                        <TextField
                            size="small"
                            label="URL"
                            variant="outlined"
                            value={linkUrl}
                            onChange={(e) => setLinkUrl(e.target.value)}
                            style={{ marginRight: "10px" }}
                        />
                        <RoundedButton onClick={handleAddElement}>
                            New element
                        </RoundedButton>
                        <br />
                        <br />
                        <TextField
                            size="small"
                            label="Manager Name"
                            variant="outlined"
                            value={managerName}
                            onChange={(e) => setManagerName(e.target.value)}
                            style={{ marginRight: "10px" }}
                        />
                        <TextField
                            size="small"
                            label="Manager Email"
                            variant="outlined"
                            value={managerEmail}
                            onChange={(e) => setManagerEmail(e.target.value)}
                            style={{ marginRight: "10px" }}
                        />
                        <RoundedButton onClick={handleAddManager}>
                            New Manager
                        </RoundedButton>
                    </>
                </CheckShow>
            </Container>
        </>
    );
};

export default MarketingStrategy;
