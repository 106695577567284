import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Axios from "axios";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { Button, CircularProgress, Table, TableBody } from "@mui/material";

import MonthlyReport from "../components/MonthlyReport";
import NoDataRow from "../components/NoDataRow";

import { DEFAULT_HOTEL, URLAPI } from "../configuration";

const getData = async (reportID, hotel_id, auth) => {
    console.log("getData", { reportID, hotel_id, auth });
    let reports = [];
    // Load report if provided
    if (reportID) {
        const response = await Axios.get(
            `${URLAPI}/jenkins/month_summary/get/${reportID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: `Bearer ${auth.user.jwt}`,
                },
            }
        );
        if (response.data.len > 0) {
            reports.push(response.data.data[0]);
        }
        console.log({ responseReport: response });
    }
    // Load all reports for that hotel if not report provided
    else {
        const response = await Axios.get(
            `${URLAPI}/jenkins/month_summary/list/${
                hotel_id === "default" ? DEFAULT_HOTEL : hotel_id
            }`,
            {
                headers: {
                    "Content-Type": "application/json",
                    //Authorization: `Bearer ${auth.user.jwt}`,
                },
            }
        );

        if (response.data.len > 0) {
            response.data.data.forEach((d) => {
                reports.push(d);
            });
        }
        console.log({ responseList: response });
    }

    console.log({ reports });

    // process them
    return reports.map((d) => {
        try {
            return {
                reportID: d.reportID,
                title: d.title,
                month: new Date(d.year_v, d.month_v).toLocaleString("en", {
                    month: "long",
                }),
                month_v: d.month_v,
                ts: d.ts,
                year: d.year_v,
                hotel_id: d.hotel_id,
                previousMonth: new Date(
                    d.year_v - (d.month_v === 1 ? 1 : 0),
                    (d.month_v + 10) % 12
                ).toLocaleString("en", { month: "long" }),
                reports: JSON.parse(d.reports),
            };
        } catch (error) {
            console.error(
                "There are reports with NaN values",
                d.reportID,
                error
            );
            return undefined;
        }
    });
};

const MonthlyReportScreen = ({ isCollapsible = true }) => {
    const { id: auxHotelID } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);
    let { monthlyReportId } = useParams();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        console.log("useEffect", { monthlyReportId, auxHotelID, auth });

        setLoading(true);
        getData(monthlyReportId, auxHotelID, auth).then((r) => {
            r = r.filter(function (element) {
                return element !== undefined;
            });
            console.log("Then getData", { data: r });
            setData(r);
            setLoading(false);
        });
    }, [monthlyReportId, auxHotelID, reload]);

    const generateLastMonth = () => {
        setLoading(true);
        let month = new Date().getUTCMonth();
        let year = new Date().getUTCFullYear();

        if (month === 0) {
            month = 11; // December (cause January is 0)
            year = year - 1; // Year before
        } else {
            month = month - 1; // Month before
        }
        Axios.get(
            `${URLAPI}/jenkins/month_summary/new/${
                auxHotelID === "default" ? DEFAULT_HOTEL : auxHotelID
            }`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
                params: {
                    title: `${year}/${String(month + 1).padStart(2, "0")}`,
                    month,
                    year,
                },
            }
        )
            .then((response) => {
                getData(monthlyReportId, auxHotelID, auth).then((r) => {
                    r = r.filter(function (element) {
                        return element !== undefined;
                    });
                    setData(r);
                    setLoading(false);
                });
            })
            .catch((e) => setLoading(false));
    };

    return (
        <>
            {/* {isCollapsible && (
                <Button disabled={loading} onClick={() => generateLastMonth()}>
                    Generate last month
                </Button>
            )} */}
            {loading && <CircularProgress />}

            <br />
            <br />

            {data.map((d, index) => (
                <MonthlyReport
                    key={index}
                    data={d}
                    isCollapsible={isCollapsible}
                    setReload={setReload}
                />
            ))}

            {data.length === 0 && (
                <Table>
                    <TableBody>
                        <NoDataRow />
                    </TableBody>
                </Table>
            )}
        </>
    );
};

export default MonthlyReportScreen;
