import React from "react";
import InsightAssistRowDesktop from "./InsightAssistRowDesktop";
import InsightAssistRowMobile from "./InsightAssistRowMobile";

const InsightAssistRow = ({
    isMobile,
    indicatorName,
    indicatorNameFormat,
    growthTrackName,
    weighting,
    currentScore,
    previousScore,
    priority,
    status,
    whatV = "This indicator does this... Would be very nice if you could do something about it...",
    whyV = "Our AI and data shows you could increase your revenue by hefty 15%",
    howV = "Increase DM Budget & Talk to our Team",
    showRedAlertCheckbox = false,
    selectedList = [],
    setSelectedList = () => {},
    hideWeights = false,
}) => {
    if (!whatV)
        whatV =
            "This indicator does this... Would be very nice if you could do something about it...";
    if (!whyV)
        whyV =
            "Our AI and data shows you could increase your revenue by hefty 15%";
    if (!howV) howV = "Increase DM Budget & Talk to our Team";
    if (isMobile) {
        return (
            <InsightAssistRowMobile
                indicatorName={indicatorName}
                indicatorNameFormat={indicatorNameFormat}
                growthTrackName={growthTrackName}
                weighting={weighting}
                currentScore={currentScore}
                previousScore={previousScore}
                priority={priority}
                status={status}
                whatV={whatV}
                howV={howV}
                whyV={whyV}
            />
        );
    } else
        return (
            <InsightAssistRowDesktop
                indicatorName={indicatorName}
                indicatorNameFormat={indicatorNameFormat}
                growthTrackName={growthTrackName}
                weighting={weighting}
                currentScore={currentScore}
                previousScore={previousScore}
                priority={priority}
                status={status}
                whatV={whatV}
                howV={howV}
                whyV={whyV}
                showRedAlertCheckbox={showRedAlertCheckbox}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                hideWeights={hideWeights}
            />
        );
};

export default InsightAssistRow;
