import { globalReportFiltersTypes } from "../types/globalReportFiltersTypes";

export const setJS = (newJS) => {
    return {
        type: globalReportFiltersTypes.set,
        payload: newJS,
    };
};

export const cleanJS = () => {
    return {
        type: globalReportFiltersTypes.clean,
    };
};
