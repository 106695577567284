import React from "react";

import SearchRequests from "../components/SearchRequests";
import { Grid, Typography } from "@mui/material";

const AroInsightsScreen = () => {
    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 2, ml: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Insights</Typography>
                </Grid>
            </Grid>{" "}
            <SearchRequests />
        </>
    );
};
export default AroInsightsScreen;
