import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Tooltip } from "@mui/material";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";

import "../styles/animations.css";

const SIZE = 12;

const breathingAnimation = {
    "@keyframes breathingEffect": {
        "0%": {
            transform: "scale(1)",
            opacity: 0.7,
        },
        "50%": {
            transform: "scale(1.1)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(1)",
            opacity: 0.7,
        },
    },
    animation: "breathingEffect 2s ease-in-out infinite",
};

const LoadingBubble = ({ dataKey, title, index = 0, reload = "" }) => {
    const { [dataKey]: data } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [loadingStartTime, setLoadingStartTime] = useState(null);
    const [loadingDuration, setLoadingDuration] = useState(0);

    useEffect(() => {
        if (data.status === "loading") {
            setLoadingStartTime(Date.now());
        } else if (data.status === "loaded" && loadingStartTime !== null) {
            const endTime = Date.now();
            const duration = endTime - loadingStartTime;
            setLoadingDuration(duration);
        }
    }, [data.status]);

    const handleDoubleClick = () => {
        console.log("double clicked!", reload);
        dispatch({ type: reload });
    };

    const determineColor = (status) => {
        switch (status) {
            case "loaded":
                return Colours.trafficLightGreen;
            case "loading":
            case "reload":
            case "partiallyLoaded":
                return Colours.trafficLightYellow;
            default:
                return Colours.trafficLightRed;
        }
    };

    return (
        <Tooltip
            title={`${title} - ${data.status} (${loadingDuration / 1000} s)`}
        >
            <Icons.Circle
                onDoubleClick={handleDoubleClick}
                sx={{
                    fontSize: SIZE,
                    color: determineColor(data.status),
                    mr: (index + 1) % 3 === 0 ? 1 : 0,

                    ...(data.status === "partiallyLoaded"
                        ? {
                              border: "1px solid " + Colours.trafficLightGreen,
                              borderRadius: "100%",
                          }
                        : {}),

                    ...(data.status === "loading" ||
                    data.status === "reload" ||
                    data.status === "partiallyLoaded"
                        ? breathingAnimation
                        : {}),
                    cursor: "pointer",
                }}
            />
        </Tooltip>
    );
};

export default LoadingBubble;
