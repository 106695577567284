import React, { useEffect, useState } from "react";

import { Grid, Skeleton } from "@mui/material";

import TrendChart from "../TrendChart";

import { months } from "../../helpers/dates";

const MembershipRoomNightsReport = ({
    data,
    time = new Date(),
    isMobile = false,
}) => {
    const TITLE = "Membership - Room Nights";
    const [dataMonth, setDataMonth] = useState({});

    useEffect(() => {
        const yearN = time.getUTCFullYear();
        const monthN = time.getUTCMonth();
        const lastDay = new Date(yearN, monthN + 1, 0).getUTCDate();
        let auxDataMonth = {};
        for (let i = 1; i <= lastDay; i++) {
            auxDataMonth[i] = 0;
        }

        if (data.hasOwnProperty(yearN) && data[yearN].hasOwnProperty(monthN)) {
            data[yearN][monthN].forEach((entry) => {
                const currentDay = new Date(entry.ResDate).getUTCDate();
                auxDataMonth[currentDay] += entry.RoomNights;
            });
        }

        setDataMonth(auxDataMonth);
    }, [data, time]);

    return (
        <>
            <Grid item sx={{ width: "100%", height: "100%" }}>
                <Grid
                    container
                    direction={isMobile ? "column" : "row"}
                    alignItems="center"
                    justifyContent="center"
                >
                    {Object.keys(dataMonth).length > 0 ? (
                        <>
                            <br />

                            <Grid item sm={12} md={12}>
                                <TrendChart
                                    TITLE={TITLE}
                                    key={"monthChart"}
                                    container=""
                                    data={{
                                        [`${
                                            months[time.getUTCMonth()]
                                        } ${time.getUTCFullYear()}`]:
                                            Object.keys(dataMonth).reduce(
                                                (acc, day) => {
                                                    acc[day] = dataMonth[day];
                                                    return acc;
                                                },
                                                {}
                                            ),
                                    }}
                                    markPoints={{
                                        min: {
                                            show: true,
                                        },
                                        max: {
                                            show: true,
                                        },
                                    }}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item sm={12} md={12}>
                            <br />
                            <Skeleton height={250} variant="rounded" />
                            <br />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default MembershipRoomNightsReport;
