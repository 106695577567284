// IMPORTANT: Update colors in comments if material color is changed
// VSCode Extensions: circlecodesolution.ccs-flutter-color and chinchiheather.vscode-margin-colours

import {
    teal,
    orange,
    lime,
    lightGreen,
    yellow,
    pink,
    blue,
    grey as greyV,
    green,
    red,
    indigo,
    amber,
    purple,
} from "@mui/material/colors";

const primary = orange[400]; // "#ffa726"
const secondary = orange[300]; //"#ffb74d"
const secondarySoft = orange[100]; //"#ffe0b2"
const error = red[800]; //"#b71c1c"
const info = indigo[400]; //"#5c6bc0"
const infoSoft = indigo[100]; //"#c5cae9"
const warning = amber[300]; //"#ffd54f"
const backgroundWhite = greyV[50]; //"#fafafa"
const plainWhite = "#ffffff"; //"#FAFAFA"

const textColour = greyV[800]; //"#424242"
const midLightText = greyV[400]; //"#bdbdbd"
const lightText = greyV[50]; //"#fafafa"
const greyIcon = greyV[600];

const divider = primary;

const notificationCard = greyV[200]; //"#eeeeee"

const lightBubble = greyV[100]; //"#f5f5f5"
const transparentBubble = greyV[500]; //"#9e9e9e"

const attract = yellow[800]; //"#f9a825"
const engage = teal[300]; //"#4db6ac"
const convert = pink[300]; //"#f06292"
const retain = blue[600]; //"#1e88e5"

const pillarBackgroundAttract = orange[50]; //"#fff3e0"
const pillarBackgroundEngage = green[50]; //"#e8f5e9"
const pillarBackgroundConvert = red[50]; //"#ffebee"
const pillarBackgroundRetain = blue[50]; //"#e3f2fd"

const statusBackgroundCompleted = green[50]; //"#e8f5e9"
const statusBackgroundPending = red[50]; //"#ffebee"

const trafficLightGreen = green[400]; //"#66bb6a"
const trafficLightYellow = yellow[800]; //"#f9a825"
const trafficLightRed = red[400]; //"#ef5350"

const navbarBottomLine = greyV[300]; //"#e0e0e0"

const notificationBackground = orange[100]; //"#ffe0b2"

const selectedRow = orange[100]; //"#ffe0b2"
const tableHeaderBackground = orange[100]; //"#ffe0b2"
const tableRowBackground = greyV[300]; //"#e0e0e0"

const graphMainLine1 = primary; //"#ffa726"
const graphMainLine2 = red[500]; //"#f44336"
const graphMainLine3 = teal[600]; //"#00897b"
const graphExtraLine1 = purple[500]; //"#9c27b0"
const graphExtraLine2 = blue[700]; //"#1976d2"

const graphSold = graphMainLine1; //"#ffa726"
const graphDisabled = greyV[600]; //"#757575"
const graphDisabledDarker = greyV[800]; //"#424242"
const graphDisabledLighter = greyV[400]; //"#bdbdbd"
const graphTarget = graphMainLine2; //"#f44336"
const graphProjection = graphMainLine3; //"#00897b"

const hoverGrey = "rgba(0, 0, 0, 0.04)";

export const avatarColours = [
    red[300], //"#e57373"
    red[600], //"#e53935"
    red[900], //"#b71c1c"

    pink[300], //"#f06292"
    pink[600], //"#d81b60"
    pink[900], //"#880e4f"

    purple[300], // '#ba68c8'
    purple[600], // '#8e24aa'
    purple[900], // '#4a148c'

    yellow[600], //"#fdd835"
    yellow[900], //"#f57f17"

    orange[300], //"#ffb74d"
    orange[600], //"#fb8c00"
    orange[900], //"#e65100"

    blue[300], //"#64b5f6"
    blue[600], //"#1e88e5"
    blue[900], //"#0d47a1"

    teal[300], //"#4db6ac"
    teal[600], //"#00897b"
    teal[900], //"#004d40"

    lime[500], //"#cddc39"

    lightGreen[600], //"#7cb342"
    lightGreen[900], //"#33691e"

    green[300], //"#81c784"
    green[600], //"#43a047"
    green[900], //"#1b5e20"

    greyV[300], // '#e0e0e0
    greyV[600], // '#757575'
    greyV[900], // '#212121'
];

// Events, TrendByChart and OverviewTable depends on these colours

// export const pastelColours = [
//     "#E59EAD",
//     "#FACC15",
//     "#A5DEE7",
//     "#7FABDA",
//     "#AE99C8",
//     "#A3D9A5",
//     "#cc3d5c",
//     "#AFB0B2",
// ];

export const pastelColours = [
    "#FF6961",
    "#FFB6C1",
    "#FFDAB9",
    "#FFEB99",
    "#98FF98",
    "#E0FFFF",
    "#AEC6CF",

    "#F08080",
    "#FFD1A4",
    "#FAFAD2",
    "#77DD77",
    "#AFEEEE",
    "#87CEFA",
    "#E6E6FA",
];

// Example get an avatar colour by name:
// Object.values(Colours.avatarColours)[
//     (name ?? "?").charCodeAt(0) %
//         Object.values(Colours.avatarColours)
//             .length
// ]

const Colours = {
    primary,
    secondary,
    secondarySoft,
    error,
    info,
    infoSoft,
    warning,
    backgroundWhite,
    plainWhite,
    attract,
    convert,
    engage,
    retain,
    trafficLightGreen,
    trafficLightYellow,
    trafficLightRed,
    transparentBubble,
    lightBubble,
    notificationCard,
    textColour,
    lightText,
    midLightText,
    greyIcon,
    divider,
    navbarBottomLine,
    selectedRow,
    statusBackgroundCompleted,
    statusBackgroundPending,
    pillarBackgroundAttract,
    pillarBackgroundEngage,
    pillarBackgroundConvert,
    pillarBackgroundRetain,
    notificationBackground,
    tableHeaderBackground,
    tableRowBackground,
    graphSold,
    graphDisabled,
    graphDisabledDarker,
    graphDisabledLighter,
    graphTarget,
    graphProjection,
    graphMainLine1,
    graphMainLine2,
    graphMainLine3,
    graphExtraLine1,
    graphExtraLine2,
    hoverGrey,
};
export default Colours;
