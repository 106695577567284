import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Container,
    FormControlLabel,
    Checkbox,
    Box,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import WrapperPDFTable from "../shared/WrapperPDFTable";
import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import TableHeadSort from "../TableHeadSort";
import TableBodyCell from "../TableBodyCell";
import CircleValue from "../CircleValue";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import DateSelector from "../DateSelector";
import OptionsButtonDialog from "../OptionsButtonDialog";
import CardCarousel from "../CardCarousel";
import TopCards from "../TopCards";
import SmallPercentage from "../SmallPercentage";
import SwitchVisualTable, {
    TABULAR_MODE,
    TABLE_MODE,
} from "../SwitchVisualTable";

import Colours from "../../helpers/colours";
import { fns } from "../../helpers/common";
import { getYearsBefore, months } from "../../helpers/dates";
import { processByAttribute, SliceTop, TOP_ALL } from "../../helpers/reports";
import { reservationsToYearMonth } from "../DataLoader";

const TOP = [5, 10, TOP_ALL];

const WrapperTopReport = ({
    hotelID,
    processBy = "Country",
    title,
    report_desc,
}) => {
    const theme = useTheme();
    let tableOverflow = useMediaQuery(theme.breakpoints.down("md"));
    let isMobile = useMediaQuery(theme.breakpoints.down("sm")); // XS is working as SM?

    const [mode, setMode] = useState(TABLE_MODE);

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(false);
    const [cancellations, setCancellations] = useState(false);

    const [selectedDataSource, setSelectedDataSource] = useState({
        status: "empty",
    });

    const {
        time: sharedTime,
        fullYear: sharedFullYear,
        dataSource: sharedDataSource,
    } = useSelector((state) => state.settings.settings);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("bookings");
    const [topNumber, setTopNumber] = useState(0);
    const [connectionError, setConnectionError] = useState(false);
    const { dataBookings } = useSelector((state) => state);

    const REPORT_DESC = report_desc;
    const TITLE = title;
    const HEADER = [
        {
            id: "revenue",
            label: "Revenue",
            format: { left: "currency" },
            sumUp: true,
            cards_unit: "top total",
            isInverted: cancellations,
        },
        {
            id: "bookings",
            label: "Bookings",
            sumUp: true,
            cards_unit: "top total",
            isInverted: cancellations,
        },
        {
            id: "roomNights",
            label: "Room Nights",
            sumUp: true,
            cards_unit: "top total",
            isInverted: cancellations,
        },
        {
            id: "ABV",
            label: "ABV",
            format: { left: "currency" },
            tooltip: "Average Booking Value",
            sumUp: false,
            isInverted: cancellations,
        },
        {
            id: "ADR",
            label: "ADR",
            format: { left: "currency" },
            tooltip: "Average Daily Rate",
            sumUp: false,
            isInverted: cancellations,
        },
        {
            id: "ALoS",
            label: "ALoS",
            format: { right: " days", maxDecimals: 2 },
            tooltip: "Average Length of Stay",
            sumUp: false,
            isInverted: cancellations,
        },
        {
            id: "ABW",
            label: "ABW",
            format: { right: " days" },
            tooltip: "Average Booking Window",
            sumUp: false,
            isInverted: cancellations,
        },
    ];

    const [tableData, setTableData] = useState([]);
    const [displayData, setDisplayData] = useState({});
    const [dataByYear, setDataByYear] = useState({});
    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});
    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const setMonthsData = [
        setDataByJanuary,
        setDataByFebruary,
        setDataByMarch,
        setDataByApril,
        setDataByMay,
        setDataByJune,
        setDataByJuly,
        setDataByAugust,
        setDataBySeptember,
        setDataByOctober,
        setDataByNovember,
        setDataByDecember,
    ];

    const calcPercentage = (actual, old, format, isInverted) => {
        return (
            <SmallPercentage
                actual={actual}
                old={old}
                context={
                    "The selected period compared to the same period last year"
                }
                formatter={(number) => fns(hotelID, number, format)}
                isInverted={isInverted}
            />
        );
    };

    useEffect(() => {
        switch (sharedDataSource) {
            case "CheckOut":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(
                        dataBookings.data,
                        "CheckOut"
                    ),
                });
                break;
            case "CheckIn":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(dataBookings.data, "CheckIn"),
                });
                break;
            case "ResDate":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(dataBookings.data, "ResDate"),
                });
                break;

            default:
                setSelectedDataSource({ status: "empty" });
                break;
        }
    }, [sharedDataSource, dataBookings]);

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    useEffect(() => {
        if (selectedDataSource.status === "error") setConnectionError(true);
        if (selectedDataSource.status === "loaded") {
            // console.log(selectedDataSource.data);

            // Get array of all years to n years before
            let years = getYearsBefore();

            months.map((month, index) =>
                setMonthsData[index](
                    Object.assign(
                        {},
                        ...years.map((year) => ({
                            [year]: processByAttribute(
                                (
                                    (selectedDataSource.data[`${year}`] ?? {})[
                                        month
                                    ] ?? []
                                ).filter((element) =>
                                    ["Special Offer"].includes(element.Type)
                                ),
                                processBy,
                                cancellations
                            ),
                        }))
                    )
                )
            );

            setDataByYear(
                Object.assign(
                    {},
                    ...years.map((year) => ({
                        [year]: processByAttribute(
                            Object.values(
                                selectedDataSource.data[`${year}`] ?? {}
                            )
                                .flat(1)
                                .filter((element) =>
                                    ["Special Offer"].includes(element.Type)
                                ),
                            processBy,
                            cancellations
                        ),
                    }))
                )
            );
        }
        // console.log(monthsData);
        // console.log(dataByYear);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataSource, cancellations]);

    //                 _   _____        _
    //                | | |  __ \      | |
    //  ___  ___  _ __| |_| |  | | __ _| |_ __ _
    // / __|/ _ \| '__| __| |  | |/ _` | __/ _` |
    // \__ \ (_) | |  | |_| |__| | (_| | || (_| |
    // |___/\___/|_|   \__|_____/ \__,_|\__\__,_|
    // const handleRequestSort = (property) => {
    //     const isAsc = orderBy === property && order === "asc";
    //     setOrder(isAsc ? "desc" : "asc");
    //     setOrderBy(property);
    // };

    useEffect(() => {
        if (
            Object.entries(dataByYear).length > 0 &&
            Object.entries(monthsData[time.getUTCMonth()]).length > 0
        ) {
            let auxData = fullYear
                ? dataByYear[time.getUTCFullYear()]
                : monthsData[time.getUTCMonth()][time.getUTCFullYear()];

            let sortedKeys = [];
            Object.entries(auxData).forEach(([key, data]) => {
                sortedKeys.push([key, data[orderBy]]);
            });
            sortedKeys.sort((first, second) => {
                return second[1] - first[1];
            });

            let displaySortedKeys = SliceTop(
                order === "asc"
                    ? sortedKeys.map((element) => element[0])
                    : sortedKeys.map((element) => element[0]).reverse(),
                TOP[topNumber]
            );

            let auxDisplayData = {};
            displaySortedKeys.forEach((key) => {
                auxDisplayData[key] = auxData[key];

                if (fullYear) {
                    auxDisplayData[key]["prev_year"] = dataByYear[
                        time.getUTCFullYear() - 1
                    ]
                        ? dataByYear[time.getUTCFullYear() - 1][key] ?? 0
                        : 0;
                } else {
                    auxDisplayData[key]["prev_year"] = monthsData[
                        time.getUTCMonth()
                    ][time.getUTCFullYear() - 1]
                        ? monthsData[time.getUTCMonth()][
                              time.getUTCFullYear() - 1
                          ][key] ?? 0
                        : 0;
                }
            });

            setDisplayData(auxDisplayData);

            const auxTableData = Object.entries(auxDisplayData).map(
                ([key, value]) => ({
                    [processBy]: key,
                    ...value,
                })
            );

            setTableData(auxTableData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, ...monthsData, dataByYear, order, orderBy, topNumber]);

    return (
        <Stack direction={"column"} spacing={0.1}>
            <Container maxWidth={false} component={HoverPaper}>
                <br />
                <Stack
                    direction={{ md: "column", sm: "row", xs: "row" }}
                    justifyContent={isMobile ? "space-around" : "space-between"}
                    alignItems="flex-start"
                    // style={{ border: "1px solid red" }}
                >
                    {/* Title */}
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"center"}
                        // style={{ border: "1px solid orange" }}
                    >
                        <Stack
                            alignItems="center"
                            // style={{ border: "1px solid yellow" }}
                        >
                            <Typography fontWeight={"bold"} textAlign="center">
                                {`${TITLE}${
                                    cancellations ? " - Cancelled" : ""
                                }`}
                            </Typography>
                            <Typography
                                fontStyle={"italic"}
                                variant="subtitle2"
                            >
                                {`by ${
                                    sharedDataSource === "CheckOut"
                                        ? "Check-out"
                                        : sharedDataSource === "CheckIn"
                                        ? "Check-in"
                                        : "Reservation"
                                } date`}
                            </Typography>
                        </Stack>
                        <InfoIcon text={REPORT_DESC} />
                        <ErrorIcon show={connectionError} />
                    </Stack>

                    {isMobile ? (
                        <OptionsButtonDialog>
                            <SwitchVisualTable
                                collapse={true}
                                modes={[TABLE_MODE, TABULAR_MODE]}
                                mode={mode}
                                setMode={setMode}
                            />
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                            />
                            <FormControlLabel
                                label="Cancelled"
                                control={
                                    <Checkbox
                                        checked={cancellations}
                                        onChange={() => {
                                            setCancellations(!cancellations);
                                        }}
                                    />
                                }
                            />
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                            >
                                <Typography>{`Switch to top ${
                                    TOP[
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    ]
                                }`}</Typography>
                                <CircleValue
                                    value={`${TOP[topNumber]}`}
                                    tooltip={`Switch to top ${
                                        TOP[
                                            (topNumber + 1) %
                                                (mode === TABLE_MODE
                                                    ? TOP.length
                                                    : TOP.length - 1)
                                        ]
                                    }`}
                                    color={Colours.primary}
                                    onClick={() => {
                                        setTopNumber(
                                            (topNumber + 1) %
                                                (mode === TABLE_MODE
                                                    ? TOP.length
                                                    : TOP.length - 1)
                                        );
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                            </Stack>
                            {""}
                        </OptionsButtonDialog>
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            // style={{ border: "1px solid green   " }}
                        >
                            <CircleValue
                                value={`${TOP[topNumber]}`}
                                tooltip={`Switch to top ${
                                    TOP[(topNumber + 1) % TOP.length]
                                }`}
                                color={Colours.primary}
                                onClick={() => {
                                    setTopNumber((topNumber + 1) % TOP.length);
                                }}
                                style={{ cursor: "pointer" }}
                            />
                            <SwitchVisualTable
                                collapse={false}
                                modes={[TABLE_MODE, TABULAR_MODE]}
                                mode={mode}
                                setMode={setMode}
                            />
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                            />
                            <FormControlLabel
                                label="Cancelled"
                                control={
                                    <Checkbox
                                        checked={cancellations}
                                        onChange={() => {
                                            setCancellations(!cancellations);
                                        }}
                                    />
                                }
                            />
                        </Stack>
                    )}
                </Stack>

                <br />

                {(!Object.entries(displayData).length > 0 ||
                    !tableData.length > 0) && <ProgressBarSteps />}

                {/* elements (like map), noOfElements, noOfElementsMobile */}

                {mode === TABULAR_MODE && (
                    <CardCarousel
                        data={TopCards({
                            data: displayData,
                            header: HEADER,
                            hotelID: hotelID,
                            fullYear: fullYear,
                            time: time,
                            months: months,
                            cancellations: cancellations,
                            processBy: processBy,
                        })}
                    />
                )}

                {mode === TABLE_MODE && (
                    //  _____     _     _
                    // |_   _|_ _| |__ | | ___
                    //   | |/ _` | '_ \| |/ _ \
                    //   | | (_| | |_) | |  __/
                    //   |_|\__,_|_.__/|_|\___|
                    <TableContainer
                        style={{ overflow: "auto", maxHeight: "600px" }}
                    >
                        <WrapperPDFTable k={TITLE}>
                            <Table>
                                <TableHeadSort
                                    stickyHeads={tableOverflow ? 2 : 0}
                                    headers={[
                                        {
                                            id: `${processBy}`,
                                            label: `${processBy
                                                .split(/(?=[A-Z])/)
                                                .join(" ")}`,
                                            ignore: true,
                                        },
                                        ...HEADER,
                                    ]}
                                    order={order}
                                    orderBy={orderBy}
                                    setOrder={setOrder}
                                    setOrderBy={setOrderBy}
                                />
                                <TableBody>
                                    {tableData.length > 0 ? (
                                        tableData.map((row) => (
                                            <TableRow key={`${row[processBy]}`}>
                                                <TableBodyCell
                                                    style={
                                                        tableOverflow
                                                            ? {
                                                                  position:
                                                                      "sticky",
                                                                  left: 0,
                                                                  zIndex: 1,
                                                                  backgroundColor:
                                                                      Colours.plainWhite,
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    {`${row[processBy]}`}
                                                </TableBodyCell>

                                                {HEADER.map((headCell) => (
                                                    <TableBodyCell
                                                        key={headCell.id}
                                                    >
                                                        {fns(
                                                            hotelID,
                                                            row[headCell.id] ??
                                                                0,
                                                            headCell.format ??
                                                                {}
                                                        )}

                                                        {calcPercentage(
                                                            row[headCell.id] ??
                                                                0,
                                                            row["prev_year"][
                                                                headCell.id
                                                            ] ?? 0,
                                                            headCell.format ??
                                                                {},
                                                            cancellations
                                                        )}
                                                    </TableBodyCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : dataBookings.status === "loaded" ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={100}
                                                align="center"
                                            >
                                                <Box
                                                    component="span"
                                                    fontStyle="italic"
                                                >
                                                    No data
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        //   _                    _ _
                                        //  | |    ___   __ _  __| (_)_ __   __ _
                                        //  | |   / _ \ / _` |/ _` | | '_ \ / _` |
                                        //  | |__| (_) | (_| | (_| | | | | | (_| |
                                        //  |_____\___/ \__,_|\__,_|_|_| |_|\__, |
                                        //                                  |___/
                                        [
                                            ...Array(
                                                TOP[topNumber] === TOP_ALL
                                                    ? 25
                                                    : TOP[topNumber]
                                            ).keys(),
                                        ].map((e) => (
                                            <TableRow key={e}>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </WrapperPDFTable>
                    </TableContainer>
                )}

                {/* <br /> */}
            </Container>
        </Stack>
    );
};

export default WrapperTopReport;
