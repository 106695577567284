import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";
import {
    Autocomplete,
    Box,
    CircularProgress,
    Container,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TextField,
} from "@mui/material";

import RoundedButton from "../components/RoundedButton";
import TableHeaderCell from "../components/TableHeaderCell";
import CheckShow from "../components/shared/CheckShow";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";

import { ARO_ALL, DEFAULT_HOTEL, HOTELS_INFO, URLAPI } from "../configuration";
import { setHotelID } from "../actions/hotelID";

const EmailListScreen = () => {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const { auth } = useSelector((state) => state);
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);

    const [data, setData] = useState([]);

    const [newEmail, setNewEmail] = useState("");
    const [newAlias, setNewAlias] = useState("");
    const [newListName, setNewListName] = useState("");
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const [loading, setLoading] = useState(false);

    const Load = () => {
        setData([]);
        setLoading(true);
        console.log({ auth });
        Axios.get(
            `${URLAPI}/email/list/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
            }
        )
            .then((r) => {
                console.log(r.data.data);
                setData(r.data.data);
            })
            .finally(() => setLoading(false));
    };

    const handleDelete = (emailToDelete, listName, rowIndex) => {
        setSelectedRowIndex(rowIndex);
        Axios({
            method: "delete",
            url: `${URLAPI}/email/list/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            data: {
                email: emailToDelete,
                list_name: listName,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((r) => {})
            .finally(() => {
                Load();
                setSelectedRowIndex(null);
            });
    };

    const handleAddNew = () => {
        if (!newEmail || !newAlias || !newListName) {
            alert("Fields cannot be empty");
            return;
        } else {
            Axios({
                method: "post",
                url: `${URLAPI}/email/list/${
                    hotelID === "default" ? DEFAULT_HOTEL : hotelID
                }`,
                data: {
                    email: newEmail,
                    alias: newAlias,
                    list_name: newListName,
                    creator_id: auth.user.user_id,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
            })
                .then((r) => {
                    console.log({ r });
                    setNewAlias("");
                    setNewEmail("");
                    setNewListName("");
                })
                .finally(() => Load());
        }
    };

    useEffect(() => {
        Load();
    }, [hotelID]);

    return (
        <>
            <br />
            <CheckShow rolesThatCanSee={ARO_ALL}>
                <Container>
                    {(((user ?? {}).rolesHotels ?? []).length > 1 ||
                        ((user ?? {}).rolesHotels ?? []).includes(-1)) && (
                        <TextField
                            select
                            variant="standard"
                            value={hotelID}
                            onChange={(e) => {
                                dispatch(setHotelID(e.target.value));
                            }}
                            size="small"
                            fullWidth
                            sx={{ pl: 1, pr: 1 }}
                        >
                            {user.rolesHotels.includes(-1)
                                ? Object.entries(HOTELS_INFO).map(
                                      ([key, data]) => (
                                          <MenuItem key={key} value={key}>
                                              {data.id} - {data.name}
                                          </MenuItem>
                                      )
                                  )
                                : user.rolesHotels.map((id) =>
                                      HOTELS_INFO.hasOwnProperty(id) ? (
                                          <MenuItem key={id} value={id}>
                                              {id} - {HOTELS_INFO[id].name}
                                          </MenuItem>
                                      ) : (
                                          <></>
                                      )
                                  )}
                        </TextField>
                    )}
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell>Email</TableHeaderCell>
                                    <TableHeaderCell>Alias</TableHeaderCell>
                                    <TableHeaderCell>List Name</TableHeaderCell>
                                    <TableHeaderCell align="right"></TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading && (
                                    <TableRow>
                                        <TableCell colSpan={100} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {data.length === 0 && !loading && (
                                    <TableRow>
                                        <TableCell colSpan={100} align="center">
                                            <Box
                                                component="span"
                                                fontStyle="italic"
                                            >
                                                No data for this hotel
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {data.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        style={{
                                            backgroundColor:
                                                selectedRowIndex === index
                                                    ? Colours.trafficLightRed
                                                    : "transparent",
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.email}
                                        </TableCell>
                                        <TableCell>{row.alias}</TableCell>
                                        <TableCell>{row.list_name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() =>
                                                    handleDelete(
                                                        row.email,
                                                        row.list_name,
                                                        index
                                                    )
                                                }
                                            >
                                                <Icons.Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item sm={12} lg={3}>
                            <TextField
                                label="Email"
                                name="email"
                                size="small"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} lg={3}>
                            <TextField
                                label="Alias"
                                name="alias"
                                size="small"
                                value={newAlias}
                                onChange={(e) => setNewAlias(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} lg={4}>
                            <Autocomplete
                                freeSolo
                                options={["monthly_summary"]}
                                value={newListName}
                                onChange={(event, newValue) => {
                                    setNewListName(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setNewListName(newInputValue.toLowerCase());
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="List Name"
                                        name="listName"
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={12} lg={2}>
                            <RoundedButton onClick={handleAddNew}>
                                New
                            </RoundedButton>
                        </Grid>
                    </Grid>
                </Container>
            </CheckShow>
            <br />
        </>
    );
};
export default EmailListScreen;
