import React from "react";

import EChartsReact from "echarts-for-react";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";

import { tooltip } from "../../helpers/eCharts";
import Colours from "../../helpers/colours";

const YearPerformance = ({
    TITLE,
    dataTarget = [],
    dataLast = [],
    dataThis = [],
}) => {
    return (
        <>
            <WrapperPDFEChart k={TITLE}>
                <EChartsReact
                    option={{
                        legend: { left: "right" },
                        xAxis: {
                            type: "category",
                            data: [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ],
                        },
                        yAxis: {},
                        tooltip: tooltip,
                        series: [
                            {
                                type: "bar",
                                name: "Last Year",
                                data: dataLast,
                                itemStyle: {
                                    color: Colours.graphDisabled,
                                },
                            },
                            {
                                type: "bar",
                                name: "This year",
                                data: dataThis,
                                itemStyle: {
                                    color: Colours.graphSold,
                                },
                            },
                            {
                                type: "line",
                                name: "Prediction",
                                data: dataTarget,
                                itemStyle: {
                                    color: Colours.graphProjection,
                                },
                            },
                        ],
                    }}
                />
            </WrapperPDFEChart>
        </>
    );
};

export default YearPerformance;
