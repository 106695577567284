import React, { useState } from "react";

import {
    Container,
    Box,
    TableCell,
    TableRow,
    Stack,
    Table,
    TableBody,
    Typography,
} from "@mui/material";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

import CircleValue from "../components/CircleValue";

const statusColor = (st) => {
    switch (st) {
        case "Complete":
            return Colours.statusBackgroundCompleted;
        case "Pending review":
        case "Under review":
        case "Partial complete":
        case "In progress":
        default:
            return Colours.statusBackgroundPending;
    }
};

const PriorityIcon = (n) => {
    switch (n) {
        case 1:
            return "High";
        // return <Icons.Priority1 color={"error"} />;
        case 2:
            return "High";
        // return <Icons.Priority2 color="warning" />;
        case 3:
            return "High";
        // return <Icons.Priority3 color="info" />;
        case 4:
            return "Low";
        // return <Icons.Priority4 color="secondary" />;
        case 5:
            return "Low";
        // return <Icons.Priority5 color="primary" />;
        case 6:
            return "Low";
        // return <Icons.Priority6 color="primary" />;
        default:
            return <Icons.PriorityMissed />;
    }
};

const InsightAssistRowMobile = ({
    indicatorName,
    growthTrackName,
    weighting,
    currentScore,
    previousScore,
    priority,
    status,
    whatV = "This indicartor does this... Would be very nice if you could do something about it...",
    whyV = "Our AI and data shows you could increase your revenue by hefty 15%",
    howV = "Increase DM Budget & Talk to our Team",
}) => {
    const [selected, setSelected] = useState(false);
    return (
        <>
            <TableRow
                sx={{
                    "& .MuiTableCell-root": {
                        borderBottom: "unset",
                        borderTop: 2,
                        borderTopColor: Colours.lightText,
                    },
                }}
                onClick={() => setSelected(!selected)}
            >
                <TableCell align="left">
                    <Box component="span" fontWeight={"bold"}>
                        INDICATOR NAME
                    </Box>
                </TableCell>
                <TableCell
                    align="center"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "grid",
                    }}
                >
                    <Typography
                        noWrap
                        style={{
                            fontSize: "95%",
                        }}
                    >
                        {indicatorName ?? "?"}
                    </Typography>
                    <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <CircleValue
                            value={(growthTrackName ?? "?").slice(0, 1)}
                            color={
                                growthTrackName === "Attract"
                                    ? Colours.attract
                                    : growthTrackName === "Acquire"
                                    ? Colours.attract
                                    : growthTrackName === "Engage"
                                    ? Colours.engage
                                    : growthTrackName === "Convert"
                                    ? Colours.convert
                                    : growthTrackName === "Retain"
                                    ? Colours.retain
                                    : null
                            }
                            tooltip={growthTrackName ?? "?"}
                        />
                        <CircleValue
                            value={currentScore}
                            tooltip={`${currentScore}/10 - Score from 11/2022`}
                            color={
                                currentScore >= previousScore
                                    ? Colours.trafficLightGreen
                                    : Colours.trafficLightRed
                            }
                        />
                    </Stack>
                </TableCell>
            </TableRow>
            {selected && (
                <>
                    <TableRow
                        sx={
                            selected
                                ? {
                                      backgroundColor: statusColor(status),

                                      "& .MuiTableCell-root": {
                                          borderBottom: "unset",
                                      },
                                  }
                                : undefined
                        }
                    >
                        <TableCell colSpan={2} align={"center"}>
                            <Box
                                component="span"
                                fontWeight={"bold"}
                                sx={{
                                    wordBreak: "break-word",
                                }}
                            >
                                {indicatorName ?? "?"}
                            </Box>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            selected
                                ? {
                                      backgroundColor: statusColor(status),

                                      "& .MuiTableCell-root": {
                                          borderBottom: "unset",
                                      },
                                  }
                                : undefined
                        }
                    >
                        <TableCell align="left">
                            <Box component="span" fontWeight={"bold"}>
                                PRIORITY
                            </Box>
                        </TableCell>
                        <TableCell align="center">
                            <Box>{PriorityIcon(priority)}</Box>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            selected
                                ? {
                                      backgroundColor: statusColor(status),
                                      "& .MuiTableCell-root": {
                                          borderBottom: "unset",
                                      },
                                  }
                                : undefined
                        }
                    >
                        <TableCell align="left">
                            <Box component="span" fontWeight={"bold"}>
                                WEIGHTING
                            </Box>
                        </TableCell>
                        <TableCell align="center">
                            <Container>
                                <CircleValue
                                    value={weighting}
                                    color={"transparent"}
                                    textColor={Colours.transparentBubble}
                                    borderColor={Colours.transparentBubble}
                                />
                            </Container>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            selected
                                ? {
                                      backgroundColor: statusColor(status),
                                      "& .MuiTableCell-root": {
                                          borderBottom: "unset",
                                      },
                                  }
                                : undefined
                        }
                    >
                        <TableCell align="left">
                            <Box component="span" fontWeight={"bold"}>
                                OLD SCORE
                            </Box>
                        </TableCell>
                        <TableCell align="center">
                            <Container>
                                <CircleValue
                                    value={previousScore}
                                    color={"transparent"}
                                    textColor={Colours.transparentBubble}
                                    borderColor={Colours.transparentBubble}
                                />
                            </Container>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            selected
                                ? {
                                      backgroundColor: statusColor(status),
                                      "& .MuiTableCell-root": {
                                          borderBottom: "unset",
                                      },
                                  }
                                : undefined
                        }
                    >
                        <TableCell align="left">
                            <Box component="span" fontWeight={"bold"}>
                                STATUS
                            </Box>
                        </TableCell>

                        <TableCell align="center">
                            <Container>
                                {/* <Tooltip title={status ?? "Status missed"}> */}
                                <Box>
                                    {/* {StatusIcon(status)} */}
                                    {status ?? "??"}
                                </Box>
                                {/* </Tooltip> */}
                            </Container>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            selected
                                ? {
                                      backgroundColor: statusColor(status),
                                      "& .MuiTableCell-root": {
                                          borderBottom: "unset",
                                      },
                                  }
                                : undefined
                        }
                    >
                        <TableCell align="left">
                            <Box component="span" fontWeight={"bold"}>
                                WHAT
                            </Box>
                        </TableCell>
                        <TableCell sx={{ wordBreak: "break-word" }}>
                            {whatV}
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            selected
                                ? {
                                      backgroundColor: statusColor(status),
                                      "& .MuiTableCell-root": {
                                          borderBottom: "unset",
                                      },
                                  }
                                : undefined
                        }
                    >
                        <TableCell align="left">
                            <Box component="span" fontWeight={"bold"}>
                                WHY
                            </Box>
                        </TableCell>
                        <TableCell sx={{ wordBreak: "break-word" }}>
                            {whyV}
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            selected
                                ? {
                                      backgroundColor: statusColor(status),
                                      "& .MuiTableCell-root": {
                                          borderBottom: "unset",
                                      },
                                  }
                                : undefined
                        }
                    >
                        <TableCell align="left">
                            <Box component="span" fontWeight={"bold"}>
                                HOW
                            </Box>
                        </TableCell>
                        <TableCell sx={{ wordBreak: "break-word" }}>
                            {howV}
                        </TableCell>
                    </TableRow>
                </>
            )}
        </>
    );
};

export default InsightAssistRowMobile;
