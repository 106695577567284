import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "../reducers/authReducer";
import { authRolesReducer } from "../reducers/authRolesReducer";
import { preferencesReducer } from "../reducers/preferencesReducer";
import { indicatorReducer } from "../reducers/indicatorReducer";
import { hotelIDReducer } from "../reducers/hotelIDReducer";
import { dataBookingsReducer } from "../reducers/dataBookingsReducer";
import { dataMembershipReducer } from "../reducers/dataMembershipReducer";
import { dataVouchersDetailsReducer } from "../reducers/dataVouchersDetailsReducer";
import { dataSimpleReducer } from "../reducers/dataSimpleReducer";
import { dataSearchesReducer } from "../reducers/dataSearchesReducer";
import { dataCommissionReducer } from "../reducers/dataCommissionReducer";
import { settingsReducer } from "../reducers/settingsReducer";
import { PDFExportReducer } from "../reducers/PDFExportReducer";
import { NotificationsReducer } from "../reducers/NotificationsReducer";
import { globalReportFiltersReducer } from "../reducers/globalReportFiltersReducer";

const reducers = combineReducers({
    auth: authReducer,
    authRoles: authRolesReducer,
    preferences: preferencesReducer,
    indicator: indicatorReducer,
    hotelID: hotelIDReducer,
    dataBookings: dataBookingsReducer,
    dataMembership: dataMembershipReducer,
    dataVouchersDetails: dataVouchersDetailsReducer,
    dataSimple: dataSimpleReducer,
    dataSearches: dataSearchesReducer,
    dataCommission: dataCommissionReducer,
    settings: settingsReducer,
    PDFExport: PDFExportReducer,
    notifications: NotificationsReducer,
    globalReportFilters: globalReportFiltersReducer,
});

const composeEnhancers =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
);
