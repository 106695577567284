import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Paper,
    Grid,
    Skeleton,
    useMediaQuery,
    useTheme,
    Typography,
} from "@mui/material";

import TabSelector from "../TabSelector";
import TrendChart from "../TrendChart";

import { fns } from "../../helpers/common";
import { months } from "../../helpers/dates";

const MonthlyRoomsCardsReport = ({
    TITLE,
    data,
    dataYears,
    fullYear,
    time,
    cardMode = "Total",
}) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const { id: hotelID } = useSelector((state) => state.hotelID);

    const HEADER = [
        // bookingsTotal: "Total", bookings: "No Cancellations", bookingsCancelled: "Only Cancellations"
        {
            id: "bookings",
            title: "Bookings",
            expand: true,
            isInverted: cardMode === "Cancelled" ? true : false,
        },

        {
            id: "roomNights",
            title: "Room Nights",
            expand: true,
            isInverted: cardMode === "Cancelled" ? true : false,
        },
        {
            id: "revenue",
            title: "Revenue",
            format: { left: "currency" },
            expand: true,
            isInverted: cardMode === "Cancelled" ? true : false,
        },
        {
            id: "ADR",
            title: "ADR",
            format: { left: "currency" },
            desc: "Average Daily Rate",
            expand: true,
            isInverted: cardMode === "Cancelled" ? true : false,
        },
        {
            id: "ALoS",
            title: "ALoS",
            format: { maxDecimals: 2, right: "days" },
            desc: "Average Length of Stay",
            expand: true,
            isInverted: cardMode === "Cancelled" ? true : false,
        },
        {
            id: "ABW",
            title: "ABW",
            format: { right: "days" },
            desc: "Average Booking Window",
            expand: true,
            isInverted: cardMode === "Cancelled" ? true : false,
        },
    ];

    const [dataByYear, setDataByYear] = useState({});

    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});

    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];

    const [dataLoaded, setDataLoaded] = useState(false);

    const [selectedData, setSelectedData] = useState({});
    const [selectedDataPrev, setSelectedDataPrev] = useState({});

    const [clickedCard, setClickedCard] = useState("");
    // const getFullKey = () => {
    //     return `${clickedCard}${
    //         cardMode === "Total"
    //             ? cardMode
    //             : cardMode === "Only Cancellations"
    //             ? "Cancelled"
    //             : ""
    //     }`;
    // };
    const getFullKey = () => {
        return `${clickedCard}${cardMode === "Total" ? cardMode : "Cancelled"}`;
    };

    //                       ____        _
    //   ___  __ ___   _____|  _ \  __ _| |_ __ _
    //  / __|/ _` \ \ / / _ \ | | |/ _` | __/ _` |
    //  \__ \ (_| |\ V /  __/ |_| | (_| | || (_| |
    //  |___/\__,_| \_/ \___|____/ \__,_|\__\__,_|
    useEffect(() => {
        setDataLoaded(false);

        if (!data) {
            return;
        }

        const [
            January,
            February,
            March,
            April,
            May,
            June,
            July,
            August,
            September,
            October,
            November,
            December,
        ] = data;
        setDataByJanuary(January);
        setDataByFebruary(February);
        setDataByMarch(March);
        setDataByApril(April);
        setDataByMay(May);
        setDataByJune(June);
        setDataByJuly(July);
        setDataByAugust(August);
        setDataBySeptember(September);
        setDataByOctober(October);
        setDataByNovember(November);
        setDataByDecember(December);

        setDataLoaded(true);
    }, [data]);
    useEffect(() => {
        setDataLoaded(false);

        if (!dataYears) {
            return;
        }
        setDataByYear(dataYears);
        setDataLoaded(true);
    }, [dataYears]);

    //            _           _           _ ____        _
    //   ___  ___| | ___  ___| |_ ___  __| |  _ \  __ _| |_ __ _
    //  / __|/ _ \ |/ _ \/ __| __/ _ \/ _` | | | |/ _` | __/ _` |
    //  \__ \  __/ |  __/ (__| ||  __/ (_| | |_| | (_| | || (_| |
    //  |___/\___|_|\___|\___|\__\___|\__,_|____/ \__,_|\__\__,_|
    useEffect(() => {
        if (!time) {
            setSelectedData({});
            setSelectedDataPrev({});
            return;
        }

        let auxSelectedData = {};
        let auxSelectedDataPrev = {};
        if (fullYear) {
            if (
                Object.keys(dataByYear).length > 0 &&
                dataByYear[`${time.getUTCFullYear()}`] &&
                dataByYear[`${time.getUTCFullYear() - 1}`]
            ) {
                auxSelectedData = dataByYear[`${time.getUTCFullYear()}`];
                auxSelectedDataPrev =
                    dataByYear[`${time.getUTCFullYear() - 1}`] ?? {};
            }
        } else if (
            monthsData[time.getUTCMonth()] &&
            monthsData[time.getUTCMonth()][`${time.getUTCFullYear()}`] &&
            monthsData[time.getUTCMonth()][`${time.getUTCFullYear() - 1}`]
        ) {
            auxSelectedData =
                monthsData[time.getUTCMonth()][`${time.getUTCFullYear()}`];

            auxSelectedDataPrev =
                monthsData[time.getUTCMonth()][`${time.getUTCFullYear() - 1}`];
        }
        setSelectedData(auxSelectedData);
        setSelectedDataPrev(auxSelectedDataPrev);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, cardMode, dataByYear, ...monthsData]);

    useEffect(() => {
        // Now when any one is loaded, it counts as loaded, so if there is some missing data, it still shows
        if (!dataLoaded) {
            let aux =
                monthsData.some((month) => Object.keys(month).length > 0) ||
                Object.keys(dataByYear).length > 0;
            setDataLoaded(aux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData, clickedCard, cardMode, dataByYear, ...monthsData]);

    const formatLabelValue = (value) => {
        let format = {
            maxDecimals:
                (HEADER.find((head) => head.id === clickedCard).format ?? {})
                    .maxDecimals ?? 0,
        };
        return fns(hotelID, value, format);
    };

    return (
        <Grid container alignItems="stretch" spacing={0} justifyContent="left">
            <TabSelector
                hotelID={hotelID}
                data={HEADER.map((head) => {
                    let auxHead = Object.assign({}, head);
                    const fullKey = `${head.id}${
                        cardMode === "Total" ? "Total" : "Cancelled" // Change to `cardMode === "Total" ? "" : "Cancelled"` to exclude cancellations in Total
                    }`;
                    auxHead.value = (selectedData ?? {})[fullKey] ?? 0;
                    // console.log({ v: auxHead.value, selectedData, fullKey });
                    if (Object.keys(selectedDataPrev).length > 0) {
                        auxHead.compare = true;
                        auxHead.old = (selectedDataPrev ?? {})[fullKey] ?? 0;
                    } else {
                        auxHead.compare = false;
                        auxHead.noArrow = true;
                    }
                    return auxHead;
                })}
                cardSelected={clickedCard}
                setCardSelected={setClickedCard}
            />

            {
                //   ____                 _
                //  / ___|_ __ __ _ _ __ | |__
                // | |  _| '__/ _` | '_ \| '_ \
                // | |_| | | | (_| | |_) | | | |
                //  \____|_|  \__,_| .__/|_| |_|
                //                 |_|
            }
            {clickedCard && !isMobile ? (
                dataLoaded ? (
                    fullYear &&
                    Object.keys(dataByYear[time.getUTCFullYear()] ?? {})
                        .length > 0 ? (
                        <TrendChart
                            TITLE={
                                TITLE +
                                    " - " +
                                    HEADER.find(
                                        (head) => head.id === clickedCard
                                    ).title ?? ""
                            }
                            key={"yearChart"}
                            data={Object.keys(dataByYear).reduce(
                                (accYear, year) => {
                                    accYear[year] = Object.keys(
                                        monthsData
                                    ).reduce((accMonth, month) => {
                                        accMonth[months[month]] =
                                            (monthsData[month][year] ?? {})[
                                                getFullKey()
                                            ] ?? 0;
                                        return accMonth;
                                    }, {});
                                    return accYear;
                                },
                                {}
                            )}
                            selected={Object.assign(
                                {},
                                ...Object.keys(dataByYear).map((key) => ({
                                    [key]: key === time.getUTCFullYear(),
                                }))
                            )}
                            markPoints={{
                                min: {
                                    show: true,
                                    formatter: formatLabelValue,
                                },
                                max: {
                                    show: true,
                                    formatter: formatLabelValue,
                                },
                            }}
                            markLine={{
                                show: true,
                                formatter: formatLabelValue,
                            }}
                        />
                    ) : !fullYear &&
                      Object.keys(
                          (monthsData[time.getUTCMonth()] ?? {})[
                              time.getUTCFullYear()
                          ] ?? {}
                      ).length > 0 ? (
                        <TrendChart
                            TITLE={
                                TITLE +
                                    " - " +
                                    HEADER.find(
                                        (head) => head.id === clickedCard
                                    ).title ?? ""
                            }
                            key={"monthChart"}
                            data={{
                                [`${
                                    months[time.getUTCMonth()]
                                } ${time.getUTCFullYear()}`]: Object.keys(
                                    monthsData[time.getUTCMonth()][
                                        time.getUTCFullYear()
                                    ].Details
                                ).reduce((acc, day) => {
                                    acc[day] =
                                        monthsData[time.getUTCMonth()][
                                            time.getUTCFullYear()
                                        ].Details[day][getFullKey()];
                                    return acc;
                                }, {}),
                            }}
                            markPoints={{
                                min: {
                                    show: true,
                                    formatter: formatLabelValue,
                                },
                                max: {
                                    show: true,
                                    formatter: formatLabelValue,
                                },
                            }}
                            markLine={{
                                show: true,
                                formatter: formatLabelValue,
                            }}
                        />
                    ) : (
                        <Box component={Paper} height={"100%"} width={"100%"}>
                            <Skeleton
                                variant="rounded"
                                height={300}
                                maxWidth={600}
                                sx={{ margin: 2 }}
                            />
                        </Box>
                    )
                ) : (
                    <Box component={Paper} height={"100%"} width={"100%"}>
                        <Skeleton
                            variant="rounded"
                            height={300}
                            maxWidth={600}
                            sx={{ margin: 2 }}
                        />
                    </Box>
                )
            ) : (
                ""
            )}
        </Grid>
    );
};

export default MonthlyRoomsCardsReport;
