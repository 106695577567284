import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Container,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import CircleValue from "../CircleValue";
import Colours from "../../helpers/colours";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import AllSearchesLeadTimeRow from "./AllSearchesLeadTimeRow";
import TableHeaderCell from "../TableHeaderCell";
import OptionsButtonDialog from "../OptionsButtonDialog";

import { SliceTop, TOP_ALL } from "../../helpers/reports";

const AllSearchesLeadTime = ({ hotelID, dataType, title, report_desc }) => {
    const dispatch = useDispatch();
    const { auth } = useSelector((state) => state);

    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const TOP = [10, TOP_ALL, 5];
    const TABLE_MODE = "TABLE_MODE";
    const VISUAL_MODE = "VISUAL_MODE";
    const SUBTITLE = "All Searches";
    const [connectionError, setConnectionError] = useState(false);

    const [rawData, setRawData] = useState([]);

    // Unable to render using selectedData
    const { dataSearches } = useSelector((state) => state);
    const [selectedData, setSelectedData] = useState([]);

    const [mode, setMode] = useState(TABLE_MODE);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("lostRevenue");

    const [topNumber, setTopNumber] = useState(0);
    const [timeStart, setTimeStart] = useState(new Date());

    const [header, setHeader] = useState([]);

    useEffect(() => {
        switch (dataType) {
            case "Searches":
                setHeader([
                    {
                        id: "searchDate",
                        label: "Search Date",
                    },
                    {
                        id: "totalSearches",
                        label: "Total Searches",
                    },
                    {
                        id: "noAvSearches",
                        label: "No Av. Searches",
                        tooltip:
                            "No Av. Searches(Searches on no availability dates)",
                    },
                    {
                        id: "totalSessions",
                        label: "Total Sessions",
                    },
                    {
                        id: "noAvSessions",
                        label: "No Av. Sessions",
                        tooltip:
                            "No Availability Sessions(Sessions without an available date)",
                    },
                ]);
                break;
            case "Revenue":
                setHeader([
                    {
                        id: "searchDate",
                        label: "Search Date",
                    },
                    {
                        id: "lostRevenue",
                        label: "Lost Revenue",
                    },
                    {
                        id: "revenue",
                        label: "Revenue",
                    },
                ]);
                break;
            case "Bookings":
                setHeader([
                    {
                        id: "searchDate",
                        label: "Search Date",
                    },
                    {
                        id: "bookings",
                        label: "Bookings",
                    },
                    {
                        id: "roomNights",
                        label: "Room Nights",
                    },
                    {
                        id: "bedNights",
                        label: "Bed Nights",
                    },
                    {
                        id: "averageLoS",
                        label: "Average LoS",
                    },
                    {
                        id: "averageLeadTime",
                        label: "Average Lead Time",
                    },
                ]);
                break;
            default:
                break;
        }

        // console.log(header);
    }, [dataType]);

    //   _                        ___                    _
    //  / |   __ _  __ _  ___    ( _ )    ___  ___  _ __| |_
    //  | |  / _` |/ _` |/ _ \   / _ \/\ / __|/ _ \| '__| __|
    //  | | | (_| | (_| | (_) | | (_>  < \__ \ (_) | |  | |_
    //  |_|  \__,_|\__, |\___/   \___/\/ |___/\___/|_|   \__|
    //             |___/
    useEffect(() => {
        // Early return if rawData is empty
        if (rawData.length === 0) return;

        // setSelectedData([]);

        // Handling non-visual mode
        if (mode !== VISUAL_MODE) {
            let auxSelected = structuredClone(rawData);

            auxSelected.sort((a, b) => {
                if (orderBy === "searchDate") {
                    return order === "asc"
                        ? new Date(b[orderBy]) - new Date(a[orderBy])
                        : new Date(a[orderBy]) - new Date(b[orderBy]);
                } else {
                    return order === "asc"
                        ? b[orderBy] - a[orderBy]
                        : a[orderBy] - b[orderBy];
                }
            });

            setSelectedData(SliceTop(auxSelected, TOP[topNumber]));
            return;
        }

        // Handling visual mode
        setSelectedData(rawData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawData, order, orderBy, topNumber, mode]);

    //   _ __  _ __ ___   ___ ___  ___ ___
    //  | '_ \| '__/ _ \ / __/ _ \/ __/ __|
    //  | |_) | | | (_) | (_|  __/\__ \__ \
    //  | .__/|_|  \___/ \___\___||___/___/
    //  |_|
    useEffect(() => {
        if (dataSearches.status === "error") {
            setConnectionError(true);
            return;
        }

        if (!(dataSearches.data ?? {}).hasOwnProperty("AllSearches")) {
            return;
        }

        setConnectionError(false);

        const data = dataSearches.data.AllSearches;

        let auxRawData = [];
        let auxTimeStart = new Date();
        data.forEach((element) => {
            // console.log(element);

            auxRawData.push({
                searchDate: element.searchDate,
                totalSearches: element.totalSearches,
                noAvSearches: element.noAvSearches,
                totalSessions: element.totalSessions,
                noAvSessions: element.noAvSessions,
                lostRevenue: element.lostRevenue,
                revenue: element.revenue,
                bookings: element.bookings,
                roomNights: element.roomNights,
                bedNights: element.bedNights,
                averageLoS: element.averageLoS,
                averageLeadTime: element.averageLeadTime,
            });
            auxTimeStart =
                new Date(element.searchDate) < auxTimeStart
                    ? new Date(element.searchDate)
                    : auxTimeStart;
        });
        auxTimeStart = new Date(
            auxTimeStart.getUTCFullYear(),
            auxTimeStart.getUTCMonth(),
            1
        );
        setTimeStart(auxTimeStart);
        setRawData(auxRawData);
    }, [dataSearches]);

    if (connectionError) {
        return (
            <Typography variant="h6" color="error">
                Failed to fetch data. Please check your network connection.
            </Typography>
        );
    }

    return (
        <Container component={HoverPaper} maxWidth={false}>
            <br />
            <Stack
                direction={{ md: "column", sm: "row", xs: "row" }}
                justifyContent={isMobile ? "space-around" : "space-between"}
                alignItems="flex-start"
                // style={{ border: "1px solid red" }}
            >
                {/* Title */}
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={"center"}
                    // style={{ border: "1px solid orange" }}
                >
                    <Stack
                    // alignItems="center"
                    // style={{ border: "1px solid yellow" }}
                    >
                        <Typography fontStyle={"italic"} fontSize={"80%"}>
                            {SUBTITLE}
                        </Typography>
                        <Typography fontWeight={"bold"}>{title}</Typography>
                    </Stack>
                    <InfoIcon text={report_desc} />
                    <ErrorIcon show={connectionError} />
                </Stack>

                {isMobile ? (
                    <OptionsButtonDialog>
                        {/* <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        /> */}
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography>{`Switch to top ${
                                TOP[
                                    (topNumber + 1) %
                                        (mode === TABLE_MODE
                                            ? TOP.length
                                            : TOP.length - 1)
                                ]
                            }`}</Typography>
                            <CircleValue
                                value={`${TOP[topNumber]}`}
                                tooltip={`Switch to top ${
                                    TOP[
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    ]
                                }`}
                                color={Colours.primary}
                                onClick={() => {
                                    setTopNumber(
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    );
                                }}
                                style={{ cursor: "pointer" }}
                            />
                        </Stack>
                        {""}
                    </OptionsButtonDialog>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        // style={{ border: "1px solid green   " }}
                    >
                        <CircleValue
                            value={`${TOP[topNumber]}`}
                            tooltip={`Switch to top ${
                                TOP[(topNumber + 1) % TOP.length]
                            }`}
                            color={Colours.primary}
                            onClick={() => {
                                setTopNumber((topNumber + 1) % TOP.length);
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        {/* <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        /> */}
                    </Stack>
                )}
            </Stack>

            <br />

            {selectedData.length === 0 && (
                <ProgressBarSteps
                    steps={[
                        5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70,
                        75, 80, 85, 87, 90, 93, 96, 100,
                    ]}
                />
            )}

            {mode === TABLE_MODE && (
                //   _____  _    ____  _     _____
                //  |_   _|/ \  | __ )| |   | ____|
                //    | | / _ \ |  _ \| |   |  _|
                //    | |/ ___ \| |_) | |___| |___
                //    |_/_/   \_\____/|_____|_____|
                <>
                    <TableContainer
                        style={{ overflowX: "auto", maxHeight: "600px" }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {header.map((headCell, index) => (
                                        <TableHeaderCell
                                            key={headCell.id}
                                            sortDirection={
                                                orderBy === headCell.id
                                                    ? order
                                                    : false
                                            }
                                            style={
                                                isMobile && index === 0
                                                    ? {
                                                          position: "sticky",
                                                          left: 0,
                                                          top: 0,
                                                          zIndex: 4,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                                    : {
                                                          position: "sticky",
                                                          top: 0,
                                                          zIndex: 3,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                            }
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={
                                                    orderBy === headCell.id
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() => {
                                                    const isAsc =
                                                        orderBy ===
                                                            headCell.id &&
                                                        order === "asc";
                                                    setOrder(
                                                        isAsc ? "desc" : "asc"
                                                    );
                                                    setOrderBy(headCell.id);
                                                }}
                                            >
                                                {headCell.tooltip ? (
                                                    <Tooltip
                                                        title={headCell.tooltip}
                                                    >
                                                        <div>
                                                            {headCell.label}
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    headCell.label
                                                )}
                                            </TableSortLabel>
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <>
                                    {selectedData.length > 0 ? (
                                        selectedData.map((rowData, i) => (
                                            <AllSearchesLeadTimeRow
                                                key={i}
                                                hotelID={dataSearches.hotelID}
                                                rowData={rowData}
                                                parentHeader={header}
                                                isMobile={isMobile}
                                                auth={auth}
                                                dataType={dataType}
                                            />
                                        ))
                                    ) : selectedData.length == 0 &&
                                      dataSearches.status === "loaded" ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={100}
                                                align="center"
                                            >
                                                <Box
                                                    component="span"
                                                    fontStyle="italic"
                                                >
                                                    No data
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        [
                                            ...Array(
                                                TOP[topNumber] === TOP_ALL
                                                    ? 25
                                                    : TOP[topNumber]
                                            ).keys(),
                                        ].map((e) => (
                                            <TableRow key={e}>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                {header.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                    >
                                                        <Skeleton />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    )}
                                </>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Container>
    );
};

export default AllSearchesLeadTime;
