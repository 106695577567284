import React from "react";
import { useState, useEffect } from "react";

import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Stack,
    Typography,
    createTheme,
    useMediaQuery,
} from "@mui/material";

import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 400,
            sm: 600,
        },
    },
});

const SummaryRing = ({ score, size = 150 }) => {
    let downSM = useMediaQuery(theme.breakpoints.down("sm"));
    let downXS = useMediaQuery(theme.breakpoints.down("xs"));

    const [percentageView, setPercentageView] = useState(100);

    useEffect(() => {
        let currentView = downSM && !downXS ? 80 : downXS ? 60 : 100;

        setPercentageView(currentView);
    }, [downSM, downXS]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <CircularProgress
                variant="determinate"
                value={100}
                size={(size * percentageView) / 100}
                thickness={1}
                sx={{ margin: 1, color: Colours.tableRowBackground }}
            />
            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress
                    variant="determinate"
                    value={score}
                    size={((size * percentageView) / 100) * 0.9} // 90%
                    thickness={3}
                />
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        fontWeight={"bold"}
                        fontSize={`${
                            (1.5 * ((size * percentageView) / 100)) / 100
                        }em`}
                        color={Colours.primary}
                    >{`${score}%`}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

const IndicatorsSummaryRing = ({
    score,
    size = 150,
    title = "",
    subtitle = "",
    basePath = "",
}) => {
    const navigate = useNavigate();
    const SeeAllHotelsButton = () => (
        <Button
            sx={{
                color: Colours.primary,
                boxShadow: "none",
                backgroundColor: "inherit",
                textAlign: "center",
                fontWeight: "bold",
                textTransform: "none",
                ":hover": {
                    color: "white",
                    backgroundColor: Colours.primary,
                },
            }}
            onClick={(event) => {
                navigate(basePath + "search");
            }}
        >
            <Icons.List />
            {"See all hotels"}
        </Button>
    );
    return (
        <>
            {title ? (
                <Paper sx={{ pt: 2, pb: 2 }}>
                    <Stack // TITLE-RING AND BUTTON
                        direction={"column"}
                    >
                        <Stack // TITLE AND RING
                            direction={{
                                md: "row",
                                sm: "column",
                                xs: "column",
                            }}
                            display={"flex"}
                            justifyContent={
                                !subtitle ? "space-around" : "center"
                            }
                        >
                            <Stack // TITLE AND SUBTITLE
                                justifyContent={"center"}
                                flexWrap={"wrap"}
                            >
                                <Typography>
                                    <Box
                                        color={Colours.textColour}
                                        textAlign={"center"}
                                        fontWeight={"bold"}
                                        fontSize={{ xs: 16, sm: 20 }}
                                        letterSpacing={2}
                                        display={"flex"}
                                        sx={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {title.toUpperCase()}
                                    </Box>
                                </Typography>
                                <Typography>
                                    <Box
                                        color={Colours.textColour}
                                        // fontWeight={"bold"}
                                        fontSize={{ xs: 14, sm: 16 }}
                                        letterSpacing={2}
                                        display={"flex"}
                                        sx={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {subtitle}
                                    </Box>
                                </Typography>
                            </Stack>
                            <Stack alignItems="center" justifyContent="center">
                                <SummaryRing score={score} size={size} />
                            </Stack>
                        </Stack>
                        {basePath && (
                            <Stack // BUTTON
                                alignItems="center"
                                justifyContent="center"
                            >
                                <SeeAllHotelsButton />
                            </Stack>
                        )}
                    </Stack>
                </Paper>
            ) : (
                <Stack direction={"column"} alignItems={"center"}>
                    <SummaryRing score={score} size={size} />
                    {basePath && <SeeAllHotelsButton />}
                </Stack>
            )}
        </>
    );
};

export default IndicatorsSummaryRing;
