import React from "react";
import { ButtonBase, Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Icons from "../helpers/icons";

const GoBack = ({
    title = "Go back",
    to = "/report",
    ICON = Icons.ArrowLeft,
}) => {
    return (
        <Container maxWidth="lg" disableGutters>
            <Box
                p={1}
                textAlign={"right"}
                fontSize="0.8em"
                sx={{ textDecoration: "underline" }}
            >
                <ButtonBase component={Link} to={to}>
                    <ICON fontSize="1em" /> {title}
                </ButtonBase>
            </Box>
        </Container>
    );
};

export default GoBack;
