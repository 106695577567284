import React, { useState, useEffect } from "react";

import { Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import Colours from "../../helpers/colours";
import CircleValue from "../CircleValue";
import { valuePost, TYPES } from "../../helpers/indicators";

export const colourTypeRange = (value, maxValue, minValue) =>
    value >= maxValue
        ? Colours.trafficLightGreen
        : value < minValue
        ? Colours.trafficLightRed
        : Colours.trafficLightYellow;

export const processTypeRange = (data) => {
    let min = 0;
    let max = 0;
    let value = 0;
    let oldValue = 0;
    let status = "";
    let priority = "";
    let status_id = null;

    let ts_value = null;
    let last_value = 0;
    let ts_min = null;
    let ts_max = null;
    data.values
        .sort((a, b) => a.ts - b.ts)
        .forEach((element) => {
            if (element.subtype === "MIN") {
                if (!ts_min || new Date(element.ts) > new Date(ts_min)) {
                    min = Number(element.value_);
                    ts_min = element.ts;
                }
            }
            if (element.subtype === "MAX") {
                if (!ts_max || new Date(element.ts) > new Date(ts_max)) {
                    max = Number(element.value_);
                    ts_max = element.ts;
                }
            }
            if (element.subtype === "VALUE") {
                if (!ts_value || new Date(element.ts) > new Date(ts_value)) {
                    oldValue = last_value; //TODO: check this
                    last_value = Number(element.value_);
                    value = last_value;
                    ts_value = element.ts;
                }
            }
        });

    let ts_sp = null;
    data.status.forEach((element) => {
        if (!ts_sp || new Date(element.ts) > new Date(ts_sp)) {
            status_id = element.status_id;
            status = element.status;
            priority = element.priority;
            ts_sp = element.ts;
        }
    });

    return {
        type: "RANGE",
        min,
        max,
        value,
        oldValue,
        status_id,
        status,
        priority,
        colour:
            value >= max
                ? Colours.trafficLightGreen
                : value >= min
                ? Colours.trafficLightYellow
                : Colours.trafficLightRed,
    };
};

// edit = false: readable TextFields / true: editable TextFields
const IndicatorsTypeRange = ({
    hotelID = null,
    new_ = false,
    edit = false,
    data = {},
    rawData = {},
    type,
    utilitySetter = () => {},
    errorForm,
}) => {
    const [initialValue, setInitialValue] = useState(0);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        if (
            data.hasOwnProperty("value") &&
            data.hasOwnProperty("min") &&
            data.hasOwnProperty("max")
        ) {
            setInitialValue(data.value);
            setMinValue(data.min);
            setMaxValue(data.max);
        }
    }, [data, type]);

    const modifiedType = () =>
        initialValue !== data.value ||
        minValue !== data.min ||
        maxValue !== data.max;

    const isValid = () => {
        return (
            (new_ ? true : initialValue !== "") &&
            minValue < maxValue &&
            minValue !== "" &&
            maxValue !== ""
        );
    };

    const post = async (auth, indicatorID) => {
        if (new_ || modifiedType()) {
            try {
                if (!new_ && initialValue !== data.value) {
                    const responseInitialValue = await valuePost(
                        auth,
                        indicatorID,
                        "range",
                        "value",
                        initialValue,
                        hotelID
                    );
                    if (responseInitialValue.status !== 201) {
                        let error = new Error(
                            "Unexpected response posting INITIAL VALUE."
                        );
                        error.response = responseInitialValue;
                        error.value = initialValue;
                        throw error;
                    }
                }
                if (new_ || (!new_ && minValue !== data.min)) {
                    const responseMin = await valuePost(
                        auth,
                        indicatorID,
                        "range",
                        "min",
                        minValue,
                        hotelID
                    );
                    if (responseMin.status !== 201) {
                        let error = new Error(
                            "Unexpected response posting MIN."
                        );
                        error.response = responseMin;
                        error.value = minValue;
                        throw error;
                    }
                }
                if (new_ || (!new_ && maxValue !== data.max)) {
                    const responseMax = await valuePost(
                        auth,
                        indicatorID,
                        "range",
                        "max",
                        maxValue,
                        hotelID
                    );
                    if (responseMax.status !== 201) {
                        let error = new Error(
                            "Unexpected response posting MAX."
                        );
                        error.response = responseMax;
                        error.value = maxValue;
                        throw error;
                    }
                }
            } catch (error) {
                let errorStr = "Failed posting Range values.";
                console.error(errorStr, { error });
                let errorPost = new Error(errorStr);
                errorPost.previousError = error;
                throw errorPost;
            }
        }
    };

    useEffect(() => {
        const utility = {
            post: post, // TODO: control fail posts in both (return true / false won't work, we have to return a promise so we can wait for its result)
            isValid: isValid,
        };
        if (!new_) utility.modifiedType = modifiedType;
        utilitySetter(utility);
    }, [utilitySetter, minValue, maxValue, initialValue]);

    return edit ? (
        <>
            {!new_ && (
                <TextField
                    fullWidth
                    label="Value"
                    value={initialValue}
                    onChange={(e) => {
                        if (e.target.value !== "")
                            setInitialValue(Number(e.target.value));
                        else setInitialValue("");
                    }}
                    type="number"
                    required
                    error={errorForm ? initialValue === "" : false}
                />
            )}
            <TextField
                fullWidth
                label={new_ ? "Default Min Value" : "Min Value"}
                value={minValue}
                required
                error={
                    errorForm
                        ? minValue === ""
                            ? true
                            : minValue >= maxValue
                            ? true
                            : false
                        : false
                }
                helperText={
                    errorForm
                        ? maxValue === ""
                            ? ""
                            : minValue >= maxValue
                            ? "Min value cannot be greater or equal than a Max value"
                            : false
                        : false
                }
                onChange={(e) => {
                    if (e.target.value !== "")
                        setMinValue(Number(e.target.value));
                    else setMinValue("");
                }}
                type="number"
            />
            <TextField
                fullWidth
                label={new_ ? "Default Max Value" : "Max Value"}
                required
                value={maxValue}
                error={
                    errorForm
                        ? maxValue === ""
                            ? true
                            : minValue >= maxValue
                            ? true
                            : false
                        : false
                }
                onChange={(e) => {
                    if (e.target.value !== "")
                        setMaxValue(Number(e.target.value));
                    else setMaxValue("");
                }}
                type="number"
            />
        </>
    ) : (
        <Stack direction={"column"} spacing={2}>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography
                    fontWeight="bold"
                    display={"flex"}
                    alignItems={"center"}
                >
                    Value{" "}
                </Typography>
                <CircleValue
                    value={initialValue}
                    color={colourTypeRange(initialValue, maxValue, minValue)}
                    size="big"
                />
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography fontWeight="bold">Min/Max</Typography>
                <Typography>{`${minValue} / ${maxValue}`}</Typography>
            </Stack>
        </Stack>
    );
};

export default IndicatorsTypeRange;
