import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "notistack";

import { loadOne } from "../../actions/PDFExport";

import { AROLOGO } from "../../configuration";

const WrapperPDFEChart = ({ children, k }) => {
    const [echartInstance, setEchartInstance] = useState(null);
    const echartContainerRef = useRef(null);

    const dispatch = useDispatch();
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const toggleButton = useSelector((state) => state.PDFExport.toggleButton);

    const { enqueueSnackbar } = useSnackbar();
    const onChartReady = (chartInstance) => {
        setEchartInstance(chartInstance);
    };

    const graph2Image = () => {
        if (echartInstance) {
            const base64URL = echartInstance.getDataURL({
                type: "png",
                pixelRatio: 2,
                backgroundColor: "#fff",
            });

            function base64ToBlob(base64, mime) {
                const byteString = atob(base64.split(",")[1]);
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                return new Blob([ab], { type: mime });
            }

            const blob = base64ToBlob(base64URL, "image/png");
            return URL.createObjectURL(blob);
        }
    };

    const downloadImage = () => {
        const url = graph2Image();
        if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.download = k + ".png";
            link.click();
        }
    };

    const handleSaveForPrint = () => {
        const urlImageChart = graph2Image();
        if (urlImageChart) {
            const html_img = `
                <div class=\'watermark\'>
                    <img src='${AROLOGO}' width=\'90%\' />
                </div>
                <img src='${urlImageChart}' width=\'100%\'/>`;

            dispatch(loadOne(html_img, k, hotelID));
            enqueueSnackbar(`Added report to printing list: ${k}`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
                variant: "success",
                autoHideDuration: 2000,
            });
        }
    };

    const addTitleForPrint = () => {
        if (echartContainerRef.current) {
            const printContents = echartContainerRef.current;
            const titleElement = document.createElement("h2");
            titleElement.innerText = k;
            titleElement.id = "print-title";
            printContents.prepend(titleElement);
        }
    };

    const removeTitleAfterPrint = () => {
        const titleElement = document.getElementById("print-title");
        if (titleElement) {
            titleElement.remove();
        }
    };

    const printThis = useReactToPrint({
        content: () => echartContainerRef.current,
        documentTitle: "PDF-Generated",
        onBeforeGetContent: addTitleForPrint,
        onAfterPrint: removeTitleAfterPrint,
    });

    const childWithProps = React.cloneElement(children, {
        onChartReady,
        ref: echartContainerRef,
    });

    return (
        <div style={{ width: "-webkit-fill-available" }}>
            {toggleButton && (
                <>
                    <button onClick={handleSaveForPrint}>
                        Add to printing list
                    </button>
                    <button onClick={downloadImage}>Download image</button>
                    <button onClick={printThis}>Print this</button>
                </>
            )}
            <div ref={echartContainerRef}>
                <div className="watermark">
                    <img src={AROLOGO} width={"90%"} />
                </div>
                {childWithProps}
            </div>
        </div>
    );
};

export default WrapperPDFEChart;

/** USAGE:
    <WrapperPDFEChart k={TITLE}>
        <EChartsReact
            ...
        />
    </WrapperPDFEChart>
 */
