import React, { useEffect, useRef, useState } from "react";

import {
    Box,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { useSwipeable } from "react-swipeable";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";
import {
    STATUS_COMPLETE,
    STATUS_ERROR,
    STATUS_WARNING,
} from "../helpers/status";

import "../styles/animations.css";

const Yesterday = new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDay() - 1
);

const formatTime = (time) => {
    const now = new Date();
    const timeDate = new Date(time);
    const diff = now - timeDate;
    const diffHours = Math.floor(diff / (1000 * 60 * 60));
    const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (diffHours < 1) {
        return "Now";
    } else if (diffHours < 24) {
        return `${diffHours}h`;
    } else if (timeDate.getUTCDate() === Yesterday.getUTCDate()) {
        return timeDate.toISOString().slice(11, 16);
    } else {
        return `${diffDays}d`;
    }
};

const IconToShow = ({ type }) => {
    return type === STATUS_COMPLETE ? (
        <Icons.StatusComplete sx={{ color: Colours.trafficLightGreen }} />
    ) : type === STATUS_WARNING ? (
        <Icons.StatusWarning sx={{ color: Colours.trafficLightYellow }} />
    ) : type === STATUS_ERROR ? (
        <Icons.StatusCancel sx={{ color: Colours.trafficLightRed }} />
    ) : (
        <Icons.Circle sx={{}} />
    );
};

const NotificationCard = ({
    id,
    type,
    title,
    text,
    time = new Date(),
    onDismiss,
    onClick,
    allowSwipe = true,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [hasOverflow, setHasOverflow] = useState(false);
    const textRef = useRef(null);

    const checkOverflow = () => {
        if (textRef.current) {
            const element = textRef.current;
            const hasOverflow =
                element.scrollHeight > element.clientHeight ||
                element.scrollWidth > element.clientWidth;
            setHasOverflow(hasOverflow);
            setIsExpanded(false); //TODO: this is because if you expand it and resize it, the button will not appear anymore, think better solution
        }
    };

    useEffect(() => {
        // Initial check
        checkOverflow();

        // Function for resize
        const handleResize = () => {
            checkOverflow();
        };

        // Add to the event listener
        window.addEventListener("resize", handleResize);

        // Clean listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [text]);

    const toggleTextExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const [position, setPosition] = useState(0);
    const swipeThreshold = 150; //  150px

    const handlers = useSwipeable({
        onSwiping: (eventData) => {
            if (allowSwipe) setPosition(eventData.deltaX);
        },
        onSwiped: (eventData) => {
            if (allowSwipe)
                if (Math.abs(eventData.deltaX) > swipeThreshold) {
                    onDismiss(id);
                } else {
                    setPosition(0);
                }
        },
        trackMouse: true,
    });

    const opacity = Math.max(0, 1 - Math.abs(position) / swipeThreshold);

    const cardStyle = {
        transform: `translateX(${position}px)`,
        opacity: opacity,
        transition:
            position === 0
                ? "transform 0.2s ease-out, opacity 0.2s ease-out"
                : "none",
    };

    return (
        <div {...handlers} style={cardStyle}>
            <Grid
                container
                component={Paper}
                elevation={3}
                sx={{
                    p: 2,
                    borderRadius: "8px",
                    bgcolor: "grey.100",
                    bgcolor: Colours.notificationCard,
                }}
                spacing={1}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={1}>
                    {onClick ? (
                        <IconButton
                            onClick={onClick}
                            className="breathing-animation"
                        >
                            <IconToShow type={type} />
                        </IconButton>
                    ) : (
                        <IconToShow type={type} />
                    )}
                </Grid>

                <Grid item xs={11}>
                    <Stack spacing={1}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Typography fontWeight={"bold"}>
                                    {title}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={1}
                                style={{ alignSelf: "flex-start" }}
                            >
                                <Tooltip
                                    title={time
                                        .toISOString()
                                        .split(".")[0]
                                        .replace("T", " ")}
                                >
                                    <Typography fontSize={"0.9em"}>
                                        {formatTime(time)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="stretch"
                        >
                            <Typography
                                ref={textRef}
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: isExpanded
                                        ? "normal"
                                        : "nowrap",
                                }}
                            >
                                {text}
                            </Typography>
                            {hasOverflow && (
                                <Box>
                                    <IconButton
                                        onClick={toggleTextExpansion}
                                        size="small"
                                    >
                                        {isExpanded ? (
                                            <Icons.ArrowUp />
                                        ) : (
                                            <Icons.ArrowDown />
                                        )}
                                    </IconButton>
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    );
};

export default NotificationCard;
