import React, { useEffect, useState } from "react";

import TabSelector from "./TabSelector";
import MonthlyLeadTime from "./MonthlyLeadTime";

import TopsComparisonMonth from "./reports/TopsComparisonMonth";

const MonthlyOthers = ({ data, month, year, hotel_id }) => {
    const getMaxBookingLeadTime = (data) => {
        let maxBooking = -1;
        let maxBookingTimeRange = "";
        Object.keys(data).forEach((key) => {
            if (data[key].Bookings > maxBooking) {
                maxBooking = data[key].Bookings;
                maxBookingTimeRange = key;
            }
        });

        return [maxBooking, maxBookingTimeRange];
    };
    const [cardSelected, setCardSelected] = useState("salesByRate");
    const [othersData, setOthersData] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const [maxBookingleadTime, maxBookingleadTimeRange] =
                getMaxBookingLeadTime(data.tabs.leadTime.this);
            const auxOthersData = [
                {
                    id: "salesByRate",
                    title: "Top rate by sales",
                    value: data.tabs.salesByRate.this,
                    expand: true,
                    noArrow: true,
                    compare: false,
                },
                {
                    id: "leadTimeDistribution",
                    title: "Lead Time Distribution",
                    value: maxBookingleadTime,
                    subtitle: maxBookingleadTimeRange,
                    format: { right: " Bookings" },
                    expand: true,
                    noArrow: true,
                    compare: false,
                },
            ];
            setOthersData(auxOthersData);
        }
    }, [data]);

    return (
        <div>
            <TabSelector
                // hotelID={hotelID}
                data={othersData}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
            />
            <br />
            {/*
                  _____ ___  ____    ____      _  _____ _____ ____  
                 |_   _/ _ \|  _ \  |  _ \    / \|_   _| ____/ ___| 
                   | || | | | |_) | | |_) |  / _ \ | | |  _| \___ \ 
                   | || |_| |  __/  |  _ <  / ___ \| | | |___ ___) |
                   |_| \___/|_|     |_| \_\/_/   \_\_| |_____|____/ 
            */}
            {cardSelected === "salesByRate" && (
                <TopsComparisonMonth
                    thisTitle={`${month} ${year}`}
                    lastTitle={`${month} ${year - 1}`}
                    thisData={data.tabs.salesByRate.historical.this}
                    lastData={data.tabs.salesByRate.historical.past}
                    topBy={"value"}
                    format={{ left: "currency" }}
                    hotel_id={hotel_id}
                    compareLast
                />
            )}

            {cardSelected === "leadTimeDistribution" && (
                <MonthlyLeadTime data={data.tabs.leadTime.this} />
            )}
        </div>
    );
};

export default MonthlyOthers;
