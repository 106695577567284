import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";

import {
    Container,
    Typography,
    TableRow,
    TableCell,
    TableBody,
    Table,
} from "@mui/material";

import TableHeaderCell from "../components/TableHeaderCell";

import { HOTELS_INFO, URLAPI } from "../configuration";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import TableHeadSort from "../components/TableHeadSort";

// const headerStyles = {
//     fontSize: "0.8em",
//     fontWeight: "bold",
//     backgroundColor: Colours.pillarBackgroundAttract,
//     border: "1px solid lightgrey",
// };

const HotelIdentifierScreen = () => {
    const auth = useSelector((state) => state.auth);

    const [sites, setSites] = useState([]);
    const [sortedSites, setSortedSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [registeredHotels, setRegisteredHotels] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("HotelID");

    useEffect(() => {
        Axios.get(`${URLAPI}/hotels`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                setSites(response.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
        Axios.get(`${URLAPI}/hotels/registered`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        }).then((response) => {
            setRegisteredHotels(response.data);
        });
    }, []);

    useEffect(() => {
        const sortedSites_ = [...sites].sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === "asc" ? -1 : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === "asc" ? 1 : -1;
            }
            return 0;
        });
        setSortedSites(sortedSites_);
    }, [order, orderBy, sites]);

    return (
        <>
            <Container>
                <h1>Hotel Identifier</h1>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : sites.length > 0 ? (
                    <Table>
                        <TableHeadSort
                            headers={[
                                {
                                    id: "HotelID",
                                    label: "Hotel ID",
                                },
                                {
                                    id: "HotelName",
                                    label: "Hotel Name",
                                },
                                {
                                    id: "SiteIDs",
                                    label: "Site ID",
                                },
                                {
                                    id: "RegisteredHotels",
                                    label: "Registered Hotels",
                                    ignore: true,
                                },
                            ]}
                            order={order}
                            orderBy={orderBy}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                        ></TableHeadSort>
                        <TableBody>
                            {sortedSites.map((e) => (
                                <TableRow
                                    key={e.SiteID} // Add a unique key to each table row
                                    sx={{
                                        background:
                                            e.HotelID in HOTELS_INFO
                                                ? Colours.secondarySoft
                                                : null,
                                    }}
                                >
                                    <TableCell>
                                        {e.HotelID ? e.HotelID : ""}
                                    </TableCell>
                                    <TableCell>
                                        {e.HotelName
                                            ? e.HotelName
                                            : "No HotelName"}
                                    </TableCell>
                                    <TableCell>
                                        {e.SiteIDs ? e.SiteIDs : "No SiteID"}
                                    </TableCell>
                                    <TableCell>
                                        {registeredHotels.includes(
                                            Number(e.HotelID)
                                        ) && <Icons.StatusComplete />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography>No data available.</Typography>
                )}
            </Container>
        </>
    );
};

export default HotelIdentifierScreen;
