import { preferencesTypes } from "../types/preferencesTypes";

export const preferencesReducer = (state = {}, action) => {
    switch (action.type) {
        case preferencesTypes.toggleThemeMode:
            return {
                ...state,
                themeMode:
                    (state.themeMode ?? "light") === "light" ? "dark" : "light",
            };

        default:
            return state;
    }
};
