export const notification = (enqueueSnackbar, message, variant = "success") => {
    enqueueSnackbar(message, {
        variant: variant,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        },
    });
};

export const nSuccess = (s, message) => {
    notification(s, message, "success");
};

export const nError = (s, message) => {
    notification(s, message, "error");
};

export const nEvaluate = async ({
    s,
    f,
    success = "",
    error = "Error at",
    callbackS = () => {},
    callbackE = () => {},
}) => {
    await f()
        .then((a) => {
            nSuccess(s, success);
            callbackS();
        })
        .catch((e) => {
            nError(s, `${error}: ${e}`);
            callbackE();
        });
};
