import React from "react";
import AppRouter from "./routers/AppRouter";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ErrorBoundary from "./pages/ErrorBoundary";

import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import Hotjar from "@hotjar/browser";

const siteId = 3625965;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

const App = () => {
    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={3}>
                <ErrorBoundary>
                    <AppRouter />
                </ErrorBoundary>
            </SnackbarProvider>
        </Provider>
    );
};

export default App;
