import { stringYearMonth } from "./api";

export const dataPandasRecordToEchartsData = (data) => {
    // Transform `df.to_json(orient="records")` to `[[a,1,1], [b,2,2], [c,3,3]]` and keys
    let newData = [];
    data.forEach((element) => {
        newData.push(Object.values(element));
    });
    const keys_ = Object.keys(data[0]);
    return [newData, keys_];
};

/**
 * Adjust the tooltip position if it's going outside the screen
 */
export const tooltip = {
    trigger: "axis",
    position: function (point, params, dom, rect, size) {
        let x = point[0];
        let y = point[1];
        const width = dom.offsetWidth;
        const height = dom.offsetHeight;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const gap = 30;
        if (x + width + gap >= windowWidth - gap) {
            x = "0%";
            y = "70%";
        }
        if (y + height + gap >= windowHeight) {
            x = "0%";
            y = "70%";
        }
        return [x, y];
    },
};
/**
 * This tooltip emulates the original Echarts design, so we can use it as template or base to create custom ones or add more fields
 */
export const tooltipCustomBase = {
    trigger: "axis",
    formatter: function (params) {
        console.log({ params });
        let tooltipHtml = '<div style="border-collapse: collapse;">';
        tooltipHtml += `<div style="margin-bottom: 5px;"><strong>${params[0].name}</strong></div>`;

        params.forEach(function (item) {
            tooltipHtml +=
                '<div style="display: flex; justify-content: space-between; padding: 0px;">';
            tooltipHtml += `<div>${item.marker}</div>`;
            tooltipHtml += `<div style="flex: 1; text-align: left; padding-left:5px;">${item.seriesName}</div>`;
            tooltipHtml += `<div style="padding-left:15px;"><strong>${item.value}</strong></div>`;
            tooltipHtml += "</div>";
        });

        tooltipHtml += "</div>";
        return tooltipHtml;
    },
};

const exampleCustomTooltip = {
    trigger: "axis",
    formatter: function (params) {
        let tooltipHtml = '<div style="border-collapse: collapse;">';
        tooltipHtml += `<div style="margin-bottom: 5px;"><strong>${params[0].name}</strong></div>`;

        params.forEach(function (item) {
            tooltipHtml +=
                '<div style="display: flex; justify-content: space-between; padding: 0px;">';
            tooltipHtml += `<div style="padding-left:3px;">${item.marker}</div>`;
            tooltipHtml += `<div style="flex: 1; text-align: left; padding-left:4px;">${item.seriesName}</div>`;
            tooltipHtml += `<div style="padding-left:15px;"><strong>${item.value}</strong></div>`;
            tooltipHtml += "</div>";
        });

        // -------
        // EXTRAS:
        // -------
        if (true) {
            //Check you have the value somewhere stored

            // Min value
            let minValue = 0;
            tooltipHtml +=
                '<div style="display: flex; justify-content: space-between; padding: 0px;">';

            // You have to copy the <path d=... from the icon you wanna show from https://mui.com/material-ui/material-icons/
            tooltipHtml += `<div style="display: flex; align-items: center; justify-content: center;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" >
                    <path d="m20 12-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z"></path>
                </svg>
            </div>`;
            tooltipHtml += `<div style="flex: 1; text-align: left; padding-left:5px;">${"Min:"}</div>`;
            tooltipHtml += `<div style="padding-left:15px;"><strong>${minValue}</strong></div>`;
            tooltipHtml += "</div>";

            // Max value
            //...
        }

        // ------
        tooltipHtml += "</div>";
        return tooltipHtml;
    },
};

/**
 * Replicates the default tooltip format from echarts, adding the total value next to the series name
 * @param {array} params array with the series info displayed on the tooltip
 * @param {object} rest within, two key-value can be given: "formatter" as a format function for the values;"sort" as a string to sort the values "asc" or "desc"
 * @returns {string} the formatted string with html tags
 */
export const tooltipSeriesNameWithTotal = (
    params,
    rest = { formatter: (val) => val, sort: "" }
) => {
    let format = rest.formatter ?? ((val) => val);
    if (rest.sort) {
        if (rest.sort === "asc") {
            params.sort(
                (a, b) => (b.data[1] ?? b.data) - (a.data[1] ?? a.data)
            );
        } else if (rest.sort === "desc") {
            params.sort(
                (a, b) => (a.data[1] ?? a.data) - (b.data[1] ?? b.data)
            );
        }
    }
    let total = 0;
    let content = "";
    params.forEach((param) => {
        total += param.data[1] ?? param.data;
        let adornment = `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${param.color};\"></span>`;
        content += `<div style="display: flex; justify-content: space-between; gap: 10px; margin-bottom:4px">
            <span>${adornment}${param.seriesName}</span>
            <span style="float: right;"><b>${format(
                param.data[1] ?? param.data
            )}</b></span>
        </div>`;
    });
    let title = `<div style="display: flex; justify-content: space-between; gap: 10px; margin-bottom:10px">
    <span><b>${params[0].name}</b></span>
    <span style="float: right;"><b>${format(total)}</b></span>
</div>`;
    return `${title}${content}`;
};
export { tooltipSeriesNameWithTotal as tooltipST };

export const dataZoom = [
    {
        type: "inside",
        start: 25,
        end: 100,
    },
    {
        start: 0,
        end: 10,
    },
];
export const dataZoomFull = [
    {
        type: "inside",
        start: 0,
        end: 100,
    },
    {
        start: 0,
        end: 10,
    },
];

export const fridaysBetween = (init, end) => {
    if (end < init) throw new Error("End has to be higher than init");
    var tempDate = init;
    var result = [];

    while (tempDate.valueOf() <= end.valueOf()) {
        if (/Fri/.test(tempDate.toDateString()))
            result.push(new Date(tempDate));
        tempDate.setDate(tempDate.getDate() + 1);
    }
    return result;
};

export const markPointsYearMonthValue = (data, keyValue) => {
    return {
        data: data.map((element) => {
            return {
                coord: [
                    stringYearMonth(element.year_v, element.month_v),
                    element[keyValue],
                ],
            };
        }),
        symbol: "circle",
        symbolSize: 8,
    };
};
