import React, { useEffect, useState } from "react";

import TabSelector from "./TabSelector";

import YearPerformance from "./reports/YearPerformance";

const MonthlyCancellations = ({ data }) => {
    const TITLE = "Cancellations";
    const [cardSelected, setCardSelected] = useState("cancellations");
    const [cancellationsData, setCancellationsData] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const auxCancellationsData = [
                {
                    id: "cancellations",
                    title: "Cancellations",
                    value: data.reports.Cancellations.tabs.cancellations.this,
                    old: data.reports.Cancellations.tabs.cancellations.last,
                    expand: true,
                    compare: true,
                    isInverted: true,
                },
                {
                    id: "cancellationsValue",
                    title: "Cancellations Value",
                    value: data.reports.Cancellations.tabs.cancellationsValue
                        .this,
                    old: data.reports.Cancellations.tabs.cancellationsValue
                        .last,
                    expand: true,
                    compare: true,
                    isInverted: true,
                },
                // {
                //     id: "potentialLostRevenue",
                //     title: "Potential Lost Revenue",
                //     value: data.reports.Cancellations.tabs.potentialLostRevenue
                //         .this,
                //     old: data.reports.Cancellations.tabs.potentialLostRevenue
                //         .last,
                //     expand: true,
                //     compare: true,
                // },
                // {
                //     id: "potentialLostBookings",
                //     title: "Potential Lost Bookings",
                //     value: data.reports.Cancellations.tabs.potentialLostBookings
                //         .this,
                //     old: data.reports.Cancellations.tabs.potentialLostBookings
                //         .last,
                //     expand: true,
                //     compare: true,
                // },
            ];
            setCancellationsData(auxCancellationsData);
        }
    }, [data]);

    return (
        <div>
            <TabSelector
                // hotelID={hotelID}
                data={cancellationsData}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
            />
            <br />

            {(cardSelected === "cancellations" ||
                cardSelected === "cancellationsValue" ||
                cardSelected === "potentialLostRevenue" ||
                cardSelected === "potentialLostBookings") && (
                <YearPerformance
                    TITLE={`${TITLE} - ${
                        cancellationsData.length > 0
                            ? cancellationsData.find(
                                  (head) => head.id === cardSelected
                              ).title ?? ""
                            : ""
                    }`}
                    dataLast={
                        data.reports.Cancellations.tabs[cardSelected].historical
                            .last
                    }
                    dataThis={
                        data.reports.Cancellations.tabs[cardSelected].historical
                            .this
                    }
                />
            )}
        </div>
    );
};

export default MonthlyCancellations;
