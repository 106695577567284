import React, { useState } from "react";

import {
    Container,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
    Stack,
    Grid,
} from "@mui/material";

import Icons from "../helpers/icons";
import { useSelector } from "react-redux";

const ProfileScreen = () => {
    const [showToken, setShowToken] = useState(false);
    const { auth } = useSelector((state) => state);

    const toggleShowToken = () => {
        setShowToken(!showToken);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(auth.user.jwt);
    };
    return (
        <>
            <Container>
                <Grid container>
                    <Grid item sx={{ mt: 2, ml: 2, mb: 2 }} xs={12}>
                        <Typography variant="h4">User Profile</Typography>
                    </Grid>
                </Grid>{" "}
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography>JWT:</Typography>
                    <OutlinedInput
                        type={showToken ? "text" : "password"}
                        value={auth.user.jwt}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={toggleShowToken}>
                                    {showToken ? (
                                        <Icons.EyeOff />
                                    ) : (
                                        <Icons.EyeOn />
                                    )}
                                </IconButton>
                                <IconButton onClick={copyToClipboard}>
                                    <Icons.Copy />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Stack>
            </Container>
        </>
    );
};

export default ProfileScreen;
