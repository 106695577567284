import Axios from "axios";

import Colours from "./colours";
import Icons from "./icons";

import { URLAPI } from "../configuration";

export const TYPES = {
    RANGE: { key: "RANGE", label: "Range", icon: "📏" },
    ABCD: { key: "ABCD", label: "ABCD", icon: "🔠" },
    PERCENTAGE: { key: "PERCENTAGE", label: "Percentage", icon: "%" },
    YESNO: { key: "YESNO", label: "Yes / No", icon: "" },
};

export const CATEGORY = {
    ATTRACT: {
        key: "ATTRACT",
        label: "Attract",
        color: Colours.attract,
    },
    ENGAGE: {
        key: "ENGAGE",
        label: "Engage",
        color: Colours.engage,
    },
    RETAIN: {
        key: "RETAIN",
        label: "Retain",
        color: Colours.retain,
    },
    CONVERT: {
        key: "CONVERT",
        label: "Convert",
        color: Colours.convert,
    },
};

export const SUBCATEGORY = {
    PPC: { key: "PPC", label: "PPC" },
    SEO: { key: "SEO", label: "SEO" },
    SESSIONS: { key: "SESSIONS", label: "Sessions" },
    WEBSITE: { key: "WEBSITE", label: "Website" },
    CONTENT: { key: "CONTENT", label: "Content" },
    BOOKING_ENGINE: { key: "BOOKING_ENGINE", label: "Booking Engine" },
    SPECIAL_OFFERS: { key: "SPECIAL_OFFERS", label: "Special Offers" },
    ROOM_MANAGEMENT: {
        key: "ROOM_MANAGEMENT",
        label: "Room Management",
    },
    RATE_MANAGEMENT: {
        key: "RATE_MANAGEMENT",
        label: "Rate Management",
    },
    CANCELLATIONS: { key: "CANCELLATIONS", label: "Cancellations" },
    EMAIL_MARKETING: {
        key: "EMAIL_MARKETING",
        label: "Email Marketing",
    },
};

export const QUALITY_PERFORMANCE = {
    QUALITY: { key: "QUALITY", label: "Quality" },
    PERFORMANCE: { key: "PERFORMANCE", label: "Performance" },
};

export const STATUS = {
    TODO: {
        key: "TODO",
        label: "To Do",
    },
    INPROGRESS: {
        key: "INPROGRESS",
        label: "In Progress",
    },
    COMPLETE: {
        key: "COMPLETE",
        label: "Complete",
    },
};

export const PRIORITY = {
    HIGH: {
        key: "HIGH",
        Icon: Icons.ArrowUp,
        color: Colours.trafficLightRed,

        label: "High",
    },
    MEDIUM: {
        key: "MEDIUM",
        Icon: Icons.Horizontal,
        color: Colours.trafficLightYellow,
        label: "Medium",
    },
    LOW: {
        key: "LOW",
        Icon: Icons.ArrowDown,
        color: Colours.retain,
        label: "Low",
    },
};

export const valuePost = async (
    auth,
    indicatorID,
    type,
    subtype,
    value,
    hotelID = null
) => {
    try {
        const response = Axios({
            method: "post",
            url: `${URLAPI}/indicators/value`,
            data: JSON.stringify({
                indicator_id: indicatorID,
                user_id: auth.user.user_id,
                hotel_id: hotelID,
                type,
                subtype,
                value_: value,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        });
        return response;
    } catch (error) {
        console.error("Error posting a value", error);
        throw error;
    }
};
