import React from "react";

import { Box, Button, Modal, Paper, Stack, Typography } from "@mui/material";

import Icons from "../helpers/icons";

const ConfirmModal = ({
    isOpen = false,
    setIsOpen = () => {},
    onAccept = () => {},
    onDecline = () => {},
    text = "",
    subtext = "",
    acceptText = "Accept",
    declineText = "Decline",
}) => {
    const AcceptAction = () => {
        onAccept();
        setIsOpen(false);
    };

    const DeclineAction = () => {
        onDecline();
        setIsOpen(false);
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box component={Paper} sx={{ p: 5 }}>
                <Typography fontSize="1.4em">{text}</Typography>
                <Typography fontSize="1.0em">{subtext}</Typography>
                <br />
                <Stack direction="row" spacing={2}>
                    <Button
                        onClick={DeclineAction}
                        variant="outlined"
                        startIcon={<Icons.StatusCancel />}
                    >
                        {declineText}
                    </Button>
                    <Button
                        onClick={AcceptAction}
                        variant="contained"
                        startIcon={<Icons.StatusComplete />}
                    >
                        {acceptText}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default ConfirmModal;
