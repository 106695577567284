import React from "react";
import { SvgIcon } from "@mui/material";

const ResDateIcon = ({ props }) => (
    <SvgIcon
        // sx={{ overflow: "inherit" }}
        width="100%"
        height="100%"
        {...props}
        viewBox="-5 0 80.843 75.477"
    >
        <path
            id="Path_880"
            data-name="Path 880"
            d="M47.507,32.269h9.155a1.171,1.171,0,0,1,1.166,1.166v6.16a19.956,19.956,0,0,0-9.679,2.622h-.641a1.171,1.171,0,0,1-1.166-1.166V33.435a1.169,1.169,0,0,1,1.166-1.166ZM6.389,6.429h5.893v7.863a7.018,7.018,0,0,0,2.657,5.462,8.271,8.271,0,0,0,10.4,0A7.018,7.018,0,0,0,28,14.293V6.429H39.9v7.863a7.017,7.017,0,0,0,2.656,5.462,8.271,8.271,0,0,0,10.4,0,7.014,7.014,0,0,0,2.657-5.462V6.429h6.152a6.416,6.416,0,0,1,6.391,6.39v29.51a19.946,19.946,0,0,0-2.047-1.045V24.929H2.047V62.777A5.04,5.04,0,0,0,7.072,67.8h32.6a19.991,19.991,0,0,0,1.054,2.046H6.389A6.416,6.416,0,0,1,0,63.459V12.819a6.414,6.414,0,0,1,6.389-6.39Zm10.667-3.9A2.85,2.85,0,0,1,20.143,0a2.849,2.849,0,0,1,3.085,2.529V14.293a2.85,2.85,0,0,1-3.085,2.529,2.851,2.851,0,0,1-3.086-2.529Zm27.62,0A2.849,2.849,0,0,1,47.761,0a2.849,2.849,0,0,1,3.085,2.529V14.293a2.85,2.85,0,0,1-3.085,2.529,2.85,2.85,0,0,1-3.085-2.529ZM11.5,32.269h9.155a1.171,1.171,0,0,1,1.166,1.166v7.616a1.173,1.173,0,0,1-1.166,1.166H11.5a1.172,1.172,0,0,1-1.167-1.166V33.435A1.171,1.171,0,0,1,11.5,32.269Zm0,16.2h9.155a1.171,1.171,0,0,1,1.166,1.166v7.616a1.173,1.173,0,0,1-1.166,1.166H11.5a1.172,1.172,0,0,1-1.167-1.166V49.632A1.171,1.171,0,0,1,11.5,48.466Zm18.1-16.2h9.155a1.171,1.171,0,0,1,1.166,1.166v7.616a1.173,1.173,0,0,1-1.166,1.166H29.6a1.171,1.171,0,0,1-1.166-1.166V33.435A1.169,1.169,0,0,1,29.6,32.269Zm0,16.2h9.155a1.171,1.171,0,0,1,1.166,1.166v1.416A19.971,19.971,0,0,0,38,58.414H29.6a1.171,1.171,0,0,1-1.166-1.166V49.632A1.169,1.169,0,0,1,29.6,48.466Z"
            fillRule="evenodd"
        />
        <path
            id="Path_881"
            data-name="Path 881"
            d="M302.594,297.88a15.785,15.785,0,1,1-15.784,15.786A15.788,15.788,0,0,1,302.594,297.88Zm-4.559,13.553,2.584,2.441,6.177-6.36c.507-.511.824-.925,1.447-.283l2.024,2.075c.666.656.632,1.043.006,1.655l-8.5,8.459c-1.324,1.3-1.092,1.375-2.432.046l-4.717-4.69a.581.581,0,0,1,.058-.908l2.349-2.438C297.391,311.054,297.674,311.086,298.035,311.433Z"
            transform="translate(-244.536 -253.974)"
            fillRule="evenodd"
        />
    </SvgIcon>
);
export default ResDateIcon;
