import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";

import {
    Select,
    MenuItem,
    Typography,
    FormControl,
    InputLabel,
    TextField,
    Stack,
    Grid,
    Paper,
    Box,
    Container,
    Modal,
    IconButton,
} from "@mui/material";

import { useSnackbar } from "notistack";

import RoundedButton from "../RoundedButton";
import ModalConfirmation from "../ModalConfirmation";

import IndicatorsTypeABCD from "./IndicatorsTypeABCD";
import IndicatorsTypeRange from "./IndicatorsTypeRange";
import IndicatorsTypeYesNo from "./IndicatorsTypeYesNo";
import IndicatorsTypePercentage from "./IndicatorsTypePercentage";

import {
    TYPES,
    STATUS,
    PRIORITY,
    CATEGORY,
    QUALITY_PERFORMANCE,
    SUBCATEGORY,
} from "../../helpers/indicators";
import Colours from "../../helpers/colours";
import Icons, { DynamicIcon } from "../../helpers/icons";
import { DEFAULT_HOTEL, URLAPI } from "../../configuration";

const IndicatorForm = ({
    setOpen = () => {},
    open = false,
    edit = false,
    indicatorDataRaw = {},
    indicatorDataProcessed = {},
    handleRefreshIndicators, // TODO: Implement refresh
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { auth } = useSelector((state) => state);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [isEditing, setIsEditing] = useState(edit);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [qualityPerformance, setQualityPerformance] = useState("");
    const [status, setStatus] = useState("");
    const [priority, setPriority] = useState("");
    const [type, setType] = useState("");

    useEffect(() => {
        if (
            Object.keys(indicatorDataProcessed).length > 0 &&
            indicatorDataProcessed.type
        ) {
            // Common fields
            setType(indicatorDataProcessed.type);
            setName(indicatorDataProcessed.name);
            setDescription(indicatorDataProcessed.description);
            setCategory(indicatorDataProcessed.category);
            setSubcategory(indicatorDataProcessed.subcategory);
            setStatus(indicatorDataProcessed.status);
            setPriority(indicatorDataProcessed.priority);
            setQualityPerformance(indicatorDataProcessed.quality_performance);
        }
    }, [indicatorDataProcessed, indicatorDataRaw]);

    const [typeUtility, setTypeUtility] = useState({
        post: () => {},
        isValid: () => {},
        modifiedType: () => {},
    });

    const modifiedCommon = () =>
        name !== indicatorDataProcessed.name ||
        description !== indicatorDataProcessed.description ||
        category !== indicatorDataProcessed.category ||
        subcategory !== indicatorDataProcessed.subcategory ||
        qualityPerformance !== indicatorDataProcessed.quality_performance;
    const modifiedStatusPrio = () =>
        status !== indicatorDataProcessed.status ||
        priority !== indicatorDataProcessed.priority;

    const [errorForm, setErrorForm] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [closeAnswer, setCloseAnswer] = useState("");
    const handleClose = () => {
        if (
            !modifiedCommon() &&
            !modifiedStatusPrio() &&
            !typeUtility.modifiedType()
        )
            // Else if isEditing === true then don't check?
            setOpen(false);
        else setConfirmOpen(true);
    };

    const [disabledPost, setDisabledPost] = useState(false);
    const postIndicator = async () => {
        setErrorForm(false);

        const validCommon = modifiedCommon()
            ? name.trim() !== "" &&
              description.trim() !== "" &&
              category.trim() !== "" &&
              subcategory.trim() !== "" &&
              qualityPerformance.trim() !== "" &&
              type.trim() !== ""
            : true;
        const validStatusPrio = modifiedStatusPrio()
            ? status !== "" && priority !== ""
            : true;
        const validType = typeUtility.modifiedType()
            ? typeUtility.isValid()
            : true;
        if (!validType || !validCommon || !validStatusPrio) {
            setErrorForm(true);
            enqueueSnackbar("Invalid fields detected.", {
                variant: "error",
            });
            return;
        }

        try {
            if (
                modifiedCommon() ||
                modifiedStatusPrio() ||
                typeUtility.modifiedType()
            ) {
                if (modifiedCommon()) {
                    const responseIndicatorCommon = await Axios({
                        method: "patch",
                        url: `${URLAPI}/indicators/indicator/${indicatorDataRaw.id}`,
                        data: JSON.stringify({
                            name: name,
                            description: description,
                            type: type,
                            category: category,
                            subcategory: subcategory,
                            quality_performance: qualityPerformance,
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${auth.user.jwt}`,
                        },
                    });

                    if (responseIndicatorCommon.status !== 200) {
                        let error = new Error(
                            "Unexpected Common patch response."
                        );
                        error.response = responseIndicatorCommon;
                        throw error;
                    }
                }

                if (modifiedStatusPrio()) {
                    const responseStatusPrio = await Axios({
                        method: "post",
                        url: `${URLAPI}/indicators/status`,
                        data: JSON.stringify({
                            indicator_id: indicatorDataProcessed.id,
                            user_id: auth.user.user_id,
                            hotel_id:
                                hotelID === "default" ? DEFAULT_HOTEL : hotelID,
                            status,
                            priority,
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${auth.user.jwt}`,
                        },
                    });
                    if (responseStatusPrio.status !== 201) {
                        let error = new Error(
                            "Unexpected Status/Priority post response."
                        );
                        error.response = responseStatusPrio;
                        throw error;
                    }
                }

                await typeUtility.post(auth, indicatorDataProcessed.id);
                handleRefreshIndicators();
                enqueueSnackbar(`Indicator '${name}' has been edited.`, {
                    variant: "success",
                });
            } else {
                enqueueSnackbar(`No changes detected.`, {
                    variant: "warning",
                });
            }
        } catch (error) {
            console.error("Saving edited fields didn't go as expected: ", {
                error,
            });
            enqueueSnackbar(
                "Saving edited fields didn't go as expected: " + error,
                {
                    variant: "error",
                }
            );
        }
        setCloseAnswer("close");
        setIsEditing(false);
    };

    useEffect(() => {
        if (closeAnswer === "close") {
            setErrorForm(false);
            setOpen(false);

            setCloseAnswer("");
        }
    }, [closeAnswer]);

    return (
        <>
            <ModalConfirmation
                title={"Closing form"}
                description={
                    "Are you sure you want to close it? All changes will be lost."
                }
                answers={[
                    { title: "Close", value: "close" },
                    { title: "Cancel", value: "cancel" },
                ]}
                setAnswer={setCloseAnswer}
                setOpen={setConfirmOpen}
                open={confirmOpen}
            />

            <Modal
                key={"details-indicator-modal"}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                open={open}
                onClose={() => handleClose()}
            >
                <Container
                    style={{
                        width: "90%",
                        maxHeight: "90%",
                        overflow: "auto",
                    }}
                    disableGutters
                >
                    <Paper sx={{ p: 5 }}>
                        <Box display={"flex"} justifyContent={"right"}>
                            <IconButton onClick={() => handleClose()}>
                                <Icons.CloseNavbar />
                            </IconButton>
                        </Box>
                        {Object.keys(indicatorDataProcessed).length > 0 && (
                            <>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack direction="column" spacing={1}>
                                            <Typography variant={"h6"}>
                                                Hotels shared fields
                                            </Typography>
                                            <Stack
                                                style={{
                                                    borderTop: `2px solid ${Colours.primary}`,
                                                    padding: 10,
                                                }}
                                                spacing={2}
                                            >
                                                {isEditing ? (
                                                    <>
                                                        <TextField
                                                            required
                                                            error={
                                                                name.trim() ===
                                                                    "" &&
                                                                errorForm
                                                            }
                                                            fullWidth
                                                            label="Name"
                                                            value={name}
                                                            onChange={(e) =>
                                                                setName(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <TextField
                                                            required
                                                            error={
                                                                description.trim() ===
                                                                    "" &&
                                                                errorForm
                                                            }
                                                            fullWidth
                                                            label="Description"
                                                            value={description}
                                                            onChange={(e) =>
                                                                setDescription(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <FormControl
                                                            fullWidth
                                                            required
                                                            error={
                                                                category.trim() ===
                                                                    "" &&
                                                                errorForm
                                                            }
                                                        >
                                                            <InputLabel id="category-label">
                                                                Category
                                                            </InputLabel>
                                                            <Select
                                                                labelId="category-label"
                                                                value={category}
                                                                label="Category"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setCategory(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                            >
                                                                {Object.values(
                                                                    CATEGORY
                                                                ).map(
                                                                    ({
                                                                        key,
                                                                        color,
                                                                        label,
                                                                    }) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    key
                                                                                }
                                                                            >
                                                                                <Stack
                                                                                    direction={
                                                                                        "row"
                                                                                    }
                                                                                >
                                                                                    <Icons.Circle
                                                                                        sx={{
                                                                                            color: color,
                                                                                            mr: 1,
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        label
                                                                                    }
                                                                                </Stack>
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl
                                                            fullWidth
                                                            required
                                                            error={
                                                                subcategory.trim() ===
                                                                    "" &&
                                                                errorForm
                                                            }
                                                        >
                                                            <InputLabel id="category-label">
                                                                SubCategory
                                                            </InputLabel>
                                                            <Select
                                                                labelId="subCategory-label"
                                                                value={
                                                                    subcategory
                                                                }
                                                                label="SubCategory"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setSubcategory(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                            >
                                                                {Object.values(
                                                                    SUBCATEGORY
                                                                ).map(
                                                                    ({
                                                                        key,
                                                                        label,
                                                                    }) => (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl
                                                            fullWidth
                                                            required
                                                            error={
                                                                qualityPerformance.trim() ===
                                                                    "" &&
                                                                errorForm
                                                            }
                                                        >
                                                            <InputLabel id="quality-performance-label">
                                                                Quality /
                                                                Performance
                                                            </InputLabel>
                                                            <Select
                                                                labelId="quality-performance-label"
                                                                value={
                                                                    qualityPerformance
                                                                }
                                                                label="Quality / Performance"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setQualityPerformance(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                            >
                                                                {Object.values(
                                                                    QUALITY_PERFORMANCE
                                                                ).map(
                                                                    ({
                                                                        key,
                                                                        label,
                                                                    }) => (
                                                                        <MenuItem
                                                                            value={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl
                                                            fullWidth
                                                            required
                                                            error={
                                                                type.trim() ===
                                                                    "" &&
                                                                errorForm
                                                            }
                                                        >
                                                            <InputLabel id="indicator-type-label">
                                                                Indicator Type
                                                            </InputLabel>
                                                            <Select
                                                                labelId="indicator-type-label"
                                                                id="indicator-type-select"
                                                                value={type}
                                                                label="Indicator Type"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setType(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                            >
                                                                {Object.values(
                                                                    TYPES
                                                                ).map(
                                                                    (
                                                                        type_obj
                                                                    ) => (
                                                                        <MenuItem
                                                                            value={
                                                                                type_obj.key
                                                                            }
                                                                        >
                                                                            {type_obj.icon +
                                                                                " " +
                                                                                type_obj.label}
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Stack direction="column">
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Name
                                                            </Typography>
                                                            <Typography>
                                                                {name}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="column">
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Description
                                                            </Typography>
                                                            <Typography>
                                                                {description}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="column">
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Category
                                                            </Typography>
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                            >
                                                                <Icons.Circle
                                                                    sx={{
                                                                        mr: 1,
                                                                        color:
                                                                            (
                                                                                CATEGORY[
                                                                                    category
                                                                                ] ??
                                                                                {}
                                                                            )
                                                                                .color ??
                                                                            "white",
                                                                    }}
                                                                />
                                                                <Typography>
                                                                    {category[0] +
                                                                        category
                                                                            .slice(
                                                                                1
                                                                            )
                                                                            .toLocaleLowerCase()}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                        <Stack direction="column">
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                SubCategory
                                                            </Typography>
                                                            <Typography>
                                                                {SUBCATEGORY.hasOwnProperty(
                                                                    subcategory
                                                                )
                                                                    ? SUBCATEGORY[
                                                                          subcategory
                                                                      ].label
                                                                    : "No Subcategory set"}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="column">
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Quality/Performance
                                                            </Typography>
                                                            <Typography>
                                                                {qualityPerformance
                                                                    ? QUALITY_PERFORMANCE[
                                                                          qualityPerformance
                                                                      ].label
                                                                    : "No Quality/Performance set"}
                                                            </Typography>
                                                        </Stack>

                                                        <Stack direction="column">
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Indicator Type
                                                            </Typography>
                                                            {TYPES.hasOwnProperty(
                                                                type
                                                            )
                                                                ? TYPES[type]
                                                                      .icon +
                                                                  " " +
                                                                  TYPES[type]
                                                                      .label
                                                                : "Type wasn't found"}
                                                        </Stack>
                                                    </>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack direction="column" spacing={1}>
                                            <Typography variant={"h6"}>
                                                Hotel individual fields
                                            </Typography>
                                            <Stack
                                                spacing={isEditing ? 6 : 4}
                                                style={{
                                                    borderTop: `2px solid ${Colours.primary}`,
                                                    padding: 10,
                                                }}
                                            >
                                                {isEditing ? (
                                                    <Stack
                                                        direction="column"
                                                        spacing={2}
                                                    >
                                                        <FormControl
                                                            fullWidth
                                                            required={modifiedStatusPrio()}
                                                            error={
                                                                status.trim() ===
                                                                    "" &&
                                                                errorForm &&
                                                                modifiedStatusPrio()
                                                            }
                                                        >
                                                            <InputLabel id="status-label">
                                                                Status
                                                            </InputLabel>
                                                            <Select
                                                                labelId="status-label"
                                                                value={status}
                                                                label="Status"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setStatus(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                            >
                                                                {Object.values(
                                                                    STATUS
                                                                ).map(
                                                                    ({
                                                                        key,
                                                                        label,
                                                                    }) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    key
                                                                                }
                                                                            >
                                                                                {
                                                                                    label
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl
                                                            fullWidth
                                                            required={modifiedStatusPrio()}
                                                            error={
                                                                priority.trim() ===
                                                                    "" &&
                                                                errorForm &&
                                                                modifiedStatusPrio()
                                                            }
                                                        >
                                                            <InputLabel id="priority-label">
                                                                Priority
                                                            </InputLabel>
                                                            <Select
                                                                labelId="priority-label"
                                                                value={priority}
                                                                label="Priority"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setPriority(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                            >
                                                                {Object.values(
                                                                    PRIORITY
                                                                ).map(
                                                                    ({
                                                                        key,
                                                                        Icon,
                                                                        color,
                                                                        label,
                                                                    }) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    key
                                                                                }
                                                                            >
                                                                                <Stack
                                                                                    direction={
                                                                                        "row"
                                                                                    }
                                                                                >
                                                                                    <Icon
                                                                                        sx={{
                                                                                            mr: 1,
                                                                                            color: color,
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        label
                                                                                    }
                                                                                </Stack>
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                ) : (
                                                    <Stack
                                                        direction="column"
                                                        spacing={2}
                                                    >
                                                        <Stack direction="column">
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Status
                                                            </Typography>

                                                            <Stack
                                                                direction={
                                                                    "row"
                                                                }
                                                            >
                                                                <Typography>
                                                                    {status
                                                                        ? (
                                                                              STATUS[
                                                                                  status
                                                                              ] ??
                                                                              {}
                                                                          )
                                                                              .label ??
                                                                          "No Status set"
                                                                        : "No Status set"}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>

                                                        <Stack direction="column">
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Priority
                                                            </Typography>
                                                            {priority ? (
                                                                <Stack
                                                                    direction={
                                                                        "row"
                                                                    }
                                                                >
                                                                    <DynamicIcon
                                                                        Icon={
                                                                            PRIORITY[
                                                                                priority
                                                                            ]
                                                                                .Icon
                                                                        }
                                                                        sx={{
                                                                            mr: 1,
                                                                            color: PRIORITY[
                                                                                priority
                                                                            ]
                                                                                .color,
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        {
                                                                            PRIORITY[
                                                                                priority
                                                                            ]
                                                                                .label
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            ) : (
                                                                <Typography>
                                                                    No Priority
                                                                    set
                                                                </Typography>
                                                            )}
                                                        </Stack>
                                                    </Stack>
                                                )}

                                                <Stack
                                                    direction="column"
                                                    spacing={2}
                                                >
                                                    {type ===
                                                        TYPES.RANGE.key && (
                                                        <IndicatorsTypeRange
                                                            errorForm={
                                                                errorForm
                                                            }
                                                            type={type}
                                                            data={
                                                                indicatorDataProcessed
                                                            }
                                                            rawData={
                                                                indicatorDataRaw
                                                            }
                                                            edit={isEditing}
                                                            utilitySetter={
                                                                setTypeUtility
                                                            }
                                                            hotelID={
                                                                hotelID ===
                                                                "default"
                                                                    ? DEFAULT_HOTEL
                                                                    : hotelID
                                                            }
                                                        />
                                                    )}
                                                    {type ===
                                                        TYPES.YESNO.key && (
                                                        <IndicatorsTypeYesNo
                                                            errorForm={
                                                                errorForm
                                                            }
                                                            type={type}
                                                            data={
                                                                indicatorDataProcessed
                                                            }
                                                            rawData={
                                                                indicatorDataRaw
                                                            }
                                                            edit={isEditing}
                                                            utilitySetter={
                                                                setTypeUtility
                                                            }
                                                            hotelID={
                                                                hotelID ===
                                                                "default"
                                                                    ? DEFAULT_HOTEL
                                                                    : hotelID
                                                            }
                                                        />
                                                    )}
                                                    {type ===
                                                        TYPES.PERCENTAGE
                                                            .key && (
                                                        <IndicatorsTypePercentage
                                                            errorForm={
                                                                errorForm
                                                            }
                                                            type={type}
                                                            data={
                                                                indicatorDataProcessed
                                                            }
                                                            rawData={
                                                                indicatorDataRaw
                                                            }
                                                            edit={isEditing}
                                                            utilitySetter={
                                                                setTypeUtility
                                                            }
                                                            hotelID={
                                                                hotelID ===
                                                                "default"
                                                                    ? DEFAULT_HOTEL
                                                                    : hotelID
                                                            }
                                                        />
                                                    )}
                                                    {type === TYPES.ABCD && (
                                                        <IndicatorsTypeABCD
                                                            type={type}
                                                            data={
                                                                indicatorDataProcessed
                                                            }
                                                            edit={isEditing}
                                                            utilitySetter={
                                                                setTypeUtility
                                                            }
                                                        />
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <br />

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    {isEditing ? (
                                        <RoundedButton
                                            disabled={disabledPost}
                                            onClick={async () => {
                                                setDisabledPost(true);

                                                await postIndicator();
                                                setDisabledPost(false);
                                            }}
                                        >
                                            Save
                                        </RoundedButton>
                                    ) : (
                                        <RoundedButton
                                            onClick={() => setIsEditing(true)}
                                        >
                                            Edit
                                        </RoundedButton>
                                    )}
                                </Box>
                            </>
                        )}
                    </Paper>
                </Container>
            </Modal>
        </>
    );
};

export default IndicatorForm;
