import { dataBookingsTypes } from "../types/dataBookingsTypes";

const dataBookingsInitial = {
    status: "empty",
    data: [],
    reload: new Date().toISOString(),
};
export const dataBookingsReducer = (state = dataBookingsInitial, action) => {
    switch (action.type) {
        case dataBookingsTypes.loading:
            return {
                ...state,
                status: "loading",
                data: {},
                hotelID: action.hotelID,
            };
        case dataBookingsTypes.partialLoad:
            return {
                ...state,
                status: "partiallyLoaded",
                data: action.payload,
                lastEntry: action.lastEntry,
                hotelID: action.hotelID,
            };
        case dataBookingsTypes.load:
            let aux = {};
            state.data.map((e) => {
                aux[e.ReservationID] = e;
            });
            action.payload.map((e) => {
                aux[e.ReservationID] = e;
            });

            return {
                ...state,
                status: "loaded",
                data: Object.values(aux),
                lastEntry:
                    new Date(state.lastEntry) < new Date(action.lastEntry)
                        ? action.lastEntry
                        : state.lastEntry,
                hotelID: action.hotelID,
            };
        case dataBookingsTypes.reload:
            return {
                ...state,
                status: "reload",
                reload: new Date().toISOString(),
            };

        case dataBookingsTypes.error:
            return {
                ...state,
                status: "error",
                data: action.payload,
                error: action.error,
            };

        case dataBookingsTypes.clean:
            return dataBookingsInitial;

        default:
            return state;
    }
};
