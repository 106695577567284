import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    CircularProgress,
    Divider,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { SignInButton } from "../components/SignInButton";

import Colours from "../helpers/colours";
import { Authenticate } from "../actions/auth";
import { AROLOGO } from "../configuration";

const LoginScreen = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    let isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const search = useLocation().search;
    const URLtoken = new URLSearchParams(search).get("token");
    const URLhotelID = new URLSearchParams(search).get("hotelid");

    const { loading } = useSelector((state) => state.auth);

    const [url, setUrl] = useState("");

    useEffect(() => {
        if (URLtoken || (URLtoken && URLhotelID)) {
            dispatch(Authenticate(URLtoken, URLhotelID));
            console.log(URLtoken, URLhotelID);
        }
    }, [URLtoken, URLhotelID]);

    useEffect(() => {
        console.log({
            environment: process.env.NODE_ENV,
            url: window.location.href,
        });

        if (
            window.location.href.includes("cuchulainndemo.dev.arosuite.com") ||
            process.env.NODE_ENV === "development"
        )
            setUrl("https://arosuitestaging.arosuite.com");
        else {
            setUrl("https://arosuite.com");
        }
    }, []);

    return (
        <>
            <Paper>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ height: "100vh" }}
                >
                    <Typography
                        color={Colours.textColour}
                        letterSpacing={4}
                        fontSize={32}
                    >
                        WELCOME TO
                    </Typography>
                    <img
                        width={isSmall ? "300em" : "400em"}
                        alt="logo"
                        src={AROLOGO}
                    />
                    <br />
                    <Divider style={{ width: "8em" }} />
                    <br />
                    {!URLtoken && <SignInButton url={url} />}
                    {loading && <CircularProgress />}
                </Stack>
            </Paper>
        </>
    );
};

export default LoginScreen;
