import React, { useState } from "react";
import Paper from "@mui/material/Paper";

const HoverPaper = ({
    children,
    elevation: defaultElevation = 2,
    ...otherProps
}) => {
    const [elevation, setElevation] = useState(defaultElevation);

    const handleMouseOver = () => setElevation(defaultElevation + 6);
    const handleMouseOut = () => setElevation(defaultElevation);

    return (
        <Paper
            elevation={elevation}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            {...otherProps}
        >
            {children}
        </Paper>
    );
};

export default HoverPaper;
