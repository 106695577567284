import { NotificationsTypes } from "../types/NotificationsTypes";

import Axios from "axios";

import { URLAPI } from "../configuration";

export const loadNotifications = (user_id) => {
    return async (dispatch) => {
        await Axios({
            method: "get",
            params: { user_id },
            url: `${URLAPI}/notifications`,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            const {
                data: { data, user_id: userID },
            } = response;
            let data_ = data.map((e) => {
                return { ...e, ts: new Date(e.ts) }; //Transform ts to new Date
            });
            dispatch({
                type: NotificationsTypes.set,
                payload: data_,
                user_id: userID,
            });
        });
    };
};

export const setNotifications = (data, user_id) => {
    return {
        type: NotificationsTypes.set,
        payload: data,
        user_id,
    };
};
