import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Container,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import WrapperPDFTable from "../shared/WrapperPDFTable";
import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import SmallPercentage from "../SmallPercentage";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import DateSelector from "../DateSelector";
import TableHeaderCell from "../TableHeaderCell";
import TableBodyCell from "../TableBodyCell";
import MonthlyRoomsCardsReport from "./MonthlyRoomsCardsReport";
import SwitchVisualTable, {
    TABLE_MODE,
    TABULAR_MODE,
} from "../SwitchVisualTable";
import OptionsButtonDialog from "../OptionsButtonDialog";

import { getYearsBefore, months } from "../../helpers/dates";
import { process, processMonth, round } from "../../helpers/reports";
import Colours from "../../helpers/colours";
import { fns } from "../../helpers/common";
import { reservationsToYearMonth } from "../DataLoader";

const CalcPercentage = (year, data, key, context, formatter) => {
    const latestYear = Object.keys(data).sort().reverse()[0];
    const actualYear = new Date().getUTCFullYear();

    let actual = 0;
    let old = 0;

    if (Object.keys(data).includes(actualYear)) {
        if (year === String(actualYear)) return;

        actual = (data[actualYear] ?? {})[key] ?? 0;
        old = (data[year] ?? {})[key] ?? 0;
    } else {
        if (year === String(latestYear)) return;

        actual = (data[latestYear] ?? {})[key] ?? 0;
        old = (data[year] ?? {})[key] ?? 0;
    }

    return (
        <SmallPercentage
            actual={actual}
            old={old}
            isInverted={key === "revenueCancelled" ? true : false}
            context={context}
            formatter={formatter}
        />
    );
};

const RoomsAndVouchersRevenueReport = ({ hotelID }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const REPORT_DESC =
        "This report shows a summary of bookings made in a particular month/year for both rooms and vouchers data. Cancellations are not included.";
    const TITLE = "Monthly Rooms & Vouchers";

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(false);
    const {
        time: sharedTime,
        fullYear: sharedFullYear,
        dataSource: sharedDataSource,
    } = useSelector((state) => state.settings.settings);

    const [displayData, setDisplayData] = useState({});
    const [connectionError, setConnectionError] = useState(false);
    const [mode, setMode] = useState(TABULAR_MODE);

    const { dataBookings } = useSelector((state) => state);
    const [selectedDataSource, setSelectedDataSource] = useState({
        status: "empty",
    });

    const [loaded, setLoaded] = useState(false);

    const [dataByYear, setDataByYear] = useState({});
    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});
    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const setMonthsData = [
        setDataByJanuary,
        setDataByFebruary,
        setDataByMarch,
        setDataByApril,
        setDataByMay,
        setDataByJune,
        setDataByJuly,
        setDataByAugust,
        setDataBySeptember,
        setDataByOctober,
        setDataByNovember,
        setDataByDecember,
    ];

    const HEADER = [
        {
            id: "bookings",
            label: "Bookings",
        },
        {
            id: "roomNights",
            label: "Room Nights",
        },
        {
            id: "revenue",
            label: "Revenue",
            format: { left: "currency" },
        },
        {
            id: "revenueCancelled",
            label: "Cancelled Revenue",
            format: { left: "currency" },
        },
        {
            id: "ADR",
            label: "ADR",
            format: { left: "currency" },
            tooltip: "Average Daily Rate",
        },
        {
            id: "ALoS",
            label: "ALoS",
            format: { right: " days", maxDecimals: 2 },
            tooltip: "Average Length of Stay",
        },
        {
            id: "ABW",
            label: "ABW",
            format: { right: " days" },
            tooltip: "Average Booking Window",
        },
    ];

    useEffect(() => {
        switch (sharedDataSource) {
            case "CheckOut":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(
                        dataBookings.data,
                        "CheckOut"
                    ),
                });
                break;
            case "CheckIn":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(dataBookings.data, "CheckIn"),
                });
                break;
            case "ResDate":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(dataBookings.data, "ResDate"),
                });
                break;

            default:
                setSelectedDataSource({ status: "empty" });
                break;
        }
    }, [sharedDataSource, dataBookings]);

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    // Gets non-empty data(at least one key not 0)
    const getValidData = (unfilteredData) => {
        let validData = {};
        Object.entries(unfilteredData).map(([yearKey, data]) => {
            if (
                !Object.keys(data).every((key) =>
                    key === "Details" ? true : data[key] === 0
                )
            ) {
                return (validData[yearKey] = data);
            }
            return undefined;
        });

        return validData;
    };

    //               _ _
    //   _ __  _   _| | |
    //  | '_ \| | | | | |
    //  | |_) | |_| | | |
    //  | .__/ \__,_|_|_|
    //  |_|
    useEffect(() => {
        if (selectedDataSource.status === "error") setConnectionError(true);
        if (selectedDataSource.status === "loaded") {
            // Get array of all years to n years before
            let years = getYearsBefore();

            months.forEach((month, index) => {
                let auxMonthData = Object.assign(
                    {},
                    ...years.map((year) => ({
                        [year]: processMonth(
                            (selectedDataSource.data[`${year}`] ?? {})[month] ??
                                [],
                            sharedDataSource,
                            year,
                            index
                        ),
                    }))
                );

                setMonthsData[index](getValidData(auxMonthData));
            });

            let auxYearData = Object.assign(
                {},
                ...years.map((year) => ({
                    [year]: process(
                        Object.values(
                            selectedDataSource.data[`${year}`] ?? {}
                        ).flat(1)
                    ),
                }))
            );

            setDataByYear(getValidData(auxYearData));

            setLoaded(true);
        } else setLoaded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataSource]);

    //      _ _           _
    //     | (_)         | |
    //   __| |_ ___ _ __ | | __ _ _   _
    //  / _` | / __| '_ \| |/ _` | | | |
    // | (_| | \__ \ |_) | | (_| | |_| |
    //  \__,_|_|___/ .__/|_|\__,_|\__, |
    //             | |             __/ |
    //             |_|            |___/
    useEffect(() => {
        let auxDisplayData = {};
        if (
            Object.entries(dataByYear).length > 0 &&
            Object.entries(monthsData).every((arr) => arr.length > 0)
        ) {
            auxDisplayData = fullYear
                ? dataByYear
                : monthsData[time.getUTCMonth()];
        }
        setDisplayData(auxDisplayData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, ...monthsData, dataByYear]);

    return (
        <Stack direction={"column"} spacing={0.1}>
            <Container maxWidth={false} component={HoverPaper}>
                <>
                    <br />
                    <Stack
                        direction={{ md: "column", sm: "column", xs: "row" }}
                        justifyContent={
                            isMobile ? "space-around" : "space-between"
                        }
                        alignItems="flex-start"
                        // style={{ border: "1px solid red" }}
                    >
                        {/* Title */}
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent={"center"}
                            // style={{ border: "1px solid orange" }}
                        >
                            <Stack
                                alignItems="center"
                                // style={{ border: "1px solid yellow" }}
                            >
                                <Typography fontWeight={"bold"}>
                                    {TITLE}
                                </Typography>
                                <Typography
                                    fontStyle={"italic"}
                                    variant="subtitle2"
                                >
                                    {`by ${
                                        sharedDataSource === "CheckOut"
                                            ? "Check-out"
                                            : sharedDataSource === "CheckIn"
                                            ? "Check-in"
                                            : "Reservation"
                                    } date`}
                                </Typography>
                            </Stack>
                            <InfoIcon text={REPORT_DESC} />
                            <ErrorIcon show={connectionError} />
                        </Stack>

                        {isMobile ? (
                            <OptionsButtonDialog>
                                <SwitchVisualTable
                                    collapse={true}
                                    modes={[TABLE_MODE, TABULAR_MODE]}
                                    mode={mode}
                                    setMode={setMode}
                                />
                                <DateSelector
                                    collapse={isMobile}
                                    fullYear={fullYear}
                                    setFullYear={setFullYear}
                                    time={time}
                                    setTime={setTime}
                                    day={false}
                                />
                                {""}
                            </OptionsButtonDialog>
                        ) : (
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                // style={{ border: "1px solid green   " }}
                            >
                                <SwitchVisualTable
                                    modes={[TABLE_MODE, TABULAR_MODE]}
                                    mode={mode}
                                    setMode={setMode}
                                />
                                <DateSelector
                                    collapse={isMobile}
                                    fullYear={fullYear}
                                    setFullYear={setFullYear}
                                    time={time}
                                    setTime={setTime}
                                    day={false}
                                />
                            </Stack>
                        )}
                        <br />
                    </Stack>

                    {!loaded ? (
                        <>
                            <br /> <ProgressBarSteps />
                        </>
                    ) : (
                        ""
                    )}

                    {mode === TABLE_MODE && (
                        <TableContainer
                            style={{ overflow: "auto", maxHeight: "600px" }}
                        >
                            <WrapperPDFTable k={TITLE}>
                                <Table>
                                    {/*  _   _ _____    _    ____   */}
                                    {/* | | | | ____|  / \  |  _ \  */}
                                    {/* | |_| |  _|   / _ \ | | | | */}
                                    {/* |  _  | |___ / ___ \| |_| | */}
                                    {/* |_| |_|_____/_/   \_\____/  */}
                                    <TableHead>
                                        {Object.keys(displayData).length > 0 ? (
                                            <TableRow>
                                                {
                                                    <>
                                                        <TableCell
                                                            key={"field"}
                                                            style={{
                                                                position:
                                                                    "sticky",
                                                                top: 0,
                                                                zIndex: 3,
                                                                backgroundColor:
                                                                    Colours.plainWhite,
                                                            }}
                                                        />

                                                        {Object.keys(
                                                            displayData
                                                        )
                                                            .reverse()
                                                            .map((year) => (
                                                                <TableHeaderCell
                                                                    key={year}
                                                                    style={{
                                                                        position:
                                                                            "sticky",
                                                                        top: 0,
                                                                        zIndex: 3,

                                                                        backgroundColor:
                                                                            Colours.plainWhite,
                                                                    }}
                                                                >
                                                                    {fullYear
                                                                        ? year
                                                                        : `${months[
                                                                              time.getUTCMonth()
                                                                          ].slice(
                                                                              0,
                                                                              3
                                                                          )} ${year}`}
                                                                </TableHeaderCell>
                                                            ))}
                                                    </>
                                                }
                                            </TableRow>
                                        ) : (
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {getYearsBefore().map(
                                                    (year) => (
                                                        <TableCell key={year}>
                                                            <Skeleton />
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        )}
                                    </TableHead>
                                    {/*  ____   _____        ______   */}
                                    {/* |  _ \ / _ \ \      / / ___|  */}
                                    {/* | |_) | | | \ \ /\ / /\___ \  */}
                                    {/* |  _ <| |_| |\ V  V /  ___) | */}
                                    {/* |_| \_\\___/  \_/\_/  |____/  */}
                                    <TableBody>
                                        {Object.entries(displayData).length >
                                        0 ? (
                                            <>
                                                {HEADER.map((head) => (
                                                    <TableRow key={head.id}>
                                                        <Tooltip
                                                            title={
                                                                head.tooltip ??
                                                                ""
                                                            }
                                                        >
                                                            <TableHeaderCell
                                                                style={
                                                                    isMobile
                                                                        ? {
                                                                              position:
                                                                                  "sticky",
                                                                              left: 0,
                                                                              zIndex: 1,
                                                                              backgroundColor:
                                                                                  Colours.plainWhite,
                                                                          }
                                                                        : {}
                                                                }
                                                            >
                                                                {head.label}
                                                            </TableHeaderCell>
                                                        </Tooltip>
                                                        {Object.keys(
                                                            displayData
                                                        )
                                                            .reverse()
                                                            .map((year) => (
                                                                <TableBodyCell
                                                                    key={year}
                                                                >
                                                                    {fns(
                                                                        hotelID,
                                                                        displayData[
                                                                            year
                                                                        ][
                                                                            head
                                                                                .id
                                                                        ],
                                                                        head.format
                                                                    )}

                                                                    {CalcPercentage(
                                                                        year,
                                                                        displayData,
                                                                        head.id,
                                                                        fullYear
                                                                            ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                                            : `Comparing ${new Date().getUTCFullYear()}-${
                                                                                  months[
                                                                                      time.getUTCMonth()
                                                                                  ]
                                                                              } vs ${year}-${
                                                                                  months[
                                                                                      time.getUTCMonth()
                                                                                  ]
                                                                              }`,
                                                                        (
                                                                            number
                                                                        ) =>
                                                                            fns(
                                                                                hotelID,
                                                                                number,
                                                                                head.format
                                                                            )
                                                                    )}
                                                                </TableBodyCell>
                                                            ))}
                                                    </TableRow>
                                                ))}
                                            </>
                                        ) : selectedDataSource.status ===
                                          "loaded" ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={100}
                                                    align="center"
                                                >
                                                    <Box
                                                        component="span"
                                                        fontStyle="italic"
                                                    >
                                                        No data
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <>
                                                {HEADER.map((head) => (
                                                    <TableRow key={head.id}>
                                                        <Tooltip
                                                            title={
                                                                head.tooltip ??
                                                                ""
                                                            }
                                                        >
                                                            <TableHeaderCell
                                                                sx={{
                                                                    maxWidth:
                                                                        "40px",
                                                                }}
                                                            >
                                                                {head.label}
                                                            </TableHeaderCell>
                                                        </Tooltip>
                                                        {getYearsBefore().map(
                                                            (f) => (
                                                                <TableCell
                                                                    key={f}
                                                                >
                                                                    <Skeleton />
                                                                </TableCell>
                                                            )
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </WrapperPDFTable>
                        </TableContainer>
                    )}
                </>
                {/* <br /> */}
            </Container>
            {mode === TABULAR_MODE && (
                <MonthlyRoomsCardsReport
                    TITLE={TITLE}
                    data={monthsData}
                    dataYears={dataByYear}
                    fullYear={fullYear}
                    time={time}
                />
            )}
        </Stack>
    );
};

export default RoomsAndVouchersRevenueReport;
