import React, { useEffect, useState } from "react";
import Axios from "axios";

import {
    Autocomplete,
    Box,
    Divider,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    keyframes,
    styled,
} from "@mui/material";
import { HOTELS_INFO, URLAPI } from "../configuration";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import { useSelector } from "react-redux";

const HeadTableCell = styled(TableCell)({
    fontWeight: "bold",
    fontSize: "1.1em",
});
const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const EmailHotelList = ({ reload, users }) => {
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);

    const [reload_, setReload_] = useState(0);
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(null);
    const [newEmail, setNewEmail] = useState("");

    useEffect(() => {
        console.log({ users });
        Axios.get(`${URLAPI}/email/hotels`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        }).then((response) => {
            setEmails(response.data.data);
            setLoading(false);
        });
    }, [reload, reload_]);

    const [hotelsAro, setHotelsAro] = useState({});
    const [hotelsNotAro, setHotelsNotAro] = useState({});
    useEffect(() => {
        let hotels_count_aro = {};
        let hotels_count_not_aro = {};

        users.forEach((user) => {
            const { email_hotel, is_aro } = user;

            if (email_hotel && Array.isArray(email_hotel)) {
                email_hotel.forEach((h) => {
                    if (is_aro) {
                        hotels_count_aro[h] = (hotels_count_aro[h] || 0) + 1;
                    } else {
                        hotels_count_not_aro[h] =
                            (hotels_count_not_aro[h] || 0) + 1;
                    }
                });
            }
        });
        setHotelsAro(hotels_count_aro);
        setHotelsNotAro(hotels_count_not_aro);
    }, [users]);

    const [alphabetic, setAlphabetic] = useState(true);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <HeadTableCell
                            onClick={() => setAlphabetic(!alphabetic)}
                        >
                            Hotel
                        </HeadTableCell>
                        <HeadTableCell>Reports</HeadTableCell>
                        <HeadTableCell>🙍‍♂️Not Aró</HeadTableCell>
                        <HeadTableCell>🙍‍♂️Aró</HeadTableCell>
                        <HeadTableCell></HeadTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(HOTELS_INFO)
                        .filter((h) => String(h.id) !== "default")
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((e) => (
                            <TableRow
                                key={e.name + e.id} // Add a unique key to each table row
                                sx={{
                                    background:
                                        String(e.id) === String(hotelID)
                                            ? Colours.secondarySoft
                                            : null,
                                }}
                            >
                                <TableCell>{e.name}</TableCell>
                                <TableCell>
                                    {emails
                                        .filter(
                                            (email) => email.hotel_id === e.id
                                        )
                                        .map((email) => (
                                            <Typography
                                                fontSize="0.8em"
                                                key={email.email_type}
                                            >
                                                {email.email_type}
                                            </Typography>
                                        ))}
                                </TableCell>
                                <TableCell>{hotelsNotAro[e.id]}</TableCell>
                                <TableCell>{hotelsAro[e.id]}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => setOpenModal(e)}>
                                        <Icons.Edit />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <Modal
                open={openModal ?? false}
                onClose={() => setOpenModal(null)}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {openModal ? (
                    <Box
                        component={Paper}
                        sx={{
                            p: 5,
                            width: 400,
                        }}
                    >
                        <Typography variant="h6">
                            {openModal.name ?? ""}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        {emails.filter(
                            (email) => email.hotel_id === openModal.id
                        ).length === 0 && (
                            <Typography fontSize={"0.8em"} fontStyle="italic">
                                No entries
                            </Typography>
                        )}
                        {emails
                            .filter((email) => email.hotel_id === openModal.id)
                            .map((email) => (
                                <Box
                                    key={email.email_type}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb={1}
                                >
                                    <Typography>{email.email_type}</Typography>
                                    <IconButton
                                        // color="error"
                                        onClick={() => {
                                            setLoading(true);
                                            Axios.delete(
                                                `${URLAPI}/email/hotels`,
                                                {
                                                    data: {
                                                        hotel_id: openModal.id,
                                                        email_type:
                                                            email.email_type,
                                                    },
                                                    headers: {
                                                        "Content-Type":
                                                            "application/json",
                                                        Authorization: `Bearer ${auth.user.jwt}`,
                                                    },
                                                }
                                            ).then(() => {
                                                setLoading(false);
                                                setReload_(
                                                    (reload_) => reload_ + 1
                                                );
                                            });
                                        }}
                                    >
                                        <Icons.Delete />
                                    </IconButton>
                                </Box>
                            ))}
                        <Divider sx={{ my: 2 }} />
                        <Box mt={2}>
                            <Typography variant="subtitle1">
                                Add new email type
                            </Typography>
                            <Box display="flex" alignItems="center" mt={1}>
                                <Autocomplete
                                    freeSolo
                                    options={[
                                        ...new Set(
                                            emails.map(
                                                (email) => email.email_type
                                            )
                                        ),
                                    ]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Email type"
                                            variant="outlined"
                                            fullWidth
                                            value={newEmail}
                                            onChange={(e) => {
                                                setNewEmail(
                                                    e.target.value.trim()
                                                );
                                            }}
                                        />
                                    )}
                                    onInputChange={(event, newInputValue) => {
                                        setNewEmail(newInputValue.trim());
                                    }}
                                    style={{ flex: 1, marginRight: 8 }}
                                />
                                {loading ? (
                                    <Icons.Refresh
                                        sx={{
                                            animation: loading
                                                ? `${rotate} 2s linear infinite`
                                                : "none",
                                        }}
                                    />
                                ) : (
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setLoading(true);
                                            if (newEmail.trim() !== "") {
                                                Axios.post(
                                                    `${URLAPI}/email/hotels`,
                                                    {
                                                        hotel_id: openModal.id,
                                                        email_type:
                                                            newEmail.trim(),
                                                    },
                                                    {
                                                        headers: {
                                                            "Content-Type":
                                                                "application/json",
                                                            Authorization: `Bearer ${auth.user.jwt}`,
                                                        },
                                                    }
                                                )
                                                    .then(() => {
                                                        setNewEmail("");
                                                        setReload_(
                                                            (reload_) =>
                                                                reload_ + 1
                                                        );
                                                        setLoading(false);
                                                    })
                                                    .catch((error) => {
                                                        console.log({ error });
                                                        setLoading(false);
                                                    });
                                            }
                                        }}
                                    >
                                        <Icons.Add />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
            </Modal>
        </>
    );
};
export default EmailHotelList;
