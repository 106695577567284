import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Container,
    Grid,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import ErrorIcon from "../ErrorIcon";
import InfoIcon from "../InfoIcon";
import DateSelector from "../DateSelector";
import { TABULAR_MODE } from "../SwitchVisualTable";
import OptionsButtonDialog from "../OptionsButtonDialog";
import TabSelector from "../TabSelector";
import TrendChart from "../TrendChart";

import { getYearsBefore, months } from "../../helpers/dates";
import { process, processMonth } from "../../helpers/reports";
import { fns } from "../../helpers/common";
import { reservationsToYearMonth } from "../DataLoader";

const ArrivalRevenueSummary = ({ hotelID, cardMode = "Total" }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [mode, setMode] = useState(TABULAR_MODE);

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(true);
    const { time: sharedTime, fullYear: sharedFullYear } = useSelector(
        (state) => state.settings.settings
    );

    const [connectionError, setConnectionError] = useState(false);

    const { dataBookings } = useSelector((state) => state);

    const TITLE = "Checked Out Revenue Summary";
    const REPORT_DESC =
        "Shows a summary of the checked out revenue for time period selected.";

    const [dataLoaded, setDataLoaded] = useState(false);

    const [selectedData, setSelectedData] = useState({});
    const [selectedDataPrev, setSelectedDataPrev] = useState({});

    const [clickedCard, setClickedCard] = useState("");

    const HEADER = [
        {
            id: "bookings",
            title: "Bookings",
            expand: true,
        },
        {
            id: "roomNights",
            title: "Room Nights",
            expand: true,
        },
        {
            id: "revenue",
            title: "Revenue",
            format: { left: "currency" },
            expand: true,
        },
        {
            id: "ADR",
            title: "ADR",
            desc: "Average Daily Rate",
            format: { left: "currency" },
            expand: true,
        },
    ];

    const [dataByYear, setDataByYear] = useState({});
    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});
    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const setMonthsData = [
        setDataByJanuary,
        setDataByFebruary,
        setDataByMarch,
        setDataByApril,
        setDataByMay,
        setDataByJune,
        setDataByJuly,
        setDataByAugust,
        setDataBySeptember,
        setDataByOctober,
        setDataByNovember,
        setDataByDecember,
    ];

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    // Gets non-empty data(at least one key not 0)
    const getValidData = (unfilteredData) => {
        let validData = {};
        Object.entries(unfilteredData).map(([yearKey, data]) => {
            if (
                !Object.keys(data).every((key) =>
                    key === "Details" ? true : data[key] === 0
                )
            ) {
                return (validData[yearKey] = data);
            }
        });
        return validData;
    };

    //

    useEffect(() => {
        if (dataBookings.status === "error") setConnectionError(true);
        if (dataBookings.status === "loaded") {
            let years = getYearsBefore();

            let aux_data = reservationsToYearMonth(
                dataBookings.data,
                "CheckIn"
            );
            months.forEach((month, index) => {
                let auxMonthData = Object.assign(
                    {},
                    ...years.map((year) => ({
                        [year]: processMonth(
                            ((aux_data[`${year}`] ?? {})[month] ?? []).filter(
                                (element) =>
                                    ["Special Offer"].includes(element.Type)
                            ),
                            "CheckOut",
                            year,
                            index
                        ),
                    }))
                );
                setMonthsData[index](auxMonthData);
            });

            let auxYearData = Object.assign(
                {},
                ...years.map((year) => ({
                    [year]: process(
                        Object.values(aux_data[`${year}`] ?? {})
                            .flat(1)
                            .filter((element) =>
                                ["Special Offer"].includes(element.Type)
                            )
                    ),
                }))
            );
            setDataByYear(getValidData(auxYearData));
            setDataLoaded(true);
        } else setDataLoaded(false);
    }, [dataBookings]);

    const getFullKey = () => {
        return `${clickedCard}${cardMode === "Total" ? cardMode : "Cancelled"}`;
    };
    // useEffect(() => {
    //     setDataLoaded(false);

    //     if (monthsData) {
    //         setDataLoaded(true);
    //     }
    // }, [monthsData]);

    // useEffect(() => {
    //     setDataLoaded(false);

    //     if (dataByYear) {
    //         // setDataByYear(dataByYear);
    //         setDataLoaded(true);
    //     }
    // }, [dataByYear]);

    useEffect(() => {
        let auxSelectedData = {};
        let auxSelectedDataPrev = {};

        if (time) {
            if (fullYear) {
                if (
                    Object.keys(dataByYear).length > 0 &&
                    dataByYear[`${time.getUTCFullYear()}`] &&
                    dataByYear[`${time.getUTCFullYear() - 1}`]
                ) {
                    auxSelectedData = dataByYear[`${time.getUTCFullYear()}`];
                    auxSelectedDataPrev =
                        dataByYear[`${time.getUTCFullYear() - 1}`] ?? {};
                }
            } else if (
                monthsData[time.getUTCMonth()] &&
                monthsData[time.getUTCMonth()][`${time.getUTCFullYear()}`] &&
                monthsData[time.getUTCMonth()][`${time.getUTCFullYear() - 1}`]
            ) {
                auxSelectedData =
                    monthsData[time.getUTCMonth()][`${time.getUTCFullYear()}`];

                auxSelectedDataPrev =
                    monthsData[time.getUTCMonth()][
                        `${time.getUTCFullYear() - 1}`
                    ];
            }
        }
        setSelectedData(auxSelectedData);
        setSelectedDataPrev(auxSelectedDataPrev);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, cardMode, dataByYear, ...monthsData]);

    useEffect(() => {
        // Now when any one is loaded, it counts as loaded, so if there is some missing data, it still shows
        if (!dataLoaded) {
            let aux =
                monthsData.some((month) => Object.keys(month).length > 0) ||
                Object.keys(dataByYear).length > 0;
            setDataLoaded(aux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData, clickedCard, cardMode, dataByYear, ...monthsData]);

    const formatLabelValue = (value) => {
        let format = {
            maxDecimals:
                (HEADER.find((head) => head.id === clickedCard).format ?? {})
                    .maxDecimals ?? 0,
        };
        return fns(hotelID, value, format);
    };

    return (
        <Stack direction={"column"} spacing={0.1}>
            <Container maxWidth={false} component={HoverPaper}>
                <br />
                {/*  ___ _   _ ___ _____  */}
                {/* |_ _| \ | |_ _|_   _| */}
                {/*  | ||  \| || |  | |   */}
                {/*  | || |\  || |  | |   */}
                {/* |___|_| \_|___| |_|   */}
                <Stack
                    direction={{ md: "column", sm: "row", xs: "row" }}
                    justifyContent={isMobile ? "space-around" : "space-between"}
                    alignItems="flex-start"
                >
                    {/* Title */}
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <Stack alignItems="center">
                            <Typography fontWeight={"bold"}>{TITLE}</Typography>
                        </Stack>
                        <InfoIcon text={REPORT_DESC} />
                        <ErrorIcon show={connectionError} />
                    </Stack>

                    {isMobile ? (
                        <OptionsButtonDialog>
                            {/* <SwitchVisualTable
                                collapse={isMobile}
                                mode={mode}
                                setMode={setMode}
                                modes={[TABULAR_MODE]}
                            /> */}
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                            />
                            {""}
                        </OptionsButtonDialog>
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            {/* <SwitchVisualTable
                                mode={mode}
                                setMode={setMode}
                                modes={[TABULAR_MODE]}
                            /> */}
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                day={false}
                            />
                        </Stack>
                    )}
                </Stack>

                <br />

                {!dataLoaded ? (
                    <>
                        <br /> <ProgressBarSteps />
                    </>
                ) : (
                    ""
                )}
            </Container>
            {mode === TABULAR_MODE && (
                <Grid
                    container
                    alignItems="stretch"
                    spacing={0}
                    justifyContent="left"
                >
                    <TabSelector
                        hotelID={hotelID}
                        data={HEADER.map((head) => {
                            let auxHead = Object.assign({}, head);
                            const fullKey = `${head.id}${
                                cardMode === "Total" ? "" : "Cancelled"
                            }`;
                            auxHead.value = (selectedData ?? {})[fullKey] ?? 0;
                            // console.log({
                            //     v: auxHead.value,
                            //     selectedData,
                            //     fullKey,
                            // });
                            if (Object.keys(selectedDataPrev).length > 0) {
                                auxHead.compare = true;
                                auxHead.old =
                                    (selectedDataPrev ?? {})[fullKey] ?? 0;
                            } else {
                                auxHead.compare = false;
                                auxHead.noArrow = true;
                            }
                            return auxHead;
                        })}
                        cardSelected={clickedCard}
                        setCardSelected={setClickedCard}
                    />
                    {
                        //   ____                 _
                        //  / ___|_ __ __ _ _ __ | |__
                        // | |  _| '__/ _` | '_ \| '_ \
                        // | |_| | | | (_| | |_) | | | |
                        //  \____|_|  \__,_| .__/|_| |_|
                        //                 |_|
                    }
                    {clickedCard && !isMobile ? (
                        dataLoaded ? (
                            fullYear ? (
                                <TrendChart
                                    TITLE={
                                        TITLE +
                                            " - " +
                                            HEADER.find(
                                                (head) =>
                                                    head.id === clickedCard
                                            ).title ?? ""
                                    }
                                    key={"yearChart"}
                                    data={Object.keys(dataByYear).reduce(
                                        (accYear, year) => {
                                            accYear[year] = Object.keys(
                                                monthsData
                                            ).reduce((accMonth, month) => {
                                                accMonth[months[month]] =
                                                    (monthsData[month][year] ??
                                                        {})[getFullKey()] ?? 0;
                                                return accMonth;
                                            }, {});
                                            return accYear;
                                        },
                                        {}
                                    )}
                                    selected={Object.assign(
                                        {},
                                        ...Object.keys(dataByYear).map(
                                            (key) => ({
                                                [key]:
                                                    key ==
                                                    time.getUTCFullYear(),
                                            })
                                        )
                                    )}
                                    markPoints={{
                                        min: {
                                            show: true,
                                            formatter: formatLabelValue,
                                        },
                                        max: {
                                            show: true,
                                            formatter: formatLabelValue,
                                        },
                                    }}
                                    markLine={{
                                        show: true,
                                        formatter: formatLabelValue,
                                    }}
                                />
                            ) : (
                                <TrendChart
                                    TITLE={
                                        TITLE +
                                            " - " +
                                            HEADER.find(
                                                (head) =>
                                                    head.id === clickedCard
                                            ).title ?? ""
                                    }
                                    key={"monthChart"}
                                    data={{
                                        [`${
                                            months[time.getUTCMonth()]
                                        } ${time.getUTCFullYear()}`]:
                                            Object.keys(
                                                monthsData[time.getUTCMonth()][
                                                    time.getUTCFullYear()
                                                ].Details
                                            ).reduce((acc, day) => {
                                                acc[day] =
                                                    monthsData[
                                                        time.getUTCMonth()
                                                    ][
                                                        time.getUTCFullYear()
                                                    ].Details[day][
                                                        getFullKey()
                                                    ];
                                                return acc;
                                            }, {}),
                                    }}
                                    markPoints={{
                                        min: {
                                            show: true,
                                            formatter: formatLabelValue,
                                        },
                                        max: {
                                            show: true,
                                            formatter: formatLabelValue,
                                        },
                                    }}
                                    markLine={{
                                        show: true,
                                        formatter: formatLabelValue,
                                    }}
                                />
                            )
                        ) : (
                            <>
                                <Skeleton variant="rounded" height={300} />
                                <br />
                            </>
                        )
                    ) : (
                        ""
                    )}
                </Grid>
            )}
        </Stack>
    );
};

export default ArrivalRevenueSummary;
