import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import InsightAssistHeader from "./InsightAssistHeader";
import InsightAssistRow from "./InsightAssistRow";
import InsightAssistRowDesktopLoading from "./InsightAssistRowDesktopLoading";
import DataLoader from "./DataLoader";
import ProgressBarSteps from "./shared/ProgressBarSteps";

const RedAlerts = ({ hotelID, hideWeights = false }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { dataSimple } = useSelector((state) => state);

    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setSelected([]);
        if (
            dataSimple.status === "error" ||
            !dataSimple.data.hasOwnProperty("Indicators")
        ) {
            return;
        }

        const { Indicators } = dataSimple.data;
        if (Indicators) {
            const RAList = Indicators.filter((element) => element.RA);
            setSelected(RAList);
            setLoading(false);
        }
    }, [dataSimple]);

    return (
        <>
            <DataLoader SimpleData />
            <Typography
                sx={{
                    pl: { xs: 2 },
                    pr: { xs: 2 },
                }}
            >
                <Box fontWeight={"bold"} component="span">
                    Red Alerts{" "}
                </Box>{" "}
                - Highlights to improve
            </Typography>

            {loading ? (
                <>
                    {" "}
                    <br />
                    <ProgressBarSteps /> <br />
                </>
            ) : (
                <></>
            )}
            <Table>
                <InsightAssistHeader hideWeights={hideWeights} />
                <TableBody>
                    {loading ? (
                        <InsightAssistRowDesktopLoading
                            times={3}
                            hideWeights={hideWeights}
                        />
                    ) : (
                        <>
                            {selected.map((n) => (
                                <InsightAssistRow
                                    isMobile={isMobile}
                                    indicatorName={n.indicator_id}
                                    indicatorNameFormat={n.indicator_name}
                                    growthTrackName={n.growth_track}
                                    weighting={
                                        n.weighting ?? n.default_weighting
                                    }
                                    currentScore={n.indicator_score}
                                    previousScore={n.indicator_score_old}
                                    whatV={n.what_v ?? n.default_what ?? ""}
                                    whyV={n.why_v ?? n.default_why ?? ""}
                                    howV={n.how_v ?? n.default_how ?? ""}
                                    priority={n.priority ?? n.default_priority}
                                    // status={n.status}
                                    status={"Under review"}
                                    key={n.indicator_id}
                                    hideWeights={hideWeights}
                                />
                            ))}
                            {selected.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={100} align="center">
                                        <Box
                                            component="span"
                                            fontStyle="italic"
                                        >
                                            No data
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default RedAlerts;
