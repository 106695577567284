import React, { useEffect, useState } from "react";

import EChartsReact from "echarts-for-react";
import {
    Container,
    Paper,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import InfoIcon from "../InfoIcon";
import TableBodyCell from "../TableBodyCell";

import { fns } from "../../helpers/common";
import Colours from "../../helpers/colours";

const AllSearchesLeadTimeGraph = ({
    data,
    title,
    header,
    hotelID,
    dataType,
}) => {
    const [clicked, setClicked] = useState(false);
    const [procDetails, setProcDetails] = useState([]);

    const [sessions, setSessions] = useState(0);
    const [searches, setSearches] = useState(0);
    const [noAvSessions, setNoAvSessions] = useState(0);
    const [noAvSearches, setNoAvSearches] = useState(0);
    const [lostRevenue, setLostRevenue] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [bookings, setBookings] = useState(0);
    const [roomNights, setRoomNights] = useState(0);
    const [bedNights, setBedNights] = useState(0);
    // const [averageLoS, setAverageLos] = useState(0);
    // const [averageLeadTime, setAverageLeadTime] = useState(0);

    const formatValueOrDate = (prop, value) => {
        // console.log(`${prop} - ${value}`);

        let leftUnit = (header.find((e) => e.id === prop) ?? {}).leftUnit ?? "";
        return `${leftUnit}${
            prop === "searchDate" || prop === "availabilityDate"
                ? new Date(value).toISOString().slice(0, 10)
                : fns(
                      hotelID,
                      value,
                      (header.find((e) => e.id === prop) ?? {}).format ?? {}
                  )
        }`;
    };

    // useEffect(() => {
    //     console.log(header);
    // }, [header]);

    useEffect(() => {
        let auxProcDetails = {};

        let auxTotalSessions = 0;
        let auxTotalSearches = 0;
        let auxNoAvSessions = 0;
        let auxNoAvSearches = 0;
        let auxLostRevenue = 0;
        let auxRevenue = 0;
        let auxBookings = 0;
        let auxRoomNights = 0;
        let auxBedNights = 0;
        // let auxLoS = 0;
        // let auxLeadTime = 0;

        let offerTitle = title;

        // console.log(data[0]);

        let uniqueSessionIDs = new Set();
        let uniqueNoAvSessionIDs = new Set();

        data.forEach((element) => {
            let {
                // searchDate,
                sessionID,
                searchRequestID,
                noAvSessionID,
                noAvSearchRequestID,
                lostRevenue,
                revenue,
                bookings,
                roomNights,
                bedNights,
                LoS,
                leadTime,
            } = element;

            // console.log(element);

            if (leadTime <= 2) {
                leadTime = "0 - 2";
            } else if (leadTime > 2 && leadTime <= 4) {
                leadTime = "3 - 4";
            } else if (leadTime > 4 && leadTime <= 7) {
                leadTime = "5 - 7";
            } else if (leadTime > 7 && leadTime <= 14) {
                leadTime = "8 - 14";
            } else if (leadTime > 14 && leadTime <= 30) {
                leadTime = "15 - 30";
            } else if (leadTime > 30 && leadTime <= 60) {
                leadTime = "31 - 60";
            } else if (leadTime > 60 && leadTime <= 90) {
                leadTime = "61 - 90";
            } else if (leadTime >= 91) {
                leadTime = "90 +";
            }

            if (!auxProcDetails.hasOwnProperty(leadTime)) {
                auxProcDetails[leadTime] = {
                    searches: 0,
                    noAvSearches: 0,
                    sessions: 0,
                    noAvSessions: 0,
                    lostRevenue: 0,
                    revenue: 0,
                    bookings: 0,
                    roomNights: 0,
                    bedNights: 0,
                    // averageLoS: 0,
                    // averageLeadTime: 0,
                };
            }

            // Filter unique sessions
            if (sessionID !== null && !uniqueSessionIDs.has(sessionID)) {
                auxTotalSessions += 1;
                auxProcDetails[leadTime].sessions += 1;
                uniqueSessionIDs.add(sessionID); // Add the session ID to the set to mark it as seen
            }

            if (searchRequestID !== null) {
                auxTotalSearches += 1;
                auxProcDetails[leadTime].searches += 1;
            }

            if (
                noAvSessionID !== null &&
                !uniqueNoAvSessionIDs.has(noAvSessionID)
            ) {
                auxNoAvSessions += 1;
                auxProcDetails[leadTime].noAvSessions += 1;
                uniqueNoAvSessionIDs.add(noAvSessionID); // Add the session ID to the set to mark it as seen
            }

            if (noAvSearchRequestID !== null) {
                auxNoAvSearches += 1;
                auxProcDetails[leadTime].noAvSearches += 1;
            }

            if (lostRevenue !== null) {
                auxLostRevenue += lostRevenue;
                auxProcDetails[leadTime].lostRevenue += lostRevenue;
            }

            if (revenue !== null) {
                auxRevenue += revenue;
                auxProcDetails[leadTime].revenue += revenue;
            }

            if (bookings !== null) {
                auxBookings += 1;
                auxProcDetails[leadTime].bookings += 1;
            }

            if (roomNights !== null) {
                auxRoomNights += roomNights;
                auxProcDetails[leadTime].roomNights += roomNights;
            }

            if (bedNights !== null) {
                auxBedNights += bedNights;
                auxProcDetails[leadTime].bedNights += bedNights;
            }
        });

        setSessions(auxTotalSessions);
        setSearches(auxTotalSearches);
        setNoAvSessions(auxNoAvSessions);
        setNoAvSearches(auxNoAvSearches);
        setLostRevenue(auxLostRevenue);
        setRevenue(auxRevenue);
        setBookings(auxBookings);
        setRoomNights(auxRoomNights);
        setBedNights(auxBedNights);
        // setAverageLos();
        // setAverageLeadTime();

        const procDetailsArray = Object.entries(auxProcDetails).map(
            ([
                leadTime,
                {
                    searches,
                    noAvSearches,
                    sessions,
                    noAvSessions,
                    lostRevenue,
                    revenue,
                    bookings,
                    roomNights,
                    bedNights,
                },
            ]) => ({
                leadTime,
                searches,
                noAvSearches,
                sessions,
                noAvSessions,
                lostRevenue,
                revenue,
                bookings,
                roomNights,
                bedNights,

                // bookingsPercentage: (bookings / totalBookings) * 100,
                // searchesPercentage: (searches / totalSearches) * 100,
                // noAvailabilitySearchesPercentage:
                //     (noAvailabilitySearches / totalNoAvailabilitySearches) *
                //     100,
                // roomRevenuePercentage: (roomRevenue / totalRoomRevenue) * 100,
                // lostRevenuePercentage: (lostRevenue / totalLostRevenue) * 100,
            })
        );

        // console.log(procDetailsArray);

        const leadTimeOrder = [
            "0 - 2",
            "3 - 4",
            "5 - 7",
            "8 - 14",
            "15 - 30",
            "31 - 60",
            "61 - 90",
            "90 +",
        ];

        const sortedProcDetailsArray = procDetailsArray.sort(
            (a, b) =>
                leadTimeOrder.indexOf(a.leadTime) -
                leadTimeOrder.indexOf(b.leadTime)
        );

        setProcDetails(sortedProcDetailsArray);

        // console.log(procDetails);
    }, [data, clicked]);

    return (
        <>
            {dataType === "Bookings" ? (
                <TableRow
                    key={title}
                    onClick={() => setClicked(!clicked)}
                    style={{
                        cursor: "pointer",
                        backgroundColor: clicked
                            ? Colours.secondary
                            : Colours.selectedRow,
                    }}
                >
                    <TableBodyCell>{title}</TableBodyCell>
                    <TableBodyCell>{bookings}</TableBodyCell>
                    <TableBodyCell>{roomNights}</TableBodyCell>
                    <TableBodyCell>{bedNights}</TableBodyCell>
                    {/* <TableBodyCell>{0}</TableBodyCell>
                    <TableBodyCell>{0}</TableBodyCell> */}
                </TableRow>
            ) : dataType === "Searches" ? (
                <TableRow
                    key={title}
                    onClick={() => setClicked(!clicked)}
                    style={{
                        cursor: "pointer",
                        backgroundColor: clicked
                            ? Colours.secondary
                            : Colours.selectedRow,
                    }}
                >
                    <TableBodyCell>{title}</TableBodyCell>
                    <TableBodyCell>{searches}</TableBodyCell>
                    <TableBodyCell>{noAvSearches}</TableBodyCell>
                    <TableBodyCell>{sessions}</TableBodyCell>
                    <TableBodyCell>{noAvSessions}</TableBodyCell>
                </TableRow>
            ) : dataType === "Revenue" ? (
                <TableRow
                    key={title}
                    onClick={() => setClicked(!clicked)}
                    style={{
                        cursor: "pointer",
                        backgroundColor: clicked
                            ? Colours.secondary
                            : Colours.selectedRow,
                    }}
                >
                    <TableBodyCell>{title}</TableBodyCell>
                    <TableBodyCell>
                        {formatValueOrDate("lostRevenue", lostRevenue)}
                    </TableBodyCell>
                    <TableBodyCell>
                        {formatValueOrDate("revenue", revenue)}
                    </TableBodyCell>
                </TableRow>
            ) : (
                <></>
            )}
            {clicked && (
                <>
                    <TableRow
                        style={{
                            cursor: "pointer",
                            backgroundColor: clicked ? Colours.secondary : "",
                        }}
                    >
                        <TableCell
                            colSpan={6}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Container component={Paper} sx={{ pt: 2, pb: 2 }}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        justifyContent={"center"}
                                    >
                                        <Typography fontWeight={"bold"}>
                                            {dataType === "Searches"
                                                ? "Lead Time Distribution Sessions"
                                                : dataType === "Revenue"
                                                ? "Lead Time Distribution Revenue"
                                                : "Lead Time Distribution Bookings"}
                                        </Typography>
                                        <InfoIcon
                                            text={
                                                dataType === "Searches"
                                                    ? "Distribution of Searches & Sessions for a range of LeadTimes for a given date"
                                                    : dataType === "Revenue"
                                                    ? "Distribution of Revenue for a range of LeadTimes for a given date"
                                                    : "Distribution of Bookings, Room Nights & Bed Nights for a range of LeadTimes for a given date"
                                            }
                                        />
                                        {/* <ErrorIcon show={connectionError} /> */}
                                    </Stack>
                                    {/* <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <SwitchIcons
                                            selectedMode={graphMode}
                                            modes={[BAR_MODE, LINE_MODE]}
                                            setGraphMode={setGraphMode}
                                        />
                                    </Stack> */}
                                </Stack>
                                <br />
                                <WrapperPDFEChart k={title}>
                                    <EChartsReact
                                        option={{
                                            xAxis: {
                                                type: "category",
                                                data: procDetails.map(
                                                    (entry) => entry.leadTime
                                                ),
                                                axisLabel: {
                                                    rotate: 45,
                                                },
                                            },
                                            yAxis: {
                                                type: "value",
                                                axisLabel: {
                                                    formatter: "{value}",
                                                },
                                            },
                                            legend: {
                                                data:
                                                    dataType === "Bookings"
                                                        ? [
                                                              "Bookings",
                                                              "Room Nights",
                                                              "Bed Nights",
                                                          ]
                                                        : dataType ===
                                                          "Searches"
                                                        ? [
                                                              "Total Searches",
                                                              "No Av. Searches",
                                                              "Total Sessions",
                                                              "No Av. Sessions",
                                                          ]
                                                        : [
                                                              "Lost Revenue",
                                                              "Revenue",
                                                          ],
                                            },
                                            tooltip: {
                                                trigger: "axis",
                                                axisPointer: {
                                                    type: "shadow",
                                                },
                                            },
                                            // dataZoom: [
                                            //     {
                                            //         type: "inside",
                                            //         startValue: 0,
                                            //         endValue: 14,
                                            //     },
                                            //     {
                                            //         start: 0,
                                            //         end: 14,
                                            //     },
                                            // ],
                                            series:
                                                dataType === "Bookings"
                                                    ? [
                                                          {
                                                              name: "Bookings",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.bookings,
                                                                      label: {
                                                                          show:
                                                                              entry.bookings >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.bookings
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                          {
                                                              name: "Room Nights",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.roomNights,
                                                                      label: {
                                                                          show:
                                                                              entry.roomNights >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.roomNights
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                          {
                                                              name: "Bed Nights",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.bedNights,
                                                                      label: {
                                                                          show:
                                                                              entry.bedNights >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.bedNights
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                      ]
                                                    : dataType === "Searches"
                                                    ? [
                                                          {
                                                              name: "Total Searches",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.searches,
                                                                      label: {
                                                                          show:
                                                                              entry.searches >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.searches
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                          {
                                                              name: "No Av. Searches",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.noAvSearches,
                                                                      label: {
                                                                          show:
                                                                              entry.noAvSearches >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.noAvSearches
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                          {
                                                              name: "Total Sessions",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.sessions,
                                                                      label: {
                                                                          show:
                                                                              entry.sessions >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.sessions
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                          {
                                                              name: "No Av. Sessions",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.noAvSessions,
                                                                      label: {
                                                                          show:
                                                                              entry.noAvSessions >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.noAvSessions
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                      ]
                                                    : [
                                                          {
                                                              name: "Revenue",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.revenue,
                                                                      label: {
                                                                          show:
                                                                              entry.revenue >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.revenue
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                          {
                                                              name: "Lost Revenue",
                                                              type: "bar",
                                                              data: procDetails.map(
                                                                  (entry) => ({
                                                                      value: entry.lostRevenue,
                                                                      label: {
                                                                          show:
                                                                              entry.lostRevenue >
                                                                              0
                                                                                  ? true
                                                                                  : false,
                                                                          position:
                                                                              "inside",
                                                                          formatter: `${Math.round(
                                                                              entry.lostRevenue
                                                                          )}`,
                                                                      },
                                                                  })
                                                              ),
                                                          },
                                                      ],
                                        }}
                                        style={{ height: 300 }}
                                    />
                                </WrapperPDFEChart>
                            </Container>
                        </TableCell>
                    </TableRow>
                </>
            )}
        </>
    );
};

export default AllSearchesLeadTimeGraph;
