import React, { useState, useEffect } from "react";

import yaml from "js-yaml";
import {
    Box,
    Container,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

import Icons from "../helpers/icons";
import TableHeaderCell from "../components/TableHeaderCell";
import Colours from "../helpers/colours";

const CollapsibleRow = ({ startOpen, version, date, changes }) => {
    const [open, setOpen] = useState(startOpen);
    const [auxDate, setAuxDate] = useState("");
    useEffect(() => {
        if (typeof date === "object" && date instanceof Date) {
            setAuxDate(date.toISOString().slice(0, 10));
        } else setAuxDate(date);
    }, [date]);

    return (
        <>
            <TableRow
                sx={{
                    "& .MuiTableCell-root": {
                        fontWeight: "bold",
                    },

                    backgroundColor: open ? Colours.selectedRow : null,
                }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <Icons.ArrowUp /> : <Icons.ArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{version}</TableCell>
                <TableCell>{auxDate}</TableCell>
            </TableRow>
            {open && (
                <TableRow
                    sx={{
                        backgroundColor: open ? Colours.selectedRow : null,
                    }}
                >
                    <TableCell />
                    <TableCell colSpan={100}>
                        <Table>
                            <TableBody>
                                {changes.map(
                                    ({ category, short, long, link }) => (
                                        <TableRow
                                            sx={{
                                                "& .MuiTableCell-root": {
                                                    borderBottom: "unset",
                                                    textAlign: "left",
                                                    p: 0,
                                                },
                                            }}
                                        >
                                            <TableCell />
                                            <TableCell>
                                                {category == "added" ? (
                                                    <Icons.New />
                                                ) : category == "changed" ? (
                                                    <Icons.Change />
                                                ) : category == "bug" ? (
                                                    <Icons.Bug />
                                                ) : category ==
                                                  "improvement" ? (
                                                    <Icons.Improvement />
                                                ) : category == "deprecated" ? (
                                                    <Icons.Deprecated />
                                                ) : category == "removed" ? (
                                                    <Icons.Delete />
                                                ) : (
                                                    <></>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    {short}
                                                    {link && (
                                                        <IconButton
                                                            href={link}
                                                            component="a"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <Icons.Link
                                                                fontSize={
                                                                    "small"
                                                                }
                                                            />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box
                                                    component="span"
                                                    fontStyle="italic"
                                                >
                                                    {long}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

const ReleaseScreen = () => {
    const [data, setData] = useState({ releases: [] });

    useEffect(() => {
        fetch("/ReleaseNotes.yml")
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    throw new Error("Error fetching YAML file");
                }
            })
            .then((data) => {
                const doc = yaml.load(data);
                console.log({ doc });
                setData(doc);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <>
            <Container>
                <h1>Release Notes</h1>
                <Paper
                    component={Stack}
                    sx={{
                        p: 2,
                        pl: { xs: 6, md: 2 },
                        pr: { xs: 6, md: 2 },
                        height: "100%",
                    }}
                    justifyContent="center"
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell></TableHeaderCell>
                                <TableHeaderCell>Version</TableHeaderCell>
                                <TableHeaderCell>Date</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data.releases ?? []).map(
                                ({ version, date, changes }) => (
                                    <CollapsibleRow
                                        version={version}
                                        date={date}
                                        changes={changes}
                                    />
                                )
                            )}
                        </TableBody>
                    </Table>
                </Paper>
            </Container>
            <br />
            <br />
            <br />
        </>
    );
};
export default ReleaseScreen;
