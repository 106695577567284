import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "notistack";
import Axios from "axios";

import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Box,
    Stack,
    Container,
    Typography,
    InputBase,
    Button,
    CircularProgress,
    Tabs,
    Tab,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { TabContext, TabPanel } from "@mui/lab";

import RoundedButton from "../components/RoundedButton";
import TableCellPopover from "../components/TableCelllPopover";

import { DEFAULT_HOTEL, URLAPI } from "../configuration";

import { fns } from "../helpers/common";
import Colours from "../helpers/colours";
import { months, months_abbreviations } from "../helpers/dates";
import { nSuccess } from "../helpers/notification";
import { reloading } from "../actions/dataSimple";

import ExcelJS from "exceljs";

const HEADER = [
    {
        id: "actual",
        title: "Room Revenue",
    },
    {
        id: "before",
        style: { display: "none" },
    },
    {
        id: "target",
        title: "Target",
    },
    {
        id: "predictions",
        title: "Predictions",
    },
    {
        id: "target_manual",
        style: { display: "none" },
    },
];

const tableHeaderCellClass = {
    fontSize: "1.1em",
    fontWeight: "bold",
    textAlign: "center",
};

const tableCellClass = {
    border: "3px solid white",
    borderRadius: "10px",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: Colours.notificationCard,
    cursor: "pointer",
};

// const workbook

const TargetsScreen = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);

    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(0);

    const [editView, setEditView] = useState(false);
    const [inputValues, setInputValues] = useState({});

    const [revenueTargetTotal, setRevenueTargetTotal] = useState(0);
    const [sessionsTargetTotal, setSessionsTargetTotal] = useState(0);
    const [bookingsTargetTotal, setBookingsTargetTotal] = useState(0);

    const [value, setValue] = React.useState(0);

    const [data, setData] = useState({});
    const [rawData, setRawData] = useState([]);

    //  _                 _
    // | |   ___  __ _ __| |
    // | |__/ _ \/ _` / _` |
    // |____\___/\__,_\__,_|
    useEffect(() => {
        setLoading(true);

        // Data
        Axios({
            method: "get",
            url: `${URLAPI}/month_summary/${
                //TODO: this is also in the data loader
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            params: {
                from: `${new Date().getUTCFullYear() - 1}-01-01`,
                to: `${new Date().getUTCFullYear() + 1}-01-01`,
                dateBy: "CheckOut",
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                const {
                    data: { data },
                } = response;

                let res = {
                    Revenue: {},
                    Sessions: {},
                    Bookings: {},
                };

                const template_months = Array(12).fill(undefined);
                const createTemplateMetrics = () => ({
                    actual: [...template_months],
                    target: [...template_months],
                    target_manual: [...template_months],
                    predictions: [...template_months],
                    before: [...template_months],
                    update_date: [...template_months],
                    updater_name: [...template_months],
                    previous_target_value: [...template_months],
                });

                let years_set = new Set();

                data.forEach(({ year_v }) => {
                    years_set.add(year_v);
                });

                years_set.forEach((year) => {
                    res.Revenue[year] = createTemplateMetrics();
                    res.Sessions[year] = createTemplateMetrics();
                    res.Bookings[year] = createTemplateMetrics();
                });

                console.log(res);

                data.forEach(
                    ({
                        year_v,
                        month_v,

                        Revenue,
                        Revenue_before,
                        Revenue_predictions,
                        Revenue_target_value,
                        Revenue_target_manual,
                        Revenue_update_date,
                        Revenue_updater_name,
                        Revenue_previous_target_value,

                        Sessions,
                        Sessions_before,
                        Sessions_predictions,
                        Sessions_target_value,
                        Sessions_target_manual,
                        Sessions_update_date,
                        Sessions_updater_name,
                        Sessions_previous_target_value,

                        Bookings,
                        Bookings_before,
                        Bookings_predictions,
                        Bookings_target_value,
                        Bookings_target_manual,
                        Bookings_update_date,
                        Bookings_updater_name,
                        Bookings_previous_target_value,
                    }) => {
                        const month = month_v - 1;

                        res.Revenue[year_v].actual[month] = Revenue;
                        res.Revenue[year_v].before[month] = Revenue_before;
                        res.Revenue[year_v].predictions[month] =
                            Revenue_predictions;
                        res.Revenue[year_v].target[month] =
                            Revenue_target_value;
                        res.Revenue[year_v].target_manual[month] =
                            Revenue_target_manual;

                        res.Revenue[year_v].update_date[month] =
                            Revenue_update_date;
                        res.Revenue[year_v].updater_name[month] =
                            Revenue_updater_name;
                        res.Revenue[year_v].previous_target_value[month] =
                            Revenue_previous_target_value;

                        res.Sessions[year_v].actual[month] = Sessions;
                        res.Sessions[year_v].before[month] = Sessions_before;
                        res.Sessions[year_v].predictions[month] =
                            Sessions_predictions;
                        res.Sessions[year_v].target[month] =
                            Sessions_target_value;
                        res.Sessions[year_v].target_manual[month] =
                            Sessions_target_manual;

                        res.Sessions[year_v].update_date[month] =
                            Sessions_update_date;
                        res.Sessions[year_v].updater_name[month] =
                            Sessions_updater_name;
                        res.Sessions[year_v].previous_target_value[month] =
                            Sessions_previous_target_value;

                        res.Bookings[year_v].actual[month] = Bookings;
                        res.Bookings[year_v].before[month] = Bookings_before;
                        res.Bookings[year_v].predictions[month] =
                            Bookings_predictions;
                        res.Bookings[year_v].target[month] =
                            Bookings_target_value;
                        res.Bookings[year_v].target_manual[month] =
                            Bookings_target_manual;

                        res.Bookings[year_v].update_date[month] =
                            Bookings_update_date;
                        res.Bookings[year_v].updater_name[month] =
                            Bookings_updater_name;
                        res.Bookings[year_v].previous_target_value[month] =
                            Bookings_previous_target_value;
                    }
                );

                setRawData(data);
                setData(res);

                let revenueTargetTotal = Math.round(
                    res.Revenue[2024]["target"].reduce((a, b) => a + b, 0),
                    2
                );

                let sessionsTargetTotal = Math.round(
                    res.Sessions[2024]["target"].reduce((a, b) => a + b, 0),
                    2
                );

                let bookingsTargetTotal = Math.round(
                    res.Bookings[2024]["target"].reduce((a, b) => a + b, 0),
                    2
                );

                setRevenueTargetTotal(revenueTargetTotal);
                setSessionsTargetTotal(sessionsTargetTotal);
                setBookingsTargetTotal(bookingsTargetTotal);

                setLoading(false);

                console.log("Loaded");
            })
            .catch((e) => console.error(e));
    }, [hotelID, reload]);

    const handleTargetChange = (index, metric) => (e) => {
        const value = Number.parseInt(e.target.value);

        setInputValues((prevValues) => ({
            ...prevValues,
            [metric]: {
                ...prevValues[metric],
                [index + 1]: value,
            },
        }));

        let targets_dynamic = [...data[metric][2024]["target"]];

        if (isNaN(value)) return;

        targets_dynamic[index] = Number.parseInt(value);

        if (metric === "Revenue") {
            setRevenueTargetTotal(targets_dynamic.reduce((a, b) => a + b, 0));
        } else if (metric === "Sessions") {
            setSessionsTargetTotal(targets_dynamic.reduce((a, b) => a + b, 0));
        } else {
            setBookingsTargetTotal(targets_dynamic.reduce((a, b) => a + b, 0));
        }
    };

    //  ___
    // / __| __ ___ _____
    // \__ \/ _` \ V / -_)
    // |___/\__,_|\_/\___|

    const handleSave = () => {
        if (Object.keys(inputValues).length === 0) return;

        Object.keys(inputValues).map((metric) => {
            Object.entries(inputValues[metric]).forEach(([index, value]) => {
                let full_name = `${
                    auth.user.firstName
                } ${auth.user.lastName.charAt(0)}.`;

                try {
                    const response = Axios.post(
                        `${URLAPI}/targets/`,
                        {
                            user_name: full_name,
                            hotel_id:
                                hotelID === "default" ? DEFAULT_HOTEL : hotelID,
                            name: `${metric}`,
                            month_v: index,
                            year_v: 2024,
                            target_value: parseInt(value),
                            previous_target_value: parseInt(
                                data[metric][2024].target[index - 1]
                            ),
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${auth.user.jwt}`,
                            },
                        }
                    );

                    if (response.status === 201) {
                        console.log(index, value, "Successful");
                    } else {
                        console.log(
                            `Response received, but with unexpected status: ${response.status}`
                        );
                    }
                } catch (error) {
                    console.log(index, value, "Unsuccessful");
                }
            });
        });

        dispatch(reloading(hotelID));
        setReload(reload + 1);

        nSuccess(enqueueSnackbar, "Targets successfully inserted or updated");
        setInputValues({});
    };

    const ExportButton = ({ data }) => {
        if (data.length === 0) return;

        const exportToExcel = async () => {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Booked Room Revenue");

            // Add headers to the worksheet with wrapped text
            worksheet.addRow(["Booked Room Revenue"]).font = { size: 16 };
            worksheet.addRow([
                "Year",
                "Month",
                "Booked Room Revenue",
                "Target",
                "Booked vs Target Diff",
                "Last Years Booked",
                "Last Year vs This Year Booked Diff",
                "Sessions",
                "Target",
                "Sessions vs Target Diff",
                "Last Years Sessions",
                "Last Year vs This Year Sessions Diff",

                "Bookings",
                "Target",
                "Bookings vs Target Diff",
                "Last Years Bookings",
                "Last Year vs This Year Bookings Diff",
            ]);
            // worksheet.getRow("2").height = 40;
            worksheet.getColumn(5).width = 20;
            worksheet.getColumn(6).width = 20;
            worksheet.getColumn(7).width = 30;

            worksheet.getColumn(5).alignment = { horizontal: "right" };
            worksheet.getColumn(7).alignment = { horizontal: "right" };
            // worksheet.getRow("2").alignment = { wrapText: "true" };

            // Add data to the worksheet
            data.forEach(
                ({
                    year_v,
                    month_v,
                    Revenue,
                    Revenue_target_value,
                    Revenue_before,

                    Sessions,
                    Sessions_target_value,
                    Sessions_before,

                    Bookings,
                    Bookings_target_value,
                    Bookings_before,
                }) => {
                    worksheet.addRow([
                        year_v,
                        months[month_v - 1],
                        Revenue > 0 ? Math.round(Revenue) : "",
                        Math.round(Revenue_target_value),
                        // Calculate and format difference as percentage
                        Revenue > 0
                            ? `${Math.round(
                                  ((Revenue - Revenue_target_value) /
                                      Revenue_target_value) *
                                      100
                              )}%`
                            : "",
                        Math.round(Revenue_before),
                        // Calculate and format difference as percentage
                        Revenue > 0
                            ? `${Math.round(
                                  ((Revenue - Revenue_before) /
                                      Revenue_before) *
                                      100
                              )}%`
                            : "",

                        Sessions > 0 ? Math.round(Sessions) : "",
                        Math.round(Sessions_target_value),
                        // Calculate and format difference as percentage
                        Sessions > 0
                            ? `${Math.round(
                                  ((Sessions - Sessions_target_value) /
                                      Sessions_target_value) *
                                      100
                              )}%`
                            : "",
                        Math.round(Sessions_before),
                        // Calculate and format difference as percentage
                        Sessions > 0
                            ? `${Math.round(
                                  ((Sessions - Sessions_before) /
                                      Sessions_before) *
                                      100
                              )}%`
                            : "",

                        Bookings > 0 ? Math.round(Bookings) : "",
                        Math.round(Bookings_target_value),
                        // Calculate and format difference as percentage
                        Bookings > 0
                            ? `${Math.round(
                                  ((Bookings - Bookings_target_value) /
                                      Bookings_target_value) *
                                      100
                              )}%`
                            : "",
                        Math.round(Bookings_before),
                        // Calculate and format difference as percentage
                        Bookings > 0
                            ? `${Math.round(
                                  ((Bookings - Bookings_before) /
                                      Bookings_before) *
                                      100
                              )}%`
                            : "",
                    ]);
                }
            );

            // Save the workbook to a Blob
            const blob = await workbook.xlsx.writeBuffer();

            // Create a Blob URL and trigger a download
            const blobUrl = URL.createObjectURL(
                new Blob([blob], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = "TargetRevenue.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(blobUrl);
        };

        return <RoundedButton onClick={exportToExcel}>Export</RoundedButton>;
    };

    const formatValueOrDate = (prop, value) => {
        let leftUnit = (HEADER.find((e) => e.id === prop) ?? {}).leftUnit ?? "";
        return `${leftUnit}${
            prop === "searchDate" || prop === "availabilityDate"
                ? new Date(value).toISOString().slice(0, 10)
                : fns(
                      hotelID,
                      value,
                      (HEADER.find((e) => e.id === prop) ?? {}).format ?? {}
                  )
        }`;
    };

    const switchMetric = (metric) => {
        switch (metric) {
            case "Revenue":
                return revenueTargetTotal;
            case "Sessions":
                return sessionsTargetTotal;
            case "Bookings":
                return bookingsTargetTotal;
            default:
                break;
        }
    };

    //  _   _ ___
    // | | | |_ _|
    // | |_| || |
    //  \___/|___|
    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Targets</Typography>
                </Grid>
            </Grid>{" "}
            {/* <br /> */}
            <Container component={Paper} maxWidth={"xl"}>
                <br />

                <Grid container>
                    {/* <Grid item xs={6}>
                        <Box sx={{ fontSize: "0.8em", marginRight: "2em" }}>
                            <ButtonBase component={Link} to={"/app"}>
                                <Icons.ArrowLeft fontSize="1em" /> Back
                            </ButtonBase>
                        </Box>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            justifyContent={"flex-end"}
                            spacing={2}
                        >
                            {editView ? (
                                <>
                                    <RoundedButton
                                        onClick={() => {
                                            setInputValues({});
                                            setEditView(false);
                                        }}
                                    >
                                        Cancel
                                    </RoundedButton>

                                    <RoundedButton
                                        onClick={() => {
                                            handleSave();
                                            setEditView(false);
                                        }}
                                    >
                                        Save
                                    </RoundedButton>
                                </>
                            ) : (
                                <>
                                    {loading && <CircularProgress />}
                                    <Button
                                        variant="outlined"
                                        onClick={() => setReload(reload + 1)}
                                    >
                                        Reload
                                    </Button>

                                    <ExportButton data={rawData}></ExportButton>

                                    <RoundedButton
                                        onClick={() => {
                                            setEditView(true);
                                        }}
                                    >
                                        Edit
                                    </RoundedButton>
                                </>
                            )}
                        </Stack>
                    </Grid>
                </Grid>

                <br />

                <TabContext value={value}>
                    <Tabs
                        value={value}
                        onChange={(_, v) => {
                            // console.log(v);
                            setValue(v);
                        }}
                        aria-label="Metric tabs"
                    >
                        {Object.keys(data).map((metric) => (
                            <Tab label={metric} key={metric} />
                        ))}
                    </Tabs>

                    {Object.keys(data).length > 0 ? (
                        <>
                            {Object.keys(data).map((metric, index) => (
                                <TabPanel value={index} key={metric}>
                                    <br />

                                    <>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell
                                                                sx={
                                                                    tableHeaderCellClass
                                                                }
                                                            />

                                                            {months_abbreviations.map(
                                                                (month, _) => (
                                                                    <TableCell
                                                                        key={
                                                                            month
                                                                        }
                                                                        sx={
                                                                            tableHeaderCellClass
                                                                        }
                                                                    >
                                                                        {month}
                                                                    </TableCell>
                                                                )
                                                            )}
                                                            <TableCell
                                                                sx={{
                                                                    ...tableHeaderCellClass,
                                                                }}
                                                            >
                                                                Total
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    {editView ? (
                                                        <TableBody>
                                                            {
                                                                //    _____                  _
                                                                //   |_   _|_ _ _ _ __ _ ___| |_
                                                                //     | |/ _` | '_/ _` / -_)  _|
                                                                //     |_|\__,_|_| \__, \___|\__|
                                                                //                 |___/
                                                            }
                                                            <TableRow>
                                                                <TableCell>
                                                                    {"Target"}
                                                                </TableCell>

                                                                {data[
                                                                    `${metric}`
                                                                ][2024][
                                                                    "target"
                                                                ].map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <TableCell
                                                                            sx={{
                                                                                ...tableCellClass,
                                                                            }}
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <InputBase
                                                                                id={`${2024}-${index}-target`}
                                                                                placeholder={formatValueOrDate(
                                                                                    "target",
                                                                                    item
                                                                                )}
                                                                                size="small"
                                                                                inputProps={{
                                                                                    style: {
                                                                                        textAlign:
                                                                                            "center",
                                                                                        fontSize:
                                                                                            "1rem",
                                                                                        padding: 0,
                                                                                        height: "2em",
                                                                                    },
                                                                                }}
                                                                                onChange={handleTargetChange(
                                                                                    index,
                                                                                    metric
                                                                                )}
                                                                            ></InputBase>
                                                                        </TableCell>
                                                                    )
                                                                )}

                                                                <TableCell
                                                                    sx={{
                                                                        ...tableCellClass,
                                                                    }}
                                                                >
                                                                    {formatValueOrDate(
                                                                        "target",
                                                                        switchMetric(
                                                                            metric
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    ) : (
                                                        <TableBody>
                                                            {
                                                                //    ___                     ___
                                                                //   | _ \___  ___ _ __  ___ | _ \_____ _____ _ _ _  _ ___
                                                                //   |   / _ \/ _ \ '  \(_-< |   / -_) V / -_) ' \ || / -_)
                                                                //   |_|_\___/\___/_|_|_/__/ |_|_\___|\_/\___|_||_\_,_\___|
                                                            }
                                                            <TableRow>
                                                                <TableCell>
                                                                    {"Actual"}
                                                                </TableCell>
                                                                {data[
                                                                    `${metric}`
                                                                ][2024][
                                                                    "actual"
                                                                ].map(
                                                                    (e, i) => (
                                                                        <TableCellPopover
                                                                            key={
                                                                                "" +
                                                                                e +
                                                                                i
                                                                            }
                                                                            index={
                                                                                i
                                                                            }
                                                                            actual={
                                                                                e
                                                                            }
                                                                            old={
                                                                                data[
                                                                                    `${metric}`
                                                                                ][2024][
                                                                                    "before"
                                                                                ][
                                                                                    i
                                                                                ]
                                                                            }
                                                                            customClass={
                                                                                tableCellClass
                                                                            }
                                                                        />
                                                                    )
                                                                )}

                                                                <TableCellPopover
                                                                    index={
                                                                        "total"
                                                                    }
                                                                    actual={data[
                                                                        `${metric}`
                                                                    ][2024][
                                                                        "actual"
                                                                    ].reduce(
                                                                        (
                                                                            total,
                                                                            value
                                                                        ) =>
                                                                            total +
                                                                            value,
                                                                        0
                                                                    )}
                                                                    old={
                                                                        data[
                                                                            `${metric}`
                                                                        ][2024][
                                                                            "before"
                                                                        ].reduce(
                                                                            (
                                                                                t,
                                                                                v
                                                                            ) =>
                                                                                t +
                                                                                v,
                                                                            0
                                                                        ) ?? 0
                                                                    }
                                                                    customClass={
                                                                        tableCellClass
                                                                    }
                                                                />
                                                            </TableRow>
                                                            {
                                                                //    _____                  _
                                                                //   |_   _|_ _ _ _ __ _ ___| |_
                                                                //     | |/ _` | '_/ _` / -_)  _|
                                                                //     |_|\__,_|_| \__, \___|\__|
                                                                //                 |___/
                                                            }
                                                            <TableRow>
                                                                <TableCell>
                                                                    {"Target"}
                                                                </TableCell>

                                                                {data[
                                                                    `${metric}`
                                                                ][2024][
                                                                    "target"
                                                                ].map(
                                                                    (e, i) => (
                                                                        <TableCellPopover
                                                                            key={
                                                                                "" +
                                                                                e +
                                                                                i
                                                                            }
                                                                            index={
                                                                                i
                                                                            }
                                                                            old={
                                                                                e ??
                                                                                0
                                                                            }
                                                                            actual={
                                                                                data[
                                                                                    `${metric}`
                                                                                ][2024][
                                                                                    "actual"
                                                                                ][
                                                                                    i
                                                                                ] ??
                                                                                0
                                                                            }
                                                                            customClass={
                                                                                tableCellClass
                                                                            }
                                                                            isTarget={
                                                                                true
                                                                            }
                                                                            previous_target_value={
                                                                                data[
                                                                                    `${metric}`
                                                                                ][2024][
                                                                                    "previous_target_value"
                                                                                ][
                                                                                    i
                                                                                ]
                                                                            }
                                                                            updater_name={
                                                                                data[
                                                                                    `${metric}`
                                                                                ][2024][
                                                                                    "updater_name"
                                                                                ][
                                                                                    i
                                                                                ]
                                                                            }
                                                                            update_date={
                                                                                data[
                                                                                    `${metric}`
                                                                                ][2024][
                                                                                    "update_date"
                                                                                ][
                                                                                    i
                                                                                ]
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                                <TableCellPopover
                                                                    index={
                                                                        "total"
                                                                    }
                                                                    actual={
                                                                        data[
                                                                            `${metric}`
                                                                        ][2024][
                                                                            "actual"
                                                                        ].reduce(
                                                                            (
                                                                                total,
                                                                                value
                                                                            ) =>
                                                                                total +
                                                                                value,
                                                                            0
                                                                        ) ?? 0
                                                                    }
                                                                    old={
                                                                        data[
                                                                            `${metric}`
                                                                        ][2024][
                                                                            "target"
                                                                        ].reduce(
                                                                            (
                                                                                t,
                                                                                v
                                                                            ) =>
                                                                                t +
                                                                                v,
                                                                            0
                                                                        ) ?? 0
                                                                    }
                                                                    customClass={
                                                                        tableCellClass
                                                                    }
                                                                    isTarget={
                                                                        true
                                                                    }
                                                                />
                                                            </TableRow>
                                                        </TableBody>
                                                    )}
                                                </Table>
                                            </TableContainer>
                                        </Grid>

                                        <br />
                                    </>
                                </TabPanel>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </TabContext>
            </Container>
        </>
    );
};

export default TargetsScreen;
