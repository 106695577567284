// https://arodigitalstrategy.atlassian.net/browse/CC-196

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Container,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import EChartsReact from "echarts-for-react";

import WrapperPDFTable from "../shared/WrapperPDFTable";
import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import CircleValue from "../CircleValue";
import TableHeadSort from "../TableHeadSort";
import TableBodyCell from "../TableBodyCell";
import DateSelector from "../DateSelector";
import OptionsButtonDialog from "../OptionsButtonDialog";
import SwitchVisualTable from "../SwitchVisualTable";

import Colours from "../../helpers/colours";
import { SliceTop, TOP_ALL } from "../../helpers/reports";
import { months } from "../../helpers/dates";
import { fns } from "../../helpers/common";
import { reservationsToYearMonth } from "../DataLoader";

// TODO: Combine with BookingDaysReport (Maybe?)
const TopBookedDates = ({
    hotelID,
    byDate = "CheckIn",
    title = "Top Booked Dates",
    report_desc = "This report shows the top reserved dates within a selected time period (metrics include arrivals, room nights and room revenue).",
    // report_desc = "Shows top dates that a client arrives to the hotel (Arrival number, Room Nights and Room Revenue)",
}) => {
    const theme = useTheme();
    let tableOverflow = useMediaQuery(theme.breakpoints.down("md"));
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const VISUAL_MODE = "VISUAL_MODE";
    const TABLE_MODE = "TABLE_MODE";
    const [mode, setMode] = useState(TABLE_MODE);
    const TITLE = title;

    const [fullYear, setFullYear] = useState(false);
    const [cancellations, setCancellations] = useState(false);
    const [time, setTime] = useState(new Date());
    const { time: sharedTime, fullYear: sharedFullYear } = useSelector(
        (state) => state.settings.settings
    );

    const [rawData, setRawData] = useState({});
    const [selectedData, setSelectedData] = useState([]);
    const [connectionError, setConnectionError] = useState(false);

    const { dataBookings } = useSelector((state) => state);

    const [processedData, setProcessedData] = useState(new Map());
    const [processedDataMonths, setProcessedDataMonths] = useState(new Map());

    const [topNumber, setTopNumber] = useState(0);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("Bookings");

    const TOP = [10, 5, TOP_ALL];

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    useEffect(() => {
        if (dataBookings.status === "error") setConnectionError(true);
        if (dataBookings.status === "loaded") {
            let aux = {};
            let aux_data = reservationsToYearMonth(
                dataBookings.data.filter((e) =>
                    ["Special Offer"].includes(e.Type)
                ),
                "CheckIn"
            );
            Object.entries(aux_data).map(([key, values]) => {
                aux[key] = Object.values(values).flat(1);
                return "";
            });
            console.log({ aux });
            setRawData(aux);
            setLoaded(true);
        } else setLoaded(false);
    }, [dataBookings]);

    //   ____
    //  |  _ \ _ __ ___   ___ ___  ___ ___
    //  | |_) | '__/ _ \ / __/ _ \/ __/ __|
    //  |  __/| | | (_) | (_|  __/\__ \__ \
    //  |_|   |_|  \___/ \___\___||___/___/
    useEffect(() => {
        const yearDates = new Map();
        const monthDates = new Map();
        Object.keys(rawData).map((year) => {
            yearDates.set(year, new Map());
            monthDates.set(year, new Map());
            let data = rawData[year];
            data.forEach(
                ({
                    [byDate]: date,
                    TotalPrice: RoomRevenue,
                    RoomNights,
                    CancelStatus,
                }) => {
                    const date_ = new Date(new Date(date));
                    const key = date_.toISOString().split("T")[0];
                    // Year data
                    if (!yearDates.get(year).has(key))
                        yearDates.get(year).set(key, {
                            Bookings: 0,
                            RoomNights: 0,
                            RoomRevenue: 0,
                        });

                    if (!cancellations) {
                        yearDates.get(year).set(key, {
                            Bookings: yearDates.get(year).get(key).Bookings + 1,
                            RoomNights:
                                yearDates.get(year).get(key).RoomNights +
                                RoomNights,
                            RoomRevenue:
                                yearDates.get(year).get(key).RoomRevenue +
                                RoomRevenue,
                        });

                        // Month data
                        const month = months[date_.getUTCMonth()];
                        if (!monthDates.get(year).has(month)) {
                            monthDates.get(year).set(month, new Map());
                        }
                        if (!monthDates.get(year).get(month).has(key)) {
                            monthDates.get(year).get(month).set(key, {
                                Bookings: 0,
                                RoomNights: 0,
                                RoomRevenue: 0,
                            });
                        }
                        monthDates
                            .get(year)
                            .get(month)
                            .set(key, {
                                Bookings:
                                    monthDates.get(year).get(month).get(key)
                                        .Bookings + 1,
                                RoomNights:
                                    monthDates.get(year).get(month).get(key)
                                        .RoomNights + RoomNights,
                                RoomRevenue:
                                    monthDates.get(year).get(month).get(key)
                                        .RoomRevenue + RoomRevenue,
                            });
                    } else if (CancelStatus === 1) {
                        yearDates.get(year).set(key, {
                            Bookings: yearDates.get(year).get(key).Bookings + 1,
                            RoomNights:
                                yearDates.get(year).get(key).RoomNights +
                                RoomNights,
                            RoomRevenue:
                                yearDates.get(year).get(key).RoomRevenue +
                                RoomRevenue,
                        });

                        // Month data
                        const month = months[date_.getUTCMonth()];
                        if (!monthDates.get(year).has(month)) {
                            monthDates.get(year).set(month, new Map());
                        }
                        if (!monthDates.get(year).get(month).has(key)) {
                            monthDates.get(year).get(month).set(key, {
                                Bookings: 0,
                                RoomNights: 0,
                                RoomRevenue: 0,
                            });
                        }
                        monthDates
                            .get(year)
                            .get(month)
                            .set(key, {
                                Bookings:
                                    monthDates.get(year).get(month).get(key)
                                        .Bookings + 1,
                                RoomNights:
                                    monthDates.get(year).get(month).get(key)
                                        .RoomNights + RoomNights,
                                RoomRevenue:
                                    monthDates.get(year).get(month).get(key)
                                        .RoomRevenue + RoomRevenue,
                            });
                    }
                }
            );

            return "";
        });

        setProcessedData(yearDates);
        setProcessedDataMonths(monthDates);
    }, [rawData, byDate, cancellations]);

    //   ____             _
    //  / ___|  ___  _ __| |_
    //  \___ \ / _ \| '__| __|
    //   ___) | (_) | |  | |_
    //  |____/ \___/|_|   \__|
    useEffect(() => {
        if (fullYear) {
            if (processedData.has(`${time.getUTCFullYear()}`)) {
                let aux = [...processedData.get(`${time.getUTCFullYear()}`)];
                aux.sort((a, b) => {
                    if (order === "asc")
                        return a[1][orderBy] >= b[1][orderBy] ? -1 : 1;
                    else return a[1][orderBy] >= b[1][orderBy] ? 1 : -1;
                });
                setSelectedData(SliceTop(aux, TOP[topNumber]));
            }
        } else {
            if (
                processedDataMonths.has(`${time.getUTCFullYear()}`) &&
                processedDataMonths
                    .get(`${time.getUTCFullYear()}`)
                    .has(months[time.getUTCMonth()])
            ) {
                let aux = [
                    ...processedDataMonths
                        .get(`${time.getUTCFullYear()}`)
                        .get(months[time.getUTCMonth()]),
                ];
                aux.sort((a, b) => {
                    if (order === "asc")
                        return a[1][orderBy] >= b[1][orderBy] ? -1 : 1;
                    else return a[1][orderBy] >= b[1][orderBy] ? 1 : -1;
                });
                setSelectedData(SliceTop(aux, TOP[topNumber]));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fullYear,
        time,
        processedData,
        processedDataMonths,
        order,
        orderBy,
        topNumber,
    ]);

    return (
        <Container maxWidth={false} component={HoverPaper}>
            <br />
            {/*  ___ _   _ ___ _____  */}
            {/* |_ _| \ | |_ _|_   _| */}
            {/*  | ||  \| || |  | |   */}
            {/*  | || |\  || |  | |   */}
            {/* |___|_| \_|___| |_|   */}
            <Stack
                direction={{ md: "column", sm: "row", xs: "row" }}
                justifyContent={isMobile ? "space-around" : "space-between"}
                alignItems="flex-start"
                // style={{ border: "1px solid red" }}
            >
                {/* Title */}
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={"center"}
                    // style={{ border: "1px solid orange" }}
                >
                    <Stack
                        alignItems="center"
                        // style={{ border: "1px solid yellow" }}
                    >
                        <Typography fontWeight={"bold"} textAlign="center">
                            {!cancellations ? TITLE : TITLE + " - Cancelled"}
                        </Typography>
                    </Stack>
                    <InfoIcon text={report_desc} />
                    <ErrorIcon show={connectionError} />
                </Stack>

                {isMobile ? (
                    <OptionsButtonDialog>
                        <SwitchVisualTable
                            collapse={true}
                            modes={[TABLE_MODE, VISUAL_MODE]}
                            mode={mode}
                            setMode={setMode}
                        />
                        <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        />
                        <FormControlLabel
                            label="See Cancelled"
                            control={
                                <Checkbox
                                    checked={cancellations}
                                    onChange={() => {
                                        console.log(!cancellations);
                                        setCancellations(!cancellations);
                                    }}
                                />
                            }
                        />
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography>{`Switch to top ${
                                TOP[
                                    (topNumber + 1) %
                                        (mode === TABLE_MODE
                                            ? TOP.length
                                            : TOP.length - 1)
                                ]
                            }`}</Typography>
                            <CircleValue
                                value={`${TOP[topNumber]}`}
                                tooltip={`Switch to top ${
                                    TOP[
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    ]
                                }`}
                                color={Colours.primary}
                                onClick={() => {
                                    setTopNumber(
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    );
                                }}
                                style={{ cursor: "pointer" }}
                            />
                        </Stack>
                        {""}
                    </OptionsButtonDialog>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        // style={{ border: "1px solid green   " }}
                    >
                        <CircleValue
                            value={`${TOP[topNumber]}`}
                            tooltip={`Switch to top ${
                                TOP[
                                    (topNumber + 1) %
                                        (mode === TABLE_MODE
                                            ? TOP.length
                                            : TOP.length - 1)
                                ]
                            }`}
                            color={Colours.primary}
                            onClick={() => {
                                setTopNumber(
                                    (topNumber + 1) %
                                        (mode === TABLE_MODE
                                            ? TOP.length
                                            : TOP.length - 1)
                                );
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <SwitchVisualTable
                            modes={[TABLE_MODE, VISUAL_MODE]}
                            mode={mode}
                            setMode={setMode}
                        />
                        <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        />

                        <FormControlLabel
                            label="See Cancelled"
                            control={
                                <Checkbox
                                    checked={cancellations}
                                    onChange={() => {
                                        console.log(!cancellations);
                                        setCancellations(!cancellations);
                                    }}
                                />
                            }
                        />
                    </Stack>
                )}
            </Stack>

            {!loaded ? (
                <>
                    <br /> <ProgressBarSteps />
                </>
            ) : (
                ""
            )}

            {mode === TABLE_MODE && (
                //  _____     _     _
                // |_   _|_ _| |__ | | ___
                //   | |/ _` | '_ \| |/ _ \
                //   | | (_| | |_) | |  __/
                //   |_|\__,_|_.__/|_|\___|
                <TableContainer
                    style={{ overflow: "auto", maxHeight: "600px" }}
                >
                    <WrapperPDFTable k={TITLE}>
                        <Table>
                            <TableHeadSort
                                stickyHeads={tableOverflow ? 2 : 0}
                                headers={[
                                    { id: "date", label: "Date", ignore: true },
                                    {
                                        id: "DayName",
                                        label: "Day Name",
                                        ignore: true,
                                    },
                                    { id: "Bookings", label: "Bookings" },
                                    { id: "RoomNights", label: "Room Nights" },
                                    {
                                        id: "RoomRevenue",
                                        label: "Room Revenue",
                                    },
                                ]}
                                order={order}
                                orderBy={orderBy}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                            />
                            <TableBody>
                                {(fullYear &&
                                    processedData.has(
                                        `${time.getUTCFullYear()}`
                                    )) ||
                                (!fullYear &&
                                    processedDataMonths.has(
                                        `${time.getUTCFullYear()}`
                                    ) &&
                                    processedDataMonths
                                        .get(`${time.getUTCFullYear()}`)
                                        .has(months[time.getUTCMonth()])) ? (
                                    SliceTop(selectedData, TOP[topNumber]).map(
                                        ([date, row]) => (
                                            <TableRow key={`${date}`}>
                                                <TableBodyCell
                                                    style={
                                                        tableOverflow
                                                            ? {
                                                                  position:
                                                                      "sticky",
                                                                  left: 0,
                                                                  zIndex: 1,
                                                                  backgroundColor:
                                                                      Colours.plainWhite,
                                                              }
                                                            : {}
                                                    }
                                                >{`${date}`}</TableBodyCell>
                                                <TableBodyCell
                                                    style={
                                                        tableOverflow
                                                            ? {
                                                                  position:
                                                                      "sticky",
                                                                  left: 76,
                                                                  zIndex: 1,
                                                                  backgroundColor:
                                                                      Colours.plainWhite,
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    {[
                                                        "Sunday",
                                                        "Monday",
                                                        "Tuesday",
                                                        "Wednesday",
                                                        "Thursday",
                                                        "Friday",
                                                        "Saturday",
                                                    ][
                                                        new Date(date).getDay()
                                                    ].slice(0, 3)}
                                                </TableBodyCell>
                                                <TableBodyCell>
                                                    {fns(
                                                        hotelID,
                                                        row["Bookings"]
                                                    )}
                                                </TableBodyCell>
                                                <TableBodyCell>
                                                    {fns(
                                                        hotelID,
                                                        row["RoomNights"]
                                                    )}
                                                </TableBodyCell>
                                                <TableBodyCell>
                                                    {fns(
                                                        hotelID,
                                                        row["RoomRevenue"],
                                                        {
                                                            left: "currency",
                                                            maxDecimals: 2,
                                                        }
                                                    )}
                                                </TableBodyCell>
                                            </TableRow>
                                        )
                                    )
                                ) : dataBookings.status === "loaded" ? (
                                    <TableRow>
                                        <TableCell colSpan={100} align="center">
                                            <Box
                                                component="span"
                                                fontStyle="italic"
                                            >
                                                No data
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    //   _                    _ _
                                    //  | |    ___   __ _  __| (_)_ __   __ _
                                    //  | |   / _ \ / _` |/ _` | | '_ \ / _` |
                                    //  | |__| (_) | (_| | (_| | | | | | (_| |
                                    //  |_____\___/ \__,_|\__,_|_|_| |_|\__, |
                                    //                                  |___/
                                    [
                                        ...Array(
                                            TOP[topNumber] === TOP_ALL
                                                ? 25
                                                : TOP[topNumber]
                                        ).keys(),
                                    ].map((e) => (
                                        <TableRow key={e}>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </WrapperPDFTable>
                </TableContainer>
            )}
            {mode === VISUAL_MODE ? (
                //   _____                 _
                //  / ____|               | |
                // | |  __ _ __ __ _ _ __ | |__
                // | | |_ | '__/ _` | '_ \| '_ \
                // | |__| | | | (_| | |_) | | | |
                //  \_____|_|  \__,_| .__/|_| |_|
                //                  | |
                //                  |_|

                (fullYear && processedData.has(`${time.getUTCFullYear()}`)) ||
                (!fullYear &&
                    processedDataMonths.has(`${time.getUTCFullYear()}`) &&
                    processedDataMonths
                        .get(`${time.getUTCFullYear()}`)
                        .has(months[time.getUTCMonth()])) ? (
                    <>
                        <br />
                        <WrapperPDFEChart k={TITLE + " Graph"}>
                            <EChartsReact
                                style={{ height: tableOverflow ? 900 : 300 }}
                                option={{
                                    title: [
                                        {
                                            text: `Bookings:${
                                                !tableOverflow ? "\n" : " "
                                            }${fns(
                                                hotelID,
                                                SliceTop(
                                                    selectedData,
                                                    TOP[topNumber]
                                                ).reduce(
                                                    (acc, [date, element]) =>
                                                        acc + element.Bookings,
                                                    0
                                                )
                                            )}`,
                                            left: tableOverflow ? "50%" : "25%",
                                            top: tableOverflow ? "0%" : 10,
                                            textAlign: "center",
                                        },
                                        {
                                            text: `Room Nights:${
                                                !tableOverflow ? "\n" : " "
                                            }${fns(
                                                hotelID,
                                                SliceTop(
                                                    selectedData,
                                                    TOP[topNumber]
                                                ).reduce(
                                                    (acc, [date, element]) =>
                                                        acc +
                                                        element.RoomNights,
                                                    0
                                                )
                                            )}`,
                                            left: tableOverflow ? "50%" : "50%",
                                            top: tableOverflow ? "33%" : 10,
                                            textAlign: "center",
                                        },
                                        {
                                            text: `Room Revenue:${
                                                !tableOverflow ? "\n" : " "
                                            }${fns(
                                                hotelID,
                                                SliceTop(
                                                    selectedData,
                                                    TOP[topNumber]
                                                ).reduce(
                                                    (acc, [date, element]) =>
                                                        acc +
                                                        element.RoomRevenue,
                                                    0
                                                ),
                                                { left: "currency" }
                                            )}`,
                                            left: tableOverflow ? "50%" : "75%",
                                            top: tableOverflow ? "66%" : 10,
                                            textAlign: "center",
                                        },
                                    ],

                                    tooltip: {
                                        trigger: "item",
                                    },

                                    series: [
                                        ...[
                                            "Bookings",
                                            "Room Nights",
                                            "Room Revenue",
                                        ].map((key, index) => {
                                            return {
                                                name: key,
                                                type: "pie",
                                                radius: tableOverflow
                                                    ? "30%"
                                                    : 70,
                                                center: tableOverflow
                                                    ? [
                                                          "50%",
                                                          `${33 * index + 18}%`,
                                                      ]
                                                    : [
                                                          `${
                                                              25 * (index + 1)
                                                          }%`,
                                                          "50%",
                                                      ],
                                                data: [
                                                    ...SliceTop(
                                                        selectedData,
                                                        TOP[topNumber]
                                                    ).map(([date, element]) => {
                                                        return {
                                                            name: fullYear
                                                                ? `${[
                                                                      "Sunday",
                                                                      "Monday",
                                                                      "Tuesday",
                                                                      "Wednesday",
                                                                      "Thursday",
                                                                      "Friday",
                                                                      "Saturday",
                                                                  ][
                                                                      new Date(
                                                                          date
                                                                      ).getDay()
                                                                  ].slice(
                                                                      0,
                                                                      3
                                                                  )} ${new Date(
                                                                      date
                                                                  ).getDate()} ${months[
                                                                      new Date(
                                                                          date
                                                                      ).getUTCMonth()
                                                                  ].slice(
                                                                      0,
                                                                      3
                                                                  )}`
                                                                : `${[
                                                                      "Sunday",
                                                                      "Monday",
                                                                      "Tuesday",
                                                                      "Wednesday",
                                                                      "Thursday",
                                                                      "Friday",
                                                                      "Saturday",
                                                                  ][
                                                                      new Date(
                                                                          date
                                                                      ).getDay()
                                                                  ].slice(
                                                                      0,
                                                                      3
                                                                  )} ${new Date(
                                                                      date
                                                                  ).getDate()}`,
                                                            value: element[
                                                                key.replace(
                                                                    " ",
                                                                    ""
                                                                )
                                                            ],
                                                        };
                                                    }),
                                                ],
                                            };
                                        }),
                                    ],
                                }}
                            />
                        </WrapperPDFEChart>
                    </>
                ) : dataBookings.status === "loaded" ? (
                    <Box
                        paddingTop={"5em"}
                        paddingBottom={"5em"}
                        display={"flex"}
                        justifyContent={"center"}
                        component="span"
                        fontStyle="italic"
                    >
                        No data
                    </Box>
                ) : (
                    <Box>
                        <br />
                        <Skeleton variant="rounded" height={"16em"} />
                    </Box>
                )
            ) : (
                ""
            )}
            <br />
        </Container>
    );
};

export default TopBookedDates;
