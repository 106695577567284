import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
    Container,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Stack,
    Select,
    AvatarGroup,
    TextField,
    Typography,
    TablePagination,
    IconButton,
    MenuItem,
    TableFooter,
    Checkbox,
    Tooltip,
    Grid,
} from "@mui/material";

import CircleValue from "../components/CircleValue";
import InsightAssistHeader from "../components/InsightAssistHeader";
import InsightAssistRow from "../components/InsightAssistRow";
import InsightAssistRowDesktopLoading from "../components/InsightAssistRowDesktopLoading";

import { rInterval } from "../helpers/randomData";
import Icons from "../helpers/icons";
import Colours from "../helpers/colours";
import ProgressBarSteps from "../components/shared/ProgressBarSteps";

//                                                    _
//    ___ ___  _ __ ___  _ __   ___  _ __   ___ _ __ | |_
//   / __/ _ \| '_ ` _ \| '_ \ / _ \| '_ \ / _ \ '_ \| __|
//  | (_| (_) | | | | | | |_) | (_) | | | |  __/ | | | |_
//   \___\___/|_| |_| |_| .__/ \___/|_| |_|\___|_| |_|\__|
//                      |_|

const InsightsAssistScreen = () => {
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { dataSimple } = useSelector((state) => state);

    const [sortWay, setSortWay] = useState("priority");
    const [allowedPillars, setAllowedPillars] = useState([
        "Attract",
        "Acquire",
        "Engage",
        "Convert",
        "Retain",
    ]);
    const [searchText, setSearchText] = useState("");
    const [originalData, setOriginalData] = useState([]);
    const [data, setData] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [selectedList, setSelectedList] = useState([]);

    const CHECK_ONLY_SELECTED = 2;
    const CHECK_ALL = 1;
    const CHECK_NOT_SELECTED = 0;
    const [selectedFilterCheck, setSelectedFilterCheck] = useState(CHECK_ALL);

    const filterData = useCallback(
        (by) => {
            let aux = [...originalData];

            //Check selected
            switch (selectedFilterCheck) {
                case CHECK_ALL:
                    break;
                case CHECK_ONLY_SELECTED:
                    aux = aux.filter((element) =>
                        selectedList.includes(element.indicatorName)
                    );
                    break;
                case CHECK_NOT_SELECTED:
                    aux = aux.filter(
                        (element) =>
                            !selectedList.includes(element.indicatorName)
                    );
                    break;
            }

            //Pillars
            aux = aux.filter((element) =>
                allowedPillars.includes(element.growthTrackName)
            );

            //Contains Text
            if (searchText.length > 0) {
                aux = aux.filter((element) => {
                    return (
                        element.indicatorName
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                        element.growthTrackName
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                        element.status
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    );
                });
            }

            //Order
            switch (by) {
                case "indicatorName":
                case "priority":
                case "growthTrackName":
                    aux.sort((a, b) =>
                        a[by] === b[by] ? 0 : a[by] > b[by] ? 1 : -1
                    );
                    break;

                default:
                    aux.sort((a, b) =>
                        a[by] === b[by] ? 0 : a[by] > b[by] ? -1 : 1
                    );
                    break;
            }
            setData(aux);
        },
        [
            allowedPillars,
            originalData,
            searchText,
            selectedFilterCheck,
            selectedList,
        ]
    );

    useEffect(() => {
        if (originalData) filterData(sortWay);
    }, []);

    useEffect(() => {
        filterData(sortWay);
    }, [searchText, allowedPillars, sortWay, filterData]);

    useEffect(() => {
        if (
            dataSimple.status !== "loaded" ||
            !dataSimple.data.hasOwnProperty("Indicators")
        ) {
            return;
        }

        const { Indicators } = dataSimple.data;
        console.log({
            indicators: Indicators.map((e) => e.indicator_name),
        });
        const chosen = [
            //TODO: move this to API
            "Page Titles Duplicated",
            "Page Titles Missing",
            "Page Titles Over 60 Char",
            "Page Titles Below 30 Char",
            "Meta Description Duplicated",
            "Meta Description Missing",
            "Meta titles Below 70 char?",
            "Meta titles Over 155 char?",
            "# pages URL > 115 char?",
            "H1 Tags Duplicated",
            "H1 Tags Missing",
            "No follow backlinks",
            "Follow backlinks",
            "Similar H1 tag to Title",
            "Text Backlinks",
            "Avg. Visit Duration in Seconds",
            "Bounce Rate",
            "Form backlink",
            "Frame backlink",
            "Image Backlinks",
            "Page Views",
            "Unique Visits",
            "Pages/Visit",
            "Client Error (4xx)",
            "Server Error (5xx)",
            "HTTPS Secure",
            "W3C Validation",
            "Sitemap",
            "robots.txt",
            "Schema.org",
            "Text/HTML ratio",
        ];
        if (!Indicators) {
            return;
        }

        let values = [];
        let RAlist = [];
        Indicators.forEach((i) => {
            if (chosen.includes(i.indicator_name ?? "")) {
                values.push({
                    RA: i.RA,
                    indicatorName: i.indicator_id,
                    indicatorNameFormat: i.indicator_name,
                    growthTrackName: i.growth_track,
                    weighting: i.weighting ?? i.default_weighting,
                    currentScore: i.indicator_score,
                    previousScore: i.indicator_score_old,
                    priority: i.priority ?? i.default_priority,
                    status:
                        i.status ??
                        [
                            "Complete",
                            "Pending review",
                            "Under review",
                            "Partial complete",
                            "In progress",
                        ][rInterval(0, 4)],
                    whatV: i.what_v ?? i.default_what ?? "",
                    howV: i.how_v ?? i.default_how ?? "",
                    whyV: i.why_v ?? i.default_why ?? "",
                    scoreDate: i.year_month,
                    oldScoreDate: i.year_month_old,
                });
                if (i.RA) RAlist.push(i.indicator_id);
            }
        });
        setOriginalData(values);
        setSelectedList(RAlist);
    }, [dataSimple]);

    return (
        <>
            <Container>
                <Grid container>
                    <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                        <Typography variant="h4">Red To Greens</Typography>
                    </Grid>
                </Grid>{" "}
                <Stack
                    component={Paper}
                    direction={{ xs: "column", sm: "column", md: "row" }}
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={2}
                    p={2}
                >
                    <Stack
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        spacing={4}
                        alignItems="center"
                    >
                        {/*  ____             _     ____         */}
                        {/* / ___|  ___  _ __| |_  | __ ) _   _  */}
                        {/* \___ \ / _ \| '__| __| |  _ \| | | | */}
                        {/*  ___) | (_) | |  | |_  | |_) | |_| | */}
                        {/* |____/ \___/|_|   \__| |____/ \__, | */}
                        {/*                               |___/  */}
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box component="span" fontWeight={"bold"}>
                                SORT BY:
                            </Box>
                            <Select
                                variant="standard"
                                value={sortWay}
                                onChange={(event) => {
                                    filterData(event.target.value);
                                    setSortWay(event.target.value);
                                }}
                            >
                                <MenuItem value={"indicatorName"}>
                                    Indicator Name
                                </MenuItem>
                                <MenuItem value={"priority"}>Priority</MenuItem>
                                <MenuItem value="growthTrackName">
                                    Growth Track
                                </MenuItem>
                                <MenuItem value="weighting">Weighting</MenuItem>
                                <MenuItem value="currentScore">
                                    Current Score
                                </MenuItem>
                                <MenuItem value="previousScore">
                                    Old Score
                                </MenuItem>
                                <MenuItem value="status">Status</MenuItem>
                            </Select>
                        </Stack>

                        {/*  ____  _                      ___        _        */}
                        {/* / ___|| |__   _____      __  / _ \ _ __ | |_   _  */}
                        {/* \___ \| '_ \ / _ \ \ /\ / / | | | | '_ \| | | | | */}
                        {/*  ___) | | | | (_) \ V  V /  | |_| | | | | | |_| | */}
                        {/* |____/|_| |_|\___/ \_/\_/    \___/|_| |_|_|\__, | */}
                        {/*                                            |___/  */}
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box component="span" fontWeight={"bold"}>
                                SHOW ONLY:
                            </Box>
                            <AvatarGroup>
                                <CircleValue
                                    color={
                                        allowedPillars.includes("Attract")
                                            ? Colours.attract
                                            : null
                                    }
                                    value="A"
                                    onClick={() => {
                                        allowedPillars.includes("Attract") ||
                                        allowedPillars.includes("Acquire")
                                            ? setAllowedPillars(
                                                  [...allowedPillars].filter(
                                                      (e) =>
                                                          e !== "Attract" &&
                                                          e !== "Acquire"
                                                  )
                                              )
                                            : setAllowedPillars([
                                                  ...allowedPillars,
                                                  "Attract",
                                                  "Acquire",
                                              ]);
                                    }}
                                />
                                <CircleValue
                                    color={
                                        allowedPillars.includes("Engage")
                                            ? Colours.engage
                                            : null
                                    }
                                    value="E"
                                    onClick={() => {
                                        allowedPillars.includes("Engage")
                                            ? setAllowedPillars(
                                                  [...allowedPillars].filter(
                                                      (e) => e !== "Engage"
                                                  )
                                              )
                                            : setAllowedPillars([
                                                  ...allowedPillars,
                                                  "Engage",
                                              ]);
                                    }}
                                />
                                <CircleValue
                                    color={
                                        allowedPillars.includes("Convert")
                                            ? Colours.convert
                                            : null
                                    }
                                    value="C"
                                    onClick={() => {
                                        allowedPillars.includes("Convert")
                                            ? setAllowedPillars(
                                                  [...allowedPillars].filter(
                                                      (e) => e !== "Convert"
                                                  )
                                              )
                                            : setAllowedPillars([
                                                  ...allowedPillars,
                                                  "Convert",
                                              ]);
                                    }}
                                />
                                <CircleValue
                                    color={
                                        allowedPillars.includes("Retain")
                                            ? Colours.retain
                                            : null
                                    }
                                    value="R"
                                    onClick={() => {
                                        allowedPillars.includes("Retain")
                                            ? setAllowedPillars(
                                                  [...allowedPillars].filter(
                                                      (e) => e !== "Retain"
                                                  )
                                              )
                                            : setAllowedPillars([
                                                  ...allowedPillars,
                                                  "Retain",
                                              ]);
                                    }}
                                />
                            </AvatarGroup>
                            <Tooltip
                                title={
                                    selectedFilterCheck === CHECK_ALL
                                        ? "Selected and Not selected"
                                        : selectedFilterCheck ===
                                          CHECK_ONLY_SELECTED
                                        ? "Only Selected"
                                        : "Only not selected"
                                }
                            >
                                <Checkbox
                                    checked={
                                        selectedFilterCheck ===
                                        CHECK_ONLY_SELECTED
                                    }
                                    indeterminate={
                                        selectedFilterCheck === CHECK_ALL
                                    }
                                    onChange={() => {
                                        setSelectedFilterCheck(
                                            (selectedFilterCheck + 1) % 3
                                        );
                                    }}
                                />
                            </Tooltip>
                        </Stack>
                    </Stack>

                    {/*  ____                      _      */}
                    {/* / ___|  ___  __ _ _ __ ___| |__   */}
                    {/* \___ \ / _ \/ _` | '__/ __| '_ \  */}
                    {/*  ___) |  __/ (_| | | | (__| | | | */}
                    {/* |____/ \___|\__,_|_|  \___|_| |_| */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        // spacing={2}
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <Icons.Search
                            sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                            hiddenLabel
                            variant="standard"
                            size="small"
                            onChange={(event) => {
                                setSearchText(event.target.value);
                            }}
                        />
                    </Stack>
                    <Box>
                        <IconButton
                            disabled={page === 0}
                            onClick={() => {
                                setPage(page - 1);
                            }}
                        >
                            <Icons.ArrowLeft />
                        </IconButton>
                        <IconButton
                            disabled={
                                (page + 1) * rowsPerPage > data.length - 1
                            }
                            onClick={() => {
                                setPage(page + 1);
                            }}
                        >
                            <Icons.ArrowRight />
                        </IconButton>
                        <Select
                            value={rowsPerPage}
                            onChange={(e) => setRowsPerPage(e.target.value)}
                            variant="standard"
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                        </Select>
                    </Box>
                    {originalData.length === 0 && <ProgressBarSteps />}
                </Stack>
                <br />
                <Container maxWidth="lg" component={Paper} disableGutters>
                    <Table>
                        {/*  _   _                _            */}
                        {/* | | | | ___  __ _  __| | ___ _ __  */}
                        {/* | |_| |/ _ \/ _` |/ _` |/ _ \ '__| */}
                        {/* |  _  |  __/ (_| | (_| |  __/ |    */}
                        {/* |_| |_|\___|\__,_|\__,_|\___|_|    */}
                        <InsightAssistHeader showRedAlertCheckbox={true} />

                        {/*  _____           _             */}
                        {/* |  ___|__   ___ | |_ ___ _ __  */}
                        {/* | |_ / _ \ / _ \| __/ _ \ '__| */}
                        {/* |  _| (_) | (_) | ||  __/ |    */}
                        {/* |_|  \___/ \___/ \__\___|_|    */}
                        <TableFooter>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event, newPage) => {
                                    setPage(newPage);
                                }}
                                onRowsPerPageChange={(event) => {
                                    setRowsPerPage(
                                        parseInt(event.target.value, 10)
                                    );
                                    setPage(0);
                                }}
                                labelRowsPerPage
                                // labelDisplayedRows={() => ""}
                                sx={{
                                    borderBottom: "none",
                                }}
                            />
                        </TableFooter>

                        {/*  ____            _        */}
                        {/* | __ )  ___   __| |_   _  */}
                        {/* |  _ \ / _ \ / _` | | | | */}
                        {/* | |_) | (_) | (_| | |_| | */}
                        {/* |____/ \___/ \__,_|\__, | */}
                        {/*                    |___/  */}
                        <TableBody>
                            <>
                                {data
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map(
                                        ({
                                            indicatorName,
                                            indicatorNameFormat,
                                            growthTrackName,
                                            weighting,
                                            currentScore,
                                            previousScore,
                                            priority,
                                            status,
                                            whatV,
                                            howV,
                                            whyV,
                                        }) => {
                                            return (
                                                <InsightAssistRow
                                                    isMobile={isMobile}
                                                    indicatorName={
                                                        indicatorName
                                                    }
                                                    indicatorNameFormat={
                                                        indicatorNameFormat
                                                    }
                                                    growthTrackName={
                                                        growthTrackName
                                                    }
                                                    weighting={weighting}
                                                    currentScore={currentScore}
                                                    previousScore={
                                                        previousScore
                                                    }
                                                    priority={priority}
                                                    status={status}
                                                    whatV={whatV}
                                                    howV={howV}
                                                    whyV={whyV}
                                                    showRedAlertCheckbox={true}
                                                    selectedList={selectedList}
                                                    setSelectedList={
                                                        setSelectedList
                                                    }
                                                />
                                            );
                                        }
                                    )}

                                {/* <InsightAssistRow isMobile={isMobile} /> */}
                            </>

                            {originalData.length === 0 && (
                                <InsightAssistRowDesktopLoading
                                    redAlerts
                                    times={10}
                                />
                            )}

                            {originalData.length !== 0 && data.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={100} align="center">
                                        <Box
                                            component="span"
                                            fontStyle="italic"
                                        >
                                            No data
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {/* <br /> */}
                </Container>
            </Container>
            <br />
            <br />
            <br />
        </>
    );
};

export default InsightsAssistScreen;
