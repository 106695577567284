import React, { useState } from "react";

import axios from "axios";

import {
    Button,
    TextField,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Stack,
} from "@mui/material";

import { URLAPI } from "../../configuration";

import {
    STATUS_COMPLETE,
    STATUS_ERROR,
    STATUS_WARNING,
} from "../../helpers/status";
import NotificationCard from "../NotificationCard";
import ListUsers from "./ListUsers";

const NotificationForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        subtext: "",
        category: "",
        creator_id: "",
        status: STATUS_WARNING,
        users: [],
        dead: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleUserChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            users: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${URLAPI}/notifications`,
                formData
            );
            console.log(response.data);
            // Handle success here
        } catch (error) {
            console.error("Error sending data", error);
            // Handle error here
        }
    };

    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                Notification Form
            </Typography>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={5}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Subtext"
                            name="subtext"
                            value={formData.subtext}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Category</InputLabel>
                            <Select
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                                required
                            >
                                {/* Aquí puedes añadir tus categorías */}
                                <MenuItem value="Category1">Category1</MenuItem>
                                <MenuItem value="Category2">Category2</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Creator ID"
                            name="creator_id"
                            value={formData.creator_id}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                            type="number"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Status</InputLabel>
                            <Select
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                                required
                            >
                                {[
                                    STATUS_COMPLETE,
                                    STATUS_ERROR,
                                    STATUS_WARNING,
                                ].map((s) => (
                                    <MenuItem value={s}>{s}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Dead"
                            name="dead"
                            value={formData.dead}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Users</InputLabel>
                            <Select
                                multiple
                                name="users"
                                value={formData.users}
                                onChange={handleUserChange}
                                required
                            >
                                {/* Aquí puedes añadir IDs de usuario */}
                                <MenuItem value={1}>User 1</MenuItem>
                                <MenuItem value={2}>User 2</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Enviar
                        </Button>
                    </form>{" "}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack>
                        <NotificationCard
                            id={0}
                            key={0}
                            type={formData.status}
                            title={formData.name}
                            text={formData.subtext}
                            time={new Date()}
                            allowSwipe={false}
                        />
                        <ListUsers />
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotificationForm;
