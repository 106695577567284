export const arrayDictYearMonthSort = (
    array,
    yearKey = "year_v",
    monthKey = "month_v"
) =>
    array.sort(
        (a, b) =>
            new Date(a[yearKey], a[monthKey], 1) -
            new Date(b[yearKey], b[monthKey], 1)
    );

export const stringYearMonth = (year, month) =>
    `${year}_${("0" + month).slice(-2)}`;
