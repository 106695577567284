import React, { useEffect, useState } from "react";

import EChartsReact from "echarts-for-react";
import { Grid } from "@mui/material";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";

import Colours from "../../helpers/colours";
import { fns } from "../../helpers/common";

const TopsComparisonMonth = ({
    topBy,
    format = {},
    thisTitle,
    lastTitle,
    thisData, //[{name: 'Ireland', value: 1451190}, ...]
    lastData, //[{name: 'Ireland', value: 1451190}, ...]
    hotel_id,
    compareLast = false,
}) => {
    const lastColours = {
        0: Colours.graphDisabledDarker,
        1: Colours.graphDisabled,
        2: Colours.graphDisabledLighter,
        3: Colours.graphDisabledDarker,
        4: Colours.graphDisabled,
        5: Colours.graphDisabledLighter,
    };

    const thisColours = {
        0: Colours.graphSold,
        1: Colours.graphExtraLine2,
        2: Colours.graphExtraLine1,
        3: Colours.graphMainLine3,
        4: Colours.graphMainLine2,
        5: Colours.graphMainLine1,
    };

    // Sum values for LAST
    const [selectedNamesLast, setSelectedNamesLast] = useState(new Set());
    useEffect(() => {
        const allNames = new Set(lastData.map((item) => item.name));
        setSelectedNamesLast(allNames);
    }, [lastData]);
    const onChartReadyLast = (chart) => {
        chart.on("legendselectchanged", function (params) {
            console.log(params); // Verificar la estructura para el manejo correcto
            const newSelectedNames = new Set();
            Object.keys(params.selected).forEach((name) => {
                if (params.selected[name]) {
                    newSelectedNames.add(name);
                }
            });

            setSelectedNamesLast(newSelectedNames);
        });
    };

    // Sum values for This
    const [selectedNamesThis, setSelectedNamesThis] = useState(new Set());
    useEffect(() => {
        console.log({ thisData });
        const allNames = new Set(thisData.map((item) => item.name));
        setSelectedNamesThis(allNames);
    }, [thisData]);
    const onChartReadyThis = (chart) => {
        chart.on("legendselectchanged", function (params) {
            console.log(params); // Verificar la estructura para el manejo correcto
            const newSelectedNames = new Set();
            Object.keys(params.selected).forEach((name) => {
                if (params.selected[name]) {
                    newSelectedNames.add(name);
                }
            });

            setSelectedNamesThis(newSelectedNames);
        });
    };

    const sumSelected = (data, namesSelected) => {
        return data
            .filter((item) => namesSelected.has(item.name))
            .reduce((acc, item) => acc + item[topBy], 0);
    };

    return (
        <Grid container spacing={3}>
            {compareLast ? (
                <>
                    {" "}
                    <Grid item xs={12} md={6}>
                        <WrapperPDFEChart k={lastTitle}>
                            <EChartsReact
                                onChartReady={onChartReadyLast}
                                style={{
                                    height: "500px",
                                    width: "100%",
                                }}
                                option={{
                                    title: {
                                        left: "center",
                                        text: lastTitle,
                                    },
                                    legend: {
                                        orient: "vertical",
                                        bottom: "0%",
                                        left: "center",
                                        height: "30%",
                                        formatter: (name) => {
                                            const matchingObj = lastData.find(
                                                (obj) => obj.name === name
                                            );
                                            if (matchingObj) {
                                                return `${name} ${fns(
                                                    hotel_id,
                                                    matchingObj[topBy],
                                                    format
                                                )}`;
                                            }
                                            return "";
                                        },
                                        textStyle: {
                                            rich: {
                                                title: {
                                                    color: "#666",
                                                    lineHeight: 30,
                                                },
                                                value: {
                                                    color: "#333",
                                                    fontSize: 14,
                                                },
                                            },
                                        },
                                    },
                                    series: [
                                        {
                                            type: "pie",
                                            selectedMode: "multiple",
                                            center: ["50%", "40%"],
                                            radius: ["40%", "60%"],
                                            avoidLabelOverlap: false,
                                            itemStyle: {
                                                borderRadius: 10,
                                                borderColor: "#fff",
                                                borderWidth: 2,
                                            },
                                            label: {
                                                show: true,
                                                position: "center",
                                                fontSize: 36,
                                                fontWeight: "bold",
                                                formatter: () => {
                                                    return `${fns(
                                                        hotel_id,
                                                        sumSelected(
                                                            lastData,
                                                            selectedNamesLast
                                                        ),
                                                        format
                                                    )}`;
                                                },
                                            },
                                            labelLine: {
                                                show: false,
                                            },
                                            data: [
                                                ...lastData.map(
                                                    (obj, index) => {
                                                        return {
                                                            name: obj.name,
                                                            value: obj[topBy],
                                                            itemStyle: {
                                                                color: lastColours[
                                                                    index
                                                                ],
                                                            },
                                                        };
                                                    }
                                                ),
                                            ],
                                        },
                                    ],
                                }}
                            />
                        </WrapperPDFEChart>
                    </Grid>
                </>
            ) : (
                <></>
            )}

            <Grid item xs={12} md={compareLast ? 6 : 12}>
                <WrapperPDFEChart k={thisTitle}>
                    <EChartsReact
                        onChartReady={onChartReadyThis}
                        style={{
                            height: "500px",
                            width: "100%",
                        }}
                        option={{
                            title: {
                                left: "center",
                                text: thisTitle,
                            },
                            legend: {
                                orient: "vertical",
                                bottom: "0%",
                                left: "center",
                                height: "30%",
                                formatter: (name) => {
                                    const matchingObj = thisData.find(
                                        (obj) => obj.name === name
                                    );
                                    if (matchingObj) {
                                        return `${name} ${fns(
                                            hotel_id,
                                            matchingObj[topBy],
                                            format
                                        )}`;
                                    }
                                    return "";
                                },
                                textStyle: {
                                    rich: {
                                        title: {
                                            color: "#666",
                                            lineHeight: 30,
                                        },
                                        value: {
                                            color: "#333",
                                            fontSize: 14,
                                        },
                                    },
                                },
                            },
                            series: [
                                {
                                    type: "pie",
                                    center: ["50%", "40%"],
                                    radius: ["40%", "60%"],
                                    avoidLabelOverlap: false,
                                    selectedMode: "multiple",
                                    itemStyle: {
                                        borderRadius: 10,
                                        borderColor: "#fff",
                                        borderWidth: 2,
                                    },
                                    label: {
                                        show: true,
                                        position: "center",
                                        fontSize: 36,
                                        fontWeight: "bold",
                                        formatter: () => {
                                            return `${fns(
                                                hotel_id,
                                                sumSelected(
                                                    thisData,
                                                    selectedNamesThis
                                                ),
                                                format
                                            )}`;
                                        },
                                    },
                                    labelLine: {
                                        show: false,
                                    },
                                    data: [
                                        ...thisData.map((obj, index) => {
                                            return {
                                                name: obj.name,
                                                value: obj[topBy],
                                                itemStyle: {
                                                    color: thisColours[index],
                                                },
                                            };
                                        }),
                                    ],
                                },
                            ],
                        }}
                    />
                </WrapperPDFEChart>
            </Grid>
        </Grid>
    );
};

export default TopsComparisonMonth;
