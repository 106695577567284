import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Axios from "axios";
import {
    Container,
    Stack,
    Paper,
    Button,
    styled,
    Grid,
    Typography,
} from "@mui/material";

import IndicatorsSummary from "../components/indicators/IndicatorsSummary";
import IndicatorsSearchHotel from "../components/indicators/IndicatorsSearchHotel";
import IndicatorsNotifications from "../components/indicators/IndicatorsNotifications";
import IndicatorsSummaryRing from "../components/indicators/IndicatorsSummaryRing";
import IndicatorsCategory from "../components/indicators/IndicatorsCategory";

import { DEFAULT_HOTEL, HOTELS_INFO, URLAPI } from "../configuration";

// Temporary data source
import data from "../temp/scoreBoardDataPreDB";
import RoundedButton from "../components/RoundedButton";
import IndicatorsTypeRange from "../components/indicators/IndicatorsTypeRange";
import IndicatorsTypeYesNo from "../components/indicators/IndicatorsTypeYesNo";
import IndicatorsTypePercentage from "../components/indicators/IndicatorsTypePercentage";
import IndicatorsTypeABCD from "../components/indicators/IndicatorsTypeABCD";
const dataTwo = JSON.parse(data);

const SButton = styled(Button)({
    fontSize: "12px",
});

const IndicatorsBasicView = ({
    score,
    pillarsScore,
    basePath,
    title,
    subtitle,
    handleRefreshIndicators,
}) => {
    return (
        <>
            <Container>
                <Grid container>
                    <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                        <Typography variant="h4">Indicators</Typography>
                    </Grid>
                </Grid>{" "}
                <br />
                {!title ? (
                    <Paper sx={{ pt: 2, pb: 2 }}>
                        <IndicatorsSummaryRing
                            score={score}
                            basePath={basePath}
                        />
                    </Paper>
                ) : (
                    <>
                        <IndicatorsSummaryRing
                            score={score}
                            title={title}
                            subtitle={subtitle}
                            basePath={basePath}
                        />
                    </>
                )}
                <br />
                <IndicatorsSummary
                    pillarsScore={pillarsScore}
                    basePath={basePath}
                    handleRefreshIndicators={handleRefreshIndicators}
                />
            </Container>
        </>
    );
};

const IndicatorsScreen = ({ basePath = "/indicators/" }) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);
    const [indicatorsData, setIndicatorsData] = useState([]);
    const [indicatorsAttract, setIndicatorsAttract] = useState([]);
    const [indicatorsEngage, setIndicatorsEngage] = useState([]);
    const [indicatorsRetain, setIndicatorsRetain] = useState([]);
    const [indicatorsConvert, setIndicatorsConvert] = useState([]);

    const getData = () => {
        Axios({
            method: "get",
            url: `${URLAPI}/indicators/get/hotel/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        }).then((response) => {
            setIndicatorsData(response.data.data);

            const CATEGORIES_DATA = {
                ATTRACT: { data: [], setter: setIndicatorsAttract },
                ENGAGE: { data: [], setter: setIndicatorsEngage },
                RETAIN: { data: [], setter: setIndicatorsRetain },
                CONVERT: { data: [], setter: setIndicatorsConvert },
            };
            response.data.data.forEach((indicator) => {
                CATEGORIES_DATA[indicator.category].data.push(indicator);
            });

            for (let category in CATEGORIES_DATA) {
                CATEGORIES_DATA[category].setter(
                    CATEGORIES_DATA[category].data
                );
            }
        });
    };

    // Temp data retrieval

    // const { data: dataPillars } = useSelector((state) => state.pillars);
    // const [pillarsScore, setPillarsScore] = useState({
    //     Acquire: 0,
    //     Convert: 0,
    //     Retain: 0,
    //     Engage: 0,
    // });
    const pillarsScore = {
        Acquire: 0,
        Convert: 0,
        Retain: 0,
        Engage: 0,
    };

    useEffect(() => {
        getData();
    }, [hotelID]);

    const [isStandalone, setIsStandalone] = useState(false);

    useEffect(() => {
        if (basePath === "/indicators/") {
            setIsStandalone(true);
        } else {
            setIsStandalone(false);
        }
    }, [basePath]);

    // Get current location
    const location = useLocation();
    const [activeRoute, setActiveRoute] = useState("home");

    const [tabTitle, setTabTitle] = useState(HOTELS_INFO[hotelID].name);
    const [tabSubtitle, setTabSubtitle] = useState("Digital Score");

    // How to navigate
    const navigate = useNavigate();

    useEffect(() => {
        setTabTitle();
        setTabSubtitle();

        const indexCurrentPath = location.pathname.indexOf("/indicators");

        if (indexCurrentPath !== -1) {
            const afterBasePath = location.pathname.substring(
                indexCurrentPath + "/indicators/".length
            );

            const [path, pillar, id] = afterBasePath.split("/");

            // let route = `${path}`;

            if (pillar) {
                // route += `/${pillar}`;
            }

            if (id) {
                // route += `/${id}`;
            }

            setActiveRoute(path);

            switch (path) {
                case "attract":
                case "convert":
                case "retain":
                case "engage":
                case "notifications":
                    setTabTitle(path.toWellFormed());
                    break;
                case "home":
                default:
                    setTabTitle(HOTELS_INFO[hotelID].name);
                    setTabSubtitle("Digital Score");
                    break;
            }
        } else {
            console.error(
                "Wrong navigation route. Expected '/indicators' to be in the path but got: ",
                location.pathname
            );
            navigate("/app/plus/indicators");
        }
    }, [location, hotelID, navigate]);

    return (
        <>
            {/* Define routes */}
            <Routes>
                <Route
                    path=""
                    element={
                        <IndicatorsBasicView
                            score={58}
                            pillarsScore={pillarsScore}
                            basePath={basePath}
                            title={isStandalone ? "" : tabTitle}
                            subtitle={isStandalone ? "" : tabSubtitle}
                            handleRefreshIndicators={getData}
                        />
                    }
                />

                <Route
                    path="search"
                    element={<IndicatorsSearchHotel basePath={basePath} />}
                />
                {["Engage", "Attract", "Convert", "Retain"].map(
                    (category, index) => {
                        let data = [];
                        // eslint-disable-next-line default-case
                        switch (category) {
                            case "Engage":
                                data = indicatorsEngage;
                                break;
                            case "Attract":
                                data = indicatorsAttract;
                                break;
                            case "Convert":
                                data = indicatorsConvert;
                                break;
                            case "Retain":
                                data = indicatorsRetain;
                                break;
                        }

                        return (
                            <Route
                                path={category}
                                element={
                                    <IndicatorsCategory
                                        who={category}
                                        pillarsScore={index} // TODO: get score of the category
                                        data={data}
                                        basePath={basePath}
                                        title={isStandalone ? "" : tabTitle}
                                        handleRefreshIndicators={getData}
                                    />
                                }
                            />
                        );
                    }
                )}

                <Route
                    path="notifications"
                    element={<IndicatorsNotifications />}
                />
            </Routes>
            <br />

            {/* Buttons for switching the route - this is just an example for simplify code */}
            <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
            >
                <SButton
                    disabled={activeRoute === "home" || activeRoute === ""}
                    onClick={() => navigate(basePath)}
                >
                    home
                </SButton>

                <SButton
                    disabled={activeRoute === "attract"}
                    onClick={() => navigate(basePath + "attract")}
                >
                    attract
                </SButton>
                <SButton
                    disabled={activeRoute === "engage"}
                    onClick={() => navigate(basePath + "engage")}
                >
                    engage
                </SButton>
                <SButton
                    disabled={activeRoute === "convert"}
                    onClick={() => navigate(basePath + "convert")}
                >
                    convert
                </SButton>
                <SButton
                    disabled={activeRoute === "retain"}
                    onClick={() => navigate(basePath + "retain")}
                >
                    retain
                </SButton>
                <SButton
                    disabled={activeRoute === "notifications"}
                    onClick={() => navigate(basePath + "notifications")}
                >
                    notifications
                </SButton>
            </Stack>
        </>
    );
};
export default IndicatorsScreen;
