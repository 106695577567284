import React, { useEffect } from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Stack,
    Box,
    useMediaQuery,
    useTheme,
    Paper,
} from "@mui/material";

import { fns } from "../helpers/common";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

import SmallPercentage from "./SmallPercentage";

const styles = {
    topTypographyStyles: {
        fontWeight: "bold",
        textAlign: "center",
        marginTop: "1em",
        marginBottom: "1em",
    },
    middleTypographyStyles: {
        textAlign: "center",
    },
    bottomTypographyStyles: {
        display: "inline-block",
    },
    bottomStackStyle: {
        justifyContent: "space-between",
        alignItems: "baseline",
    },
};

const TopCards = ({
    data,
    header,
    hotelID,
    fullYear,
    time,
    months,
    cancellations,
    processBy = "Country",
}) => {
    const formatValueOrDate = (prop, value) => {
        let leftUnit = (header.find((e) => e.id === prop) ?? {}).leftUnit ?? "";
        return `${leftUnit}${
            prop === "searchDate" || prop === "availabilityDate"
                ? new Date(value).toISOString().slice(0, 10)
                : fns(
                      hotelID,
                      value,
                      (header.find((e) => e.id === prop) ?? {}).format ?? {}
                  )
        }`;
    };

    const CalcPercentage = (data, key, formatter) => {
        return (
            <SmallPercentage
                actual={data[key]}
                old={data["prev_year"] === 0 ? 0 : data["prev_year"][key]}
                context={
                    fullYear
                        ? `Comparing ${new Date().getUTCFullYear()} vs ${
                              time.getUTCFullYear() - 1
                          }`
                        : `Comparing ${new Date().getUTCFullYear()}-${
                              months[time.getUTCMonth()]
                          } vs ${time.getUTCFullYear() - 1}-${
                              months[time.getUTCMonth()]
                          }`
                }
                formatter={formatter}
                cancellations={cancellations}
            />
        );
    };

    const allTopCards = Object.entries(data).map(([key, value], index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            {/* xs={12} sm={12} md={6} lg={4}> */}
            {/* md={12 / 3}> */}
            <Paper
                sx={{
                    p: 5,
                    pb: 3,
                    ml: 1,
                    mr: 1,
                    backgroundColor: "rgba(211, 211, 211, 0.2)",
                    // Colours.secondarySoft,
                    borderRadius: "15px",
                }}
                elevation={5}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        sx={{
                            width: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <Typography
                            noWrap
                            variant="subtitle1"
                            style={{
                                ...styles.topTypographyStyles,
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {key.toUpperCase()}
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{
                                ...styles.topTypographyStyles,
                                color: Colours.secondary,
                            }}
                        >
                            {formatValueOrDate("revenue", value.revenue)}
                        </Typography>
                    </Box>

                    <Stack
                        direction="row"
                        spacing={1}
                        style={{
                            justifyContent: "space-around",
                            marginBottom: "2em",
                        }}
                    >
                        <Stack direction="column">
                            <Typography
                                variant="body2"
                                style={styles.middleTypographyStyles}
                            >
                                Bookings
                            </Typography>
                            <Typography
                                variant="body2"
                                style={styles.middleTypographyStyles}
                            >
                                {value.bookings}
                            </Typography>
                        </Stack>

                        <Stack direction="column">
                            <Typography
                                variant="body2"
                                style={styles.middleTypographyStyles}
                            >
                                Room Nights
                            </Typography>
                            <Typography
                                variant="body2"
                                style={styles.middleTypographyStyles}
                            >
                                {value.roomNights}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack direction="column" style={{ width: "100%" }}>
                        <Stack
                            direction="row"
                            spacing={1}
                            style={styles.bottomStackStyle}
                        >
                            <Typography variant="body2">ABV</Typography>
                            <Box>
                                <Typography
                                    variant="body2"
                                    style={styles.bottomTypographyStyles}
                                >
                                    {value.ABV}
                                </Typography>
                                {CalcPercentage(value, "ABV", (number) =>
                                    fns(
                                        hotelID,
                                        number,
                                        (header.find((e) => e.id === key) ?? {})
                                            .format ?? {}
                                    )
                                )}
                            </Box>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            style={styles.bottomStackStyle}
                        >
                            <Typography variant="body2">ADR</Typography>
                            <Box>
                                <Typography
                                    variant="body2"
                                    style={styles.bottomTypographyStyles}
                                >
                                    {value.ADR}
                                </Typography>
                                {CalcPercentage(value, "ADR", (number) =>
                                    fns(
                                        hotelID,
                                        number,
                                        (header.find((e) => e.id === key) ?? {})
                                            .format ?? {}
                                    )
                                )}
                            </Box>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            style={styles.bottomStackStyle}
                        >
                            <Typography variant="body2">ALoS</Typography>
                            <Box>
                                <Typography
                                    variant="body2"
                                    style={styles.bottomTypographyStyles}
                                >
                                    {value.ALoS}
                                </Typography>
                                {CalcPercentage(value, "ALoS", (number) =>
                                    fns(
                                        hotelID,
                                        number,
                                        (header.find((e) => e.id === key) ?? {})
                                            .format ?? {}
                                    )
                                )}
                            </Box>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            style={styles.bottomStackStyle}
                        >
                            <Typography variant="body2">ABW</Typography>
                            <Box>
                                <Typography
                                    variant="body2"
                                    style={styles.bottomTypographyStyles}
                                >
                                    {value.ABW}
                                </Typography>
                                {CalcPercentage(value, "ABW", (number) =>
                                    fns(
                                        hotelID,
                                        number,
                                        (header.find((e) => e.id === key) ?? {})
                                            .format ?? {}
                                    )
                                )}
                            </Box>
                        </Stack>
                    </Stack>
                    <br />
                </Stack>
            </Paper>
        </Grid>
    ));

    return allTopCards;
};

export default TopCards;
