import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

import { Box, Grid, Skeleton, Table, TableBody, TableRow } from "@mui/material";

import EChartsReact from "echarts-for-react";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import WrapperPDFTable from "../shared/WrapperPDFTable";

import TableBodyCell from "../TableBodyCell";

import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";
import { fns } from "../../helpers/common";
import { tooltip } from "../../helpers/eCharts";

// Active Members
const MembershipTotalsReport = ({ hotelID, data, isMobile = false }) => {
    const TITLE = "Membership - Active Members";
    return (
        <Grid item sx={{ width: "100%", height: "100%" }}>
            <Grid
                container={!isMobile}
                direction={isMobile ? "column" : "row"}
                alignItems="center"
                justifyContent="center"
            >
                {data.length > 0 ? (
                    <>
                        <Grid item sm={12} md={4}>
                            <WrapperPDFEChart k={TITLE}>
                                <EChartsReact
                                    style={
                                        isMobile
                                            ? { height: "300%", width: "100%" }
                                            : {}
                                    }
                                    option={{
                                        responsive: true,

                                        tooltip: {
                                            trigger: "item",
                                            position: tooltip.position,
                                        },

                                        series: [
                                            {
                                                type: "pie",
                                                center: ["50%", "50%"],
                                                radius: ["50%", "70%"],
                                                data: data.map((element) => {
                                                    return {
                                                        name: element.MemberStatus,
                                                        value: element.Total,
                                                        itemStyle: {
                                                            color:
                                                                element.MemberStatus ===
                                                                "Active"
                                                                    ? Colours.primary
                                                                    : Colours.info,
                                                        },
                                                    };
                                                }),
                                                labelLine: {
                                                    show: false,
                                                },
                                                label: {
                                                    color: "black",
                                                    fontSize: "30",
                                                    position: "center",
                                                    formatter: () => {
                                                        return `{bold|${data.reduce(
                                                            (acc, element) =>
                                                                acc +
                                                                element.Total,
                                                            0
                                                        )}}\n{gray|Total}`;
                                                    },
                                                    rich: {
                                                        bold: {
                                                            fontWeight: "bold",
                                                            fontSize: "30px",
                                                        },
                                                        gray: {
                                                            color: Colours.midLightText,
                                                            fontSize: "20px",
                                                        },
                                                    },
                                                },
                                                avoidLabelOverlap: false,
                                            },
                                        ],
                                    }}
                                />
                            </WrapperPDFEChart>
                        </Grid>
                        <Grid item sm={12} md={8}>
                            <WrapperPDFTable k={TITLE + " values"}>
                                <Table sx={{ width: "80%" }}>
                                    <TableBody>
                                        {data.map((element, i) => (
                                            <TableRow key={i}>
                                                <TableBodyCell>
                                                    <Icons.Circle
                                                        key={
                                                            element.MemberStatus
                                                        }
                                                        sx={{
                                                            fontSize: "100%",
                                                            color:
                                                                element.MemberStatus ===
                                                                "Active"
                                                                    ? Colours.primary
                                                                    : Colours.info,
                                                        }}
                                                    />
                                                </TableBodyCell>
                                                <TableBodyCell>
                                                    {element.MemberStatus}
                                                </TableBodyCell>
                                                <TableBodyCell>
                                                    {fns(
                                                        hotelID,
                                                        element.Total
                                                    )}
                                                </TableBodyCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </WrapperPDFTable>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} md={4}>
                            <br />
                            <Skeleton
                                variant="circular"
                                align="center"
                                sx={{
                                    margin: "auto",
                                    height: "200px",
                                    width: "200px",
                                }}
                            />
                            <br />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableBodyCell
                                            colSpan={100}
                                            align="center"
                                        >
                                            <Box
                                                component="span"
                                                fontStyle="italic"
                                            >
                                                No data
                                            </Box>
                                        </TableBodyCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default MembershipTotalsReport;
