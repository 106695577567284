import React, { useEffect, useState } from "react";

import TabSelector from "./TabSelector";

import YearPerformance from "./reports/YearPerformance";

const MonthlyLostRevenue = ({ data }) => {
    const TITLE = "Lost Revenue";
    const [cardSelected, setCardSelected] = useState("sessions");
    const [lostRevenueData, setLostRevenueData] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const auxLostRevenueData = [
                {
                    id: "sessions",
                    title: "Total Sessions",
                    value: data.tabs.sessions.this,
                    old: data.tabs.sessions.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "searches",
                    title: "Total Searches",
                    value: data.tabs.searches.this,
                    old: data.tabs.searches.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "noAvSessions",
                    title: "No Av. Sessions",
                    value: data.tabs.noAvSessions.this,
                    old: data.tabs.noAvSessions.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "noAvSearches",
                    title: "No Av. Searches",
                    value: data.tabs.noAvSearches.this,
                    old: data.tabs.noAvSearches.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "lostRevenue",
                    title: "Lost Revenue",
                    value: data.tabs.lostRevenue.this,
                    old: data.tabs.lostRevenue.last,
                    expand: true,
                    compare: true,
                },
            ];
            setLostRevenueData(auxLostRevenueData);
        }
    }, [data]);

    return (
        <div>
            <TabSelector
                // hotelID={hotelID}
                data={lostRevenueData}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
            />
            <br />

            {(cardSelected === "sessions" ||
                cardSelected === "searches" ||
                cardSelected === "noAvSessions" ||
                cardSelected === "noAvSearches" ||
                cardSelected === "lostRevenue") && (
                <YearPerformance
                    TITLE={`${TITLE} - ${
                        lostRevenueData.length > 0
                            ? lostRevenueData.find(
                                  (head) => head.id === cardSelected
                              ).title ?? ""
                            : ""
                    }`}
                    dataLast={data.tabs[cardSelected].historical.last}
                    dataThis={data.tabs[cardSelected].historical.this}
                />
            )}
        </div>
    );
};

export default MonthlyLostRevenue;
