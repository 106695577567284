import React, { useState, useEffect } from "react";

import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import Colours from "../../helpers/colours";
import { valuePost } from "../../helpers/indicators";
import CircleValue from "../CircleValue";

export const colourTypeYesNo = (value) =>
    value ? Colours.trafficLightGreen : Colours.trafficLightRed;

export const processTypeYesNo = (data) => {
    let value = 0;
    let status = "";
    let priority = "";

    let ts_value = null;
    data.values
        .sort((a, b) => a.ts - b.ts)
        .forEach((element) => {
            if (!ts_value || new Date(element.ts) > new Date(ts_value)) {
                value = element.value_ === "True";
                ts_value = element.ts;
            }
        });

    let ts_sp = null;
    data.status.forEach((element) => {
        if (!ts_sp || new Date(element.ts) > new Date(ts_sp)) {
            status = element.status;
            priority = element.priority;
            ts_sp = element.ts;
        }
    });

    return {
        type: "YESNO",
        value,
        status,
        priority,
        colour: value ? Colours.trafficLightGreen : Colours.trafficLightRed,
    };
};

const IndicatorsTypeYesNo = ({
    hotelID = null,
    new_ = false,
    edit = false,
    data = {},
    rawData = {},
    type,
    utilitySetter = () => {},
    errorForm,
}) => {
    const [yesNoValue, setYesNoValue] = useState(false);

    useEffect(() => {
        if (data.hasOwnProperty("value")) {
            setYesNoValue(data.value);
        }
    }, [data, type]);

    const modifiedType = () => yesNoValue !== data.value;

    const isValid = () => yesNoValue === true || yesNoValue === false;

    const post = async (auth, indicatorID) => {
        if (new_ || modifiedType()) {
            try {
                if (new_ || (!new_ && yesNoValue !== data.value)) {
                    const responseYesNoValue = await valuePost(
                        auth,
                        indicatorID,
                        "yesno",
                        null,
                        yesNoValue ? "True" : "False",
                        new_ ? null : hotelID
                    );
                    if (responseYesNoValue.status !== 201) {
                        let error = new Error(
                            "Unexpected response posting value."
                        );
                        error.response = responseYesNoValue;
                        error.value = yesNoValue;
                        throw error;
                    }
                }
            } catch (error) {
                let errorStr = "Failed posting YesNo value.";
                console.error(errorStr, { error });
                let errorPost = new Error(errorStr);
                errorPost.previousError = error;
                throw errorPost;
            }
        }
    };

    useEffect(() => {
        const utility = {
            post: post,
            isValid: isValid,
        };
        if (!new_) utility.modifiedType = modifiedType;
        utilitySetter(utility);
    }, [utilitySetter, yesNoValue]);

    return edit ? (
        <Stack direction="row" justifyContent={"space-between"}>
            <Typography
                fontWeight="bold"
                display={"flex"}
                alignItems={"center"}
            >
                Yes / No Value{" "}
            </Typography>
            {/* <FormControlLabel
                control={
                    <Switch
                        checked={yesNoValue}
                        onChange={(event) => {
                            setYesNoValue(event.target.checked);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                }
                label={yesNoValue ? "Yes" : "No"}
            /> */}

            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="yesno-radio-buttons-group"
                    value={yesNoValue ? "yes" : "no"}
                    onChange={(event, newVal) => {
                        setYesNoValue(newVal === "yes");
                    }}
                    name="radio-buttons-group"
                >
                    <FormControlLabel
                        value={"yes"}
                        control={<Radio />}
                        label="Yes"
                    />

                    <FormControlLabel
                        value={"no"}
                        control={<Radio />}
                        label="No"
                    />
                </RadioGroup>
            </FormControl>
        </Stack>
    ) : (
        <Stack direction="row" justifyContent={"space-between"}>
            <Typography
                fontWeight="bold"
                display={"flex"}
                alignItems={"center"}
            >
                Yes / No Value{" "}
            </Typography>
            <CircleValue
                value={yesNoValue ? "Yes" : "No"}
                color={colourTypeYesNo(yesNoValue)}
                size="big"
            />
        </Stack>
    );
};

export default IndicatorsTypeYesNo;
