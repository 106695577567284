import React, { useState, useEffect } from "react";

import { Box, Stack, TextField, Typography } from "@mui/material";
import Colours from "../../helpers/colours";
import { valuePost } from "../../helpers/indicators";
import CircleValue from "../CircleValue";

export const colourTypePercentage = (value, reference) =>
    value >= reference ? Colours.trafficLightGreen : Colours.trafficLightRed;

export const processTypePercentage = (data) => {
    let reference = 0;
    let value = 0;
    let oldValue = 0;
    let status = "";
    let priority = "";

    let ts_value = null;
    let last_value = 0;
    let ts_reference = null;
    data.values
        .sort((a, b) => a.ts - b.ts)
        .forEach((element) => {
            if (element.subtype === "REFERENCE") {
                if (
                    !ts_reference ||
                    new Date(element.ts) > new Date(ts_reference)
                ) {
                    reference = Number(element.value_);
                    ts_reference = element.ts;
                }
            }
            if (element.subtype === "VALUE") {
                if (!ts_value || new Date(element.ts) > new Date(ts_value)) {
                    oldValue = last_value; //TODO: check this
                    last_value = Number(element.value_);
                    value = last_value;
                    ts_value = element.ts;
                }
            }
        });

    let ts_sp = null;
    data.status.forEach((element) => {
        if (!ts_sp || new Date(element.ts) > new Date(ts_sp)) {
            status = element.status;
            priority = element.priority;
            ts_sp = element.ts;
        }
    });

    return {
        type: "PERCENTAGE",
        reference,
        value,
        oldValue,
        status,
        priority,
        colour:
            value >= reference
                ? Colours.trafficLightGreen
                : Colours.trafficLightRed,
    };
};

const IndicatorsTypePercentage = ({
    hotelID = null,
    new_ = false,
    edit = false,
    data = {},
    rawData = {},
    type,
    utilitySetter = () => {},
    errorForm,
}) => {
    const [initialValue, setInitialValue] = useState(0);
    const [referenceValue, setReferenceValue] = useState(0);

    useEffect(() => {
        if (data.hasOwnProperty("value") && data.hasOwnProperty("reference")) {
            setInitialValue(data.value);
            setReferenceValue(data.reference);
        }
    }, [data, type]);

    const modifiedType = () =>
        initialValue !== data.value || referenceValue !== data.reference;

    const isValid = () => {
        return (
            (new_ ? true : referenceValue !== "" && referenceValue >= 0) &&
            initialValue !== "" &&
            initialValue >= 0
        );
    };

    const post = async (auth, indicatorID) => {
        if (new_ || modifiedType()) {
            try {
                if (!new_ && referenceValue !== data.reference) {
                    const responseReference = await valuePost(
                        auth,
                        indicatorID,
                        "percentage",
                        "reference",
                        referenceValue,
                        hotelID
                    );
                    if (responseReference.status !== 201) {
                        let error = new Error(
                            "Unexpected response posting REFERENCE."
                        );
                        error.response = responseReference;
                        error.value = referenceValue;
                        throw error;
                    }
                }

                if (new_ || (!new_ && initialValue !== data.value)) {
                    //     insert("percentage", "value", initialValue);
                    const responseInitialValue = await valuePost(
                        auth,
                        indicatorID,
                        "percentage",
                        "value",
                        initialValue,
                        hotelID
                    );
                    if (responseInitialValue.status !== 201) {
                        let error = new Error(
                            "Unexpected response posting INITIAL VALUE."
                        );
                        error.response = responseInitialValue;
                        error.value = initialValue;
                        throw error;
                    }
                }
            } catch (error) {
                let errorStr = "Failed posting Percentage values.";
                console.error(errorStr, { error });
                let errorPost = new Error(errorStr);
                errorPost.previousError = error;
                throw errorPost;
            }
        }
    };

    useEffect(() => {
        const utility = {
            post: post, // TODO: control fail posts in both (return true / false won't work, we have to return a promise so we can wait for its result)
            isValid: isValid,
        };
        if (!new_) utility.modifiedType = modifiedType;
        utilitySetter(utility);
    }, [utilitySetter, initialValue, referenceValue]);

    return edit ? (
        <>
            <TextField
                fullWidth
                label="Value"
                value={initialValue}
                onChange={(e) => {
                    if (e.target.value !== "")
                        setInitialValue(Number(e.target.value));
                    else setInitialValue("");
                }}
                type="number"
                required
                error={
                    errorForm ? initialValue === "" || initialValue < 0 : false
                }
                helperText={
                    errorForm
                        ? initialValue === ""
                            ? ""
                            : initialValue < 0
                            ? "Value cannot be negative"
                            : false
                        : false
                }
            />

            {!new_ && (
                <TextField
                    fullWidth
                    label="Reference Value"
                    value={referenceValue}
                    onChange={(e) => {
                        if (e.target.value !== "")
                            setReferenceValue(Number(e.target.value));
                        else setReferenceValue("");
                    }}
                    type="number"
                    required
                    error={
                        errorForm
                            ? referenceValue === "" || referenceValue < 0
                            : false
                    }
                    helperText={
                        errorForm
                            ? referenceValue === ""
                                ? ""
                                : referenceValue < 0
                                ? "Reference value cannot be negative"
                                : false
                            : false
                    }
                />
            )}
        </>
    ) : (
        <Stack direction={"column"} spacing={2}>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography
                    fontWeight="bold"
                    display={"flex"}
                    alignItems={"center"}
                >
                    Value{" "}
                </Typography>
                <CircleValue
                    value={initialValue}
                    color={colourTypePercentage(initialValue, referenceValue)}
                    size="big"
                />
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography
                    fontWeight="bold"
                    display={"flex"}
                    alignItems={"center"}
                >
                    Reference Value{" "}
                </Typography>
                <CircleValue
                    value={referenceValue}
                    color={Colours.trafficLightGreen}
                    size="big"
                />
            </Stack>
        </Stack>
    );
};

export default IndicatorsTypePercentage;
