import React from "react";

import { Container, Paper } from "@mui/material";

import RedAlerts from "../components/RedAlerts";

const RedAlertsScreen = () => {
    return (
        <>
            <br />
            <Container component={Paper}>
                <br />
                <RedAlerts />
                <br />
            </Container>
            <br />
        </>
    );
};
export default RedAlertsScreen;
