//TODO: update comments to match show instead of hide
import React from "react";
import { useSelector } from "react-redux";
import { HOTELS_INFO } from "../../configuration";

export const CheckShowBool = (k, hotelID) => {
    const shows = (HOTELS_INFO[hotelID] ?? {}).show ?? {};
    if (shows[k] ?? 0) return true;
    return false;
};
/**
 * This component checks if the selected hotel has the option `{hide: {NNNN: 1}}`
 * If that NNNN key matches the k param provided, it returns empty.
 * If there is not hide option, if in the hide option there is not NNNN key, or if this key is 0, returns the children
 * @param {String} k
 * @param {Component} children
 * @param {Boolean} forceShow when is present, it always show it
 * @param {Array} rolesThatCanSee array of roles, if any of your roles is in that list, the component will be shown always
 */
const CheckShow = ({
    k,
    children,
    forceShow,
    rolesThatCanSee = [],
    useEditedRoles = true,
    alternative = null,
}) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const authRoles = useSelector((state) => state.authRoles.edited);
    const authRolesOriginal = useSelector((state) => state.authRoles.original);

    if (forceShow || CheckShowBool(k, hotelID)) {
        return children;
    }

    if (
        (useEditedRoles ? authRoles : authRolesOriginal).length > 0 &&
        rolesThatCanSee.length > 0 &&
        rolesThatCanSee.some((value) =>
            (useEditedRoles ? authRoles : authRolesOriginal).includes(value)
        )
    ) {
        return children;
    }

    return alternative;
};

export default CheckShow;

/**
 * For Tab elements use like this:
        {CheckShow({
            k: "Vouchers",
            children: (
                <Tab
                    wrapped
                    label="Vouchers"
                    id="VouchersReport"
                />
            ),
        })}
 *
 * Other elements:
        <CheckShow k="Vouchers">
            <Grid item sm={12} md={12} sx={{ width: "100%" }}>
                <VouchersRevenueReport hotelID={hotelID} />
            </Grid>
        </CheckShow>
 */
