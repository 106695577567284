import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, Skeleton, Stack, Typography } from "@mui/material";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import { fns } from "../helpers/common";
import HoverPaper from "./shared/HoverPaper";

const CardDashboard = ({
    title,
    subtitle,
    value,
    format,
    subbody,
    footer,
    isIncrease,
    selected,
    selectedColoured,
    colours = { selected: Colours.secondarySoft, unselected: null },
    loading = false,
    elevation = 10,
    noArrow = false,
    pointer = false,
    isInverted = false,
}) => {
    const [body, setBody] = useState("");

    const { id: hotelID } = useSelector((state) => state.hotelID);

    const {
        status,
        settings: { readability = false },
    } = useSelector((state) => state.settings);

    const [readImprove, setReadImprove] = useState(false);

    useEffect(() => {
        setReadImprove(
            readability === true || readability === "True" ? true : false
        );
    }, [readability]);

    useEffect(() => {
        let auxBody = fns(hotelID, value, format, true);

        setBody(auxBody);
    }, [value, format]);

    return (
        <HoverPaper
            sx={{
                cursor: pointer ? "pointer" : "",
                p: 1,
                height: "100%",
                backgroundColor:
                    selected & selectedColoured
                        ? colours.selected
                        : colours.unselected,
            }}
            {...(selected ? { elevation: elevation } : {})}
        >
            <Stack
                direction="column"
                alignItems="baseline"
                sx={{ height: "100%" }}
            >
                {loading ? (
                    <Skeleton variant="text" width={"60%"} />
                ) : (
                    <Stack direction="column" spacing={0}>
                        <Stack direction={"row"}>
                            {/* Title */}
                            <Typography
                                fontWeight={"bold"}
                                sx={{
                                    color:
                                        selected & selectedColoured
                                            ? Colours.secondary
                                            : "",
                                }}
                                fontSize={readImprove ? "1.2em" : ""}
                            >
                                {body.left}
                                {typeof body.number === "string" &&
                                body.number.length > 20
                                    ? body.number.substring(0, 20) + "..."
                                    : body.number}
                                {body.right}
                            </Typography>

                            {/* Arrow */}
                            {!noArrow && (
                                <>
                                    {isIncrease ? (
                                        <Icons.ArrowUp
                                            sx={{
                                                color: isInverted
                                                    ? Colours.trafficLightRed
                                                    : Colours.trafficLightGreen,
                                            }}
                                        />
                                    ) : (
                                        <Icons.ArrowDown
                                            sx={{
                                                color: isInverted
                                                    ? Colours.trafficLightGreen
                                                    : Colours.trafficLightRed,
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </Stack>
                        {/* Percentage */}

                        <Typography
                            variant="caption"
                            color={Colours.midLightText}
                            fontSize={readImprove ? "1em" : ""}
                        >
                            {footer || <br />}
                        </Typography>
                    </Stack>
                )}
                <Box color={Colours.midLightText}>{subbody || <br />}</Box>

                <Stack>
                    <Typography
                        variant="caption"
                        fontSize={readImprove ? "1em" : ""}
                    >
                        {title}
                    </Typography>
                    <Typography variant="caption" color={Colours.midLightText}>
                        {subtitle || ""}
                    </Typography>
                </Stack>
            </Stack>
        </HoverPaper>
    );
};

export default CardDashboard;
