import React, { Fragment, useEffect, useState } from "react";

import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";

import TableHeaderCell from "../TableHeaderCell";

import { fns } from "../../helpers/common";
import Colours from "../../helpers/colours";
import InfoIcon from "../InfoIcon";

const MembershipTableReport = ({
    hotelID,
    time,
    dataTotalsRaw,
    dataSignupsRaw,
    dataRepeatBookersRaw,
    dataBookingsRaw,
    dataDetailsRaw,
    status,
}) => {
    const HOTELS = {
        92: "ILH Group",
        93: "Travistock Hotel",
        94: "President Hotel",
        95: "The Morton Hotel",
        96: "Bedford Hotel",
        97: "Royal National Hotel",
        107: "City Sleeper Hotel",
    };

    // TOTALS
    const [dataTotals, setDataTotals] = useState(-1);
    useEffect(() => {
        if (dataTotalsRaw.length > 0) {
            const processedTotals = dataTotalsRaw[1].Total;
            setDataTotals(processedTotals);
        }
    }, [dataTotalsRaw]);

    // SIGNUPS
    const [dataSignups, setDataSignups] = useState({});

    useEffect(() => {
        if (Object.keys(dataSignupsRaw).length > 0) {
            let processedSignups = {};
            for (let year in dataSignupsRaw.summary) {
                processedSignups[year] = {};
                for (let month in dataSignupsRaw.summary[year]) {
                    processedSignups[year][month] =
                        dataSignupsRaw.summary[year][month].Total;
                }
            }
            setDataSignups(processedSignups);
        }
    }, [dataSignupsRaw]);

    // REPEAT BOOKERS
    const [dataRepeatBookers, setDataRepeatBookers] = useState({});
    useEffect(() => {
        if (Object.keys(dataRepeatBookersRaw).length > 0) {
            setDataRepeatBookers(dataRepeatBookersRaw);
        }
    }, [dataRepeatBookersRaw]);

    // BOOKINGS
    const [dataBookings, setDataBookings] = useState({});
    useEffect(() => {
        if (Object.keys(dataBookingsRaw).length > 0) {
            let processedBookings = {};
            for (let hotel in dataBookingsRaw) {
                processedBookings[hotel] = {};
                for (let year in dataBookingsRaw[hotel].summary) {
                    processedBookings[hotel][year] = {};
                    for (let month in dataBookingsRaw[hotel].summary[year]) {
                        processedBookings[hotel][year][month] =
                            dataBookingsRaw[hotel].summary[year][
                                month
                            ].MemberBookings;
                    }
                }
            }

            setDataBookings(processedBookings);
        }
    }, [dataBookingsRaw]);

    // DETAILS
    const [dataALoS, setDataALoS] = useState({});
    const [dataRoomNights, setDataRoomNights] = useState({});
    useEffect(() => {
        if (Object.keys(dataDetailsRaw).length > 0) {
            let roomNights = {};
            let ALoS = {};

            for (let hotel in dataDetailsRaw) {
                roomNights[hotel] = {};
                ALoS[hotel] = {};
                for (let year in dataDetailsRaw[hotel].summary) {
                    roomNights[hotel][year] = {};
                    ALoS[hotel][year] = {};

                    for (let month in dataDetailsRaw[hotel].summary[year]) {
                        roomNights[hotel][year][month] =
                            dataDetailsRaw[hotel].summary[year][
                                month
                            ].RoomNights;
                        ALoS[hotel][year][month] =
                            dataDetailsRaw[hotel].summary[year][month].ALoS;
                    }
                }
            }
            setDataRoomNights(roomNights);
            setDataALoS(ALoS);
        }
    }, [dataDetailsRaw]);

    const getHeader = () => {
        return {
            dataBookings: { title: "Bookings", data: dataBookings },
            dataALoS: {
                title: "ALoS",
                data: dataALoS,
                format: { maxDecimals: 2 },
            },
            dataRoomNights: { title: "Room Nights", data: dataRoomNights },
            dataTotals: {
                title: "Active Members",
                data: dataTotals,
                info: (
                    <InfoIcon
                        placement="top"
                        text={
                            <div>
                                Total people with a registered and confirmed
                                account
                            </div>
                        }
                    />
                ),
            },
            dataSignups: {
                title: "Sign Ups",
                data: dataSignups,
                info: (
                    <InfoIcon
                        placement="top"
                        text={
                            <div>
                                Number of people that has registered an account
                            </div>
                        }
                    />
                ),
            },
            dataRepeatBookers: {
                title: "Repeat Bookers",
                data: dataRepeatBookers,
                info: (
                    <InfoIcon
                        placement="top"
                        text={
                            <div>
                                A repeat booker is one who previously booked
                                prior to the start of the booking month (same
                                month repeats are not counted).
                                <br />
                                <b>Retrieved after the month ends.</b>
                            </div>
                        }
                    />
                ),
            },
        };
    };
    const [header, setHeader] = useState(getHeader());

    useEffect(() => {
        const auxHeader = getHeader();
        setHeader(auxHeader);
    }, [
        dataTotals,
        dataSignups,
        dataRepeatBookers,
        dataBookings,
        dataALoS,
        dataRoomNights,
    ]);

    return (
        <>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        {Object.values(header)
                            .slice(0, 3)
                            .map((head, index) => {
                                return (
                                    <TableHeaderCell
                                        key={index}
                                        sx={{
                                            textAlign: "right",
                                        }}
                                    >
                                        {head.title}
                                    </TableHeaderCell>
                                );
                            })}
                    </TableRow>

                    {Object.keys(HOTELS).map((hotel_id) => {
                        return (
                            <TableRow
                                key={hotel_id}
                                sx={
                                    hotel_id === "92"
                                        ? {
                                              backgroundColor:
                                                  Colours.notificationCard,
                                          }
                                        : {}
                                }
                            >
                                <TableHeaderCell
                                    sx={{
                                        textAlign: "left",
                                    }}
                                >
                                    {HOTELS[hotel_id]}
                                </TableHeaderCell>

                                {status === "loaded" ? (
                                    Object.values(header)
                                        .slice(0, 3)
                                        .map((head, index) => {
                                            return (
                                                <Fragment key={head.title}>
                                                    {Object.keys(head.data)
                                                        .length > 0 ? (
                                                        <TableHeaderCell>
                                                            <Typography
                                                                display={"flex"}
                                                                justifyContent={
                                                                    "flex-end"
                                                                }
                                                                sx={
                                                                    hotel_id ===
                                                                        "92" &&
                                                                    head.title ===
                                                                        "Repeat Bookers"
                                                                        ? {
                                                                              textDecoration:
                                                                                  "underline",
                                                                          }
                                                                        : {}
                                                                }
                                                            >
                                                                {fns(
                                                                    hotel_id,
                                                                    ((head.data[
                                                                        hotel_id
                                                                    ] ?? {})[
                                                                        time.getUTCFullYear()
                                                                    ] ?? {})[
                                                                        time.getUTCMonth()
                                                                    ] ?? 0,
                                                                    head.format ??
                                                                        {}
                                                                )}
                                                            </Typography>
                                                        </TableHeaderCell>
                                                    ) : (
                                                        <TableHeaderCell>
                                                            <Typography
                                                                display={"flex"}
                                                                justifyContent={
                                                                    "center"
                                                                }
                                                            >
                                                                No data
                                                            </Typography>
                                                        </TableHeaderCell>
                                                    )}
                                                </Fragment>
                                            );
                                        })
                                ) : (
                                    <TableHeaderCell colSpan={3}>
                                        <Typography
                                            display={"flex"}
                                            justifyContent={"center"}
                                        >
                                            Loading...
                                        </Typography>
                                    </TableHeaderCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <br />
            <Table>
                <TableBody>
                    <TableRow>
                        {Object.values(header)
                            .slice(3)
                            .map((head) => {
                                return (
                                    <TableHeaderCell
                                        key={head.title}
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {head.title}
                                        {head.info ?? ""}
                                    </TableHeaderCell>
                                );
                            })}
                    </TableRow>
                    <TableRow
                        sx={{ backgroundColor: Colours.notificationCard }}
                    >
                        <TableCell>
                            <Typography
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                {dataTotals < 0
                                    ? "Error processing the data"
                                    : fns(hotelID, dataTotals)}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                {(dataSignups[time.getUTCFullYear()] ?? {})[
                                    time.getUTCMonth()
                                ] || false
                                    ? fns(
                                          hotelID,
                                          dataSignups[time.getUTCFullYear()][
                                              time.getUTCMonth()
                                          ]
                                      )
                                    : status === "loaded"
                                    ? "No data"
                                    : "Loading..."}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                {(
                                    (dataRepeatBookers[time.getUTCFullYear()] ??
                                        {})[time.getUTCMonth() + 1] ?? {}
                                ).MemberRepeatBookings || false
                                    ? fns(
                                          hotelID,

                                          dataRepeatBookers[
                                              time.getUTCFullYear()
                                          ][time.getUTCMonth() + 1]
                                              .MemberRepeatBookings ?? 0
                                      )
                                    : status === "loaded"
                                    ? "No data"
                                    : "Loading..."}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default MembershipTableReport;
