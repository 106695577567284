import React, { useEffect } from "react";
import { Grid, Box, useMediaQuery, useTheme } from "@mui/material";

import Carousel from "react-material-ui-carousel";

const ArrayIntoPieces = (arr, N) => {
    // console.log(
    //     arr,
    //     N,
    //     Array.from({ length: Math.ceil(arr.length / N) }, (v, i) =>
    //         arr.slice(i * N, i * N + N)
    //     )
    // );

    // return Array.from({ length: Math.ceil(arr.length / N) }, (v, i) =>
    //     arr.slice(i * N, i * N + N)
    // );

    const len = arr.length;
    let counter = 0;
    const newArray = [];

    for (let i = 0; i < len - N + 1; i += N) {
        newArray[counter++] = arr.slice(i, i + N);
    }

    if (len % N !== 0) {
        newArray[counter] = arr.slice(len - (len % N), len);
    }

    return newArray;
};

const CardCarousel = ({ data }) => {
    const theme = useTheme();
    let isXs = useMediaQuery(theme.breakpoints.down("xs"));
    let isSm = useMediaQuery(theme.breakpoints.down("sm"));
    let isMd = useMediaQuery(theme.breakpoints.down("md"));
    let isLg = useMediaQuery(theme.breakpoints.down("lg"));

    let size = isXs ? 1 : isSm ? 1 : isMd ? 2 : isLg ? 3 : 4;

    return (
        <>
            <Box width={"100%"}>
                <Carousel animation={"slide"} autoPlay={false} duration={500}>
                    {ArrayIntoPieces(data, size).map((group, index) => (
                        <Grid
                            container
                            key={index}
                            // justifyContent="center"
                            // alignItems="center"
                            sx={{ width: "100%" }}
                        >
                            {group.map((G) => G)}
                        </Grid>
                    ))}
                </Carousel>
            </Box>
        </>
    );
};

export default CardCarousel;
