import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "../styles/print.css";

import {
    Checkbox,
    Collapse,
    Drawer,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";

import TableHeaderCell from "./TableHeaderCell";
import RoundedButton from "./RoundedButton";
import NoDataRow from "./NoDataRow";

import Icons from "../helpers/icons";

import { AROLOGO, HOTELS_INFO } from "../configuration";
import { loadOne, removeOne } from "../actions/PDFExport";

import Colours from "../helpers/colours";
import GoBack from "./GoBack";

const OPENED_SIZE = 300;
const CLOSED_SIZE = 20;

const AUTO_OPEN_ZONE = 10;

const BUTTON_SIZE = 30;

const Tables2Print = () => {
    const [open, setOpen] = useState(false);
    const [keepOpen, setKeepOpen] = useState(true);

    const handleMouseMove = (e) => {
        const x = e.clientX;

        if (x <= AUTO_OPEN_ZONE) {
            setOpen(true);
        }
    };

    const printRef = useRef();
    const dispatch = useDispatch();

    const tables = useSelector((state) => state.PDFExport.data);
    const pdfstate = useSelector((state) => state.PDFExport);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [data, setData] = useState([]);

    const [documentTitle, setDocumentTitle] = useState(
        `${HOTELS_INFO[hotelID].name.replaceAll(" ", "")}_${new Date()
            .toISOString()
            .slice(0, 16)}`
    );
    const [printScale, setPrintScale] = useState(1);
    const [printMargin, setPrintMargin] = useState(8);
    const [newPagePerReport, setNewPagePerReport] = useState(true);
    const [reportsOptions, setReportsOptions] = useState({});

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Printed-Tables",
        // onAfterPrint: () =>
        //     dispatch(/* clean if needed */),
    });

    useEffect(() => {
        if (tables) {
            let auxReportsOptions = {};
            let auxData = Object.entries(tables).map(([key, data]) => {
                auxReportsOptions[key] = {
                    ...(tables[key].options ?? {}),
                    ...(reportsOptions[key] ?? {}),
                };

                return [key, data.component];
            });
            setData(auxData);
            setReportsOptions(auxReportsOptions);
        }
    }, [tables]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newData = Array.from(data);
        const [relocatedItem] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, relocatedItem);

        setData(newData);
    };

    const handleDelete = (index, key) => {
        let auxData = [...data];

        let auxReportsOptions = {
            ...reportsOptions,
        };
        delete auxReportsOptions[key];

        const baseName = key.replace(/\d+$/, "");
        auxData.splice(index, 1);

        // Get all items with the same base name
        const relatedItems = auxData.filter(([name, report]) =>
            name.startsWith(baseName)
        );

        // Rename the remaining items to maintain sequence
        relatedItems.forEach(([name, report], idx) => {
            const newIndex = idx + 1;
            const oldIndex = parseInt(name.match(/\d+$/)[0]);
            if (newIndex !== oldIndex) {
                const newItem = `${baseName}${newIndex}`;
                auxData[
                    auxData.map(([origName, report]) => origName).indexOf(name)
                ] = [newItem, report];
                auxReportsOptions[newItem] = auxReportsOptions[name];
            }
        });

        setData(auxData);

        setReportsOptions(auxReportsOptions);
    };

    const handleDuplicate = (key, index, report) => {
        let auxData = [...data];

        let baseName = key.replace(/\d+$/, "");

        // Find the highest number for the same base name
        const numbers = auxData
            .filter(([name, report]) => name.startsWith(baseName))
            .map(([name, report]) => parseInt(name.match(/\d+$/)))
            .filter((num) => !isNaN(num));

        const newNumber = numbers.length ? Math.max(...numbers) + 1 : 1;
        const copyKey = `${baseName}${newNumber}`;

        auxData.splice(index + 1, 0, [copyKey, report]);

        setData(auxData);

        let auxReportsOptions = {
            ...reportsOptions,
        };
        auxReportsOptions[copyKey] = {
            ...reportsOptions[key],
        };
        setReportsOptions(auxReportsOptions);
    };

    return (
        // TODO: Hide SideMenu only if there is reports available, so both menus don't overlap
        <div>
            {/*  _____  _    ____  _     _____ 
                |_   _|/ \  | __ )| |   | ____|
                  | | / _ \ |  _ \| |   |  _|  
                  | |/ ___ \| |_) | |___| |___ 
                  |_/_/   \_\____/|_____|_____|*/}
            {data.length > 0 ? (
                <>
                    <div
                        onMouseMove={handleMouseMove}
                        onMouseLeave={() => {
                            setOpen(false);
                        }}
                        style={{ display: "flex", marginLeft: "1em" }}
                    >
                        <IconButton
                            size="small"
                            style={{
                                width: BUTTON_SIZE,
                                height: BUTTON_SIZE,
                                position: "fixed",
                                top: "100px",
                                right:
                                    open || keepOpen
                                        ? `${OPENED_SIZE - BUTTON_SIZE / 2}px`
                                        : `${CLOSED_SIZE / 2}px`,
                                backgroundColor: Colours.backgroundWhite,
                                zIndex: 1200,
                                transition:
                                    "all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                                border: `1px solid ${Colours.navbarBottomLine}`,
                            }}
                            onClick={() => {
                                if (open || keepOpen) {
                                    setKeepOpen(false);
                                    setOpen(false);
                                } else {
                                    setKeepOpen(true);
                                    setOpen(true);
                                }
                            }}
                        >
                            {open || keepOpen ? (
                                <Icons.ArrowRight fontSize="inherit" />
                            ) : (
                                <Icons.ArrowLeft fontSize="inherit" />
                            )}
                        </IconButton>

                        <Drawer
                            variant="permanent"
                            open={open || keepOpen}
                            sx={{
                                position: "absolute",
                                zIndex: 1199,
                                width:
                                    open || keepOpen
                                        ? OPENED_SIZE
                                        : CLOSED_SIZE,
                                transition: (theme) =>
                                    theme.transitions.create("width", {
                                        easing: theme.transitions.easing.sharp,
                                        duration:
                                            theme.transitions.duration
                                                .enteringScreen,
                                    }),
                                "& .MuiDrawer-paper": {
                                    background: "#fafafa",
                                    borderLeft:
                                        open || keepOpen
                                            ? `1px solid ${Colours.navbarBottomLine}`
                                            : "transparent",
                                    justifyContent: "space-start",
                                    overflowX: "hidden",
                                    width:
                                        open || keepOpen
                                            ? OPENED_SIZE
                                            : CLOSED_SIZE,
                                    transition: (theme) =>
                                        theme.transitions.create("width", {
                                            easing: theme.transitions.easing
                                                .sharp,
                                            duration:
                                                theme.transitions.duration
                                                    .leavingScreen,
                                        }),
                                },
                            }}
                            anchor={"right"}
                        >
                            {(open || keepOpen) && (
                                <>
                                    {/* ↓ This is for reserving some room for the TopBar */}
                                    <Toolbar />
                                    {/* {ELEMENTS.filter((e) => e).map((e) => (
                                            <React.Fragment key={e.title}>
                                                
                                                <br />
                                            </React.Fragment>
                                        ))} */}

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <Table
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableHeaderCell
                                                                colspan={2}
                                                            >
                                                                Selected Reports
                                                            </TableHeaderCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.map(
                                                            (
                                                                [key, report],
                                                                index
                                                            ) => (
                                                                <Draggable
                                                                    key={key}
                                                                    draggableId={
                                                                        key
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    {(
                                                                        provided
                                                                    ) => (
                                                                        <TableRow
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <TableCell
                                                                                sx={{
                                                                                    borderBottom:
                                                                                        "1px solid lightgrey",

                                                                                    paddingLeft: 2,
                                                                                    paddingY: 1,
                                                                                    paddingRight: 1,
                                                                                }}
                                                                            >
                                                                                <Tooltip title="Visible">
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={
                                                                                                    (
                                                                                                        reportsOptions[
                                                                                                            key
                                                                                                        ] ??
                                                                                                        {}
                                                                                                    )
                                                                                                        .visible ??
                                                                                                    false
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    let checked =
                                                                                                        reportsOptions[
                                                                                                            key
                                                                                                        ]
                                                                                                            .visible;
                                                                                                    let auxReportsOptions =
                                                                                                        {
                                                                                                            ...reportsOptions,
                                                                                                        };
                                                                                                    auxReportsOptions[
                                                                                                        key
                                                                                                    ].visible =
                                                                                                        !checked;

                                                                                                    setReportsOptions(
                                                                                                        auxReportsOptions
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                                {
                                                                                    key
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell
                                                                                sx={{
                                                                                    borderBottom:
                                                                                        "1px solid lightgrey",

                                                                                    paddingRight: 2,
                                                                                    paddingLeft: 0,
                                                                                    paddingY: 1,
                                                                                }}
                                                                            >
                                                                                <Tooltip title="Delete">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={
                                                                                            () => {
                                                                                                handleDelete(
                                                                                                    index,
                                                                                                    key
                                                                                                );
                                                                                            }
                                                                                            // dispatch(
                                                                                            //     removeOne(
                                                                                            //         key,
                                                                                            //         hotelID
                                                                                            //     )
                                                                                            // )
                                                                                        }
                                                                                    >
                                                                                        <Icons.Delete fontSize="small" />
                                                                                    </IconButton>
                                                                                </Tooltip>

                                                                                {reportsOptions.hasOwnProperty(
                                                                                    key
                                                                                ) &&
                                                                                    reportsOptions[
                                                                                        key
                                                                                    ]
                                                                                        .duplicable && (
                                                                                        <Tooltip title="Duplicate">
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={() => {
                                                                                                    handleDuplicate(
                                                                                                        key,
                                                                                                        index,
                                                                                                        report
                                                                                                    );

                                                                                                    // dispatch(
                                                                                                    //     loadOne(
                                                                                                    //         report,
                                                                                                    //         copyKey,
                                                                                                    //         hotelID
                                                                                                    //     )
                                                                                                    // );
                                                                                                }}
                                                                                            >
                                                                                                <Icons.Add fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        )}
                                                        {provided.placeholder}
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <br />
                                    <Stack
                                        direction={"column"}
                                        sx={{ padding: 2 }}
                                    >
                                        {/* <label>
                                            Print Scale:
                                            <input
                                                type="number"
                                                value={printScale}
                                                onChange={(e) =>
                                                    setPrintScale(
                                                        e.target.value
                                                    )
                                                }
                                                min="0.1"
                                                step="0.1"
                                            />
                                        </label> */}
                                        {/* <TextField
                                            label="Document Title"
                                            variant="filled"
                                            multiline
                                            value={documentTitle}
                                            onChange={(e) =>
                                                setDocumentTitle(e.target.value)
                                            }
                                            required
                                            sx={{ width: "100%" }}
                                        /> */}

                                        <FormControlLabel
                                            sx={{
                                                justifyContent: "center",
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={newPagePerReport}
                                                    onChange={(e) =>
                                                        setNewPagePerReport(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            }
                                            label="New Page Per Item"
                                        />
                                        <RoundedButton onClick={handlePrint}>
                                            Print Items
                                        </RoundedButton>
                                        <GoBack to={"/app/report"} />
                                    </Stack>
                                </>
                            )}
                        </Drawer>
                    </div>

                    {/*  ____  _   _  _____        __
                        / ___|| | | |/ _ \ \      / /
                        \___ \| |_| | | | \ \ /\ / / 
                         ___) |  _  | |_| |\ V  V /  
                        |____/|_| |_|\___/  \_/\_/   */}

                    <Grid container>
                        <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                            <Typography
                                fontStyle={"italic"}
                                variant="h5"
                                justifyContent={"center"}
                                display={"flex"}
                            >
                                Preview your PDF
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper sx={{ paddingY: 2 }}>
                        <div ref={printRef}>
                            <div className="watermark">
                                <img src={AROLOGO} width={"90%"} />
                            </div>

                            <div
                                ref={printRef}
                                style={{
                                    // transform: `scale(${printScale})`,
                                    // transformOrigin: "top left",
                                    // width: `calc(100% / ${printScale})`,
                                    // height: `calc(100% / ${printScale})`,
                                    margin: `${printMargin}mm`,
                                }}
                            >
                                {data
                                    .filter(
                                        ([key, data]) =>
                                            reportsOptions[key].visible ?? false
                                    )
                                    .map(([key, component], index) => (
                                        <React.Fragment key={key}>
                                            {/* <h3 contentEditable>{key}</h3> */}
                                            {/* <div
                                                dangerouslySetInnerHTML={{
                                                    __html: tables[key],
                                                }}
                                            /> */}

                                            {newPagePerReport && (
                                                <hr
                                                    style={{
                                                        pageBreakBefore:
                                                            "always",
                                                        visibility: "hidden",
                                                    }}
                                                />
                                            )}
                                            {component}
                                        </React.Fragment>
                                    ))}
                            </div>
                        </div>
                    </Paper>
                </>
            ) : (
                <Typography>
                    There are not selected reports to print. Activate print
                    buttons in the burger menu and select some reports for
                    exporting.
                </Typography>
            )}
        </div>
    );
};

export default Tables2Print;
