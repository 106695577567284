import { globalReportFiltersTypes } from "../types/globalReportFiltersTypes";
import {
    DATATYPE_RESDATE,
    DEFAULT_COMPARISON,
    DISPLAY_ELEMENT_HELPER,
    GROUP_COUNTRIES,
    GROUP_DATE_DAY,
    GROUP_DATE_MONTH,
    GROUP_DATE_YEAR,
    PRESET_ARRIVAL_DEFAULT,
    PRESET_MONTH_TO_DATE,
    PresetArrivalDates,
    PresetDates,
} from "../helpers/filters";
import { MAX_DATE, MIN_DATE } from "../helpers/dates";

const globalReportFiltersInitial = {
    dataType: DATATYPE_RESDATE,
    ...PresetDates(PRESET_MONTH_TO_DATE),
    presetDate: PRESET_MONTH_TO_DATE, // If not "Custom", it will ignore from and to. If custom, it will take from and to values
    presetArrivalDate: PRESET_ARRIVAL_DEFAULT, // If not "Custom", it will ignore from and to. If custom, it will take from and to values
    ...PresetArrivalDates(PRESET_ARRIVAL_DEFAULT),
    fromBookingDay: MIN_DATE,
    toBookingDay: MAX_DATE,
    filters: {
        Cancellations: "included",
        Countries: [],
        Offers: [],
        Rooms: [],
        Types: [],
        VoucherNames: [],
        Comparison: DEFAULT_COMPARISON,
        ComparisonCustom: { from: new Date(), to: new Date() },
    },
    groupBy: [
        GROUP_COUNTRIES,
        GROUP_DATE_YEAR,
        GROUP_DATE_MONTH,
        GROUP_DATE_DAY,
    ],
    fieldsOrder: [
        // These will be the first ones
        "revenueTotal",
        "revenueRoom",
        "revenueExtras",
        "revenueVouchers",

        "bookingsTotal",
        "roomNightsTotal",
        "bookingsCancelled",
        "revenueRoomAndExtras",

        // "empty",

        // "ABWTotal",
        // "ALoSTotal",
        // "ADRTotal",
        // "empty",
    ],
    hideFields: [
        ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
            (e) =>
                ![
                    "ABWTotal",
                    "ALoSTotal",
                    "ADRTotal",
                    "bookingsCancelled",
                    "bookingsTotal",
                    "revenueTotal",
                    "roomNightsTotal",
                    "revenueRoom",
                    "revenueExtras",
                    "revenueRoomAndExtras",
                    "revenueVouchers",
                    "vouchersCount",
                ].includes(e)
        ),
        // "ABW",
        // "ABWCancelled",
        // // "ABWTotal",
        // "ALoS",
        // "ALoSCancelled",
        // // "ALoSTotal",
        // "ADR",
        // "ADRCancelled",
        // // "ADRTotal",
        // "bookings",
        // // "bookingsCancelled",
        // // "bookingsTotal",
        // "revenue",
        // "revenueCancelled",
        // // "revenueTotal",
        // "roomNights",
        // "roomNightsCancelled",
        // // "roomNightsTotal",
        // "revenueNett",
        // "revenueNettCancelled",
        // "revenueNettTotal",
        // "cancellationRate",
        // "vouchersCount",
        // "voucherMin",
        // "voucherMax",
    ],
};
export const globalReportFiltersReducer = (
    state = globalReportFiltersInitial,
    action
) => {
    switch (action.type) {
        case globalReportFiltersTypes.set:
            return { ...action.payload };

        case globalReportFiltersTypes.clean:
            return globalReportFiltersInitial;

        default:
            return state;
    }
};
