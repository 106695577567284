import { Box, IconButton, Modal, Paper, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Icons from "../helpers/icons";

const InfoIcon = ({
    size = "100%",
    color = "info",
    text,
    placement = "bottom",
    margin = 5,
}) => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <Tooltip
                title={text}
                placement={placement}
                PopperProps={{
                    sx: {
                        "& .MuiTooltip-tooltip": {
                            whiteSpace: "pre-line",
                        },
                    },
                }}
            >
                <IconButton onClick={() => setOpenModal(true)}>
                    <Icons.Info
                        color={color}
                        style={{ fontSize: size, color: color }}
                    />
                </IconButton>
            </Tooltip>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    component={Paper}
                    sx={{
                        p: margin,
                        whiteSpace: "pre-wrap",
                        width: {
                            xs: "100%", // 100% del ancho en xs
                            sm: "100%", // 100% del ancho en sm
                            md: 600, // 600px de ancho en md
                            lg: 800, // 800px de ancho en lg
                            xl: 1000, // 1000px de ancho en xl
                        },
                        maxWidth: "calc(100% - 32px)", // Asegura que el modal no toque los bordes de la pantalla
                    }}
                >
                    {text}
                </Box>
            </Modal>
        </>
    );
};

export default InfoIcon;
