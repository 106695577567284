import { authTypes } from "../types/authTypes";
export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case authTypes.token:
            return action.payload;

        case authTypes.logout:
            return {};

        default:
            return state;
    }
};
