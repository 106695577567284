import React, { useEffect, useState } from "react";

import TabSelector from "./TabSelector";

import YearPerformance from "./reports/YearPerformance";

const MonthlySessions = ({ data }) => {
    const TITLE = "Sessions";
    const [cardSelected, setCardSelected] = useState("bookingEngineSessions");
    const [sessionsData, setSessionsData] = useState([]);

    useEffect(() => {
        console.log({ sessionsData });
    }, [sessionsData]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const auxSessionsData = [
                // {
                //     id: "websiteSessions",
                //     title: "Website Sessions",
                //     value: data.tabs.websiteSessions.this,
                //     old: data.tabs.websiteSessions.last,
                //     expand: true,
                //     compare: true,
                // },
                {
                    id: "bookingEngineSessions",
                    title: "Booking Engine Sessions",
                    value: data.tabs.bookingEngineSessions.this,
                    old: data.tabs.bookingEngineSessions.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "perSessionValue",
                    title: "Per Session Value",
                    format: { maxDecimals: 2 },
                    value: data.tabs.perSessionValue.this,
                    old: data.tabs.perSessionValue.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "numberOfSearches",
                    title: "Number Of Searches",
                    value: data.tabs.numberOfSearches.this,
                    old: data.tabs.numberOfSearches.last,
                    expand: true,
                    compare: true,
                },
            ];
            setSessionsData(auxSessionsData);
        }
    }, [data]);

    return (
        <div>
            <TabSelector
                // hotelID={hotelID}
                data={sessionsData}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
            />

            <br />
            {sessionsData.length > 1 && //cardSelected === "websiteSessions" ||
                (cardSelected === "bookingEngineSessions" ||
                    cardSelected === "perSessionValue" ||
                    cardSelected === "numberOfSearches") && (
                    <YearPerformance
                        TITLE={`${TITLE} - ${
                            sessionsData.length > 0
                                ? (
                                      sessionsData.find(
                                          (head) => head.id === cardSelected
                                      ) ?? {}
                                  ).title ?? ""
                                : ""
                        }`}
                        dataLast={
                            ((data.tabs[cardSelected] ?? {}).historical ?? {})
                                .last
                        }
                        dataThis={
                            ((data.tabs[cardSelected] ?? {}).historical ?? {})
                                .this
                        }
                    />
                )}
        </div>
    );
};

export default MonthlySessions;
