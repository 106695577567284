import React, { useEffect, useState } from "react";
import Axios from "axios";

import { URLAPI } from "../../configuration";

import {
    Box,
    Container,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { fns } from "../../helpers/common";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import OptionsButtonDialog from "../OptionsButtonDialog";

import CircleValue from "../CircleValue";
import Colours from "../../helpers/colours";
import InfoIcon from "../InfoIcon";
import TableBodyCell from "../TableBodyCell";
import ErrorIcon from "../ErrorIcon";

import TableHeaderCell from "../TableHeaderCell";
import { useSelector } from "react-redux";

import { SliceTop, TOP_ALL } from "../../helpers/reports";

const HEADER = [
    {
        id: "searchDate",
        label: "Search Date",
    },
    {
        id: "searches",
        label: "Total Searches",
    },
    {
        id: "sessions",
        label: "Total Sessions",
    },
    {
        id: "noAvSearches",
        label: "No Av. Searches",
        tooltip: "No Av. Searches(Searches on no availability dates)",
    },
    {
        id: "noAvSessions",
        label: "No Av. Sessions",
        tooltip: "No Availability Sessions(Sessions without an available date)",
    },
    {
        id: "lostRevenue",
        label: "Lost Revenue",
        tooltip: "Sum of the minimum amount of revenue per No Av. Session",
        format: { left: "currency" },
    },
];

const LostRevenueDailyDistribution = ({ hotelID }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const TOP = [10, TOP_ALL, 5];
    const TABLE_MODE = "TABLE_MODE";
    const VISUAL_MODE = "VISUAL_MODE";
    const TITLE = "Offer Title Daily Distribution";
    const SUBTITLE = "Lost Revenue";
    const REPORT_DESC =
        "This report shows the daily break down of offer titles (no availability sessions & lost revenue) from unfinished user searches";

    const [connectionError, setConnectionError] = useState(false);

    const [rawData, setRawData] = useState([]);

    // Unable to render using selectedData
    const { dataSearches } = useSelector((state) => state);
    const [selectedData, setSelectedData] = useState({});

    const [mode, setMode] = useState(TABLE_MODE);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("lostRevenue");

    const [topNumber, setTopNumber] = useState(0);
    const [timeStart, setTimeStart] = useState(new Date());

    const [loaded, setLoaded] = useState(0);

    //   _                        ___                    _
    //  / |   __ _  __ _  ___    ( _ )    ___  ___  _ __| |_
    //  | |  / _` |/ _` |/ _ \   / _ \/\ / __|/ _ \| '__| __|
    //  | | | (_| | (_| | (_) | | (_>  < \__ \ (_) | |  | |_
    //  |_|  \__,_|\__, |\___/   \___/\/ |___/\___/|_|   \__|
    //             |___/
    useEffect(() => {
        // Early return if rawData is empty
        if (rawData.length === 0) return;

        setSelectedData([]);

        // Handling non-visual mode
        if (mode !== VISUAL_MODE) {
            let auxSelected = structuredClone(rawData);

            auxSelected.sort((a, b) => {
                if (orderBy === "searchDate") {
                    return order === "asc"
                        ? new Date(b[orderBy]) - new Date(a[orderBy])
                        : new Date(a[orderBy]) - new Date(b[orderBy]);
                } else {
                    return order === "asc"
                        ? b[orderBy] - a[orderBy]
                        : a[orderBy] - b[orderBy];
                }
            });

            setSelectedData(SliceTop(auxSelected, TOP[topNumber]));
            return;
        }

        // Handling visual mode
        setSelectedData(rawData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawData, order, orderBy, topNumber, mode]);
    //   _ __  _ __ ___   ___ ___  ___ ___
    //  | '_ \| '__/ _ \ / __/ _ \/ __/ __|
    //  | |_) | | | (_) | (_|  __/\__ \__ \
    //  | .__/|_|  \___/ \___\___||___/___/
    //  |_|
    useEffect(() => {
        if (dataSearches.status === "error") {
            setConnectionError(true);
            return;
        }

        if (!(dataSearches.data ?? {}).hasOwnProperty("LostRevenueSummary")) {
            setLoaded(false);
            return;
        }

        setConnectionError(false);

        const data = dataSearches.data.LostRevenueSummary;

        let auxRawData = [];
        let auxTimeStart = new Date();
        data.forEach((element) => {
            auxRawData.push({
                searchDate: element.SearchDate,
                searches: element.TotalSearchCount,
                sessions: element.TotalSessionCount,
                noAvSearches: element.NoAvailabilitySearchCount,
                noAvSessions: element.NoAvailabilitySessionCount,
                lostRevenue: element.LostRevenue,
            });
            auxTimeStart =
                new Date(element.SearchDate) < auxTimeStart
                    ? new Date(element.SearchDate)
                    : auxTimeStart;
        });
        auxTimeStart = new Date(
            auxTimeStart.getUTCFullYear(),
            auxTimeStart.getUTCMonth(),
            1
        );
        setTimeStart(auxTimeStart);
        setRawData(auxRawData);

        setLoaded(true);
    }, [dataSearches]);

    if (connectionError) {
        return (
            <Typography variant="h6" color="error">
                Failed to fetch data. Please check your network connection.
            </Typography>
        );
    }

    return (
        <Container component={HoverPaper} maxWidth={false}>
            <br />
            <Stack
                direction={{ md: "column", sm: "row", xs: "row" }}
                justifyContent={isMobile ? "space-around" : "space-between"}
                alignItems="flex-start"
                // style={{ border: "1px solid red" }}
            >
                {/* Title */}
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={"center"}
                    // style={{ border: "1px solid orange" }}
                >
                    <Stack
                    // alignItems="center"
                    // style={{ border: "1px solid yellow" }}
                    >
                        <Typography fontStyle={"italic"} fontSize={"80%"}>
                            {SUBTITLE}
                        </Typography>
                        <Typography fontWeight={"bold"}>{TITLE}</Typography>
                    </Stack>
                    <InfoIcon text={REPORT_DESC} />
                    <ErrorIcon show={connectionError} />
                </Stack>

                {isMobile ? (
                    <OptionsButtonDialog>
                        {/* <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        /> */}
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography>{`Switch to top ${
                                TOP[
                                    (topNumber + 1) %
                                        (mode === TABLE_MODE
                                            ? TOP.length
                                            : TOP.length - 1)
                                ]
                            }`}</Typography>
                            <CircleValue
                                value={`${TOP[topNumber]}`}
                                tooltip={`Switch to top ${
                                    TOP[
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    ]
                                }`}
                                color={Colours.primary}
                                onClick={() => {
                                    setTopNumber(
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    );
                                }}
                                style={{ cursor: "pointer" }}
                            />
                        </Stack>
                        {""}
                    </OptionsButtonDialog>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        // style={{ border: "1px solid green   " }}
                    >
                        <CircleValue
                            value={`${TOP[topNumber]}`}
                            tooltip={`Switch to top ${
                                TOP[(topNumber + 1) % TOP.length]
                            }`}
                            color={Colours.primary}
                            onClick={() => {
                                setTopNumber((topNumber + 1) % TOP.length);
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        {/* <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        /> */}
                    </Stack>
                )}
            </Stack>

            <br />

            {!loaded ? (
                <ProgressBarSteps
                    steps={[33, 66, 100]}
                    // steps={[
                    //     5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70,
                    //     75, 80, 85, 90, 95, 100,
                    // ]}
                />
            ) : (
                ""
            )}

            {mode === TABLE_MODE && (
                //   _____  _    ____  _     _____
                //  |_   _|/ \  | __ )| |   | ____|
                //    | | / _ \ |  _ \| |   |  _|
                //    | |/ ___ \| |_) | |___| |___
                //    |_/_/   \_\____/|_____|_____|
                <>
                    <TableContainer
                        style={{ overflowX: "auto", maxHeight: "600px" }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {HEADER.map((headCell, index) => (
                                        <TableHeaderCell
                                            key={headCell.id}
                                            sortDirection={
                                                orderBy === headCell.id
                                                    ? order
                                                    : false
                                            }
                                            style={
                                                isMobile && index === 0
                                                    ? {
                                                          position: "sticky",
                                                          left: 0,
                                                          top: 0,
                                                          zIndex: 4,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                                    : {
                                                          position: "sticky",
                                                          top: 0,
                                                          zIndex: 3,
                                                          backgroundColor:
                                                              Colours.plainWhite,
                                                      }
                                            }
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={
                                                    orderBy === headCell.id
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() => {
                                                    const isAsc =
                                                        orderBy ===
                                                            headCell.id &&
                                                        order === "asc";
                                                    setOrder(
                                                        isAsc ? "desc" : "asc"
                                                    );
                                                    setOrderBy(headCell.id);
                                                }}
                                            >
                                                {headCell.tooltip ? (
                                                    <Tooltip
                                                        title={headCell.tooltip}
                                                    >
                                                        <div>
                                                            {headCell.label}
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    headCell.label
                                                )}
                                            </TableSortLabel>
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <>
                                    {selectedData.length > 0 ? (
                                        selectedData.map((rowData, i) => (
                                            <React.Fragment key={i}>
                                                <LostRevenueDailyDistributionRow
                                                    key={i}
                                                    hotelID={
                                                        dataSearches.hotelID
                                                    }
                                                    rowData={rowData}
                                                    header={HEADER}
                                                    isMobile={isMobile}
                                                />
                                            </React.Fragment>
                                        ))
                                    ) : selectedData.length == 0 &&
                                      dataSearches.status === "loaded" ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={100}
                                                align="center"
                                            >
                                                <Box
                                                    component="span"
                                                    fontStyle="italic"
                                                >
                                                    No data
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        [
                                            ...Array(
                                                TOP[topNumber] === TOP_ALL
                                                    ? 25
                                                    : TOP[topNumber]
                                            ).keys(),
                                        ].map((e) => (
                                            <TableRow key={e}>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                {HEADER.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                    >
                                                        <Skeleton />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    )}
                                </>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Container>
    );
};

const getDetailsData = async (hotelID, searchDate) => {
    return new Promise((resolve, reject) => {
        Axios({
            method: "post",
            url: `${URLAPI}/lost_revenue/details/${hotelID}`,
            data: { from: searchDate, to: searchDate },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                const {
                    data: { data },
                } = response;
                resolve(data);
            })
            .catch((error_) => {
                console.log({ error_ });
                reject([]);
            });
    });
};

const LostRevenueDailyDistributionRow = ({
    hotelID,
    rowData,
    header,
    isMobile,
}) => {
    const [clicked, setClicked] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [rawDetails, setRawDetails] = useState([]);
    const [procDetails, setProcDetails] = useState([]);

    useEffect(() => {
        if (clicked && rawDetails.length === 0) {
            console.log(new Date(rowData.searchDate).toISOString());

            const fetchData = async () => {
                try {
                    const auxRawDetails = await getDetailsData(
                        hotelID,
                        new Date(rowData.searchDate).toISOString()
                    );
                    if (auxRawDetails.length > 0) {
                        setRawDetails(auxRawDetails);
                        setConnectionError(false);
                    } else {
                        setConnectionError(true);
                    }
                } catch (error) {
                    console.log(error);
                    setConnectionError(true);
                }
            };
            fetchData();
        }
    }, [clicked]);

    useEffect(() => {
        if (rawDetails.length > 0) {
            let auxProcDetails = {};

            rawDetails.forEach((element) => {
                const offerID = element.OfferID;
                const isMinimum = element.IsMinimum;

                if (!auxProcDetails.hasOwnProperty(offerID)) {
                    auxProcDetails[offerID] = {
                        OfferTitle: "",
                        sumRevenue: 0,
                        count: 0,
                        sumRevenuePercentage: 0,
                        countPercentage: 0,
                    };
                }

                auxProcDetails[offerID].OfferTitle = element.OfferTitle;

                isMinimum
                    ? (auxProcDetails[offerID].sumRevenue += element.Revenue)
                    : (auxProcDetails[offerID].sumRevenue += 0);

                auxProcDetails[offerID].count += 1;
            });

            Object.values(auxProcDetails).forEach((detail) => {
                detail.sumRevenuePercentage =
                    (detail.sumRevenue / rowData.lostRevenue) * 100;

                detail.countPercentage =
                    (detail.count / rowData.noAvSearches) * 100;
            });

            setProcDetails(auxProcDetails);
        }
    }, [rawDetails]);

    const formatValueOrDate = (prop, value) => {
        let leftUnit = (header.find((e) => e.id === prop) ?? {}).leftUnit ?? "";
        return `${leftUnit}${
            prop === "searchDate" || prop === "availabilityDate"
                ? new Date(value).toISOString().slice(0, 10)
                : fns(
                      hotelID,
                      value,
                      (header.find((e) => e.id === prop) ?? {}).format ?? {}
                  )
        }`;
    };

    return (
        <>
            <TableRow
                key={Object.values(rowData)[0]}
                onClick={() => setClicked(!clicked)}
                sx={
                    clicked
                        ? { backgroundColor: Colours.selectedRow }
                        : undefined
                }
                style={{ cursor: "pointer" }}
            >
                {header.map((head, index) => (
                    <TableBodyCell
                        key={head.id}
                        style={
                            isMobile && index === 0
                                ? {
                                      position: "sticky",
                                      left: 0,
                                      zIndex: 1,
                                      backgroundColor: clicked
                                          ? Colours.selectedRow
                                          : Colours.plainWhite,
                                  }
                                : {}
                        }
                    >
                        {formatValueOrDate(head.id, rowData[head.id])}
                    </TableBodyCell>
                ))}
            </TableRow>

            {clicked &&
                (Object.entries(procDetails).length > 0 ? (
                    <>
                        <TableRow
                            onClick={() => setClicked(!clicked)}
                            sx={
                                clicked
                                    ? {
                                          backgroundColor: Colours.selectedRow,
                                      }
                                    : undefined
                            }
                        >
                            <TableCell
                                colSpan={6}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableHeaderCell
                                                style={{
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: 1,
                                                    backgroundColor:
                                                        Colours.selectedRow,
                                                }}
                                            >
                                                {`Offer Title`}
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                {`No Av. Searches`}
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                {`Lost Revenue`}
                                            </TableHeaderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(procDetails).map(
                                            ([i, rowData]) => (
                                                <>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{
                                                                position:
                                                                    "sticky",
                                                                left: 0,
                                                                zIndex: 1,
                                                                backgroundColor:
                                                                    Colours.selectedRow,
                                                            }}
                                                        >
                                                            {rowData.OfferTitle}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowData.count}
                                                            <Box
                                                                component="span"
                                                                fontSize={"75%"}
                                                            >
                                                                {" ("}
                                                                {formatValueOrDate(
                                                                    "lostRevenue%",
                                                                    rowData.countPercentage
                                                                )}
                                                                {"%"}
                                                                {")"}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatValueOrDate(
                                                                "lostRevenue",
                                                                rowData.sumRevenue
                                                            )}
                                                            <Box
                                                                component="span"
                                                                fontSize={"75%"}
                                                            >
                                                                {" ("}
                                                                {formatValueOrDate(
                                                                    "lostRevenue%",
                                                                    rowData.sumRevenuePercentage
                                                                )}
                                                                {"%"}
                                                                {")"}
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    </>
                ) : (
                    <TableRow
                        key={"skeleton"}
                        sx={
                            clicked
                                ? {
                                      backgroundColor: Colours.selectedRow,
                                  }
                                : undefined
                        }
                    >
                        <ErrorIcon show={connectionError} />
                        <TableCell colSpan={header.length}>
                            <Skeleton />
                        </TableCell>
                    </TableRow>
                ))}
        </>
    );
};

export default LostRevenueDailyDistribution;
