import React from "react";
import { useSelector } from "react-redux";

import {
    Table,
    TableCell,
    TableHead,
    TableBody,
    TableRow,
    Checkbox,
    FormControlLabel,
    styled,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";

import NoDataRow from "./NoDataRow";

import Icons from "../helpers/icons";

const HeadTableCell = styled(TableCell)({
    fontWeight: "bold",
    fontSize: "1.1em",
});

const StyledTableCell = styled(TableCell)({
    padding: "0px 16px",
});

export const UserList = ({
    title,
    users,
    defaultExpanded = false,
    handleChangeReceiveEmail = () => {},
    toReceive = new Set(),
    notToReceive = new Set(),
    showCheckBox = true,
    elevation,
    notCompactTable = false,
}) => {
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);

    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            sx={{
                border: "none",
                "&:before": { display: "none" },
                "&:not(:last-child)": { borderBottom: 0 },
            }}
            elevation={elevation}
        >
            <AccordionSummary
                expandIcon={<Icons.ArrowDown />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <Typography fontWeight={"bold"}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <HeadTableCell>First Name</HeadTableCell>
                            <HeadTableCell>Last Name</HeadTableCell>
                            <HeadTableCell>Email address</HeadTableCell>
                            {showCheckBox && (
                                <HeadTableCell>Receive email</HeadTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.length === 0 && <NoDataRow />}
                        {users.map((user) => (
                            <TableRow key={user.email}>
                                {notCompactTable ? (
                                    <>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.last_name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        {showCheckBox && (
                                            <TableCell>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            indeterminate={
                                                                (
                                                                    user.email_hotel ??
                                                                    []
                                                                ).includes(
                                                                    Number(
                                                                        hotelID ===
                                                                            "default"
                                                                            ? 0
                                                                            : hotelID
                                                                    )
                                                                ) &&
                                                                notToReceive.has(
                                                                    user.email
                                                                )
                                                            }
                                                            checked={
                                                                ((
                                                                    user.email_hotel ??
                                                                    []
                                                                ).includes(
                                                                    Number(
                                                                        hotelID ===
                                                                            "default"
                                                                            ? 0
                                                                            : hotelID
                                                                    )
                                                                ) &&
                                                                    !notToReceive.has(
                                                                        user.email
                                                                    )) ||
                                                                toReceive.has(
                                                                    user.email
                                                                )

                                                                // TODO: here to make it red if it is in the toReceive list, because we clicked it
                                                                // TODO: And to make it empty and red if we unselected it because it is in the to Not receive list
                                                            }
                                                            onChange={(event) =>
                                                                handleChangeReceiveEmail(
                                                                    user,
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            name="receiveEmail"
                                                            color={
                                                                notToReceive.has(
                                                                    user.email
                                                                ) ||
                                                                toReceive.has(
                                                                    user.email
                                                                )
                                                                    ? "info"
                                                                    : "primary"
                                                            }
                                                        />
                                                    }
                                                />
                                            </TableCell>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <StyledTableCell>
                                            {user.name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {user.last_name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {user.email}
                                        </StyledTableCell>
                                        {showCheckBox && (
                                            <StyledTableCell>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            indeterminate={
                                                                (
                                                                    user.email_hotel ??
                                                                    []
                                                                ).includes(
                                                                    Number(
                                                                        hotelID ===
                                                                            "default"
                                                                            ? 0
                                                                            : hotelID
                                                                    )
                                                                ) &&
                                                                notToReceive.has(
                                                                    user.email
                                                                )
                                                            }
                                                            checked={
                                                                ((
                                                                    user.email_hotel ??
                                                                    []
                                                                ).includes(
                                                                    Number(
                                                                        hotelID ===
                                                                            "default"
                                                                            ? 0
                                                                            : hotelID
                                                                    )
                                                                ) &&
                                                                    !notToReceive.has(
                                                                        user.email
                                                                    )) ||
                                                                toReceive.has(
                                                                    user.email
                                                                )

                                                                // TODO: here to make it red if it is in the toReceive list, because we clicked it
                                                                // TODO: And to make it empty and red if we unselected it because it is in the to Not receive list
                                                            }
                                                            onChange={(event) =>
                                                                handleChangeReceiveEmail(
                                                                    user,
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                            name="receiveEmail"
                                                            color={
                                                                notToReceive.has(
                                                                    user.email
                                                                ) ||
                                                                toReceive.has(
                                                                    user.email
                                                                )
                                                                    ? "info"
                                                                    : "primary"
                                                            }
                                                        />
                                                    }
                                                />
                                            </StyledTableCell>
                                        )}
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    );
};
