import { Tooltip, Typography } from "@mui/material";
import React from "react";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

// TODO: Include this everywhere there is a percentage
// isInverted when the numbers are for cancellation
const SmallPercentage = ({
    bubble = false,
    bubbleRound = true,
    tooltip = true,
    context = "",
    actualName = "Actual",
    oldName = "Old",
    showFormula = true,
    actual,
    old,
    isInverted = false,
    colorized = true,
    brackets = true,
    styles,
    usePercentage = true,
    percentages = [
        {
            min: -Infinity,
            max: -0.001,
            colour: "#D11D1D",
            backgroundColour: Colours.trafficLightRed,
        },
        // { min: -5, max: 5, colour: Colours.trafficLightYellow },
        {
            min: 0,
            max: Infinity,
            colour: "#1B7B3E",
            backgroundColour: Colours.trafficLightGreen,
        },
    ],
    cancellations = false,
    cancellationPercentages = [
        {
            min: -Infinity,
            max: -0.001,
            colour: "#1B7B3E",
            backgroundColour: Colours.trafficLightGreen,
        },
        // { min: -5, max: 5, colour: Colours.trafficLightYellow },
        {
            min: 0,
            max: Infinity,
            colour: "#D11D1D",
            backgroundColour: Colours.trafficLightRed,
        },
    ],
    formatter = (val) => val.toLocaleString(),
    text = "",
}) => {
    // TODO: Improve checking small values, for example 1.2838566789458909e-14
    if (isNaN(actual) || isNaN(old) || old === 0 || old < 0.00001) return;

    const value = ((actual - old) / old) * 100;
    const percentageRanges = cancellations
        ? cancellationPercentages
        : percentages;

    const findColour = () => {
        let colourIndex = percentageRanges.findIndex(
            (e) => e.min <= value && value < e.max
        );
        if (isInverted) colourIndex = percentageRanges.length - 1 - colourIndex;

        const defaultColours = {
            colour: "#D11D1D",
            backgroundColor: Colours.trafficLightRed,
        }; // Add your default background color here
        const colours = percentageRanges[colourIndex] ?? defaultColours;

        return {
            colour: colours.colour,
            backgroundColour: colours.backgroundColour,
        };
    };

    const { colour, backgroundColour } = findColour();

    const getTooltip = () => {
        if (!tooltip) return "";

        const formula = `((${actualName} - ${oldName}) / ${oldName}) * 100`;
        return (
            <div>
                {value > 1000 && <div>{`${value.toFixed(0)}%`}</div>}
                <ul>
                    {context && (
                        <li>
                            <div>{context}</div>
                        </li>
                    )}
                    <li>{`${actualName}: ${formatter(actual)}`}</li>
                    <li>{`${oldName}: ${formatter(old)}`}</li>
                    {showFormula && <li>{`Formula: ${formula}`}</li>}
                </ul>
            </div>
        );
    };

    const renderValue = () => (
        <>
            {value > 0 ? (
                <>
                    <Icons.ArrowFullUp sx={{ fontSize: "0.9em", mr: 0.3 }} />
                    {value > 1000
                        ? (value / 1000).toFixed(0) + "K"
                        : value.toFixed(0)}
                </>
            ) : (
                <>
                    <Icons.ArrowFullDown sx={{ fontSize: "0.9em", mr: 0.3 }} />
                    {value < -1000
                        ? (value / 1000).toFixed(0) + "K"
                        : Math.abs(value).toFixed(0)}
                </>
            )}
        </>
    );

    return (
        <Tooltip title={getTooltip()}>
            <Typography
                variant="sub"
                fontWeight="bold"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    ...(bubble && {
                        backgroundColor: colorized
                            ? `${backgroundColour}20`
                            : "",
                        borderRadius: bubbleRound ? "50px" : "",
                        // padding: bubbleRound ? ".5em" : "",
                        py: ".1em",
                        px: ".5em",
                        color: colorized ? colour : "",
                    }),
                    ...(!bubble && {
                        color: colorized ? colour : "",
                    }),
                    ...styles,
                }}
            >
                {brackets && "("}
                {text || renderValue()}
                {usePercentage && "%"}
                {brackets && ")"}
            </Typography>
        </Tooltip>
    );
};

export default SmallPercentage;
