import React, { useEffect, useState } from "react";

import EChartsReact from "echarts-for-react";

import WrapperPDFEChart from "./shared/WrapperPDFEChart";

const MonthlyLeadTime = ({ data }) => {
    // LeadTime - needs to be moved to additional component
    const [legend, setLegend] = useState([]);
    const [keys, setKeys] = useState([]);
    const [procDetails, setProcDetails] = useState([]);
    const TITLE = "Lead Time Distribution";

    useEffect(() => {
        if (Object.entries(data).length > 0) {
            // Axis data
            const keys = Object.keys(data);
            setKeys(keys);

            // Legend data
            const legend = Object.keys(Object.entries(data).shift()[1]);
            setLegend(legend);

            // Process data
            const auxProcessedDetails = legend.map((name) => ({
                name,
                type: "bar",
                data: keys.map((key) => data[key][name]),
                yAxisIndex: name === "No Av. Searches" ? 1 : 0,
            }));

            setProcDetails(auxProcessedDetails);
        }
    }, [data]);

    return (
        <>
            <WrapperPDFEChart k={TITLE}>
                <EChartsReact
                    option={{
                        xAxis: {
                            type: "category",
                            data: keys,
                            axisLabel: {
                                rotate: 45,
                            },
                        },
                        yAxis: [
                            {
                                type: "value",
                                axisLabel: {
                                    formatter: "{value}",
                                },
                            },
                            {
                                type: "value",
                                axisLabel: {
                                    formatter: "{value}",
                                },
                            },
                        ],
                        legend: {
                            data: legend,
                        },
                        tooltip: {
                            trigger: "axis",
                            axisPointer: {
                                type: "shadow",
                            },
                        },
                        series: procDetails,
                    }}
                    style={{ height: 300 }}
                />
            </WrapperPDFEChart>
        </>
    );
};

export default MonthlyLeadTime;
