import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Checkbox,
    Container,
    FormControlLabel,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import MonthlyRoomsTableReport from "./MonthlyRoomsTableReport";
import MonthlyRoomsCardsReport from "./MonthlyRoomsCardsReport";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import DateSelector, {
    currentYearButton,
    currentMonthButton,
    lastMonthButton,
} from "../DateSelector";
import OptionsButtonDialog from "../OptionsButtonDialog";
import SwitchVisualTable, {
    TABULAR_MODE,
    TABLE_MODE,
} from "../SwitchVisualTable";

import { process, processMonth } from "../../helpers/reports";
import { getYearsBefore, months } from "../../helpers/dates";
import { reservationsToYearMonth } from "../DataLoader";

const WrapperMonthlyRoomsReport = () => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [mode, setMode] = useState(TABULAR_MODE);

    const [cardMode, setCardMode] = useState("Total");

    const [connectionError, setConnectionError] = useState(false);

    const { dataBookings } = useSelector((state) => state);
    const [selectedDataSource, setSelectedDataSource] = useState({
        status: "empty",
    });

    const {
        time: sharedTime,
        fullYear: sharedFullYear,
        dataSource: sharedDataSource,
    } = useSelector((state) => state.settings.settings);

    const REPORT_DESC =
        "This report shows the number of bookings, roomnights, room revenue, average daily rate (ADR), average length of stay (ALoS) & average booking window (ABW)";
    // "For each month or year, shows number of bookings, number of nights, revenue, revenue from cancelled bookings, average daily rate, average length of stay and average booking window";
    const TITLE = "Monthly Room Revenue";

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(false);

    const [loaded, setLoaded] = useState(false);

    const [dataByYear, setDataByYear] = useState({});
    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});
    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const setMonthsData = [
        setDataByJanuary,
        setDataByFebruary,
        setDataByMarch,
        setDataByApril,
        setDataByMay,
        setDataByJune,
        setDataByJuly,
        setDataByAugust,
        setDataBySeptember,
        setDataByOctober,
        setDataByNovember,
        setDataByDecember,
    ];

    useEffect(() => {
        switch (sharedDataSource) {
            case "CheckOut":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(
                        dataBookings.data,
                        "CheckOut"
                    ),
                });
                break;
            case "CheckIn":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(dataBookings.data, "CheckIn"),
                });
                break;
            case "ResDate":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(dataBookings.data, "ResDate"),
                });
                break;

            default:
                setSelectedDataSource({ status: "empty" });
                break;
        }
    }, [sharedDataSource, dataBookings]);

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    useEffect(() => {
        console.log(cardMode);
    }, [cardMode]);

    // Gets non-empty data(at least one key not 0)
    const getValidData = (unfilteredData) => {
        let validData = {};
        Object.entries(unfilteredData).map(([yearKey, data]) => {
            if (
                !Object.keys(data).every((key) =>
                    key === "Details" ? true : data[key] === 0
                )
            ) {
                return (validData[yearKey] = data);
            }
            return undefined;
        });
        return validData;
    };

    useEffect(() => {
        if (selectedDataSource.status === "error") setConnectionError(true);
        if (selectedDataSource.status === "loaded") {
            // console.log(selectedDataSource);

            // Get array of all years to n years before
            let years = getYearsBefore();

            months.forEach((month, index) => {
                let auxMonthData = Object.assign(
                    {},
                    ...years.map((year) => ({
                        [year]: processMonth(
                            (
                                (selectedDataSource.data[`${year}`] ?? {})[
                                    month
                                ] ?? []
                            ).filter((element) =>
                                ["Special Offer"].includes(element.Type)
                            ),
                            sharedDataSource,
                            year,
                            index
                        ),
                    }))
                );
                setMonthsData[index](getValidData(auxMonthData));
            });

            let auxYearData = Object.assign(
                {},
                ...years.map((year) => ({
                    [year]: process(
                        Object.values(selectedDataSource.data[`${year}`] ?? {})
                            .flat(1)
                            .filter((element) =>
                                ["Special Offer"].includes(element.Type)
                            )
                    ),
                }))
            );
            setDataByYear(getValidData(auxYearData));

            console.log({ dataByYear, monthsData });
            setLoaded(true);
        } else setLoaded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataSource]);

    // useEffect(() => {
    //     console.log(loaded);
    // }, [loaded]);

    return (
        <Stack
            direction={"column"}
            spacing={0.1}
            // style={{ border: "1px solid black" }}
        >
            <Container maxWidth={false} component={HoverPaper}>
                <br />
                <Stack
                    direction={{ md: "column", sm: "column", xs: "row" }}
                    justifyContent={isMobile ? "space-around" : "space-between"}
                    alignItems="flex-start"
                    // style={{ border: "1px solid red" }}
                >
                    {/* Title */}
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"center"}
                        // style={{ border: "1px solid orange" }}
                    >
                        <Stack
                            alignItems="center"
                            // style={{ border: "1px solid yellow" }}
                        >
                            <Typography fontWeight={"bold"}>{TITLE}</Typography>
                            <Typography
                                fontStyle={"italic"}
                                variant="subtitle2"
                            >
                                {`by ${
                                    sharedDataSource === "CheckOut"
                                        ? "Check-out"
                                        : sharedDataSource === "CheckIn"
                                        ? "Check-in"
                                        : "Reservation"
                                } date`}
                            </Typography>
                        </Stack>
                        <InfoIcon text={REPORT_DESC} />
                        <ErrorIcon show={connectionError} />
                    </Stack>

                    {isMobile ? (
                        <OptionsButtonDialog>
                            <SwitchVisualTable
                                collapse={true}
                                modes={[TABLE_MODE, TABULAR_MODE]}
                                mode={mode}
                                setMode={setMode}
                            />
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                buttons={[
                                    {
                                        label: "Current Year",
                                        value: currentYearButton(),
                                        fullYear: true,
                                    },
                                    {
                                        label: "Current Month",
                                        value: currentMonthButton(),
                                        fullYear: false,
                                    },
                                    {
                                        label: "Last Month",
                                        value: lastMonthButton(),
                                        fullYear: false,
                                    },
                                ]}
                                day={false}
                            />
                            {""}
                        </OptionsButtonDialog>
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            // style={{ border: "1px solid green   " }}
                        >
                            <SwitchVisualTable
                                modes={[TABLE_MODE, TABULAR_MODE]}
                                mode={mode}
                                setMode={setMode}
                            />
                            <DateSelector
                                collapse={isMobile}
                                fullYear={fullYear}
                                setFullYear={setFullYear}
                                time={time}
                                setTime={setTime}
                                buttons={[
                                    {
                                        label: "Current Year",
                                        value: currentYearButton(),
                                        fullYear: true,
                                    },
                                    {
                                        label: "Current Month",
                                        value: currentMonthButton(),
                                        fullYear: false,
                                    },
                                    {
                                        label: "Last Month",
                                        value: lastMonthButton(),
                                        fullYear: false,
                                    },
                                ]}
                                day={false}
                            />
                            {mode === TABULAR_MODE && (
                                <FormControlLabel
                                    label="Cancelled"
                                    control={
                                        <Checkbox
                                            checked={cardMode === "Cancelled"}
                                            onChange={() => {
                                                setCardMode(
                                                    cardMode === "Cancelled"
                                                        ? "Total"
                                                        : "Cancelled"
                                                );
                                            }}
                                        />
                                    }
                                />
                            )}
                        </Stack>
                    )}
                </Stack>

                {mode === TABLE_MODE && (
                    //   _____  _    ____  _     _____
                    //  |_   _|/ \  | __ )| |   | ____|
                    //    | | / _ \ |  _ \| |   |  _|
                    //    | |/ ___ \| |_) | |___| |___
                    //    |_/_/   \_\____/|_____|_____|
                    <MonthlyRoomsTableReport
                        data={monthsData}
                        dataYears={dataByYear}
                        fullYear={fullYear}
                        monthN={time.getUTCMonth() ?? 0}
                    />
                )}
                <br />
                {!loaded ? (
                    <>
                        <ProgressBarSteps />
                    </>
                ) : (
                    ""
                )}
            </Container>
            {mode === TABULAR_MODE && (
                //    ____    _    ____  ____  ____
                //   / ___|  / \  |  _ \|  _ \/ ___|
                //  | |     / _ \ | |_) | | | \___ \
                //  | |___ / ___ \|  _ <| |_| |___) |
                //   \____/_/   \_\_| \_\____/|____/
                <MonthlyRoomsCardsReport
                    TITLE={TITLE}
                    data={monthsData}
                    dataYears={dataByYear}
                    fullYear={fullYear}
                    time={time}
                    cardMode={cardMode}
                />
            )}
        </Stack>
    );
};

export default WrapperMonthlyRoomsReport;
