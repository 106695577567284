import React, { useEffect, useState } from "react";

import TabSelector from "./TabSelector";

import YearPerformance from "./reports/YearPerformance";

const MonthlyBookings = ({ data }) => {
    const TITLE = "Bookings";
    const [cardSelected, setCardSelected] = useState("numberOfBookings");
    const [bookingsData, setBookingsData] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const auxBookingsData = [
                {
                    id: "numberOfBookings",
                    title: "Number Of Bookings",
                    value: data.reports.Bookings.tabs.numberOfBookings.this,
                    old: data.reports.Bookings.tabs.numberOfBookings.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "roomsPerBooking",
                    title: "Rooms Per Booking",
                    format: { maxDecimals: 2 },
                    value: data.reports.Bookings.tabs.roomsPerBooking.this,
                    old: data.reports.Bookings.tabs.roomsPerBooking.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "totalRoomNights",
                    title: "Total Room Nights",
                    value: data.reports.Bookings.tabs.totalRoomNights.this,
                    old: data.reports.Bookings.tabs.totalRoomNights.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "ABV",
                    title: "Average Booking Value",
                    format: {
                        left: "currency",
                    },
                    value: data.reports.Bookings.tabs.ABV.this,
                    old: data.reports.Bookings.tabs.ABV.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "bookingsMobile",
                    title: "Mobile Bookings",
                    value: data.reports.Bookings.tabs.bookingsMobile.this,
                    old: data.reports.Bookings.tabs.bookingsMobile.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "bookingsDesktop",
                    title: "Desktop Bookings",
                    value: data.reports.Bookings.tabs.bookingsDesktop.this,
                    old: data.reports.Bookings.tabs.bookingsDesktop.last,
                    expand: true,
                    compare: true,
                },
            ];
            setBookingsData(auxBookingsData);
        }
    }, [data]);

    return (
        <div>
            <TabSelector
                // hotelID={hotelID}
                data={bookingsData}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
            />
            <br />

            {(cardSelected === "numberOfBookings" ||
                cardSelected === "roomsPerBooking" ||
                cardSelected === "totalRoomNights" ||
                cardSelected === "ABV" ||
                cardSelected === "bookingsMobile" ||
                cardSelected === "bookingsDesktop") && (
                <YearPerformance
                    TITLE={`${TITLE} - ${
                        bookingsData.length > 0
                            ? bookingsData.find(
                                  (head) => head.id === cardSelected
                              ).title ?? ""
                            : ""
                    }`}
                    dataLast={
                        data.reports.Bookings.tabs[cardSelected].historical.last
                    }
                    dataThis={
                        data.reports.Bookings.tabs[cardSelected].historical.this
                    }
                />
            )}
        </div>
    );
};

export default MonthlyBookings;
