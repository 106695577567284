import React from "react";

import { Alert, Container, Grid, Typography, styled } from "@mui/material";

import AICancellations from "../components/AICancellations";
import AvailabilityAndDemandChart from "../components/reports/echarts/AvailabilityAndDemandChart";
import CheckHide from "../components/shared/CheckHide";

import Colours from "../helpers/colours";

const StyledAlert = styled(Alert)(({ theme }) => ({
    "& .MuiAlert-icon": {
        color: Colours.primary,
    },
    "& .MuiAlert-message": {
        color: "black",
    },
    backgroundColor: Colours.secondarySoft,
    borderColor: Colours.primary,
    borderWidth: 1,
    borderStyle: "solid",
}));

const AIScreen = () => {
    return (
        <>
            <Container>
                <Grid container>
                    <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                        <Typography variant="h4">AI</Typography>
                    </Grid>
                </Grid>{" "}
                <StyledAlert severity="info" variant={"outlined"}>
                    AI components are in beta version. We are working to improve
                    them.
                </StyledAlert>
                <br />
                <AICancellations />
                <br />
                <CheckHide k="AvailabilityAndDemand">
                    <AvailabilityAndDemandChart />
                </CheckHide>
            </Container>
            <br />
            <br />
            <br />
        </>
    );
};
export default AIScreen;
