import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Container,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

import EChartsReact from "echarts-for-react";

import WrapperPDFTable from "../shared/WrapperPDFTable";
import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import CircleValue from "../CircleValue";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import SmallPercentage from "../SmallPercentage";
import TableHeaderCell from "../TableHeaderCell";
import TableBodyCell from "../TableBodyCell";
import DateSelector from "../DateSelector";
import SwitchVisualTable, {
    TABLE_MODE,
    VISUAL_MODE,
} from "../SwitchVisualTable";
import OptionsButtonDialog from "../OptionsButtonDialog";

import Colours from "../../helpers/colours";
import { getYearsBefore, months } from "../../helpers/dates";
import { SliceTop, TOP_ALL } from "../../helpers/reports";
import { fns } from "../../helpers/common";

const TopVouchersReport = ({ hotelID }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));
    let collapse = useMediaQuery(theme.breakpoints.down("sm"));

    const TOP = [10, 25, TOP_ALL, 5];
    const TITLE = "Top Performing Vouchers";
    const REPORT_DESC = "This report shows the top selling vouchers.";
    const HEADER = [
        {
            id: "vouchers",
            label: "Vouchers",
        },
        {
            id: "voucherPriceMin",
            label: "Min Price",
            tooltip: "Lowest price during the date",
            format: { left: "currency" },
        },
        {
            id: "voucherPriceMax",
            label: "Max Price",
            tooltip: "Highest price during the date",
            format: { left: "currency" },
        },
        {
            id: "revenue",
            label: "Revenue",
            format: { left: "currency" },
        },
        {
            id: "vouchersRedeemed",
            label: "Redeemed",
        },
    ];

    const { dataVouchersDetails } = useSelector((state) => state);

    const [connectionError, setConnectionError] = useState(false);
    const [mode, setMode] = useState(TABLE_MODE);

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(false);
    const [cancellations, setCancellations] = useState(false);
    const { time: sharedTime, fullYear: sharedFullYear } = useSelector(
        (state) => state.settings.settings
    );

    const [topNumber, setTopNumber] = useState(0);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("vouchers");

    const [rawData, setRawData] = useState({});
    const [selectedData, setSelectedData] = useState({});

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    //   _ __  _ __ ___   ___ ___  ___ ___
    //  | '_ \| '__/ _ \ / __/ _ \/ __/ __|
    //  | |_) | | | (_) | (_|  __/\__ \__ \
    //  | .__/|_|  \___/ \___\___||___/___/
    //  |_|
    useEffect(() => {
        if (dataVouchersDetails.status === "error") setConnectionError(true);
        if (dataVouchersDetails.status === "loaded") {
            // Prepare
            let auxMonths = {};
            let auxYears = {};
            let template = {
                vouchers: 0,
                voucherPriceMin: 0,
                voucherPriceMax: 0,
                revenue: 0,
                vouchersRedeemed: 0,
            };
            getYearsBefore().map((year) => {
                auxMonths[year] = {};
                auxYears[year] = {};
                months.map((month) => {
                    auxMonths[year][month] = {};
                    return "";
                });
                return "";
            });

            // Process
            Object.keys(dataVouchersDetails.data).map((year) => {
                Object.keys(dataVouchersDetails.data[year]).map((month) => {
                    let elements = dataVouchersDetails.data[year][month];
                    elements.forEach(
                        ({
                            Title,
                            TotalPrice,
                            VoucherPrice,
                            RedeemStatus,
                            CancelStatus,
                        }) => {
                            if (!cancellations) {
                                if (Title) {
                                    // New offer (offer is not in obj)
                                    if (
                                        !auxMonths[year][month].hasOwnProperty(
                                            Title
                                        )
                                    ) {
                                        auxMonths[year][month][Title] = {
                                            ...template,
                                        };
                                        auxMonths[year][month][
                                            Title
                                        ].voucherPriceMin = VoucherPrice;
                                        auxMonths[year][month][
                                            Title
                                        ].voucherPriceMax = VoucherPrice;
                                    }
                                    if (!auxYears[year].hasOwnProperty(Title)) {
                                        auxYears[year][Title] = {
                                            ...template,
                                        };
                                        auxYears[year][Title].voucherPriceMin =
                                            VoucherPrice;
                                        auxYears[year][Title].voucherPriceMax =
                                            VoucherPrice;
                                    }

                                    // Old offer (offer is in obj)
                                    auxMonths[year][month][Title].vouchers += 1;
                                    auxMonths[year][month][
                                        Title
                                    ].vouchersRedeemed += RedeemStatus;
                                    auxMonths[year][month][Title].revenue +=
                                        TotalPrice;
                                    auxMonths[year][month][
                                        Title
                                    ].voucherPriceMin =
                                        VoucherPrice >
                                        auxMonths[year][month][Title]
                                            .voucherPriceMin
                                            ? auxMonths[year][month][Title]
                                                  .voucherPriceMin
                                            : VoucherPrice;
                                    auxMonths[year][month][
                                        Title
                                    ].voucherPriceMax =
                                        VoucherPrice <
                                        auxMonths[year][month][Title]
                                            .voucherPriceMax
                                            ? auxMonths[year][month][Title]
                                                  .voucherPriceMax
                                            : VoucherPrice;

                                    auxYears[year][Title].vouchers += 1;
                                    auxYears[year][Title].vouchersRedeemed +=
                                        RedeemStatus;
                                    auxYears[year][Title].revenue += TotalPrice;
                                    auxYears[year][Title].voucherPriceMin =
                                        VoucherPrice >
                                        auxYears[year][Title].voucherPriceMin
                                            ? auxYears[year][Title]
                                                  .voucherPriceMin
                                            : VoucherPrice;
                                    auxYears[year][Title].voucherPriceMax =
                                        VoucherPrice <
                                        auxYears[year][Title].voucherPriceMax
                                            ? auxYears[year][Title]
                                                  .voucherPriceMin
                                            : VoucherPrice;
                                }
                            } else if (Title && CancelStatus === 1) {
                                // New offer (offer is not in obj)
                                if (
                                    !auxMonths[year][month].hasOwnProperty(
                                        Title
                                    )
                                ) {
                                    auxMonths[year][month][Title] = {
                                        ...template,
                                    };
                                    auxMonths[year][month][
                                        Title
                                    ].voucherPriceMin = VoucherPrice;
                                    auxMonths[year][month][
                                        Title
                                    ].voucherPriceMax = VoucherPrice;
                                }
                                if (!auxYears[year].hasOwnProperty(Title)) {
                                    auxYears[year][Title] = {
                                        ...template,
                                    };
                                    auxYears[year][Title].voucherPriceMin =
                                        VoucherPrice;
                                    auxYears[year][Title].voucherPriceMax =
                                        VoucherPrice;
                                }

                                // Old offer (offer is in obj)
                                auxMonths[year][month][Title].vouchers += 1;
                                auxMonths[year][month][
                                    Title
                                ].vouchersRedeemed += RedeemStatus;
                                auxMonths[year][month][Title].revenue +=
                                    TotalPrice;
                                auxMonths[year][month][Title].voucherPriceMin =
                                    VoucherPrice >
                                    auxMonths[year][month][Title]
                                        .voucherPriceMin
                                        ? auxMonths[year][month][Title]
                                              .voucherPriceMin
                                        : VoucherPrice;
                                auxMonths[year][month][Title].voucherPriceMax =
                                    VoucherPrice <
                                    auxMonths[year][month][Title]
                                        .voucherPriceMax
                                        ? auxMonths[year][month][Title]
                                              .voucherPriceMax
                                        : VoucherPrice;

                                auxYears[year][Title].vouchers += 1;
                                auxYears[year][Title].vouchersRedeemed +=
                                    RedeemStatus;
                                auxYears[year][Title].revenue += TotalPrice;
                                auxYears[year][Title].voucherPriceMin =
                                    VoucherPrice >
                                    auxYears[year][Title].voucherPriceMin
                                        ? auxYears[year][Title].voucherPriceMin
                                        : VoucherPrice;
                                auxYears[year][Title].voucherPriceMax =
                                    VoucherPrice <
                                    auxYears[year][Title].voucherPriceMax
                                        ? auxYears[year][Title].voucherPriceMin
                                        : VoucherPrice;
                            }
                        }
                    );
                    return undefined;
                });
                return undefined;
            });

            setRawData({ years: auxYears, months: auxMonths });

            setLoaded(true);
        } else setLoaded(false);
    }, [dataVouchersDetails, cancellations]);

    //   _                        ___                    _
    //  / |   __ _  __ _  ___    ( _ )    ___  ___  _ __| |_
    //  | |  / _` |/ _` |/ _ \   / _ \/\ / __|/ _ \| '__| __|
    //  | | | (_| | (_| | (_) | | (_>  < \__ \ (_) | |  | |_
    //  |_|  \__,_|\__, |\___/   \___/\/ |___/\___/|_|   \__|
    //             |___/
    const getDataArray = (year, month) => {
        if (rawData.months.hasOwnProperty(year)) {
            if (month)
                return Object.entries(rawData.months[year][month] ?? {}).map(
                    ([key, value]) => ({ ...value, key })
                );

            return Object.entries(rawData.years[year] ?? {}).map(
                ([key, value]) => ({ ...value, key })
            );
        }
        return [];
    };
    useEffect(() => {
        if (Object.keys(rawData).length > 0) {
            setSelectedData([]);
            let month = time.getUTCMonth();
            let year = time.getUTCFullYear();
            let aux = getDataArray(year, fullYear ? false : months[month]);
            // Calculate one year ago
            let auxBefore = getDataArray(
                year - 1,
                fullYear ? false : months[month]
            );
            aux.forEach((element) => {
                let key = element.key;
                let bef = auxBefore.filter((el) => el.key === key);
                if (bef.length > 0) {
                    element.vouchers_before = bef[0].vouchers;
                    element.voucherPriceMin_before = bef[0].voucherPriceMin;
                    element.voucherPriceMax_before = bef[0].voucherPriceMax;
                    element.revenue_before = bef[0].revenue;
                    element.vouchersRedeemed_before = bef[0].vouchersRedeemed;
                }
            });
            // Sort
            aux.sort((a, b) => {
                if (order === "asc") return a[orderBy] >= b[orderBy] ? -1 : 1;
                else return a[orderBy] >= b[orderBy] ? 1 : -1;
            });
            setSelectedData(SliceTop(aux, TOP[topNumber]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, rawData, order, orderBy, topNumber]);

    return (
        <Container maxWidth={false} component={HoverPaper}>
            <br />
            <Stack
                direction={{ md: "column", sm: "row", xs: "row" }}
                justifyContent={isMobile ? "space-around" : "space-between"}
                alignItems="flex-start"
                // style={{ border: "1px solid red" }}
            >
                {/* Title */}
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={"center"}
                    // style={{ border: "1px solid orange" }}
                >
                    <Stack
                        alignItems="center"
                        // style={{ border: "1px solid yellow" }}
                    >
                        <Typography fontWeight={"bold"} textAlign="center">
                            {`${TITLE}${cancellations ? " - Cancelled" : ""}`}
                        </Typography>
                    </Stack>
                    <InfoIcon text={REPORT_DESC} />
                    <ErrorIcon show={connectionError} />
                </Stack>

                {isMobile ? (
                    <OptionsButtonDialog>
                        <SwitchVisualTable
                            collapse={true}
                            modes={[TABLE_MODE, VISUAL_MODE]}
                            mode={mode}
                            setMode={setMode}
                        />
                        <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        />
                        <FormControlLabel
                            label="See Cancelled"
                            control={
                                <Checkbox
                                    checked={cancellations}
                                    onChange={() => {
                                        console.log(!cancellations);
                                        setCancellations(!cancellations);
                                    }}
                                />
                            }
                        />
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography>{`Switch to top ${
                                TOP[
                                    (topNumber + 1) %
                                        (mode === TABLE_MODE
                                            ? TOP.length
                                            : TOP.length - 1)
                                ]
                            }`}</Typography>
                            <CircleValue
                                value={`${TOP[topNumber]}`}
                                tooltip={`Switch to top ${
                                    TOP[
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    ]
                                }`}
                                color={Colours.primary}
                                onClick={() => {
                                    setTopNumber(
                                        (topNumber + 1) %
                                            (mode === TABLE_MODE
                                                ? TOP.length
                                                : TOP.length - 1)
                                    );
                                }}
                                style={{ cursor: "pointer" }}
                            />
                        </Stack>
                        {""}
                    </OptionsButtonDialog>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        // style={{ border: "1px solid green   " }}
                    >
                        <CircleValue
                            value={`${TOP[topNumber]}`}
                            tooltip={`Switch to top ${
                                TOP[(topNumber + 1) % TOP.length]
                            }`}
                            color={Colours.primary}
                            onClick={() => {
                                setTopNumber((topNumber + 1) % TOP.length);
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <SwitchVisualTable
                            modes={[TABLE_MODE, VISUAL_MODE]}
                            mode={mode}
                            setMode={setMode}
                        />
                        <DateSelector
                            collapse={isMobile}
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={time}
                            setTime={setTime}
                            day={false}
                        />
                        <FormControlLabel
                            label="See Cancelled"
                            control={
                                <Checkbox
                                    checked={cancellations}
                                    onChange={() => {
                                        console.log(!cancellations);
                                        setCancellations(!cancellations);
                                    }}
                                />
                            }
                        />
                    </Stack>
                )}
            </Stack>

            {!loaded ? (
                <>
                    {" "}
                    <br /> <ProgressBarSteps />{" "}
                </>
            ) : (
                ""
            )}

            {mode === TABLE_MODE && (
                //   _____  _    ____  _     _____
                //  |_   _|/ \  | __ )| |   | ____|
                //    | | / _ \ |  _ \| |   |  _|
                //    | |/ ___ \| |_) | |___| |___
                //    |_/_/   \_\____/|_____|_____|
                <>
                    <TableContainer
                        style={{ overflow: "auto", maxHeight: "600px" }}
                    >
                        <WrapperPDFTable k={TITLE}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderCell
                                            style={{
                                                position: "sticky",
                                                left: 0,
                                                top: 0,
                                                zIndex: 4,
                                                backgroundColor:
                                                    Colours.plainWhite,
                                            }}
                                        >
                                            Title
                                        </TableHeaderCell>
                                        {HEADER.map((headCell) => (
                                            <TableHeaderCell
                                                key={headCell.id}
                                                sortDirection={
                                                    orderBy === headCell.id
                                                        ? order
                                                        : false
                                                }
                                                style={{
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 3,
                                                    backgroundColor:
                                                        Colours.plainWhite,
                                                }}
                                            >
                                                <TableSortLabel
                                                    active={
                                                        orderBy === headCell.id
                                                    }
                                                    direction={
                                                        orderBy === headCell.id
                                                            ? order
                                                            : "asc"
                                                    }
                                                    onClick={() => {
                                                        const isAsc =
                                                            orderBy ===
                                                                headCell.id &&
                                                            order === "asc";
                                                        setOrder(
                                                            isAsc
                                                                ? "desc"
                                                                : "asc"
                                                        );
                                                        setOrderBy(headCell.id);
                                                    }}
                                                >
                                                    {headCell.tooltip ? (
                                                        <Tooltip
                                                            title={
                                                                headCell.tooltip
                                                            }
                                                        >
                                                            <div>
                                                                {headCell.label}
                                                            </div>
                                                        </Tooltip>
                                                    ) : (
                                                        headCell.label
                                                    )}
                                                </TableSortLabel>
                                            </TableHeaderCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedData.length > 0 ? (
                                        SliceTop(
                                            selectedData,
                                            TOP[topNumber]
                                        ).map((row) => (
                                            <TableRow key={row.key}>
                                                <TableBodyCell
                                                    style={
                                                        isMobile
                                                            ? {
                                                                  position:
                                                                      "sticky",
                                                                  left: 0,
                                                                  zIndex: 1,
                                                                  backgroundColor:
                                                                      Colours.plainWhite,
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    {row.key}
                                                </TableBodyCell>
                                                {HEADER.map((head) => (
                                                    <TableBodyCell
                                                        key={head.id}
                                                    >
                                                        {fns(
                                                            hotelID,
                                                            row[head.id],
                                                            head.format ?? {}
                                                        )}
                                                        <SmallPercentage
                                                            actual={
                                                                row[head.id]
                                                            }
                                                            old={
                                                                row[
                                                                    `${head.id}_before`
                                                                ]
                                                            }
                                                            context={
                                                                fullYear
                                                                    ? `Comparing ${time.getUTCFullYear()} vs ${
                                                                          time.getUTCFullYear() -
                                                                          1
                                                                      }`
                                                                    : `Comparing ${time.getUTCFullYear()}-${
                                                                          months[
                                                                              time.getUTCMonth()
                                                                          ]
                                                                      } vs ${
                                                                          time.getUTCFullYear() -
                                                                          1
                                                                      }-${
                                                                          months[
                                                                              time.getUTCMonth()
                                                                          ]
                                                                      }`
                                                            }
                                                            formatter={(
                                                                number
                                                            ) =>
                                                                fns(
                                                                    hotelID,
                                                                    number,
                                                                    head.format
                                                                )
                                                            }
                                                        />
                                                    </TableBodyCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : dataVouchersDetails.status ===
                                      "loaded" ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={100}
                                                align="center"
                                            >
                                                <Box
                                                    component="span"
                                                    fontStyle="italic"
                                                >
                                                    No data
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        [
                                            ...Array(
                                                TOP[topNumber] === TOP_ALL
                                                    ? 25
                                                    : TOP[topNumber]
                                            ).keys(),
                                        ].map((e) => (
                                            <TableRow key={e}>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                {HEADER.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                    >
                                                        <Skeleton />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </WrapperPDFTable>
                    </TableContainer>
                </>
            )}
            {/*   ____ ____      _    ____  _   _  */}
            {/*  / ___|  _ \    / \  |  _ \| | | | */}
            {/* | |  _| |_) |  / _ \ | |_) | |_| | */}
            {/* | |_| |  _ <  / ___ \|  __/|  _  | */}
            {/*  \____|_| \_\/_/   \_\_|   |_| |_| */}
            {mode === VISUAL_MODE && (
                <>
                    {selectedData.length > 0 ? (
                        <>
                            <br />
                            <WrapperPDFEChart k={TITLE + " graph"}>
                                <EChartsReact
                                    style={{
                                        height: "700%",
                                        width: "100%",
                                    }}
                                    option={{
                                        grid: {
                                            top: 150,
                                            bottom: 20,
                                            left: collapse ? 80 : 130,
                                        },
                                        legend: {},
                                        tooltip: {
                                            trigger: "axis",
                                            axisPointer: {
                                                type: "shadow",
                                            },
                                        },
                                        yAxis: {
                                            inverse: true,
                                            type: "category",
                                            axisLabel: {
                                                formatter: (value) => {
                                                    let ret = [];
                                                    value
                                                        .split(" - ")
                                                        .map((f) => {
                                                            let aux = "";
                                                            f.split(" ").map(
                                                                (e) => {
                                                                    if (
                                                                        collapse
                                                                            ? 13
                                                                            : 20
                                                                    ) {
                                                                        aux +=
                                                                            "\n";
                                                                    }
                                                                    aux +=
                                                                        e + " ";
                                                                    return "";
                                                                }
                                                            );
                                                            ret.push(aux);
                                                            return "";
                                                        });
                                                    return ret.join("\n");
                                                },
                                            },
                                        },
                                        xAxis: [
                                            ...HEADER.map((head, index) => {
                                                return {
                                                    type: "value",
                                                    position: "top",
                                                    axisLabel: {
                                                        margin: 87 - index * 13,
                                                        padding: 0 - index * 7,

                                                        formatter: (value) => {
                                                            if (value >= 1000) {
                                                                return (
                                                                    (
                                                                        value /
                                                                        1000
                                                                    ).toFixed(
                                                                        1
                                                                    ) + "K"
                                                                );
                                                            } else {
                                                                return value;
                                                            }
                                                        },
                                                    },
                                                    name: head.label,
                                                    nameLocation: "start",
                                                    nameGap: 0,
                                                    nameTextStyle: {
                                                        fontStyle: "italic",
                                                        align: "right",
                                                        verticalAlign: "top",
                                                        padding: [
                                                            index * 20 - 100,
                                                            10,
                                                            0,
                                                            0,
                                                        ], //t, l, b, r
                                                    },
                                                };
                                            }),
                                        ],
                                        dataZoom: [
                                            {
                                                show: true,
                                                yAxisIndex: 0,
                                            },
                                            {
                                                type: "inside",
                                                yAxisIndex: 0,
                                            },
                                        ],
                                        dataset: {
                                            source: [
                                                [
                                                    "Title",
                                                    ...HEADER.map(
                                                        (head) => head.label
                                                    ),
                                                ],
                                                ...SliceTop(
                                                    selectedData,
                                                    TOP[topNumber]
                                                ).map((element) => [
                                                    element.key,
                                                    element.vouchers,
                                                    element.voucherPriceMin,
                                                    element.voucherPriceMax,
                                                    element.revenue,
                                                    element.vouchersRedeemed,
                                                ]),
                                            ],
                                        },
                                        series: [
                                            {
                                                type: "bar",
                                                xAxisIndex: 0,
                                                itemStyle: {
                                                    color: Colours.attract,
                                                },
                                            },
                                            {
                                                type: "bar",
                                                xAxisIndex: 1,
                                                itemStyle: {
                                                    color: Colours.engage,
                                                },
                                            },
                                            {
                                                type: "bar",
                                                xAxisIndex: 2,
                                                itemStyle: {
                                                    color: Colours.convert,
                                                },
                                            },
                                            {
                                                type: "bar",
                                                xAxisIndex: 3,
                                                itemStyle: {
                                                    color: Colours.retain,
                                                },
                                            },
                                            {
                                                type: "bar",
                                                xAxisIndex: 4,
                                                itemStyle: {
                                                    color: Colours.info,
                                                },
                                            },
                                        ],
                                    }}
                                />
                            </WrapperPDFEChart>
                        </>
                    ) : (
                        <>
                            <br />
                            <Skeleton height={750} variant="rounded" />
                        </>
                    )}
                </>
            )}
            <br />
        </Container>
    );
};

export default TopVouchersReport;
