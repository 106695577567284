export const SETTING_readability = {
    id: "readability",
    text: "Readability improvement",
    type: "switch",
    default: "false",
    icon: "Size",
};
export const SETTING_defaultHomeReport = {
    id: "defaultHomeReport",
    text: "Default Report",
    type: "select1",
    options: {
        RoomNights: "Room Nights",
        RevenueChart: "Revenue Chart",
        SessionsChart: "Sessions Chart",
        VouchersReport: "Vouchers Report",
        CheckOutBusiness: "CheckOut Business",
    },
    default: "RoomNights",
    icon: "Reports",
};
