import React, { useEffect, useState } from "react";

import {
    Button,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
} from "@mui/material";

import Fuse from "fuse.js";

import FilterDropdown, { FilterChips } from "../FiltersDropdown";
import { EventsCalendarButton } from "./EventsCalendar";
import EventsModal from "./EventsModal";
import { EventsAddMultipleButton } from "./EventsAddMultiple";
import DateRangePicker from "../DateRangePicker";
import TableHeadSort from "../TableHeadSort";

import { CATEGORY, OWNER, EVENT_TYPE, PRIORITY } from "../../helpers/events";
import { dateToUTC, newDateUTC } from "../../helpers/dates";
import Icons from "../../helpers/icons";
import Colours, { pastelColours } from "../../helpers/colours";
import EventsDetails from "./EventsDetails";

const ITEM_HEIGHT = "43px";
export const LIST_MODE = "LIST_MODE";

const transformOptions = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] = value.label;
        return acc;
    }, {});
};

const EventsList = ({
    events,
    isMobile = false,
    setMainMode,
    handleRefreshEvents,
    hotelID,
}) => {
    const [fromDate, setFromDate] = useState(
        newDateUTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
    );
    const [toDate, setToDate] = useState(
        newDateUTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0)
    );

    const [querySearch, setQuerySearch] = useState("");
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [transformedEvents, setTransformedEvents] = useState([]);

    const HEADER = [
        {
            id: "FromDate",
            label: "Date",
            format: (event) => {
                let strFrom = new Date(event["FromDate"])
                    .toUTCString()
                    .slice(0, 16);
                let strTo = new Date(event["ToDate"])
                    .toUTCString()
                    .slice(0, 16);
                return strFrom === strTo ? strFrom : `${strFrom} - ${strTo}`;
            },
        },
        {
            id: "Name",
            label: "Name",
            ignore: true,
            style: { maxWidth: "250px" },
        },
        {
            id: "Category",
            label: "Category",
            ignore: true,
            format: (val) =>
                CATEGORY.hasOwnProperty(val) ? CATEGORY[val].label : val,
        },
        {
            id: "Owner",
            label: "Owner",
            ignore: true,
            format: (val) =>
                OWNER.hasOwnProperty(val) ? OWNER[val].label : val,
        },
        {
            id: "ActionAlert",
            label: "Type",
            ignore: true,
            format: (val) =>
                EVENT_TYPE.hasOwnProperty(val) ? EVENT_TYPE[val].label : val,
        },
        {
            id: "Priority",
            label: "Priority",
            ignore: true,
            format: (val) => {
                if (PRIORITY.hasOwnProperty(val)) {
                    let { Icon, color, label } = PRIORITY[val];
                    return (
                        <Stack direction={"row"}>
                            <Icon
                                sx={{
                                    mr: 1,
                                    color: color,
                                }}
                            />

                            {label}
                        </Stack>
                    );
                } else return val;
            },
        },
    ];
    const [order, setOrder] = useState("asc");
    const [sortedEvents, setSortedEvents] = useState([]);

    useEffect(() => {
        let sortArray = (arr, order) => {
            return arr.sort((a, b) => {
                if (order === "asc")
                    return a["FromDate"] > b["FromDate"] ? -1 : 1;
                else return a["FromDate"] >= b["FromDate"] ? 1 : -1;
            });
        };

        let auxSortedEvents = sortArray([...filteredEvents], order);

        setSortedEvents(auxSortedEvents);
    }, [filteredEvents, order]);

    const [activeFilters, setActiveFilters] = useState({
        Category: [],
        ActionAlert: [],
        Owner: [],
        Priority: [],
    });

    const FILTERS = {
        Category: {
            label: "Category",
            options: transformOptions(CATEGORY),
        },
        ActionAlert: {
            label: "Event Type",
            options: transformOptions(EVENT_TYPE),
        },
        Owner: {
            label: "Owner",
            options: transformOptions(OWNER),
        },
        Priority: {
            label: "Priority",
            options: transformOptions(PRIORITY),
        },
    };

    const filterEvents = () => {
        let auxFilteredEvents = transformedEvents;

        if (querySearch !== "") {
            const searchOptions = {
                threshold: 0.2,
                keys: [
                    { name: "Name" /*weight: 0.8*/ },
                    { name: "Description" /*weight: 0.8*/ },
                    { name: "FromDateString" /*weight: 100*/ },
                    { name: "FromDate" /*weight: 100*/ },
                ],
            }; // TODO: Weight tuning not working properly to make text look for similarities, and make dates be more accurate

            const fuseSearch = new Fuse(transformedEvents, searchOptions);

            auxFilteredEvents = fuseSearch
                .search(querySearch)
                .map(({ item: event, index }) => event);
        }

        if (
            !Object.values(activeFilters).every(
                (filters) => filters.length === 0
            )
        ) {
            auxFilteredEvents = auxFilteredEvents.filter((event) => {
                let includeEvent = Object.entries(activeFilters).every(
                    ([key, filters]) => {
                        let isIncluded = true;
                        if (filters.length > 0) {
                            isIncluded = filters.includes(event[key]);
                        }

                        return isIncluded;
                    }
                );
                return includeEvent;
            });
        }

        setFilteredEvents(auxFilteredEvents);
    };

    useEffect(() => {
        if (transformedEvents.length > 0) filterEvents();
    }, [querySearch, transformedEvents, activeFilters]);

    useEffect(() => {
        if (Object.keys(events).length > 0) {
            let auxTransformedEvents = [];

            Object.keys(events).forEach((dateString) => {
                let date = dateToUTC(new Date(dateString));

                if (date >= fromDate && date <= toDate) {
                    events[dateString].forEach((event) => {
                        let auxEvent = event;
                        auxEvent["FromDateString"] = new Date(event.FromDate)
                            .toUTCString()
                            .slice(0, 16);
                        auxEvent.FromDate = dateString;
                        auxTransformedEvents.push(auxEvent);
                    });
                }
            });

            setTransformedEvents(auxTransformedEvents);
        }
    }, [events, fromDate, toDate]);

    const [openDetails, setOpenDetails] = useState(false);
    const [detailsAnchor, setDetailsAnchor] = useState(null);
    const [eventClicked, setEventClicked] = useState({});

    return (
        <>
            <Stack
                direction={"column"}
                spacing={2}
                sx={{
                    minWidth: "300px",
                    minHeight: "400px",
                }}
            >
                <Stack spacing={1}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <DateRangePicker
                            boxProps={{ sx: { height: ITEM_HEIGHT } }}
                            fromDateProps={{
                                value: fromDate,
                                maxDate: new Date(
                                    new Date().getUTCFullYear() + 2,
                                    0,
                                    0
                                ),
                            }}
                            onChangeFromDate={(newValue) => {
                                setFromDate(newValue);
                            }}
                            toDateProps={{
                                value: toDate,
                                maxDate: new Date(
                                    new Date().getUTCFullYear() + 2,
                                    0,
                                    0
                                ),
                            }}
                            onChangeToDate={(newValue) => {
                                setToDate(newValue);
                            }}
                        />
                        <Stack direction={"row"} spacing={1}>
                            <EventsCalendarButton
                                isMobile={isMobile}
                                setMainMode={setMainMode}
                            />
                            <EventsModal
                                hotelID={hotelID}
                                handleRefreshEvents={handleRefreshEvents}
                            />
                            <EventsAddMultipleButton isMobile={isMobile} />
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <TextField
                            id="search-bar"
                            className="text"
                            onInput={(e) => {
                                setQuerySearch(e.target.value);
                            }}
                            value={querySearch}
                            variant="outlined"
                            placeholder="Search..."
                            sx={{ minWidth: "200px" }}
                            autoComplete="off"
                            size={"small"}
                            autoFocus
                        />

                        <FilterDropdown
                            title={"Filters"}
                            buttonProps={{ sx: { height: ITEM_HEIGHT } }}
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                            filters={FILTERS}
                        />

                        <FilterChips
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                            filters={FILTERS}
                        />
                    </Stack>
                </Stack>
                <TableContainer
                    style={{
                        maxHeight: isMobile ? "100px" : "600px",
                        overflow: "auto",
                    }}
                >
                    <Table>
                        <TableHeadSort
                            //stickyHeads={tableOverflow ? 2 : 0}
                            headers={HEADER.map((headCell) => {
                                headCell.styles = {
                                    textAlign: "left",
                                    backgroundColor: Colours.tableRowBackground,
                                    fontWeight: "normal",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                };
                                return headCell;
                            })}
                            order={order}
                            setOrder={setOrder}
                            orderBy={"FromDate"}
                            setOrderBy={() => {}}
                        />
                        <TableBody>
                            {sortedEvents.length > 0 ? (
                                sortedEvents.map((event, index) => {
                                    return (
                                        <TableRow
                                            key={
                                                event.EventID +
                                                "-sorted-" +
                                                order
                                            }
                                            onClick={(v) => {
                                                setOpenDetails(true);
                                                setDetailsAnchor(
                                                    v.currentTarget
                                                );
                                                setEventClicked(event);
                                            }}
                                            sx={{
                                                cursor: "pointer",
                                                opacity:
                                                    event.ToDate < newDateUTC()
                                                        ? 0.5
                                                        : 1,
                                                border: "2px solid lightgrey",
                                                borderLeft: "3px solid",
                                                borderLeftColor:
                                                    (
                                                        CATEGORY[
                                                            event.Category
                                                        ] ?? {}
                                                    ).color ?? pastelColours[0],
                                            }}
                                        >
                                            {HEADER.map(
                                                ({ id, format, style }) => {
                                                    let val = event[id];
                                                    if (id === "FromDate")
                                                        val = event;
                                                    return (
                                                        <TableCell
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                                ...(style ??
                                                                    {}),
                                                            }}
                                                            noWrap
                                                        >
                                                            {format
                                                                ? format(val)
                                                                : val}
                                                        </TableCell>
                                                    );
                                                }
                                            )}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell>{"No results"}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            {openDetails && Object.keys(eventClicked).length > 0 && (
                <EventsDetails
                    handleRefreshEvents={handleRefreshEvents}
                    hotelID={hotelID}
                    eventDetails={eventClicked}
                    anchorClick={detailsAnchor}
                    onCloseDetails={() => {
                        setOpenDetails(false);
                        setDetailsAnchor(null);
                        setEventClicked({});
                    }}
                />
            )}
        </>
    );
};

export default EventsList;

export const EventsListButton = ({ isMobile = false, setMainMode }) => {
    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid orange",
                borderRadius: 1000,
                "&:hover": {
                    backgroundColor: "orange",
                    color: "white",
                },
            }}
            disableElevation
            onClick={(v) => {
                setMainMode();
            }}
        >
            <>
                <Icons.List sx={{ paddingRight: 1 }} />
                {isMobile ? "" : "List"}
            </>
        </Button>
    );
};
