import { Tooltip } from "@mui/material";
import React from "react";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

import "../styles/animations.css";

const ErrorIcon = ({ show, tooltip = "Error pulling the data" }) => {
    if (!show) return;
    else
        return (
            <Tooltip title={tooltip}>
                <Icons.ReportError
                    sx={{
                        color: Colours.error,
                    }}
                    className="blink-fade"
                />
            </Tooltip>
        );
};

export default ErrorIcon;
