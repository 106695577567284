import React from "react";
import { useSelector } from "react-redux";

import { TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";

import Colours from "../helpers/colours";

import TableHeaderCell from "./TableHeaderCell";
import { fns } from "../helpers/common";

// headers = [{ id: "", label: "", tooltip: "", ignore: false, ... }, {...}];
const TableHeadSort = ({
    stickyHeads = 0,
    headers,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    colSpan = 1,
}) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);

    return (
        <TableHead>
            <TableRow>
                {headers.map((headCell, index) => (
                    <TableHeaderCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            position: "sticky",
                            top: 0,
                            zIndex: index < stickyHeads ? 4 : 3,
                            left: index < stickyHeads ? index * 76 : "",
                            backgroundColor: Colours.plainWhite,
                            borderCollapse: "separate",
                            ...headCell.styles,
                        }}
                        colSpan={colSpan}
                    >
                        {"ignore" in headCell ? (
                            <>{headCell.label}</>
                        ) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : "asc"
                                }
                                onClick={() => {
                                    // const reset =
                                    //     orderBy === headCell.id &&
                                    //     order === "desc";
                                    const isAsc =
                                        orderBy === headCell.id &&
                                        order === "asc";
                                    setOrder(
                                        // reset ? "" :
                                        isAsc ? "desc" : "asc"
                                    );
                                    setOrderBy(
                                        // reset ? "" :
                                        headCell.id
                                    );
                                }}
                            >
                                {headCell.tooltip ? (
                                    <Tooltip title={headCell.tooltip}>
                                        <div>
                                            {fns(
                                                hotelID,
                                                headCell.label,
                                                headCell.headerFormat ?? {}
                                            )}
                                        </div>
                                    </Tooltip>
                                ) : (
                                    fns(
                                        hotelID,
                                        headCell.label,
                                        headCell.headerFormat ?? {}
                                    )
                                )}
                            </TableSortLabel>
                        )}
                    </TableHeaderCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export const sortArray = (arr, orderBy, order) => {
    return arr.sort((a, b) => {
        if (order === "asc") return a[orderBy] >= b[orderBy] ? -1 : 1;
        else return a[orderBy] >= b[orderBy] ? 1 : -1;
    });
};

export default TableHeadSort;
