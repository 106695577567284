import React from "react";

import { Box, Container, Paper, Stack, Typography, Grid } from "@mui/material";
import EventsAddMultiple from "../components/events/EventsAddMultiple";
import GoBack from "../components/GoBack";

const EventsAddManyScreen = () => {
    return (
        <>
            <Container maxWidth={false}>
                <Grid container>
                    <Grid item sx={{ mt: 2, mb: 2 }} xs={10}>
                        <Typography variant="h4">Add many events</Typography>
                    </Grid>
                    <Grid item sx={{ mt: 2 }}>
                        <GoBack to={"/app/plus/events"} />
                    </Grid>
                </Grid>
                <Paper sx={{ p: 1, pt: 2, pb: 3, overflowX: "auto" }}>
                    <EventsAddMultiple handleRefreshEvents={() => {}} />
                </Paper>
            </Container>
        </>
    );
};
export default EventsAddManyScreen;
