import { dataVouchersDetailsTypes } from "../types/dataVouchersDetailsTypes";

export const loading = (hotelID) => {
    return {
        type: dataVouchersDetailsTypes.loading,
        hotelID,
    };
};
export const partialLoad = (data, hotelID) => {
    return {
        type: dataVouchersDetailsTypes.partialLoad,
        payload: data,
        hotelID,
    };
};
export const load = (data, hotelID) => {
    return {
        type: dataVouchersDetailsTypes.load,
        payload: data,
        hotelID,
    };
};
export const error = (error, data) => {
    return {
        type: dataVouchersDetailsTypes.error,
        payload: data,
        error: error,
    };
};
