import {
    IconButton,
    InputAdornment,
    InputBase,
    createTheme,
    styled,
} from "@mui/material";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

const Search = styled("div")(({ theme, query }) => ({
    position: "relative",
    border: "1px solid",
    borderColor: Colours.graphDisabledLighter,
    borderRadius: 100,
    backgroundColor: "white",
    "&:hover": {
        borderColor: Colours.graphDisabled,
    },
    "&:focus-within": {
        borderColor: Colours.primary, // Change outline color on focus
        width: "20ch",
    },
    transition: theme.transitions.create("width"),

    marginLeft: 0,
    width: query ? "20ch" : "15ch",

    [theme.breakpoints.down("xs")]: {
        width: query ? "20ch" : "5ch",
        "&:focus-within": {
            width: "20ch",
        },
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme, query }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 0, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",

        [theme.breakpoints.down("xs")]: {
            width: query ? "12ch" : "0",
            "&:focus": {
                width: "12ch",
            },
        },
    },
}));

const SearchButtonAndField = ({ setQuery, query = "" }) => {
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 400,
                sm: 600,
            },
        },
    });
    const handleClearClick = () => {
        setQuery("");
    };
    return (
        <Search theme={theme} query={query}>
            <SearchIconWrapper>
                <Icons.Search />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={(event) => {
                    const newQuery = event.target.value;
                    setQuery(newQuery);
                }}
                query={query}
                value={query}
                theme={theme}
                endAdornment={
                    query && (
                        <InputAdornment position="end" sx={{ paddingRight: 1 }}>
                            <Icons.CloseNavbar
                                sx={{ color: "black" }}
                                onClick={handleClearClick}
                            />
                        </InputAdornment>
                    )
                }
            />
        </Search>
    );
};
export default SearchButtonAndField;
