import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const NotificationNewVersion = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        const updateListener = () => {
            enqueueSnackbar("New version available.", {
                variant: "info",
                preventDuplicate: true,
                persist: true,
                style: { whiteSpace: "pre-line" },
                action: (key) => (
                    <>
                        <Button
                            color="inherit"
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                caches.keys().then(function (names) {
                                    for (let name of names) caches.delete(name);
                                    window.location.reload(true);
                                });
                            }}
                        >
                            Update page
                        </Button>{" "}
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            sx={{ pl: 2 }}
                            onClick={() => closeSnackbar(key)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                ),
            });
        };

        document.addEventListener("appUpdateAvailable", updateListener);

        // Clean the event
        return () => {
            document.removeEventListener("appUpdateAvailable", updateListener);
        };
    }, [enqueueSnackbar, closeSnackbar]);

    return null; // Not rendering anything in the component tree
};

export default NotificationNewVersion;
