import React, { useEffect, useState } from "react";

import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";

import MembershipTotalsReport from "./MembershipTotalsReport";
import MembershipSignUpsReport from "./MembershipSignUpsReport";
import MembershipBookingsReport from "./MembershipBookingsReport";
import MembershipALoSReport from "./MembershipALoSReport";
import MembershipRepeatBookersReport from "./MembershipRepeatBookersReport";
import MembershipRoomNightsReport from "./MembershipRoomNightsReport";
import TabSelector from "../TabSelector";

const MembershipCardsReport = ({
    hotelID = 93,
    time = new Date(),
    dataTotalsRaw,
    dataSignupsRaw,
    dataRepeatBookersRaw,
    dataBookingsRaw,
    dataDetailsRaw,
}) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [clickedCard, setClickedCard] = useState("");

    const [previewTotals, setPreviewTotals] = useState(-1);
    const [dataTotals, setDataTotals] = useState([]);

    const [previewSignups, setPreviewSignups] = useState(-1);
    const [dataSignups, setDataSignups] = useState({});

    const [previewRepeatBookers, setPreviewRepeatBookers] = useState({
        members: -1,
        nonMembers: -1,
    });
    const [dataRepeatBookers, setDataRepeatBookers] = useState({});

    const [previewBookings, setPreviewBookings] = useState(-1);
    const [dataBookings, setDataBookings] = useState({});

    const [previewALoS, setPreviewALoS] = useState(-1);
    const [dataALoS, setDataALoS] = useState({});

    const [previewRoomNights, setPreviewRoomNights] = useState(-1);
    const [dataRoomNights, setDataRoomNights] = useState({});

    useEffect(() => {
        if (dataTotalsRaw.length > 0 && hotelID === "92") {
            setDataTotals(dataTotalsRaw);
            setPreviewTotals(dataTotalsRaw[1].Total);
        }

        if (
            dataSignupsRaw.hasOwnProperty("data") &&
            dataSignupsRaw.hasOwnProperty("summary") &&
            time &&
            hotelID === "92"
        ) {
            setDataSignups(dataSignupsRaw.data);
            setPreviewSignups(
                ((dataSignupsRaw.summary[time.getUTCFullYear()] ?? {})[
                    time.getUTCMonth()
                ] ?? {})["Total"] ?? 0
            );
        }
        if (Object.keys(dataRepeatBookersRaw).length > 0 && time) {
            setDataRepeatBookers(dataRepeatBookersRaw);
            setPreviewRepeatBookers(
                (dataRepeatBookersRaw[time.getUTCFullYear()] ?? {})[
                    time.getUTCMonth() + 1
                ] ?? {}
            );
        }

        if (dataBookingsRaw.hasOwnProperty(hotelID) && time) {
            setDataBookings(dataBookingsRaw[hotelID].data);
            setPreviewBookings(
                ((dataBookingsRaw[hotelID].summary[time.getUTCFullYear()] ??
                    {})[time.getUTCMonth()] ?? {})["MemberBookings"] ?? 0
            );
        }

        if (dataDetailsRaw.hasOwnProperty(hotelID) && time) {
            setDataALoS(dataDetailsRaw[hotelID].data ?? {});
            setPreviewALoS(
                ((dataDetailsRaw[hotelID].summary[time.getUTCFullYear()] ?? {})[
                    time.getUTCMonth()
                ] ?? {})["ALoS"] ?? 0
            );

            setDataRoomNights(dataDetailsRaw[hotelID].data);
            setPreviewRoomNights(
                ((dataDetailsRaw[hotelID].summary[time.getUTCFullYear()] ?? {})[
                    time.getUTCMonth()
                ] ?? {})["RoomNights"] ?? 0
            );
        }
    }, [
        hotelID,
        time,
        dataBookingsRaw,
        dataDetailsRaw,
        dataTotalsRaw,
        dataSignupsRaw,
        dataRepeatBookersRaw,
    ]);

    const HEADER = [
        {
            id: "totals",
            title: "Active Members",
            footer: "All Time",
            value: previewTotals,
            report: (
                <MembershipTotalsReport
                    hotelID={hotelID}
                    data={dataTotals}
                    isMobile={isMobile}
                    time={time}
                />
            ),
            noArrow: true,
            expand: true,
        },
        {
            id: "signUps",
            title: "Sign Ups",
            footer: "Monthly",
            value: previewSignups,
            report: (
                <MembershipSignUpsReport
                    data={dataSignups}
                    isMobile={isMobile}
                    time={time}
                />
            ),
            noArrow: true,
            expand: true,
        },
        {
            id: "repeatBookers",
            title: "Repeat Bookers",
            footer: "Monthly",
            value: previewRepeatBookers.MemberRepeatBookings ?? 0,
            report: (
                <MembershipRepeatBookersReport
                    hotelID={hotelID}
                    data={dataRepeatBookers}
                    isMobile={isMobile}
                    time={time}
                />
            ),
            noArrow: true,
            expand: true,
        },
        {
            id: "bookings",
            title: "Members Bookings",
            footer: "Monthly",
            value: previewBookings,
            report: (
                <MembershipBookingsReport
                    data={dataBookings}
                    isMobile={isMobile}
                    time={time}
                />
            ),
            noArrow: true,
            expand: true,
        },
        {
            id: "ALoS",
            title: "Members ALoS",
            footer: "Monthly",
            value: previewALoS,
            format: { maxDecimals: 2 },
            report: (
                <MembershipALoSReport
                    data={dataALoS}
                    isMobile={isMobile}
                    time={time}
                />
            ),
            noArrow: true,
            expand: true,
        },
        {
            id: "roomNights",
            title: "Room Nights",
            footer: `Monthly`,
            value: previewRoomNights,
            report: (
                <MembershipRoomNightsReport
                    data={dataRoomNights}
                    isMobile={isMobile}
                    time={time}
                />
            ),
            noArrow: true,
            expand: true,
        },
    ];

    return (
        <Stack direction={"column"} spacing={0.1}>
            <Grid
                container
                alignItems="stretch"
                spacing={0.5}
                justifyContent="left"
            >
                <TabSelector
                    hotelID={hotelID}
                    data={hotelID === "92" ? HEADER : HEADER.slice(3)}
                    cardSelected={clickedCard}
                    setCardSelected={setClickedCard}
                />
                {/* 
                 _____     _        _ 
                |_   _|__ | |_ __ _| |
                  | |/ _ \| __/ _` | |
                  | | (_) | || (_| | |
                  |_|\___/ \__\__,_|_|       
                */}
                {clickedCard === "totals" && !isMobile && (
                    <MembershipTotalsReport
                        hotelID={hotelID}
                        data={dataTotals}
                        isMobile={isMobile}
                    />
                )}
                {/*
                 ____  _             _   _           
                / ___|(_) __ _ _ __ | | | |_ __  ___ 
                \___ \| |/ _` | '_ \| | | | '_ \/ __|
                 ___) | | (_| | | | | |_| | |_) \__ \
                |____/|_|\__, |_| |_|\___/| .__/|___/
                         |___/            |_|        
                */}
                {clickedCard === "signUps" && !isMobile && (
                    <MembershipSignUpsReport
                        data={dataSignups}
                        time={time}
                        isMobile={isMobile}
                    />
                )}
                {/*
                 ____              _    _                 
                | __ )  ___   ___ | | _(_)_ __   __ _ ___ 
                |  _ \ / _ \ / _ \| |/ / | '_ \ / _` / __|
                | |_) | (_) | (_) |   <| | | | | (_| \__ \
                |____/ \___/ \___/|_|\_\_|_| |_|\__, |___/
                                                |___/     
                */}
                {clickedCard === "bookings" && !isMobile && (
                    <MembershipBookingsReport
                        data={dataBookings}
                        time={time}
                        isMobile={isMobile}
                    />
                )}
                {/* 
                         _    _         ____  
                        / \  | |    ___/ ___| 
                       / _ \ | |   / _ \___ \ 
                      / ___ \| |__| (_) |__) |
                     /_/   \_\_____\___/____/ 
                        */}
                {clickedCard === "ALoS" && !isMobile && (
                    <MembershipALoSReport
                        data={dataALoS}
                        time={time}
                        isMobile={isMobile}
                    />
                )}
                {/*
                      ____                       _   
                     |  _ \ ___ _ __   ___  __ _| |_ 
                     | |_) / _ \ '_ \ / _ \/ _` | __|
                     |  _ <  __/ |_) |  __/ (_| | |_ 
                     |_| \_\___| .__/ \___|\__,_|\__|
                               |_|       
                    */}
                {clickedCard === "repeatBookers" && !isMobile && (
                    <MembershipRepeatBookersReport
                        hotelID={hotelID}
                        data={dataRepeatBookers}
                        isMobile={isMobile}
                        time={time}
                    />
                )}
                {/*
                 ____                       _   _ _       _     _       
                |  _ \ ___   ___  _ __ ___ | \ | (_) __ _| |__ | |_ ___ 
                | |_) / _ \ / _ \| '_ ` _ \|  \| | |/ _` | '_ \| __/ __|
                |  _ < (_) | (_) | | | | | | |\  | | (_| | | | | |_\__ \
                |_| \_\___/ \___/|_| |_| |_|_| \_|_|\__, |_| |_|\__|___/
                                                    |___/               
                */}
                {clickedCard === "roomNights" && !isMobile && (
                    <MembershipRoomNightsReport
                        data={dataRoomNights}
                        time={time}
                        isMobile={isMobile}
                    />
                )}
            </Grid>
        </Stack>
    );
};

export default MembershipCardsReport;
