import { settingsTypes } from "../types/settingsTypes";

const settingsInitial = {
    status: "empty",
    settings: {},
    reload: new Date().toISOString(),
};
export const settingsReducer = (state = settingsInitial, action) => {
    switch (action.type) {
        case settingsTypes.loading:
            return { ...action, status: "loading", settings: {} };

        case settingsTypes.load:
            return { ...action, status: "loaded", settings: action.payload };

        case settingsTypes.reload:
            return {
                ...state,
                status: "reload",
                reload: new Date().toISOString(),
            };

        case settingsTypes.error:
            return {
                ...action,
                status: "error",
                settings: action.payload,
                error: action.error,
            };

        case settingsTypes.clean:
            return settingsInitial;

        // Receives [{a:true}, {b:false}, {c:15}]
        case settingsTypes.addDefault:
            return {
                ...action,
                status: state.status,
                settings: {
                    ...Object.assign({}, ...action.payload),
                    ...state.settings,
                },
            };

        // Receives {key:a, value:true}
        case settingsTypes.add:
        case settingsTypes.update:
            return {
                ...action,
                status: state.status,
                settings: {
                    ...state.settings,
                    [action.payload.key]: action.payload.value,
                },
            };

        default:
            return state;
    }
};
