import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

import {
    Box,
    Container,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from "@mui/material";
import EChartsReact from "echarts-for-react";

import WrapperPDFTable from "../shared/WrapperPDFTable";
import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import ProgressBarSteps from "../shared/ProgressBarSteps";
import HoverPaper from "../shared/HoverPaper";

import SmallPercentage from "../SmallPercentage";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import TableHeaderCell from "../TableHeaderCell";
import TableBodyCell from "../TableBodyCell";
import DateSelector, {
    currentMonthButton,
    currentYearButton,
    lastMonthButton,
} from "../DateSelector";
import SwitchVisualTable, {
    TABLE_MODE,
    VISUAL_MODE,
} from "../SwitchVisualTable";
import SwitchIcons, { LINE_MODE, BAR_MODE } from "./SwitchIcons";
import OptionsButtonDialog from "../OptionsButtonDialog";

import { getYearsBefore, months } from "../../helpers/dates";
import Colours from "../../helpers/colours";
import { fns } from "../../helpers/common";
import { reservationsToYearMonth } from "../DataLoader";

const CalcPercentage = (year, data, key, context, formatter) => {
    const latestYear = Object.keys(data).sort().reverse()[0];
    const actualYear = new Date().getUTCFullYear();

    let actual = 0;
    let old = 0;

    if (Object.keys(data).includes(actualYear)) {
        if (year === String(actualYear)) return;

        actual = (data[actualYear] ?? {})[key] ?? 0;
        old = (data[year] ?? {})[key] ?? 0;
    } else {
        if (year === String(latestYear)) return;

        actual = (data[latestYear] ?? {})[key] ?? 0;
        old = (data[year] ?? {})[key] ?? 0;
    }

    return (
        <SmallPercentage
            actual={actual}
            old={old}
            isInverted={key === "revenueCancelled" ? true : false}
            context={context}
            formatter={formatter}
        />
    );
};

const process = (data, cancellations) => {
    let aux = { voucherRevenue: 0, bookings: 0 };
    if (data.length > 0) {
        data.forEach((element) => {
            if (cancellations) {
                if (element.CancelStatus === 1 && element.Type === "Voucher") {
                    // TODO: check this
                    aux.voucherRevenue += element.TotalPrice;
                    aux.bookings++;
                }
            } else {
                if (element.Type === "Voucher") {
                    // TODO: check this
                    aux.voucherRevenue += element.TotalPrice;
                    aux.bookings++;
                }
            }
        });
    }
    return aux;
};

const VouchersRevenueReport = ({ hotelID }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    let collapse = useMediaQuery(theme.breakpoints.down("sm"));

    const REPORT_DESC =
        "This report shows summary of bookings made in a particular month/year for vouchers, including cancellations and special offers.";
    const TITLE = "Monthly Vouchers";
    const HEADER = [
        {
            id: "bookings",
            label: "Bookings",
        },
        {
            id: "voucherRevenue",
            label: "Voucher Revenue",
            format: { left: "currency" },
        },
    ];

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(false);

    const [cancellations, setCancellations] = useState(false);

    const [selectedDataSource, setSelectedDataSource] = useState({
        status: "empty",
    });

    const {
        time: sharedTime,
        fullYear: sharedFullYear,
        dataSource: sharedDataSource,
    } = useSelector((state) => state.settings.settings);

    const [displayData, setDisplayData] = useState({});
    const [connectionError, setConnectionError] = useState(false);
    const [mode, setMode] = useState(TABLE_MODE);

    const [graphMode, setGraphMode] = useState(LINE_MODE);

    const { dataBookings } = useSelector((state) => state);

    const [loaded, setLoaded] = useState(false);

    const [dataByYear, setDataByYear] = useState({});
    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});
    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const setMonthsData = [
        setDataByJanuary,
        setDataByFebruary,
        setDataByMarch,
        setDataByApril,
        setDataByMay,
        setDataByJune,
        setDataByJuly,
        setDataByAugust,
        setDataBySeptember,
        setDataByOctober,
        setDataByNovember,
        setDataByDecember,
    ];

    useEffect(() => {
        switch (sharedDataSource) {
            case "CheckOut":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(
                        dataBookings.data,
                        "CheckOut"
                    ),
                });
                break;
            case "CheckIn":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(dataBookings.data, "CheckIn"),
                });
                break;
            case "ResDate":
                setSelectedDataSource({
                    ...dataBookings,
                    data: reservationsToYearMonth(dataBookings.data, "ResDate"),
                });
                break;

            default:
                setSelectedDataSource({ status: "empty" });
                break;
        }
    }, [sharedDataSource, dataBookings]);

    useEffect(() => {
        if (sharedTime) {
            setTime(new Date(sharedTime));

            setFullYear(sharedFullYear === 1 ? true : false);
        }
    }, [sharedTime, sharedFullYear]);

    // Gets non-empty data(at least one key not 0)
    const getValidData = (unfilteredData) => {
        let validData = {};
        Object.entries(unfilteredData).map(([yearKey, data]) => {
            if (!Object.values(data).every((val) => val === 0)) {
                return (validData[yearKey] = data);
            }
        });
        // console.log(validData);
        return validData;
    };

    //               _ _
    //   _ __  _   _| | |
    //  | '_ \| | | | | |
    //  | |_) | |_| | | |
    //  | .__/ \__,_|_|_|
    //  |_|
    useEffect(() => {
        if (selectedDataSource.status === "error") setConnectionError(true);
        if (selectedDataSource.status === "loaded") {
            // Get array of all years to n years before
            let years = getYearsBefore();

            months.forEach((month, index) => {
                let auxMonthsData = Object.assign(
                    {},
                    ...years.map((year) => ({
                        [year]: process(
                            (selectedDataSource.data[`${year}`] ?? {})[month] ??
                                [],
                            cancellations
                        ),
                    }))
                );
                setMonthsData[index](getValidData(auxMonthsData));
            });

            let auxYearData = Object.assign(
                {},
                ...years.map((year) => ({
                    [year]: process(
                        Object.values(
                            selectedDataSource.data[`${year}`] ?? {}
                        ).flat(1),
                        cancellations
                    ),
                }))
            );
            // console.log(auxYearData);
            setDataByYear(getValidData(auxYearData));

            setLoaded(true);
        } else setLoaded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataSource, cancellations]);

    //      _ _           _
    //     | (_)         | |
    //   __| |_ ___ _ __ | | __ _ _   _
    //  / _` | / __| '_ \| |/ _` | | | |
    // | (_| | \__ \ |_) | | (_| | |_| |
    //  \__,_|_|___/ .__/|_|\__,_|\__, |
    //             | |             __/ |
    //             |_|            |___/
    useEffect(() => {
        if (
            Object.entries(dataByYear).length > 0 &&
            Object.entries(monthsData).every((arr) => arr.length > 0)
        ) {
            let auxDisplayData = fullYear
                ? dataByYear
                : monthsData[time.getUTCMonth()];

            setDisplayData(auxDisplayData);
        } else setDisplayData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, ...monthsData, dataByYear]);

    return (
        <>
            <Container maxWidth={false} component={HoverPaper}>
                <>
                    <br />
                    <Stack
                        direction={{ md: "column", sm: "column", xs: "row" }}
                        justifyContent={
                            isMobile ? "space-around" : "space-between"
                        }
                        alignItems="flex-start"
                        // style={{ border: "1px solid red" }}
                    >
                        {/* Title */}
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent={"center"}
                            // style={{ border: "1px solid orange" }}
                        >
                            <Stack
                                alignItems="center"
                                // style={{ border: "1px solid yellow" }}
                            >
                                <Typography fontWeight={"bold"}>
                                    {TITLE}
                                </Typography>
                                <Typography
                                    fontStyle={"italic"}
                                    variant="subtitle2"
                                >
                                    {`by ${
                                        sharedDataSource === "CheckOut"
                                            ? "Check-out"
                                            : sharedDataSource === "CheckIn"
                                            ? "Check-in"
                                            : "Reservation"
                                    } date`}
                                </Typography>
                            </Stack>
                            <InfoIcon text={REPORT_DESC} />
                            <ErrorIcon show={connectionError} />
                        </Stack>
                        {isMobile ? (
                            <OptionsButtonDialog>
                                <SwitchVisualTable
                                    collapse={true}
                                    modes={[TABLE_MODE, VISUAL_MODE]}
                                    mode={mode}
                                    setMode={setMode}
                                />
                                <DateSelector
                                    collapse={isMobile}
                                    fullYear={fullYear}
                                    setFullYear={setFullYear}
                                    time={time}
                                    setTime={setTime}
                                    day={false}
                                    buttons={[
                                        {
                                            label: "Years",
                                            value: currentYearButton(),
                                            fullYear: true,
                                        },
                                        {
                                            label: "Current Month",
                                            value: currentMonthButton(),
                                            fullYear: false,
                                        },
                                        {
                                            label: "Last Month",
                                            value: lastMonthButton(),
                                            fullYear: false,
                                        },
                                    ]}
                                />
                                {""}
                            </OptionsButtonDialog>
                        ) : (
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                // style={{ border: "1px solid green   " }}
                            >
                                <SwitchVisualTable
                                    modes={[TABLE_MODE, VISUAL_MODE]}
                                    mode={mode}
                                    setMode={setMode}
                                />
                                <DateSelector
                                    collapse={isMobile}
                                    fullYear={fullYear}
                                    setFullYear={setFullYear}
                                    time={time}
                                    setTime={setTime}
                                    day={false}
                                    buttons={[
                                        {
                                            label: "Years",
                                            value: currentYearButton(),
                                            fullYear: true,
                                        },
                                        {
                                            label: "Current Month",
                                            value: currentMonthButton(),
                                            fullYear: false,
                                        },
                                        {
                                            label: "Last Month",
                                            value: lastMonthButton(),
                                            fullYear: false,
                                        },
                                    ]}
                                />
                            </Stack>
                        )}
                    </Stack>

                    {!loaded ? (
                        <>
                            <br />
                            <ProgressBarSteps />
                        </>
                    ) : (
                        ""
                    )}

                    {mode === TABLE_MODE && (
                        <>
                            <TableContainer style={{ overflowX: "auto" }}>
                                <WrapperPDFTable k={TITLE}>
                                    <Table>
                                        {/*  _   _ _____    _    ____   */}
                                        {/* | | | | ____|  / \  |  _ \  */}
                                        {/* | |_| |  _|   / _ \ | | | | */}
                                        {/* |  _  | |___ / ___ \| |_| | */}
                                        {/* |_| |_|_____/_/   \_\____/  */}
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    <>
                                                        <TableCell
                                                            key={"field"}
                                                        />

                                                        {Object.keys(
                                                            displayData
                                                        ).map((year) => (
                                                            <TableHeaderCell
                                                                key={year}
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                {year}
                                                            </TableHeaderCell>
                                                        ))}
                                                    </>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        {/*  ____   _____        ______   */}
                                        {/* |  _ \ / _ \ \      / / ___|  */}
                                        {/* | |_) | | | \ \ /\ / /\___ \  */}
                                        {/* |  _ <| |_| |\ V  V /  ___) | */}
                                        {/* |_| \_\\___/  \_/\_/  |____/  */}
                                        <TableBody>
                                            {Object.entries(displayData)
                                                .length > 0 ? (
                                                <>
                                                    {HEADER.map((head) => (
                                                        <TableRow key={head.id}>
                                                            <TableHeaderCell
                                                                style={
                                                                    isMobile
                                                                        ? {
                                                                              position:
                                                                                  "sticky",
                                                                              left: 0,
                                                                              zIndex: 1,
                                                                              backgroundColor:
                                                                                  Colours.plainWhite,
                                                                          }
                                                                        : {}
                                                                }
                                                            >
                                                                {head.label}
                                                            </TableHeaderCell>
                                                            {Object.keys(
                                                                displayData
                                                            ).map((year) => (
                                                                <TableBodyCell
                                                                    key={year}
                                                                >
                                                                    {
                                                                        head.left_unit
                                                                    }
                                                                    {fns(
                                                                        hotelID,
                                                                        displayData[
                                                                            year
                                                                        ][
                                                                            head
                                                                                .id
                                                                        ],
                                                                        head.format ??
                                                                            {}
                                                                    )}
                                                                    {head.unit}
                                                                    {CalcPercentage(
                                                                        //TODO: replace with proper component
                                                                        year,
                                                                        displayData,
                                                                        head.id,
                                                                        fullYear
                                                                            ? `Comparing ${new Date().getUTCFullYear()} vs ${year}`
                                                                            : `Comparing ${new Date().getUTCFullYear()}-${
                                                                                  months[
                                                                                      time.getUTCMonth()
                                                                                  ]
                                                                              } vs ${year}-${
                                                                                  months[
                                                                                      time.getUTCMonth()
                                                                                  ]
                                                                              }`,
                                                                        (
                                                                            number
                                                                        ) =>
                                                                            fns(
                                                                                hotelID,
                                                                                number,
                                                                                head.format
                                                                            )
                                                                    )}
                                                                </TableBodyCell>
                                                            ))}
                                                        </TableRow>
                                                    ))}
                                                </>
                                            ) : selectedDataSource.status ===
                                              "loaded" ? (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={100}
                                                        align="center"
                                                    >
                                                        <Box
                                                            component="span"
                                                            fontStyle="italic"
                                                        >
                                                            No data
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                <>
                                                    {HEADER.map((e) => (
                                                        <TableRow key={e.id}>
                                                            <TableHeaderCell
                                                                sx={{
                                                                    maxWidth:
                                                                        "40px",
                                                                }}
                                                            >
                                                                {e.label}
                                                            </TableHeaderCell>
                                                            {getYearsBefore().map(
                                                                (f) => (
                                                                    <TableCell
                                                                        key={f}
                                                                    >
                                                                        <Skeleton />
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    ))}
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </WrapperPDFTable>
                            </TableContainer>
                            <br />
                        </>
                    )}
                    {mode === VISUAL_MODE && (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                <SwitchIcons
                                    selectedMode={graphMode}
                                    modes={[LINE_MODE, BAR_MODE]}
                                    setGraphMode={setGraphMode}
                                />
                            </Stack>

                            {Object.entries(displayData).length > 0 ? (
                                <WrapperPDFEChart k={TITLE + " graph"}>
                                    <EChartsReact
                                        option={{
                                            legend: {},
                                            tooltip: {
                                                trigger: "axis",
                                            },
                                            xAxis: [
                                                {
                                                    type: "category",
                                                    data: [
                                                        ...Object.keys(
                                                            displayData ?? {}
                                                        ),
                                                    ],
                                                },
                                            ],
                                            yAxis: [
                                                {
                                                    type: "value",
                                                },
                                                {
                                                    type: "value",
                                                },
                                            ],
                                            series: [
                                                {
                                                    key: "bookings",
                                                    name: "Bookings",
                                                    color: Colours.graphMainLine1,
                                                },
                                                {
                                                    key: "voucherRevenue",
                                                    name: "Voucher Revenue",
                                                    color: Colours.graphMainLine3,
                                                },
                                            ].map((item, index) => ({
                                                data: Object.keys(
                                                    displayData
                                                ).map(
                                                    (year) =>
                                                        displayData[year][
                                                            item.key
                                                        ]
                                                ),
                                                type: graphMode.type,
                                                name: item.name,
                                                yAxisIndex: index,
                                                color: item.color,
                                            })),
                                        }}
                                    />
                                </WrapperPDFEChart>
                            ) : (
                                <>
                                    <br />
                                    <Skeleton
                                        sx={{ p: 5 }}
                                        variant="rounded"
                                        width={"100%"}
                                        height={250}
                                    />
                                    <br />
                                </>
                            )}
                        </>
                    )}
                </>
            </Container>
        </>
    );
};

export default VouchersRevenueReport;
