import React, { useState, useEffect } from "react";

import {
    Box,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";

export const TYPE_ABCD = "ABCD";
export const validForm = () => {};
export const post = () => {};

export const processTypeABCD = (data) => {
    let status = "";
    let priority = "";
    let selected = { name: "", desc: "" };
    let selected_id = 0;
    let not_selected = [];

    let ts_selected = null;
    data.values
        .filter((e) => e.subtype === "SELECTED_VALUE_ID")
        .sort((a, b) => a.ts - b.ts)
        .forEach((element) => {
            if (!ts_selected || new Date(element.ts) > new Date(ts_selected)) {
                selected_id = element.value_;
                ts_selected = element.ts;
            }
        });

    selected = JSON.parse(
        data.values.filter(
            (e) =>
                e.subtype === "DICT" &&
                Number(e.value_id) === Number(selected_id)
        )[0].value_
    );

    not_selected = data.values
        .filter(
            (e) =>
                e.subtype === "DICT" &&
                Number(e.value_id) !== Number(selected_id)
        )
        .map((e) => JSON.parse(e.value_));

    let ts_sp = null;
    data.status.forEach((element) => {
        if (!ts_sp || new Date(element.ts) > new Date(ts_sp)) {
            status = element.status;
            priority = element.priority;
            ts_sp = element.ts;
        }
    });

    return {
        type: "ABCD",
        selected,
        not_selected,
        status,
        priority,
        colour: Colours.trafficLightGreen,
    };
};

const ABCDCard = ({
    selected,
    name,
    desc,
    index,
    setABCDSelected,
    setABCDValues,
    ABCDValues,
    edit = true,
}) => {
    const handleNameChange = (event) => {
        const updatedValues = [...ABCDValues];
        updatedValues[index].name = event.target.value;
        setABCDValues(updatedValues);
    };

    const handleDescChange = (event) => {
        const updatedValues = [...ABCDValues];
        updatedValues[index].desc = event.target.value;
        setABCDValues(updatedValues);
    };

    const handleDelete = () => {
        const updatedValues = ABCDValues.filter((_, i) => i !== index);
        setABCDValues(updatedValues);
    };

    return (
        <Grid
            onClick={() => {
                if (edit) setABCDSelected(index);
            }}
            container
            component={Paper}
            elevation={3}
            sx={{
                p: 2,
                mb: 2,
                borderRadius: "8px",
                bgcolor: selected
                    ? Colours.selectedRow
                    : Colours.notificationCard,
            }}
            spacing={1}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={10}>
                <Stack spacing={1}>
                    <TextField
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        variant="standard"
                        size="small"
                    />
                    <TextField
                        fullWidth
                        value={desc}
                        onChange={handleDescChange}
                        variant="standard"
                        size="small"
                        multiline
                        maxRows={4}
                    />
                </Stack>
            </Grid>
            {edit && (
                <Grid item xs={2}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={handleDelete}>
                            <Icons.Delete />
                        </IconButton>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

// const ABCDValueFields = ({
//     type,
//     ABCDValues,
//     setABCDValues,
//     ABCDSelected,
//     setABCDSelected,
// }) => {
//     if (type !== TYPE_ABCD) return null;
//     return (
//         <>
//             <Box
//                 sx={{
//                     p: 2,
//                     overflowY: "auto",
//                     maxHeight: "50vh",
//                 }}
//             >
//                 {ABCDValues.map((e, i) => (
//                     <ABCDCard
//                         name={e.name}
//                         desc={e.desc}
//                         key={i}
//                         selected={i === ABCDSelected}
//                         index={i}
//                         setABCDSelected={setABCDSelected}
//                         setABCDValues={setABCDValues}
//                         ABCDValues={ABCDValues}
//                     />
//                 ))}
//             </Box>{" "}
//             <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//                 <IconButton
//                     onClick={() =>
//                         setABCDValues([
//                             ...ABCDValues,
//                             { name: "name", desc: "description" },
//                         ])
//                     }
//                 >
//                     <Icons.Add />
//                 </IconButton>
//             </Box>
//         </>
//     );
// };

const IndicatorsTypeABCD = ({
    edit = false,
    data,
    type,
    postSetter = () => {},
    isValidSetter = () => {},
}) => {
    const [ABCDSelected, setABCDSelected] = useState(0);
    const [ABCDValues, setABCDValues] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const allValues = [data.selected, ...(data.not_selected ?? [])];
            setABCDValues(allValues);
        }
    }, [data, type]);

    const isValid = () => {
        // TODO: Fill in

        return "ABCD IS VALID";
    };

    const post = (insert = () => {}) => {
        Promise.all(
            ABCDValues.map((v) =>
                insert("ABCD", "dict", JSON.stringify(v), true)
            )
        ).then((ids) => {
            // From the values ids, get the selected one and mark it as selected
            insert("ABCD", "selected_value_id", ids[ABCDSelected]);
        });
    };

    useEffect(() => {
        postSetter(post);
        isValidSetter(isValid);
    }, []);

    return edit ? (
        <>
            <Box
                sx={{
                    p: 2,
                    overflowY: "auto",
                    maxHeight: "50vh",
                }}
            >
                {ABCDValues.map((e, i) => (
                    <ABCDCard
                        name={e.name}
                        desc={e.desc}
                        key={i}
                        selected={i === ABCDSelected}
                        index={i}
                        setABCDSelected={setABCDSelected}
                        setABCDValues={setABCDValues}
                        ABCDValues={ABCDValues}
                    />
                ))}
            </Box>{" "}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                    onClick={() =>
                        setABCDValues([
                            ...ABCDValues,
                            { name: "name", desc: "description" },
                        ])
                    }
                >
                    <Icons.Add />
                </IconButton>
            </Box>
        </>
    ) : (
        <Stack direction={"column"} spacing={2}>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography fontWeight={"bold"}>Indicator Type</Typography>{" "}
                <Typography>🔠 ABCD</Typography>
            </Stack>
            <Box
                sx={{
                    p: 2,
                    overflowY: "auto",
                    maxHeight: "50vh",
                }}
            >
                {ABCDValues.map((e, i) => (
                    <ABCDCard
                        name={e.name}
                        desc={e.desc}
                        key={i}
                        selected={i === ABCDSelected}
                        index={i}
                        setABCDSelected={setABCDSelected}
                        setABCDValues={setABCDValues}
                        ABCDValues={ABCDValues}
                        edit={false}
                    />
                ))}
            </Box>{" "}
        </Stack>
    );
};

export default IndicatorsTypeABCD;
