import React from "react";
import {
    Box,
    Container,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";

import Colours from "../helpers/colours";

const Footer = () => {
    return (
        <Container>
            <br />
            <Divider sx={{ borderColor: Colours.navbarBottomLine }} />
            <Container>
                <br />
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={1}
                >
                    <Box
                        component="img"
                        sx={{
                            mlinkxHeight: { xs: 150, md: 150 },
                            maxWidth: { xs: 100, md: 150 },
                            cursor: "pointer",
                        }}
                        alt="Hotel Logo"
                        src={"/imgs/aro-white.svg"}
                        onClick={() => {
                            window.open(
                                "https://www.arodigitalstrategy.com/",
                                "_blank",
                                "noopener,noreferrer"
                            );
                        }}
                    ></Box>

                    <br />
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={2}>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography>5 STAR BY EVERY MEASURE</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Typography fontSize={"0.75em"}>
                        ©2024 Aró Digital Strategy. All rights reserved
                    </Typography>
                </Stack>
                <br />
            </Container>
        </Container>
    );
};

export default Footer;
