import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";
import {
    Container,
    Box,
    TableCell,
    TableRow,
    Stack,
    Checkbox,
} from "@mui/material";
import { Divider } from "@material-ui/core";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

import CircleValue from "../components/CircleValue";
import { URLAPI } from "../configuration";

const PriorityIcon = (n) => {
    switch (n) {
        case 1:
            return "High";
        // return <Icons.Priority1 color={"error"} />;
        case 2:
            return "High";
        // return <Icons.Priority2 color="warning" />;
        case 3:
            return "High";
        // return <Icons.Priority3 color="info" />;
        case 4:
            return "Low";
        // return <Icons.Priority4 color="secondary" />;
        case 5:
            return "Low";
        // return <Icons.Priority5 color="primary" />;
        case 6:
            return "Low";
        // return <Icons.Priority6 color="primary" />;
        default:
            return <Icons.PriorityMissed />;
    }
};

const statusColor = (st) => {
    switch (st) {
        case "Complete":
            return Colours.statusBackgroundCompleted;
        case "Pending review":
        case "Under review":
        case "Partial complete":
        case "In progress":
        default:
            return Colours.statusBackgroundPending;
    }
};

const InsightAssistRowDesktop = ({
    indicatorName,
    indicatorNameFormat,
    growthTrackName,
    weighting,
    currentScore,
    previousScore,
    priority,
    whatV = "This indicartor does this... Would be very nice if you could do something about it...",
    whyV = "Our AI and data shows you could increase your revenue by hefty 15%",
    howV = "Increase DM Budget & Talk to our Team",
    status,
    selectedList = [],
    setSelectedList = (v) => {
        selectedList = v;
    },
    showRedAlertCheckbox = false,
    hideWeights,
}) => {
    const auth = useSelector((state) => state.auth);
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);
    const [displayName, setDisplayName] = useState("");

    const [selected, setSelected] = useState(false);
    const insertPop = (array, value, set) => {
        array.includes(value)
            ? set([...array].filter((e) => e !== value))
            : set([...array, value]);
    };

    useEffect(() => {
        if (auth.response) {
            setDisplayName(auth.response.account.username);
        }
    }, [auth.response]);
    return (
        <>
            {/*  ____  _____ ____  _  _______ ___  ____   */}
            {/* |  _ \| ____/ ___|| |/ /_   _/ _ \|  _ \  */}
            {/* | | | |  _| \___ \| ' /  | || | | | |_) | */}
            {/* | |_| | |___ ___) | . \  | || |_| |  __/  */}
            {/* |____/|_____|____/|_|\_\ |_| \___/|_|   */}
            <TableRow
                onClick={() => setSelected(!selected)}
                sx={{
                    "& .MuiTableCell-root": {
                        borderBottom: "unset",
                        borderTop: 2,
                        borderTopColor: Colours.lightText,
                    },
                    backgroundColor: statusColor(status),
                }}
            >
                {showRedAlertCheckbox && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            // indeterminate={
                            //     numSelected > 0 && numSelected < rowCount
                            // }
                            checked={selectedList.includes(indicatorName)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                insertPop(
                                    selectedList,
                                    indicatorName,
                                    setSelectedList
                                );
                                let args = JSON.stringify({
                                    indicator_name: indicatorName,
                                    user_id: displayName,
                                    value: currentScore,
                                });
                                Axios({
                                    method: "post",
                                    url: `${URLAPI}/redalerts/${hotelID}`,
                                    data: args,
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${auth.user.jwt}`,
                                    },
                                });
                            }}
                        />
                    </TableCell>
                )}
                {/*  _  _                 */}
                {/* | \| |__ _ _ __  ___  */}
                {/* | .` / _` | '  \/ -_) */}
                {/* |_|\_\__,_|_|_|_\___| */}
                <TableCell
                    align="center"
                    sx={{
                        maxWidth: 250,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    }}
                >
                    <Box component="div">
                        {indicatorNameFormat ?? indicatorName}
                    </Box>
                </TableCell>
                {/*  ___     _         _ _         */}
                {/* | _ \_ _(_)___ _ _(_) |_ _  _  */}
                {/* |  _/ '_| / _ \ '_| |  _| || | */}
                {/* |_| |_| |_\___/_| |_|\__|\_, | */}
                {/*                          |__/  */}
                <TableCell align="center">
                    <Box>{PriorityIcon(priority)}</Box>
                </TableCell>
                {/*   ___                _   _      _____            _    */}
                {/*  / __|_ _ _____ __ _| |_| |_   |_   _| _ __ _ __| |__ */}
                {/* | (_ | '_/ _ \ V  V /  _| ' \    | || '_/ _` / _| / / */}
                {/*  \___|_| \___/\_/\_/ \__|_||_|   |_||_| \__,_\__|_\_\ */}
                <TableCell align="center">
                    <Container>
                        <CircleValue
                            value={(growthTrackName ?? "?").slice(0, 1)}
                            color={
                                growthTrackName === "Attract"
                                    ? Colours.attract
                                    : growthTrackName === "Acquire"
                                    ? Colours.attract
                                    : growthTrackName === "Engage"
                                    ? Colours.engage
                                    : growthTrackName === "Convert"
                                    ? Colours.convert
                                    : growthTrackName === "Retain"
                                    ? Colours.retain
                                    : null
                            }
                            tooltip={growthTrackName ?? "?"}
                        />
                    </Container>
                </TableCell>
                {!hideWeights && (
                    <>
                        {/* __      __   _      _   _   _            */}
                        {/* \ \    / /__(_)__ _| |_| |_(_)_ _  __ _  */}
                        {/*  \ \/\/ / -_) / _` | ' \  _| | ' \/ _` | */}
                        {/*   \_/\_/\___|_\__, |_||_\__|_|_||_\__, | */}
                        {/*               |___/               |___/  */}
                        <TableCell align="center">
                            <Container>
                                <CircleValue
                                    value={weighting ?? ""}
                                    color={"transparent"}
                                    textColor={Colours.transparentBubble}
                                    borderColor={Colours.transparentBubble}
                                />
                            </Container>
                        </TableCell>
                        {/*  ___                     _     ___                  */}
                        {/* / __|  _ _ _ _ _ ___ _ _| |_  / __| __ ___ _ _ ___  */}
                        {/*| (_| || | '_| '_/ -_) ' \  _| \__ \/ _/ _ \ '_/ -_) */}
                        {/* \___\_,_|_| |_| \___|_||_\__| |___/\__\___/_| \___| */}
                        <TableCell align="center">
                            <Container>
                                <CircleValue
                                    value={currentScore ?? ""}
                                    tooltip={`${currentScore} - Score from 11/2022`}
                                    color={
                                        currentScore === "*"
                                            ? Colours.transparentBubble
                                            : currentScore >= previousScore
                                            ? Colours.trafficLightGreen
                                            : Colours.trafficLightRed
                                    }
                                />
                            </Container>
                        </TableCell>
                        {/*  ___             _               ___                  */}
                        {/* | _ \_ _ _____ _(_)___ _  _ ___ / __| __ ___ _ _ ___  */}
                        {/* |  _/ '_/ -_) V / / _ \ || (_-< \__ \/ _/ _ \ '_/ -_) */}
                        {/* |_| |_| \___|\_/|_\___/\_,_/__/ |___/\__\___/_| \___| */}
                        <TableCell align="center">
                            <Container>
                                <CircleValue
                                    value={previousScore}
                                    color={"transparent"}
                                    textColor={Colours.transparentBubble}
                                    borderColor={Colours.transparentBubble}
                                />
                            </Container>
                        </TableCell>
                    </>
                )}
                {/*  ___ _        _            */}
                {/* / __| |_ __ _| |_ _  _ ___ */}
                {/* \__ \  _/ _` |  _| || (_-< */}
                {/* |___/\__\__,_|\__|\_,_/__/ */}
                <TableCell align="center">
                    <Container>
                        {/* <Tooltip title={status ?? "Status missed"}> */}
                        <Box>
                            {/* {StatusIcon(status)} */}
                            {status ?? "??"}
                        </Box>
                        {/* </Tooltip> */}
                    </Container>
                </TableCell>
            </TableRow>
            {selected && (
                <TableRow
                    sx={{
                        backgroundColor: statusColor(status),
                    }}
                >
                    <TableCell colSpan={10}>
                        <Stack
                            direction="row"
                            justifyContent="space-around"
                            alignItems="baseline"
                            spacing={2}
                        >
                            <Box maxWidth={"35%"}>
                                <Box>
                                    {/* __      ___  _   _ _____  */}
                                    {/* \ \    / / || | /_\_   _| */}
                                    {/*  \ \/\/ /| __ |/ _ \| |   */}
                                    {/*   \_/\_/ |_||_/_/ \_\_|   */}
                                    <Box component="span" fontWeight={"bold"}>
                                        WHAT
                                    </Box>
                                </Box>
                                <br />
                                <Box>{whatV}</Box>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box maxWidth={"35%"}>
                                <Box>
                                    {/* __      ___  ___   __ */}
                                    {/* \ \    / / || \ \ / / */}
                                    {/*  \ \/\/ /| __ |\ V /  */}
                                    {/*   \_/\_/ |_||_| |_|   */}
                                    <Box component="span" fontWeight={"bold"}>
                                        WHY
                                    </Box>
                                </Box>
                                <br />
                                <Box>{whyV}</Box>
                            </Box>
                            <Divider orientation="vertical" flexItem />

                            <Box maxWidth={"35%"}>
                                <Box>
                                    {/*  _  _  _____      __ */}
                                    {/* | || |/ _ \ \    / / */}
                                    {/* | __ | (_) \ \/\/ /  */}
                                    {/* |_||_|\___/ \_/\_/   */}
                                    <Box component="span" fontWeight={"bold"}>
                                        HOW
                                    </Box>
                                </Box>
                                <br />
                                <Box>{howV}</Box>
                            </Box>
                        </Stack>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default InsightAssistRowDesktop;
