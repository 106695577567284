import React, { useEffect, useState } from "react";

import EChartsReact from "echarts-for-react";

import { Container, Grid, Paper, Skeleton, Stack } from "@mui/material";

import HoverPaper from "./shared/HoverPaper";

import SwitchIcons, { BAR_MODE, LINE_MODE } from "./reports/SwitchIcons";

import Colours from "../helpers/colours";

// Mandatory structure of data:
// data = {
//   "name_of_category_1": {"name_of_xAxis_label_1": "value", ..., "name_of_xAxis_label_X": value },
//   "name_of_category_2": {"name_of_xAxis_label_1": "value", ..., "name_of_xAxis_label_X": value }
// }
const TrendChart = ({
    data,
    xAxis = {},
    yAxis = {},
    markPoints = {},
    markLine = {},
    selected = {},
    modes = [BAR_MODE, LINE_MODE],
    container = "Paper",
    TITLE = "",
}) => {
    const [graphMode, setGraphMode] = useState(modes[0]);
    const [series, setSeries] = useState([]);

    useEffect(() => {
        if (
            Object.keys(data).length > 0 &&
            Object.keys(data).every(
                (category) =>
                    Object.keys(data[category]).length ==
                    Object.keys(data[Object.keys(data)[0]]).length
            )
        ) {
            // console.log(data);

            let dataMarkPoints = [];
            if (
                markPoints.hasOwnProperty("max") &&
                markPoints.max.hasOwnProperty("show") &&
                markPoints.max.show
            ) {
                let maxMarkPoint = {
                    type: "max",
                    name: "Max",

                    symbol: "circle",
                    symbolSize: 0,
                    emphasis: {
                        label: {
                            formatter: (e) => {
                                let value =
                                    (markPoints.max ?? {}).hasOwnProperty(
                                        "formatter"
                                    ) &&
                                    typeof markPoints.formatter === "function"
                                        ? markPoints.formatter(e.value)
                                        : e.value;
                                return `${e.name}\n${value}`;
                            },
                            fontWeight: "bold",
                            fontSize: 15,
                            backgroundColor: "white",
                            padding: 10,
                        },
                    },
                    label: {
                        fontWeight: "bold",
                        fontSize: 15,
                        formatter: (e) => {
                            return (markPoints.max ?? {}).hasOwnProperty(
                                "formatter"
                            ) && typeof markPoints.max.formatter === "function"
                                ? markPoints.max.formatter(e.value)
                                : e.value;
                        },
                    },
                };
                dataMarkPoints.push(maxMarkPoint);
            }

            if (
                markPoints.hasOwnProperty("min") &&
                markPoints.min.hasOwnProperty("show") &&
                markPoints.min.show
            ) {
                let minMarkPoint = {
                    type: "min",
                    name: "Min",
                    emphasis: {
                        label: {
                            formatter: (e) => {
                                let value =
                                    (markPoints.min ?? {}).hasOwnProperty(
                                        "formatter"
                                    ) &&
                                    typeof markPoints.min.formatter ===
                                        "function"
                                        ? markPoints.min.formatter(e.value)
                                        : e.value;
                                return `${e.name}\n${value}`;
                            },
                            fontWeight: "bold",
                            fontSize: 15,
                            backgroundColor: "white",
                            padding: 10,
                        },
                    },
                    label: {
                        fontWeight: "bold",
                        fontSize: 15,
                        formatter: (e) => {
                            return (markPoints.min ?? {}).hasOwnProperty(
                                "formatter"
                            ) && typeof markPoints.min.formatter === "function"
                                ? markPoints.min.formatter(e.value)
                                : e.value;
                        },
                    },

                    symbol: "circle",
                    symbolSize: 0,
                };
                dataMarkPoints.push(minMarkPoint);
            }

            let auxSeries = [
                ...[...Object.keys(data)].map((category, index) => {
                    return {
                        name: category,
                        type: graphMode.type,
                        data: Object.values(data[category]),
                        smooth: true,
                        itemStyle: {
                            color: [
                                Colours.attract,
                                Colours.info,
                                Colours.engage,
                                Colours.convert,
                                Colours.retain,
                                Colours.graphExtraLine1,
                            ][index % Object.keys(data).length],
                        },
                        markPoint: {
                            data: dataMarkPoints,
                        },
                        markLine: {
                            data:
                                markLine.hasOwnProperty("show") && markLine.show
                                    ? [
                                          {
                                              type: "average",
                                              name: "Avg",
                                              symbol: "none",
                                              emphasis: {
                                                  label: {
                                                      formatter: (e) => {
                                                          let value =
                                                              (
                                                                  markLine ?? {}
                                                              ).hasOwnProperty(
                                                                  "formatter"
                                                              ) &&
                                                              typeof markLine.formatter ===
                                                                  "function"
                                                                  ? markLine.formatter(
                                                                        e.value
                                                                    )
                                                                  : e.value;
                                                          return `${e.name}\n${value}`;
                                                      },
                                                      fontWeight: "bold",
                                                      fontSize: 15,
                                                  },
                                              },
                                              label: {
                                                  formatter: (e) => {
                                                      return (
                                                          markLine ?? {}
                                                      ).hasOwnProperty(
                                                          "formatter"
                                                      ) &&
                                                          typeof markLine.formatter ===
                                                              "function"
                                                          ? markLine.formatter(
                                                                e.value
                                                            )
                                                          : e.value;
                                                  },
                                                  fontWeight: "bold",
                                                  fontSize: 15,
                                              },
                                          },
                                      ]
                                    : [],
                        },
                    };
                }),
            ];
            setSeries(auxSeries);
        }
    }, [data, graphMode]);

    return (
        <>
            {Object.keys(data).length > 0 ? (
                <Grid item md={12} sx={{ width: "100%" }}>
                    <Container
                        component={container === "Paper" ? HoverPaper : ""}
                    >
                        <br />
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <SwitchIcons
                                selectedMode={graphMode}
                                modes={modes}
                                setGraphMode={setGraphMode}
                            />
                        </Stack>
                        <EChartsReact
                            option={{
                                tooltip: { trigger: "axis" },
                                legend: {
                                    selected: selected,
                                },

                                xAxis: {
                                    type: "category",
                                    show: xAxis.show ?? true,
                                    axisLine: {
                                        show: false,
                                    },
                                    axisTick: { show: false },
                                    axisLabel: {
                                        interval: 0,
                                        formatter:
                                            xAxis.formatter ??
                                            ((e) => {
                                                return e.slice(0, 3);
                                            }),
                                    },
                                    data: Object.keys(
                                        data[Object.keys(data)[0]]
                                    ),
                                },
                                yAxis: {
                                    position: "left",
                                    show: yAxis.show ?? false,
                                },
                                dataZoom: [{ show: false }],
                                series: series,
                            }}
                        />
                        <br />
                    </Container>
                </Grid>
            ) : (
                <>
                    <Skeleton variant="rounded" height={300} />
                    <br />
                </>
            )}
        </>
    );
};

export default TrendChart;
