import React, { useEffect } from "react";
import { useState } from "react";

import {
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Popover,
    Stack,
    Typography,
} from "@mui/material";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

function DropdownButton({
    iconPos = "left",
    iconSize = "medium",
    Icon = Icons.Filter,
    header = {
        component: false, // component
        closeButton: true,
    },
    title = "",
    sx = {},
    children,
}) {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [filteredChildren, setFilteredChildren] = useState([]);

    useEffect(() => {
        setFilteredChildren(React.Children.toArray(children).filter(Boolean));
    }, [children]);

    return (
        <>
            <Button
                variant="outlined"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                }}
                sx={{
                    ...sx,
                    textTransform: "none",
                    borderColor: Colours.midLightText,
                    color: Colours.textColour,
                }}
            >
                <Stack direction={"row"} spacing={1}>
                    {iconPos === "left" && <Icon fontSize={iconSize} />}

                    {title.length > 0 && <Typography>{title}</Typography>}
                    {iconPos === "right" && <Icon fontSize={iconSize} />}
                </Stack>
            </Button>

            <Popover
                sx={{ mt: 1 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                anchorEl={anchorEl}
                PaperProps={{ elevation: 3 }}
            >
                <Paper sx={{ p: 1 }}>
                    <Grid container alignItems={"center"}>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={10}>
                            {(header ?? {}).component ?? <></>}
                        </Grid>
                        <Grid item xs={1}>
                            {(header ?? {}).closeButton && (
                                <IconButton
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <Icons.CloseNavbar />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                    {/*  */}

                    <Stack
                        sx={{ p: 2 }}
                        direction={"column"}
                        divider={
                            <Divider
                                orientation="horizontal"
                                flexItem
                                sx={{
                                    m: 1.5,
                                    marginX: -2,
                                    borderColor: Colours.navbarBottomLine,
                                }}
                            />
                        }
                    >
                        {React.Children.map(filteredChildren, (child) => {
                            return <>{child}</>;
                        })}
                    </Stack>
                </Paper>
            </Popover>
        </>
    );
}

export default DropdownButton;
