import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Checkbox,
    Container,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    FormControl,
    OutlinedInput,
    Grid,
} from "@mui/material";

import ErrorIcon from "../components/ErrorIcon";
import OptionsButtonDialog from "../components/OptionsButtonDialog";
import DateSelector, {
    lastMonthButton,
    currentMonthButton,
} from "../components/DateSelector";
import SwitchVisualTable, {
    TABLE_MODE,
    VISUAL_MODE,
} from "../components/SwitchVisualTable";
import MembershipCardsReport from "../components/reports/MembershipCardsReport";
import MembershipTableReport from "../components/reports/MembershipTableReport";

import Icons from "../helpers/icons";
import { addYear as addYearAction } from "../actions/dataMembership";
import DataLoader from "../components/DataLoader";

const MembershipReportScreen = ({}) => {
    const TITLE = "Membership Summary";
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const dispatch = useDispatch();

    const [mode, setMode] = useState(TABLE_MODE);

    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { dataMembership } = useSelector((state) => state);

    const [connectionError, setConnectionError] = useState(false);
    const [time, setTime] = useState(new Date());

    // Returns data processed by dates and summarized data by key
    const processByDateKey = (data, dateKey, summarizeBy) => {
        let processedData = {};
        let summarized = {};

        if (data.length > 0) {
            data.forEach((element) => {
                const year = new Date(element[dateKey]).getUTCFullYear();
                const month = new Date(element[dateKey]).getUTCMonth();

                if (!processedData.hasOwnProperty(year)) {
                    processedData[year] = {};
                    summarized[year] = {};
                }
                if (!processedData[year].hasOwnProperty(month)) {
                    processedData[year][month] = [];
                    summarized[year][month] = {};
                    summarizeBy.forEach((summarizeKey) => {
                        if (summarizeKey === "ALoS") {
                            summarized[year][month][summarizeKey] = 0;
                        } else {
                            summarized[year][month][summarizeKey] = 0;
                        }
                    });
                }

                processedData[year][month].push(element);
                summarizeBy.forEach((summarizeKey) => {
                    if (summarizeKey === "ALoS") {
                        summarized[year][month][summarizeKey] += 1;
                    } else {
                        summarized[year][month][summarizeKey] +=
                            element[summarizeKey];
                    }
                });
            });

            if (dateKey === "ResDate") {
                for (let year in summarized) {
                    for (let month in summarized[year]) {
                        summarized[year][month]["ALoS"] =
                            summarized[year][month]["ALoS"] > 0
                                ? summarized[year][month]["RoomNights"] /
                                  summarized[year][month]["ALoS"]
                                : 0;
                    }
                }
            }
        }

        return { data: processedData, summary: summarized };
    };

    const [dataTotals, setDataTotals] = useState({});

    const [dataSignups, setDataSignups] = useState({});
    const processSignups = (data) => {
        const processedData = processByDateKey(data, "DateOfSignup", ["Total"]);

        setDataSignups(processedData);
    };

    const [dataRepeatBookers, setDataRepeatBookers] = useState({});

    const [dataBookings, setDataBookings] = useState({});
    const processBookings = (data) => {
        const processedData = {};
        for (let hotel in data) {
            processedData[hotel] = processByDateKey(
                data[hotel],
                "DateOfBooking",
                ["MemberBookings"]
            );
        }
        processedData["92"] = processByDateKey(
            Object.values(data).flat(),
            "DateOfBooking",
            ["MemberBookings"]
        );
        setDataBookings(processedData);
    };

    const [dataDetails, setDataDetails] = useState({});
    const processDetails = (data) => {
        const processedData = {};
        for (let hotel in data) {
            processedData[hotel] = processByDateKey(data[hotel], "ResDate", [
                "RoomNights",
                "ALoS",
            ]);
        }
        processedData["92"] = processByDateKey(
            Object.values(data).flat(),
            "ResDate",
            ["RoomNights", "ALoS"]
        );
        setDataDetails(processedData);
    };

    useEffect(() => {
        if (dataMembership.status === "error") {
            setConnectionError(true);
            return;
        } else if (
            dataMembership.status === "loaded" &&
            dataMembership.hasOwnProperty("data")
        ) {
            setConnectionError(false);
            // Check and process each property in dataMembership.data
            if (dataMembership.data.hasOwnProperty("Total")) {
                setDataTotals(dataMembership.data.Total);
            }

            if (dataMembership.data.hasOwnProperty("Signups")) {
                processSignups(dataMembership.data.Signups);
            }

            if (dataMembership.data.hasOwnProperty("RepeatBookers")) {
                setDataRepeatBookers(dataMembership.data.RepeatBookers);
            }

            if (dataMembership.data.hasOwnProperty("Bookings")) {
                processBookings(dataMembership.data.Bookings);
            }

            if (dataMembership.data.hasOwnProperty("Details")) {
                processDetails(dataMembership.data.Details);
            }
        }
    }, [dataMembership]);

    useEffect(() => {
        if (
            time &&
            !dataMembership.loadedYears.find(
                (year) => time.getUTCFullYear() === year
            ) &&
            dataMembership.status === "loaded"
        ) {
            dispatch(addYearAction(hotelID, time.getUTCFullYear()));
        }
    }, [time]);

    const HOTELS = {
        92: { clicked: true, name: "Imperial London Hotels Group" },
        93: { clicked: true, name: "Travistock Hotel" },
        94: { clicked: true, name: "President Hotel" },
        95: { clicked: true, name: "The Morton Hotel" },
        96: { clicked: true, name: "Bedford Hotel" },
        97: { clicked: true, name: "Royal National Hotel" },
        107: { clicked: true, name: "City Sleeper Hotel" },
    };

    const [selectedHotels, setSelectedHotels] = useState([]);
    const [clickedHotels, setClickedHotels] = useState({});

    const handleExpand = (hotelClicked) => {
        let auxClickedHotels = Object.assign({}, clickedHotels);

        auxClickedHotels[hotelClicked].clicked =
            !auxClickedHotels[hotelClicked].clicked;

        setClickedHotels(auxClickedHotels);
    };

    useEffect(() => {
        const auxSelectedHotels =
            hotelID === "92" ? Object.keys(HOTELS) : ["92", `${hotelID}`];
        setSelectedHotels(auxSelectedHotels);
        setClickedHotels(HOTELS);
    }, [hotelID]);

    return (
        <>
            <Stack direction={"column"} spacing={0.1}>
                <Grid container>
                    <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                        <Typography variant="h4">Membership</Typography>
                    </Grid>
                </Grid>{" "}
                <Container component={Paper}>
                    <br />
                    <Stack
                        direction={{ md: "row", sm: "column", xs: "row" }}
                        justifyContent={
                            isMobile ? "space-around" : "space-between"
                        }
                        alignItems="center"
                    >
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent={"center"}
                        >
                            <Typography fontWeight={"bold"} variant="h5">
                                {TITLE}
                            </Typography>

                            <ErrorIcon show={connectionError} />
                        </Stack>
                        {isMobile ? (
                            <OptionsButtonDialog>
                                {mode === VISUAL_MODE && (
                                    <FormControl>
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            Hotel
                                        </InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={selectedHotels}
                                            onChange={(event) => {
                                                const {
                                                    target: { value },
                                                } = event;
                                                let auxClicked = Object.assign(
                                                    {},
                                                    clickedHotels
                                                );
                                                selectedHotels
                                                    .filter(
                                                        (hotel_id) =>
                                                            !value.includes(
                                                                hotel_id
                                                            )
                                                    )
                                                    .forEach(
                                                        (hotel_id) =>
                                                            (auxClicked[
                                                                hotel_id
                                                            ].clicked = true)
                                                    );
                                                setClickedHotels(auxClicked);
                                                setSelectedHotels(value);
                                            }}
                                            input={
                                                <OutlinedInput label="Hotel" />
                                            }
                                            renderValue={(selected) =>
                                                selected
                                                    .map((hotel_id) => {
                                                        return HOTELS[hotel_id]
                                                            .name;
                                                    })
                                                    .join(", ")
                                            }
                                        >
                                            {Object.keys(HOTELS).map(
                                                (hotel) => (
                                                    <MenuItem
                                                        key={HOTELS[hotel].name}
                                                        value={hotel}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedHotels.findIndex(
                                                                    (
                                                                        selectedHotel
                                                                    ) =>
                                                                        selectedHotel ===
                                                                        hotel
                                                                ) >= 0
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                HOTELS[hotel]
                                                                    .name
                                                            }
                                                        />
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                                <SwitchVisualTable
                                    collapse={true}
                                    modes={[TABLE_MODE, VISUAL_MODE]}
                                    mode={mode}
                                    setMode={setMode}
                                />

                                <DateSelector
                                    deactivated={
                                        dataMembership.status !== "loaded" ??
                                        false
                                    }
                                    collapse={isMobile}
                                    time={time}
                                    setTime={setTime}
                                    day={false}
                                    buttons={[
                                        {
                                            label: "Current Month",
                                            value: currentMonthButton(),
                                            fullYear: false,
                                        },
                                        {
                                            label: "Last Month",
                                            value: lastMonthButton(),
                                            fullYear: false,
                                        },
                                    ]}
                                />
                            </OptionsButtonDialog>
                        ) : (
                            <Stack
                                direction={{ md: "row", sm: "column" }}
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={3}
                            >
                                {mode === VISUAL_MODE && (
                                    <FormControl sx={{ width: 300 }}>
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            Hotel
                                        </InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={selectedHotels}
                                            onChange={(event) => {
                                                const {
                                                    target: { value },
                                                } = event;
                                                let auxClicked = Object.assign(
                                                    {},
                                                    clickedHotels
                                                );
                                                selectedHotels
                                                    .filter(
                                                        (hotel_id) =>
                                                            !value.includes(
                                                                hotel_id
                                                            )
                                                    )
                                                    .forEach(
                                                        (hotel_id) =>
                                                            (auxClicked[
                                                                hotel_id
                                                            ].clicked = true)
                                                    );
                                                setClickedHotels(auxClicked);
                                                const sorted = value.sort(
                                                    (a, b) =>
                                                        parseInt(a) -
                                                        parseInt(b)
                                                );
                                                setSelectedHotels(sorted);
                                            }}
                                            input={
                                                <OutlinedInput label="Hotel" />
                                            }
                                            renderValue={(selected) =>
                                                selected
                                                    .map(
                                                        (hotel_id) =>
                                                            HOTELS[hotel_id]
                                                                .name
                                                    )
                                                    .join(", ")
                                            }
                                        >
                                            {Object.keys(HOTELS).map(
                                                (hotel) => (
                                                    <MenuItem
                                                        key={HOTELS[hotel].name}
                                                        value={hotel}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedHotels.findIndex(
                                                                    (
                                                                        selectedHotel
                                                                    ) =>
                                                                        selectedHotel ===
                                                                        hotel
                                                                ) >= 0
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                HOTELS[hotel]
                                                                    .name
                                                            }
                                                        />
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                                <SwitchVisualTable
                                    collapse={false}
                                    modes={[TABLE_MODE, VISUAL_MODE]}
                                    mode={mode}
                                    setMode={setMode}
                                />
                                <div sx={{ pointerEvents: "none" }}>
                                    <DateSelector
                                        deactivated={
                                            dataMembership.status !==
                                                "loaded" ?? false
                                        }
                                        time={time}
                                        setTime={setTime}
                                        day={false}
                                        buttons={[
                                            {
                                                label: "Current Month",
                                                value: currentMonthButton(),
                                                fullYear: false,
                                            },
                                            {
                                                label: "Last Month",
                                                value: lastMonthButton(),
                                                fullYear: false,
                                            },
                                        ]}
                                    />
                                </div>
                            </Stack>
                        )}
                    </Stack>
                    <br />
                    {mode === TABLE_MODE ? (
                        <MembershipTableReport
                            hotelID={hotelID}
                            time={time}
                            dataTotalsRaw={dataTotals}
                            dataSignupsRaw={dataSignups}
                            dataRepeatBookersRaw={dataRepeatBookers}
                            dataBookingsRaw={dataBookings}
                            dataDetailsRaw={dataDetails}
                            status={dataMembership.status ?? "error"}
                        />
                    ) : (
                        <>
                            {/* <Typography fontWeight={"bold"}>ILH Group</Typography> */}

                            <br />
                            {selectedHotels.length > 0 ? (
                                <>
                                    {selectedHotels.map((hotel_id, index) => (
                                        <Stack direction={"column"} key={index}>
                                            <Stack
                                                direction={"row"}
                                                justifyContent={"space-between"}
                                                onClick={() =>
                                                    handleExpand(hotel_id)
                                                }
                                                sx={{
                                                    marginY: 1,
                                                    cursor: "pointer",
                                                    boxShadow: 0.5,
                                                }}
                                            >
                                                <Typography fontWeight={"bold"}>
                                                    {HOTELS[hotel_id].name}
                                                </Typography>

                                                {clickedHotels[hotel_id]
                                                    .clicked ? (
                                                    <Icons.ArrowDown />
                                                ) : (
                                                    <Icons.ArrowUp />
                                                )}
                                            </Stack>
                                            {clickedHotels[hotel_id].clicked ? (
                                                <MembershipCardsReport
                                                    hotelID={hotel_id}
                                                    time={time}
                                                    dataTotalsRaw={dataTotals}
                                                    dataSignupsRaw={dataSignups}
                                                    dataRepeatBookersRaw={
                                                        dataRepeatBookers
                                                    }
                                                    dataBookingsRaw={
                                                        dataBookings
                                                    }
                                                    dataDetailsRaw={dataDetails}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </Stack>
                                    ))}
                                </>
                            ) : (
                                <Typography fontStyle={"italic"}>
                                    Select an hotel to display data
                                </Typography>
                            )}
                        </>
                    )}
                    <br />
                </Container>
            </Stack>
        </>
    );
};

export default MembershipReportScreen;
