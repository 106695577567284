import { dataSearchesTypes } from "../types/dataSearchesTypes";

export const loading = (hotelID) => {
    return {
        type: dataSearchesTypes.loading,
        hotelID,
    };
};
export const partialLoad = (data, hotelID) => {
    return {
        type: dataSearchesTypes.partialLoad,
        payload: data,
        hotelID,
    };
};
export const load = (data, hotelID) => {
    return {
        type: dataSearchesTypes.load,
        payload: data,
        hotelID,
    };
};
export const error = (error, data) => {
    return {
        type: dataSearchesTypes.error,
        payload: data,
        error: error,
    };
};
export const updateOne = (hotelID, who, month_v, year_v, obj) => {
    return {
        type: dataSearchesTypes.updateOne,
        hotelID,
        payload: {
            who: who,
            month_v: month_v,
            year_v: year_v,
            obj,
        },
    };
};
