import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { styled, TableCell } from "@mui/material";

const TableHeaderCell_Readability = styled(TableCell)({
    fontWeight: "bold",
    fontSize: "1.2em",
});
const TableHeaderCell_NotReadability = styled(TableCell)({
    fontWeight: "bold",
    fontSize: "1.1em",
});

const TableHeaderCell = (props) => {
    const {
        settings: { readability = false },
    } = useSelector((state) => state.settings);

    const [ret, setRet] = useState(
        <TableHeaderCell_NotReadability {...props} />
    );

    useEffect(() => {
        if (readability === true || readability === "True")
            setRet(<TableHeaderCell_Readability {...props} />);
        else setRet(<TableHeaderCell_NotReadability {...props} />);
    }, [readability, props]);

    return ret;
};

export default TableHeaderCell;
