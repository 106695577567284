import { TableCell, Popover, Typography, Stack, Box } from "@mui/material";
import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import SmallPercentage from "./SmallPercentage";

import { fns } from "../helpers/common";
import Icons from "../helpers/icons";
import Colours from "../helpers/colours";

import { makeStyles } from "@material-ui/core/styles";

const HEADER = [
    {
        id: "actual",
        title: "Room Revenue",
    },
    {
        id: "before",
        style: { display: "none" },
    },
    {
        id: "target",
        title: "Target",
    },
    {
        id: "predictions",
        title: "Predictions",
    },
    {
        id: "target_manual",
        style: { display: "none" },
    },
];

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none",
    },
    popoverContent: {
        pointerEvents: "auto",
    },
}));

const TableCellPopover = ({
    index,
    actual,
    old,
    customClass,
    isTarget,
    previous_target_value,
    updater_name,
    update_date,
}) => {
    const [open, setOpen] = React.useState(null);
    const tableCellRef = React.useRef(null);

    const { id: hotelID } = useSelector((state) => state.hotelID);

    const classes = useStyles();

    const handlePopoverOpen = () => {
        if ((actual > 0 && old === 0) || (old > 0 && actual === 0)) return;

        setOpen(true);
    };

    const handlePopoverClose = () => {
        setOpen(false);
    };

    // const open = Boolean(anchorEl);
    // const id = open ? index : undefined;

    const formatValueOrDate = (prop, value) => {
        let leftUnit = (HEADER.find((e) => e.id === prop) ?? {}).leftUnit ?? "";
        return `${leftUnit}${
            prop === "searchDate" || prop === "availabilityDate"
                ? new Date(value).toISOString().slice(0, 10)
                : fns(
                      hotelID,
                      value,
                      (HEADER.find((e) => e.id === prop) ?? {}).format ?? {}
                  )
        }`;
    };

    const getDifference = (getPercentage) => {
        let difference = 0;

        if (getPercentage) {
            difference = ((actual - old) / old) * 100;
        } else {
            difference = actual - old;
        }

        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    // alignSelf: "center"
                    color:
                        Math.sign(difference) === 1
                            ? Colours.trafficLightGreen
                            : Colours.trafficLightRed,
                    backgroundColor:
                        Math.sign(difference) === 1
                            ? Colours.pillarBackgroundEngage
                            : Colours.pillarBackgroundConvert,
                    pr: 1,
                    margin: !!updater_name ? "" : "auto",
                    fontWeight: "bold",
                }}
            >
                {Math.sign(difference) === 1 ? (
                    <Icons.ArrowUp
                        fontSize="small"
                        sx={{ fontWeight: "bold" }}
                    />
                ) : (
                    <Icons.ArrowDown fontSize="small" />
                )}
                <Typography variant="button" fontWeight={"bold"}>
                    {formatValueOrDate("revenue", difference)}
                </Typography>
                {getPercentage ? "%" : ""}
            </Box>
        );
    };

    return !isTarget ? (
        <>
            <TableCell
                key={index}
                sx={{
                    ...customClass,
                }}
                // aria-describedby={id}
                variant="contained"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                ref={tableCellRef}
            >
                {actual > 0 && formatValueOrDate("Actual", actual)}
                {actual > 0 && old > 0 && getDifference(true)}
            </TableCell>
            {actual > 0 && old > 0 && (
                <Popover
                    // id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    anchorEl={tableCellRef.current}
                    // onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    PaperProps={{
                        sx: { width: 250 },
                        onMouseEnter: handlePopoverOpen,
                        onMouseLeave: handlePopoverClose,
                    }}
                >
                    <Stack direction="column" spacing={1} padding={2}>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Typography>Previous Year:</Typography>
                            <Typography>
                                {formatValueOrDate("old", old)}
                            </Typography>
                        </Stack>

                        {getDifference(false)}
                    </Stack>
                </Popover>
            )}
        </>
    ) : (
        <>
            <TableCell
                key={index}
                sx={{
                    ...customClass,
                }}
                // aria-describedby={id}
                variant="contained"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                ref={tableCellRef}
            >
                {old > 0 && formatValueOrDate("old", old)}
                {actual > 0 && old > 0 && getDifference(true)}
            </TableCell>
            {actual > 0 && old > 0 && (
                <Popover
                    // id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    anchorEl={tableCellRef.current}
                    // onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    PaperProps={{
                        sx: { width: !!updater_name ? 350 : 300 },
                        onMouseEnter: handlePopoverOpen,
                        onMouseLeave: handlePopoverClose,
                    }}
                >
                    <Stack direction="column" spacing={1} padding={2}>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Typography>
                                {Math.sign(actual - old) === 1
                                    ? "Over Target By"
                                    : "Under Target By"}
                            </Typography>

                            {getDifference(false)}
                        </Stack>

                        {!!updater_name && (
                            <>
                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography>Previous Target: </Typography>
                                    <Typography>
                                        {formatValueOrDate(
                                            "",
                                            previous_target_value
                                        )}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography>Last edited by: </Typography>
                                    <Typography>{updater_name}</Typography>
                                </Stack>

                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography>Date edited: </Typography>
                                    <Typography>
                                        {new Date(
                                            update_date
                                        ).toLocaleDateString("en-IE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            // hour: "2-digit",
                                            // minute: "2-digit",
                                            // second: "2-digit",
                                            timeZone: "UTC",
                                        })}
                                    </Typography>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Popover>
            )}
        </>
    );
};

export default TableCellPopover;
