import React from "react";
import {
    Box,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";

const InsightAssistHeader = ({
    showRedAlertCheckbox = false,
    hideWeights = false,
}) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    if (isMobile) return null;
    return (
        <TableHead>
            <TableRow>
                {showRedAlertCheckbox && (
                    <TableCell>
                        <Tooltip title={"Red Alerts Highlights"}>
                            <Box fontWeight={"bold"} textAlign="center">
                                RA
                            </Box>
                        </Tooltip>
                    </TableCell>
                )}
                <TableCell>
                    <Box fontWeight={"bold"} textAlign="center">
                        INDICATOR NAME
                    </Box>
                </TableCell>
                <TableCell>
                    <Box fontWeight={"bold"} textAlign="center">
                        PRIORITY
                    </Box>
                </TableCell>
                <TableCell>
                    <Box fontWeight={"bold"} textAlign="center">
                        GROWTH TRACK
                    </Box>
                </TableCell>
                {!hideWeights && (
                    <>
                        <TableCell>
                            <Box fontWeight={"bold"} textAlign="center">
                                WEIGHTING
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box fontWeight={"bold"} textAlign="center">
                                CURRENT SCORE
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box fontWeight={"bold"} textAlign="center">
                                OLD SCORE
                            </Box>
                        </TableCell>
                    </>
                )}
                <TableCell>
                    <Box fontWeight={"bold"} textAlign="center">
                        STATUS
                    </Box>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default InsightAssistHeader;
