import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Container,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
    createTheme,
} from "@mui/material";

import Fuse from "fuse.js";

import IndicatorsSummaryRing from "./IndicatorsSummaryRing";
import IndicatorTable from "./IndicatorTable";
import GoBack from "../GoBack";
import SearchButtonAndField from "../SearchButtonAndField";
import { processTypeRange } from "./IndicatorsTypeRange";
import { processTypeABCD } from "./IndicatorsTypeABCD";
import { processTypeYesNo } from "./IndicatorsTypeYesNo";
import { processTypePercentage } from "./IndicatorsTypePercentage";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 400,
            sm: 600,
        },
    },
});
// For showing details for one indicator: Engage, Attract, Convert and Retain
const IndicatorsCategory = ({
    who,
    pillarsScore,
    data,
    basePath,
    title = "",
    handleRefreshIndicators,
}) => {
    let downSM = useMediaQuery(theme.breakpoints.down("sm"));

    const [processedIndicators, setProcessedIndicators] = useState([]); // By Indicator Type
    const [rawIndicators, setRawIndicators] = useState({}); // By Indicator ID with all info

    useEffect(() => {
        const auxProcessedIndicators = [];
        const auxRawIndicators = {};
        if (data.length > 0) {
            const processFun = {
                RANGE: processTypeRange,
                ABCD: processTypeABCD,
                YESNO: processTypeYesNo,
                PERCENTAGE: processTypePercentage,
            };
            data.forEach((indicator) => {
                // TODO: process status here and remove from indicators process functions
                // TODO: check processfunctions for empty values processing
                const auxProcessedIndicator =
                    processFun[`${indicator.type}`](indicator);

                auxProcessedIndicator.id = indicator.id;
                auxProcessedIndicator.name = indicator.name;
                auxProcessedIndicator.description = indicator.description;
                auxProcessedIndicator.category = indicator.category;
                auxProcessedIndicator.subcategory = indicator.subcategory;
                auxProcessedIndicator.ts = indicator.ts;
                auxProcessedIndicator.quality_performance =
                    indicator.quality_performance;

                auxProcessedIndicators.push(auxProcessedIndicator);
                auxRawIndicators[indicator.id] = indicator;
            });
        }
        setProcessedIndicators(auxProcessedIndicators);
        setRawIndicators(auxRawIndicators);
    }, [who, pillarsScore, data]);

    return (
        <Container>
            <GoBack to={basePath} />
            <br />
            {!title ? (
                <Paper sx={{ pt: 2, pb: 2 }}>
                    <IndicatorsSummaryRing
                        score={
                            pillarsScore[who] > 0
                                ? Math.round(pillarsScore[who])
                                : 0
                        }
                    />
                </Paper>
            ) : (
                <IndicatorsSummaryRing
                    score={
                        pillarsScore[who] > 0
                            ? Math.round(pillarsScore[who])
                            : 0
                    }
                    title={title}
                />
            )}
            <br />
            <Typography fontWeight={"bold"}>Score Breakdown</Typography>
            <br />
            <IndicatorTable
                data={processedIndicators}
                rawData={rawIndicators}
                handleRefreshIndicators={handleRefreshIndicators}
            />
            <br />
            <Typography fontWeight="bold">Actionable Items</Typography>
        </Container>
    );
};

export default IndicatorsCategory;
