import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    useMediaQuery,
    useTheme,
    Box,
    TableContainer,
    Container,
    TableHead,
} from "@mui/material";
import HoverPaper from "./shared/HoverPaper";
import Colours from "../helpers/colours";
import { months_abbreviations } from "../helpers/dates";
import { fns } from "../helpers/common";
import TableHeadSort from "./TableHeadSort";
import { DISPLAY_ELEMENT_HELPER } from "../helpers/filters";
import ToggleButtonTitled from "./ToggleButtonTitled";
import NoDataRow from "./NoDataRow";

const headerStyles = {
    fontSize: "0.8em",
    fontWeight: "bold",
    backgroundColor: Colours.pillarBackgroundAttract,
    border: "1px solid lightgrey",
};

const formatMonthYear = (date) => {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const m_y = Number.parseInt(`${year}${month < 10 ? "0" + month : month}`);
    return m_y;
};

const transformData = (js, data, selectedHotels) => {
    let filtered_data = [];

    if (selectedHotels.length === 0) {
        filtered_data = data;
    } else {
        filtered_data = data.filter((e) => selectedHotels.includes(e.SiteName));
    }

    const { toDay, fromDay } = { fromDay: js.fromDay, toDay: js.toDay };

    let fromDayOneYearBefore = new Date();
    let toDayOneYearBefore = new Date();

    try {
        fromDayOneYearBefore = new Date(fromDay);
        fromDayOneYearBefore.setFullYear(
            fromDayOneYearBefore.getFullYear() - 1
        );
    } catch (_e) {
        console.log(_e);
    }

    try {
        toDayOneYearBefore = new Date(toDay);
        toDayOneYearBefore.setFullYear(toDayOneYearBefore.getFullYear() - 1);
    } catch (_e) {
        console.log(_e);
    }

    const filterData = (data, from, to) => {
        let transformedData = [];
        let totalMonths = {
            Total: { Total: 0, Rooms: 0, Vouchers: 0 },
        };

        data.filter(
            (e) =>
                new Date(e.CheckOut) >= from &&
                new Date(e.CheckOut) <= to &&
                e.CancelStatus === 0
        ).forEach((e) => {
            const date_by = new Date(e.CheckOut);
            const m_y = formatMonthYear(date_by);

            const siteName = e.SiteName;

            let client = transformedData.find(
                (client) => client.Client === siteName
            );

            if (!client) {
                client = {
                    Client: siteName,
                    Total: { Total: 0, Rooms: 0, Vouchers: 0 }, // Initialize client total
                };
                transformedData.push(client);
            }

            if (!client[m_y]) {
                client[m_y] = {
                    Total: 0,
                    Rooms: 0,
                    Vouchers: 0,
                };
            }

            if (!totalMonths[m_y]) {
                totalMonths[m_y] = {
                    Total: 0,
                    Rooms: 0,
                    Vouchers: 0,
                };
            }

            // Update client month totals
            client[m_y].Total += e.AroTotal;
            totalMonths[m_y].Total += e.AroTotal;

            if (e.ResTypeID === 4) {
                client[m_y].Vouchers += e.AroTotal;
                client.Total.Vouchers += e.AroTotal; // Update client's total vouchers
                totalMonths[m_y].Vouchers += e.AroTotal;
                totalMonths.Total.Vouchers += e.AroTotal;
            } else {
                client[m_y].Rooms += e.AroTotal;
                client.Total.Rooms += e.AroTotal; // Update client's total rooms
                totalMonths[m_y].Rooms += e.AroTotal;
                totalMonths.Total.Rooms += e.AroTotal;
            }

            // Update client overall total
            client.Total.Total += e.AroTotal;
            totalMonths.Total.Total += e.AroTotal;
        });

        // Sort clients alphabetically
        transformedData.sort((a, b) => a.Client.localeCompare(b.Client));

        // Add overall totals row
        transformedData.push({
            Client: "Total",
            ...totalMonths,
        });

        return transformedData;
    };

    let dataToUse = filterData(
        filtered_data,
        new Date(fromDay),
        new Date(toDay)
    );
    let dataToUseLastYear = filterData(
        filtered_data,
        fromDayOneYearBefore,
        toDayOneYearBefore
    );

    return { dataToUse, dataToUseLastYear };
};

const CommissionTable = ({
    js,
    data,
    selectedHotels,
    metrics = {
        Total: {
            ...DISPLAY_ELEMENT_HELPER.commissionTotal,
            short: "Total",
        },
        Rooms: {
            ...DISPLAY_ELEMENT_HELPER.commissionRoomsTotal,
            short: "Rooms",
        },
        Vouchers: {
            ...DISPLAY_ELEMENT_HELPER.commissionVouchers,
            short: "Vouchers",
        },
    },
}) => {
    const theme = useTheme();
    let tableOverflow = useMediaQuery(theme.breakpoints.down("lg"));

    const [processedData, setProcessedData] = useState([]);
    const [processedDataLastYear, setProcessedDataLastYear] = useState([]);

    const [selectedData, setSelectedData] = useState([]);
    const [monthsToDisplay, setMonthsToDisplay] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("Client");

    const [valueType, setValueType] = useState(Object.keys(metrics)[0] ?? "");

    useEffect(() => {
        setProcessedData([]);
        setProcessedDataLastYear([]);
        setMonthsToDisplay([]);
        setSelectedData([]);

        if (!data || data.length === 0 || !js) {
            return;
        }

        const { dataToUse, dataToUseLastYear } = transformData(
            js,
            data,
            selectedHotels
        );

        if (dataToUse.length === 0) {
            return;
        }

        const allMonths = new Set();

        // Add "Total" as the first entry in the set
        allMonths.add("Total");

        dataToUse.forEach((client) => {
            Object.keys(client).forEach((month) => {
                if (month === "Client" || month === "ClientTotal") {
                    return;
                }
                allMonths.add(month);
            });
        });

        setOrderBy("Client");
        setMonthsToDisplay([...allMonths]);
        setProcessedData(dataToUse);
        setProcessedDataLastYear(dataToUseLastYear);
    }, [data, js, selectedHotels]);

    useEffect(() => {
        if (processedData.length === 0) {
            setSelectedData([]);
            return;
        }

        let auxSelected = processedData.filter(
            (item) => item.Client !== "Total"
        );

        auxSelected.sort((a, b) => {
            if (orderBy === "Client") {
                let aValue = a[orderBy] ? a[orderBy].toLowerCase() : "";
                let bValue = b[orderBy] ? b[orderBy].toLowerCase() : "";
                return order === "asc"
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            } else {
                let aValue = a[orderBy] ? a[orderBy][valueType] : 0;
                let bValue = b[orderBy] ? b[orderBy][valueType] : 0;
                return order === "asc" ? aValue - bValue : bValue - aValue;
            }
        });

        setSelectedData(auxSelected);
    }, [processedData, order, orderBy, valueType]);

    if (processedData.length === 0 || monthsToDisplay.length === 0) {
        return;
    }

    const totalRow = processedData.find((row) => row.Client === "Total");

    return (
        <>
            <ToggleButtonTitled
                TITLE=""
                valueSet={valueType}
                values={Object.fromEntries(
                    Object.keys(metrics).map((valueKey) => [
                        valueKey,
                        metrics[valueKey].short,
                    ])
                )}
                onChange={(e, v) => {
                    setValueType(v);
                }}
            />
            {valueType === null ? (
                <Container
                    maxWidth={false}
                    component={HoverPaper}
                    sx={{ p: 2, backgroundColor: Colours.notificationCard }}
                >
                    <Table>
                        <TableBody>
                            <NoDataRow text="Please select a metric" />
                        </TableBody>
                    </Table>
                </Container>
            ) : (
                <Box
                    component={HoverPaper}
                    // sx={{ overflow: "auto", maxHeight: "600px" }}
                >
                    <TableContainer sx={{ maxHeight: "600px" }}>
                        <Table stickyHeader>
                            <TableHeadSort
                                stickyHeads={tableOverflow ? 2 : 0}
                                headers={[
                                    {
                                        id: "Client",
                                        label: "Client",
                                        styles: {
                                            ...headerStyles,
                                        },
                                    },
                                    ...monthsToDisplay.map((month) => ({
                                        id: month,
                                        label:
                                            month === "Total"
                                                ? "Total"
                                                : `${
                                                      months_abbreviations[
                                                          Number.parseInt(
                                                              month.slice(4, 6)
                                                          )
                                                      ]
                                                  } ${month.slice(0, 4)} `,
                                        styles: {
                                            ...headerStyles,
                                            textAlign: "right",
                                            color:
                                                Number.parseInt(month) ===
                                                formatMonthYear(new Date())
                                                    ? "blue"
                                                    : "black",
                                        },
                                    })),
                                ]}
                                order={order}
                                orderBy={orderBy}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                            />
                            <TableBody>
                                {selectedData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            sx={{
                                                textAlign: "left",
                                                border: "1px solid lightgrey",
                                                fontWeight: row.Client
                                                    ? "bold"
                                                    : "",
                                                backgroundColor: row.Client
                                                    ? Colours.pillarBackgroundAttract
                                                    : "",
                                            }}
                                        >
                                            {row.Client}
                                        </TableCell>
                                        {monthsToDisplay.map((month) => (
                                            <TableCell
                                                key={month}
                                                sx={{
                                                    textAlign: "right",
                                                    border: "1px solid lightgrey",
                                                    fontWeight:
                                                        row.Client === "Total"
                                                            ? "bold"
                                                            : "",
                                                    backgroundColor:
                                                        Number.parseInt(
                                                            month
                                                        ) ===
                                                        formatMonthYear(
                                                            new Date()
                                                        )
                                                            ? Colours.pillarBackgroundRetain
                                                            : "",
                                                }}
                                            >
                                                {fns(
                                                    1,
                                                    row[month] &&
                                                        row[
                                                            month
                                                        ].hasOwnProperty([
                                                            valueType,
                                                        ])
                                                        ? Math.round(
                                                              row[month][
                                                                  valueType
                                                              ],
                                                              2
                                                          )
                                                        : 0,
                                                    {}
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                            {totalRow && (
                                <TableHead
                                    sx={{
                                        position: "sticky",
                                        bottom: 0,
                                        zIndex: 1,
                                    }}
                                >
                                    {" "}
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                textAlign: "left",
                                                border: "1px solid lightgrey",
                                                fontWeight: "bold",
                                                backgroundColor:
                                                    Colours.pillarBackgroundAttract,
                                            }}
                                        >
                                            {totalRow.Client}
                                        </TableCell>
                                        {monthsToDisplay.map((month) => (
                                            <TableCell
                                                key={month}
                                                sx={{
                                                    textAlign: "right",
                                                    border: "1px solid lightgrey",
                                                    fontWeight: "bold",
                                                    backgroundColor:
                                                        Number.parseInt(
                                                            month
                                                        ) ===
                                                        formatMonthYear(
                                                            new Date()
                                                        )
                                                            ? Colours.pillarBackgroundRetain
                                                            : "",
                                                }}
                                            >
                                                {fns(
                                                    1,
                                                    totalRow[month] &&
                                                        totalRow[
                                                            month
                                                        ].hasOwnProperty([
                                                            valueType,
                                                        ])
                                                        ? Math.round(
                                                              totalRow[month][
                                                                  valueType
                                                              ],
                                                              2
                                                          )
                                                        : 0,
                                                    {}
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                            )}
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    );
};

export default CommissionTable;
