import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import CheckShow from "./shared/CheckShow";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import { ARO_ADMIN, ARO_ALL, HOTELS_INFO } from "../configuration";

const HotelSelectorMany = ({
    selectedHotels = [],
    setSelectedHotels = () => {},
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { user } = useSelector((state) => state.auth);
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);

    const [searchText, setSearchText] = useState("");

    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (hotelID && hotelID !== "default")
            setSelectedHotels((prevHotels) => {
                if (!prevHotels.includes(Number(hotelID))) {
                    return [...prevHotels, Number(hotelID)];
                } else {
                    return prevHotels;
                }
            });
    }, [hotelID]);

    useEffect(() => {
        if (user && user.rolesHotels) {
            if ((user.rolesHotels ?? []).includes(-1)) {
                // Remove key 14
                const filteredHotels = Object.entries(HOTELS_INFO)
                    .filter(([key, value]) => key !== "14")
                    .reduce((obj, [key, value]) => {
                        obj[key] = value;
                        return obj;
                    }, {});

                setOptions(Object.values(filteredHotels));
            } else {
                setOptions(
                    user.rolesHotels
                        .filter((h) => h in HOTELS_INFO)
                        .map((id) => HOTELS_INFO[id])
                );
            }
        } else {
            setOptions([]);
        }
    }, [user]);

    useEffect(() => {
        if (searchText === "") {
            setFilteredOptions(options);
        } else {
            const lowerCaseSearchText = searchText.toLowerCase();

            const results = options.filter(
                (option) =>
                    option.name.toLowerCase().includes(lowerCaseSearchText) ||
                    (option.simplified_name ?? "")
                        .toLowerCase()
                        .includes(lowerCaseSearchText) ||
                    option.id
                        .toString()
                        .toLowerCase()
                        .includes(lowerCaseSearchText)
            );
            setFilteredOptions(results);
        }
    }, [options, searchText]);

    const handleToggleHotel = (id) => {
        setSelectedHotels(
            selectedHotels.includes(id)
                ? selectedHotels.filter((id_) => id_ !== id)
                : [...selectedHotels, id]
        );
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSearchText("");
        setFilteredOptions(options);
    };

    const ListItemComponent = ({ data }) => {
        const [expandChild, setExpandChild] = useState(true);

        return (
            <>
                <ListItem disableGutters key={data.id}>
                    <ListItemButton
                        onClick={() => {
                            handleToggleHotel(data.id);
                        }}
                    >
                        <Checkbox
                            checked={selectedHotels.includes(data.id)}
                            onChange={() => handleToggleHotel(data.id)}
                        />
                        <ListItemAvatar>
                            <Avatar
                                alt={`Hotel Logo ${data.name}`}
                                src={data.img}
                            />
                        </ListItemAvatar>
                        <CheckShow
                            rolesThatCanSee={ARO_ADMIN}
                            alternative={
                                <ListItemText
                                    primary={
                                        isMobile
                                            ? data.name.substring(0, 20) + "..."
                                            : data.name
                                    }
                                />
                            }
                        >
                            <ListItemText
                                secondary={data.id}
                                primary={
                                    isMobile
                                        ? data.name.substring(0, 20) + "..."
                                        : data.name
                                }
                            />
                        </CheckShow>
                    </ListItemButton>
                    {data.hasOwnProperty("parent") && (
                        <Button
                            onClick={() => {
                                setExpandChild(!expandChild);
                            }}
                            sx={{ color: Colours.transparentBubble }}
                        >
                            {expandChild ? (
                                <Icons.ArrowDown />
                            ) : (
                                <Icons.ArrowUp />
                            )}
                        </Button>
                    )}
                </ListItem>
                {data.hasOwnProperty("parent") && (
                    <Collapse in={expandChild} timeout="auto" unmountOnExit>
                        <List>
                            {data.parent
                                .filter(
                                    (id) =>
                                        user.rolesHotels.includes(-1) ||
                                        user.rolesHotels.includes(id)
                                )
                                .map((id) => HOTELS_INFO[id])
                                .map((subHotel) => (
                                    <ListItemButton
                                        sx={{ py: 1, pl: "2em" }}
                                        onClick={() => {
                                            handleToggleHotel(subHotel.id);
                                        }}
                                    >
                                        <Checkbox
                                            checked={selectedHotels.includes(
                                                subHotel.id
                                            )}
                                            onChange={() =>
                                                handleToggleHotel(subHotel.id)
                                            }
                                        />
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={`Hotel Logo ${subHotel.name}`}
                                                src={subHotel.img ?? ""}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            secondary={subHotel.id}
                                            primary={
                                                isMobile
                                                    ? subHotel.name.substring(
                                                          0,
                                                          20
                                                      ) + "..."
                                                    : subHotel.name
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                        </List>
                    </Collapse>
                )}
            </>
        );
    };

    return (
        <>
            <Button
                variant="text"
                sx={{
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: Colours.hoverGrey,
                    },
                }}
                onClick={() => setOpenDialog(true)}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    {/* <Avatar
                        alt={`Hotel Logo ${HOTELS_INFO[hotelID].name}`}
                        src={HOTELS_INFO[hotelID].img}
                        sx={{ mr: 1 }}
                    /> */}
                    {/* <Box height={"2em"} sx={{ pr: 1, overflow: "hidden" }}>
                        <img
                            src={(HOTELS_INFO[hotelID] ?? {}).img ?? ""}
                            alt={`Hotel Logo ${
                                (HOTELS_INFO[hotelID] ?? {}).name ?? ""
                            }`}
                            style={{
                                height: "100%",
                                width: "auto",
                                maxWidth: "100%",
                            }}
                        />
                    </Box>
                    <Typography>
                        {isMobile
                            ? (
                                  (HOTELS_INFO[hotelID] ?? {}).name ?? ""
                              ).substring(0, 20) + "..."
                            : (HOTELS_INFO[hotelID] ?? {}).name ?? ""}
                    </Typography> */}
                    Select hotels ({selectedHotels.length})
                    <Icons.ArrowDown />
                </Stack>
            </Button>
            <Dialog onClose={handleCloseDialog} open={openDialog}>
                <DialogTitle>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography variant="h6">Select Hotel</Typography>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icons.Search />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) =>
                                setSearchText(event.target.value)
                            }
                            variant="standard"
                        />
                        <IconButton onClick={handleCloseDialog}>
                            <Icons.CloseNavbar />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <List sx={{ pt: 0 }}>
                        {/* No searching, so all */}
                        {searchText === "" &&
                            filteredOptions
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((data, index) => (
                                    <ListItemComponent
                                        key={data.id}
                                        data={data}
                                    />
                                ))}

                        {/* No results */}
                        {filteredOptions.length === 0 && (
                            <React.Fragment>
                                <Divider
                                    sx={{
                                        borderColor: Colours.navbarBottomLine,
                                    }}
                                />
                                <Typography fontSize={"0.8em"}>
                                    No results
                                </Typography>
                            </React.Fragment>
                        )}

                        {/* Top 4 */}
                        {searchText !== "" && filteredOptions.length > 0 && (
                            <>
                                <React.Fragment>
                                    <Divider
                                        sx={{
                                            borderColor:
                                                Colours.navbarBottomLine,
                                        }}
                                    />
                                    <Typography fontSize={"0.8em"}>
                                        Main results
                                    </Typography>
                                </React.Fragment>
                                {filteredOptions
                                    .slice(0, 4)
                                    .map((data, index) => (
                                        <ListItemComponent
                                            key={data.id}
                                            data={data}
                                        />
                                    ))}
                            </>
                        )}

                        {/* Others than top 4 */}
                        {searchText !== "" && filteredOptions.length > 4 && (
                            <>
                                <React.Fragment>
                                    <Divider
                                        sx={{
                                            borderColor:
                                                Colours.navbarBottomLine,
                                        }}
                                    />
                                    <Typography fontSize={"0.8em"}>
                                        Similar results
                                    </Typography>
                                </React.Fragment>
                                {filteredOptions.slice(4).map((data, index) => (
                                    <ListItemComponent
                                        key={data.id}
                                        data={data}
                                    />
                                ))}
                            </>
                        )}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default HotelSelectorMany;
