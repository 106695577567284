import React from "react";
import { useSelector } from "react-redux";
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
} from "@mui/material";

import WrapperPDFTable from "./shared/WrapperPDFTable";
import SmallPercentage from "./SmallPercentage";
import TableHeaderCell from "./TableHeaderCell";
import TableBodyCell from "./TableBodyCell";

import { getYearsBefore, months } from "../helpers/dates";
import { fns } from "../helpers/common";
import Colours from "../helpers/colours";

const CalcPercentage = (data, key, year, formatter, month = null) => {
    const fullYear = month === null ? true : false;
    if (!data.hasOwnProperty(year) || !data.hasOwnProperty(year - 1)) return;
    else if (
        !fullYear &&
        (!data[year].hasOwnProperty(month) ||
            !data[year - 1].hasOwnProperty(month))
    )
        return;

    const actual = fullYear
        ? data[year][key] ?? 0
        : data[year][month][key] ?? 0;
    const old = fullYear
        ? data[year - 1][key] ?? 0
        : data[year - 1][month][key] ?? 0;

    return (
        <SmallPercentage
            actualName="Selected"
            oldName="Previous"
            actual={actual}
            old={old}
            isInverted
            formatter={formatter}
        />
    );
};

const TableKeyToTime = ({
    tableKey,
    header,
    dataMonths,
    dataYears,
    fullYear,
    time,
}) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);

    return (
        <>
            <br />
            <TableContainer style={{ overflow: "auto", maxHeight: "600px" }}>
                <WrapperPDFTable k={tableKey}>
                    {fullYear && Object.keys(dataYears).length > 0 ? (
                        // YEARS TABLE ------------------------------------------------------------------------
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 3,
                                            backgroundColor: Colours.plainWhite,
                                        }}
                                    />
                                    {Object.keys(dataYears)
                                        .reverse()
                                        .map((year) => {
                                            return (
                                                <TableHeaderCell
                                                    style={{
                                                        position: "sticky",
                                                        top: 0,
                                                        zIndex: 3,
                                                        backgroundColor:
                                                            Colours.plainWhite,
                                                    }}
                                                >
                                                    {year}
                                                </TableHeaderCell>
                                            );
                                        })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {header.map((head) => {
                                    return (
                                        <TableRow key={head.id + "-tableRow"}>
                                            <TableHeaderCell
                                                tooltip={head.tooltip ?? ""}
                                                style={{
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: 1,
                                                    backgroundColor:
                                                        Colours.plainWhite,
                                                }}
                                            >
                                                {head.title}
                                            </TableHeaderCell>

                                            {Object.keys(dataYears)
                                                .reverse()
                                                .map((year) => {
                                                    return (
                                                        <TableBodyCell>
                                                            {fns(
                                                                hotelID,
                                                                (dataYears[
                                                                    year
                                                                ] ?? {})[
                                                                    head.id
                                                                ] ?? 0,
                                                                head.format ??
                                                                    {}
                                                            )}
                                                            {CalcPercentage(
                                                                dataYears,
                                                                head.id,
                                                                year,
                                                                (number) =>
                                                                    fns(
                                                                        hotelID,
                                                                        number,
                                                                        head.format
                                                                    )
                                                            )}
                                                        </TableBodyCell>
                                                    );
                                                })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    ) : !fullYear && Object.keys(dataMonths).length > 0 ? (
                        // MONTHS TABLE ------------------------------------------------------------------------
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 3,
                                            backgroundColor: Colours.plainWhite,
                                        }}
                                    />
                                    {Object.keys(dataMonths)
                                        .reverse()
                                        .map((year) => {
                                            return (
                                                <TableHeaderCell
                                                    key={
                                                        "header-" +
                                                        months[
                                                            time.getUTCMonth()
                                                        ].slice(0, 3) +
                                                        "-" +
                                                        year
                                                    }
                                                    style={{
                                                        position: "sticky",
                                                        top: 0,
                                                        zIndex: 3,
                                                        backgroundColor:
                                                            Colours.plainWhite,
                                                    }}
                                                >
                                                    {months[
                                                        time.getUTCMonth()
                                                    ].slice(0, 3) +
                                                        " " +
                                                        year}
                                                </TableHeaderCell>
                                            );
                                        })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {header.map((head) => {
                                    return (
                                        <TableRow>
                                            <TableHeaderCell
                                                style={{
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: 1,
                                                    backgroundColor:
                                                        Colours.plainWhite,
                                                }}
                                            >
                                                {head.title}
                                            </TableHeaderCell>
                                            {Object.keys(dataMonths)
                                                .reverse()
                                                .map((year) => {
                                                    return (
                                                        <TableBodyCell>
                                                            {fns(
                                                                hotelID,
                                                                ((dataMonths[
                                                                    year
                                                                ] ?? {})[
                                                                    time.getUTCMonth()
                                                                ] ?? {})[
                                                                    head.id
                                                                ] ?? 0,
                                                                head.format ??
                                                                    {}
                                                            )}
                                                            {CalcPercentage(
                                                                dataMonths,
                                                                head.id,
                                                                year,
                                                                (number) =>
                                                                    fns(
                                                                        hotelID,
                                                                        number,
                                                                        head.format
                                                                    ),
                                                                time.getUTCMonth()
                                                            )}
                                                        </TableBodyCell>
                                                    );
                                                })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    ) : (
                        // SKELETON TABLE ------------------------------------------------------------------------
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        key={"skeleton-placeholder-tablecell"}
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 3,
                                            backgroundColor: Colours.plainWhite,
                                        }}
                                    />
                                    {[...getYearsBefore()].map((f) => (
                                        <TableCell key={f}>
                                            <Skeleton />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...Array(6).keys()].map((e) => (
                                    <TableRow key={e}>
                                        <TableCell
                                            key={e + "-skeleton-key-body"}
                                        >
                                            <Skeleton />
                                        </TableCell>
                                        {[...getYearsBefore()].map((f) => (
                                            <TableCell
                                                key={
                                                    e +
                                                    "-skeleton-key-body-" +
                                                    f
                                                }
                                            >
                                                <Skeleton />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableHead>
                                {
                                    //   _                    _ _
                                    //  | |    ___   __ _  __| (_)_ __   __ _
                                    //  | |   / _ \ / _` |/ _` | | '_ \ / _` |
                                    //  | |__| (_) | (_| | (_| | | | | | (_| |
                                    //  |_____\___/ \__,_|\__,_|_|_| |_|\__, |
                                    //                                  |___/
                                }
                            </TableHead>
                        </Table>
                    )}
                </WrapperPDFTable>
            </TableContainer>
        </>
    );
};

export default TableKeyToTime;
