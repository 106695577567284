import React, { useEffect, useState } from "react";

import TabSelector from "./TabSelector";

import YearPerformance from "./reports/YearPerformance";

const MonthlyUsers = ({ data }) => {
    const TITLE = "Users";
    const [cardSelected, setCardSelected] = useState("uniqueBookers");
    const [bookersData, setBookersData] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const auxBookersData = [
                // {
                //     id: "uniqueBookers",
                //     title: "Unique Bookers",
                //     value: data.tabs.uniqueBookers.this,
                //     old: data.tabs.uniqueBookers.last,
                //     expand: true,
                //     compare: true,
                // },
                {
                    id: "bookingEngineConversionRate",
                    title: "Booking Engine Conversion Rate",
                    format: { maxDecimals: 2, right: "%" },
                    value: data.tabs.bookingEngineConversionRate.this,
                    old: data.tabs.bookingEngineConversionRate.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "overallConversionRate",
                    title: "Overall Conversion Rate",
                    format: { maxDecimals: 2, right: "%" },
                    value: data.tabs.overallConversionRate.this,
                    old: data.tabs.overallConversionRate.last,
                    expand: true,
                    compare: true,
                },
            ];
            setBookersData(auxBookersData);
            setCardSelected("bookingEngineConversionRate");
        }
    }, [data]);

    return (
        <div>
            <TabSelector
                // hotelID={hotelID}
                data={bookersData}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
            />
            <br />

            {
                // cardSelected === "uniqueBookers" ||

                (cardSelected === "bookingEngineConversionRate" ||
                    cardSelected === "overallConversionRate") && (
                    <YearPerformance
                        TITLE={`${TITLE} - ${
                            bookersData.length > 0
                                ? bookersData.find(
                                      (head) => head.id === cardSelected
                                  ).title ?? ""
                                : ""
                        }`}
                        dataLast={data.tabs[cardSelected].historical.last}
                        dataThis={data.tabs[cardSelected].historical.this}
                    />
                )
            }
        </div>
    );
};

export default MonthlyUsers;
