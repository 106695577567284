import {
    uniqueNamesGenerator,
    adjectives,
    animals,
} from "unique-names-generator";

export const capitalize = (stringsWithSpaces) => {
    return stringsWithSpaces
        .split(" ")
        .map((element) => {
            return (
                element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()
            );
        })
        .join(" ");
};

export const getRandomName = () => {
    return capitalize(
        uniqueNamesGenerator({
            dictionaries: [adjectives, animals],
            separator: " ",
            length: 2,
        })
    );
};

export const rInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
export const randomData = () => {
    let data = [];
    [1, 2, 3, 4, 5, 6, 7].forEach((element) => {
        data.push({
            name: `0${element}/2022`,
            uv: rInterval(500, 4000),
            pv: rInterval(500, 4000),
            amt: rInterval(500, 4000),
        });
    });
    return data;
};

export const randomDataPie1 = () => {
    let data = [];
    [1, 2, 3, 4, 5, 6, 7].forEach((element) => {
        data.push({
            name: `A${element}`,
            value: rInterval(5, 300),
        });
    });
    return data;
};
export const randomDataPie2 = () => {
    let data = [];
    [1, 2, 3, 4].forEach((element) => {
        data.push({
            name: `B${element}`,
            value: rInterval(5, 300),
        });
    });
    return data;
};

export const randomStatus = () => {
    return [
        "Complete",
        "Pending review",
        "Under review",
        "Partial complete",
        "In progress",
    ][Math.floor(Math.random() * 5)];
};
export const randomStatusUncompleted = () => {
    return [
        "Pending review",
        "Under review",
        "Partial complete",
        "In progress",
    ][Math.floor(Math.random() * 4)];
};
