export const settingsTypes = {
    loading: "[settings] loading",
    load: "[settings] load",
    reload: "[settings] reload",
    error: "[settings] error",
    clean: "[settings] clean",
    addDefault: "[settings] addDefault",
    add: "[settings] add",
    update: "[settings] update",
};
