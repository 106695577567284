import React, { useEffect, useState } from "react";

import { Box, TextField, Typography, styled } from "@mui/material";

import {
    LocalizationProvider,
    DatePicker,
    PickersDay,
} from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enIE } from "date-fns/locale";
import Colours from "../helpers/colours";
import { formatDate, getDatesBetween } from "../helpers/filters";
import { MAX_DATE, MIN_DATE, newDateUTC } from "../helpers/dates";

const HighlightedDay = styled(PickersDay)(
    ({ theme, isSelected, isFromTo }) => ({
        ...(isSelected && {
            backgroundColor: isFromTo
                ? Colours.secondarySoft
                : Colours.tableRowBackground,
        }),
    })
);

const DefaultBoxProps = {
    sx: {
        height: "auto",
        maxWidth: 370,
        border: 1,
        borderRadius: 1,
        borderColor: Colours.midLightText,

        "& .MuiTextField-root": {
            border: "none",
            "& .MuiOutlinedInput-input": {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                marginTop: "4px",
                marginBottom: "4px",
                // margin: "4px",
                width: "100%",
            },
            "& fieldset": {
                border: "none",
            },
        },
    },
};

const DefaultFromDateProps = {
    value: new Date(),
    minDate: MIN_DATE,
    maxDate: MAX_DATE,
};

const DefaultToDateProps = {
    value: new Date(),
    maxDate: MAX_DATE,
};

const DateRangePicker = ({
    title = "",
    onChangeFromDate,
    onChangeToDate,
    fromDateProps = {},
    toDateProps = {},
    boxProps = {},
    open = false,
}) => {
    const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
    const [openFromDatePicker, setOpenFromDatePicker] = useState(false);
    useEffect(() => {
        setOpenFromDatePicker(open);
    }, [open]);

    const [fromDate, setFromDate] = useState(new Date());
    useEffect(() => {
        let date = fromDateProps.hasOwnProperty("value")
            ? fromDateProps.value
            : DefaultFromDateProps.value;
        setFromDate(date);
    }, [fromDateProps]);

    const [toDate, setToDate] = useState(new Date());
    useEffect(() => {
        let date = toDateProps.hasOwnProperty("value")
            ? toDateProps.value
            : DefaultToDateProps.value;
        setToDate(date);
    }, [toDateProps]);

    const renderDay = (day, selectedDates, pickersDayProps) => {
        day.setHours(0, 0, 0, 0);
        const fromDay = new Date(fromDate);
        fromDay.setHours(0, 0, 0, 0);
        const toDay = new Date(toDate);
        toDay.setHours(0, 0, 0, 0);
        return (
            <HighlightedDay
                {...pickersDayProps}
                isSelected={getDatesBetween(
                    formatDate(fromDate),
                    formatDate(toDate)
                ).includes(formatDate(day))}
                isFromTo={
                    day.getTime() === fromDay.getTime() ||
                    day.getTime() === toDay.getTime()
                }
            />
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enIE}>
            <Box>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    {title}
                </Typography>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ ...DefaultBoxProps.sx, ...(boxProps.sx ?? {}) }}
                >
                    {/* START */}
                    <DatePicker
                        inputFormat="dd MMM yyyy"
                        value={fromDate}
                        minDate={
                            fromDateProps.minDate ??
                            DefaultFromDateProps.minDate
                        }
                        maxDate={
                            fromDateProps.maxDate ??
                            DefaultFromDateProps.maxDate
                        }
                        onChange={(newValue) => {
                            let year = newValue.getFullYear();
                            let month = newValue.getMonth();
                            let day = newValue.getDate();
                            let utcDate = newDateUTC(year, month, day);
                            setFromDate(utcDate);
                            onChangeFromDate(utcDate);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onClick={() => setOpenFromDatePicker(true)}
                            />
                        )}
                        renderDay={renderDay}
                        open={openFromDatePicker}
                        onOpen={() => setOpenFromDatePicker(true)}
                        onClose={() => {
                            setOpenFromDatePicker(false);
                            setOpenEndDatePicker(true);
                        }}
                        InputAdornmentProps={{
                            position: "start",
                        }}
                    />
                    -{/* END */}
                    <DatePicker
                        inputFormat="dd MMM yyyy"
                        value={toDate}
                        minDate={fromDate}
                        maxDate={
                            toDateProps.maxDate ?? DefaultToDateProps.maxDate
                        }
                        onChange={(newValue) => {
                            let year = newValue.getFullYear();
                            let month = newValue.getMonth();
                            let day = newValue.getDate();
                            let utcDate = newDateUTC(year, month, day);
                            setToDate(utcDate);
                            onChangeToDate(utcDate);
                        }}
                        open={openEndDatePicker}
                        onOpen={() => setOpenEndDatePicker(true)}
                        onClose={() => setOpenEndDatePicker(false)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onClick={() => setOpenEndDatePicker(true)}
                            />
                        )}
                        renderDay={renderDay}
                        InputAdornmentProps={{
                            style: { display: "none" },
                        }}
                    />
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default DateRangePicker;
