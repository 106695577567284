import React from "react";
import RoundedButton from "./RoundedButton";

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = ({ url }) => {
    async function handleLogin() {
        // console.log({ a: process.env.REACT_APP_LOGIN_URL });

        await window.location.replace(
            process.env.REACT_APP_LOGIN_URL ?? url // "https://arosuite.com"
        );
    }
    return (
        <RoundedButton long={4} onClick={() => handleLogin()}>
            Arosuite Login
        </RoundedButton>
    );
};
