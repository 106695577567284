import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";

const getRandomSpeed = (speed) => {
    const variation = (Math.random() - 0.5) * 200;
    return speed + variation;
};

const ProgressBarSteps = ({
    speed = 1000, //Every 1s there is an step
    steps = [5, 10, 20, 30, 35, 60, 70, 80, 90, 95, 100],
}) => {
    const [progress, setProgress] = useState(0);
    const [n, setN] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (n < steps.length) {
                setProgress(steps[n]);
                setN(n + 1);
            } else {
                clearInterval(interval);
            }
        }, getRandomSpeed(speed));

        return () => clearInterval(interval);
    }, [n, steps]);

    return (
        <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
                "& .MuiLinearProgress-bar": {
                    transitionDuration: `${getRandomSpeed(speed) - 50}ms`,
                },
            }}
        />
    );
};

export default ProgressBarSteps;
