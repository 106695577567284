import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    createTheme,
    useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Axios from "axios";
import Fuse from "fuse.js";

import { DEFAULT_HOTEL, HOTELS_INFO, URLAPI } from "../../configuration";
import { setHotelID } from "../../actions/hotelID";

import Colours from "../../helpers/colours";

import GoBack from "../GoBack";
import SearchButtonAndField from "../SearchButtonAndField";
import CircleValue from "../CircleValue";
import Icons from "../../helpers/icons";
import OptionsButtonDialog from "../OptionsButtonDialog";

// TODO: Data retrieval

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}
const HOTELS_DATA = Object.keys(HOTELS_INFO).reduce((acc_obj, hotel_id) => {
    acc_obj[hotel_id] = {
        name: HOTELS_INFO[hotel_id].name,
        score: getRandomIntInclusive(30, 100),
        actionableItems: getRandomIntInclusive(0, 70),
        tasksCompleted: getRandomIntInclusive(0, 100),
    };
    return acc_obj;
}, {});

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 400,
            sm: 600,
            md: 1050,
        },
    },
});

const StyledStack = styled(Stack)(({ collapse, theme }) => ({
    backgroundColor: Colours.tableRowBackground,
    borderRadius: !collapse ? "50px" : "20px",
    "& .MuiButtonBase-root": {
        borderColor: Colours.tableRowBackground,
        color: "black",
        borderRadius: "50px",
        "&:hover": {
            backgroundColor: Colours.secondarySoft,
        },
        textTransform: "none",
    },
    "& .Mui-selected": {
        backgroundColor: Colours.secondary,
        color: "black",
    },
}));

// Search the hotels
const IndicatorsSearchHotel = ({ basePath }) => {
    const downMD = useMediaQuery(theme.breakpoints.down("md"));
    const downSM = useMediaQuery(theme.breakpoints.down("sm"));
    const downXS = useMediaQuery(theme.breakpoints.down("xs"));

    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [query, setQuery] = useState("");
    const [filteredHotels, setFilteredHotels] = useState([]);
    const [transformedHotels, setTransformedHotels] = useState([]);

    const [managerHotels, setManagerHotels] = useState([]);
    const [managerView, setManagerView] = useState(false);

    const [expanded, setExpanded] = useState(false);

    const [orderAsc, setOrderAsc] = useState(true);
    const [orderBy, setOrderBy] = useState("name");
    const [sortedHotels, setSortedHotels] = useState([]);

    const getAuxHotel = (hotel_id) => {
        let auxHotel = {};
        auxHotel.id = hotel_id;
        auxHotel.name = HOTELS_DATA[hotel_id].name;
        auxHotel.score = HOTELS_DATA[hotel_id].score;
        auxHotel.actionableItems = HOTELS_DATA[hotel_id].actionableItems;
        auxHotel.tasksCompleted = HOTELS_DATA[hotel_id].tasksCompleted;
        return auxHotel;
    };

    useEffect(() => {
        let auxTransformedHotels = [];

        for (const hotel_id in HOTELS_DATA) {
            let auxHotel = getAuxHotel(hotel_id);
            auxTransformedHotels.push(auxHotel);
        }
        setTransformedHotels(auxTransformedHotels);
    }, []);

    useEffect(() => {
        //const user_id = auth.user.user_id;
        const user_id = 4;

        Axios({
            method: "get",
            url: `${URLAPI}/hotels/managers/${user_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                const {
                    data: { data },
                } = response;

                setManagerHotels(data);
            })
            .catch((e) => {
                console.log({ error_loading: e });
            });
    }, [auth]);

    useEffect(() => {
        let auxTransformedHotels = [];
        if (managerView && managerHotels.length > 0) {
            managerHotels.forEach((hotel_id) => {
                if (HOTELS_DATA.hasOwnProperty(hotel_id)) {
                    let auxHotel = getAuxHotel(hotel_id);
                    auxTransformedHotels.push(auxHotel);
                }
            });
        } else {
            for (const hotel_id in HOTELS_DATA) {
                let auxHotel = getAuxHotel(hotel_id);
                auxTransformedHotels.push(auxHotel);
            }
        }
        setTransformedHotels(auxTransformedHotels);
    }, [managerView, managerHotels, HOTELS_DATA]);

    useEffect(() => {
        let auxFilteredHotels = [];

        if (query !== "") {
            const searchOptions = {
                threshold: 0.2,
                keys: [{ name: "name" }, { name: "id" }],
            };

            const fuseSearch = new Fuse(transformedHotels, searchOptions);

            auxFilteredHotels = fuseSearch
                .search(query)
                .map(({ item: hotel, refIndex }) => hotel);
        } else {
            auxFilteredHotels = transformedHotels;
        }
        setFilteredHotels(auxFilteredHotels);
    }, [query, transformedHotels]);

    useEffect(() => {
        let sortedData = [...filteredHotels];

        const transformId = (id) => {
            return id === "default" ? -1 : parseInt(id);
        };

        sortedData.sort((a, b) => {
            let valueA =
                orderBy === "id" ? transformId(a[orderBy]) : a[orderBy];
            let valueB =
                orderBy === "id" ? transformId(b[orderBy]) : b[orderBy];

            if (typeof valueA === "string" && typeof valueB === "string") {
                return orderAsc
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            } else {
                if (valueA < valueB) return orderAsc ? 1 : -1;
                if (valueA > valueB) return orderAsc ? -1 : 1;
            }
            return 0;
        });
        setSortedHotels(sortedData);
    }, [filteredHotels, orderBy, orderAsc]);

    const Filters = () => {
        return (
            <Stack
                direction={downMD ? "column" : "row"}
                divider={
                    <Divider
                        orientation={downMD ? "horizontal" : "vertical"}
                        flexItem
                        sx={{
                            m: 1,
                            mr: 2,
                            borderColor: Colours.navbarBottomLine,
                        }}
                    />
                }
            >
                {managerHotels.length > 0 && (
                    <FormControlLabel
                        sx={{ color: "black" }}
                        label={<Typography>My Hotels</Typography>}
                        control={
                            <Checkbox
                                checked={managerView}
                                onChange={() => setManagerView(!managerView)}
                            />
                        }
                    />
                )}
                <Stack
                    direction={downMD ? "column" : "row"}
                    spacing={2}
                    alignItems={downMD ? "left" : "center"}
                >
                    <Typography display="flex" sx={{ color: "black" }}>
                        Order By:
                    </Typography>
                    <StyledStack
                        collapse={downMD}
                        direction={downMD ? "column" : "row"}
                    >
                        {[
                            { label: "Name", id: "name" },
                            { label: "ID", id: "id" },
                            {
                                label: "Score",
                                id: "score",
                            },
                            {
                                label: "Items",
                                id: "actionableItems",
                            },
                            { label: "Completed", id: "tasksCompleted" },
                        ].map((button) => {
                            return (
                                <IconButton
                                    sx={{
                                        minWidth: downMD ? "8em" : "",
                                        backgroundColor:
                                            orderBy === button.id
                                                ? Colours.primary
                                                : "",
                                        pl: 2,
                                        pr: 2,
                                    }}
                                    onClick={() => {
                                        if (orderBy === button.id) {
                                            setOrderAsc(!orderAsc);
                                        } else {
                                            setOrderBy(button.id);
                                            setOrderAsc(true);
                                        }
                                    }}
                                >
                                    <Typography>{button.label}</Typography>
                                    {orderBy === button.id &&
                                        (orderAsc ? (
                                            <Icons.ArrowFullUp
                                                sx={{ fontSize: "0.8em" }}
                                            />
                                        ) : (
                                            <Icons.ArrowFullDown
                                                sx={{ fontSize: "0.8em" }}
                                            />
                                        ))}
                                </IconButton>
                            );
                        })}
                    </StyledStack>
                </Stack>
            </Stack>
        );
    };

    return (
        <>
            <GoBack to={basePath} />
            <Paper sx={{ padding: 2 }}>
                <Stack
                    direction={"column"}
                    sx={{
                        minHeight: "400px",
                    }}
                >
                    <Stack
                        justifyContent="space-between"
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                        sx={{ pl: 2, pr: 2 }}
                    >
                        <SearchButtonAndField
                            setQuery={setQuery}
                            query={query}
                        />
                        {downMD ? (
                            <OptionsButtonDialog Icon={Icons.Filter}>
                                <Filters />
                            </OptionsButtonDialog>
                        ) : (
                            <Filters />
                        )}
                    </Stack>
                    <TableContainer
                        display={"flex"}
                        sx={{ minWidth: "100%" }}
                        style={{
                            overflow: "auto",
                            maxHeight: expanded
                                ? ""
                                : downXS
                                ? "450px"
                                : "600px",
                        }}
                    >
                        <Table
                            sx={{
                                "& .MuiTableCell-head": {
                                    textAlign: "center",
                                },
                                "& .MuiTableCell-body": {
                                    textAlign: "center",
                                    paddingX: "1em",
                                },
                            }}
                        >
                            <TableBody sx={{ padding: 0 }}>
                                {sortedHotels.length > 0 ? (
                                    sortedHotels.map((hotel) => {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={2}
                                                    sx={{ padding: 1 }}
                                                >
                                                    <Paper
                                                        component={Stack}
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        p={2}
                                                        onClick={() => {
                                                            dispatch(
                                                                setHotelID(
                                                                    hotel.id
                                                                )
                                                            );
                                                            navigate(basePath);
                                                        }}
                                                        sx={{
                                                            cursor: "pointer",
                                                            transition:
                                                                "box-shadow 0.3s",
                                                            "&:hover": {
                                                                boxShadow:
                                                                    "0px 5px 15px rgba(0, 0, 0, 0.3)",
                                                            },
                                                        }}
                                                    >
                                                        <Stack
                                                            direction={"row"}
                                                            display={"flex"}
                                                            spacing={2}
                                                            alignItems={
                                                                "center"
                                                            }
                                                        >
                                                            <CircleValue
                                                                value={
                                                                    hotel.score
                                                                }
                                                                color={
                                                                    hotel.score >=
                                                                    75
                                                                        ? Colours.trafficLightGreen
                                                                        : hotel.score <
                                                                              75 &&
                                                                          hotel.score >=
                                                                              50
                                                                        ? Colours.trafficLightYellow
                                                                        : Colours.trafficLightRed
                                                                }
                                                                size="big"
                                                            />

                                                            <Stack
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                width={"100%"}
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    textAlign={
                                                                        "left"
                                                                    }
                                                                    fontWeight={
                                                                        "bold"
                                                                    }
                                                                    gutterBottom
                                                                >
                                                                    {hotel.name}
                                                                </Typography>

                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Typography
                                                                        variant="caption"
                                                                        gutterBottom
                                                                    >
                                                                        {hotel.tasksCompleted !==
                                                                        100
                                                                            ? hotel.tasksCompleted +
                                                                              "% completed"
                                                                            : "All tasks completed!"}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="caption"
                                                                        gutterBottom
                                                                    >
                                                                        {hotel.actionableItems +
                                                                            " Actionable Items"}
                                                                    </Typography>
                                                                </Stack>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={
                                                                        hotel.tasksCompleted
                                                                    }
                                                                    sx={{
                                                                        color: (
                                                                            theme
                                                                        ) =>
                                                                            theme
                                                                                .palette
                                                                                .grey[
                                                                                theme
                                                                                    .palette
                                                                                    .mode ===
                                                                                "light"
                                                                                    ? 200
                                                                                    : 800
                                                                            ],
                                                                    }}
                                                                />
                                                                <Box />
                                                            </Stack>
                                                        </Stack>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell>{"No results"}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {((downSM && !downXS && sortedHotels.length > 4) ||
                        (downXS && sortedHotels.length > 3)) && (
                        <Box sx={{ p: 2 }}>
                            <Typography
                                variant={"subtitle2"}
                                textAlign="center"
                                alignSelf={"center"}
                                sx={{
                                    cursor: "pointer",
                                    color: Colours.secondary,
                                }}
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                            >
                                {expanded ? "See less" : "See all"}
                            </Typography>
                        </Box>
                    )}
                </Stack>
            </Paper>
        </>
    );
};

export default IndicatorsSearchHotel;
