import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";
import {
    Box,
    Button,
    Container,
    IconButton,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";

import TableHeaderCell from "../components/TableHeaderCell";

import { DEFAULT_HOTEL, URLAPI } from "../configuration";
import Icons from "../helpers/icons";
import { valuePost } from "../helpers/indicators";
import Colours from "../helpers/colours";

const METRICS = {
    Class: { data: "Hotel", key: "rating", type: "value" },
    ADR: { data: "Month", key: "ADR", type: "list", get: "lastDate" },
    "Stay Duration": {
        data: "Reservations",
        key: "StayDurationAVG",
        type: "list",
        get: "lastDate",
    },
    "Modify my booking": {
        data: "Hotel",
        key: "modify_my_booking",
        type: "value",
    },
    "Monthly budget cap for marketing campaigns": {
        data: "Month",
        key: "CapMarketingMonth",
        type: "list",
        get: "lastDate",
    },
    "Booking Lead Time": {
        data: "Reservations",
        key: "BookingLeadTimeAVG",
        type: "list",
        get: "lastDate",
    },
};

const Indicators2Screen = () => {
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);
    const [reload, setReload] = useState(0);

    const [openModalNewValue, setOpenModalNewValue] = useState(null);
    const [newValue, setNewValue] = useState(null);

    const [indicatorsData, setIndicatorsData] = useState([]);

    const [indicatorsMonth, setIndicatorsMonth] = useState([]);
    const [indicatorsReservations, setIndicatorsReservations] = useState([]);
    const [indicatorsHotel, setIndicatorsHotel] = useState([]);

    const getValue = (k) => {
        if (
            Object.keys(indicatorsHotel).length > 0 &&
            indicatorsMonth.length > 0 &&
            indicatorsReservations.length > 0
        ) {
            if (!METRICS[k]) return "no metrics";
            const { data: dataK, key, type, get } = METRICS[k];
            const data =
                dataK === "Month"
                    ? indicatorsMonth
                    : dataK === "Hotel"
                    ? indicatorsHotel
                    : dataK === "Reservations"
                    ? indicatorsReservations
                    : null;
            if (!data) return "no data";

            switch (type) {
                case "list":
                    switch (get) {
                        case "lastDate":
                            return (data ?? []).reduce(
                                (acc, obj) =>
                                    new Date(obj.ResDate) >
                                        new Date(acc.ResDate) && obj[key]
                                        ? obj
                                        : acc,
                                {
                                    ResDate: new Date(1900, 1, 1),
                                    [key]: "empty",
                                }
                            )[key];

                        default:
                            return "other list";
                            break;
                    }
                    break;
                case "value":
                    return data[key];
                    break;
                default:
                    return "default";
                    break;
            }
        } else return "not initialized";
    };

    useEffect(() => {
        Axios({
            method: "get",
            url: `${URLAPI}/indicators/get/hotel/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        }).then((response) => {
            setIndicatorsData(response.data.data);
            console.log({ id: response.data.data });
        });
    }, [hotelID, reload]);

    useEffect(() => {
        [
            { endpoint: "monthly", f: setIndicatorsMonth },
            { endpoint: "reservations", f: setIndicatorsReservations },
            { endpoint: "hotel", f: setIndicatorsHotel },
        ].map((e) => {
            Axios({
                method: "get",
                url: `${URLAPI}/indicators/${e.endpoint}/${
                    hotelID === "default" ? DEFAULT_HOTEL : hotelID
                }`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
            }).then((response) => {
                e.f(response.data.data);
                console.log({ [e.endpoint]: response.data.data });
            });
        });
    }, [hotelID, reload]);

    const [valuesProcessed, setValuesProcessed] = useState({});
    useEffect(() => {
        if (
            Object.keys(indicatorsHotel).length > 0 &&
            indicatorsMonth.length > 0 &&
            indicatorsReservations.length > 0 &&
            indicatorsData.length > 0
        ) {
            let aux = {};
            indicatorsData.map((r) => {
                aux[r.name] = getValue(r.name);
            });
            setValuesProcessed(aux);
        }
    }, [
        indicatorsHotel,
        indicatorsMonth,
        indicatorsReservations,
        indicatorsData,
        hotelID,
        reload,
    ]);

    return (
        <>
            <Container maxWidth={false}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <h1>Indicators2</h1>
                    <IconButton
                        onClick={() => {
                            setReload((a) => a + 1);
                        }}
                    >
                        <Icons.Refresh />
                    </IconButton>
                </Stack>
                <br />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Category</TableHeaderCell>
                            <TableHeaderCell>Group</TableHeaderCell>
                            <TableHeaderCell>Update</TableHeaderCell>
                            <TableHeaderCell>Report</TableHeaderCell>
                            <TableHeaderCell>Last Value</TableHeaderCell>
                            <TableCell
                            // style={{ width: "auto" }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {indicatorsData.map((r) => (
                            <TableRow
                                key={r.id}
                                sx={{
                                    background:
                                        r.frequency_update !== "AUTO" &&
                                        r.frequency_update
                                            ? Colours.secondarySoft
                                            : null,
                                }}
                            >
                                <TableCell>{r.name}</TableCell>
                                <TableCell>{r.category}</TableCell>
                                <TableCell>{r.subcategory}</TableCell>
                                <TableCell>{r.frequency_update}</TableCell>
                                <TableCell>{r.report_type}</TableCell>
                                <TableCell>{valuesProcessed[r.name]}</TableCell>
                                <TableCell

                                // style={{ width: "auto" }}
                                >
                                    {r.frequency_update !== "AUTO" ? (
                                        <IconButton
                                            onClick={() =>
                                                setOpenModalNewValue(r)
                                            }
                                        >
                                            <Icons.ArrowRight />
                                        </IconButton>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>
            <br />
            <br />
            <br />
            <Modal
                open={openModalNewValue}
                onClose={() => setOpenModalNewValue(null)}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    component={Paper}
                    sx={{
                        p: 5,
                        // width: 400,
                    }}
                >
                    {openModalNewValue && (
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography>
                                Add a new value to{" "}
                                <strong>{openModalNewValue.name}</strong>
                            </Typography>
                            <TextField
                                fullWidth
                                label="New value"
                                onChange={(e) => {
                                    setNewValue(e.target.value);
                                }}
                            />
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    valuePost(
                                        auth,
                                        openModalNewValue.id,
                                        openModalNewValue.type,
                                        "",
                                        newValue,
                                        hotelID
                                    ).then((r) => {
                                        console.log({ r });
                                        setOpenModalNewValue(null);
                                        setNewValue(null);
                                        setReload((a) => a + 1);
                                    });
                                }}
                            >
                                SAVE
                            </Button>
                        </Stack>
                    )}
                </Box>
            </Modal>
        </>
    );
};
export default Indicators2Screen;
