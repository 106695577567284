import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { styled, TableCell } from "@mui/material";

const TableBodyCell_Readability = styled(TableCell)({
    fontWeight: "bold",
});

const TableBodyCell = (props) => {
    const {
        status,
        settings: { readability = false },
    } = useSelector((state) => state.settings);

    const [ret, setRet] = useState(<TableCell {...props} />);

    useEffect(() => {
        if (readability === true || readability === "True")
            setRet(<TableBodyCell_Readability {...props} />);
        else setRet(<TableCell {...props} />);
    }, [readability, props]);

    return ret;
};

export default TableBodyCell;
