import React from "react";

import { Box, TableCell, TableRow } from "@mui/material";

const NoDataRow = ({ text = "No data" }) => {
    return (
        <TableRow>
            <TableCell colSpan={100} align="center">
                <Box component="span" fontStyle="italic">
                    {text}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default NoDataRow;
