import React, { useEffect, useState } from "react";

import {
    Typography,
    Paper,
    Box,
    Table,
    TableBody,
    TableRow,
    TablePagination,
    TableContainer,
    useTheme,
    useMediaQuery,
    Container,
    Stack,
} from "@mui/material";

import Fuse from "fuse.js";

import TableHeadSort from "../TableHeadSort";
import TableBodyCell from "../TableBodyCell";
import IndicatorForm from "./IndicatorForm";
import CircleValue from "../CircleValue";
import SearchButtonAndField from "../SearchButtonAndField";

import Colours from "../../helpers/colours";
import {
    TYPES,
    PRIORITY,
    QUALITY_PERFORMANCE,
    STATUS,
    SUBCATEGORY,
} from "../../helpers/indicators";
import { colourTypeRange } from "./IndicatorsTypeRange";
import { colourTypePercentage } from "./IndicatorsTypePercentage";
import { colourTypeYesNo } from "./IndicatorsTypeYesNo";
import FilterDropdown, { FilterChips } from "../FiltersDropdown";

const transformFilters = (filter) => {
    return Object.values(filter).reduce((acc, obj) => {
        acc[obj.key] = obj.label;
        return acc;
    }, {});
};

const IndicatorTable = ({ data, rawData, handleRefreshIndicators }) => {
    const theme = useTheme();
    const downSM = useMediaQuery(theme.breakpoints.down("sm"));
    const downMD = useMediaQuery(theme.breakpoints.down("md"));

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("indicator_perf_weighting");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [expanded, setExpanded] = React.useState(false);

    const [dataSorted, setDataSorted] = useState([]);

    const [openIndicatorsForm, setOpenIndicatorsForm] = useState(false);
    const [indicatorClicked, setIndicatorClicked] = useState({});
    useEffect(() => {
        if (!openIndicatorsForm) {
            setIndicatorClicked({});
        }
    }, [openIndicatorsForm]);

    const handleExpand = () => {
        if (!expanded) {
            setRowsPerPage(dataSorted.length);
            setExpanded(true);
        } else {
            setRowsPerPage(5);
            setExpanded(false);
        }
    };

    const [query, setQuery] = useState("");
    const [activeFilters, setActiveFilters] = useState({});
    const [searchedIndicators, setSearchedIndicators] = useState([]); // After search and filters

    const FILTERS = {
        subcategory: {
            label: "Subcategory",
            options: transformFilters(SUBCATEGORY),
        },
        type: { label: "Type", options: transformFilters(TYPES) },
        status: { label: "Status", options: transformFilters(STATUS) },
        priority: { label: "Priority", options: transformFilters(PRIORITY) },
        quality_performance: {
            label: "Quality/Performance",
            options: transformFilters(QUALITY_PERFORMANCE),
        },
    };

    useEffect(() => {
        console.log({ data, rawData });
        setPage(0);
        setRowsPerPage(5);
        let auxSearchedIndicators = [];
        if (data.length > 0) {
            if (query !== "") {
                const searchOptions = {
                    threshold: 0.2,
                    keys: [
                        { name: "name" },
                        { name: "type" },
                        { name: "subcategory" },
                        { name: "ts" },
                        { name: "quality_performance" },
                        { name: "status" },
                        { name: "priority" },
                    ],
                };

                const fuseSearch = new Fuse(data, searchOptions);

                // Applies search text query
                auxSearchedIndicators = fuseSearch
                    .search(query)
                    .map(({ item: indicator, refIndex }) => indicator);
            } else {
                auxSearchedIndicators = data;
            }

            // Applies filters if any
            if (
                !Object.values(activeFilters).every(
                    (filters) => filters.length === 0
                )
            ) {
                auxSearchedIndicators = auxSearchedIndicators.filter(
                    (indicator) => {
                        let includeIndicator = Object.entries(
                            activeFilters
                        ).every(([key, filters]) => {
                            let isIncluded = true;
                            if (filters.length > 0) {
                                isIncluded = filters.includes(indicator[key]);
                            }

                            return isIncluded;
                        });
                        return includeIndicator;
                    }
                );
            }
        }
        setSearchedIndicators(auxSearchedIndicators);
        // Returns to initial page if any search or filter has been applied
    }, [query, activeFilters, data]);

    useEffect(() => {
        let sortedData = [];
        if (searchedIndicators.length > 0) {
            sortedData = [...searchedIndicators];

            sortedData.sort((a, b) => {
                let valueA = a[orderBy];
                let valueB = b[orderBy];

                if (valueA < valueB) {
                    return order === "desc" ? 1 : -1;
                }
                if (valueA > valueB) {
                    return order === "desc" ? -1 : 1;
                }
                return 0;
            });
        }
        // Applies sorting
        setDataSorted(sortedData);
    }, [searchedIndicators, orderBy, order]);

    const HEADER = [
        {
            id: "score",
            label: "Score",
        },
        {
            id: "name",
            label: "Name",
        },
        {
            id: "status",
            label: "Status",
            styles: {
                display: downSM ? "none" : "",
            },
        },
        {
            id: "priority",
            label: "Priority",
            styles: {
                display: downSM ? "none" : "",
            },
        },
    ];

    return (
        <Container component={Paper} sx={{ paddingTop: 2 }}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <SearchButtonAndField setQuery={setQuery} query={query} />

                <FilterDropdown
                    filters={FILTERS}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    downMD={downMD}
                    downSM={downSM}
                />
                <FilterChips
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    filters={FILTERS}
                />
            </Stack>
            <TableContainer style={{ overflow: "auto", maxHeight: "600px" }}>
                <Table
                    sx={{
                        "& .MuiTableCell-head": {
                            textAlign: "left",
                        },
                        "& .MuiTableCell-body": {
                            textAlign: "left",
                            paddingX: "1em",
                        },
                        marginTop: dataSorted.length > 1 ? "1em" : "0em",
                    }}
                >
                    <TableHeadSort
                        stickyHeads={theme.breakpoints.down("md") ? 2 : 0}
                        headers={HEADER.slice(0, 4)}
                        order={order}
                        orderBy={orderBy}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                    />
                    <TableBody>
                        {dataSorted
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((element) => {
                                return (
                                    <TableRow
                                        key={`${element.subcategory}-${element.name}`}
                                        onClick={() => {
                                            setOpenIndicatorsForm(true);
                                            setIndicatorClicked(element);
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                            transition: "box-shadow 0.3s",
                                            "&:hover": {
                                                boxShadow:
                                                    "0px 5px 15px rgba(0, 0, 0, 0.3)",
                                            },
                                        }}
                                    >
                                        <TableBodyCell>
                                            <CircleValue
                                                // value={5}
                                                value={
                                                    element.type ===
                                                    TYPES.YESNO.key
                                                        ? element.value
                                                            ? "Y"
                                                            : "N"
                                                        : element.value || 0
                                                }
                                                color={
                                                    element.type ===
                                                    TYPES.RANGE.key
                                                        ? colourTypeRange(
                                                              element.value,
                                                              element.max,
                                                              element.min
                                                          )
                                                        : element.type ===
                                                          TYPES.PERCENTAGE.key
                                                        ? colourTypePercentage(
                                                              element.value,
                                                              element.reference
                                                          )
                                                        : element.type ===
                                                          TYPES.YESNO.key
                                                        ? colourTypeYesNo(
                                                              element.value
                                                          )
                                                        : null
                                                }
                                            />
                                        </TableBodyCell>
                                        <TableBodyCell>
                                            {element.name}
                                        </TableBodyCell>
                                        {!downSM && (
                                            <>
                                                <TableBodyCell>
                                                    {element.status}
                                                </TableBodyCell>
                                                <TableBodyCell>
                                                    {element.priority}
                                                </TableBodyCell>
                                            </>
                                        )}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    {!downSM && dataSorted.length > rowsPerPage && (
                        <TablePagination
                            rowsPerPageOptions={[5]}
                            count={dataSorted.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => {
                                setPage(newPage);
                            }}
                            sx={{
                                borderBottom: "none",
                            }}
                        />
                    )}
                </Table>
                {downSM && data.length > 5 && (
                    <Box sx={{ p: 2 }}>
                        <Typography
                            variant={"subtitle2"}
                            textAlign="center"
                            alignSelf={"center"}
                            sx={{
                                cursor: "pointer",
                                color: Colours.secondary,
                            }}
                            onClick={() => {
                                handleExpand();
                            }}
                        >
                            {expanded ? "See less" : "See all"}
                        </Typography>
                    </Box>
                )}
            </TableContainer>
            {openIndicatorsForm && (
                <IndicatorForm
                    setOpen={setOpenIndicatorsForm}
                    open={openIndicatorsForm}
                    indicatorDataRaw={rawData[indicatorClicked.id ?? 0] ?? {}}
                    indicatorDataProcessed={indicatorClicked}
                    handleRefreshIndicators={handleRefreshIndicators}
                />
            )}
        </Container>
    );
};

export default IndicatorTable;
