import { dataMembershipTypes } from "../types/dataMembershipTypes";

export const loading = (hotelID) => {
    return {
        type: dataMembershipTypes.loading,
        hotelID,
    };
};
export const partialLoad = (data, hotelID) => {
    return {
        type: dataMembershipTypes.partialLoad,
        payload: data,
        hotelID,
    };
};
export const load = (data, hotelID) => {
    return {
        type: dataMembershipTypes.load,
        payload: data,
        hotelID,
    };
};
export const addYear = (hotelID, year) => {
    return {
        type: dataMembershipTypes.addYear,
        hotelID,
        year,
    };
};
export const error = (error, data) => {
    return {
        type: dataMembershipTypes.error,
        payload: data,
        error: error,
    };
};
