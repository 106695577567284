import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import EChartsReact from "echarts-for-react";
import {
    Box,
    Grid,
    MenuItem,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import WrapperPDFTable from "../shared/WrapperPDFTable";

import SwitchIcons, { BAR_MODE, LINE_MODE } from "./SwitchIcons";
import TableHeaderCell from "../TableHeaderCell";

import Colours from "../../helpers/colours";
import {
    getYearsBefore,
    monthToQuarter,
    quarterToMonths,
} from "../../helpers/dates";
import { months } from "../../helpers/dates";
import { round } from "../../helpers/reports";
import { tooltip } from "../../helpers/eCharts";
import { fns } from "../../helpers/common";

const cero = (deg) => ("0" + deg).slice(-2);

const RevenueReport = ({}) => {
    const TITLE = "Revenue";
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);

    const { dataSimple } = useSelector((state) => state);
    const [showData, setShowData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [graphMode, setGraphMode] = useState(LINE_MODE);

    const [zoomInit, setZoomInit] = useState("");
    const [zoomEnd, setZoomEnd] = useState("");
    const [markPoints, setMarkPoints] = useState([]);

    const [optionsSelectQuarter, setOptionsSelectQuarter] = useState(
        getYearsBefore()
            .reverse()
            .map((y) => [`Q1_${y}`, `Q2_${y}`, `Q3_${y}`, `Q4_${y}`])
            .flat()
    );
    const [quarterSelected, setQuarterSelected] = useState(
        `Q${monthToQuarter(
            new Date().getUTCMonth() + 1
        )}_${new Date().getUTCFullYear()}`
    );

    // Get data
    useEffect(() => {
        setLoaded(false);

        if (!(dataSimple.data ?? {}).hasOwnProperty("MonthSummary")) {
            return;
        }

        let data = dataSimple.data["MonthSummary"];

        let aux = {};
        let years_with_data = {};
        data.forEach((element) => {
            const {
                year_v,
                month_v,
                Revenue: revenue_value,
                Revenue_predictions: projection,
                Revenue_target_value: target,
            } = element;

            if (target || revenue_value || projection) {
                aux[`${year_v}_${cero(month_v)}`] = {
                    target: target ? round(target) : "-",
                    sold: revenue_value ? round(revenue_value) : "-",
                    projection: projection ? round(projection) : "-",
                };
                years_with_data[year_v] = true;
            }
        });

        setShowData(aux);

        // Calculate Quartes with Data
        setOptionsSelectQuarter(
            Array.from(
                new Set(
                    Object.keys(aux).map(
                        (k) =>
                            //[monthToQuarter(Number(k.slice(5))), k]
                            `Q${Number(
                                monthToQuarter(Number(k.slice(5)))
                            )}_${k.slice(0, 4)}` //TODO:
                    )
                )
            )
        );

        setLoaded(true);
    }, [dataSimple]);

    useEffect(() => {
        if (quarterSelected === "Custom") {
            let startIndex = Object.keys(showData).indexOf(zoomInit);
            let endIndex = Object.keys(showData).indexOf(zoomEnd);
            let customMarkPoints = Object.keys(showData).filter(
                (y_m, index) => index >= startIndex && index <= endIndex
            );
            setMarkPoints(customMarkPoints);
        } else {
            // Move zoom
            let q = quarterSelected.slice(1, 2);
            let y = quarterSelected.slice(3);
            let init = new Date(y, 0, 15).toISOString().slice(0, 7);
            let end = new Date(y, 11, 15).toISOString().slice(0, 7);
            setZoomInit(init.replace("-", "_"));
            setZoomEnd(end.replace("-", "_"));

            // MarkPoints
            setMarkPoints(
                quarterToMonths(Number(q)).map((m) => `${y}_${cero(m)}`)
            );
        }
    }, [quarterSelected]);

    // Custom dates with Slider Range selected
    const onZoomChange = (params) => {
        const init =
            Object.keys(showData)[
                Math.round(
                    (params.start * (Object.keys(showData).length - 1)) / 100
                )
            ];
        const end =
            Object.keys(showData)[
                Math.round(
                    (params.end * (Object.keys(showData).length - 1)) / 100
                )
            ];
        if (init !== zoomInit || end !== zoomEnd) {
            // Update markPoints and Table Values
            let startIndex = Object.keys(showData).indexOf(init);
            let endIndex = Object.keys(showData).indexOf(end);
            let customMarkPoints = Object.keys(showData).filter(
                (y_m, index) => index >= startIndex && index <= endIndex
            );
            setMarkPoints(customMarkPoints);

            setZoomInit(init);
            setZoomEnd(end);
        }
    };

    // Timer for slider selection
    let updateTableTimer;

    return (
        <>
            {!loaded ? (
                <>
                    {" "}
                    <ProgressBarSteps /> <br />
                </>
            ) : (
                <></>
            )}

            <Stack direction="column" height={"100%"}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography>
                        <Box
                            component="span"
                            fontWeight={"bold"}
                            sx={{ display: "inline" }}
                            mr={1}
                        >
                            Revenue
                        </Box>
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <SwitchIcons
                            selectedMode={graphMode}
                            modes={[LINE_MODE, BAR_MODE]}
                            setGraphMode={setGraphMode}
                        />
                        <TextField
                            // overfl
                            select
                            value={quarterSelected ?? ""}
                            onChange={(e) => {
                                setQuarterSelected(e.target.value);
                            }}
                            size="small"
                            sx={{
                                backgroundColor: Colours.secondarySoft,
                                borderRadius: 1000,
                                "& .MuiOutlinedInput-root": {
                                    fontWeight: "bold",
                                    borderRadius: 1000,
                                    "& fieldset": {
                                        borderColor: "transparent",
                                    },
                                },
                            }}
                        >
                            <MenuItem
                                key={"Custom"}
                                value={"Custom"}
                                divider={true}
                            >
                                {`Slider \n  Range`}
                            </MenuItem>
                            {optionsSelectQuarter.map((element) => (
                                <MenuItem key={element} value={element}>
                                    {element.replace("_", " ")}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                </Stack>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <WrapperPDFTable k={TITLE + " values"}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {showData && (
                                            <TableHeaderCell
                                                colSpan={2}
                                                align="center"
                                            >
                                                {`from ${(
                                                    markPoints[0] ?? ""
                                                ).replace("_", "/")} to ${(
                                                    markPoints[
                                                        markPoints.length - 1
                                                    ] ?? ""
                                                ).replace("_", "/")}`}
                                            </TableHeaderCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>
                                            Sold Quarter selected
                                        </TableCell>
                                        <TableCell>
                                            {loaded ? (
                                                <>
                                                    {fns(
                                                        hotelID,
                                                        round(
                                                            markPoints

                                                                .map(
                                                                    (y_m) =>
                                                                        showData[
                                                                            y_m
                                                                        ] ?? []
                                                                )
                                                                .reduce(
                                                                    (
                                                                        acc,
                                                                        month
                                                                    ) =>
                                                                        acc +
                                                                        (!isNaN(
                                                                            month.sold
                                                                        )
                                                                            ? month.sold
                                                                            : 0),
                                                                    0
                                                                ),
                                                            0
                                                        ),
                                                        { left: "currency" }
                                                    )}
                                                </>
                                            ) : (
                                                <Skeleton
                                                    variant="text"
                                                    width={50}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>
                                            Target Quarter selected
                                        </TableCell>
                                        <TableCell>
                                            {loaded ? (
                                                <>
                                                    {fns(
                                                        hotelID,
                                                        round(
                                                            markPoints

                                                                .map(
                                                                    (y_m) =>
                                                                        showData[
                                                                            y_m
                                                                        ] ?? []
                                                                )
                                                                .reduce(
                                                                    (
                                                                        acc,
                                                                        month
                                                                    ) =>
                                                                        acc +
                                                                        (!isNaN(
                                                                            month.target
                                                                        )
                                                                            ? month.target
                                                                            : 0),
                                                                    0
                                                                ),
                                                            0
                                                        ),
                                                        { left: "currency" }
                                                    )}
                                                </>
                                            ) : (
                                                <Skeleton
                                                    variant="text"
                                                    width={50}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </WrapperPDFTable>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={9}
                        sx={{ marginLeft: isMobile ? -3 : 0 }}
                    >
                        {loaded ? (
                            <WrapperPDFEChart k={TITLE}>
                                <EChartsReact
                                    style={{
                                        width: isMobile ? "130%" : "100%",
                                    }}
                                    option={{
                                        tooltip: tooltip,
                                        legend: {},
                                        xAxis: {
                                            type: "category",
                                            data: Object.keys(showData),
                                            triggerEvent: true,
                                            axisLabel: {
                                                formatter: (v) => {
                                                    return months[
                                                        Number(v.slice(-2)) - 1
                                                    ].slice(0, 3);
                                                },
                                            },
                                        },
                                        yAxis: {
                                            axisLabel: {
                                                formatter: function (value) {
                                                    if (value >= 1000) {
                                                        return (
                                                            (
                                                                value / 1000
                                                            ).toFixed(0) + "K"
                                                        );
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                rotate: isMobile ? 55 : 0,
                                            },
                                        },
                                        dataZoom: [
                                            {
                                                type: "slider",
                                                startValue: zoomInit,
                                                endValue: zoomEnd,
                                            },
                                            {
                                                start: 0,
                                                end: 10,
                                            },
                                        ],
                                        series: [
                                            {
                                                type: graphMode.type,
                                                smooth: true,
                                                data: Object.values(
                                                    showData
                                                ).map((e) => e.sold ?? "-"),
                                                name: "Revenue",
                                                symbol: "none",
                                                itemStyle: {
                                                    color: Colours.graphSold,
                                                },
                                                markPoint: {
                                                    data: markPoints.map(
                                                        (mp) => ({
                                                            coord: [
                                                                mp,
                                                                (
                                                                    showData[
                                                                        mp
                                                                    ] ?? {}
                                                                ).sold ?? "-",
                                                            ],
                                                        })
                                                    ),
                                                    symbol: "circle",
                                                    symbolSize: 8,
                                                },
                                            },
                                            {
                                                type: graphMode.type,
                                                smooth: true,
                                                data: Object.values(
                                                    showData
                                                ).map((e) => e.target ?? "-"),
                                                name: "Target",
                                                symbol: "none",
                                                itemStyle: {
                                                    color: Colours.graphTarget,
                                                },
                                                markPoint: {
                                                    data: markPoints.map(
                                                        (mp) => ({
                                                            coord: [
                                                                mp,
                                                                (
                                                                    showData[
                                                                        mp
                                                                    ] ?? {}
                                                                ).target ?? "-",
                                                            ],
                                                        })
                                                    ),
                                                    symbol: "circle",
                                                    symbolSize: 8,
                                                },
                                            },
                                            {
                                                type: graphMode.type,
                                                smooth: true,
                                                data: Object.values(
                                                    showData
                                                ).map(
                                                    (e) => e.projection ?? "-"
                                                ),
                                                name: "Prediction",
                                                symbol: "none",
                                                itemStyle: {
                                                    color: Colours.graphProjection,
                                                },
                                                markPoint: {
                                                    data: markPoints.map(
                                                        (mp) => ({
                                                            coord: [
                                                                mp,
                                                                (
                                                                    showData[
                                                                        mp
                                                                    ] ?? {}
                                                                ).projection ??
                                                                    "-",
                                                            ],
                                                        })
                                                    ),
                                                    symbol: "circle",
                                                    symbolSize: 8,
                                                },
                                            },
                                        ],
                                    }}
                                    onEvents={{
                                        datazoom:
                                            quarterSelected === "Custom"
                                                ? (params) => {
                                                      clearTimeout(
                                                          updateTableTimer
                                                      );
                                                      updateTableTimer =
                                                          setTimeout(
                                                              function () {
                                                                  onZoomChange(
                                                                      params
                                                                  );
                                                              },
                                                              400
                                                          );
                                                  }
                                                : null,
                                    }}
                                />
                            </WrapperPDFEChart>
                        ) : (
                            <Skeleton
                                sx={{ mt: 2, marginLeft: isMobile ? -3 : 0 }}
                                variant="rounded"
                                width={isMobile ? "120%" : "100%"}
                                height={"300px"}
                            />
                        )}
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
};

export default RevenueReport;
