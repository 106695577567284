import React, { useEffect, useState } from "react";

import TabSelector from "./TabSelector";

import RevenueComparisonMonth from "./reports/RevenueComparisonMonth";
import TopDaysMonthComparison from "./reports/TopDaysMonthComparison";
import YearPerformance from "./reports/YearPerformance";

function largestTH(arr) {
    function getSuffix(number) {
        if (number % 100 >= 11 && number % 100 <= 13) {
            return "th";
        }
        switch (number % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }
    let largest = Math.max(...arr);
    let position = arr.indexOf(largest) + 1;
    return position + getSuffix(position);
}

// For the monthly email report
const MonthlyRevenue = ({ data }) => {
    const [cardSelected, setCardSelected] = useState("total");
    const [revenueData, setRevenueData] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const auxRevenueData = [
                {
                    id: "total",
                    title: "Total this month",
                    format: {
                        left: "currency",
                    },
                    value: Object.values(
                        data.reports.Revenue.tabs.revenueTotal.this
                    ).reduce((acc, val) => (acc += val), 0),
                    old: Object.values(
                        data.reports.Revenue.tabs.revenueTotal.last
                    ).reduce((acc, val) => (acc += val), 0),
                    expand: true,
                    compare: true,
                },
                {
                    id: "topSellingDate",
                    title: "Top selling date",
                    value: largestTH(
                        data.reports.Revenue.tabs.topSellingDate.thisTopDays
                    ),
                    noArrow: true,
                    expand: true,
                    compare: false,
                },
                {
                    id: "revenuePerRoomNight",
                    title: "Revenue per Room Night",
                    format: {
                        left: "currency",
                    },
                    value: data.reports.Revenue.tabs.revenuePerRoomNight.this,
                    old: data.reports.Revenue.tabs.revenuePerRoomNight.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "revenueCheckIn",
                    title: "Check In Revenue",
                    format: {
                        left: "currency",
                    },
                    value: data.reports.Revenue.tabs.revenueCheckIn.this,
                    old: data.reports.Revenue.tabs.revenueCheckIn.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "revenueMobile",
                    title: "Mobile Revenue",
                    format: {
                        left: "currency",
                    },
                    value: data.reports.Revenue.tabs.revenueMobile.this,
                    old: data.reports.Revenue.tabs.revenueMobile.last,
                    expand: true,
                    compare: true,
                },
                {
                    id: "revenueDesktop",
                    title: "Desktop Revenue ",
                    format: {
                        left: "currency",
                    },
                    value: data.reports.Revenue.tabs.revenueDesktop.this,
                    old: data.reports.Revenue.tabs.revenueDesktop.last,
                    expand: true,
                    compare: true,
                },
            ];
            setRevenueData(auxRevenueData);
        }
    }, [data]);

    return (
        <div>
            <TabSelector
                // hotelID={hotelID}
                data={revenueData}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
            />
            <br />
            {cardSelected === "total" && (
                <RevenueComparisonMonth
                    thisTitle={`${(data ?? "").month} ${(data ?? "").year}`}
                    lastTitle={`${(data ?? "").month} ${(data ?? "").year - 1}`}
                    thisRevenue={
                        data.reports.Revenue.tabs.revenueTotal.this.revenue
                    }
                    thisVouchers={
                        data.reports.Revenue.tabs.revenueTotal.this.vouchers
                    }
                    thisCancellations={
                        data.reports.Revenue.tabs.revenueTotal.this
                            .cancellations
                    }
                    lastRevenue={
                        data.reports.Revenue.tabs.revenueTotal.last.revenue
                    }
                    lastVouchers={
                        data.reports.Revenue.tabs.revenueTotal.last.vouchers
                    }
                    lastCancellations={
                        data.reports.Revenue.tabs.revenueTotal.last
                            .cancellations
                    }
                    hotel_id={data.hotel_id}
                />
            )}

            {cardSelected === "topSellingDate" && (
                <TopDaysMonthComparison
                    year={data.year}
                    month={data.month}
                    thisTopDays={
                        data.reports.Revenue.tabs.topSellingDate.thisTopDays
                    }
                    lastTopDays={
                        data.reports.Revenue.tabs.topSellingDate.lastTopDays
                    }
                    TITLE={"Top Selling Date"}
                />
            )}
            {(cardSelected === "revenuePerRoomNight" ||
                cardSelected === "revenueCheckIn" ||
                cardSelected === "revenueMobile" ||
                cardSelected === "revenueDesktop") && (
                <YearPerformance
                    dataLast={
                        data.reports.Revenue.tabs[cardSelected].historical.last
                    }
                    dataThis={
                        data.reports.Revenue.tabs[cardSelected].historical.this
                    }
                    dataTarget={
                        data.reports.Revenue.tabs[cardSelected].historical
                            .target
                    }
                />
            )}
        </div>
    );
};

export default MonthlyRevenue;
