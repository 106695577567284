import React from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

import CheckShow from "./shared/CheckShow";

import { DISPLAY_ELEMENT_HELPER } from "../helpers/filters";
import { ARO_ADMIN } from "../configuration";

const FiltersMetricSelection = ({
    metrics = DISPLAY_ELEMENT_HELPER,
    metric,
    setMetric,
}) => {
    return (
        <FormControl sx={{ mr: 1 }}>
            {/* <InputLabel id="metricLabel">Metric</InputLabel> */}
            <Select
                // labelId="metricLabel"
                id="metricSelect"
                value={metric}
                // label="Metric"
                onChange={(e, v) => {
                    setMetric(v.props.value);
                }}
            >
                {[...Object.keys(metrics)]
                    .filter((e) => metrics[e].important)
                    .map((m) => (
                        <MenuItem key={m} value={m}>
                            <Typography
                                variant="body1"
                                // style={{
                                //     fontWeight: "bold",
                                // }}
                            >
                                {metrics[m].short ?? m}
                            </Typography>
                        </MenuItem>
                    ))}
                <CheckShow rolesThatCanSee={ARO_ADMIN}>
                    {[...Object.keys(metrics)]
                        .filter((e) => !metrics[e].important)
                        .map((m) => (
                            <MenuItem key={m} value={m}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontWeight: "normal",
                                    }}
                                >
                                    {metrics[m].short ?? m}
                                </Typography>
                            </MenuItem>
                        ))}
                </CheckShow>
            </Select>
        </FormControl>
    );
};

export default FiltersMetricSelection;
