import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";

import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import EChartsReact from "echarts-for-react";

import TableHeaderCell from "../components/TableHeaderCell";
import ToggleButtonTitled from "../components/ToggleButtonTitled";
import SmallPercentage from "../components/SmallPercentage";
import DataLoader, {
    timesFirstReservations,
    timesSecondReservations,
} from "../components/DataLoader";

import Icons from "../helpers/icons";
import {
    DATATYPE_CHECKIN,
    DATATYPE_CHECKOUT,
    DATATYPE_RESDATE,
} from "../helpers/filters";
import HotelSelectorMany from "../components/HotelSelectorMany";
import { HOTELS_INFO, URLAPI } from "../configuration";

const round = (num) => Math.round(num * 100) / 100;

const EventsImpactScreen = () => {
    const [DataBy, setDataBy] = useState(DATATYPE_RESDATE);

    const [eventsData, setEventsData] = useState([]);
    const [revenue, setRevenue] = useState([
        { day: "2024-01-15", v: 1000 },
        { day: "2024-01-16", v: 1500 },
    ]);
    const [lastYearRevenue, setLastYearRevenue] = useState([
        { day: "2024-01-15", v: 1500 },
        { day: "2024-01-16", v: 1000 },
    ]);

    const { dataSimple } = useSelector((state) => state);

    const [Events, setEvents] = useState([]);
    useEffect(() => {
        if (dataSimple.status === "loaded") {
            setEvents(dataSimple.data.Events);
        }
    }, [dataSimple]);

    const { dataBookings } = useSelector((state) => state);

    useEffect(() => {
        if (dataBookings.status === "loaded") {
            const today = new Date();
            const oneYearAgo = new Date(
                today.getFullYear() - 1,
                today.getMonth(),
                today.getDate()
            );
            const twoYearAgo = new Date(
                today.getFullYear() - 2,
                today.getMonth(),
                today.getDate()
            );
            const oneYearFromNow = new Date(
                today.getFullYear() + 1,
                today.getMonth(),
                today.getDate()
            );

            let aux = dataBookings.data
                .sort((a, b) => new Date(a[DataBy]) - new Date(b[DataBy]))
                .reduce((acc, n) => {
                    if (n[DataBy]) {
                        const bookingDate = new Date(n[DataBy]);
                        if (
                            bookingDate >= oneYearAgo &&
                            bookingDate <= oneYearFromNow
                        ) {
                            let d = new Date(n[DataBy])
                                .toISOString()
                                .slice(0, 10);
                            acc[d] =
                                d in acc ? acc[d] + n.TotalPrice : n.TotalPrice;
                        }
                    }
                    return acc;
                }, {});

            const revenueData = Object.entries(aux).map(([day, value]) => ({
                day,
                v: value,
            }));

            setRevenue(revenueData);

            // Last year
            let auxLY = dataBookings.data
                .sort((a, b) => new Date(a[DataBy]) - new Date(b[DataBy]))
                .reduce((acc, n) => {
                    if (n[DataBy]) {
                        const bookingDate = new Date(n[DataBy]);
                        if (
                            bookingDate >= twoYearAgo &&
                            bookingDate <= new Date()
                        ) {
                            let d = new Date(n[DataBy])
                                .toISOString()
                                .slice(0, 10);
                            acc[d] =
                                d in acc ? acc[d] + n.TotalPrice : n.TotalPrice;
                        }
                    }
                    return acc;
                }, {});

            const revenueDataLY = Object.entries(auxLY).map(([day, value]) => ({
                day: new Date(
                    new Date(day).getUTCFullYear() + 1,
                    new Date(day).getUTCMonth(),
                    new Date(day).getUTCDate()
                )
                    .toISOString()
                    .slice(0, 10),
                v: value,
            }));

            setLastYearRevenue(revenueDataLY);
        }
    }, [dataBookings, DataBy]);

    const getAverages = (revenueData) => {
        const sums = {};
        const counts = {};
        revenueData.forEach(({ day, v }) => {
            const month = day.substring(0, 7); // "YYYY-MM"
            if (sums[month]) {
                sums[month] += v;
                counts[month] += 1;
            } else {
                sums[month] = v;
                counts[month] = 1;
            }
        });

        const averages = Object.keys(sums).map((month) => {
            return {
                month,
                average: sums[month] / counts[month],
            };
        });

        return averages;
    };

    const getMarkArea = () => ({
        itemStyle: {
            color: "rgba(255, 233, 204, 1)",
            opacity: 0.75,
        },
        data: Events.filter((e) => !eventsToHide.includes(e.Name)).map(
            (event) => {
                const startDate = new Date(event.FromDate);
                const endDate = new Date(event.ToDate || event.FromDate);
                endDate.setDate(endDate.getDate() + 1);

                return [
                    {
                        name: event.Name,
                        xAxis: startDate.toISOString().slice(0, 10),
                    },
                    {
                        xAxis: endDate.toISOString().slice(0, 10),
                    },
                ];
            }
        ),
    });
    const getOptions = () => ({
        tooltip: {
            trigger: "axis",
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            splitArea: {
                show: true,
            },
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: "Revenue",
                type: "line",
                data: revenue.map((r) => [r.day, round(r.v)]),
                smooth: false,
                symbol: "none",
                markArea: getMarkArea(),
            },
            {
                name: "Revenue one year ago",
                type: "line",
                data: lastYearRevenue.map((r) => [r.day, round(r.v)]),
                smooth: false,
                symbol: "none",
            },
            {
                name: "Monthly Average Revenue",
                type: "line",
                data: getAverages(revenue).map((avg) => [
                    `${avg.month}-15`,
                    round(avg.average),
                ]),
                smooth: true,
                symbol: "circle",
                symbolSize: 6,
                itemStyle: {
                    // color: "red",
                },
            },
        ],
        dataZoom: [
            {
                type: "inside",
            },
            {
                start: 0,
                end: 10,
            },
        ],
        legend: {},
    });

    const [eventsToHide, setEventsToHide] = useState([]);
    const handleCheckboxChange = (eventName, isChecked) => {
        setEventsToHide((prevEventsToHide) => {
            if (isChecked) {
                return prevEventsToHide.filter((event) => event !== eventName);
            } else {
                return [...prevEventsToHide, eventName];
            }
        });
    };

    const { auth } = useSelector((state) => state);
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const [hotelsSelectedMany, setHotelsSelectedMany] = useState([]);
    const [dataHotelsMany, setDataHotelsMany] = useState({});
    const [loadingCalculateData, setLoadingCalculateData] = useState(false);
    const askForData = async (hotelID_) => {
        let output = [];

        // First load
        const { fromTime, toTime } = timesFirstReservations();
        let args = JSON.stringify({
            from: fromTime.toISOString(),
            to: toTime.toISOString(),
            dateBy: "ResDate",
        });
        let response = await Axios({
            method: "post",
            url: `${URLAPI}/reservations/${hotelID_}`,
            data: args,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        });
        const {
            data: { data = [] },
        } = response;

        // Second load
        const { fromTime: fromTimeSecond, toTime: toTimeSecond } =
            timesSecondReservations();
        let argsSecond = JSON.stringify({
            from: fromTimeSecond.toISOString(),
            to: toTimeSecond.toISOString(),
            dateBy: "ResDate",
        });
        let responseSecond = await Axios({
            method: "post",
            url: `${URLAPI}/reservations/${hotelID_}`,
            data: argsSecond,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        });
        const {
            data: { data: dataSecond = [] },
        } = responseSecond;

        // Save
        output = [...data, ...dataSecond];
        return output;
    };
    const removeHours = (ts) =>
        new Date(new Date(ts).setHours(0, 0, 0, 0)).getTime();

    const [normalize, setNormalize] = useState(true);
    const processData = (data) => {
        let out = data.reduce((acc, item) => {
            const { CheckIn, CheckOut, ResDate, TotalPrice } = item;
            let checkIn = removeHours(CheckIn);
            let checkOut = removeHours(CheckOut);
            let resDate = removeHours(ResDate);
            console.log({ checkIn, checkOut, resDate });

            if (!acc[checkIn]) {
                acc[checkIn] = { CheckIn: 0, CheckOut: 0, ResDate: 0 };
            }
            acc[checkIn].CheckIn += TotalPrice;

            if (!acc[checkOut]) {
                acc[checkOut] = { CheckIn: 0, CheckOut: 0, ResDate: 0 };
            }
            acc[checkOut].CheckOut += TotalPrice;

            if (!acc[resDate]) {
                acc[resDate] = { CheckIn: 0, CheckOut: 0, ResDate: 0 };
            }
            acc[resDate].ResDate += TotalPrice;

            return acc;
        }, {});

        console.log({ out });
        return out;
    };
    const [dataHotels, setDataHotels] = useState({});
    const calculateData = async () => {
        if (loadingCalculateData) return;

        setLoadingCalculateData(true);

        try {
            // First request data
            let aux_request = {};
            for (const hotelID_ of hotelsSelectedMany) {
                if (dataHotels[hotelID_] === undefined) {
                    aux_request[hotelID_] = await askForData(hotelID_);
                }
            }
            setDataHotels({ ...dataHotels, ...aux_request });

            // Then process data
            let aux_process = {};
            for (const hotelID_ of hotelsSelectedMany) {
                aux_process[hotelID_] = processData(
                    { ...dataHotels, ...aux_request }[hotelID_]
                );
            }
            setDataHotelsMany({ ...aux_process });
        } catch (error) {
            console.error("Failed to fetch or process data:", error);
        }

        setLoadingCalculateData(false);
    };
    const [charDataComparison, setCharDataComparison] = useState([]);
    const normalizeData = (hotelData) => {
        const From = new Date(
            new Date().getFullYear() - 1,
            new Date().getMonth(),
            new Date().getDate()
        ).getTime();

        const filteredHotelData = Object.entries(hotelData).filter(
            ([date, _]) => date >= From
        );
        const CIN_d = filteredHotelData.map(
            ([_, value]) => value[DATATYPE_CHECKIN]
        );
        const COUT_d = filteredHotelData.map(
            ([_, value]) => value[DATATYPE_CHECKOUT]
        );
        const RES_d = filteredHotelData.map(
            ([_, value]) => value[DATATYPE_RESDATE]
        );

        const CIN = { min: Math.min(...CIN_d), max: Math.max(...CIN_d) };
        const COUT = { min: Math.min(...COUT_d), max: Math.max(...COUT_d) };
        const RES = { min: Math.min(...RES_d), max: Math.max(...RES_d) };

        const data_N = {};
        for (const [date, value] of Object.entries(hotelData)) {
            data_N[date] = {};
            data_N[date][DATATYPE_CHECKIN] =
                (value[DATATYPE_CHECKIN] - CIN.min) / (CIN.max - CIN.min);
            data_N[date][DATATYPE_CHECKOUT] =
                (value[DATATYPE_CHECKOUT] - COUT.min) / (COUT.max - COUT.min);
            data_N[date][DATATYPE_RESDATE] =
                (value[DATATYPE_RESDATE] - RES.min) / (RES.max - RES.min);
        }

        return data_N;
    };
    useEffect(() => {
        const chartData = Object.keys(dataHotelsMany).map((hotelId) => {
            const hotelData = normalize
                ? normalizeData(dataHotelsMany[hotelId])
                : dataHotelsMany[hotelId];
            console.log({ hotelData });
            const hotelName = HOTELS_INFO[hotelId].name;

            const values = Object.entries(hotelData).map(([k, d]) => {
                const date = new Date(Number(k)).toISOString().slice(0, 10);
                return [date, d[DataBy]];
            });

            return {
                name: hotelName,
                type: "line",
                showSymbol: false,
                data: values.sort((a, b) => (a[0] < b[0] ? -1 : 1)),
                markArea:
                    Number(hotelId) === Number(hotelID) ? getMarkArea() : null,
            };
        });
        console.log({ chartData });
        setCharDataComparison(chartData);
    }, [dataHotelsMany, DataBy, normalize, eventsToHide]);
    return (
        <Container>
            <DataLoader Reservations SimpleData />
            <br />
            {dataBookings.status !== "loaded" ? (
                <CircularProgress />
            ) : (
                <>
                    <ToggleButtonTitled
                        values={{
                            [DATATYPE_RESDATE]: "Booked on date",
                            [DATATYPE_CHECKIN]: "Checked in date",
                            [DATATYPE_CHECKOUT]: "Checked out date",
                        }}
                        valueSet={DataBy}
                        onChange={(e, v) => {
                            setDataBy(v);
                        }}
                    />
                    <br />
                    <Typography>
                        Number of events this year:{" "}
                        {
                            (Events ?? []).filter(
                                (e) =>
                                    new Date(e.FromDate) >=
                                    new Date(new Date().getUTCFullYear(), 0, 1)
                            ).length
                        }
                    </Typography>
                    <EChartsReact
                        option={getOptions()}
                        style={{ height: "400px", width: "100%" }}
                    />
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>
                                    {eventsToHide.length > 0 && (
                                        <IconButton
                                            onClick={() => setEventsToHide([])}
                                        >
                                            <Icons.EyeOn />
                                        </IconButton>
                                    )}
                                </TableHeaderCell>
                                <TableHeaderCell>Event Name</TableHeaderCell>
                                <TableHeaderCell>Category</TableHeaderCell>
                                <TableHeaderCell>From</TableHeaderCell>
                                <TableHeaderCell>To</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                                <TableHeaderCell>Revenue</TableHeaderCell>
                                <TableHeaderCell>Last Year R.</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(Events ?? []).map((e) => (
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                !eventsToHide.includes(e.Name)
                                            }
                                            onChange={(e_) =>
                                                handleCheckboxChange(
                                                    e.Name,
                                                    e_.target.checked
                                                )
                                            }
                                        />
                                    </TableCell>

                                    <TableCell>{e.Name}</TableCell>
                                    <TableCell>
                                        {e.Category} {e.Subcategory}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(e.FromDate)
                                            .toISOString()
                                            .slice(0, 10)}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(e.ToDate)
                                            .toISOString()
                                            .slice(0, 10)}
                                    </TableCell>
                                    <TableCell>
                                        <SmallPercentage
                                            actual={revenue
                                                .filter(
                                                    (f) =>
                                                        new Date(f.day) >=
                                                            new Date(
                                                                e.FromDate
                                                            ) &&
                                                        new Date(f.day) <=
                                                            new Date(e.ToDate)
                                                )
                                                .reduce(
                                                    (acc, v) => acc + v["v"],
                                                    0
                                                )}
                                            old={lastYearRevenue
                                                .filter(
                                                    (f) =>
                                                        new Date(f.day) >=
                                                            new Date(
                                                                e.FromDate
                                                            ) &&
                                                        new Date(f.day) <=
                                                            new Date(e.ToDate)
                                                )
                                                .reduce(
                                                    (acc, v) => acc + v["v"],
                                                    0
                                                )}
                                            brackets={false}
                                            tooltip={false}
                                            bubble={true}
                                            bubbleRound={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {revenue
                                            .filter(
                                                (f) =>
                                                    new Date(f.day) >=
                                                        new Date(e.FromDate) &&
                                                    new Date(f.day) <=
                                                        new Date(e.ToDate)
                                            )
                                            .reduce((acc, v) => acc + v["v"], 0)
                                            .toLocaleString()}
                                    </TableCell>
                                    <TableCell>
                                        {lastYearRevenue
                                            .filter(
                                                (f) =>
                                                    new Date(f.day) >=
                                                        new Date(e.FromDate) &&
                                                    new Date(f.day) <=
                                                        new Date(e.ToDate)
                                            )
                                            .reduce((acc, v) => acc + v["v"], 0)
                                            .toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <hr />
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <HotelSelectorMany
                            selectedHotels={hotelsSelectedMany}
                            setSelectedHotels={setHotelsSelectedMany}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={normalize}
                                    onChange={() => setNormalize(!normalize)}
                                />
                            }
                            label="Normalize"
                        />
                        <Button
                            variant="outlined"
                            onClick={async () => {
                                calculateData().then(() =>
                                    console.log({ dataHotelsMany })
                                );
                            }}
                        >
                            Calculate
                        </Button>
                        {loadingCalculateData && <CircularProgress />}
                    </Stack>
                    {normalize && (
                        <>
                            <Typography>
                                Normalized using max and min values per each
                                hotel during the last 12 months
                            </Typography>
                            {/* <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Hotel</TableCell>
                                        <TableCell>Max</TableCell>
                                        <TableCell>Min</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody></TableBody>
                            </Table> */}
                        </>
                    )}
                    <br />
                    <EChartsReact
                        option={{
                            title: {
                                text: `Total Prices by ${DataBy}`,
                            },
                            tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                    type: "shadow",
                                },
                            },
                            xAxis: {
                                type: "time",
                                boundaryGap: false,
                                splitArea: {
                                    show: true,
                                },
                            },
                            yAxis: {
                                type: "value",
                            },
                            series: charDataComparison,
                            dataZoom: [
                                {
                                    type: "inside",
                                },
                                {
                                    start: 0,
                                    end: 10,
                                },
                            ],
                            legend: {},
                        }}
                        style={{ height: "400px", width: "100%" }}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"theme_name"}
                    />
                </>
            )}
        </Container>
    );
};

export default EventsImpactScreen;
