import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@mui/material";

import LostRevenueReport_Old from "./reports/LostRevenueReport_Old";

import LostRevenueReport from "./reports/LostRevenueReport";
import LostRevenueDailyDistribution from "./reports/LostRevenueDailyDistribution";
import LostRevenueSiteStatusBreakdown from "./reports/LostRevenueSiteStatusBreakdown";
import AllSearchesLeadTime from "./reports/AllSearchesLeadTime";

const SearchRequests = () => {
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);
    return (
        <Grid item sm={12} md={12} sx={{ width: "100%" }}>
            <br />
            <LostRevenueReport_Old hotelID={hotelID} />

            <br />
            <LostRevenueReport hotelID={hotelID} />

            <br />
            <LostRevenueSiteStatusBreakdown hotelID={hotelID} />

            <br />
            <LostRevenueDailyDistribution hotelID={hotelID} />

            <br />
            <AllSearchesLeadTime
                hotelID={hotelID}
                dataType="Searches"
                title="Lead Time Distribution of Sessions"
                report_desc="This reports shows the breakdown of bookings by their offer titles & lead times with respect to session data."
            />

            <br />
            <AllSearchesLeadTime
                hotelID={hotelID}
                dataType="Bookings"
                title="Lead Time Distribution of Bookings"
                report_desc="This reports shows the breakdown of bookings by their offer titles & lead times with respect to booking data."
            />

            <br />
            <AllSearchesLeadTime
                hotelID={hotelID}
                dataType="Revenue"
                title="Lead Time Distribution of Revenue"
                report_desc="This reports shows the breakdown of bookings by their offer titles & lead times with respect to revenue data."
            />

            <br />
        </Grid>
    );
};

export default SearchRequests;
