import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Button,
    Typography,
    Modal,
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Grid,
    Container,
    Paper,
    Snackbar,
    Alert,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enIE } from "date-fns/locale";

import ModalConfirmation from "../ModalConfirmation";

import { URLAPI } from "../../configuration";

import Colours, { pastelColours } from "../../helpers/colours";
import Icons from "../../helpers/icons";
import { dateToUTC, months_abbreviations } from "../../helpers/dates";
import { CATEGORY, OWNER, EVENT_TYPE, PRIORITY } from "../../helpers/events";

const customFieldsStyle = (required = true) => {
    return {
        style: { borderRadius: 10 },
        sx: {
            backgroundColor: Colours.notificationCard,
            "& .MuiFormLabel-root": {
                //color: "black",
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: required ? Colours.primary : "transparent",
                },
                "&:hover fieldset": {
                    borderColor: required ? Colours.primary : "transparent",
                },
                "&.Mui-focused fieldset": {
                    borderColor: required ? Colours.primary : "transparent",
                    color: "black",
                },
            },
        },
        InputProps: {
            style: {
                color: "black",

                backgroundColor: Colours.notificationCard,
            },
        },
    };
};
const CustomTextField = ({ required = true, ...props }) => {
    return <TextField {...props} fullWidth {...customFieldsStyle(required)} />;
};
const CustomDropdownSelector = ({ label, options, error, ...props }) => {
    return (
        <FormControl fullWidth {...customFieldsStyle()} error={error}>
            <InputLabel id={label + "-label"}>{label}</InputLabel>
            <Select
                labelId={label + "-label"}
                label={label}
                {...props}
                sx={{ color: "black" }}
            >
                {Object.keys(options).map((key) => {
                    let Icon = (options[key] ?? {}).Icon ?? null;
                    let color = (options[key] ?? {}).color ?? null;
                    return (
                        <MenuItem value={key}>
                            <Stack direction={"row"}>
                                {Icon && color && (
                                    <Icon
                                        sx={{
                                            mr: 1,
                                            color: color,
                                        }}
                                    />
                                )}

                                {(options[key] ?? {}).label ?? ""}
                            </Stack>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

const CustomDateButton = ({
    onClick = () => {},
    date,
    label,
    onChange = () => {},
    onClose = () => {},
    open,
    anchorEl,
}) => {
    let { year, month, day } = dateToUTC(new Date(date), true);
    return (
        <>
            <Button
                onClick={onClick}
                variant="outlined"
                fullWidth
                size={"large"}
                sx={{
                    height: "60px",
                    textTransform: "none",
                    backgroundColor: Colours.notificationCard,
                    color: "black",
                    borderColor: Colours.primary,
                    justifyContent: "space-between",
                }}
            >
                <Typography>{label}</Typography>
                <Box
                    fullWidth
                    sx={{
                        backgroundColor: "white",
                        width: "50%",
                        height: "100%",
                        alignItems: "center",
                        alignContent: "center",
                        borderRadius: 2,
                    }}
                >
                    <Typography>{`${day} ${months_abbreviations[month]} ${year}`}</Typography>
                </Box>
            </Button>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enIE}
            >
                <DatePicker
                    open={open}
                    value={date}
                    onChange={onChange}
                    onClose={onClose}
                    renderInput={() => {}}
                    disableOpenPicker
                    PopperProps={{ anchorEl: anchorEl }}
                    minDate={
                        new Date(
                            new Date().getUTCFullYear() - 5,
                            new Date().getUTCMonth(),
                            new Date().getUTCDay()
                        )
                    }
                    maxDate={
                        new Date(
                            new Date().getUTCFullYear() + 5,
                            new Date().getUTCMonth(),
                            new Date().getUTCDay()
                        )
                    }
                />
            </LocalizationProvider>
        </>
    );
};

const EventsModal = ({
    editEvent = false,
    eventDetails = {},
    hotelID,
    handleRefreshEvents,
    handleExclude,
    onClose = () => {},
}) => {
    const { auth } = useSelector((state) => state);
    const { user } = useSelector((state) => state.auth);

    const [open, setOpen] = useState(false);
    const [errorForm, setErrorForm] = useState(false);
    const [openFromDate, setOpenFromDate] = useState(false);
    const [openToDate, setOpenToDate] = useState(false);
    const [anchorElFromDate, setAnchorElFromDate] = useState(null);
    const [anchorElToDate, setAnchorElToDate] = useState(null);

    const [name, setName] = useState("");
    const [fromDate, setFromDate] = useState(
        new Date().toISOString().slice(0, 10)
    );
    const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [actionAlert, setActionAlert] = useState("");
    const [owner, setOwner] = useState("");
    const [priority, setPriority] = useState("");
    const [description, setDescription] = useState("");

    const [eventID, setEventID] = useState("");

    const [userDetails, setUserDetails] = useState({
        name: " ",
        full: " ",
        user_id: 0,
    });

    const setEventDetails = () => {
        if (Object.keys(eventDetails).length > 0) {
            setEventID(eventDetails["EventID"]);

            setName(eventDetails["Name"]);

            setFromDate(
                new Date(eventDetails["FromDate"]).toISOString().slice(0, 10)
            );
            setToDate(
                new Date(eventDetails["ToDate"]).toISOString().slice(0, 10)
            );

            setCategory(eventDetails["Category"]);
            setSubcategory(eventDetails["Subcategory"]);

            setActionAlert(eventDetails["ActionAlert"]); // TODO: change name?
            setOwner(eventDetails["Owner"]);

            setPriority(eventDetails["Priority"]);
            setDescription(eventDetails["Description"]);
        }
    };

    useEffect(() => {
        if (editEvent) {
            setEventDetails();
        }
    }, [editEvent, eventDetails]);
    useEffect(() => {
        if (user) {
            if (user.firstName === "")
                setUserDetails({
                    name: user.user.split("@")[0],
                    user_id: user.user_id,
                });
            else
                setUserDetails({
                    name: user.firstName,
                    full: `${user.firstName} ${user.lastName}`,
                    user_id: user.user_id,
                });
        }
    }, [user]);

    const handleOpen = (e) => {
        setOpen(true);
    };
    const handleClose = () => {
        if (wasModified()) {
            setOpenConfirmationModal(true);
        } else {
            cleanClose();
        }
    };

    const validateForm = () => {
        let isValid = true;
        setErrorForm(false);

        if (
            name.trim() === "" ||
            description.trim() === "" ||
            !Object.keys(CATEGORY).includes(category) ||
            !Object.keys(EVENT_TYPE).includes(actionAlert) ||
            !Object.keys(OWNER).includes(owner) ||
            !Object.keys(PRIORITY).includes(priority)
        ) {
            setErrorForm(true);
            isValid = false;
        }
        return isValid;
    };

    const wasModified = () => {
        let modified = false;
        if (editEvent) {
            // edit
            if (
                name !== eventDetails["Name"] ||
                fromDate !==
                    new Date(eventDetails["FromDate"])
                        .toISOString()
                        .slice(0, 10) ||
                toDate !==
                    new Date(eventDetails["ToDate"])
                        .toISOString()
                        .slice(0, 10) ||
                category !== eventDetails["Category"] ||
                subcategory !== eventDetails["Subcategory"] ||
                actionAlert !== eventDetails["ActionAlert"] ||
                owner !== eventDetails["Owner"] ||
                priority !== eventDetails["Priority"] ||
                description !== eventDetails["Description"]
            )
                modified = true;
        } else {
            // new
            if (
                name.trim() !== "" ||
                fromDate !== new Date().toISOString().slice(0, 10) ||
                toDate !== new Date().toISOString().slice(0, 10) ||
                category !== "" ||
                subcategory !== "" ||
                actionAlert !== "" ||
                owner !== "" ||
                priority !== "" ||
                description.trim() !== ""
            )
                modified = true;
        }
        return modified;
    };

    const [discardAnswer, setDiscardAnswer] = useState("");
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    useEffect(() => {
        if (discardAnswer === "discard") {
            cleanClose();
        }
    }, [discardAnswer]);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const submit = async (method) => {
        try {
            let payload = {};
            payload = {
                Name: name,
                FromDate: fromDate,
                ToDate: toDate,
                Category: category,
                Subcategory: subcategory,
                Description: description,
                ActionAlert: actionAlert,
                Owner: owner,
                Priority: priority,
                HotelID: hotelID === "default" ? 88 : hotelID, //DEFAULT_HOTEL : hotelID
                UserID: userDetails.user_id,
                UpdatedBy: userDetails.user_id,
                // UNUSED FIELDS
                TimeZone: "UTC1",
                AllDay: true,
                //Deadline: deadline,
                ColourLightMode:
                    (CATEGORY[category] ?? {}).color ??
                    eventDetails["ColourLightMode"] ??
                    pastelColours[0],
                // "ColourDarkMode": "",
                //RepeatRule: repeat || null,
                //RepeatEnd: repeat ? repeatEnd : null,
            };
            const response = await fetch(
                `${URLAPI}/event/${method === "PATCH" ? eventID : ""}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                    body: JSON.stringify(payload),
                }
            );

            if (!response.ok) {
                console.error("Request failed:", response.statusText);
                setErrorAlert(true);
                setAlertMessage(`Request failed: ${response.statusText}`);
                return;
            }

            const responseData = await response.json();

            if (response.ok) {
                handleRefreshEvents(
                    `Event was ${editEvent ? "edited" : "created"}!`
                );

                cleanClose();
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleSubmit = async () => {
        setErrorAlert(false);
        setAlertMessage("");

        setIsLoading(true);
        if (validateForm()) {
            if (editEvent) await submit("PATCH");
            else await submit("POST");
        } else {
            setErrorAlert(true);
            setAlertMessage("Invalid fields detected.");
        }
        setIsLoading(false);
    };

    const cleanClose = () => {
        setOpen(false);
        setErrorForm(false);
        setOpenFromDate(false);
        setOpenToDate(false);
        setAnchorElFromDate(null);
        setAnchorElToDate(null);
        setDiscardAnswer("");

        setIsLoading(false);

        if (!editEvent) {
            setName("");
            setFromDate(new Date().toISOString().slice(0, 10));
            setToDate(new Date().toISOString().slice(0, 10));
            setCategory("");
            setSubcategory("");
            setActionAlert("");
            setOwner("");
            setPriority("");
            setDescription("");
        } else {
            setEventDetails();
        }
        onClose();
    };

    return (
        <>
            {!editEvent ? (
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid orange",
                        borderRadius: 1000,
                        "&:hover": {
                            backgroundColor: "orange",
                            color: "white",
                        },
                    }}
                    disableElevation
                    onClick={handleOpen}
                >
                    <Icons.Add sx={{ paddingRight: 1 }} /> Add
                </Button>
            ) : (
                <IconButton onClick={handleOpen}>
                    <Icons.Edit fontSize="small" />
                </IconButton>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                key={"details-event-modal"}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Container
                    style={{
                        width: "50%",
                    }}
                    disableGutters
                >
                    <Paper sx={{ p: 3 }}>
                        <Stack direction="column" spacing={2}>
                            <Grid container>
                                <Grid item xs={0.5}></Grid>
                                <Grid
                                    item
                                    xs={11}
                                    sx={{ alignContent: "center" }}
                                >
                                    <Typography
                                        id="modal-modal-title"
                                        textAlign={"center"}
                                        fontWeight={"bold"}
                                    >
                                        {editEvent ? "Edit Event" : "New Event"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={0.5}>
                                    <IconButton onClick={() => handleClose()}>
                                        <Icons.CloseNavbar />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            {/*  _____                      */}
                            {/* |  ___|__  _ __ _ __ ___    */}
                            {/* | |_ / _ \| '__| '_ ` _ \   */}
                            {/* |  _| (_) | |  | | | | | |  */}
                            {/* |_|  \___/|_|  |_| |_| |_|  */}

                            <form>
                                <Stack direction={"column"} spacing={2}>
                                    <CustomTextField
                                        error={name.trim() === "" && errorForm}
                                        label="Name"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                    <Stack direction={"row"} spacing={1}>
                                        <CustomDateButton
                                            onClick={(event) => {
                                                setAnchorElFromDate(
                                                    event.currentTarget
                                                );
                                                setOpenFromDate(true);
                                            }}
                                            date={fromDate}
                                            label={"Starts"}
                                            anchorEl={anchorElFromDate}
                                            onClose={() =>
                                                setOpenFromDate(false)
                                            }
                                            onChange={(newValue) => {
                                                let newString = new Date(
                                                    newValue
                                                )
                                                    .toISOString()
                                                    .slice(0, 10);
                                                setFromDate(newString);

                                                if (newString > toDate)
                                                    setToDate(newString);
                                            }}
                                            open={openFromDate}
                                        />
                                        <CustomDateButton
                                            onClick={(event) => {
                                                setAnchorElToDate(
                                                    event.currentTarget
                                                );
                                                setOpenToDate(true);
                                            }}
                                            date={toDate}
                                            label={"Ends"}
                                            anchorEl={anchorElToDate}
                                            onClose={() => {
                                                setOpenToDate(false);
                                                setAnchorElToDate(null);
                                            }}
                                            onChange={(newValue) => {
                                                let newString = new Date(
                                                    newValue
                                                )
                                                    .toISOString()
                                                    .slice(0, 10);
                                                setToDate(newString);

                                                if (newString < fromDate)
                                                    setFromDate(newString);
                                            }}
                                            open={openToDate}
                                        />
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                        <CustomDropdownSelector
                                            error={
                                                errorForm &&
                                                !Object.keys(CATEGORY).includes(
                                                    category
                                                )
                                            }
                                            options={CATEGORY}
                                            label={"Category"}
                                            value={category}
                                            onChange={(event) =>
                                                setCategory(event.target.value)
                                            }
                                        />
                                        <CustomTextField
                                            required={false}
                                            label="Subcategory"
                                            value={subcategory}
                                            onChange={(e) =>
                                                setSubcategory(e.target.value)
                                            }
                                        />
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                        <CustomDropdownSelector
                                            error={
                                                errorForm &&
                                                !Object.keys(
                                                    EVENT_TYPE
                                                ).includes(actionAlert)
                                            }
                                            options={EVENT_TYPE}
                                            label={"Event Type"}
                                            value={actionAlert}
                                            onChange={(event) =>
                                                setActionAlert(
                                                    event.target.value
                                                )
                                            }
                                        />
                                        <CustomDropdownSelector
                                            error={
                                                errorForm &&
                                                !Object.keys(OWNER).includes(
                                                    owner
                                                )
                                            }
                                            options={OWNER}
                                            label={"Owner"}
                                            value={owner}
                                            onChange={(event) =>
                                                setOwner(event.target.value)
                                            }
                                        />
                                    </Stack>
                                    <CustomDropdownSelector
                                        error={
                                            errorForm &&
                                            !Object.keys(PRIORITY).includes(
                                                priority
                                            )
                                        }
                                        options={PRIORITY}
                                        label={"Priority"}
                                        value={priority}
                                        onChange={(event) =>
                                            setPriority(event.target.value)
                                        }
                                    />
                                    <CustomTextField
                                        error={
                                            description.trim() === "" &&
                                            errorForm
                                        }
                                        label="Description"
                                        value={description}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        multiline
                                    />
                                    {!editEvent && (
                                        <Typography fontSize={"medium"}>
                                            Note: highlighted fields are
                                            mandatory
                                        </Typography>
                                    )}
                                </Stack>
                            </form>

                            <Stack
                                justifyContent={"right"}
                                direction={"row"}
                                spacing={2}
                            >
                                <>
                                    <Button
                                        disabled={isLoading}
                                        variant="outlined"
                                        onClick={() => {
                                            if (wasModified())
                                                setOpenConfirmationModal(true);
                                            else cleanClose();
                                        }}
                                        sx={{
                                            fontSize: "large",
                                            color: "black",
                                            textTransform: "none",
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        disabled={isLoading || !wasModified()}
                                        onClick={handleSubmit}
                                        sx={{
                                            fontSize: "large",
                                            color: "black",
                                            textTransform: "none",
                                            backgroundColor: Colours.primary,
                                            "&:hover": {
                                                backgroundColor:
                                                    Colours.secondarySoft,
                                            },
                                        }}
                                    >
                                        {editEvent ? "Save" : "Add new"}
                                    </Button>
                                </>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Snackbar
                        open={errorAlert}
                        autoHideDuration={6000}
                        onClose={() => {
                            setErrorAlert(false);
                        }}
                    >
                        <Alert severity={"error"}>{alertMessage}</Alert>
                    </Snackbar>
                </Container>
            </Modal>

            <ModalConfirmation
                open={openConfirmationModal}
                setOpen={setOpenConfirmationModal}
                title={"Discard changes?"}
                description={`All changes will be lost`}
                answers={[
                    { title: "Discard", value: "discard" },
                    { title: "Cancel", value: "cancel" },
                ]}
                setAnswer={setDiscardAnswer}
            />
        </>
    );
};

export default EventsModal;
